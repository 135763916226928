import { Component, Input, OnInit } from "@angular/core";
import { QueryResult } from "breeze-client";
import { CharacteristicService } from "../../characteristics/characteristic.service";
import { TaxonCharacteristic } from "../../common/types";
import { SettingService } from "../setting.service";

@Component({
    selector: "workgroup-animal-characteristics",
    templateUrl: "./workgroup-animal-characteristics.component.html"
})
export class WorkgroupAnimalCharacteristicsComponent implements OnInit {
    @Input() readonly: boolean;

    selectedCharacteristicKeys: number[];

    taxonCharacteristicMap: { [key: string]: TaxonCharacteristic } = {};

    constructor(
        private _settingService: SettingService,
        private _characteristicService: CharacteristicService) {

    }

    ngOnInit() {
        this.selectedCharacteristicKeys = new Array<number>(6).fill(null);
        this.setTaxonCharacteristicMap();
    }

    /**
     * Gets the TaxonCharacteristics that have been set as Workgroup Characteristics and group them by their List View Order.
     */
    setTaxonCharacteristicMap(): void {
        this._settingService.getTaxonCharacteristicsShownInListView().then((results: TaxonCharacteristic[]) => {
            for (const taxonCharacteristic of results) {
                this.selectedCharacteristicKeys[taxonCharacteristic.ListViewOrder - 1] = taxonCharacteristic.C_TaxonCharacteristic_key;
                this.taxonCharacteristicMap[taxonCharacteristic.ListViewOrder.toString()] = taxonCharacteristic;
            }
        });
    }

    /**
     * Resets the old TaxonCharacteristic's List View order while setting the new one's. 
     * @param taxonCharacteristic
     * @param listOrder
     */
    setTaxonCharacteristicListOrder(taxonCharacteristic: TaxonCharacteristic, listOrder: number): void {
        this.selectedCharacteristicKeys[listOrder - 1] = taxonCharacteristic ? taxonCharacteristic.C_TaxonCharacteristic_key : null;

        const oldTaxonCharacteristic = this.taxonCharacteristicMap[listOrder.toString()];

        if (oldTaxonCharacteristic) {
            oldTaxonCharacteristic.ListViewOrder = null;
        }

        if (taxonCharacteristic) {
            this.taxonCharacteristicMap[listOrder.toString()] = taxonCharacteristic;
            taxonCharacteristic.ListViewOrder = listOrder;
        } else {
            this.taxonCharacteristicMap[listOrder.toString()] = null;
        }

    }

    searchTaxonCharacteristics = (characteristicName: string): Promise<any> => {
        return this._characteristicService.getTaxonCharacteristics({ Name: characteristicName, IsActive: true }, false).then((result: QueryResult) => {
            const taxonCharacteristics = result.results;
            return taxonCharacteristics.filter((tc: any) => !this.selectedCharacteristicKeys.includes(tc.C_TaxonCharacteristic_key));
        });
    }

    searchTaxonCharacteristicsByKey = (characteristicKey: number): Promise<any> => {
        return this._characteristicService.getTaxonCharacteristics({ TaxonCharacteristicKey: characteristicKey, IsActive: true }, false).then((result: QueryResult) => {
            return result.results;
        });
    }
    taxonCharacteristicKeyFormatter = (value: TaxonCharacteristic) => {
        return value.C_TaxonCharacteristic_key;
    }

    taxonCharacteristicValueFormatter = (value: TaxonCharacteristic) => {
        return value.CharacteristicName;
    }
}
