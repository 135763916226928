import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
} from 'breeze-client';

import {
    getSafeProp,
    notEmpty
} from '../common/util';

import {
    getIsActivePredicate,
    getDateRangePredicates
} from '../services/queries';

import { DataManagerService } from '../services/data-manager.service';
import { BaseEntityService } from '../services/base-entity.service';
import { CommonCharacteristic } from './models/common-characteristic.type';
import { Animal, JobCharacteristic, SampleCharacteristic, StudyCharacteristic, TaxonCharacteristic } from '../common/types';
import { CharacteristicTypeNameEnum } from './models/characteristic-type-name.enum';
import { WebApiService } from '../services/web-api.service';
import { ApiResponse } from '../services/models/api-response';
import { TaxonCharacteristicInstance } from '../common/types';

@Injectable()
export class CharacteristicService extends BaseEntityService {

    readonly SORT_ORDER_FIELD: string = 'SortOrder';

    constructor(
        private dataManager: DataManagerService,
        private webApiService: WebApiService
    ) {
        super();
    }


    // Taxon
    getTaxonCharacteristics(filter: any, includeExpands?: boolean, numSkip?: number, numTake?: number): Promise<any> {
        const predicates: Predicate[] = [];
        if (filter.Name) {
            predicates.push(Predicate.create('CharacteristicName', FilterQueryOp.Contains, { value: filter.Name }));
        }
        if (filter.IsActive) {
            predicates.push(Predicate.create("IsActive", "eq", true));
        }

        if (filter.TaxonCharacteristicKey) {
            predicates.push(Predicate.create("C_TaxonCharacteristic_key", "eq", filter.TaxonCharacteristicKey));
        }

        if (filter.C_Taxon_keys && filter.C_Taxon_keys.length > 0) {
            predicates.push(Predicate.create("TaxonCharacteristicTaxon", "any", "C_Taxon_key", "in", filter.C_Taxon_keys));
        }

        this.getSharedPredicates(filter, predicates);

        let query = EntityQuery.from('TaxonCharacteristics')
            .expand('cv_DataType, TaxonCharacteristicTaxon, TaxonCharacteristicTaxon.cv_Taxon')
            .orderBy(this.SORT_ORDER_FIELD)
            .inlineCount();

        if (includeExpands) {
            query = query.expand('cv_DataType, TaxonCharacteristicTaxon, TaxonCharacteristicTaxon.cv_Taxon');
        }

        if (numSkip) {
            query.skipCount = numSkip;
        }
        if (numTake) {
            query.takeCount = numTake;
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }
        return this.dataManager.executeQuery(query);
    }

    createTaxonCharacteristic(): any {
        const initialValues = {
            IsActive: true,
        };

        return this.dataManager.createEntity('TaxonCharacteristic', initialValues);
    }

    getTaxonCharacteristicInstanceValue(instances: TaxonCharacteristicInstance[], characteristicKey: number): string {
        if (!instances || instances.length === 0) {
            return "";
        }

        const characteristicInstance = instances.find((i: TaxonCharacteristicInstance) => i.C_TaxonCharacteristic_key === characteristicKey);
        if (characteristicInstance && characteristicInstance.CharacteristicValue) {
            return characteristicInstance.CharacteristicValue;
        } else {
            return "";
        }
    }

    // Sample
    getSampleCharacteristics(filter: any, numSkip: number, numTake: number): Promise<any> {
        const predicates: Predicate[] = [];
        if (filter.Name) {
            predicates.push(Predicate.create('CharacteristicName', FilterQueryOp.Contains, { value: filter.Name }));
        }
        if (notEmpty(filter.C_SampleType_keys)) {
            predicates.push(Predicate.create("SampleCharacteristicSampleType", "any", "C_SampleType_key", "in", filter.C_SampleType_keys));
        }

        this.getSharedPredicates(filter, predicates);

        let query = EntityQuery.from('SampleCharacteristics')
            .expand('cv_DataType,SampleCharacteristicSampleType, SampleCharacteristicSampleType.cv_SampleType')
            .orderBy(this.SORT_ORDER_FIELD)
            .inlineCount();

        if (numSkip) {
            query.skipCount = numSkip;
        }
        if (numTake) {
            query.takeCount = numTake;
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query);
    }

    createSampleCharacteristic(): any {
        const manager = this.dataManager.getManager();

        const characteristics: any[] = manager.executeQueryLocally(EntityQuery
            .from('SampleCharacteristics')
            .select(this.SORT_ORDER_FIELD)
            .orderByDesc(this.SORT_ORDER_FIELD)
            .take(1)
            .inlineCount());

        let sortOrder;
        if (characteristics.length > 0) {
            sortOrder = characteristics[0][this.SORT_ORDER_FIELD] + 1;
        } else {
            sortOrder = 1;
        }

        const initialValues = {
            IsActive: true,
            SortOrder: sortOrder
        };

        return this.dataManager.createEntity('SampleCharacteristic', initialValues);
    }

    // Job
    getJobCharacteristics(filter: any, numSkip: number, numTake: number): Promise<any> {
        const predicates: Predicate[] = [];
        if (filter.Name) {
            predicates.push(Predicate.create('CharacteristicName', FilterQueryOp.Contains, { value: filter.Name }));
        }
        if (filter.Type) {
            predicates.push(Predicate.create('cv_JobType.JobType', FilterQueryOp.Contains, { value: filter.Type }));
        }
        if (notEmpty(filter.C_IACUCProtocol_keys)) {
            predicates.push(Predicate.create("JobCharacteristicIACUCProtocol", "any", "C_IACUCProtocol_key", "in", filter.C_IACUCProtocol_keys));
        }
        if (notEmpty(filter.C_JobType_keys)) {
            predicates.push(Predicate.create("JobCharacteristicJobType", "any", "C_JobType_key", "in", filter.C_JobType_keys));
        }
        if (notEmpty(filter.C_JobSubtype_keys)) {
            predicates.push(Predicate.create("JobCharacteristicJobSubtype", "any", "C_JobSubtype_key", "in", filter.C_JobSubtype_keys));
        }
        this.getSharedPredicates(filter, predicates);

        const expands = [
            'cv_DataType',
            'cv_JobCharacteristicLinkType',
            'JobCharacteristicJobType',
            'JobCharacteristicJobType.cv_JobType',
            'JobCharacteristicJobSubtype',
            'JobCharacteristicJobSubtype.cv_JobSubtype',
            'JobCharacteristicIACUCProtocol',
            'JobCharacteristicIACUCProtocol.cv_IACUCProtocol'
        ];

        let query = EntityQuery.from('JobCharacteristics')
            .expand(expands.join(","))
            .orderBy(this.SORT_ORDER_FIELD)
            .inlineCount();

        if (numSkip) {
            query.skipCount = numSkip;
        }
        if (numTake) {
            query.takeCount = numTake;
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query);
    }

    createJobCharacteristic(): any {
        const manager = this.dataManager.getManager();

        const characteristics = manager.executeQueryLocally(EntityQuery.from('JobCharacteristics')
            .select(this.SORT_ORDER_FIELD)
            .orderByDesc(this.SORT_ORDER_FIELD)
            .take(1)
            .inlineCount());

        let sortOrder;
        if (characteristics.length > 0) {
            sortOrder = characteristics[0][this.SORT_ORDER_FIELD] + 1;
        } else {
            sortOrder = 1;
        }

        const initialValues = {
            IsActive: true,
            SortOrder: sortOrder
        };

        return this.dataManager.createEntity('JobCharacteristic', initialValues);
    }


    // Mating
    getMatingCharacteristics(filter: any): Promise<any[]> {
        const predicates: Predicate[] = [];
        if (filter.Name) {
            predicates.push(
                Predicate.create('CharacteristicName', FilterQueryOp.Contains, { value: filter.Name })
            );
        }
        if (filter.Type) {
            predicates.push(
                Predicate.create('cv_MatingType.MatingType', FilterQueryOp.Contains, { value: filter.Type })
            );
        }
        if (filter.IsActive) {
            const isActivePredicate: Predicate = getIsActivePredicate(filter.IsActive);
            predicates.push(isActivePredicate);
        }

        let query = EntityQuery.from('MatingCharacteristics')
            .expand('cv_DataType, cv_MatingType')
            .orderBy(this.SORT_ORDER_FIELD);

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.returnQueryResults(query);
    }

    createMatingCharacteristic(): any {
        const manager = this.dataManager.getManager();

        const characteristics = manager.executeQueryLocally(EntityQuery.from('MatingCharacteristics')
            .select(this.SORT_ORDER_FIELD)
            .orderByDesc(this.SORT_ORDER_FIELD)
            .take(1)
            .inlineCount());

        let sortOrder;
        if (characteristics.length > 0) {
            sortOrder = characteristics[0][this.SORT_ORDER_FIELD] + 1;
        } else {
            sortOrder = 1;
        }

        const initialValues = {
            IsActive: true,
            SortOrder: sortOrder
        };

        return this.dataManager.createEntity('MatingCharacteristic', initialValues);
    }


    // Study
    getStudyCharacteristics(filter: any, numSkip: number, numTake: number): Promise<any> {
        const predicates: Predicate[] = [];
        if (filter.Name) {
            predicates.push(Predicate.create('CharacteristicName', FilterQueryOp.Contains, { value: filter.Name }));
        }
        if (notEmpty(filter.C_StudyType_keys)) {
            predicates.push(Predicate.create("StudyCharacteristicStudyType", "any", "C_StudyType_key", "in", filter.C_StudyType_keys));
        }
        this.getSharedPredicates(filter, predicates);

        let query = EntityQuery.from('StudyCharacteristics')
            .expand('cv_DataType, StudyCharacteristicStudyType, StudyCharacteristicStudyType.cv_StudyType')
            .orderBy(this.SORT_ORDER_FIELD)
            .inlineCount();

        if (numSkip) {
            query.skipCount = numSkip;
        }
        if (numTake) {
            query.takeCount = numTake;
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query);
    }

    createStudyCharacteristic(): any {
        const manager = this.dataManager.getManager();
        const characteristics = manager.executeQueryLocally(EntityQuery.from('StudyCharacteristics')
            .select(this.SORT_ORDER_FIELD)
            .orderByDesc(this.SORT_ORDER_FIELD)
            .take(1)
            .inlineCount());

        let sortOrder;
        if (characteristics.length > 0) {
            sortOrder = characteristics[0][this.SORT_ORDER_FIELD] + 1;
        } else {
            sortOrder = 1;
        }

        const initialValues = {
            IsActive: true,
            SortOrder: sortOrder
        };

        return this.dataManager.createEntity('StudyCharacteristic', initialValues);
    }

    private getSharedPredicates(filter: any, predicates: Predicate[]) {
        if (filter.Actives && filter.Actives.length > 0) {
            predicates.push(Predicate.create('IsActive', 'in', filter.Actives));
        }

        if (filter.DateLastReviewedStart || filter.DateLastReviewedEnd) {
            const datePredicates = Predicate.and(getDateRangePredicates(
                'DateLastReviewed',
                filter.DateLastReviewedStart,
                filter.DateLastReviewedEnd
            ));
            predicates.push(datePredicates);
        }
    }

    // Deleting
    async deleteCharacteristic(characteristic: any, type: string) {
        switch (type) {
            case CharacteristicTypeNameEnum.Taxon:
                this.deleteTaxonCharacteristic(characteristic);
                break;
            case CharacteristicTypeNameEnum.Sample:
                this.deleteSampleCharacteristic(characteristic);
                break;
            case CharacteristicTypeNameEnum.Job:
                this.deleteJobCharacteristic(characteristic);
                break;
            case CharacteristicTypeNameEnum.Study:
                this.deleteStudyCharacteristic(characteristic);
                break;
            default:
                throw new Error("Characteristic type not supported");
        }
    }

    deleteTaxonCharacteristic(taxonCharacteristic: TaxonCharacteristic): void {
        while (taxonCharacteristic.TaxonCharacteristicTaxon.length > 0) {
            this.dataManager.deleteEntity(taxonCharacteristic.TaxonCharacteristicTaxon[0]);
        }
        this.dataManager.deleteEntity(taxonCharacteristic);
    }

    deleteSampleCharacteristic(sampleCharacteristic: SampleCharacteristic) {
        while (sampleCharacteristic.SampleCharacteristicSampleType.length > 0) {
            this.dataManager.deleteEntity(sampleCharacteristic.SampleCharacteristicSampleType[0]);
        }
        this.dataManager.deleteEntity(sampleCharacteristic);
    }

    deleteJobCharacteristic(jobCharacteristic: JobCharacteristic) {
        while (jobCharacteristic.JobCharacteristicIACUCProtocol.length > 0) {
            this.dataManager.deleteEntity(jobCharacteristic.JobCharacteristicIACUCProtocol[0]);
        }
        while (jobCharacteristic.JobCharacteristicJobSubtype.length > 0) {
            this.dataManager.deleteEntity(jobCharacteristic.JobCharacteristicJobSubtype[0]);
        }
        while (jobCharacteristic.JobCharacteristicJobType.length > 0) {
            this.dataManager.deleteEntity(jobCharacteristic.JobCharacteristicJobType[0]);
        }

        this.dataManager.deleteEntity(jobCharacteristic);
    }

    deleteStudyCharacteristic(studyCharacteristic: StudyCharacteristic) {
        while (studyCharacteristic.StudyCharacteristicStudyType.length > 0) {
            this.dataManager.deleteEntity(studyCharacteristic.StudyCharacteristicStudyType[0]);
        }
        this.dataManager.deleteEntity(studyCharacteristic);
    }

    // Related entities check
    isTaxonCharacteristicBeingUsed(characteristic: any): Promise<boolean> {
        const predicates = Predicate.and(
            new Predicate("C_TaxonCharacteristic_key", "==", characteristic.C_TaxonCharacteristic_key),
            new Predicate("CharacteristicValue", "!=", null),
            new Predicate("TaxonCharacteristic.ListViewOrder", "!=", null)
        );
        const query = EntityQuery.from("TaxonCharacteristicInstances")
            .expand("TaxonCharacteristic")
            .where(predicates)
            .noTracking(true)
            .take(0)
            .inlineCount(true);

        return this.dataManager.returnQueryCount(query).then((count) => {
            return count > 0;
        });
    }

    isSampleCharacteristicBeingUsed(characteristic: any): Promise<boolean> {
        const query = EntityQuery.from("SampleCharacteristicInstances")
            .where('C_SampleCharacteristic_key', '==', characteristic.C_SampleCharacteristic_key)
            .take(0)
            .noTracking(true)
            .inlineCount(true);

        return this.dataManager.returnQueryCount(query).then((count) => {
            return count > 0;
        });
    }

    isJobCharacteristicBeingUsed(characteristic: any): Promise<boolean> {
        const query = EntityQuery.from("JobCharacteristicInstances")
            .where('C_JobCharacteristic_key', '==', characteristic.C_JobCharacteristic_key)
            .take(0)
            .noTracking(true)
            .inlineCount(true);

        return this.dataManager.returnQueryCount(query).then((count) => {
            return count > 0;
        });
    }

    isMatingCharacteristicBeingUsed(characteristic: any): Promise<boolean> {
        const query = EntityQuery.from("MatingCharacteristicInstances")
            .where('C_MatingCharacteristic_key', '==', characteristic.C_MatingCharacteristic_key)
            .take(0)
            .noTracking(true)
            .inlineCount(true);

        return this.dataManager.returnQueryCount(query).then((count) => {
            return count > 0;
        });
    }

    isStudyCharacteristicBeingUsed(characteristic: any): Promise<boolean> {
        const query = EntityQuery.from("StudyCharacteristicInstances")
            .where('C_StudyCharacteristic_key', '==', characteristic.C_StudyCharacteristic_key)
            .noTracking(true)
            .take(0)
            .inlineCount(true);

        return this.dataManager.returnQueryCount(query).then((count) => {
            return count > 0;
        });
    }

    cancelCharacteristic(characteristic: any) {
        if (!characteristic) {
            return;
        }

        this.dataManager.rejectEntityAndRelatedPropertyChanges(characteristic);
    }

    validateCommonCharacteristic(commonCharacteristic: CommonCharacteristic): string {
        let errorMsg: string;
        if (!commonCharacteristic.CharacteristicName || commonCharacteristic.CharacteristicName.trim().length === 0) {
            errorMsg = "Save Failed: Characteristic requires a name";
        }

        if (getSafeProp(commonCharacteristic, "cv_DataType.DataType") === "Long Text") {
            if (commonCharacteristic.TextLineCount < 1) {
                errorMsg = "text's 'Number of Rows' must be at least 1";
            }
        }

        if (getSafeProp(commonCharacteristic, "cv_DataType.DataType") === "Number") {
            if (commonCharacteristic.NumericScale < 0) {
                errorMsg = "data type's 'Decimal Places' cannot be negative";
            }

            if (commonCharacteristic.ValidationMinimum && commonCharacteristic.ValidationMaximum && +commonCharacteristic.ValidationMinimum > +commonCharacteristic.ValidationMaximum) {
                errorMsg = "data type's 'Minimum Value' cannot be more than 'Maximum Value'";
            }
        }

        return errorMsg;
    }

    
    refreshTaxonCharacteristics(animals: Animal[]): Promise<ApiResponse> {
        return this.webApiService.postApi("/api/taxoncharacteristics/add-bulk", animals.map(a => a.C_Material_key));
    }

    bulkDeleteTaxonCharacteristics(taxonCharacteristic: TaxonCharacteristic): Promise<ApiResponse> {
        while (taxonCharacteristic.TaxonCharacteristicInstance.length > 0) {
            this.dataManager.detachEntity(taxonCharacteristic.TaxonCharacteristicInstance[0]);
        }
        return this.webApiService.postApi("api/taxoncharacteristics/delete-bulk", { taxonCharacteristicKey: taxonCharacteristic.C_TaxonCharacteristic_key});
    }
}
