import {
    Component,
    Input,
    Output,
    OnDestroy,
    EventEmitter
} from '@angular/core';

import { JobService } from './job.service';
import { Subscription } from 'rxjs';
import { LineService } from '../lines/line.service';

/**
 * Table for adding groups to jobs
 */
@Component({
    selector: 'job-line-table',
    templateUrl: './job-line-table.component.html'
})
export class JobLineTableComponent implements OnDestroy {
    @Input() job: any;
    @Input() readonly: boolean;
    @Input() required: boolean;
    @Output() firstLineChanged: EventEmitter<any> = new EventEmitter<any>();

    // All subscriptions
    subs = new Subscription();

    constructor(
        private jobService: JobService,
        private lineService: LineService,
    ) {
        //
    }

    ngOnDestroy() {
        // Clear all the subscriptions
        this.subs.unsubscribe();
    }    

    addJobLine(job: any) {
        if (this.readonly) {
            return;
        }
        let initialValues = {
            C_Job_key: job.C_Job_key,
            SortOrder: job.JobLine.length + 1,
        };
        this.jobService.createJobLine(initialValues);
    }

    removeLine(jobLine: any) {
        if (this.readonly) {
            return;
        }

        // Update Job if change was made to the first JobLine record
        if (this.job.JobLine[0].C_JobLine_key === jobLine.C_JobLine_key) {
            if (this.job.JobLine.length >= 2) {
                this.job.C_Line_key = this.job.JobLine[1].C_Line_key;
            } else {
                this.job.C_Line_key = null;
            }
            this.firstLineChanged.emit();
        }

        this.jobService.deleteJobLine(jobLine);
        if (this.job.JobLine.length < 1) {
            this.job.C_Line_key = null;
        }
    }

    lineChanged(jobLine: any) {
        if (jobLine.C_Line_key) {
            this.lineService.getLine(jobLine.C_Line_key);
        }

        // Update Job if change was made to the first JobLine record
        if (this.job.JobLine[0].C_JobLine_key === jobLine.C_JobLine_key) {
            this.job.C_Line_key = jobLine.C_Line_key;
            this.firstLineChanged.emit();
        }
    }
}
