import { createStore, createEvent, sample } from 'effector';
import { WorkspaceState } from './types';

export const workspaceStore = createStore<WorkspaceState>({
  active: false,
});

// public events
export const setActiveEvent = createEvent<boolean>();

// @ts-ignore
sample({
  source: workspaceStore,
  clock: setActiveEvent,
  fn: (_, active) => ({ active }),
  target: workspaceStore,
});
