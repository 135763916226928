<!-- template to render audit type dropdown -->
<ng-template #auditTypeDropdown>
    <climb-audit-type-dropdown [options]="options"
                               [activeGridView]="gridView"
                               [isGLP]="isGLP"
                               (changeGridView)="changeGridView($event)">
    </climb-audit-type-dropdown>
</ng-template>

<div class="facet">
    <facet-header caption="Audit"
                  [facetId]="facetId">
        <button *ngIf="gridView !== 'Choose Section'"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Audit Records">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" *ngIf="facet.Privilege!=='None'">
        <div *ngIf="gridView==='Choose Section'" class="audit-chooser-wrapper">
            <label control-label>Choose an audit section to proceed</label>
            <div>
                <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
            </div>
        </div>
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Animals"
                                [dataService]="dataService"
                                [options]="auditAnimalsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.AnimalComments"
                                [dataService]="dataService"
                                [options]="auditAnimalCommentsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.AnimalTasks"
                                [dataService]="dataService"
                                [options]="auditAnimalsTasksTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Samples"
                                [dataService]="dataService"
                                [options]="auditSamplesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Jobs"
                                [dataService]="dataService"
                                [options]="auditJobsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobAnimals"
                                [dataService]="dataService"
                                [options]="auditJobAnimalsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobSamples"
                                [dataService]="dataService"
                                [options]="auditJobSamplesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobSampleGroups"
                                [dataService]="dataService"
                                [options]="auditJobSampleGroupsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobTasks"
                                [dataService]="dataService"
                                [options]="auditJobTasksTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobLines"
                                [dataService]="dataService"
                                [options]="auditJobLinesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobLineDetails"
                                [dataService]="dataService"
                                [options]="auditJobLineDetailsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobStandardPhrases"
                                [dataService]="dataService"
                                [options]="auditJobStandardPhrasesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobGroups"
                                [dataService]="dataService"
                                [options]="auditJobGroupsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobTestArticles"
                                [dataService]="dataService"
                                [options]="auditJobTestArticlesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobCharacteristics"
                                [dataService]="dataService"
                                [options]="auditJobCharacteristicsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobsInstitutions"
                                [dataService]="dataService"
                                [options]="auditJobInstitutionsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobOrders"
                                [dataService]="dataService"
                                [options]="auditJobOrdersTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobIndividualPlaceholders"
                                [dataService]="dataService"
                                [options]="auditJobIndividualPlaceholdersTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobGroupPlaceholders"
                                [dataService]="dataService"
                                [options]="auditJobGroupPlaceholdersTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.WorkflowInputs"
                                [dataService]="dataService"
                                [options]="auditWorkflowInputsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.WorkflowOutputs"
                                [dataService]="dataService"
                                [options]="auditWorkflowOutputsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Housing"
                                [dataService]="dataService"
                                [options]="auditMaterialPoolsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.HousingAnimals"
                                [dataService]="dataService"
                                [options]="auditMaterialPoolMaterialsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.HousingTasks"
                                [dataService]="dataService"
                                [options]="auditMaterialPoolTasksTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.HousingLocations"
                                [dataService]="dataService"
                                [options]="auditMaterialLocationsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.HousingSocialStatutes"
                                [dataService]="dataService"
                                [options]="auditMaterialPoolSocialStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.HousingCompatibilities"
                                [dataService]="dataService"
                                [options]="auditCompatibilityMaterialsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Matings"
                                [dataService]="dataService"
                                [options]="auditMatingsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.MatingMaterials"
                                [dataService]="dataService"
                                [options]="auditMatingMaterialsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Cohorts"
                                [dataService]="dataService"
                                [options]="auditCohortsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.CohortAnimals"
                                [dataService]="dataService"
                                [options]="auditCohortAnimalsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Lines"
                                [dataService]="dataService"
                                [options]="auditLinesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Studies"
                                [dataService]="dataService"
                                [options]="auditStudiesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.StudyLines"
                                [dataService]="dataService"
                                [options]="auditStudyLinesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Births"
                                [dataService]="dataService"
                                [options]="auditBirthsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Locations"
                                [dataService]="dataService"
                                [options]="auditLocationsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.ClinicalObservations"
                                [dataService]="dataService"
                                [options]="auditAnimalClinicalObservationsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Clinical"
                                [dataService]="dataService"
                                [options]="auditAnimalHealthRecordsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.ClinicalTasks"
                                [dataService]="dataService"
                                [options]="auditTaskAnimalHealthRecordsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.ClinicalObservations && !isGLP"
                                [dataService]="dataService"
                                [options]="auditAnimalClinicalObservationsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.ClinicalObservationDetails && !isGLP"
                                [dataService]="dataService"
                                [options]="auditClinicalObservationDetailsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.ClinicalObservationDetails && isGLP"
                                [dataService]="dataService"
                                [options]="auditObservationDetailsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Tasks"
                                [dataService]="dataService"
                                [options]="auditWorkflowTasksTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.TaskAssignments"
                                [dataService]="dataService"
                                [options]="auditTaskAssignmentsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.TaskOutputFlags"
                                [dataService]="dataService"
                                [options]="auditOutputFlagsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Files"
                                [dataService]="dataService"
                                [options]="auditFilesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.WorkflowTaskDetails"
                                [dataService]="dataService"
                                [options]="auditWorkflowTaskDetailsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.TaskInputs"
                                [dataService]="dataService"
                                [options]="auditTaskInputsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.TaskOutputs"
                                [dataService]="dataService"
                                [options]="auditTaskOutputsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Orders"
                                [dataService]="dataService"
                                [options]="auditOrdersTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.OrderAnimals"
                                [dataService]="dataService"
                                [options]="auditOrdersAnimalsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.OrderLocations"
                                [dataService]="dataService"
                                [options]="auditOrdersLocationsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Protocols"
                                [dataService]="dataService"
                                [options]="auditProtocolsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.ProtocolDefaultInputs"
                                [dataService]="dataService"
                                [options]="auditProtocolDefaultInputsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.ProtocolTasks"
                                [dataService]="dataService"
                                [options]="auditProtocolsTasksTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.ProtocolSampleGroups"
                                [dataService]="dataService"
                                [options]="auditProtocolsSamplesGroupsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Resources"
                                [dataService]="dataService"
                                [options]="auditResourcesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.StudyAdministratorStudies"
                                [dataService]="dataService"
                                [options]="auditStudyAdministratorStudiesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Users"
                                [dataService]="dataService"
                                [options]="auditUsersTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.UserDetails"
                                [dataService]="dataService"
                                [options]="auditUserDetailsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.UserRoles"
                                [dataService]="dataService"
                                [options]="auditUserRolesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.RoleFacets"
                                [dataService]="dataService"
                                [options]="auditRoleFacetsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesTaskFlagMessages"
                                [dataService]="dataService"
                                [options]="auditVocabulariesTaskFlagMessagesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesTaskStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesTaskStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesAnimalClassifications"
                                [dataService]="dataService"
                                [options]="auditVocabulariesAnimalClassificationsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesAnimalCommentStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesAnimalCommentStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesAnimalStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesAnimalStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesTestArticles"
                                [dataService]="dataService"
                                [options]="auditVocabulariesTestArticlesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesJobSubtypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesJobSubtypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesTaskOutcomes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesTaskOutcomesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.CharacteristicsAnimals"
                                [dataService]="dataService"
                                [options]="auditCharacteristicsAnimalsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.CharacteristicsJobs"
                                [dataService]="dataService"
                                [options]="auditCharacteristicsJobsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesLineStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesLineStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesJobStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesJobStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesAnimalUses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesAnimalUsesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.CharacteristicsStudies"
                                [dataService]="dataService"
                                [options]="auditCharacteristicsStudiesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesMarkerTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesPhysicalMarkerTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesSampleAnalyses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesSampleAnalysesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesSampleConditions"
                                [dataService]="dataService"
                                [options]="auditVocabulariesSampleConditionsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.CharacteristicsSamples"
                                [dataService]="dataService"
                                [options]="auditCharacteristicsSamplesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesPlateStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesPlateStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.Enumerations"
                                [dataService]="dataService"
                                [options]="auditEnumerationClassesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesAnimalSampleOrigins"
                                [dataService]="dataService"
                                [options]="auditVocabulariesMaterialOriginsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesBirthStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesBirthStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesBodyConditionScores"
                                [dataService]="dataService"
                                [options]="auditVocabulariesBodyConditionScoresTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesBreedingStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesBreedingStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.SettingsNaming"
                                [dataService]="dataService"
                                [options]="auditNameFormatTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.SettingsFacets"
                                [dataService]="dataService"
                                [options]="auditFacetSettingsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.SettingsOther"
                                [dataService]="dataService"
                                [options]="auditSettingsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesShipmentAnimalConditions"
                                [dataService]="dataService"
                                [options]="auditVocabulariesShipmentAnimalConditionsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesShipmentContainerConditions"
                                [dataService]="dataService"
                                [options]="auditVocabulariesShipmentContainerConditionsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesClinicalObservationStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesClinicalObservationStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesClinicalObservations"
                                [dataService]="dataService"
                                [options]="auditVocabulariesClinicalObservationsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesClinicalObservationBodySystems"
                                [dataService]="dataService"
                                [options]="auditVocabulariesClinicalObservationBodySystemsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesDiets"
                                [dataService]="dataService"
                                [options]="auditVocabulariesDietsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesGenerations"
                                [dataService]="dataService"
                                [options]="auditVocabulariesGenerationsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesLocationTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesLocationTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.EnumerationValues"
                                [dataService]="dataService"
                                [options]="auditEnumerationItemsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesResourceGroups"
                                [dataService]="dataService"
                                [options]="auditVocabulariesResourceGroupsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesResourceTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesResourceTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesCompliances"
                                [dataService]="dataService"
                                [options]="auditVocabulariesCompliancesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesInstitutionTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesInstitutionTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesLineTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesLineTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesIACUCProtocols"
                                [dataService]="dataService"
                                [options]="auditVocabulariesIACUCProtocolsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesCouriers"
                                [dataService]="dataService"
                                [options]="auditVocabulariesCouriersTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesExitReasons"
                                [dataService]="dataService"
                                [options]="auditVocabulariesExitReasonsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesGenotypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesGenotypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesHousingStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesMaterialPoolStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesHousingTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesMaterialPoolTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesMatingPurposes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesMatingPurposesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesMatingStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesMatingStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesPaymentTerms"
                                [dataService]="dataService"
                                [options]="auditVocabulariesPaymentTermsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesMatingTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesMatingTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesCompatibilityAccessLevels"
                                [dataService]="dataService"
                                [options]="auditVocabulariesCompatibilityAccessLevelsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesModifiers1"
                                [dataService]="dataService"
                                [options]="auditVocabulariesModifiers1TableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesModifiers2"
                                [dataService]="dataService"
                                [options]="auditVocabulariesModifiers2TableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesModifiers3"
                                [dataService]="dataService"
                                [options]="auditVocabulariesModifiers3TableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesModifiers4"
                                [dataService]="dataService"
                                [options]="auditVocabulariesModifiers4TableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesSocialGroupAccessLevels"
                                [dataService]="dataService"
                                [options]="auditVocabulariesSocialGroupAccessLevelsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesContainerTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesContainerTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesJobReports"
                                [dataService]="dataService"
                                [options]="auditVocabulariesJobReportsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesContactTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesContactPersonTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesJobTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesJobTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesQuarantineFacilities"
                                [dataService]="dataService"
                                [options]="auditVocabulariesQuarantineFacilitiesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesGenotypeAssays"
                                [dataService]="dataService"
                                [options]="auditVocabulariesGenotypeAssaysTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesSiteTypes"
                                [dataService]="dataService"
                                [options]="auditVocabulariesSiteTypesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesUnits"
                                [dataService]="dataService"
                                [options]="auditVocabulariesUnitsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.JobLocations"
                                [dataService]="dataService"
                                [options]="auditJobLocationsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesRoutesOfAdministration"
                                [dataService]="dataService"
                                [options]="auditVocabulariesRouteOfAdministrationsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesVeterinaryRequestStatuses"
                                [dataService]="dataService"
                                [options]="auditVocabulariesVeterinaryRequestStatusesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.VocabulariesStandardPhrases"
                                [dataService]="dataService"
                                [options]="auditVocabulariesStandardPhrasesTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.StudyCharacteristics"
                                [dataService]="dataService"
                                [options]="auditStudyCharacteristicsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.SampleCharacteristics"
                                [dataService]="dataService"
                                [options]="auditSampleCharacteristicsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>

        <climb-audit-data-table *ngIf="gridView === auditGridViews.AnimalCharacteristics"
                                [dataService]="dataService"
                                [options]="auditAnimalCharacteristicsTableOptions.options">
            <ng-template [ngTemplateOutlet]="auditTypeDropdown"></ng-template>
        </climb-audit-data-table>
    </div>
</div>
