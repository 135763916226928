const ELLIPSIS = '\u2026';

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { JobService } from './job.service';
import { SearchService } from '../search/search.service';
import { TranslationService } from '../services/translation.service';
import { LoggingService } from '../services/logging.service';
import { DataManagerService } from '../services/data-manager.service';
import { ReasonForChangeService } from '../common/reason-for-change/reason-for-change.service';

/**
 * Selector for associating multiple jobs with a material.
 * Allows selecting only unlocked jobs.
 */
@Component({
    selector: 'material-job-select',
    templateUrl: './material-job-select.component.html',
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class MaterialJobSelectComponent implements OnInit {
    @Input() material: any;
    @Input() readonly: boolean;
    @Input() isGLP: boolean;
    @Input() required: boolean;

    placeholder: string;

    constructor(
        private jobService: JobService,
        private searchService: SearchService,
        private translationService: TranslationService,
        private loggingService: LoggingService,
        private dataManager: DataManagerService,
        private reasonForChangeService: ReasonForChangeService,
    ) {
        //
    }

    ngOnInit() {
        this.placeholder = this.translationService.translate('Job') +
            ' name' +
            ELLIPSIS;
    }

    getJobMaterial(): any[] {
        if (this.isGLP) {
            return this.material.JobMaterial.filter((x: any) => x.DateOut === null);
        } else {
            return this.material.JobMaterial;
        }
    }

    hasActiveJobs(): boolean {
        return this.getJobMaterial().length > 0;
    }

    addJobMaterial(job: any) {
        let materialKey = this.material.C_Material_key;
        let jobKey = job.JobKey;
        let newJobMaterial = this.jobService.createJobMaterial({
            C_Material_key: materialKey,
            C_Job_key: jobKey,
            Version: 0,
            DateIn: new Date(),
            DateModified: new Date()
        });
        if (this.material.Animal) {
            this.reasonForChangeService.markModification([this.material.Animal]);
        }
        // load job into breeze cache
        if (newJobMaterial) {
            this.jobService.getJob(jobKey).then((data: any) => {
                newJobMaterial.Job = data;
            });
        }
    }

    removeJobMaterial(jobMaterial: any) {
        // Make sure task data is loaded
        this.dataManager.ensureRelationships([this.material], ['TaskMaterial.TaskInstance.TaskJob']).then(() => { 
            if (!this.hasTaskData(jobMaterial)) {
                // no data, so it's okay to delete
                if (this.material.Animal) {
                    this.reasonForChangeService.markModification([this.material.Animal]);
                }
                if (this.isGLP) {
                    jobMaterial.DateOut = new Date();
                } else {
                    this.jobService.deleteJobMaterial(jobMaterial);
                }
            } else {
                // tell user they cannot delete the job material
                let translated = this.translationService.translate('job');
                this.loggingService.logWarning(
                    `A material can't be removed from a ${translated} if associated with that ${translated}'s tasks`,
                    null,
                    'material-job-select',
                    true
                );
            }
        });
    }

    hasTaskData(jobMaterial: any) {
        // Check if this material has tasks on specified job 
        for (const tm of this.material.TaskMaterial) {
            const task = tm.TaskInstance;
            if (!task) {
                continue;
            }

            if (task.TaskJob) {
                for (const tj of task.TaskJob) {
                    if (tj.C_Job_key === jobMaterial.C_Job_key) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    searchJobs = (term: string): Promise<any> => {
        // Exclude locked jobs
        let isLocked = false;
        return this.searchService.searchJobs(term, isLocked).then((results: any) => {
            return results.data;
        });
    }

    jobIdFormatter = (value: any) => {
        return value.JobID;
    }
}
