import { DataContextService } from './../services/data-context.service';
import {
    Component,
    OnInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationService } from '../services/translation.service';
import { sortObjectArrayByProperty } from '../common/util';


@Component({
    selector: 'wip-modal',
    templateUrl: './wip-modal.component.html'
})
export class WipModalComponent implements OnInit {

    wip: ChangeItem[] = [];

    constructor(
        private activeModal: NgbActiveModal,
        private dataContext: DataContextService,
        private translationService: TranslationService
    ) {
       // Nothing to do
    }

    ngOnInit() {
        this.getWipSummary();
    }

    getWipSummary() {
        const changes = this.dataContext.getChanges();
        this.wip = [];

        // Loop through dataContext changes
        for (const change of changes) {
            if (!change || !change.entityAspect) {
                continue;
            }

            const recordKey = change.entityAspect.getKey();
            const shortName = recordKey.entityType.shortName;

            change.entityAspect.validateEntity();
            const changedItem: ChangeItem = {
                entity: change,
                key: recordKey.values[0],
                entityName: shortName,
                displayName: this._getDisplayName(shortName),
                isValid: this._getIsValidText(change),
                changeDateTime: this._getChangeDateTime(change),
                description: this._getDescription(change)
            };

            // Check associated material record if animal or sample
            if ((changedItem.entityName === 'Animal' || 
                    changedItem.entityName === 'Sample') && 
                changedItem.isValid === 'Yes'
            ) {
                try {
                    change.Material.entityAspect.validateEntity();
                    changedItem.isValid = this._getIsValidText(change.Material);
                } catch (err) { 
                    // do nothing
                }
            }

            this.wip.push(changedItem);
        }

        this.wip = this.filterEntitiesToDisplay(this.wip);
        sortObjectArrayByProperty(this.wip, 'changeDateTime');
    }

    private _getDisplayName(entityName: string): string {
        if (!entityName) {
            return '';
        }

        let displayName = entityName;

        entityName = entityName.toLowerCase();

        switch (entityName) {
            case "animalclinicalobservation":
                displayName = 'Clinical Observation';
                break;
            case "birthmaterial":
                displayName = 'Birth Sire or Dam';
                break;
            case "materialpool":
                displayName = 'Housing Unit';
                break;
            case "materialpoolmaterial":
                displayName = "Animal or Sample Group";
                break;
            case "taskoutputset":
                displayName = 'Output Group';
                break;
            case "taskinput":
                displayName = 'Input';
                break;
            case "taskoutput":
                displayName = 'Output';
                break;
            case "materialsourcematerial":
                displayName = 'Sample Source';
                break;
            case "taskoutputsetmaterial":
                displayName = 'Output Animal or Sample';
                break;
            case "storedfilemap":
                displayName = 'File Association';
                break;
            case "storedfile":
                displayName = 'File';
                break;
            case "taskinstance":
                displayName = 'Task';
                break;
            case "taskanimalhealthrecord":
                displayName = 'Clinical Task';
                break;
            case "climbrole":
                displayName = 'Role';
                break;
            case "job":
            case "taxoncharacteristic":
            case "animalcharacteristic":
            case "birth":
            case "protocol":
            case "study":
            case "taxon":
                displayName = this.translationService.translate(displayName);
                break;
        }

        return displayName;
    }

    private _getIsValidText(entity: any): string {
        if (!entity || !entity.entityAspect) {
            return '';
        }
        return entity.entityAspect.hasValidationErrors ? 'No' : 'Yes';
    }

    private _getChangeDateTime(entity: any): Date  {
        if (!entity || !entity.entityAspect) {
            return null;
        }
        return entity.entityAspect.entityState.name === 'Added' ? 
            entity.DateCreated : entity.DateModified;
    }

    private _getDescription(entity: any): string {
        if (!entity || !entity.entityAspect) {
            return '';
        }
        return entity.entityAspect.entityState.name;
    }

    filterEntitiesToDisplay(wip: ChangeItem[]): ChangeItem[] {
        if (!wip) {
            return [];
        }

        const namesToExclude = [
            'deletion',
            'material',
            'platematerial',
            'workgroupuser'];

        return wip.filter((item) => {
            
            let entityName = item.entityName;
            if (!entityName) {
                return false;
            }
            entityName = entityName.toLowerCase();

            // remove excluded types
            if (namesToExclude.indexOf(entityName) >= 0) {
                return false;
            }

            // remove characteristic instances
            if (entityName.indexOf('characteristicinstance') > 0) {
                return false;
            }

            return true;
        });
    }

    cancelChange(item: ChangeItem) {
        if (item.entity) {
            item.entity.entityAspect.rejectChanges();
        }

        // Also delete the records in related tables
        for (const baseEntity of this.wip) {

            if (item.entityName === 'Animal' || item.entityName === 'Sample') {
                if (baseEntity.entityName === 'Material' && 
                    baseEntity.key === item.key
                ) {
                    baseEntity.entity.entityAspect.rejectChanges();
                }
                if (baseEntity.entityName === 'TaxonCharacteristicInstance') {
                    if (baseEntity.entity.C_Material_key === item.key) {
                        baseEntity.entity.entityAspect.rejectChanges();
                    }
                }
                if (baseEntity.entityName === 'SampleCharacteristicInstance') {
                    if (baseEntity.entity.C_Material_key === item.key) {
                        baseEntity.entity.entityAspect.rejectChanges();
                    }
                }
            }

            if (item.entityName === 'Mating' &&
                baseEntity.entityName === 'MaterialPool' && 
                baseEntity.key === item.key
            ) {
                baseEntity.entity.entityAspect.rejectChanges();
            }
        }

        this.getWipSummary();
        if (this.wip.length === 0) {
            this.dataContext.cancel(false);
        }
    }

    closeModal() {
        this.activeModal.close('close');
    }
}

class ChangeItem {
    entity: any;
    key: any;
    entityName: string;
    displayName: string;
    isValid: string;
    changeDateTime: Date;
    description: string;
}
