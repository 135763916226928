import {
    Component,
    Input,
    OnInit,
    ViewChildren,
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { TaskService } from '../task.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { LocationService } from '../../locations/location.service';
import { TaskType } from '../models';

import { DataType } from '../../data-type/data-type.enum';

import {
    notEmpty,
    randomId,
} from '../../common/util/';

import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';
import { NgModel } from '@angular/forms';

@Component({
    selector: 'add-task',
    templateUrl: './add-task.component.html',
    styleUrls: ['./add-task.component.scss'],
})
export class AddTaskComponent implements OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() taskType: TaskType;

    // Vocabularies
    frequencies: any[];
    readonly FREQUENCY_DAILY: string = 'Daily';
    readonly FREQUENCY_WEEKDAY: string = 'Every weekday (Monday to Friday)';
    readonly FREQUENCY_MWF: string = 'Every Monday, Wednesday, and Friday';
    readonly FREQUENCY_TR: string = 'Every Tuesday and Thursday';
    readonly FREQUENCY_WEEKLY: string = 'Weekly';

    readonly FREQUENCY_DAILY_LABEL: string = 'days';
    readonly FREQUENCY_WEEKLY_LABEL: string = 'weeks';

    inputsApplyOptions: any[];
    readonly INPUTS_APPLY_FIRST = 'First occurrence';
    readonly INPUTS_APPLY_ALL = 'All occurrences';


    // State
    domIdAddition: string;
    workflowTaskKey: number;
    dateDue: Date;
    assignedToKey: number;
    frequency: string;
    frequencyLabel: string;
    interval: number;
    occurrences: number;
    inputsApply: string;
    inputs: any[];
    sampleGroups: any[] = [];
    hasInvalidInputs: boolean;
    hasRequiredInputs: boolean;
    canAddSampleGroups = false;

    // Default keys
    defaultPreservationMethodKey: string;
    defaultSampleStatusKey: string;
    defaultSampleTypeKey: string;
    defaultContainerTypeKey: string;
    defaultSampleSubtypeKey: string;
    defaultSampleProcessingMethodKey: string;
    defaultSampleAnalysisMethodKey: string;

    // CVs
    preservationMethods: any[];
    containerTypes: any[];
    sampleStatuses: any[];
    sampleTypes: any[];
    timeUnits: any[];
    sampleSubtypes: any[];
    sampleAnalysisMethods: any[];
    sampleProcessingMethods: any[];

    // Bulk update placeholders
    bulkNumSamples: number;
    bulkPreservationMethodKey: string;
    bulkContainerTypeKey: string;
    bulkSampleStatusKey: string;
    bulkSampleTypeKey: string;
    bulkDateHarvest: Date;
    bulkDateExpiration: Date;
    bulkTimePoint: number;
    bulkTimeUnitKey: string;
    bulkSampleSubtypeKey: string;
    bulkSampleProcessingMethodKey: string;
    bulkSendTo: string;
    bulkSampleAnalysisMethodKey: string;

    constructor(
        private activeModal: NgbActiveModal,
        private taskService: TaskService,
        private vocabularyService: VocabularyService,
        private locationService: LocationService,
        private loggingService: LoggingService,
    ) {
        //
    }

    ngOnInit() {
        // Vocabularies/options
        this.frequencies = [
            this.FREQUENCY_DAILY,
            this.FREQUENCY_WEEKDAY,
            this.FREQUENCY_MWF,
            this.FREQUENCY_TR,
            this.FREQUENCY_WEEKLY
        ];
        this.inputsApplyOptions = [
            this.INPUTS_APPLY_FIRST,
            this.INPUTS_APPLY_ALL
        ];

        this.domIdAddition = randomId();

        // Defaults
        this.getCVs();
        this.workflowTaskKey = null;
        this.dateDue = new Date();
        this.dateDue.setHours(0, 0, 0, 0);
        this.assignedToKey = null;
        this.frequency = null;
        this._ensureIntervalSet();
        this.setFrequencyLabel();
        this.occurrences = 3;
        this.inputsApply = this.INPUTS_APPLY_FIRST;
        this._resetInputs();
    }

    private _resetInputs() {
        this.inputs = [];
    }

    getCVs(): Promise<any> {
        const preferLocal = true;
        return Promise.all([
            this.vocabularyService.getCV(
                'cv_PreservationMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.preservationMethods = data;
            }),
            this.locationService.getContainerTypes('Sample').then((data) => {
                this.containerTypes = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleStatuses', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleStatuses = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleTypes', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleTypes = data;
            }),
            this.vocabularyService.getCV(
                'cv_TimeUnits', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.timeUnits = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleSubtypes', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleSubtypes = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleProcessingMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleProcessingMethods = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleAnalysisMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleAnalysisMethods = data;
            }),
            this.vocabularyService.getCVDefault(
                'cv_PreservationMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultPreservationMethodKey = value.C_PreservationMethod_key;
                }
            }),
            this.vocabularyService.getCVContainerTypeDefault(
                'Sample'
            ).then((value) => {
                if (value != null) {
                    this.defaultContainerTypeKey = value.C_ContainerType_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleStatuses', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleStatusKey = value.C_SampleStatus_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleTypes', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleTypeKey = value.C_SampleType_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleSubtypes', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleSubtypeKey = value.C_SampleSubtype_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleProcessingMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleProcessingMethodKey = value.C_SampleProcessingMethod_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleAnalysisMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleAnalysisMethodKey = value.C_SampleAnalysisMethod_key;
                }
            }),

        ]);
    }

    onTaskChange(): Promise<any> {
        this._resetInputs();

        if (this.workflowTaskKey > 0) {
            // ensure WorkflowTask is loaded
            return this.taskService.getTaskByKey(this.workflowTaskKey).then((task: any) => {
                this.canAddSampleGroups = this.taskType === TaskType.Job && (task.cv_TaskType.TaskType === 'Job' || task.cv_TaskType.TaskType === 'Study');
                return this.taskService.getTaskInputs(this.workflowTaskKey);
            }).then((data: any[]) => {
                this.inputs = data;
                this.resetInputValues();
                this.checkForInvalidInputs();
            });
        }
    }

    private _ensureIntervalSet(): void {
        if (!this.interval || this.interval < 1) {
            this.interval = 1;
        }
    }

    frequencyChanged(): void {
        this.setFrequencyLabel();
    }

    intervalInputAllowed(): boolean {
        return (this.frequency === this.FREQUENCY_DAILY ||
            this.frequency === this.FREQUENCY_WEEKLY
        );
    }

    setFrequencyLabel(): void {
        if (this.frequency === this.FREQUENCY_DAILY) {
            this.frequencyLabel = this.FREQUENCY_DAILY_LABEL;
        } else if (this.frequency === this.FREQUENCY_WEEKLY) {
            this.frequencyLabel = this.FREQUENCY_WEEKLY_LABEL;
        } else {
            this.frequencyLabel = "";
        }
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    onSubmit(): void {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }
        // Give UI events a chance to finish first
        setTimeout(() => {
            const taskInstances = this.getTaskInstances();
            this.activeModal.close(taskInstances);
        });
    }

    getTaskInstances(): any[] {
        // Check required values
        if (!this.workflowTaskKey || !this.dateDue) {
            return [];
        }

        const seedDateDue: Date = this.dateDue;
        const taskInputs: any[] = this._constructTaskInputs();
        const sampleGroups: any[] = this._constructSampleGroups();

        let taskInstances: any[] = [];
        if (this.frequency && this.occurrences && this.occurrences > 1) {
            // Repeating occurrences
            taskInstances = this._getRepeatingTaskInstances(seedDateDue, taskInputs, sampleGroups);
        } else {
            // Single occurrence
            const taskInstance = this._constructTaskInstance(seedDateDue, taskInputs, sampleGroups);
            taskInstances.push(taskInstance);
        }

        return taskInstances;
    }

    isRegularInput(input: any): boolean {
        return input.cv_DataType.DataType !== DataType.DOSING_TABLE &&
            input.cv_DataType.DataType !== DataType.JOB_CHARACTERISTIC;
    }

    isDosingTableInput(input: any): boolean {
        return input.cv_DataType.DataType === DataType.DOSING_TABLE;
    }

    isJobCharacteristicInput(input: any): boolean {
        return input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC;
    }

    /**
     * Add a new default SampleGroup row to the create modal
     */
    createAddRow() {
        this.sampleGroups.push({
            NumSamples: 1,
            C_SampleType_key: this.defaultSampleTypeKey,
            C_SampleStatus_key: this.defaultSampleStatusKey,
            C_PreservationMethod_key: this.defaultPreservationMethodKey,
            C_ContainerType_key: this.defaultContainerTypeKey,
            C_SampleSubtype_key: this.defaultSampleSubtypeKey,
            C_SampleProcessingMethod_key: this.defaultSampleProcessingMethodKey,
            C_SampleAnalysis_key: this.defaultSampleAnalysisMethodKey
        });
    }

    /**
     * Remove a SampleGroup row from the create modal
     *
     * @param index index of the reow to remove
     */
    createRemoveRow(index: number) {
        this.sampleGroups.splice(index, 1);
    }

    updateBulkNumSamples() {
        this.updateBulkValue('NumSamples', this.bulkNumSamples);
    }
    updateBulkPreservationMethodKey() {
        this.updateBulkValue('C_PreservationMethod_key', this.bulkPreservationMethodKey);
    }
    updateBulkContainerTypeKey() {
        this.updateBulkValue('C_ContainerType_key', this.bulkContainerTypeKey);
    }
    updateBulkSampleStatusKey() {
        this.updateBulkValue('C_SampleStatus_key', this.bulkSampleStatusKey);
    }
    updateBulkSampleTypeKey() {
        this.updateBulkValue('C_SampleType_key', this.bulkSampleTypeKey);
    }
    updateBulkDateHarvest() {
        this.updateBulkValue('DateHarvest', this.bulkDateHarvest);
    }
    updateBulkDateExpiration() {
        this.updateBulkValue('DateExpiration', this.bulkDateExpiration);
    }
    updateBulkTimePoint() {
        this.updateBulkValue('TimePoint', this.bulkTimePoint);
        this.updateBulkValue('C_TimeUnit_key', this.bulkTimeUnitKey);
    }
    updateBulkSampleSubtypeKey() {
        this.updateBulkValue('C_SampleSubtype_key', this.bulkSampleSubtypeKey);
    }
    updateBulkSampleProcessingMethodKey() {
        this.updateBulkValue('C_SampleProcessingMethod_key', this.bulkSampleProcessingMethodKey);
    }
    updateBulkSampleAnalysisMethodKey() {
        this.updateBulkValue('C_SampleAnalysisMethod_key', this.bulkSampleAnalysisMethodKey);
    }
    updateBulkSendTo() {
        this.updateBulkValue('SendTo', this.bulkSendTo);
    }

    // Bulk update handlers
    updateBulkValue(key: string, value: any) {
        // Update the rows in the Create Modal
        for (const row of this.sampleGroups) {
            row[key] = value;
        }
    }

    // <select> formatters
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }
    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }
    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
    preservationMethodKeyFormatter = (value: any) => {
        return value.C_PreservationMethod_key;
    }
    preservationMethodFormatter = (value: any) => {
        return value.PreservationMethod;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    timeUnitKeyFormatter = (value: any) => {
        return value.C_TimeUnit_key;
    }
    timeUnitFormatter = (value: any) => {
        return value.TimeUnit;
    }
    sampleSubtypeKeyFormatter = (value: any) => {
        return value.C_SampleSubtype_key;
    }
    sampleSubtypeFormatter = (value: any) => {
        return value.SampleSubtype;
    }
    sampleProcessingMethodKeyFormatter = (value: any) => {
        return value.C_SampleProcessingMethod_key;
    }
    sampleProcessingMethodFormatter = (value: any) => {
        return value.SampleProcessingMethod;
    }
    sampleAnalysisMethodKeyFormatter = (value: any) => {
        return value.C_SampleAnalysisMethod_key;
    }
    sampleAnalysisMethodFormatter = (value: any) => {
        return value.SampleAnalysisMethod;
    }

    private _getRepeatingTaskInstances(seedDateDue: Date, taskInputs: any[], sampleGroups: any[]): any[] {
        const taskInstances: any[] = [];
        let occurrenceNumber = 1;
        let dateAddend = 0;
        this._ensureIntervalSet();

        while (occurrenceNumber <= this.occurrences) {
            const newDateDue: Date = this._getNewDateDue(seedDateDue, dateAddend);

            // newDateDue has a value only if valid for the current frequency
            if (newDateDue) {

                // Include taskInputs based on inputsApply value
                let useTaskInputs: any[] = [];
                if (
                    this.inputsApply === this.INPUTS_APPLY_ALL ||
                    (this.inputsApply === this.INPUTS_APPLY_FIRST && occurrenceNumber === 1)
                ) {
                    useTaskInputs = taskInputs.slice();
                }
                const newTaskInstance = this._constructTaskInstance(newDateDue, useTaskInputs, sampleGroups);
                taskInstances.push(newTaskInstance);

                // Increment occurrences only if a TaskInstance was added
                occurrenceNumber++;
            }

            dateAddend++;
        }

        return taskInstances;
    }

    /**
     * Returns a new DateDue calculated from the seedDateDue and the dateAddend based on the
     * selected frequency. Returns null if the calculated date is invalid for the frequency
     * (e.g. a weekend day but a weekday frequency)
     *
     * @param seedDateDue
     * @param dateAddend
     */
    private _getNewDateDue(seedDateDue: Date, dateAddend: number): Date {
        let newDateDue: Date = null;

        if (this.frequency === this.FREQUENCY_WEEKLY) {
            newDateDue = this._addWeeks(seedDateDue, dateAddend * this.interval);
        } else if (this.frequency === this.FREQUENCY_WEEKDAY) {
            // Interval not used with weekday frequencies
            newDateDue = this._addDaysIfWeekday(seedDateDue, dateAddend);
        } else if (this.frequency === this.FREQUENCY_MWF) {
            // Interval not used with weekday frequencies
            newDateDue = this._addDaysIfMonWedOrFri(seedDateDue, dateAddend);
        } else if (this.frequency === this.FREQUENCY_TR) {
            // Interval not used with weekday frequencies
            newDateDue = this._addDaysIfTueOrThu(seedDateDue, dateAddend);
        } else {
            // Default to daily
            newDateDue = this._addDays(seedDateDue, dateAddend * this.interval);
        }

        return newDateDue;
    }

    private _addWeeks(dateIn: Date, weeks: number): Date {
        const dateMoment = convertValueToLuxon(dateIn);
        return dateMoment.plus({"weeks": weeks}).toJSDate();
    }

    /**
     * Adds a number of days to a date, but returns a value only if the new date is a weekday.
     *
     * @param dateIn
     * @param days
     */
    private _addDaysIfWeekday(dateIn: Date, days: number): Date {
        const dateOut = this._addDays(dateIn, days);

        if (this._isWeekday(dateOut)) {
            return dateOut;
        } else {
            return null;
        }
    }

    /**
     * Adds a number of days to a date, but returns a value only if the new date is a
     * Monday, Wednesday, or Friday.
     *
     * @param dateIn
     * @param days
     */
    private _addDaysIfMonWedOrFri(dateIn: Date, days: number): Date {
        const dateOut = this._addDays(dateIn, days);

        if (this._isMonWedOrFri(dateOut)) {
            return dateOut;
        } else {
            return null;
        }
    }

    /**
     * Adds a number of days to a date, but returns a value only if the new date is a
     * Tuesday or Thursday.
     *
     * @param dateIn
     * @param days
     */
    private _addDaysIfTueOrThu(dateIn: Date, days: number): Date {
        const dateOut = this._addDays(dateIn, days);

        if (this._isTueOrThu(dateOut)) {
            return dateOut;
        } else {
            return null;
        }
    }

    private _addDays(dateIn: Date, days: number): Date {
        const dateMoment = convertValueToLuxon(dateIn);
        return dateMoment.plus({"days": days}).toJSDate();
    }

    private _isWeekday(dateIn: Date): boolean {
        const dateMoment = convertValueToLuxon(dateIn);
        const dayOfWeek = dateMoment.weekday;

        return (dayOfWeek < 6);
    }

    private _isMonWedOrFri(dateIn: Date): boolean {
        const dateMoment = convertValueToLuxon(dateIn);
        const dayOfWeek = dateMoment.weekday;

        return (
            dayOfWeek === 1 ||
            dayOfWeek === 3 ||
            dayOfWeek === 5
        );
    }

    private _isTueOrThu(dateIn: Date): boolean {
        const dateMoment = convertValueToLuxon(dateIn);
        const dayOfWeek = dateMoment.weekday;

        return (
            dayOfWeek === 2 ||
            dayOfWeek === 4
        );
    }

    private _constructTaskInstance(dateDue: Date, taskInputs: any[], sampleGroups: any[]): any {
        return {
            C_AssignedTo_key: this.assignedToKey,
            C_WorkflowTask_key: this.workflowTaskKey,
            DateDue: dateDue,
            TaskInputs: taskInputs,
            SampleGroups: sampleGroups
        };
    }

    private _constructSampleGroups(): any[] {
        const sampleGroups: any[] = [];
        if (notEmpty(this.sampleGroups)) {
            for (const sampleGroup of this.sampleGroups) {
                sampleGroups.push(sampleGroup);
            }
        }

        return sampleGroups;
    }

    private _constructTaskInputs(): any[] {
        const taskInputs: any[] = [];

        if (notEmpty(this.inputs)) {
            for (const input of this.inputs) {
                // Only add the input if it has a value
                if (notEmpty(input.taskInputValue)) {
                    const taskInput = {
                        C_Input_key: input.C_Input_key,
                        InputValue: input.taskInputValue
                    };
                    taskInputs.push(taskInput);
                }
            }
        }

        return taskInputs;
    }

    checkForInvalidInputs() {
        this.hasRequiredInputs = false;
        this.hasInvalidInputs = false;

        for (const input of this.inputs) {
            if (input.IsRequired) {
                this.hasRequiredInputs = true;
            }
            if (input.IsRequired &&
                input.cv_DataType.DataType !== 'Boolean' &&
                !input.taskInputValue
            ) {
                this.hasInvalidInputs = true;
            }
        }
    }

    resetInputValues() {
        for (const input of this.inputs) {
            input.taskInputValue = null;
        }
    }
}
