import type { BirthSample } from './birth-sample.interface';
import type { Material } from './material.interface';
import type { SampleCharacteristicInstance } from './sample-characteristic-instance.interface';
import type { SampleConstruct } from './sample-construct.interface';
import type { SampleGroup } from './sample-group.interface';
import type { SampleOrder } from './sample-order.interface';
import type { cv_PreservationMethod } from './cv-preservation-method.interface';
import type { cv_SampleAnalysisMethod } from './cv-sample-analysis-method.interface';
import type { cv_SampleProcessingMethod } from './cv-sample-processing-method.interface';
import type { cv_SampleStatus } from './cv-sample-status.interface';
import type { cv_SampleSubtype } from './cv-sample-subtype.interface';
import type { cv_SampleType } from './cv-sample-type.interface';
import type { cv_TimeUnit } from './cv-time-unit.interface';
import type { cv_Unit } from './cv-unit.interface';

export interface Sample {
    BirthSample: BirthSample[];
    C_Material_key: number;
    C_PreservationMethod_key: number | null;
    C_SampleAnalysisMethod_key: number | null;
    C_SampleGroup_key: number | null;
    C_SampleOrder_key: number | null;
    C_SampleProcessingMethod_key: number | null;
    C_SampleStatus_key: number;
    C_SampleSubtype_key: number | null;
    C_SampleType_key: number;
    C_TimeUnit_key: number | null;
    C_Unit_key: number | null;
    C_Workgroup_key: number;
    CreatedBy: string;
    DateCreated: Date;
    DateExpiration: Date | null;
    DateHarvest: Date | null;
    DateModified: Date;
    Description: string;
    IsControl: boolean;
    LotNumber: string;
    Material: Material;
    ModifiedBy: string;
    SampleCharacteristicInstance: SampleCharacteristicInstance[];
    SampleConstruct: SampleConstruct[];
    SampleGroup: SampleGroup;
    SampleName: string;
    SampleNameSortable: string;
    SampleOrder: SampleOrder;
    SendTo: string;
    TimePoint: number | null;
    Version: number;
    Volume: number | null;
    cv_PreservationMethod: cv_PreservationMethod;
    cv_SampleAnalysisMethod: cv_SampleAnalysisMethod;
    cv_SampleProcessingMethod: cv_SampleProcessingMethod;
    cv_SampleStatus: cv_SampleStatus;
    cv_SampleSubtype: cv_SampleSubtype;
    cv_SampleType: cv_SampleType;
    cv_TimeUnit: cv_TimeUnit;
    cv_Unit: cv_Unit;
}
