import { DataType } from './../data-type/data-type.enum';
import { DosingTable } from '../dotmatics/dosing-table.enum';
import { map } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { DataManagerService } from '../services/data-manager.service';
import { CurrentWorkgroupService } from '../services/current-workgroup.service';
import { TaskService } from './task.service';
import { TaskVocabService } from './task-vocab.service';
import { TranslationService } from '../services/translation.service';
import { SaveChangesService, IValidatable } from '../services/save-changes.service';
import { PrivilegeService } from '../services/privilege.service';

import {
    BaseDetail,
    BaseDetailService,
    FacetView,
    IFacet,
    PageState
} from '../common/facet';

import {
    notEmpty,
    randomId,
    getSafeProp
} from '../common/util';

import { TaskOutputProperty } from './models';
import { WebApiService } from '../services/web-api.service';
import { JobService } from '../jobs/job.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { isNull } from 'lodash';
import { SettingService } from '../settings/setting.service';
import { Subscription } from 'rxjs';
import { TaskValidationService } from './task-validation.service';
import { ExpressionInputMapping } from '@common/types';
import { ActiveVocabSelectComponent } from '@common/active-vocab-select.component';
import { DotmaticsService } from '../dotmatics/dotmatics.service';

@Component({
    selector: 'task-detail',
    templateUrl: './task-detail.component.html',
    styles: [`
        .output-options-group {
            margin-top: 1.2em;
        }
        .output-options-group h5 {
            font-weight: 600;
            margin-bottom: 0.3em;
        }

        .task-select-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `]
})
export class TaskDetailComponent extends BaseDetail
    implements OnInit, OnChanges, OnDestroy, IValidatable {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() task: any;
    @Input() pageState: PageState;
    @Input() isReadOnly = false;
    @Input() hideDecimalPlaces = false;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() modelCopy: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('taskForm') taskForm: NgForm;
    @ViewChild('taskTypeSelect') taskTypeSelect: ActiveVocabSelectComponent;

    // expose enum to template
    DataType = DataType;

    // CVs
    dataTypes: any[] = [];
    enumerationClasses: any[] = [];
    taskTypes: any[] = [];
    vocabularyClasses: any[] = [];
    dosingTableFields: any[] = [];
    jobCharacteristicTypeFields: any[] = [];

    taskOutputProperties: TaskOutputProperty[];

    jobTypeKey = -1;
    animalTypeKey = -1;

    // State
    currentWorkgroupName = '';
    domIdAddition = '';

    readonly COMPONENT_LOG_TAG = 'task-detail';
    readonly HEALTHRECORD_TYPE = 'Health Record';
    readonly JOBTASK_TYPE = 'Job';

    readwrite: boolean;
    readonly: boolean;

    // Uses Jobs Classic (studies 1.0) feature flag
    useJobsClassic = false;

    // IsCRO Feature Flag
    isCRO = false;
    isDTX = false;

    // task type permission
    hasTaskInstance = false;

    canEditInputsAndOutputsForUsedTasks = false;

    messageDisabledAutomaticallyEndTask: string;
    hasAutomaticallyEndTask = false;

    // Active and required fields set by facet settings
    activeFields: string[] = [];
    requiredFields: string[] = [];

    originalTaskTypeKey: number;
    hideDosingTableAndJobCharacteristicDataType = false;
    hideDosingTableDataType = false;

    saveEventSubscriptions: Subscription[] = [];

    constructor(
        private baseDetailService: BaseDetailService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private taskService: TaskService,
        private taskVocabService: TaskVocabService,
        private translationService: TranslationService,
        private saveChangesService: SaveChangesService,
        private privilegeService: PrivilegeService,
        private webApiService: WebApiService,
        private jobService: JobService,
        private featureFlagService: FeatureFlagService,
        private settingService: SettingService,
        private taskValidationService: TaskValidationService,
        private _dataManager: DataManagerService,
        private dotmaticsService: DotmaticsService
    ) {
        super(baseDetailService);
    }

    /**
     * Sets privilege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    // lifecycle
    ngOnInit() {
        this.saveChangesService.registerValidator(this);
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.task) {
            if (this.task && !changes.task.firstChange) {
                if (this.taskForm) {
                    this.taskForm.form.markAsPristine();
                }
                this.initialize();
            }
        }
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);
    }

    async initialize(): Promise<void> {
        this.messageDisabledAutomaticallyEndTask = 'This option only applies to ' + this.translationService.translate('job') + '-type tasks with materials.' +
            'When enabled, Climb prompts the user to set future tasks statuses to their default automatic end state for animals assigned an end state status.';

        const response = await this.getEditInputsAndOutputsForUsedTasks();
        if (response === true) {
            this.canEditInputsAndOutputsForUsedTasks = true;
        } else {
            const isAssociatedWithWorkflowData = await this.taskService.isTaskAssociatedWithWorkflowData(this.task);
            this.canEditInputsAndOutputsForUsedTasks = !isAssociatedWithWorkflowData;
        }

        this.currentWorkgroupName = this.currentWorkgroupService.getWorkgroupName();
        this.domIdAddition = randomId();
        this.initTaskOutputProperties();
        this.setPrivileges();
        this.hasAutomaticallyEndTask = this.jobService.getIsCroFlag() || !this.jobService.getIsClassicJobOnlyFlag();
        this.initUseJobsClassic();
        this.initIsCRO();
        this.initIsDTX();

        const facetSettings = await this.settingService.getFacetSettingsByType('task');
        this.activeFields = this.settingService.getActiveFields(facetSettings);
        this.requiredFields = this.settingService.getRequiredFields(facetSettings);

        await this.getCVs();

        const jobType = this.taskTypes.filter(type => type.TaskType.toLowerCase() === 'job');
        this.jobTypeKey = jobType[0].C_TaskType_key;

        const animalType = this.taskTypes.filter(type => type.TaskType.toLowerCase() === 'animal');
        this.animalTypeKey = animalType[0].C_TaskType_key;

        this.originalTaskTypeKey = this.task.cv_TaskType.C_TaskType_key;
        await this.getDetails();
    }

    private getEditInputsAndOutputsForUsedTasks(): Promise<any> {
        const url = 'api/task/getEditInputsAndOutputsForUsedTasks';
        return this.webApiService.callApi(url).then((response) => {
            return response.data;
        });
    }

    private initUseJobsClassic() {
        const flag = this.featureFlagService.getFlag("UseJobsClassic");
        this.useJobsClassic = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    private initIsCRO() {
        const flag = this.featureFlagService.getFlag("IsCRO");
        this.isCRO = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    private initIsDTX() {
        this.isDTX = this.dotmaticsService.setIsDotmatics();
    }

    private initTaskOutputProperties() {
        this.taskOutputProperties = [
            <TaskOutputProperty>
            {
                name: 'Birth Date',
                fieldName: 'ShowBirthDate'
            },
            <TaskOutputProperty>
            {
                name: 'Age (weeks)',
                fieldName: 'ShowAgeInWeeks'
            },
            <TaskOutputProperty>
            {
                name: 'Age (days)',
                fieldName: 'ShowAgeInDays'
            },
            <TaskOutputProperty>
            {
                name: 'Marker',
                fieldName: 'ShowMarker'
            },
            <TaskOutputProperty>
            {
                name: 'Animal Status',
                fieldName: 'ShowAnimalStatus'
            },
            <TaskOutputProperty>
            {
                name: 'Housing ID',
                fieldName: 'ShowHousingID'
            },
            <TaskOutputProperty>
            {
                name: 'Animal Comments',
                fieldName: 'ShowAnimalComments'
            }
        ];
    }

    private getCVs(): Promise<any> {
        const p1 = this.taskVocabService.dataTypes$.pipe(map((data) => {
            if (data) {
                data = data.filter((item) => {
                    return item.ShowInWorkflow;
                });
            }
            this.dataTypes = data;
        })).toPromise();
        const p2 = this.taskVocabService.enumerationClasses$.pipe(map((data) => {
            this.enumerationClasses = data;
        })).toPromise();
        const p3 = this.taskVocabService.taskTypes$.pipe(map((data) => {
            this.taskTypes = data;
        })).toPromise();
        const p4 = this.taskVocabService.dosingTableFields$.pipe(map((data) => {
            if (!this.isDTX) {
                this.dosingTableFields = data.filter(dosingTableField => dosingTableField.DosingTable !== DosingTable.CONCENTRATION)
            }
            else {
                this.dosingTableFields = data
            }
        })).toPromise();
        const p5 = this.taskVocabService.jobCharacteristicTypeFields$.pipe(map((data) => {
            this.jobCharacteristicTypeFields = data;
        })).toPromise();
        this.vocabularyClasses = [
            {
                C_VocabularyClass_key: 1,
                ClassName: "Test Article"
            },
            {
                C_VocabularyClass_key: 2,
                ClassName: "IACUC Protocol"
            }
        ];
        return Promise.all([p1, p2, p3, p4, p5]);
    }

    private getDetails(): Promise<any> {
        if (this.task && this.task.C_WorkflowTask_key > 0) {
            this.setLoading(true);

            const p1 = this.taskService.getTaskInputs(this.task.C_WorkflowTask_key).then(() => {
                this.updateAllInputMappings();
            });

            const p2 = this.taskService.getTaskOutputs(this.task.C_WorkflowTask_key).then(() => {
                this.updateAllOutputMappings();
            });

            const p3 = this.taskService.isTaskAssociatedWithWorkflowData(this.task).then((isAssociatedWithWorkflowData: boolean) => {
                this.hasTaskInstance = isAssociatedWithWorkflowData;
            });

            return Promise.all([p1, p2, p3]).then(() => {
                this.setLoading(false);
                return this.task;
            }).catch((error: any) => {
                this.setLoading(false);
                console.error(error);
            });
        }

        return Promise.resolve(this.task);
    }

    onCancel() {
        this.taskService.cancelWorkflowTask(this.task);
    }

    isTaskEditable(task: any): boolean {
        if (!task) {
            return false;
        }

        // Currently disable editing on Health Record tasks
        if (task.cv_TaskType &&
            task.cv_TaskType.TaskType === this.HEALTHRECORD_TYPE
        ) {
            return false;
        }

        return task.IsEditable !== false;
    }

    moveInputUp(input: any) {
        for (const taskInput of this.task.Input) {
            if (taskInput.SortOrder === (input.SortOrder - 1)) {
                taskInput.SortOrder = input.SortOrder;
            }
        }
        input.SortOrder = input.SortOrder - 1;
    }

    moveInputDown(input: any) {
        for (const taskInput of this.task.Input) {
            if (taskInput.SortOrder === (input.SortOrder + 1)) {
                taskInput.SortOrder = input.SortOrder;
            }
        }
        input.SortOrder = input.SortOrder + 1;
    }

    moveOutputUp(output: any) {
        for (const taskOutput of this.task.Output) {
            if (taskOutput.SortOrder === (output.SortOrder - 1)) {
                taskOutput.SortOrder = output.SortOrder;
            }
        }
        output.SortOrder = output.SortOrder - 1;
    }

    moveOutputDown(output: any) {
        for (const taskOutput of this.task.Output) {
            if (taskOutput.SortOrder === (output.SortOrder + 1)) {
                taskOutput.SortOrder = output.SortOrder;
            }
        }
        output.SortOrder = output.SortOrder + 1;
    }

    addInput(task: any) {
        if (!this.isTaskEditable(task)) {
            return;
        }

        if (task.Input.length >= 30) {
            this.showLimitReachedError();
            return;
        }

        const initialValues = {
            C_WorkflowTask_key: task.C_WorkflowTask_key,
            SortOrder: task.Input.length + 1,
            IsActive: true
        };
        const newInput = this.taskService.createInput(initialValues);
        newInput.C_DataType_key = null;
        this.updateAllInputMappings();
    }

    addOutput(task: any) {
        if (!this.isTaskEditable(task)) {
            return;
        }

        if (task.Output.length >= 75) {
            this.showLimitReachedError();
            return;
        }

        const initialValues = {
            C_WorkflowTask_key: task.C_WorkflowTask_key,
            SortOrder: task.Output.length + 1,
            IsActive: true
        };
        const newOutput = this.taskService.createOutput(initialValues);
        newOutput.C_DataType_key = null;
        this.updateAllOutputMappings();
    }

    removeInput(input: any) {
        if (!this.isTaskEditable(input.WorkflowTask)) {
            return;
        }
        this.taskService.deleteInputSafe(input).then((isSafe) => {
            if (isSafe) {
                this._deleteInput(input);
                this.task.Input = this.task.Input.sort((a: any, b: any) => a.SortOrder - b.SortOrder);
                for (let i = 0; i < this.task.Input.length; i++) {
                    this.task.Input[i].SortOrder = i + 1;
                }
            } else {
                const message = 'Cannot delete input: it is associated with workflow data.';
                const showToast = true;

                this.loggingService.logWarning(
                    message,
                    null,
                    this.COMPONENT_LOG_TAG,
                    showToast
                );
            }
        });
    }

    private _deleteInput(input: any) {
        this.taskService.deleteInput(input);
        this.updateAllInputMappings();
    }

    removeOutput(output: any) {
        if (!this.isTaskEditable(output.WorkflowTask)) {
            return;
        }
        this.taskService.isOutputAssociatedWithWorkflowData(output).then((isAssociatedWithWorkflowData) => {
            if (!isAssociatedWithWorkflowData) {
                this.taskService.isOutputAssociatedWithInheritedOutputs(output).then((isAssociatedWithInheritedOutputs: boolean) => {
                    if (!isAssociatedWithInheritedOutputs) {
                        this._deleteOutput(output);
                        this.task.Output = this.task.Output.sort((a: any, b: any) => a.SortOrder - b.SortOrder);
                        for (let i = 0; i < this.task.Output.length; i++) {
                            this.task.Output[i].SortOrder = i + 1;
                        }
                    } else {
                        this.loggingService.logWarning(
                            'Cannot delete output: the output is inherited into at least one other output.',
                            null,
                            this.COMPONENT_LOG_TAG,
                            true
                        );
                    }
                });
            } else {
                const message = 'Cannot delete output: it is associated with existing data.';
                const showToast = true;

                this.loggingService.logWarning(
                    message,
                    null,
                    this.COMPONENT_LOG_TAG,
                    showToast
                );
            }
        });
    }

    private _deleteOutput(output: any) {
        this.taskService.deleteOutput(output);
        this.updateAllOutputMappings();
    }

    getInputDataTypes(): any {
        if (!this.dataTypes) {
            return [];
        }

        this.hideDosingTableAndJobCharacteristicDataType = this.task.C_TaskType_key !== this.jobTypeKey;
        this.hideDosingTableDataType = this.task.NoMaterials;

        let filteredTypes = this.dataTypes.filter((dataType) => {
            if (this.isCRO) {
                return dataType.DataType !== DataType.CALCULATED
                    && dataType.DataType !== DataType.INHERITED_MOST_RECENT
                    && dataType.DataType !== DataType.INHERITED_FIRST_OCCURRENCE
                    && dataType.DataType !== DataType.INHERITED_SECOND_MOST_RECENT
                    && dataType.DataType !== DataType.INHERITED_THIRD_MOST_RECENT;
            } else {
                return dataType.DataType !== DataType.CALCULATED
                    && dataType.DataType !== DataType.INHERITED_MOST_RECENT
                    && dataType.DataType !== DataType.INHERITED_FIRST_OCCURRENCE
                    && dataType.DataType !== DataType.INHERITED_SECOND_MOST_RECENT
                    && dataType.DataType !== DataType.INHERITED_THIRD_MOST_RECENT
                    && dataType.DataType !== DataType.DOSING_TABLE
                    && dataType.DataType !== DataType.JOB_CHARACTERISTIC;
            }
        });

        if (this.hideDosingTableAndJobCharacteristicDataType) {
            filteredTypes = filteredTypes.filter((dataType) => {
                return dataType.DataType !== DataType.DOSING_TABLE &&
                    dataType.DataType !== DataType.JOB_CHARACTERISTIC;
            });
        }

        if (this.hideDosingTableDataType) {
            filteredTypes = filteredTypes.filter((dataType) => {
                return dataType.DataType !== DataType.DOSING_TABLE;
            });
        }

        return filteredTypes;
    }

    getOutputDataTypes(): any {
        if (this.dataTypes) {
            return this.dataTypes.filter((dataType) => {
                return dataType.DataType !== DataType.DOSING_TABLE &&
                    dataType.DataType !== DataType.JOB_CHARACTERISTIC;
            });
        } else {
            return [];
        }
    }

    emptyArray: any[] = [];

    getOutputMappings(output: any): any[] {
        this.updateOutputMappings(output);

        const calcOutExpr = this.getCalculatedOutputExpression(output);
        if (calcOutExpr) {
            return [...calcOutExpr.ExpressionOutputMapping];
        }

        return this.emptyArray;
    }

    getInputMappings(output: Output): ExpressionInputMapping[] {
        this.updateInputMappings(output);

        const calcOutExpr = this.getCalculatedOutputExpression(output);
        if (calcOutExpr) {
            const numericInputs: ExpressionInputMapping[] = calcOutExpr.ExpressionInputMapping.filter((expressionInputMapping: ExpressionInputMapping) => {
                if (expressionInputMapping.Input.cv_DataType.DataType === DataType.DOSING_TABLE) {
                    const dosingTable = expressionInputMapping.Input.cv_DosingTable?.DosingTable;
                    return dosingTable !== 'Route' && dosingTable !== 'Treatment';
                }
                return true;
            });
            return numericInputs;
        }

        return this.emptyArray;
    }

    expressionChanged(taskOutput: any, expression: any) {

        if (!this.isTaskEditable(this.task)) {
            return;
        }

        const calcExprArr = taskOutput.CalculatedOutputExpression;
        let calcExpr = null;
        const createNeeded = calcExprArr.length === 0;
        if (createNeeded) {
            calcExpr = this.taskService.createCalculatedOutputExpression({
                C_Output_key: taskOutput.C_Output_key
            });
        } else {
            calcExpr = calcExprArr[0];
        }

        calcExpr.OutputExpression = JSON.stringify(expression);

        if (createNeeded) {
            this.updateInputMappings(taskOutput);
            this.updateOutputMappings(taskOutput);
        }
    }

    getExpressionJson(taskOutput: any) {
        const calcExprArr = taskOutput.CalculatedOutputExpression;
        if (calcExprArr.length === 0) {
            return null;
        } else {
            return calcExprArr[0].OutputExpression;
        }
    }

    outputDataTypeChange(output: any) {
        // for a 'Calculated' type we may need to initialize the
        // CalculatedOutputExpression if it hasn't already been
        // done
        if (output.cv_DataType) {
            const typeStr = output.cv_DataType.DataType;
            if (typeStr === 'Calculated') {
                const calcExprArr = output.CalculatedOutputExpression;
                if (calcExprArr.length === 0) {
                    this.taskService.createCalculatedOutputExpression({
                        C_Output_key: output.C_Output_key
                    });
                }
            }
        }

        // It is the way to mark as deleted ALL OutputFlags. In case of usual forEach only the first one will be deleted
        while (output.OutputFlag.length > 0) {
            this._dataManager.deleteEntity(output.OutputFlag[0]);
        }
    }

    requiresValidationChange(taskInput: any) {
        if (taskInput.RequiresValidation) {
            taskInput.IsRequired = true;
        }
    }

    cohortStatsFlagIsCheckedChange(taskOutput: any, hasFlag: boolean) {
        if (!hasFlag && taskOutput.HasCohortStatsFlag) {
            taskOutput.HasCohortStatsFlag = hasFlag;
            taskOutput.AverageFlagMinimum = null;
            taskOutput.AverageFlagMaximum = null;
            taskOutput.MedianFlagMinimum = null;
            taskOutput.MedianFlagMaximum = null;
            taskOutput.StdDevFlagMinimum = null;
            taskOutput.StdDevFlagMaximum = null;
        }
    }

    avgFlagMaximumChange(taskOutput: any, maxValue: number) {
        taskOutput.AverageFlagMaximum = maxValue;
        taskOutput.HasCohortStatsFlag = true;
    }

    avgFlagMinimumChange(taskOutput: any, minValue: number) {
        taskOutput.AverageFlagMinimum = minValue;
        taskOutput.HasCohortStatsFlag = true;
    }

    mdnFlagMaximumChange(taskOutput: any, maxValue: number) {
        taskOutput.MedianFlagMaximum = maxValue;
        taskOutput.HasCohortStatsFlag = true;
    }

    mdnFlagMinimumChange(taskOutput: any, minValue: number) {
        taskOutput.MedianFlagMinimum = minValue;
        taskOutput.HasCohortStatsFlag = true;
    }

    stDevFlagMaximumChange(taskOutput: any, maxValue: number) {
        taskOutput.StdDevFlagMaximum = maxValue;
        taskOutput.HasCohortStatsFlag = true;
    }

    stDevFlagMinimumChange(taskOutput: any, minValue: number) {
        taskOutput.StdDevFlagMinimum = minValue;
        taskOutput.HasCohortStatsFlag = true;
    }

    private updateAllInputMappings() {
        if (notEmpty(this.task.Output)) {
            for (const taskOutput of this.task.Output) {
                this.updateInputMappings(taskOutput);
            }
        }
    }

    private updateAllOutputMappings() {
        if (notEmpty(this.task.Output)) {
            for (const taskOutput of this.task.Output) {
                this.updateOutputMappings(taskOutput);
            }
        }
    }

    private updateInputMappings(output: any) {
        const calcOutExpr = this.getCalculatedOutputExpression(output);
        if (!calcOutExpr) {
            return [];
        }

        const inIdSet = this.numericInputIdSet();

        // Remove any mappings that point to inputs which no
        // longer exist.
        //
        // We use slice to make a shallow copy of the
        // mappings because element deletion will modify
        // the array size (and we don't want to do that
        // while iterating)
        const mappingsCopy = calcOutExpr.ExpressionInputMapping.slice();
        for (const mapping of mappingsCopy) {
            if (!inIdSet[mapping.C_Input_key]) {
                this.taskService.deleteInputMapping(mapping);
            }
        }

        // now add any input mappings that are missing
        const inMappingIdSet = this.inputMappingsIdSet(calcOutExpr.ExpressionInputMapping);
        const inVarNameSet = this.variableMappingsNameSet(calcOutExpr.ExpressionInputMapping);
        if (notEmpty(this.task.Input)) {
            this.task.Input.filter(this.isNumeric).forEach((currInput: any) => {
                if (inMappingIdSet[currInput.C_Input_key]) {
                    return;
                }

                const inMapping = this.taskService.createInputMapping(calcOutExpr, currInput);

                // find a name that has not already been taken
                let currName = null;
                const currNameIndex = calcOutExpr.ExpressionInputMapping.length;
                do {
                    currName = `input_${currNameIndex}`;
                } while (inVarNameSet[currName]);
                inVarNameSet[currName] = true;

                inMapping.ExpressionVariableName = currName;
            });
        }

        return calcOutExpr.ExpressionInputMapping;
    }

    private updateOutputMappings(output: any) {
        const calcOutExpr = this.getCalculatedOutputExpression(output);
        if (!calcOutExpr) {
            return [];
        }

        const outIdSet = this.numericOutputIdSet();

        // Remove any mappings that point to outputs which no
        // longer exist.
        //
        // We use slice to make a shallow copy of the
        // mappings because element deletion will modify
        // the array size (and we don't want to do that
        // while iterating)
        const mappingsCopy = calcOutExpr.ExpressionOutputMapping.slice();
        for (const mapping of mappingsCopy) {
            if (!outIdSet[mapping.C_Output_key]) {
                this.taskService.deleteOutputMapping(mapping);
            }
        }

        // now add any output mappings that are missing
        const outMappingIdSet = this.outputMappingsIdSet(calcOutExpr.ExpressionOutputMapping);
        const outVarNameSet = this.variableMappingsNameSet(calcOutExpr.ExpressionOutputMapping);
        if (notEmpty(this.task.Output)) {
            this.task.Output.filter(this.isNumeric).forEach((currOutput: any) => {
                if (currOutput.C_Output_key !== output.C_Output_key) {
                    if (outMappingIdSet[currOutput.C_Output_key]) {
                        return;
                    }

                    const outMapping = this.taskService.createOutputMapping(calcOutExpr, currOutput);

                    // find a name that has not already been taken
                    let currName = null;
                    let currNameIndex = calcOutExpr.ExpressionOutputMapping.length;
                    currName = `output_${currNameIndex}`;
                    while (outVarNameSet[currName]) {
                        currNameIndex++;
                        currName = `output_${currNameIndex}`;
                    }
                    outVarNameSet[currName] = true;

                    outMapping.ExpressionVariableName = currName;
                }
            });
        }

        return calcOutExpr.ExpressionOutputMapping;
    }


    /**
     * Generate an associative array indicating which variable names exist
     * for fast lookup
     * @param {any} variableMappings
     */
    private variableMappingsNameSet(variableMappings: any[]): any {
        const varNameSet = {};
        if (variableMappings) {
            for (const variableMapping of variableMappings) {
                varNameSet[variableMapping.ExpressionVariableName] = true;
            }
        }

        return varNameSet;
    }


    /**
     * Convenience function for pulling the calculated exp out of an
     * ouput in the case that it exists and returning null otherwise.
     */
    private getCalculatedOutputExpression(output: any): any {
        if (output.cv_DataType) {
            const typeStr = output.cv_DataType.DataType;
            if (typeStr === 'Calculated') {
                if (output.CalculatedOutputExpression.length >= 1) {
                    return output.CalculatedOutputExpression[0];
                }
            }
        }

        return null;
    }

    private numericInputIdSet(): any {
        const inIdSet = {};
        if (this.task && notEmpty(this.task.Input)) {
            this.task.Input.filter(this.isNumeric).forEach((input: any) => {
                inIdSet[input.C_Input_key] = true;
            });
        }

        return inIdSet;
    }

    private numericOutputIdSet(): any {
        const outIdSet = {};
        if (this.task && notEmpty(this.task.Output)) {
            this.task.Output.filter(this.isNumeric).forEach((output: any) => {
                outIdSet[output.C_Output_key] = true;
            });
        }

        return outIdSet;
    }

    private inputMappingsIdSet(inputMappings: any[]): any {
        const inIdSet = {};
        if (inputMappings) {
            for (const inputMapping of inputMappings) {
                inIdSet[inputMapping.C_Input_key] = true;
            }
        }

        return inIdSet;
    }

    private outputMappingsIdSet(outputMappings: any[]): any {
        const outIdSet = {};
        if (outputMappings) {
            for (const outputMapping of outputMappings) {
                outIdSet[outputMapping.C_Output_key] = true;
            }
        }

        return outIdSet;
    }

    private isNumeric(inputOrOutput: any): boolean {
        const dataType = getSafeProp(inputOrOutput, 'cv_DataType.DataType');
        if (dataType === DataType.INHERITED_MOST_RECENT ||
            dataType === DataType.INHERITED_FIRST_OCCURRENCE ||
            dataType === DataType.INHERITED_SECOND_MOST_RECENT ||
            dataType === DataType.INHERITED_THIRD_MOST_RECENT
        ) {
            const inheritedDataType = getSafeProp(
                inputOrOutput,
                'InheritedFromOutput.cv_DataType.DataType'
            );
            return inheritedDataType === DataType.NUMBER
                || inheritedDataType === DataType.CALCULATED
                || inheritedDataType === DataType.DOSING_TABLE;
        }

        return dataType === DataType.NUMBER || dataType === DataType.CALCULATED || dataType === DataType.DOSING_TABLE;
    }

    async validate(): Promise<string> {
        if (!this.task.TaskName) {
            return 'A Task requires a Name.';
        }

        if (!notEmpty(this.task.C_TaskType_key)) {
            return `A Task requires a Type.`;
        }

        if (notEmpty(this.task.Output)) {
            for (const taskOutput of this.task.Output) {
                if (!isNull(taskOutput.FlagMinimum) && !isNull(taskOutput.FlagMaximum) && taskOutput.FlagMinimum > taskOutput.FlagMaximum) {
                    return 'Output Flag values not applicable.';
                }
            }
        }

        const taskInputsValid = this.task.Input?.every((taskInput: any) => this.taskValidationService.isValidTaskInput(taskInput));
        const taskOutputsValid = this.task.Output?.every((taskOutput: any) => this.taskValidationService.isValidTaskOutput(taskOutput));

        if (!taskInputsValid) {
            return 'Ensure that all required fields within Inputs are filled.';
        }

        if (!taskOutputsValid) {
            return 'Ensure that all required fields within Outputs are filled.';
        }

        return await this.settingService.validateRequiredFields(this.requiredFields, this.task, 'task');
    }

    copyTask() {
        this.saveChangesService.promptForUnsavedChanges(this.COMPONENT_LOG_TAG).then(() => {
            const fromTask = this.task;

            // Copy from current task to a newly created task
            const toTask = this.taskService.createTask();

            // Copy values
            this.taskService.copyTask(fromTask, toTask);

            this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false).then(() => {
                // Set inherited output value (this logic ASSUMES that toTask Outputs were copied in same order as fromTask Outputs)
                const outputKeyMap: any = {};
                for (let i = 0; i < fromTask.Output.length; i++) {
                    outputKeyMap[fromTask.Output[i].C_Output_key] = toTask.Output[i].C_Output_key;
                }
                for (let i = 0; i < fromTask.Output.length; i++) {
                    const fromOutput = fromTask.Output[i];
                    if (fromOutput.C_InheritedFromOutput_key && this.isOutputInheritedWithinTask(fromOutput, fromTask.Output)) {
                        toTask.Output[i].C_InheritedFromOutput_key = outputKeyMap[fromOutput.C_InheritedFromOutput_key];
                    } else {
                        toTask.Output[i].C_InheritedFromOutput_key = fromOutput.C_InheritedFromOutput_key;
                    }
                }
                this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false).then(() => {
                    // Set the current task to the newly created task
                    this.task = toTask;
                    this.modelCopy.emit(this.task);
                });
            }).catch(e => {
                if (toTask) {
                    this.loggingService.logDebug(
                        'New task copy state should be reverted locally because of error',
                        null,
                        this.COMPONENT_LOG_TAG
                    );
                    this.taskService.cancelWorkflowTask(toTask);
                    this.loggingService.logDebug(
                        'New task copy state was reverted locally because of error',
                        null,
                        this.COMPONENT_LOG_TAG
                    );
                }
            });
        });
    }

    // Check if an output is inherited from an output in the same task
    isOutputInheritedWithinTask(output: any, outputs: any): boolean {
        if (outputs.find((el: any) => el.C_Output_key === output.C_InheritedFromOutput_key)) {
            return true;
        }
        return false;
    }

    /**
     * Sets the selectedOutput model from the nested component's output event.
     *
     * @param currentOutput
     * @param output
     */
    onSelectedOutputChange(currentOutput: any, output: any) {
        if (output) {
            currentOutput.C_InheritedFromOutput_key = output.C_Output_key;
        }
    }


    // formatters for <select> inputs
    taskTypeKeyFormatter = (value: any) => {
        return value.C_TaskType_key;
    }
    taskTypeFormatter = (value: any) => {
        return this.translationService.translate(value.TaskType);
    }

    showLimitReachedError() {
        const errMsg = "Maximum input or output limit reached.";
        this.loggingService.logWarning(errMsg, null, this.COMPONENT_LOG_TAG, true);
    }

    typeChanged(task: any) {
        if (this.hasInputWithDosingTablesOrJobCharacteristics()) {
            // undo change
            this.loggingService.logWarning(
                `Please delete all dosing table or ${this.translationService.translate('job').toLowerCase()} characteristic inputs before changing task type.`,
                null,
                this.COMPONENT_LOG_TAG,
                true);
            this.task.C_TaskType_key = this.originalTaskTypeKey;
            setTimeout(() => this.taskTypeSelect.model = this.originalTaskTypeKey, 0);
        } else {
            this.originalTaskTypeKey = task.C_TaskType_key;
            if (this.jobTypeKey !== task.C_TaskType_key) {
                task.NoMaterials = false;
            }
            if (this.hasAutomaticallyEndTask && task.C_TaskType_key !== this.jobTypeKey) {
                task.AutomaticallyEndTask = false;
            }
        }
    }

    noMaterialsChanged(task: any) {
        if (task.NoMaterials) {
            if (this.hasInputWithDosingTables()) {

                // undo change
                this.loggingService.logWarning(
                    'Please delete all dosing table inputs before changing material status.',
                    null,
                    this.COMPONENT_LOG_TAG,
                    true);
                this.task.NoMaterials = false;
                $("#noMaterialsCheckbox").prop('checked', false);
            } else {
                if (this.hasAutomaticallyEndTask && task.NoMaterials) {
                    task.AutomaticallyEndTask = false;
                }
            }
        }
    }

    private hasInputWithDosingTablesOrJobCharacteristics(): boolean {
        const inputs = this.task.Input.filter((item: any) => {
            return item.cv_DataType.DataType === "Dosing Table" || item.cv_DataType.DataType === "Job Characteristic";
        });
        return inputs.length > 0;
    }

    private hasInputWithDosingTables(): boolean {
        const inputs = this.task.Input.filter((item: any) => {
            return item.cv_DataType.DataType === "Dosing Table";
        });
        return inputs.length > 0;
    }
}
