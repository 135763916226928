import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet';

import {
    notEmpty,
} from '../../../common/util';

import { DataType } from '../../../data-type/data-type.enum';

import { DataManagerService } from '../../../services/data-manager.service'; // TODO: Remove
import { JobPharmaDetailService } from '../job-pharma-detail.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { Entity, ExtendedJob, TaskInstance } from '@common/types';

interface DataRow {
    task: any;
    cohort?: any;
    placeholder?: any;
    materials?: any[];
    inputs?: any[];
    taskFirst?: boolean;

    childInputs?: { [key: string]: any[] };
    classes?: {
        [key: string]: { [key: string]: boolean };
    };
}

@Component({
    selector: 'job-pharma-tasks-inputs-table',
    templateUrl: './job-pharma-tasks-inputs-table.component.html',
})
export class JobPharmaTasksInputsTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() readonly: boolean;
    @Input() job: Entity<ExtendedJob>;

    @Input() tabset: string = 'tasks';
    @Input() tab: string = 'inputs';
    @Input() isStudyDirector: boolean;
    taskPage: number = 1;

    // Non-member tasks
    tasks: TaskInstance[] = [];

    // Rows to show in the table
    rows: any[] = [];

    // All subscriptions
    subs = new Subscription();

    // Column selections
    columnSelect: ColumnSelect = {
        model: [],
        labels: [],
    };

    // Visible columns
    visible: any = {};

    // Bulk Input form
    bulkInputTasks: any[] = [];
    bulkInputTask: any;
    bulkInputTaskAliases: string[] = [];
    bulkInputTaskAlias: string;
    bulkInputCohorts: any[] = [];
    bulkInputCohort: any;
    bulkInputInputs: any[] = [];
    bulkInputInput: any = null;
    bulkInputValue: any = null;

    allLocked: boolean = false;

    readonly COMPONENT_LOG_TAG = 'job-pharma-tasks-inputs-table';

    loading: boolean = false;
    loadingMessage: string = "Loading";

    constructor(
        private dataManager: DataManagerService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private vocabularyService: VocabularyService,
    ) {
        // Do nothing
    }

    async ngOnInit() {
        this.loading = true;
        this.initChangeDetection();
        this.initColumnSelect();
        await this.initialize();
        this.loading = false;
    }

    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            this.initJob();
        }
    }

    ngOnDestroy() {
        // Clear all the subscriptions
        this.subs.unsubscribe();
    }

    async initialize(): Promise<any> {
        await this.getCVs();
        await this.initJob();
    }

    getCVs(): Promise<any> {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_DataTypes'),
            this.vocabularyService.ensureCVLoaded('cv_DosingTables'),
        ]);
    }

    allLockedChanged() {
        // locked or unlocked all the rows
        if (this.tasks) {
            for (let task of this.tasks) {
                task.IsLocked = this.allLocked;
            }
        }
    }

    isLockedChanged() {
        // Check if all the rows are locked
        this.allLocked = this.tasks.every((task) => task.IsLocked);
    }

    /**
     * Watch for external changes
     */
    initChangeDetection() {
        // Also watch for changes to Job.TaskJob
        this.subs.add(this.jobPharmaDetailService.jobTasksChanged$.subscribe(() => {
            this.initJob();
        }));
    }

    /**
     * Initialize the column selections
     */
    initColumnSelect() {
        // Default Visibility
        this.visible = {
            protocol: true,
            taskAlias: true,
            materials: true,
            inputs: true,
        };

        // Assemble the list of all columns that can be selected
        this.columnSelect.labels = [
            new ColumnSelectLabel('protocol', 'Protocol'),
            new ColumnSelectLabel('taskAlias', 'Task'),
            new ColumnSelectLabel('materials', 'Animals/Samples'),
            new ColumnSelectLabel('inputs', 'Inputs'),
        ];

        this.columnSelect.model = this.columnSelect.labels.filter(
            (item) => this.visible[item.key]
        ).map((item) => item.key);

        // Register the columns
        this.subs.add(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, this.tab, this.columnSelect,
                () => {
                    this.updateVisible();
                }
            )
        );

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }

    /**
     * Initialize the Job-related values.
     */
    async initJob(): Promise<any> {
        // Make sure the tasks are loaded
        this.loading = true;

        const tasks = this.jobPharmaDetailService.getJobPharmaTaskRows(this.job);
        // Fill out the task entities

        this.jobPharmaDetailService.sortTasksBySchedule(tasks);
        // Going to use these tasks...
        this.tasks = tasks;
        // ... but they need to be split into rows first
        this.initRows();
        await this.ensureRelationshipsOfPage(this.taskPage);
        this.isLockedChanged();
        // Figure which tasks to show inthe bulk input UI.
        this.initBulkInputTasks();
        this.loading = false;
    }

    // function splits this.tasks array based on number of rows and page number, using  50 rows per page,
    // then ensures the relationships only for those task instances
    ensureRelationshipsOfPage(page: number): any {
        const expands = [
            'ProtocolInstance.Protocol',
            'ProtocolTask',
            'TaskCohort.TaskCohortInput.Input.cv_DataType',
            'TaskInput.Input.cv_DataType',
            'TaskPlaceholder.TaskPlaceholderInput.Input.cv_DataType',
            'TaskMaterial.Material.Animal',
            'TaskMaterial.Material.Sample',
            'MemberTaskInstance.TaskInput',
            'cv_TaskStatus',
        ];

        const startIndex = (page - 1) * 50;
        let endIndex: number;
        let pageTaskInstances: any[] = [];
        if (page * 50 - 1 > this.rows.length) {
            endIndex = this.rows.length;
        } else {
            endIndex = page * 50;
        }
        // creates new array with only the rows of the specified page
        const rowPage = this.rows.slice(startIndex, endIndex);
        // pushes task instance keys to array declared above
        for (const row of rowPage) {
            pageTaskInstances.push(row.task.C_TaskInstance_key);
        }
        // extract unique task instance keys
        const uniqueTaskInstanceKeys = Array.from(new Set(pageTaskInstances));
        // filter this.tasks
        const tasksToLoad = this.tasks.filter(task => uniqueTaskInstanceKeys.includes(task.C_TaskInstance_key));
        // ensure relationships of only the task instaces relavant to the page passed into the function
        return this.dataManager.ensureRelationships(tasksToLoad, expands);
    }

    initRows() {
        // These rows will appear in the table
        const rows: DataRow[] = [];
        for (const task of this.tasks) {
            // Each task may be split into multiple rows
            const taskRows: DataRow[] = [];

            if (task.IsGroup) {
                // Group tasks are split by cohorts and individual animals
                if (task.TaskCohort) {
                    // Cohorts have their own inputs that are applied to all
                    // their animals.
                    for (const tc of task.TaskCohort) {
                        const cohort = tc.Cohort;
                        const inputs = tc.TaskCohortInput;

                        // Prepare a lookup table for all the animals in the cohort.
                        const animalMap: any = {};
                        if (cohort.CohortMaterial) {
                            cohort.CohortMaterial.filter(
                                (cm: any) => cm.Material && cm.Material.Animal
                            ).forEach((cm: any) => {
                                animalMap[cm.C_Material_key] = true;
                            });
                        }

                        // Prepare arrays of all the animal (child) inputs
                        // keyed by Input key.
                        const childInputs: { [key: string]: any[] } = {};
                        if (inputs) {
                            for (const input of inputs) {
                                childInputs[input.C_Input_key] = [];
                            }
                        }
                        // Find all the member tasks for this cohort's animals
                        // and add their inputs to the child inputs.
                        if (task.MemberTaskInstance) {
                            for (const ti of task.MemberTaskInstance) {
                                if (ti.TaskInput && ti.TaskMaterial) {
                                    for (const tm of ti.TaskMaterial) {
                                        if (animalMap[tm.C_Material_key]) {
                                            for (const input of ti.TaskInput) {
                                                if (childInputs[input.C_Input_key]) {
                                                    childInputs[input.C_Input_key].push(input);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // Add a row to edit the cohort inputs
                        taskRows.push({
                            task,
                            cohort,
                            inputs,
                            childInputs
                        });
                    }
                }

                if (task.TaskPlaceholder) {
                    // Placeholders have their own inputs
                    for (const tp of task.TaskPlaceholder) {
                        // Add a row to edit the placeholder inputs
                        if (this.jobPharmaDetailService.taskDontHaveMatchingCohort(tp, task.TaskCohort)) {
                            taskRows.push({
                                task,
                                placeholder: tp.Placeholder,
                                inputs: tp.TaskPlaceholderInput
                            });
                        }
                    }
                }

                if (task.TaskMaterial && task.MemberTaskInstance) {
                    // Individual materials added to the group task get their own rows
                    for (const tm of task.TaskMaterial) {
                        if (tm.Material && (tm.Material.Animal || tm.Material.Sample)) {
                            // Find the corresponding member task for the material
                            const materialTask = task.MemberTaskInstance.find(ti => {
                                return ti.TaskMaterial && ti.TaskMaterial.some(
                                    _tm => _tm.C_Material_key === tm.Material.C_Material_key
                                );
                            });

                            if (materialTask) {
                                // Add a row to edit the materials inputs
                                taskRows.push({
                                    task,
                                    materials: [tm.Material.Animal !== null ? tm.Material.Animal : tm.Material.Sample],
                                    inputs: materialTask.TaskInput,
                                });
                            }
                        }
                    }
                }
            } else {
                // Individual Task get a single row for all attached materials
                const materials = task.TaskMaterial.filter(
                    (tm: any) => tm.Material && (tm.Material.Animal || tm.Material.Sample)
                ).map((tm: any) => tm.Material.Animal !== null ? tm.Material.Animal : tm.Material.Sample);

                // Add a row to edit the task inputs
                taskRows.push({
                    task,
                    materials,
                    inputs: task.TaskInput,
                });
            }

            if (taskRows.length > 0) {
                // Mark the first row of each task and add the task rows to the table
                taskRows.forEach((row: DataRow, index: number) => {
                    row.taskFirst = (index === 0);
                    rows.push(row);
                });
            } else {
                // The task has no rows, so add a placeholder for it.
                rows.push({
                    task,
                    inputs: task.TaskInput,
                    taskFirst: true,
                });

            }
        }

        // Process all the rows
        for (const row of rows) {
            // Prepare the cell classes
            row.classes = {
                task: {'task-extra': !row.taskFirst},
            };
        }

        // Use these rows
        this.rows = rows;
    }

    /**
     * Handle a change to an input value.
     *
     * Update child input values when an input value is changed in the table.
     * For example, update the animal inputs values when a cohort input value
     * is changed.
     *
     * @param row The table row
     * @param input The change input
     */
    onInputValueChange(row: DataRow, input: any) {
        if (!row.childInputs || !row.childInputs[input.C_Input_key]) {
            // This row odes not have child inputs for this input
            return;
        }

        // Update all the child inputs
        const childInputs = row.childInputs[input.C_Input_key];
        for (const childInput of childInputs) {

            if (!childInput.TaskInstance.cv_TaskStatus || !childInput.TaskInstance.cv_TaskStatus.IsEndState) {
                childInput.InputValue = input.InputValue;
            }
        }
    }

    /**
     * Prepare the Task options in the Bulk Input fill down
     */
    initBulkInputTasks() {
        const tasks: any[] = [];
        const seen: any = {};

        // Find all the distinct tasks that have inputs
        if (notEmpty(this.tasks)) {
            for (const task of this.tasks) {
                const key = task.C_WorkflowTask_key;
                if (seen[key]) {
                    // Already added
                    continue;
                }

                if (!task.TaskInput || (task.TaskInput.length === 0)) {
                    // No inputs
                    continue;
                }

                // This one is a keeper
                tasks.push(task);
                seen[key] = true;
            }
        }

        // Use these tasks
        this.bulkInputTasks = tasks;

        // If there is only one task, select it by default
        if (this.bulkInputTasks.length === 1) {
            this.bulkInputTask = this.bulkInputTasks[0];

            // Pretend like the user picked this task to update the related
            // values
            this.bulkInputTaskChanged();
        }
    }

    /**
     * Clear the Bulk Input fill down form.
     *
     * @param clearTask If true, the selected task will also be cleared
     */
    resetBulkInput(clearTask: boolean = true) {
        if (clearTask) {
            this.bulkInputTask = null;
        }

        this.bulkInputTaskAliases = [];
        this.bulkInputTaskAlias = null;
        this.bulkInputCohorts = [];
        this.bulkInputCohort = null;
        this.bulkInputInputs = [];
        this.bulkInputInput = null;
        this.bulkInputValue = null;
    }

    /**
     * The selection of the Bulk Input Task has changed
     */
    bulkInputTaskChanged() {
        // Clear the form, but keep the task selection
        this.resetBulkInput(false);

        if (!this.tasks || !this.bulkInputTask) {
            // Nothing to do
            return;
        }

        // Find all the distinct aliases for this task.
        const aliases: string[] = [];
        const seen: any = {};
        const cohorts: any[] = [];
        const seenCohorts: any = {};
        for (const task of this.tasks) {
            if (task.C_WorkflowTask_key !== this.bulkInputTask.C_WorkflowTask_key) {
                // Not the selected task
                continue;
            }

            if (!task.TaskInput || (task.TaskInput.length === 0)) {
                // This task has no inputs
                continue;
            }
            if (task.IsLocked) {
                // Not the selected task
                continue;
            }

            const alias = task.TaskAlias;
            if (seen[alias]) {
                // Already added
            } else {
                // This one is a keeper
                aliases.push(alias);
                seen[alias] = true;
            }

            for (let taskCohort of task.TaskCohort) {
                const cohort = taskCohort.Cohort;
                if (seenCohorts[cohort.C_Cohort_key]) {
                    // Already added
                    continue;
                }

                // This one is a keeper
                cohorts.push(cohort);
                seenCohorts[cohort.C_Cohort_key] = true;
            }
        }

        if ((aliases.length === 1) && (aliases[0] === this.bulkInputTask.WorkflowTask.TaskName)) {
            // There are no aliases that are different the task name, so clear
            // the list.
            aliases.length = 0;
        }

        // Use these aliases
        this.bulkInputTaskAliases = aliases;
        this.bulkInputCohorts = cohorts;

        // Copy the task inputs into the form
        if (this.bulkInputTask.TaskInput) {
            const inputs = this.bulkInputTask.TaskInput.map((taskInput: any) => {
                return taskInput.Input;
            });

            // Put the inputs in the right order
            inputs.sort((a: any, b: any) => a.SortOrder - b.SortOrder);

            // Use the inputs
            this.bulkInputInputs = inputs;

            // Select the first one by default
            this.bulkInputInput = this.bulkInputInputs[0];
        }
    }

    /**
     * The Bulk Input Input selection was changed
     */
    bulkInputInputChanged() {
        // Clear the prior value
        this.bulkInputValue = null;
    }

    /**
     * The Bulk Input "Update All" button was clicked.
     *
     * Update the inputs for all the matching tasks.
     */
    bulkInputUpdated() {
        if (!this.tasks || !this.bulkInputTask || !this.bulkInputInput) {
            // Nothing to do
            return;
        }

        // Find matching tasks and update the inputs
        for (const jobTask of this.job.TaskJob) {
            const task = jobTask.TaskInstance;
            if (task.IsLocked || (task.cv_TaskStatus && task.cv_TaskStatus.IsEndState)) {
                continue;
            }

            if (task.C_WorkflowTask_key !== this.bulkInputTask.C_WorkflowTask_key) {
                // Not the selected task
                continue;
            }

            if ((this.bulkInputTaskAlias !== null) &&
                (task.TaskAlias !== this.bulkInputTaskAlias)) {
                // Not the selected alias
                continue;
            }

            if (!task.TaskInput) {
                // No inputs to update
                continue;
            }

            if (this.bulkInputCohort !== null) {
                if (!task.C_GroupTaskInstance_key) {
                    // Skip parent tasks
                    continue;
                }
                const groupTask = task.GroupTaskInstance;
                // Filter the taskMaterials for animals
                const animalMaterial = task.TaskMaterial.filter((value: any) => {
                    return value.Material.Animal !== null;
                });

                // Check if the animal is a member of the selected cohort
                const cohortMaterial = animalMaterial[0].Material.CohortMaterial.filter((value: any) => {
                    return value.C_Cohort_key === this.bulkInputCohort.C_Cohort_key;
                });
                if (cohortMaterial.length === 0) {
                    // Child task is not in the selected cohort
                    continue;
                } else {
                    // Update the TaskCohortInput of the parent for the UI
                    if (groupTask.TaskCohort) {
                        for (const taskCohort of groupTask.TaskCohort) {
                            if (taskCohort.Cohort !== this.bulkInputCohort) {
                                // Not the selected cohort
                                continue;
                            }
                            if (taskCohort.TaskCohortInput) {
                                // Check and update each input
                                for (const taskCohortInput of taskCohort.TaskCohortInput) {
                                    if (taskCohortInput.C_Input_key !==
                                        this.bulkInputInput.C_Input_key) {
                                        // Not the selected input
                                        continue;
                                    }

                                    // Set the value for this input
                                    taskCohortInput.InputValue = this.bulkInputValue;
                                }
                            }
                        }
                    }
                }
            }

            // Check and update each input
            for (const taskInput of task.TaskInput) {
                if (taskInput.C_Input_key !== this.bulkInputInput.C_Input_key) {
                    // Not the selected input
                    continue;
                }

                // Set the value for this input
                taskInput.InputValue = this.bulkInputValue;
            }

            if (task.TaskCohort) {
                for (const taskCohort of task.TaskCohort) {
                    if ((this.bulkInputCohort !== null) &&
                        taskCohort.Cohort !== this.bulkInputCohort) {
                        // Not the selected cohort
                        continue;
                    }
                    if (taskCohort.TaskCohortInput) {
                        // Check and update each input
                        for (const taskCohortInput of taskCohort.TaskCohortInput) {
                            if (taskCohortInput.C_Input_key !== this.bulkInputInput.C_Input_key) {
                                // Not the selected input
                                continue;
                            }

                            // Set the value for this input
                            taskCohortInput.InputValue = this.bulkInputValue;
                        }
                    }
                }
            }
        }

        // Clear the Bulk Input form
        this.resetBulkInput(true);
    }

    async changeTaskPage(newPage: number) {
        this.loading = true;
        this.taskPage = newPage;
        await this.ensureRelationshipsOfPage(this.taskPage);
        this.loading = false;
    }

    isRegularInput(input: any): boolean {
        return !input || !input.cv_DataType ||
            (input.cv_DataType.DataType !== DataType.DOSING_TABLE &&
                input.cv_DataType.DataType !== DataType.JOB_CHARACTERISTIC);
    }

    isDosingTableInput(input: any): boolean {
        return input && input.cv_DataType && input.cv_DataType.DataType === DataType.DOSING_TABLE;
    }

    isJobCharacteristicInput(input: any): boolean {
        return input && input.cv_DataType && input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC;
    }
}
