import { Entity } from '@common/types';
import { WorkspaceDetail } from '@common/types/models/workspace-detail.interface';
import { createStore, createEvent, sample } from 'effector';
import { FACET_DEFAULT_H, FACET_DEFAULT_W, Facet, FacetDimensions } from './types';
import { autoSize, closeFacet, collapse, collapseAll, expand, syncWithBreezy } from './helpers';

export const $facetStore = createStore<Facet[]>([]);

// public events
export const expandEvent = createEvent<{ id: string }>();
export const collapseEvent = createEvent<{ id: string }>();
export const collapseAllEvent = createEvent();
export const closeEvent = createEvent<{ id: string }>();
export const addEvent = createEvent<Pick<Facet, 'id' | 'componentId' | 'facet' | 'type'>>();
export const changeEvent = createEvent<{ id: string, facet: Partial<Facet> }>();
export const initEvent = createEvent<Pick<Facet, 'id' | 'componentId' | 'facet' | 'type'>[]>();
export const autoSizeEvent = createEvent();

// private events
const setEvent = createEvent<Facet[]>();
const setWithAutosizeEvent = createEvent<Facet[]>();


// @ts-ignore
// Triggers
// expand event
sample({
  source: $facetStore,
  clock: expandEvent,
  fn: (facets, { id }) => collapseAll(facets).map(facet => facet.id !== id ? facet : expand(facet)),
  target: setEvent,
});

// collapse event
sample({
  source: $facetStore,
  clock: collapseEvent,
  fn: (facets, { id }) => facets.map(facet => facet.id !== id ? facet : collapse(facet)),
  target: setEvent,
});

// collapse all event
sample({
  source: $facetStore,
  clock: collapseAllEvent,
  fn: collapseAll,
  target: setEvent,
});

// adding new facet event
sample({
  source: $facetStore,
  clock: addEvent,
  fn: (facets, value) => {
    const dimensions: FacetDimensions = {
      x: null,
      y: null,
      w: FACET_DEFAULT_W,
      h: FACET_DEFAULT_H,
    };

    return [...facets, { 
      ...value,
      ...dimensions,
      manually: false,
      expanded: false,
      collapsedDimensions: { ...dimensions },
    }]
  },
  target: setWithAutosizeEvent,
});

// close facet event
sample({
  source: $facetStore,
  clock: closeEvent,
  fn: closeFacet,
  target: setWithAutosizeEvent,
});

// initializing store values
sample({
  source: initEvent,
  fn: facets => facets.map(item => {
    const facet = item.facet as Entity<WorkspaceDetail>;

    return <Facet>{
      ...item,
      x: facet.Column,
      y: facet.Row,
      w: facet.SizeX,
      h: facet.SizeY,
      expanded: false,
      manually: false,
      collapsedDimensions: {
        x: facet.collapseColumn,
        y: facet.collapseRow,
        w: facet.collapseSizeX,
        h: facet.collapseSizeY,
      },
    }
  }),
  target: setEvent,
});

// change event
sample({
  source: $facetStore,
  clock: changeEvent,
  fn: (facets, { id, facet }) => facets.map(item => item.id === id ? { ...item, ...facet } : item),
  target: setEvent,
});

// autosize event
sample({
  source: $facetStore,
  clock: autoSizeEvent,
  fn: autoSize,
  target: setEvent,
});

sample({
  source: setWithAutosizeEvent,
  fn: autoSize,
  target: setEvent,
});

// Setting store value
sample({
  source: setEvent,
  fn: syncWithBreezy,
  target: $facetStore,
});
