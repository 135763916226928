import {
    CellFormatterService,
    TableOptions
} from '../common/datatable';
import {
    getSafeProp,
    uniqueArrayFromPropertyPath,
} from '../common/util';

import { SampleLogicService } from './sample-logic.service';
import { TranslationService } from '../services/translation.service';
import { SampleSourceCellRendererComponent } from './sample-source-cell-renderer.component';
import { formatTimePoint } from './util';
import { addAuditColumnsToOptions } from '@common/datatable/utils';
import { Sample } from '@common/types';

export class SampleTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private sampleLogicService: SampleLogicService,
        private translationService: TranslationService
    ) {
        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'samples.csv',
            enableDetailColumn: true,
            enableDraggable: true,
            enableSelectable: true,
            columns: [
                {
                    displayName: 'ID',
                    field: 'Material.Identifier'
                },
                {
                    displayName: 'Name',
                    field: 'SampleName',
                    sortField: 'SampleNameSortable'
                },
                {
                    displayName: 'Type',
                    field: 'cv_SampleType.SampleType'
                },
                {
                    displayName: 'Time Point',
                    field: 'TimePoint',
                    visible: false,
                    formatter: (row: any, value: any) => {
                        return formatTimePoint(row.TimePoint, row.cv_TimeUnit);
                    }
                },
                {
                    displayName: 'Harvest Date',
                    field: 'DateHarvest',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false
                },
                {
                    displayName: 'Expiration Date',
                    field: 'DateExpiration',
                    formatter: this.cellFormatterService.dateFormatter,
                    visible: false
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'Material.Line.LineName',
                    visible: false
                },
                {
                    displayName: 'Species',
                    field: 'Material.cv_Taxon.CommonName',
                    visible: false
                },
                {
                    displayName: 'Status',
                    field: 'cv_SampleStatus.SampleStatus'
                },
                {
                    displayName: 'Preservation',
                    field: 'cv_PreservationMethod.PreservationMethod',
                    visible: false
                },
                {
                    displayName: 'Origin',
                    field: 'Material.cv_MaterialOrigin.MaterialOrigin',
                    visible: false
                },
                {
                    displayName: 'External ID',
                    field: 'Material.ExternalIdentifier',
                    visible: false
                },
                {
                    displayName: 'Microchip ID',
                    field: 'Material.MicrochipIdentifier',
                    visible: false
                },
                {
                    displayName: 'Container',
                    field: 'Material.cv_ContainerType.ContainerType',
                    visible: false
                },
                {
                    displayName: 'Measurement',
                    field: 'Volume',
                    visible: false
                },
                {
                    displayName: 'Unit',
                    field: 'cv_Unit.Unit',
                    visible: false
                },
                {
                    displayName: 'Subtype',
                    field: 'cv_SampleSubtype.SampleSubtype',
                    visible: false
                },
                {
                    displayName: 'Processing',
                    field: 'cv_SampleProcessingMethod.SampleProcessingMethod',
                    visible: false
                },
                {
                    displayName: 'Send To',
                    field: 'SendTo',
                    visible: false
                },
                {
                    displayName: 'Analysis',
                    field: 'cv_SampleAnalysisMethod.SampleAnalysisMethod',
                    visible: false
                },
                {
                    displayName: 'Source',
                    field: 'Material.MaterialSourceMaterial',
                    sortable: false,
                    formatter: (row: any, value: any) => {
                        const sourceMaterialAssocs = row.Material.MaterialSourceMaterial;
                        const names = sourceMaterialAssocs.map((sourceMaterialAssoc: any) => {
                            let name = getSafeProp(sourceMaterialAssoc,
                                "SourceMaterial.Animal.AnimalName");
                            if (!name) {
                                name = getSafeProp(sourceMaterialAssoc,
                                    "SourceMaterial.Sample.SampleName");
                            }
                            return name || '';
                        });
                        return names.join(', ');
                    },
                    rendererComponent: <new () => SampleSourceCellRendererComponent>
                    SampleSourceCellRendererComponent,
                },
                {
                    displayName: 'Source Microchip ID',
                    field: 'Material.MaterialSourceMaterial[0].SourceMaterial.MicrochipIdentifier',
                    sortable: false,
                    formatter: (row: Sample, _: any) => {
                        const sourceMaterialMicrochipIDs: string[] = row.Material.MaterialSourceMaterial
                            .map(msm => getSafeProp(msm, "SourceMaterial.MicrochipIdentifier"))
                            .filter(id => id);
                        return sourceMaterialMicrochipIDs?.length > 0 ? sourceMaterialMicrochipIDs.join(', ') : '';
                    },
                    visible: false
                },
                {
                    displayName: 'Age (weeks)',
                    field: 'AgeInWeeksAtHarvest',
                    formatter: (row: any, value: any) => {
                        return this.sampleLogicService.getSourceAgeInWeeksAtHarvest(row);
                    },
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell',
                    sortable: false,
                    visible: false
                },
                {
                    displayName: 'Location',
                    field: 'Material.CurrentLocationPath',
                    visible: false
                },
                {
                    displayName: this.translationService.translate('Job'),
                    field: 'Material.JobMaterial',
                    formatter: (row: any, value: any) => {
                        const jobIds = row.Material.JobMaterial.map((jobMaterial: any) => {
                            return getSafeProp(jobMaterial, 'Job.JobID');
                        });
                        return jobIds.join(', ');
                    },
                    visible: false,
                    sortable: false
                },
                {
                    displayName: this.translationService.translate('Study'),
                    field: 'Material.JobMaterial.Job.Study.StudyName',
                    sortable: false,
                    visible: false,
                    formatter: (row: any, value: any) => {
                        const uniqueValues = uniqueArrayFromPropertyPath(
                            row,
                            'Material.JobMaterial.Job.Study.StudyName'
                        );
                        return uniqueValues.join(', ');
                    }
                },
                {
                    displayName: 'Construct',
                    field: 'SampleConstruct.Construct',
                    formatter: (row: any, value: any) => {
                        const jobIds = row.SampleConstruct.map((sampleConstruct: any) => {
                            return getSafeProp(sampleConstruct, 'Construct.FullName');
                        });
                        return jobIds.join(', ');
                    },
                    visible: false,
                    sortable: false
                },
                {
                    displayName: 'Description',
                    field: 'Description',
                    visible: false
                },
                {
                    displayName: 'Order',
                    field: 'SampleOrder.Order.OrderID',
                    visible: false
                },
                {
                    displayName: 'Lot Number',
                    field: 'LotNumber',
                    visible: false
                },
                {
                    displayName: 'Notes',
                    field: 'Material.Note',
                    visible: false,
                    formatter: this.cellFormatterService.noteFormatter,
                    sortable: false
                },
            ]
        };
    }
}
