import { DataContextService } from './../../services/data-context.service';
import { ClinicalService } from './../clinical.service';
import { ObservationBulkTemplatesComponent } from './observation-bulk-templates.component';
import { BulkAddCommService } from '../../common/facet/bulk-add-comm.service';
import { BulkAddComponent } from '../../common/facet/bulk-add.component';
import { DroppableEvent } from '../../common/droppable-event';
import { AnimalService } from '../../animals/services/animal.service';
import { FacetLoadingStateService } from '../../common/facet/facet-loading-state.service';
import { BulkAddResult } from '../../common/facet/models/bulk-edit.classes';
import { BulkAddExitReason } from '../../common/facet/models/bulk-add-exit-reason.enum';
import { BulkEditSection } from '../../common/facet/models/bulk-edit-section.enum';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    OnInit,
    ViewChildren
} from '@angular/core';
import { notEmpty, uniqueArrayOnProperty, uniqueArrayFromPropertyPath, softCompare } from '../../common/util';
import { ResourceService } from '../../resources';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicalVocabService } from '../clinical-vocab.service';
import { LoggingService } from '@services/logging.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { CopyBufferService } from '@common/services';


@Component({
    selector: 'observation-bulk-add',
    templateUrl: './observation-bulk-add.component.html',
    styles: [`
        h5 {
            font-weight: bold;
        }
        .number-items-to-add-label {
            font-weight: normal;
        }
        .dropzone {
            max-height: 160px;
            overflow-y: scroll;
        }
    `]
})
export class ObservationBulkAddComponent implements OnInit, AfterViewInit {
    @Input() facet: any;
    @Output() exit: EventEmitter<BulkAddResult> = new EventEmitter<BulkAddResult>();
    @ViewChild('itemsToAddTmpl') itemsToAddTmpl: TemplateRef<any>;
    @ViewChild('bulkAdd') bulkAdd: BulkAddComponent;
    @ViewChild('bulkTemplates') bulkTemplates: ObservationBulkTemplatesComponent;
    @ViewChildren('dateControl') dateControls: NgModel[];

    readonly COMPONENT_LOG_TAG = 'observation-bulk-add';

    BulkEditSection = BulkEditSection;

    observations: any[] = [];
    private obsID = 0;

    sourceMaterials: any = [];
    defaultResourceKey: any;
    defaultClinicalObservation: any;
    defaultClinicalObservationStatusKey: any;

    // vocabularies
    clinicalObservationStatuses: any[];
    resources: any[];

    // state variables
    selectedObservation: any;
    clinicalObservationDetail: any[] = [];

    constructor(
        private animalService: AnimalService,
        private bulkAddCommService: BulkAddCommService,
        private copyBufferService: CopyBufferService,
        private clinicalService: ClinicalService,
        private faceLoadingStateService: FacetLoadingStateService,
        private dataContext: DataContextService,
        private resourceService: ResourceService,
        private vocabularyService: VocabularyService,
        private clinicalVocabService: ClinicalVocabService,
        private modalService: NgbModal,
        private loggingService: LoggingService,
    ) {
    }

    /**
     * AfterViewInit is when bulkTemplates is fully initialized
     */
    ngAfterViewInit() {
        if (this.bulkTemplates) {
            this.bulkTemplates.bulkOptions.itemsToAddTemplate = this.itemsToAddTmpl;
        }
    }

    ngOnInit() {
        this.getCVs();
    }

    onDropSourceMaterials(event: DroppableEvent) {
        if (notEmpty(this.animalService.draggedAnimals)) {
            const draggedAnimals = this.animalService.draggedAnimals;
            this.addSourceMaterials(draggedAnimals);
            this.animalService.draggedAnimals = [];
        }
    }

    pasteSourceMaterials() {
        if (this.copyBufferService.hasAnimals()) {
            const pastedAnimals = this.copyBufferService.paste();
            this.addSourceMaterials(pastedAnimals);
        }
    }

    addSourceMaterials(newSourceMaterials: any[]) {
        newSourceMaterials = newSourceMaterials.slice();
        newSourceMaterials = this.sourceMaterials.concat(newSourceMaterials);
        this.sourceMaterials = uniqueArrayOnProperty(newSourceMaterials, 'C_Material_key');

        this.syncItemsToAdd();
    }

    /** Event handler for selecting animals in animal-multiselect component */
    onSelectSourceMaterials() {
        this.sourceMaterials = uniqueArrayOnProperty(this.sourceMaterials, 'C_Material_key');
        this.syncItemsToAdd();
    }

    // formatters for <select> inputs
    observationStatusKeyFormatter = (value: any) => {
        return value.C_ClinicalObservationStatus_key;
    }
    observationStatusFormatter = (value: any) => {
        return value.ClinicalObservationStatus;
    }
    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }

    /**
     * Set the numberItemsToAdd on BulkAddComponent.
     * It should be in sync with the sourceMaterials list
     */
    syncItemsToAdd() {
        if (!notEmpty(this.sourceMaterials) ||
            !this.bulkAdd
        ) {
            return;
        }

        this.bulkAdd.addState.numberItemsToAdd = this.sourceMaterials.length;
    }

    saveClicked(result: BulkAddResult) {
        const errMessage = dateControlValidator(this.dateControls) ?? this.bulkTemplates.validate();
        if (errMessage) {
            return this.loggingService.logError(errMessage, null, '', true);
        }
        result.newItems = [];
        let promise = Promise.resolve([]);
        const promises: any[] = [];
        switch (result.reason) {
            case BulkAddExitReason.Save:
                this.faceLoadingStateService.changeLoadingState(true);

                promise = this.replaceSearchObjectsWithBreezeEntities()
                    .then(() => {
                        this.animalService.statusBulkChangePostProcess(this.sourceMaterials, result.initialValues.C_AnimalStatus_key);
                    }).then(() => { 
                        return this.createNewObservations(result.initialValues);
                    }).then((newObservations) => {
                        return this.dataContext.save().then(() => {
                            this.faceLoadingStateService.changeLoadingState(false);
                            if (result.clearForm) {
                                this.sourceMaterials = [];
                                this.observations = [];
                                this.clinicalObservationDetail = [];
                                this.bulkAdd.addState.numberItemsToAdd = 1;
                            }
                            return newObservations;
                    }).catch((error) => {
                        this.faceLoadingStateService.changeLoadingState(false);
                        for (const observation of newObservations) {
                            this.clinicalService.cancelHealthRecord(observation);
                        }
                        throw error;
                    });
                    });
                promises.push(promise);
                break;
        }

        Promise.all(promises).then((newItems) => {
            result.newItems = newItems;
            this.bulkAddCommService.saveComplete();
            this.faceLoadingStateService.changeLoadingState(false);
        }).catch((error) => {
            this.bulkAddCommService.saveCanceled();
            this.faceLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    exitClicked(result: BulkAddResult) {
        this.exit.emit(result);
    }

    createNewObservations(initialValues: any): Promise<any[]> {
        const observations: any[] = [];
        const promises: Promise<any>[] = [];

        if (notEmpty(this.sourceMaterials)) {
            const p = this.replaceSearchObjectsWithBreezeEntities().then(() => {
                const promises2: Promise<any>[] = [];
                for (const parentMaterial of this.sourceMaterials) {
                    const p2 = this.createOrFetchHealthRecord(parentMaterial.C_Material_key).then(() => {
                        parentMaterial.AnimalHealthRecord.C_Resource_key = initialValues.C_Resource_key;
                        if (initialValues.C_AnimalStatus_key) {
                            parentMaterial.C_AnimalStatus_key = initialValues.C_AnimalStatus_key;
                        }
                        parentMaterial.AnimalHealthRecord.IsUrgent = initialValues.IsUrgent;
                        return this.createNewObservation(
                            initialValues, parentMaterial
                        );
                    }).then((newObservation) => {
                        observations.push(newObservation);
                    });
                    promises2.push(p2);
                }
                return Promise.all(promises2);
            });
            promises.push(p);
        }
        
        return Promise.all(promises).then(() => {
          return observations;  
        });
    }

    createNewObservation(initialValues: any, parentMaterial?: any): Promise<any> {
        return Promise.all(this.observations.map((obs) => {
            const observation = this.clinicalService.createObservation(
                { 
                    C_ClinicalObservationStatus_key: obs.C_ClinicalObservationStatus_key,
                    C_ClinicalObservation_key: obs.C_ClinicalObservation_key,
                    DateObserved: obs.DateObserved,
                    DateDue: obs.DateDue,
                    Treatment: obs.Treatment,
                    Comments: obs.Comments,
                    C_Material_key: parentMaterial.C_Material_key,
                    C_AnimalStatus_key: initialValues.C_AnimalStatus_key,
                    C_Resource_key: obs.C_Resource_key,
                    IsUrgent: initialValues.IsUrgent
                }
            );
            for (const observationCV of obs.ClinicalObservationDetail) {
                this.clinicalService.createObservationDetail(
                    {
                        C_AnimalClinicalObservation_key: observation ? observation.C_AnimalClinicalObservation_key : null,
                        C_ClinicalObservation_key: observationCV.C_ClinicalObservation_key
                    }
                );
            }
            return observation;
        }));
    }

    createOrFetchHealthRecord(animalKey: any): Promise<any> {
        // check if there is a health record for this animal
        return this.clinicalService.getHealthRecord(animalKey)
            .then((healthRecord: any) => {
                if (healthRecord) {
                    return healthRecord;
                }
                return this.createNewHealthRecord(animalKey);
            });
    }

    createNewHealthRecord(animalKey: any): Promise<any> {
        // load original Animal record
        return this.animalService.getAnimal(animalKey)
            .then((animalRecord: any) => {
                const initialValues = {
                    C_Material_key: animalRecord.C_Material_key
                };
                return this.clinicalService.createHealthRecord(initialValues);
            });
    }
    
    private replaceSearchObjectsWithBreezeEntities(): Promise<any> {
        const promises: Promise<any>[] = [];

        const length = this.sourceMaterials.length;
        for (const sourceMaterial of this.sourceMaterials) {
            if (sourceMaterial.entityAspect) {
                continue;
            }

            // swap search object for breeze entity
            const materialKey = sourceMaterial.C_Material_key;
            const p = this.animalService.getAnimal(materialKey).then((breezeAnimal) => {
                for (let i = 0; i < length; i++) {
                    if (this.sourceMaterials[i].C_Material_key === materialKey) {
                        this.sourceMaterials[i] = breezeAnimal;
                        break;
                    }
                }
            });
            promises.push(p);
        }

        return Promise.all(promises);
    }

    private getCVs(): Promise<any> {
        const promises: Promise<any>[] = [];
        this.clinicalVocabService.clinicalObservationStatuses$.subscribe((clinicalObservationStatuses: any) => {
            this.clinicalObservationStatuses = clinicalObservationStatuses;
        });
        this.clinicalVocabService.resources$.subscribe((resources: any) => {
            this.resources = resources;
        });
        
        // set current user as resource if possible
        promises.push(this.resourceService.getCurrentUserResource().then((resource: any) => {
            if (resource) {
                this.defaultResourceKey = resource.C_Resource_key;
            }
        }));
        // set default clinical observation
        promises.push(this.vocabularyService.getCVDefault('cv_ClinicalObservations')
            .then((value: any) => {
                this.defaultClinicalObservation = value;
            }));

        // set default observation status
        promises.push(this.vocabularyService.getCVDefault('cv_ClinicalObservationStatuses')
            .then((value: any) => {
                if (value) {
                    this.defaultClinicalObservationStatusKey = value.C_ClinicalObservationStatus_key;
                }
            }));

        return Promise.resolve(promises);
    }

    addObservation() {
        const newObservation: any = {
            ClinicalObservationDetail: [],
            DateObserved: new Date(),
            C_Resource_key: this.defaultResourceKey,
            C_ClinicalObservationStatus_key: this.defaultClinicalObservationStatusKey,
            tempID: ++this.obsID
        };
        this.createNewObservationDetails(newObservation, this.defaultClinicalObservation ? [this.defaultClinicalObservation] : []);
        this.selectedObservation = this.defaultClinicalObservation;
    
        this.observations.push(newObservation);
    }

    removeObservation(observation: any) {
        this.observations = this.observations.filter((x) => x.tempID !== observation.tempID);
    }

    openObservationChooser(observationmodal: TemplateRef<any>, observation: any) {
        this.selectedObservation = observation;
        this.modalService.open(observationmodal);
    }

    onSelectObservations(selected: any[]) {
        // create new observations based on selections
        this.deleteUnselectedObservationDetails(this.selectedObservation, selected);
        this.createNewObservationDetails(this.selectedObservation, selected);
    }

    /**
     * Delete those observation statuses that are in the current observation
     *   but not in the set of selected observation statuses
     * @param observation - current AnimalClinicalObservation
     * @param observationCVs - selected cv_ClinicalObservationStatuses
     */
    deleteUnselectedObservationDetails(observation: any, observationCVs: any[]) {
        // find observations missing from selection
        const currentDetails = uniqueArrayFromPropertyPath(
            observation, 'ClinicalObservationDetail'
        );
        const missingObservationDetails = currentDetails.filter((current) => {
            return observationCVs.indexOf(current.cv_ClinicalObservation) < 0;
        });

        // delete observations missing from selection 
        for (const observationDetail of missingObservationDetails) {
            const index = observation.ClinicalObservationDetail.indexOf(observationDetail);
            if (index > -1) {
                observation.ClinicalObservationDetail.splice(index, 1);
                this.clinicalObservationDetail.splice(index, 1);
            }
        }
    }

    /**
     * Create new observations for those in selected that
     *   are not in current observation
     * @param observation - current AnimalClinicalObservation
     * @param observationCVs - selected cv_ClinicalObservationStatuses
     */
     createNewObservationDetails(observation: any, observationCVs: any[]) {
        // find observations in selection missing from current
        const currentObservationCVs = uniqueArrayFromPropertyPath(
            observation, 'ClinicalObservationDetail.cv_ClinicalObservation'
        );
        const missingObservationCVs = observationCVs.filter((selected) => {
            return currentObservationCVs.indexOf(selected) < 0;
        });

        // create observations missing from current
        for (const observationCV of missingObservationCVs) {
            const observationDetail = this.createObservationDetail(
                {
                    tempID: observation.tempID,
                    C_ClinicalObservation_key: observationCV.C_ClinicalObservation_key
                }
            );
            if (observationDetail) {
                observation.ClinicalObservationDetail.push({
                    C_ClinicalObservation_key: observationCV.C_ClinicalObservation_key,
                    cv_ClinicalObservation: observationCV
                });
            }
        }
    }

    createObservationDetail(initialValues: any): any {
        const initialTempID = initialValues.tempID;
        const initialCVKey = initialValues.C_ClinicalObservation_key;

        // Check local entities for duplicates
        const duplicates = this.clinicalObservationDetail.filter((detail) => {
            return softCompare(detail.tempID, initialTempID) &&
                softCompare(detail.C_ClinicalObservation_key, initialCVKey);
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.clinicalObservationDetail.push(initialValues);
        }

        return null;
    }

    onObservationClicked(observation: any) {
        this.selectedObservation = observation;
    }
}
