import {
    Component,
    OnInit,
    Input,
} from '@angular/core';
import { FeatureFlagService } from '../../services/feature-flags.service';
import { SettingService } from '../setting.service';
import { FacetType, IFacetSetting } from './facet-settings.interface';

@Component({
    selector: 'facet-settings',
    templateUrl: './facet-settings.component.html',
    styles: [`
        .facet-form {
            padding-top: 1em;
        }
        .settings-container {
            margin-bottom: 2em;
        }
    `]
})
export class FacetSettingsComponent implements OnInit {
    @Input() readonly: boolean;

    animalFacetSettings: IFacetSetting[];
    orderFacetSettings: IFacetSetting[];
    taskFacetSettings: IFacetSetting[];
    jobFacetSettings: IFacetSetting[];
    resourceFacetSettings: IFacetSetting[];
    cohortFacetSettings: IFacetSetting[];
    clinicalFacetSettings: IFacetSetting[];
    housingFacetSettings: IFacetSetting[];
    protocolFacetSettings: IFacetSetting[];
    studyFacetSettings: IFacetSetting[];
    sampleFacetSettings: IFacetSetting[];
    matingFacetSettings: IFacetSetting[];

    isGLP = false;
    isCRO = false;
    isCRL = false;
    
    constructor(
        private settingService: SettingService,
        private featureFlagService: FeatureFlagService
    ) {}

    ngOnInit() {
        this.initIsGLP();
        this.initIsCRO();
        this.initIsCRL();
        this.initializeTabData();
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    initIsCRO() {
        const flag = this.featureFlagService.getFlag("IsCRO");
        this.isCRO = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    initIsCRL() {
        const flag = this.featureFlagService.getFlag("IsCRL");
        this.isCRL = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    translate(displayName: string) {        
        if (this.isGLP && displayName.toLowerCase() === "client manager") {
            return "Alternate Contact";
        }
        return this.settingService.translateFacetSettingDisplayName(displayName);
    }

    initializeTabData() {
        this.settingService.getAllFacetSettings(this.isCRO, this.isGLP, this.isCRL).then((settings: IFacetSetting[][]) => {
            this.animalFacetSettings = this._findByType(settings, 'animal');
            this.orderFacetSettings = this._findByType(settings, 'order').concat(this._findByType(settings, 'order-animal'));
            this.taskFacetSettings = this._findByType(settings, 'task');
            this.jobFacetSettings = this._findByType(settings, 'job');
            this.resourceFacetSettings = this._findByType(settings, 'resource');
            this.clinicalFacetSettings = this._findByType(settings, 'clinical');
            this.housingFacetSettings = this._findByType(settings, 'housing');
            this.protocolFacetSettings = this._findByType(settings, 'protocol');
            this.studyFacetSettings = this._findByType(settings, 'study');
            this.sampleFacetSettings = this._findByType(settings, 'sample');
            this.matingFacetSettings = this._findByType(settings, 'mating');
        }).then(() => {
            if (this.isCRO || this.isGLP) {
                this.settingService.getFacetSettingsByTypeAndField('job', 'StudyDirector').then((results: IFacetSetting[]) => {
                    if (results.length > 0) {
                        this.jobFacetSettings.push(results[0]);
                        this.jobFacetSettings.sort((a: IFacetSetting, b: IFacetSetting) => {
                            return a.SortOrder - b.SortOrder;
                        });
                    }
                });
            }
        });
    }

    private _findByType(allSettings: IFacetSetting[][], facetType: FacetType) {
        return allSettings.find((settings: IFacetSetting[]) => {
            // Current settings array is empty, don't waste time checking facet type
            if (settings.length === 0) {
                return false;
            }

            // If facet type of first element matches, array contains all settings for specified facet type
            if (settings[0].FacetType) {
                return settings[0].FacetType.toLowerCase() === facetType.toLowerCase();
            }

            // No settings found for specified facet type
            return false;
        });
    }
}
