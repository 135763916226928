import {
    Component,
    Input,
    OnInit,
    OnChanges
} from '@angular/core';

import { JobService } from './job.service';

/**
 * Table for adding institutions to jobs
 */
@Component({
    selector: 'job-location-table',
    templateUrl: './job-location-table.component.html',
})
export class JobLocationTableComponent implements OnInit, OnChanges {
    @Input() job: any;
    @Input() readonly: boolean;
    @Input() required: boolean;

    constructor(
        private jobService: JobService
    ) {}

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (this.job && !changes.job.firstChange) {
            this.initialize();
        }
    }

    initialize() {
        //
    }
    addLocation(job: any) {
        if (this.readonly) {
            return;
        }
        let initialValues = {
            C_Job_key: job.C_Job_key,
        };
        this.jobService.createJobLocation(initialValues);
    }

    removeLocation(jobInstitution: any) {
        if (this.readonly) {
            return;
        }
        this.jobService.deleteJobLocation(jobInstitution);
    }
}
