import { AnimalClinicalObservation, AnimalDiagnosticObservation, AnimalHealthRecord, ClinicalObservationDetail, JobMaterial, TaskAnimalHealthRecord } from '@common/types';
import {
    CellFormatterService,
    TableOptions,
    URGENT_STATE_CLASS,
} from '@common/datatable';
import {
    sortObjectArrayByAccessor,
} from '@common/util';
import { DateFormatterService } from '@common/util/date-time-formatting';
import { TranslationService } from '@services/translation.service';
import { addAuditColumnsToOptions, ignoreColumnsInOptions, pipe } from '@common/datatable/utils';

export class ClinicalTableOptions {

    options: TableOptions;
    private isGLP = false;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private dateFormatterService: DateFormatterService,
        _isGLP: boolean
    ) {
        this.isGLP = _isGLP;
        this.options = pipe(
            this.getDefaultOptions(),
            addAuditColumnsToOptions(cellFormatterService),
            ignoreColumnsInOptions(),
        );
    }

    updateIsGLP(_isGLP: boolean) {
        this.isGLP = _isGLP;
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'healthrecords.csv',
            enableDetailColumn: true,
            enableDraggable: false,
            enableSelectable: true,
            rowClass: (params: any) => {
                const row = params.data;
                const classes: string[] = [];
                if (row &&
                    row.IsUrgent === true
                ) {
                    classes.push(URGENT_STATE_CLASS);
                }
                return classes;
            },
            columns: [
                {
                    displayName: 'Urgent',
                    field: 'IsUrgent',
                    formatter: this.cellFormatterService.urgentFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    width: 76,
                    maxWidth: 76,
                },
                {
                    displayName: 'Animal ID',
                    field: 'Animal.Material.Identifier',
                },
                {
                    displayName: 'Animal Name',
                    field: 'Animal.AnimalName',
                    sortField: 'Animal.AnimalNameSortable'
                },               
                {
                    displayName: 'Last Confirmed Date and Time',
                    field: 'LastConfirmedDateAndTime',
                    visible: false,
                    ignore: !this.isGLP,
                    formatter: (row: any, value: any) => {
                        return this.dateFormatterService.formatDateOrTime(value);
                    }
                },
                {
                    displayName: 'Microchip ID',
                    field: 'Animal.Material.MicrochipIdentifier',
                    visible: false,
                },
                {
                    displayName: 'Birth Date',
                    field: 'Animal.DateBorn',
                    visible: false,
                    formatter: this.cellFormatterService.dateFormatter
                },
                {
                    displayName: 'Location',
                    field: 'Animal.Material.CurrentLocationPath',
                    visible: false,
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'Animal.Material.Line.LineName',
                    visible: false,
                },
                {
                    displayName: 'Animal Health Tech',
                    field: 'Resource.ResourceName',
                    visible: false,
                },
                {
                    displayName: this.translationService.translate('Job'),
                    field: 'Animal.Material.JobMaterial[0].Job.JobID',
                    visible: false,
                    sortable: true,
                    sortField: 'JobsSortable',
                    formatter: (row: AnimalHealthRecord, value: any) => {
                        const values = [...row.Animal.Material.JobMaterial]
                        .map((currentJobMaterial: JobMaterial) => currentJobMaterial.Job.JobID);
                        return [...new Set(values)]
                        .sort((a: string, b: string) => a.localeCompare(b))
                        .join(', ');
                    },
                },
                {
                    displayName: 'Animal Status',
                    field: 'Animal.cv_AnimalStatus.AnimalStatus',
                },
                {
                    displayName: 'Animal External ID',
                    field: 'Animal.Material.ExternalIdentifier',
                    visible: false,
                },
                {
                    displayName: 'Body Condition Score',
                    field: 'Animal.cv_BodyConditionScore.BodyConditionScore',
                    visible: false,
                    headerClass: 'text-nowrap',
                    cellClass: 'num-cell'
                },
                {
                    displayName: 'Observations',
                    field: 'Animal.AnimalClinicalObservation[0]',
                    visible: false,
                    sortable: true,
                    sortField: 'AnimalClinicalObservationsSortable',
                    ignore: this.isGLP,
                    formatter: (row: AnimalHealthRecord, value: any) => {
                        if (this.isGLP) {
                            const values = row.Animal.AnimalClinicalObservation.map((obs: AnimalClinicalObservation) => {
                                return obs.cv_ClinicalObservation.ClinicalObservation;
                            });

                            return [].concat(values).join(', ');
                        } else {
                            const values = [...row.Animal.AnimalClinicalObservation]
                            .filter(obs => obs.ClinicalObservationDetail.length > 0)
                            .sort(this.compareDateObserved)
                            .map((obs: AnimalClinicalObservation) => {
                                return [...obs.ClinicalObservationDetail]
                                .map((detail: ClinicalObservationDetail) =>
                                    detail.cv_ClinicalObservation.ClinicalObservation);
                            });

                            return [].concat(values).join(', ');
                        }
                    },
                },
                {
                    displayName: 'Observation Status',
                    field: 'Animal.AnimalClinicalObservation[0].cv_ClinicalObservationStatus',
                    visible: false,
                    sortable: true,
                    sortField: 'AnimalClinicalObservationStatusesSortable',
                    ignore: this.isGLP,
                    formatter: (row: AnimalHealthRecord, value: any) => {
                        const values = [...row.Animal.AnimalClinicalObservation]
                        .sort(this.compareDateObserved)
                        .map((obs: AnimalClinicalObservation) => {
                            if (obs.cv_ClinicalObservationStatus) {
                                return obs.cv_ClinicalObservationStatus.ClinicalObservationStatus;
                            } else {
                                return null;
                            }
                        }).filter((obs: string | null) => obs !== null);

                        return [].concat(values).join(', ');
                    },
                },
                {
                    displayName: 'Clinical Observations',
                    field: 'Animal.AnimalClinicalObservation[0]',
                    visible: false,
                    sortable: true,
                    sortField: 'AnimalClinicalObservationsSortable',
                    ignore: !this.isGLP,
                    formatter: (row: AnimalHealthRecord, value: any) => {
                        if (this.isGLP) {
                            const values = [...row.Animal.AnimalClinicalObservation]
                            .sort(this.compareDateObserved)
                            .map((obs: AnimalClinicalObservation) => {
                                if (obs.cv_ClinicalObservation) {
                                    return obs.cv_ClinicalObservation.ClinicalObservation;
                                } else {
                                    return null;
                                }
                            })
                            .filter((obs: string | null) => obs !== null);



                            return [].concat(values).join(', ');
                        } else {
                            const values = row.Animal.AnimalClinicalObservation.map((obs: AnimalClinicalObservation) => {
                                return obs.ClinicalObservationDetail.map((detail: ClinicalObservationDetail) =>
                                    detail.cv_ClinicalObservation.ClinicalObservation);
                            });

                            return [].concat(values).join(', ');
                        }
                    },
                },
                {
                    displayName: 'Clinical Observation Status',
                    field: 'Animal.AnimalClinicalObservation[0].cv_ClinicalObservationStatus',
                    visible: false,
                    sortable: true,
                    sortField: 'AnimalClinicalObservationStatusesSortable',
                    ignore: !this.isGLP,
                    formatter: (row: AnimalHealthRecord, value: any) => {
                        const values = [...row.Animal.AnimalClinicalObservation]
                        .sort(this.compareDateObserved)
                        .map((obs: AnimalClinicalObservation) => {
                            if (obs.cv_ClinicalObservationStatus) {
                                return obs.cv_ClinicalObservationStatus.ClinicalObservationStatus;
                            } else {
                                return null;
                            }
                        }).filter((obs: string | null) => obs !== null);
                        return values.join(', ');
                    },
                },
                {
                    displayName: 'Diagnostic Observations',
                    field: 'Animal.AnimalDiagnosticObservation[0]',
                    visible: false,
                    sortable: true,
                    sortField: 'AnimalDiagnosticObservationsSortable',
                    ignore: !this.isGLP,
                    formatter: (row: AnimalHealthRecord, value: any) => {
                        if (this.isGLP) {
                            const values: string[] = [...row.Animal.AnimalDiagnosticObservation]
                            .sort(this.compareDateObserved)
                            .map((obs: AnimalDiagnosticObservation) => {
                                if (obs.cv_ClinicalObservation) {
                                    return obs.cv_ClinicalObservation.ClinicalObservation;
                                } else {
                                    return null;
                                }
                            }).filter((obs: string | null) => obs !== null);

                            return [].concat(values).join(', ');
                        }
                        return '';
                    },
                },
                {
                    displayName: 'Diagnostic Observation Status',
                    field: 'Animal.AnimalDiagnosticObservation[0].cv_ClinicalObservationStatus',
                    visible: false,
                    sortable: true,
                    sortField: 'AnimalDiagnosticObservationStatusesSortable',
                    ignore: !this.isGLP,
                    formatter: (row: AnimalHealthRecord, value: any) => {
                        if (this.isGLP) {
                            const values = [...row.Animal.AnimalDiagnosticObservation]
                            .sort(this.compareDateObserved)
                            .map((obs: AnimalDiagnosticObservation) => {
                                if (obs.cv_ClinicalObservationStatus) {
                                    return obs.cv_ClinicalObservationStatus.ClinicalObservationStatus;
                                } else {
                                    return null;
                                }
                            }).filter((obs: string | null) => obs !== null);
                            return values.join(', ');
                        }
                        return '';
                    },
                },
                {
                    // This will display the most recent due date where complete date is null
                    displayName: 'Due Date',
                    field: 'TaskAnimalHealthRecord.TaskInstance',
                    visible: false,
                    sortable: true,
                    sortField: 'TaskInstanceDateDueSortable',
                    formatter: (row: AnimalHealthRecord, value: any) => {
                        const incompleteRecords = row.TaskAnimalHealthRecord.filter((record: TaskAnimalHealthRecord) => record.TaskInstance.DateComplete === null);

                        if (incompleteRecords.length) {
                            sortObjectArrayByAccessor(incompleteRecords, (record: TaskAnimalHealthRecord) => record.TaskInstance.DateDue);
                            const closestDateRecord = incompleteRecords[0];
                            return this.dateFormatterService.formatDateOrTime(closestDateRecord.TaskInstance.DateDue);
                        } else {
                            return '';
                        }
                    },
                }
            ]
        };
    }

    compareDateObserved(a: AnimalClinicalObservation | AnimalDiagnosticObservation, b: AnimalClinicalObservation | AnimalDiagnosticObservation) {
        if (a.DateObserved > b.DateObserved) {
            return -1;
        }
        if (a.DateObserved < b.DateObserved) {
            return 1;
        }
        return 0;
    }
}
