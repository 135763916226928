import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    OnDestroy,
    ViewChildren,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import { ClinicalVocabService } from '../clinical-vocab.service';
import { ClinicalService } from '../clinical.service';
import { AnimalService } from '../../animals/services/animal.service';
import { ResourceService } from '../../resources/resource.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { WorkflowService } from '../../workflow/services/workflow.service';
import { ExportClinicalDetailService } from '../export-clinical-detail.service';
import { ClinicalAlertSenderComponent } from '../clinical-alert-sender/clinical-alert-sender.component';
import {
    randomId,
    scrollToElement,
    uniqueArrayFromPropertyPath,
    notEmpty,
} from '../../common/util';

import {
    BaseDetail,
    BaseDetailService,
    FacetView,
    IFacet,
    PageState
} from '../../common/facet';
import { Subscription } from 'rxjs';
import { ExportType } from '../../common/export';
import { SettingService } from '../../settings/setting.service';
import { IValidatable, OnSaveResult, SaveChangesService } from '../../services/save-changes.service';
import { FeatureFlagService } from '../../services/feature-flags.service';
import {
    Animal,
    cv_AnimalStatus,
    cv_BodyConditionScore,
    cv_ClinicalObservation,
    cv_ClinicalObservationStatus,
    cv_ExitReason,
    cv_TaskStatus,
    Resource,
    Entity,
    cv_Modifier1,
    cv_Modifier2,
    cv_Modifier3,
    cv_Modifier4,
    AnimalHealthRecord,
    AnimalClinicalObservation,
    TaskAnimalHealthRecord,
} from '../../common/types';
import { DateTime } from 'luxon';
import { AuthService } from "@services/auth.service";
import { dateControlValidator } from '@common/util/date-control.validator';

interface TaskType {
    C_WorkflowTask_key: number;
    TaskName: string;
}

interface IsSelected { isSelected: boolean; }
interface ParentId { parentId: number; }

type ExtendedModifier<T, withParentId extends boolean> = Entity<T>
    & IsSelected
    & (withParentId extends true ? ParentId : Record<string, unknown>);

interface HealthRecord extends Entity<AnimalHealthRecord> {
    DerivedAssignedTo?: string;
    DerivedDateDue?: Date | null;
    LastConfirmedDateAndTime?: Date | null;
}

@Component({
    selector: 'clinical-detail',
    templateUrl: './clinical-detail.component.html',
    styles: [`
        .event-add {
            font-weight: bold !important;
        }

        .event-delete {
            color: red !important;
        }

        .event-update {
            font-style: italic !important;
        }

        .label-center {
            margin:auto;
            display:block;
            text-align: center;
        }

        ::ng-deep .dropdown-header {
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
        }
    `]
})
export class ClinicalDetailComponent extends BaseDetail
    implements OnInit, OnChanges, OnDestroy, IValidatable, OnSaveResult {
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() animal: Entity<Animal>;
    @Input() pageState: PageState;
    @Input() noPaginator: boolean;
    @Input() isWorkflowAdd: boolean;

    @Output() isWorkflowAddChange = new EventEmitter<boolean>();
    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("clinicalForm") clinicalForm: NgForm;

    // vocabularies
    animalStatuses: Entity<cv_AnimalStatus>[];
    bodyConditionScores: Entity<cv_BodyConditionScore>[];
    clinicalObservationStatuses: Entity<cv_ClinicalObservationStatus>[];
    resources: Entity<Resource>[];
    taskTypes: TaskType[];
    taskStatuses: Entity<cv_TaskStatus>[];
    exitReasons: Entity<cv_ExitReason>[];
    clinicalObservation: Entity<cv_ClinicalObservation>[];
    diagnosticObservation: Entity<cv_ClinicalObservation>[];

    // CV defaults
    defaultAnimalStatusKey: number = null;
    defaultBodyConditionScoreKey: number = null;
    defaultHealthTechResourceKey: number = null;

    // Modifiers Value from DB
    modifiers1CV: ExtendedModifier<cv_Modifier1, false>[] = [];
    modifiers2CV: ExtendedModifier<cv_Modifier2, true>[] = [];
    modifiers3CV: ExtendedModifier<cv_Modifier3, true>[] = [];
    modifiers4CV: ExtendedModifier<cv_Modifier4, false>[] = [];

    healthRecord: HealthRecord;

    // state variables
    selectedObservation: Entity<AnimalClinicalObservation>;
    panelBodyClass: string;

    scrollTopElementClass = "clinical-detail-scroll-top-" + randomId();

    detailsExpanded = true;
    historyExpanded = false;

    isGLP = false;
    confirmStatus = false;

    // Active and required fields set by facet settings
    activeFields: string[] = [];
    requiredFields: string[] = [];

    exportTypes = ExportType;

    private readonly subs = new Subscription();
    readonly COMPONENT_LOG_TAG = 'clinical-detail';

    constructor(
        baseDetailService: BaseDetailService,
        private animalService: AnimalService,
        private resourceService: ResourceService,
        private clinicalService: ClinicalService,
        private vocabularyService: VocabularyService,
        private workflowService: WorkflowService,
        private clinicalVocabService: ClinicalVocabService,
        private exportClinicalService: ExportClinicalDetailService,
        private settingService: SettingService,
        private saveChangesService: SaveChangesService,
        private featureFlagService: FeatureFlagService,
        private modalService: NgbModal,
        private authService: AuthService
    ) {
        super(baseDetailService);
    }

    // lifecycle
    ngOnInit() {
        this.saveChangesService.registerValidator(this);
        this.subs.add(this.saveChangesService.saveResult$.subscribe(() => {
          this.onSaveResult();
        }));

        this.initIsGLP();
        this.initialize();
        if (this.facet.BulkDataConfiguration) {
            this.detailsExpanded = JSON.parse(this.facet.BulkDataConfiguration);
        }
    }

    ngOnChanges(changes: any) {
        if (changes.animal) {
            if (this.animal && !changes.animal.firstChange) {
                if (this.clinicalForm) {
                    this.clinicalForm.form.markAsPristine();
                }

                this.initialize();
            }
        }
    }

    ngOnDestroy() {
      this.subs.unsubscribe();
        this.saveChangesService.unregisterValidator(this);
        if (this.isGLP) {
            this.healthRecord.Animal.AnimalClinicalObservation.forEach((data: any) => {
                data.confirmStatus = false;
            });
            this.healthRecord.Animal.AnimalDiagnosticObservation.forEach((data: any) => {
                data.confirmStatus = false;
            });
        }
    }

    initialize() {
        if (!this.animal) {
            return;
        }

        this.healthRecord = this.animal.AnimalHealthRecord as Entity<AnimalHealthRecord>;
        this.selectedObservation = null;
        this.setLoading(true);
        this.initIsGLP();
        this.getDefaultHealthTechResource();

        return this.settingService.getFacetSettingsByType('clinical', undefined, this.isGLP).then((facetSettings) => {
            this.activeFields = this.settingService.getActiveFields(facetSettings);
            this.requiredFields = this.settingService.getRequiredFields(facetSettings);
        }).then(() => {
            return this.settingService.getFacetSettingsByTypeAndField('animal', 'C_AnimalClassification_key').then((facetSettingsAnimalClassification) => {
                this.activeFields = this.activeFields.concat(this.settingService.getActiveFields(facetSettingsAnimalClassification));
            });
        }).then(() => {
            return this.getCVs().then(() => {
                if (this.isGLP) {
                    this.getClinicalObservationsCVs();
                }
            });
        }).then(() => {
            if (this.isGLP) {
                this.getClinicalObservationsCVs();
            }
            return this.setupHealthRecord();
        }).then(() => {
            if (this.isGLP) {
                // show Selected Observation Modifiers
                this.healthRecord.Animal.AnimalClinicalObservation.forEach((observation: any) => {
                    this.clinicalObservationChanged(observation);
                    this.initModifiers(observation);
                });
                this.healthRecord.Animal.AnimalDiagnosticObservation.forEach((observation: any) => {
                    this.clinicalObservationChanged(observation);
                    this.initModifiers(observation);
                });
            }
            this.setLoading(false);
            this.setupSyncItem();
            if (this.isWorkflowAdd) {
                this.setAnimalStatusToDefaultHealthRecord(this.healthRecord);
                this.setHealthTechToDefault(this.healthRecord);
                this.setBodyConditionScoreToDefaultHealthRecord(this.healthRecord);
                this.isWorkflowAdd = false;
                this.isWorkflowAddChange.emit(this.isWorkflowAdd);
            }
        }).catch((error) => {
            this.setLoading(false);
            throw error;
        });
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    private getCVs(): Promise<any> {
        this.clinicalVocabService.animalStatuses$.subscribe((animalStatuses) => {
            this.animalStatuses = animalStatuses;
            this.getDefaultAnimalStatus();
        });
        this.clinicalVocabService.bodyConditionScores$.subscribe((bodyConditionScores) => {
            this.bodyConditionScores = bodyConditionScores;
            this.getDefaultBodyConditionScore();
        });
        this.clinicalVocabService.clinicalObservationStatuses$
            .subscribe((clinicalObservationStatuses) => {
                this.clinicalObservationStatuses = clinicalObservationStatuses;
            });
        this.clinicalVocabService.resources$.subscribe((resources) => {
            this.resources = resources;
        });
        this.clinicalVocabService.taskTypes$.subscribe((taskTypes) => {
            this.taskTypes = taskTypes;
        });
        this.clinicalVocabService.taskStatuses$.subscribe((taskStatuses) => {
            this.taskStatuses = taskStatuses;
        });
        this.clinicalVocabService.exitReasons$.subscribe((exitReasons) => {
            this.exitReasons = exitReasons;
        });
        if (this.isGLP) {
            this.vocabularyService.getClinicalObservationsWithModifiers().then((clinicalObservations: any) => {
                this.clinicalObservation = clinicalObservations.filter((co: any) => co.TrackInWorkflow === false);
                this.diagnosticObservation = clinicalObservations;
            });
        } else {
            this.clinicalVocabService.clinicalObservations$.subscribe((clinicalObservations) => {
                this.clinicalObservation = clinicalObservations;
                this.diagnosticObservation = clinicalObservations;
            });
        }

        return Promise.resolve();
    }

    getClinicalObservationsCVs(): Promise<any> {
        const p1 = this.vocabularyService.getCV('cv_Modifiers1').then((data) => {
            if (data.length > 0) {
                this.modifiers1CV = data;
            }
        }).catch((error: any) => console.log(error));

        const p2 = this.vocabularyService.getCV('cv_Modifiers2').then((data) => {
            if (data.length > 0) {
                this.modifiers2CV = data;
            }
        }).catch((error: any) => console.log(error));

        const p3 = this.vocabularyService.getCV('cv_Modifiers3').then((data) => {
            if (data.length > 0) {
                this.modifiers3CV = data;
            }
        }).catch((error: any) => console.log(error));

        const p4 = this.vocabularyService.getCV('cv_Modifiers4').then((data) => {
            if (data.length > 0) {
                this.modifiers4CV = data;
            }
        }).catch((error: any) => console.log(error));

        return Promise.all([p1, p2, p3, p4]);
    }

    get healthRecordJobName(): string {
        return this._getJobNames();
    }

    setupHealthRecord(): Promise<any> {
        const animalExpands = [
            "cv_Sex",
            "Material.cv_Taxon",
            "Material.Line",
            "Material.JobMaterial.Job",
            "AnimalClinicalObservation.ClinicalObservationDetail.cv_ClinicalObservation",
            "AnimalClinicalObservation.Event.cv_EventType",
            "AnimalClinicalObservation.Event",
            "AnimalClinicalObservation.Resource",
            "AnimalHealthRecord.StoredFileMap",
            "AnimalHealthRecord.TaskAnimalHealthRecord.TaskInstance.AssignedToResource",
            "AnimalHealthRecord.TaskAnimalHealthRecord.TaskInstance.TaskInput.Input"
        ];
        const materialKey = this.animal.C_Material_key;
        if (this.isGLP) {
            animalExpands.push("AnimalClinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier1.cv_Modifier1");
            animalExpands.push("AnimalClinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier2.cv_Modifier2");
            animalExpands.push("AnimalClinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier3.cv_Modifier3");
            animalExpands.push("AnimalClinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier4.cv_Modifier4");
            animalExpands.push("AnimalDiagnosticObservation.Event");
            animalExpands.push("AnimalDiagnosticObservation.Event.cv_EventType");
        }
        return this.animalService.getAnimalClinical(materialKey, animalExpands).then((animal) => {
            if (animal && animal.AnimalHealthRecord) {
                this.healthRecord = animal.AnimalHealthRecord;
            }
        });
    }

    // On Changing Clinical Observation Option Rebuild selection options
    clinicalObservationChanged(clinicalObservation: any) {
        if (clinicalObservation) {
            clinicalObservation.modifier1VocabOptions = [];
            clinicalObservation.modifier2VocabOptions = [];
            clinicalObservation.modifier3VocabOptions = [];
            clinicalObservation.modifier4VocabOptions = [];
            if (clinicalObservation.cv_ClinicalObservation) {
                if (clinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier1) {
                    const cvCOModifier1 = clinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier1
                        .filter((mod1: any) => mod1.C_ClinicalObservation_key === clinicalObservation.C_ClinicalObservation_key);
                    clinicalObservation.modifier1VocabOptions = cvCOModifier1.map((mod1: any) => mod1.cv_Modifier1);
                }
                if (clinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier2) {
                    const cvCOModifier2 = clinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier2
                        .filter((mod1: any) => mod1.C_ClinicalObservation_key === clinicalObservation.C_ClinicalObservation_key);
                    clinicalObservation.modifier2VocabOptions = cvCOModifier2.map((mod2: any) => mod2.cv_Modifier2);
                }
                if (clinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier3) {
                    const cvCOModifier3 = clinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier3
                        .filter((mod1: any) => mod1.C_ClinicalObservation_key === clinicalObservation.C_ClinicalObservation_key);
                    clinicalObservation.modifier3VocabOptions = cvCOModifier3.map((mod3: any) => mod3.cv_Modifier3);
                }
                if (clinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier4) {
                    const cvCOModifier4 = clinicalObservation.cv_ClinicalObservation.cv_ClinicalObservationModifier4
                        .filter((mod1: any) => mod1.C_ClinicalObservation_key === clinicalObservation.C_ClinicalObservation_key);
                    clinicalObservation.modifier4VocabOptions = cvCOModifier4.map((mod4: any) => mod4.cv_Modifier4);
                }
            }

            const modifier1OptionKeys = clinicalObservation.modifier1VocabOptions.map((option: any) => option.C_Modifier1_key);
            const modifier2OptionKeys = clinicalObservation.modifier2VocabOptions.map((option: any) => option.C_Modifier2_key);
            const modifier3OptionKeys = clinicalObservation.modifier3VocabOptions.map((option: any) => option.C_Modifier3_key);
            const modifier4OptionKeys = clinicalObservation.modifier4VocabOptions.map((option: any) => option.C_Modifier4_key);

            this.modifiers1CV.forEach((mod1) => {
                mod1.isSelected = mod1.C_Modifier1_key === clinicalObservation.C_Modifier1_key;
            });

            this.modifiers2CV.forEach((mod2) => {
                mod2.isSelected = mod2.C_Modifier2_key === clinicalObservation.C_Modifier2_key;
            });

            this.modifiers3CV.forEach((mod3) => {
                mod3.isSelected = mod3.C_Modifier3_key === clinicalObservation.C_Modifier3_key;
            });

            this.modifiers4CV.forEach((mod4) => {
                mod4.isSelected = mod4.C_Modifier4_key === clinicalObservation.C_Modifier4_key;
            });

            clinicalObservation.modifier1VocabOptions = [
                ...clinicalObservation.modifier1VocabOptions,
                {
                    C_Modifier1_key: 0, Modifier1: 'All', isLabel: true
                },
                ...this.modifiers1CV
                .filter((observation: any) => !modifier1OptionKeys.includes(observation.C_Modifier1_key) && (observation.IsActive || observation.isSelected)  )
                .map((observation: any) => {
                    observation.parentId = 0;
                    return observation;
                })
            ];
            clinicalObservation.modifier2VocabOptions = [
                ...clinicalObservation.modifier2VocabOptions,
                {
                    C_Modifier2_key: 0, Modifier2: 'All', isLabel: true
                },
                ...this.modifiers2CV
                .filter((observation: any) => !modifier2OptionKeys.includes(observation.C_Modifier2_key) && (observation.IsActive || observation.isSelected)   )
                .map((observation: any) => {
                    observation.parentId = 0;
                    return observation;
                })
            ];
            clinicalObservation.modifier3VocabOptions = [
                ...clinicalObservation.modifier3VocabOptions,
                {
                    C_Modifier3_key: 0, Modifier3: 'All', isLabel: true
                },
                ...this.modifiers3CV
                .filter((observation: any) => !modifier3OptionKeys.includes(observation.C_Modifier3_key) && (observation.IsActive || observation.isSelected)  )
                .map((observation: any) => {
                    observation.parentId = 0;
                    return observation;
                })
            ];
            clinicalObservation.modifier4VocabOptions = [
                ...clinicalObservation.modifier4VocabOptions,
                {
                    C_Modifier4_key: 0, Modifier4: 'All', isLabel: true
                },
                ...this.modifiers4CV
                .filter((observation: any) => !modifier4OptionKeys.includes(observation.C_Modifier4_key) && (observation.IsActive || observation.isSelected)  )
                .map((observation: any) => {
                    observation.parentId = 0;
                    return observation;
                })
            ];
        }
    }

    initModifiers(observation: any) {
        if (observation.C_Modifier1_key) {
            setTimeout(() => {
                observation.Modifier1Key = [observation.C_Modifier1_key];
            }, 1);
        } else {
            observation.Modifier1Key = [];
        }

        if (observation.C_Modifier2_key) {
            setTimeout(() => {
                observation.Modifier2Key = [observation.C_Modifier2_key];
            }, 1);
        } else {
            observation.Modifier2Key = [];
        }
        if (observation.C_Modifier3_key) {
            setTimeout(() => {
                observation.Modifier3Key = [observation.C_Modifier3_key];
            }, 1);
        } else {
            observation.Modifier3Key = [];
        }
        if (observation.C_Modifier4_key) {
            setTimeout(() => {
                observation.Modifier4Key = [observation.C_Modifier4_key];
            }, 1);
        } else {
            observation.Modifier4Key = [];
        }
    }

    applyModifier1Key(observation: any) {
        if (observation.C_Modifier1_key && observation.C_Modifier1_key !== observation.C_Modifier1_key[0]) {
            observation.C_Modifier1_key = observation.C_Modifier1_key[0];
        }
    }

    applyModifier2Key(observation: any) {
        if (observation.C_Modifier2_key && observation.C_Modifier2_key !== observation.C_Modifier2_key[0]) {
            observation.C_Modifier2_key = observation.C_Modifier2_key[0];
        }
    }

    applyModifier3Key(observation: any) {
        if (observation.C_Modifier3_key && observation.C_Modifier3_key !== observation.C_Modifier3_key[0]) {
            observation.C_Modifier3_key = observation.C_Modifier3_key[0];
        }
    }

    applyModifier4Key(observation: any) {
        if (observation.C_Modifier4_key && observation.C_Modifier4_key !== observation.C_Modifier4_key[0]) {
            observation.C_Modifier4_key = observation.C_Modifier4_key[0];
        }
    }

    onCancel() {
        this.clinicalService.cancelHealthRecord(this.healthRecord);
    }

    export(exportType: ExportType) {
        this.exportClinicalService.export(this.healthRecord, exportType, this.isGLP);
    }

    async validate(): Promise<string> {
        const dateErrorMessage = dateControlValidator(this.dateControls);
        if (dateErrorMessage) {
            return dateErrorMessage;
        }

        const areClinicalObservationsValid = this.healthRecord?.Animal?.AnimalClinicalObservation?.every((observation: any) => observation.DateObserved);

        const areObservationCommentsValid = this.healthRecord?.Animal?.AnimalClinicalObservation?.every((observation: AnimalClinicalObservation) =>
            !observation.Comments || observation.Comments?.length <= 1000);

        const areTaskInstanceCommentsValid = this.healthRecord?.TaskAnimalHealthRecord?.every((record: TaskAnimalHealthRecord) =>
            !record.TaskInstance?.Comments || record.TaskInstance?.Comments?.length <= 1000);

        const areTaskInputsValid = this.healthRecord?.TaskAnimalHealthRecord?.every((record: TaskAnimalHealthRecord) => record.TaskInstance?.TaskInput?.every(x =>
            !x.InputValue || x.InputValue?.length <= 1000));

        if (!areObservationCommentsValid) {
            return 'You have exceeded the 1000-character limit for clinical observation comments. Please create a new clinical observation to save this information.';
        }

        if (!areTaskInputsValid) {
            return 'You have exceeded the 1000-character limit for treatment plans. Please create a new treatment plan to save this information.';
        }

        if (!areTaskInstanceCommentsValid) {
            return 'You have exceeded the 1000-character limit for treatment plan comments. Please create a new treatment plan to save this information.';
        }

        if (!areClinicalObservationsValid) {
            return 'Ensure that all required fields within Clinical Observations are filled.';
        }

        const areDiagnosticObservationsValid = this.healthRecord?.Animal?.AnimalDiagnosticObservation?.every((observation: any) => observation.DateObserved);

        if (!areDiagnosticObservationsValid) {
            return 'Ensure that all required fields within Diagnostic Observations are filled.';
        }

        return await this.settingService.validateRequiredFields(this.requiredFields, this.healthRecord, 'clinical');
    }

    onSaveResult() {
        this.loggingService.logDebug('Save result reported in Clinical Details (may be success or failure)', null, this.COMPONENT_LOG_TAG);
    }

    getDefaultTaskStatus(taskStatuses: any[]) {
        const defaultStatuses = taskStatuses.filter((status) => {
            return status.IsDefault;
        });
        return defaultStatuses.length > 0 ? defaultStatuses[0] : null;
    }

    getDefaultClinicalObservation(clinicalObservations: any[]) {
        const defaultClinicalObservation = clinicalObservations.filter((clinicalObservation) => {
            return clinicalObservation.IsDefault;
        });
        return defaultClinicalObservation.length > 0 ? defaultClinicalObservation[0] : null;
    }

    getDefaultEndTaskStatus(taskStatuses: any[]) {
        const defaultEndStatuses = taskStatuses.filter((status) => {
            return status.IsDefaultEndState;
        });
        return defaultEndStatuses.length > 0 ? defaultEndStatuses[0] : null;
    }

    confirmObservation(observation: any) {
        const date = DateTime.now().toISO();
        observation.ReviewDate = date;
        this.healthRecord.LastConfirmedDateAndTime = date as any;
        this.confirmStatus = true;
        observation.confirmStatus = this.confirmStatus;

        // Update the Review Date to DB
        this.clinicalService.updateReviewDate(observation);
    }

    addObservation() {
        const newObservation = this.clinicalService.createObservation(
            {
                C_Material_key: this.healthRecord.Animal.C_Material_key,
                JobName: this._getJobNames(),
                ObservedByUsername: this.authService.getCurrentUserName()
            }
        );

        // set current user as resource if possible
        this.resourceService.getCurrentUserResource().then((resource: any) => {
            if (resource) {
                newObservation.C_Resource_key = resource.C_Resource_key;
            }
        });
        // set default observation status
        this.vocabularyService.getCVDefault('cv_ClinicalObservationStatuses')
            .then((value: any) => {
                newObservation.cv_ClinicalObservationStatus = value;
            });
        // set default body condition score
        this.vocabularyService.getCVDefault('cv_BodyConditionScores')
            .then((value: any) => {
                newObservation.cv_BodyConditionScore = value;
            });

        // set default clinical observation
        if (!this.isGLP) {
            this.vocabularyService.getCVDefault('cv_ClinicalObservations')
                .then((value: any) => {
                    this.createNewObservationDetails(newObservation, [value]);
                    this.selectedObservation = value;
                });
        }

        // set observed date to today
        newObservation.DateObserved = Date.now();

        this.clinicalObservationChanged(newObservation);
    }

    addDiagnosticObservation() {
        const newObservation = this.clinicalService.createDiagnosticObservation(
            {
                C_Material_key: this.healthRecord.Animal.C_Material_key,
                JobName: this._getJobNames(),
                ObservedByUsername: this.authService.getCurrentUserName()
            }
        );
        // set current user as resource if possible
        this.resourceService.getCurrentUserResource().then((resource: any) => {
            if (resource) {
                newObservation.C_Resource_key = resource.C_Resource_key;
            }
        });
        // set default observation status
        this.vocabularyService.getCVDefault('cv_ClinicalObservationStatuses')
            .then((value: any) => {
                newObservation.cv_ClinicalObservationStatus = value;
            });
        // set default body condition score
        this.vocabularyService.getCVDefault('cv_BodyConditionScores')
            .then((value: any) => {
                newObservation.cv_BodyConditionScore = value;
            });

        // set observed date to today
        newObservation.DateObserved = Date.now();

        this.clinicalObservationChanged(newObservation);
    }

    removeObservation(observation: any) {
        this.clinicalService.deleteObservation(observation);

        this.getLastConfirmedDateAndTime(observation);
    }

    removeDiagnosticObservation(observation: any) {
        this.clinicalService.deleteDiagnosticObservation(observation);

        this.getLastConfirmedDateAndTime(observation);
    }

    getLastConfirmedDateAndTime(observation: any) {
        if (this.healthRecord.LastConfirmedDateAndTime.getTime() === observation.ReviewDate.getTime()) {
            this.healthRecord.LastConfirmedDateAndTime = null;

            let maxClinicalDate = null;
            const clinicalDates: any[] = this.healthRecord.Animal.AnimalClinicalObservation.filter((item: any) => item.ReviewDate).map((observationItem: any) => {
                return observationItem.ReviewDate;
            });
            if (clinicalDates.length > 0) {
                maxClinicalDate = new Date(Math.max.apply(null, clinicalDates));
            }

            let maxDiagnosticDate = null;
            const diagnosticDates: any[] = this.healthRecord.Animal.AnimalDiagnosticObservation.filter((item: any) => item.ReviewDate).map((observationItem: any) => {
                return observationItem.ReviewDate;
            });
            if (diagnosticDates.length > 0) {
                maxDiagnosticDate = new Date(Math.max.apply(null, diagnosticDates));
            }

            if (maxClinicalDate !== null || maxDiagnosticDate !== null) {
                if (maxClinicalDate > maxDiagnosticDate) {
                    this.healthRecord.LastConfirmedDateAndTime = maxClinicalDate;
                } else {
                    this.healthRecord.LastConfirmedDateAndTime = maxDiagnosticDate;
                }
            }
        }
    }

    openObservationChooser(observationmodal: TemplateRef<any>, observation: any) {
        this.selectedObservation = observation;
        this.modalService.open(observationmodal);
    }

    onSelectObservations(selected: any) {
        if (this.isGLP) {
            if (selected) {
                this.selectedObservation.C_ClinicalObservation_key = selected.C_ClinicalObservation_key;
                this.selectedObservation.C_BodySystem_key = selected.C_BodySystem_key;
            } else {
                this.selectedObservation.C_ClinicalObservation_key = null;
                this.selectedObservation.C_BodySystem_key = null;
                this.selectedObservation.cv_Modifier1 = undefined;
                this.selectedObservation.cv_Modifier2 = undefined;
                this.selectedObservation.cv_Modifier3 = undefined;
                this.selectedObservation.cv_Modifier4 = undefined;
            }
            this.clinicalObservationChanged(this.selectedObservation);
            this.initModifiers(this.selectedObservation);
        } else {
            // create new observations based on selections
            this.deleteUnselectedObservationDetails(this.selectedObservation, selected);
            this.createNewObservationDetails(this.selectedObservation, selected);
        }
    }

    /**
     * Delete those observation statuses that are in the current observation
     *   but not in the set of selected observation statuses
     * @param observation - current AnimalClinicalObservation
     * @param observationCVs - selected cv_ClinicalObservationStatuses
     */
    deleteUnselectedObservationDetails(observation: any, observationCVs: any[]) {
        // find observations missing from selection
        const currentDetails = uniqueArrayFromPropertyPath(
            observation, 'ClinicalObservationDetail'
        );
        const missingObservationDetails = currentDetails.filter((current) => {
            return observationCVs.indexOf(current.cv_ClinicalObservation) < 0;
        });

        // delete observations missing from selection
        for (const observationDetail of missingObservationDetails) {
            this.clinicalService.deleteObservationDetail(observationDetail);
        }
    }

    /**
     * Create new observations for those in selected that
     *   are not in current observation
     * @param observation - current AnimalClinicalObservation
     * @param observationCVs - selected cv_ClinicalObservationStatuses
     */
    createNewObservationDetails(observation: any, observationCVs: any[]) {
        // find observations in selection missing from current
        const currentObservationCVs = uniqueArrayFromPropertyPath(
            observation, 'ClinicalObservationDetail.cv_ClinicalObservation'
        );
        const missingObservationCVs = observationCVs.filter((selected) => {
            return currentObservationCVs.indexOf(selected) < 0;
        });

        // create observations missing from current
        for (const observationCV of missingObservationCVs) {
            this.clinicalService.createObservationDetail(
                {
                    C_AnimalClinicalObservation_key: observation ? observation.C_AnimalClinicalObservation_key : null,
                    C_ClinicalObservation_key: observationCV.C_ClinicalObservation_key,
                }
            );
        }
    }

    createNewDiagnosticObservationDetails(observation: any, observationCVs: any[]) {
        // find observations in selection missing from current
        const currentObservationCVs = uniqueArrayFromPropertyPath(
            observation, 'DiagnosticObservationDetail.cv_ClinicalObservation'
        );
        const missingObservationCVs = observationCVs.filter((selected) => {
            return currentObservationCVs.indexOf(selected) < 0;
        });

        // create observations missing from current
        for (const observationCV of missingObservationCVs) {
            this.clinicalService.createObservationDetail(
                {
                    C_AnimalClinicalObservation_key: observation ? observation.C_AnimalClinicalObservation_key : null,
                    C_ClinicalObservation_key: observationCV.C_ClinicalObservation_key
                }
            );
        }
    }

    animalStatusChanged() {
        this.animalService.statusChangePostProcess(this.animal);
    }

    isUrgentChanged() {
        if (!this.isGLP && this.healthRecord.IsUrgent) {
            this.openAlertSender();
        }
    }

    private openAlertSender() {
        const modalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
        };

        const ref = this.modalService.open(ClinicalAlertSenderComponent, modalOptions);
        const component = ref.componentInstance as ClinicalAlertSenderComponent;
        component.healthRecord = this.healthRecord;
    }

    // task controls
    addTask(): Promise<any> {
        // TODO: Put health record tasks types in a select and get the key from that selection
        const workflowTaskKey = this.taskTypes[0].C_WorkflowTask_key;
        const taskAlias = this.taskTypes[0].TaskName;
        return this.clinicalService.createHealthRecordTask(
            this.healthRecord.C_Material_key,
            {
                C_WorkflowTask_key: workflowTaskKey,
                TaskAlias: taskAlias
            },
            undefined,
            this._getJobNames()
        ).then((newTask: any) => {
            // set task due date to today
            newTask.DateDue = new Date();

            const defaultTaskStatus = this.getDefaultTaskStatus(this.taskStatuses);
            if (defaultTaskStatus) {
                newTask.C_TaskStatus_key = defaultTaskStatus.C_TaskStatus_key;
            }
        });
    }

    removeTask(task: any) {
        this.workflowService.deleteTask(task);
    }

    deleteEvent(event: any): void {
        this.clinicalService.deleteEvent(event);
    }

    markComplete(task: any) {
        if (task.DateComplete) {
            // clear completed if already set
            task.C_CompletedBy_key = null;
            task.DateComplete = null;

        } else {
            // set completed if not
            // get current user resource for completed by
            this.resourceService.getCurrentUserResource().then((resource: any) => {
                if (resource) {
                    task.C_CompletedBy_key = resource.C_Resource_key;
                }
            });
            task.DateComplete = new Date();

            const defaultEndTaskStatus = this.getDefaultEndTaskStatus(this.taskStatuses);
            if (defaultEndTaskStatus) {
                task.C_TaskStatus_key = defaultEndTaskStatus.C_TaskStatus_key;
            }
        }
    }

    onDetailsExpand() {
        this.facet.BulkDataConfiguration = this.detailsExpanded.toString();
    }

    async setupSyncItem() {
        await this.saveChangesService.promptForUnsavedChanges(this.COMPONENT_LOG_TAG);
        await scrollToElement('.' + this.scrollTopElementClass);
    }

    private setAnimalStatusToDefaultHealthRecord(healthRecord: any) {
        if (!healthRecord?.Animal?.C_AnimalStatus_key) {
            healthRecord.Animal.C_AnimalStatus_key = this.defaultAnimalStatusKey;
        }
    }

    private setHealthTechToDefault(healthRecord: any) {
        if (!healthRecord?.C_Resource_key) {
            healthRecord.C_Resource_key = this.defaultHealthTechResourceKey;
        }
    }

    private setBodyConditionScoreToDefaultHealthRecord(healthRecord: any) {
        if (!healthRecord?.Animal?.C_BodyConditionScore_key) {
            healthRecord.Animal.C_BodyConditionScore_key = this.defaultBodyConditionScoreKey;
        }
    }

    private getDefaultAnimalStatus() {
        this.defaultAnimalStatusKey = null;

        if (notEmpty(this.animalStatuses)) {
            for (const item of this.animalStatuses) {
                if (item.IsDefaultHealthRecord === true) {
                    this.defaultAnimalStatusKey = item.C_AnimalStatus_key;
                    break;
                }
            }
        }
    }

    private getDefaultHealthTechResource(): Promise<any> {
        this.defaultHealthTechResourceKey = null;

        return this.settingService.getDefaultHealthTechSetting().then((data: any) => {
            if (data) {
                this.defaultHealthTechResourceKey = data.C_DefaultHealthTechnician_key;
            }
        });
    }

    private getDefaultBodyConditionScore() {
        this.defaultBodyConditionScoreKey = null;
        if (notEmpty(this.bodyConditionScores)) {
            for (const item of this.bodyConditionScores) {
                if (item.IsDefault === true) {
                    this.defaultBodyConditionScoreKey = item.C_BodyConditionScore_key;
                    break;
                }
            }
        }
    }

    onObservationClicked(observation: any) {
        this.selectedObservation = observation;
    }

    // formatters for <select> inputs
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
    bodyScoreKeyFormatter = (value: any) => {
        return value.C_BodyConditionScore_key;
    }
    bodyScoreFormatter = (value: any) => {
        return value.BodyConditionScore;
    }
    observationStatusKeyFormatter = (value: any) => {
        return value.C_ClinicalObservationStatus_key;
    }
    observationStatusFormatter = (value: any) => {
        return value.ClinicalObservationStatus;
    }
    clinicalObservationKeyFormatter = (value: any) => {
        return value.C_ClinicalObservation_key;
    }
    clinicalObservationFormatter = (value: any) => {
        return value.ClinicalObservation;
    }

    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }
    taskStatusKeyFormatter = (value: any) => {
        return value.C_TaskStatus_key;
    }
    taskStatusFormatter = (value: any) => {
        return value.TaskStatus;
    }
    exitReasonKeyFormatter = (value: any) => {
        return value.C_ExitReason_key;
    }
    exitReasonFormatter = (value: any) => {
        return value.ExitReason;
    }
    modifier1KeyFormatter = (value: any) => {
        return value.C_Modifier1_key;
    }
    modifier1Formatter = (value: any) => {
        return value.Modifier1;
    }
    modifier2KeyFormatter = (value: any) => {
        return value.C_Modifier2_key;
    }
    modifier2Formatter = (value: any) => {
        return value.Modifier2;
    }
    modifier3KeyFormatter = (value: any) => {
        return value.C_Modifier3_key;
    }
    modifier3Formatter = (value: any) => {
        return value.Modifier3;
    }
    modifier4KeyFormatter = (value: any) => {
        return value.C_Modifier4_key;
    }
    modifier4Formatter = (value: any) => {
        return value.Modifier4;
    }

    private _getJobNames(): string {
        let jobMaterials = this.healthRecord.Animal.Material.JobMaterial;
        let jobNames = '';
        if (jobMaterials) {
            if (this.isGLP) {
                jobMaterials = jobMaterials.filter((jm: any) => !jm.DateOut);
            } else {
                jobMaterials = jobMaterials.sort((a: any, b: any) => b.DateCreated - a.DateCreated);
            }
            jobNames = jobMaterials.map((jm: any) => jm.Job.JobID).join(",");
        }
        return jobNames;
    }
}
