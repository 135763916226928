import { map } from 'rxjs/operators';
import { BulkAddCommService } from './../../common/facet/bulk-add-comm.service';
import { QueryDef } from './../../services/query-def';
import { SampleService } from './../sample.service';
import { SampleVocabService } from './../sample-vocab.service';
import { SampleLogicService } from './../sample-logic.service';
import { TranslationService } from '../../services/translation.service';
import { 
    BulkEditOptions, 
    BulkEditField,
    BulkEditSection 
} from '../../common/facet/models';
import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    ViewChildren,
} from '@angular/core';

import { JobService } from '../../jobs/job.service';
import { NamingService } from '../../services/naming.service';

import { notEmpty } from '../../common/util';

import { FacetLoadingStateService } from '../../common/facet';
import { LocationService } from '../../locations/location.service';
import { DotmaticsService } from '../../dotmatics/dotmatics.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { CharacteristicInputComponent } from 'src/app/characteristics/characteristic-input/characteristic-input.component';

/**
 * Shared component and configuration templates
 * for BulkAdd and BulkEdit tables
 * 
 * 
 */
@Component({
    selector: 'sample-bulk-templates',
    templateUrl: './sample-bulk-templates.component.html',
    styleUrls: ['./sample-bulk-templates.component.scss']
})
export class SampleBulkTemplatesComponent implements OnInit, AfterViewInit {
    @ViewChildren('characteristicInput') characteristicInputs: CharacteristicInputComponent[];
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() samples: any[];
    @Input() addLineDisabled: boolean;
    @Input() activeFields: string[];
    @Input() requiredFields: string[];

    // bulk edit input templates
    @ViewChild('idTmpl') idTmpl: TemplateRef<any>;
    @ViewChild('nameTmpl') nameTmpl: TemplateRef<any>;
    @ViewChild('sourceTmpl') sourceTmpl: TemplateRef<any>;
    @ViewChild('sampleTypeTmpl') sampleTypeTmpl: TemplateRef<any>;
    @ViewChild('timePointTmpl') timePointTmpl: TemplateRef<any>;
    @ViewChild('harvestDateTmpl') harvestDateTmpl: TemplateRef<any>;
    @ViewChild('expirationDateTmpl') expirationDateTmpl: TemplateRef<any>;
    @ViewChild('lineTmpl') lineTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl') statusTmpl: TemplateRef<any>;
    @ViewChild('preservationTmpl') preservationTmpl: TemplateRef<any>; 
    @ViewChild('originTmpl') originTmpl: TemplateRef<any>;
    @ViewChild('microchipIdTmpl') microchipIdTmpl: TemplateRef<any>;
    @ViewChild('externalIDTmpl') externalIDTmpl: TemplateRef<any>;
    @ViewChild('containerTmpl') containerTmpl: TemplateRef<any>;
    @ViewChild('measurementTmpl') measurementTmpl: TemplateRef<any>;
    @ViewChild('unitTmpl') unitTmpl: TemplateRef<any>;
    @ViewChild('jobTmpl') jobTmpl: TemplateRef<any>;
    @ViewChild('locationTmpl') locationTmpl: TemplateRef<any>;
    @ViewChild('descriptionTmpl') descriptionTmpl: TemplateRef<any>;
    @ViewChild('lotNumberTmpl') lotNumberTmpl: TemplateRef<any>;
    @ViewChild('labelTmpl') labelTmpl: TemplateRef<any>;
    @ViewChild('characteristicTmpl') characteristicTmpl: TemplateRef<any>;
    @ViewChild('sampleSubtypeTmpl') sampleSubtypeTmpl: TemplateRef<any>;
    @ViewChild('sampleProcessingMethodTmpl') sampleProcessingMethodTmpl: TemplateRef<any>;
    @ViewChild('sendToTmpl') sendToTmpl: TemplateRef<any>;
    @ViewChild('sampleAnalysisMethodTmpl') sampleAnalysisMethodTmpl: TemplateRef<any>;

    // CVs
    containerTypes: any[];
    materialOrigins: any[];
    preservationMethods: any[];
    sampleStatuses: any[];
    sampleTypes: any[];
    sampleNamingActive = false;
    timeUnits: any[];
    units: any[];
    sampleSubtypes: any[];
    sampleProcessingMethods: any[];
    sampleAnalysisMethods: any[];

    // Default CVs
    sampleTypeDefaultKey: any;
    sampleStatusDefaultKey: any;
    materialOriginDefaultKey: any;
    preservationMethodDefaultKey: any;
    containerTypeDefaultKey: any;
    unitDefaultKey: any;
    sampleSubtypeDefaultKey: any;
    sampleProcessingMethodDefaultKey: any;
    sampleAnalysisMethodDefaultKey: any;
    sampleOrders: any[];   

    rootLocationPositionPromise: Promise<any>;
    rootLocationPosition: any;

    readonly COMPONENT_LOG_TAG = 'sample-bulk-edit';
    readonly MULTI_PASTE_INPUT_LIMIT = 150;

    bulkOptions: BulkEditOptions;
    BulkEditSection = BulkEditSection;

    // Dotmatics workgroup flag
    isDotmatics: boolean;

    constructor(
        private bulkAddCommService: BulkAddCommService,
        private sampleService: SampleService,
        private sampleLogicService: SampleLogicService,
        private sampleVocabService: SampleVocabService,
        private facetLoadingState: FacetLoadingStateService,
        private jobService: JobService,
        private locationService: LocationService,
        private namingService: NamingService,
        private translateService: TranslationService,
        private dotmaticsService: DotmaticsService
    ) { }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.getData();
        this.setIsDotmatics();
    }

    /**
     * Configuration with TemplateRefs can only be assigned
     *   after "ngAfterViewInit".
     * Otherwise they will be undefined.
     */
    ngAfterViewInit() {
        // assign all the BulkAdd and BulkEdit configuration options
        this.bulkOptions = {
            itemTypeLabel: "Sample",
            itemTypeLabelPlural: "Samples",
            clearForm: false,
            fields: [
                {
                    label: "ID",
                    modelPath: 'Material.Identifier',
                    template: this.idTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Name',
                    labelTooltip: `Enter one or multiple names separated by commas or Enter.  
                        You may copy and paste a list of sample names.  Limit is 200.`,
                    modelPath: 'SampleNames',
                    hideFromEditHeader: false,
                    template: this.nameTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownName(item.SampleNames);
                    }
                },
                {
                    label: 'Source',
                    modelPath: 'Material.MaterialSourceMaterial[0]',
                    modelSettingName: 'Material.MaterialSourceMaterial[0]',
                    template: this.sourceTmpl,
                    onItemInit: (item) => {
                        if (!Array.isArray(item.Material?.MaterialSourceMaterial)) {
                            item.Material.MaterialSourceMaterial = [];
                        }
                    },
                    onUpdateItem: (item) => {
                        this.fillDownSource(item);
                    }
                    
                },
                {
                    label: 'Type',
                    modelPath: 'C_SampleType_key',
                    template: this.sampleTypeTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownType(value);
                    }
                },
                {
                    label: 'Time Point',
                    modelPath: 'TimePoint',
                    template: this.timePointTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownTimePoint(item);
                    },
                },
                {
                    label: 'Harvest Date',
                    modelPath: 'DateHarvest',
                    template: this.harvestDateTmpl,
                },
                {
                    label: 'Expiration Date',
                    modelPath: 'DateExpiration',
                    template: this.expirationDateTmpl,
                },
                {
                    label: this.translateService.translate('Line'),
                    modelPath: 'Material.C_Line_key',
                    template: this.lineTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownLine(value);
                    },
                },
                {
                    label: 'Status',
                    modelPath: 'C_SampleStatus_key',
                    template: this.statusTmpl
                },
                {
                    label: 'Preservation',
                    modelPath: 'C_PreservationMethod_key',
                    template: this.preservationTmpl,
                },
                {
                    label: 'Origin',
                    modelPath: 'Material.C_MaterialOrigin_key',
                    template: this.originTmpl,
                },
                {
                    label: 'External ID',
                    modelPath: 'Material.ExternalIdentifier',
                    template: this.externalIDTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownExternalIdentifier(item.ExternalIdentifiers);
                    },
                    hideFromEditHeader: this.isDotmatics,
                },
                {
                    label: 'Microchip ID',
                    modelPath: 'Material.MicrochipIdentifier',
                    template: this.microchipIdTmpl,
                    hideFromAddScreen: true,
                    onUpdateItem: (item, value) => {
                        this.fillDownMicrochipIdentifier(item.MicrochipIdentifiers);
                    },
                },
                {
                    label: 'Container Type',
                    modelPath: 'Material.C_ContainerType_key',
                    template: this.containerTmpl
                },
                {
                    label: 'Measurement',
                    modelPath: 'Volume',
                    template: this.measurementTmpl,
                    visible: false
                },

                {
                    label: 'Unit',
                    modelPath: 'C_Unit_key',
                    template: this.unitTmpl,
                    visible: false
                },
                {
                    label: 'Subtype',
                    modelPath: 'C_SampleSubtype_key',
                    template: this.sampleSubtypeTmpl
                },
                {
                    label: 'Processing',
                    modelPath: 'C_SampleProcessingMethod_key',
                    template: this.sampleProcessingMethodTmpl
                },
                {
                    label: 'Send To',
                    modelPath: 'SendTo',
                    template: this.sendToTmpl
                },
                {
                    label: 'Analysis',
                    modelPath: 'C_SampleAnalysisMethod_key',
                    template: this.sampleAnalysisMethodTmpl
                },
                {
                    label: this.translateService.translate('Job'),
                    modelPath: 'Material.JobMaterial',
                    modelSettingName: 'Material.JobMaterial[0]',
                    template: this.jobTmpl,
                    onUpdateItem: (item, value) => {
                        this.fillDownJob(item.JobKey);
                    }
                },
                {
                    label: 'Location',
                    modelPath: 'locationPosition',
                    modelSettingName: 'Location',
                    template: this.locationTmpl,
                    onItemInit: (item) => {
                        this.getRootLocationPosition().then((rootLocationPosition) => {
                            item.locationPosition = rootLocationPosition;
                            this.bulkAddCommService.markFormPristine();
                        });
                    },
                    onUpdateItem: (item, value) => {
                        this.fillDownLocation(item.locationPosition);
                    }
                },
                {
                    label: 'Description',
                    modelPath: 'Description',
                    template: this.descriptionTmpl
                },
                {
                    label: 'Lot Number',
                    modelPath: 'LotNumber',
                    template: this.lotNumberTmpl                   
                },
                {
                    label: 'Label',
                    modelPath: 'label',
                    template: this.labelTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Characteristics',
                    modelPath: 'TaxonCharacteristicInstance',
                    template: this.characteristicTmpl,
                    hideFromEditHeader: true,
                    onItemInit: (item) => {
                        item.TaxonCharacteristicInstance = [];
                    }
                }
            ]
        };

        const activeFieldsSet = new Set(this.activeFields ?? []);

        this.bulkOptions.fields.forEach((field: BulkEditField) => {
            const { modelPath, modelSettingName } = field;

            if (!["ID", "Name", "Type", "Status"].includes(field.label)) {
                field.inactive = !activeFieldsSet.has(modelSettingName ?? modelPath);
            }
        });
        this.configSampleNaming(this.sampleNamingActive);
    }

    initialize() {
         // Copy the input so we don't touch the grid data
        this.samples = this.samples.slice();
    }

    getData() {
        this.facetLoadingState.changeLoadingState(true);

        return this.isNamingActive().then(() => {
            return this.getSamplesDetails();
        }).then(() => {
            this.getSampleOrders();            
            return this.getCVs();
        }).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    getSampleOrders() {
        const preferLocal = true;
        this.sampleService.getSampleOrders(preferLocal).then((data) => {
            this.sampleOrders = data;
        });
    }
    
    private isNamingActive(): Promise<any> {
        return this.namingService.isSampleNamingActive()
            .then((active: boolean) => {
                this.sampleNamingActive = active;
                this.configSampleNaming(this.sampleNamingActive);
            });
    }

    private configSampleNaming(isNamingActive: boolean) {
        const nameField = this.getField('Name');
        if (nameField) {
            nameField.subLabel = isNamingActive ? '(assigned automatically)' : '';
            nameField.hideFromAddScreen = isNamingActive;
        }
    }

    private getField(label: string): BulkEditField {
        if (!this.bulkOptions || 
            !this.bulkOptions.fields
        ) {
            return null;
        }
        return this.bulkOptions.fields.find((field) => {
            return field.label === label;
        });
    }

    getSamplesDetails(): Promise<any[]> {
        if (notEmpty(this.samples)) {
            const queryDef: QueryDef = {
                page: 0,
                size: this.samples.length,
                filter: {
                    materialKeys: this.samples.map((sample) => {
                        return sample.C_Material_key;
                    })
                },
                expands: ['Material.MaterialLocation']
            };

            return this.sampleService.getSamples(queryDef).then((result) => {
                return this.getSamplesCharacteristics(this.samples);
            }).then(() => {
                return this.samples;
            });
        }

        return Promise.resolve(this.samples);
    }

    private getSamplesCharacteristics(samples: any[]): Promise<any> {
        const promises: any[] = [];

        for (const sample of samples) {
            if (!sample.SampleCharacteristics) {
                const promise = this.sampleLogicService.loadCharacteristics(sample);
                promises.push(promise);
            }
        }

        return Promise.all(promises);
    }

    getCVs(): Promise<any> {
        const p1: Promise<any> = this.sampleVocabService.containerTypes$.pipe(map((data) => {
            this.containerTypes = data;
            this.containerTypeDefaultValue();
        })).toPromise();

        const p2: Promise<any> = this.sampleVocabService.materialOrigins$.pipe(map((data) => {
            this.materialOrigins = data;
            this.materialOriginDefaultValue();
        })).toPromise();

        const p3: Promise<any> = this.sampleVocabService.preservationMethods$.pipe(map((data) => {
            this.preservationMethods = data;
            this.preservationMethodDefaultValue();
        })).toPromise();

        const p4: Promise<any> = this.sampleVocabService.sampleStatuses$.pipe(map((data) => {
            this.sampleStatuses = data;
            this.sampleStatusDefaultValue();
        })).toPromise();

        const p5: Promise<any> = this.sampleVocabService.sampleTypes$.pipe(map((data) => {
            this.sampleTypes = data;
            this.sampleTypeDefaultValue();
        })).toPromise();

        const p6: Promise<any> = this.sampleVocabService.timeUnits$.pipe(map((data) => {
            this.timeUnits = data;            
        })).toPromise();

        const p7: Promise<any> = this.getRootLocationPosition();

        const p8: Promise<any> = this.sampleVocabService.units$.pipe(map((data) => {
            this.units = data;
            this.unitDefaultValue();
        })).toPromise();

        const p9: Promise<any> = this.sampleVocabService.sampleSubtypes$.pipe(map((data) => {
            this.sampleSubtypes = data;
            this.sampleSubtypeDefaultValue();
        })).toPromise();

        const p10: Promise<any> = this.sampleVocabService.sampleProcessingMethods$.pipe(map((data) => {
            this.sampleProcessingMethods = data;
            this.sampleProcessingMethodDefaultValue();
        })).toPromise();

        const p11: Promise<any> = this.sampleVocabService.sampleAnalysisMethods$.pipe(map((data) => {
            this.sampleAnalysisMethods = data;
            this.sampleAnalysisMethodDefaultValue();
        })).toPromise();

        return Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11]);
    }

    getRootLocationPosition(): Promise<any> {
        if (!this.rootLocationPositionPromise) {
            this.rootLocationPositionPromise = this.locationService.getRootLocationPositions()
                .then((data) => {
                    if (notEmpty(data)) {
                        // when setting a default, there can only be one root location
                        const rootLocationPosition = data[0];
                        this.rootLocationPosition = rootLocationPosition;
                        return rootLocationPosition;
                    }
                    return null;
                });
        }
        return this.rootLocationPositionPromise;
    }

    onSourceSelection(sample: any, sourceMaterial: any) {
        return this.sampleLogicService.onSourceSelection(sample, sourceMaterial);
    }

    fillDownLine(lineKey: number) {
        for (const sample of this.samples) {
            sample.Material.C_Line_key = lineKey;
            this.onLineSelection(lineKey, sample);
        }
    }

    fillDownName(names: string[]) {
        if (names) {
            for (let i = 0; i < names.length; i++) {
                // only update the name if it's an existing sample or auto naming is disabled
                if (this.samples[i].C_Material_key > 0 || !this.sampleNamingActive) {
                    this.samples[i].SampleName = names[i];
                }
            }
        }
    }

    onLineSelection(lineKey: number, sample: any): Promise<any> {
        return this.sampleLogicService.onLineSelection(lineKey, sample);
    }

    async fillDownSource(source: any) {
        const sourceMaterialSources = source.Material.MaterialSourceMaterial; 
        const sourceMaterialSourcesSet = new Set(sourceMaterialSources.map((item: any) => item.C_Material_key));

        const promises: Promise<void>[] = [];
        for (const sample of this.samples) {
            // deleting by source
            let i = 0;
            const sampleMaterialSources = sample.Material.MaterialSourceMaterial;
            while (sampleMaterialSources.length !== i) {
                const sampleMaterial = sampleMaterialSources[i];
                if (sourceMaterialSourcesSet.has(sampleMaterial.C_SourceMaterial_key)) {
                    i ++;
                    continue;
                }
                this.sampleService.deleteMaterialSourceMaterial(sampleMaterial);
            } 

            // Filling from source
            const existMaterialSourcesSet = new Set(sampleMaterialSources.map((item: any) => item.C_SourceMaterial_key));
            for (const sourceMaterialSource of sourceMaterialSources) {
                if (existMaterialSourcesSet.has(sourceMaterialSource.C_Material_key)) {
                    continue;
                }
                existMaterialSourcesSet.add(sourceMaterialSource.C_Material_key);
                const entity = this.sampleService.createMaterialSourceMaterial({
                    C_Material_key: sample.C_Material_key,
                    C_SourceMaterial_key: sourceMaterialSource.C_Material_key,
                    Version: 0
                });
                sample.Material.MaterialSourceMaterial.push(entity);
                promises.push(this.onSourceSelection(sample, sourceMaterialSource));
            }         
        }
        await Promise.all(promises);
    }

    fillDownType(typeKey: number) {
        for (const sample of this.samples) {
            sample.C_SampleType_key = typeKey;
            this.sampleLogicService.onTypeChange(sample);
        }
    }

    fillDownTimePoint(item: any) {
        for (const sample of this.samples) {
            sample.TimePoint = item.TimePoint;
            sample.C_TimeUnit_key = item.C_TimeUnit_key;
        }
    }

    fillDownMicrochipIdentifier(ids: string[]) {
        if (ids) {
            for (let i = 0; i < ids.length; i++) {
                this.samples[i].Material.MicrochipIdentifier = ids[i];
            }
        }
    }

    fillDownExternalIdentifier(ids: string[]) {
        if (ids) {
            for (let i = 0; i < ids.length; i++) {
                this.samples[i].Material.ExternalIdentifier = ids[i];
            }
        }
    }

    onTypeChange(sample: any, section: BulkEditSection) {
        switch (section) {
            case BulkEditSection.InputCell:
                this.sampleLogicService.onTypeChange(sample);
                break;
            case BulkEditSection.AddScreen:
                this.createMockSampleCharacteristics(sample);
                break;
        }
    }

    /**
     * Create mock SampleCharacteristics for the bulk Add or Edit screen
     */
    createMockSampleCharacteristics(sample: any) {
        // clear old characteristics
        sample.SampleCharacteristicInstance = [];

        const sampleTypeKey: number = sample.C_SampleType_key;

        this.sampleService.getActiveCharacteristics(sampleTypeKey).then((characteristics) => {
            const newCharacteristics: any[] = [];
            for (const characteristic of characteristics) {
                const mockCharacteristic = {
                    C_SampleCharacteristic_key: characteristic.C_SampleCharacteristic_key,
                    CharacteristicValue: '',
                    SampleCharacteristic: characteristic
                };
                newCharacteristics.push(mockCharacteristic);
            }
            sample.SampleCharacteristicInstance = newCharacteristics;
        });
    }


    fillDownJob(jobKey: number) {
        if (jobKey) {
            for (const sample of this.samples) {
                this.sampleLogicService.addJobMaterial(jobKey, sample);
            }

            // load job into breeze cache
            this.jobService.getJob(jobKey);
        }
    }

    fillDownLocation(locationPosition: any) {    
        if (locationPosition) {
            for (const sample of this.samples) {
                // if is edit and save we have to remove default MaterialLocation
                if (sample.C_Material_key < 0) {
                    for (const materialLocation of sample.Material.MaterialLocation) {
                        this.locationService.deleteMaterialLocation(materialLocation);
                    }                    
                }

                this.locationService.processSampleLocationChange(
                    sample,
                    locationPosition
                );
            }
        }
    }

    // <select> formatters
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }
    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }
    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
    preservationMethodKeyFormatter = (value: any) => {
        return value.C_PreservationMethod_key;
    }
    preservationMethodFormatter = (value: any) => {
        return value.PreservationMethod;
    }
    materialOriginKeyFormatter = (value: any) => {
        return value.C_MaterialOrigin_key;
    }
    materialOriginFormatter = (value: any) => {
        return value.MaterialOrigin;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    timeUnitKeyFormatter = (value: any) => {
        return value.C_TimeUnit_key;
    }
    timeUnitFormatter = (value: any) => {
        return value.TimeUnit;
    }
    unitKeyFormatter = (value: any) => {
        return value.C_Unit_key;
    }
    unitFormatter = (value: any) => {
        return value.Unit;
    }
    sampleSubtypeKeyFormatter = (value: any) => {
        return value.C_SampleSubtype_key;
    }
    sampleSubtypeFormatter = (value: any) => {
        return value.SampleSubtype;
    }
    sampleProcessingMethodKeyFormatter = (value: any) => {
        return value.C_SampleProcessingMethod_key;
    }
    sampleProcessingMethodFormatter = (value: any) => {
        return value.SampleProcessingMethod;
    }
    sampleAnalysisMethodKeyFormatter = (value: any) => {
        return value.C_SampleAnalysisMethod_key;
    }
    sampleAnalysisMethodFormatter = (value: any) => {
        return value.SampleAnalysisMethod;
    }

    // Default functions
    sampleTypeDefaultValue() {
        for (const sampleType of this.sampleTypes) {
            if (sampleType.IsDefault) {
                this.sampleTypeDefaultKey = sampleType.C_SampleType_key;
            }
        }
    }
    sampleStatusDefaultValue() {
        for (const sampleStatus of this.sampleStatuses) {
            if (sampleStatus.IsDefault) {
                this.sampleStatusDefaultKey = sampleStatus.C_SampleStatus_key;
            }
        }
    }
    materialOriginDefaultValue() {
        for (const materialOrigin of this.materialOrigins) {
            if (materialOrigin.IsDefault) {
                this.materialOriginDefaultKey = materialOrigin.C_MaterialOrigin_key;
            }
        }
    }
    preservationMethodDefaultValue() {
        for (const preservationMethod of this.preservationMethods) {
            if (preservationMethod.IsDefault) {
                this.preservationMethodDefaultKey = preservationMethod.C_PreservationMethod_key;
            }
        }
    }
    containerTypeDefaultValue() {
        for (const containerType of this.containerTypes) {
            if (containerType.IsDefaultSample) {
                this.containerTypeDefaultKey = containerType.C_ContainerType_key;
            }
        }
    }
    unitDefaultValue() {
        for (const unit of this.units) {
            if (unit.IsDefault) {
                this.unitDefaultKey = unit.C_Unit_key;
            }
        }
    }
    sampleSubtypeDefaultValue() {
        for (const subtype of this.sampleSubtypes) {
            if (subtype.IsDefault) {
                this.sampleSubtypeDefaultKey = subtype.C_SampleSubtype_key;
            }
        }
    }
    sampleProcessingMethodDefaultValue() {
        for (const processing of this.sampleProcessingMethods) {
            if (processing.IsDefault) {
                this.sampleProcessingMethodDefaultKey = processing.C_SampleProcessingMethod_key;
            }
        }
    }
    sampleAnalysisMethodDefaultValue() {
        for (const analysis of this.sampleAnalysisMethods) {
            if (analysis.IsDefault) {
                this.sampleAnalysisMethodDefaultKey = analysis.C_SampleAnalysisMethod_key;
            }
        }
    }

    selectMaterial(sample: any, material: any) {
        sample.Material.MaterialSourceMaterial.push(material);
    }

    removeMaterial(sample: any, material: any) {
        sample.Material.MaterialSourceMaterial.filter((item: any) => item.C_Material_key === material.C_Material_key);
    }

    validate() {
        const inputMessage = this.characteristicInputs.map(input => input.validate()).find(msg => msg);
        return inputMessage ?? dateControlValidator(this.dateControls);
    }

    /**
     * Sets isDotmatics flag
     */
    private setIsDotmatics() {
        this.isDotmatics = this.dotmaticsService.setIsDotmatics();
    }
}
