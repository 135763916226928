<table class="table">
    <loading-overlay *ngIf="loading"></loading-overlay>
    <thead>
        <tr class="detail-grid-header">
            <th [attr.colspan]="readonly ? columnSelect.model.length + 7 : columnSelect.model.length + 5">Animals</th>
            <th>
                <climb-column-select [columnLabels]="columnSelect"
                                     (onChange)="columnSelectChanged($event)"
                                     class="old-fashioned">
                </climb-column-select>
            </th>
        </tr>
        <tr class="detail-grid-header">
            <ng-container *ngIf="!readonly">
                <th class="icon-cell">
                    <p class="form-control-plaintext">
                        <a *ngIf="generatorExpanded"
                           class="generator-link"
                           (click)="generatorExpanded = false">
                            <i class="fa fa-chevron-up" title="Hide"></i>
                        </a>
                        <a class="add-item-link"
                           *ngIf="!generatorExpanded"
                           (click)="generatorExpanded = true">
                            <i class="fa fa-plus-circle" title="Add animals"></i>
                        </a>
                    </p>
                </th>
                <th class="icon-cell">
                    <div class="dropdown">
                        <a class="dropdown-toggle text-nowrap"
                           data-toggle="dropdown"
                           role="button"
                           aria-haspopup="true"
                           aria-expanded="false">
                            <i data-automation-id="copy-animals-button" 
                               class="fa fa-copy" 
                               aria-hidden="true" 
                               title="Copy animals">
                            </i>
                        </a>
                        <div class="dropdown-menu">
                            <a (click)="copySelectedAnimals()"
                               class="dropdown-item"
                               [appInsightsClick]="'click-copy-selected-animals-from-birth'">
                                Copy selected animals
                            </a>
                            <a (click)="copyAllAnimals()"
                               class="dropdown-item"
                               [appInsightsClick]="'click-copy-all-animals-from-birth'">
                                Copy all animals
                            </a>
                        </div>
                    </div>


                </th>
            </ng-container>
            <th>ID</th>
            <th>Name</th>
            <th *ngIf="visible.Sex">Sex</th>
            <th>{{'Line' | translate}}</th>
            <th *ngIf="visible.Genotype">Genotypes</th>
            <th *ngIf="visible.Status">Status</th>
            <th *ngIf="visible.BreedingStatus">Breeding Status</th>
            <th *ngIf="visible.Generation">Generation</th>
            <th *ngIf="visible.Use">Use</th>
            <th *ngIf="visible.Owner">Owner</th>
            <th *ngIf="visible.Origin">Origin</th>
            <th *ngIf="visible.Diet">Diet</th>
            <th *ngIf="visible.Job">Job</th>
            <th *ngIf="visible.IACUCProtocol">IACUC Protocol</th>
            <th *ngIf="visible.BirthDate">Birth Date</th>
            <th *ngIf="visible.PhysicalMarkerType">Marker Type</th>
            <th *ngIf="visible.PhysicalMarker">Marker</th>
            <th *ngIf="visible.MicrochipIdentifier">Microchip ID</th>
            <th *ngIf="visible.ExternalIdentifier">External ID</th>
            <th>Birth Comment
                <info-tooltip [text]="'Climb only shows birth comments in the Births facet.'">
                </info-tooltip>
            </th>
            <th *ngIf="visible.Characteristics">Characteristics</th>
        </tr>
    </thead>

    <tbody *ngIf="!loading">
        <!-- Animal Generator Row -->
        <tr class="table-active"
            *ngIf="generatorExpanded && !readonly">
            <td colspan="2">
                <div class="input-group flex-nowrap">
                    <input type="number"
                           name="generatorCount"
                           [(ngModel)]="generatorCount"
                           min="1"
                           style="min-width:5em;"
                           class="form-control">
                    <div class="input-group-append">
                        <button type="button"
                                class="btn btn-secondary"
                                (click)="addAnimals()"
                                [disabled]="!birth.Mating || !birth.animalLineKey">
                            Add
                        </button>
                    </div>
                </div>
            </td>
            <td>
                <!-- ID -->
            </td>
            <td>
                <!-- Name -->
            </td>
            <td *ngIf="visible.Sex">
                <active-vocab-select [(model)]="birth.animalSexKey"
                                     [vocabChoices]="sexes"
                                     [keyFormatter]="sexKeyFormatter"
                                     [optionFormatter]="sexNameFormatter"></active-vocab-select>
            </td>
            <td>
                <line-select [(model)]="birth.animalLineKey"
                             [required]="true"></line-select>
            </td>
            <td *ngIf="visible.Genotype">
                <!-- Genotypes -->
            </td>
            <td *ngIf="visible.Status">
                <active-vocab-select [(model)]="birth.animalStatusKey"
                                     [vocabChoices]="animalStatuses"
                                     [keyFormatter]="animalStatusKeyFormatter"
                                     [optionFormatter]="animalStatusFormatter"></active-vocab-select>
            </td>
            <td *ngIf="visible.BreedingStatus">
                <active-vocab-select [(model)]="birth.breedingStatusKey"
                                     [vocabChoices]="breedingStatuses"
                                     [keyFormatter]="breedingStatusKeyFormatter"
                                     [optionFormatter]="breedingStatusFormatter"></active-vocab-select>
            </td>
            <td *ngIf="visible.Generation">
                <active-vocab-select [(model)]="birth.generationKey"
                                     [vocabChoices]="generations"
                                     [keyFormatter]="generationKeyFormatter"
                                     [optionFormatter]="generationFormatter">
                </active-vocab-select>
            </td>
            <td *ngIf="visible.Use">
                <active-vocab-select [(model)]="birth.animalUseKey"
                                     [vocabChoices]="animalUses"
                                     [keyFormatter]="animalUseKeyFormatter"
                                     [optionFormatter]="animalUseFormatter">
                </active-vocab-select>
            </td>
            <td *ngIf="visible.Owner">
                <user-select [(model)]="birth.ownerKey"
                             [forceValidSelection]="false"></user-select>
            </td>
            <td *ngIf="visible.Origin">
                <active-vocab-select [(model)]="birth.materialOriginKey"
                                     [vocabChoices]="materialOrigins"
                                     [keyFormatter]="materialOriginKeyFormatter"
                                     [optionFormatter]="materialOriginFormatter"
                                     [loadDefaultValue]="true">
                </active-vocab-select>
            </td>
            <td *ngIf="visible.Diet">
                <active-vocab-select [(model)]="birth.dietKey"
                                     [vocabChoices]="diets"
                                     [keyFormatter]="dietKeyFormatter"
                                     [optionFormatter]="dietFormatter">
                </active-vocab-select>
            </td>
            <td *ngIf="visible.Job">
                <job-select [excludeLocked]="true"
                    [(model)]="birth.JobKey"></job-select>
            </td>
            <td *ngIf="visible.IACUCProtocol">
                <active-vocab-select [(model)]="birth.iacucProtocolKey"
                                     [vocabChoices]="iacucProtocols"
                                     [keyFormatter]="iacucProtocolKeyFormatter"
                                     [optionFormatter]="iacucProtocolFormatter">
                </active-vocab-select>
            </td>
            <td *ngIf="visible.BirthDate">
                <climb-ngb-date #dateControl="ngModel"
                                name="Date Born"
                                [(ngModel)]="birth.animalDateBorn">
                </climb-ngb-date>
            </td>
            <td *ngIf="visible.PhysicalMarkerType">
                <active-vocab-select [(model)]="birth.physicalMarkerTypeKey"
                                     [vocabChoices]="physicalMarkerTypes"
                                     [keyFormatter]="physicalMarkerTypeKeyFormatter"
                                     [optionFormatter]="physicalMarkerTypeFormatter"
                                     [nullable]="true"></active-vocab-select>
            </td>
            <td *ngIf="visible.PhysicalMarker">
                <input type="text"
                       name="animalMarker"
                       [(ngModel)]="birth.animalMarker"
                       class="form-control input-medium" />
            </td>
            <td *ngIf="visible.MicrochipIdentifier">
                <input type="text"
                       name="animalMicrochipId"
                       [(ngModel)]="birth.animalMicrochipIdentifier"
                       class="form-control input-medium" />
            </td>
            <td *ngIf="visible.ExternalIdentifier">
                <input type="text"
                       name="animalExternalId"
                       [(ngModel)]="birth.animalExternalIdentifier"
                       class="form-control input-medium" />
            </td>
            <td>
                <input type="text"
                       name="animalComments"
                       [(ngModel)]="birth.animalComments"
                       class="form-control input-large" />
            </td>
            <td *ngIf="visible.Characteristics"></td>
        </tr>

        <!-- Animals -->
        <ng-container *ngIf="birth.birthAnimals | notEmpty">
            <tr *ngFor="let animal of birth.birthAnimals">
                <ng-container *ngIf="!readonly">
                    <td class="icon-cell">
                        <p class="form-control-plaintext">
                            <a (click)="removeMaterial(animal)">
                                <i class="fa fa-remove remove-item" title="Delete animal"></i>
                            </a>
                        </p>
                    </td>
                    <td class="text-center" style="width:25px;">
                        <div>
                            <ng-container *ngIf="!animal.Material?.MaterialPoolMaterial[0]">
                                <input type="checkbox"
                                       name="selectedForWean"
                                       class="mt-1"
                                       [(ngModel)]="animal.selectedForWean" />
                            </ng-container>
                        </div>
                    </td>
                </ng-container>
                <td>
                    <div class="form-control-plaintext"
                         data-drag-type="animal"
                         draggable
                         (dragStart)="dragAnimalsStart()"
                         (dragStop)="dragAnimalsStop()">
                        {{animal.Material?.Identifier || '[Unsaved]'}}
                    </div>
                <td>
                    <input type="text"
                           name="animalName"
                           [(ngModel)]="animal.AnimalName"
                           [disabled]="animalNamingActive"
                           required
                           class="form-control input-medium" />
                </td>
                <td *ngIf="visible.Sex">
                    <active-vocab-select [(model)]="animal.C_Sex_key"
                                         [vocabChoices]="sexes"
                                         [keyFormatter]="sexKeyFormatter"
                                         [optionFormatter]="sexNameFormatter"></active-vocab-select>
                </td>
                <td>
                    <ng-container *ngIf="animal.Material">
                        <line-select [(model)]="animal.Material.C_Line_key"
                                     [required]="true"
                                     (modelChange)="selectLine($event, animal)"></line-select>
                    </ng-container>
                </td>
                <td *ngIf="visible.Genotype">
                    <p class="form-control-plaintext">
                        <span *ngFor="let genotype of animal?.Genotype;
                          let last = last">{{genotype.cv_GenotypeAssay?.GenotypeAssay}}&nbsp;{{genotype.cv_GenotypeSymbol?.GenotypeSymbol}}<span *ngIf="!last">, </span></span>
                    </p>
                </td>
                <td *ngIf="visible.Status">
                    <active-vocab-select [(model)]="animal.C_AnimalStatus_key"
                                         [vocabChoices]="animalStatuses"
                                         [keyFormatter]="animalStatusKeyFormatter"
                                         [optionFormatter]="animalStatusFormatter"></active-vocab-select>
                </td>
                <td *ngIf="visible.BreedingStatus">
                    <active-vocab-select [(model)]="animal.C_BreedingStatus_key"
                                         [vocabChoices]="breedingStatuses"
                                         [keyFormatter]="breedingStatusKeyFormatter"
                                         [optionFormatter]="breedingStatusFormatter"></active-vocab-select>
                </td>
                <td *ngIf="visible.Generation">
                    <active-vocab-select [(model)]="animal.C_Generation_key"
                                         [vocabChoices]="generations"
                                         [keyFormatter]="generationKeyFormatter"
                                         [optionFormatter]="generationFormatter"></active-vocab-select>
                </td>
                <td *ngIf="visible.Use">
                    <active-vocab-select [(model)]="animal.C_AnimalUse_key"
                                         [vocabChoices]="animalUses"
                                         [keyFormatter]="animalUseKeyFormatter"
                                         [optionFormatter]="animalUseFormatter"></active-vocab-select>
                </td>

                <td *ngIf="visible.Owner">
                    <user-select [(model)]="animal.Owner"
                                 [forceValidSelection]="false"></user-select>
                </td>
                <td *ngIf="visible.Origin">
                    <active-vocab-select  *ngIf="animal.Material"
                                         [(model)]="animal.Material.C_MaterialOrigin_key"
                                         [vocabChoices]="materialOrigins"
                                         [keyFormatter]="materialOriginKeyFormatter"
                                         [optionFormatter]="materialOriginFormatter">
                    </active-vocab-select>
                </td>
                <td *ngIf="visible.Diet">
                    <active-vocab-select [(model)]="animal.C_Diet_key"
                                         [vocabChoices]="diets"
                                         [keyFormatter]="dietKeyFormatter"
                                         [optionFormatter]="dietFormatter">
                    </active-vocab-select>
                </td>
                <td *ngIf="visible.Job">
                    <material-job-select [material]="animal.Material"></material-job-select>
                </td>
                <td *ngIf="visible.IACUCProtocol">
                    <active-vocab-select [(model)]="animal.C_IACUCProtocol_key"
                                         [vocabChoices]="iacucProtocols"
                                         [keyFormatter]="iacucProtocolKeyFormatter"
                                         [optionFormatter]="iacucProtocolFormatter">
                    </active-vocab-select>
                </td>
                <td *ngIf="visible.BirthDate">
                    <div class="d-flex">
                        <climb-ngb-date #dateControl="ngModel"
                                        name="Birth Date"
                                        [(ngModel)]="animal.DateBorn"
                                        (ngModelChange)="setDateArrival(animal)">
                        </climb-ngb-date>
                        <div *ngIf="!(animal.DateBorn | datesEqual: birth.DateBorn)"
                            class="form-control-plaintext ml-1">
                            <warning-tooltip [text]="'This animal\'s birth date does not match the birth date for this birth.'"></warning-tooltip>
                        </div>
                    </div>
                </td>
                <td *ngIf="visible.PhysicalMarkerType">
                    <active-vocab-select [(model)]="animal.C_PhysicalMarkerType_key"
                                         [vocabChoices]="physicalMarkerTypes"
                                         [keyFormatter]="physicalMarkerTypeKeyFormatter"
                                         [optionFormatter]="physicalMarkerTypeFormatter"
                                         [nullable]="true"></active-vocab-select>
                </td>
                <td *ngIf="visible.PhysicalMarker">
                    <input *ngIf="!animal.cv_PhysicalMarkerType?.Vendor"
                           type="text"
                           name="physicalMarker"
                           [(ngModel)]="animal.PhysicalMarker"
                           size="5"
                           class="form-control input-medium" />
                    <animal-marker *ngIf="animal.cv_PhysicalMarkerType?.Vendor"
                                   [marker]="animal.PhysicalMarker"
                                   [markerType]="animal.cv_PhysicalMarkerType"></animal-marker>
                </td>
                <td *ngIf="visible.MicrochipIdentifier">
                    <input type="text"
                           name="microchipIdentifier"
                           [(ngModel)]="animal.Material.MicrochipIdentifier"
                           size="5"
                           class="form-control input-medium" />
                </td>
                <td *ngIf="visible.ExternalIdentifier">
                    <input type="text"
                           name="externalIdentifier"
                           [(ngModel)]="animal.Material.ExternalIdentifier"
                           size="5"
                           class="form-control input-medium" />
                </td>
                <td>
                    <input type="text"
                           name="animalComments"
                           [(ngModel)]="animal.Comments"
                           class="form-control input-large">
                </td>
                <td *ngIf="visible.Characteristics">
                    <div class="text-nowrap">
                        <a (click)="animal.characteristicsExpanded = !animal.characteristicsExpanded"
                           class="details-chevron-link"
                           [attr.title]="animal.characteristicsExpanded ? 'Hide details' : 'Show details'">
                            Details<i class="fa fa-fw"
                                      [ngClass]="animal.characteristicsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                      [attr.title]="animal.characteristicsExpanded ? 'Hide animal details' : 'Show animal details'"></i>
                        </a>
                    </div>
                    <div *ngIf="animal.characteristicsExpanded"
                         style="margin-top: 0.5em;">
                        <div *ngFor="let characteristic of animal.TaxonCharacteristics | orderBy:'TaxonCharacteristic.SortOrder'"
                             class="form-group">
                            <label>
                                {{characteristic.CharacteristicName}}
                            </label>
                            <characteristic-input #characteristicInput
                                                  [characteristic]="characteristic"
                                                  [readonly]="readonly"
                                                  [(value)]="characteristic.CharacteristicValue"></characteristic-input>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>

<!-- Wean Controls -->
<fieldset class="fieldset-bordered"
          *ngIf="!readonly"
          [disabled]="isWeaningDisabled()">
    <legend>Wean Selected Animals</legend>
    <div class="form-group" style="padding-left: 1em;">
        <div class="col-md-12">
            <div class="form-check">
                <label class="form-check-label">
                    <input type="radio"
                           class="form-check-input"
                           name="weanHousingType"
                           id="weanHousingTypeOptionNew-{{domIdAddition}}"
                           [(ngModel)]="weanHousingType"
                           value="{{WEAN_HOUSING_TYPE_NEW}}" />
                    Into <strong>new</strong> housing unit
                </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                    <input type="radio"
                           class="form-check-input"
                           name="weanHousingType"
                           id="weanHousingTypeOptionExisting-{{domIdAddition}}"
                           [(ngModel)]="weanHousingType"
                           value="{{WEAN_HOUSING_TYPE_EXISTING}}" />
                    Into <strong>existing</strong> housing unit:
                </label>
                <div style="width: 300px; display: inline-block;">
                    <housing-select [(model)]="birth.existingHousingUnitKey"
                                    [excludeMatingUnits]="true"></housing-select>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group" style="padding-left: 1em;">
        <div class="col-md-12">
            <button type="button"
                    class="btn btn-primary"
                    (click)="weanSelectedAnimals()">
                Wean
            </button>
        </div>
    </div>
</fieldset>
