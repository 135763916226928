<ng-template #observationcheck let-observationCV="observationCV">
    <input type="checkbox" [(ngModel)]="observationCV.IsSelected"
        name="large-checkbox{{observationCV.C_ClinicalObservation_key}}"
        id="large-checkbox{{observationCV.C_ClinicalObservation_key}}" autocomplete="off" />
    <div class="btn-group" style="display:inline-flex;">
        <label for="large-checkbox{{observationCV.C_ClinicalObservation_key}}"
               class="btn btn-secondary input-shape"
               style="margin-right:5px;">
            <span class="fa fa-check"></span>
            <span> </span>
        </label>
        <label for="large-checkbox{{observationCV.C_ClinicalObservation_key}}"
               class="observation-label">
            {{observationCV.ClinicalObservation}}
        </label>
    </div>
</ng-template>

<ng-template #bSystemcheck let-bSystem="bSystem">
    <input type="checkbox" [(ngModel)]="bSystem.IsSelected" name="large-checkbox{{bSystem.C_ClinicalObservation_key}}"
        id="large-checkbox{{bSystem.C_ClinicalObservation_key}}" autocomplete="off" />
    <div class="btn-group" style="display:inline-flex;">
        <label for="large-checkbox{{bSystem.C_ClinicalObservation_key}}"
               class="btn btn-secondary input-shape"
               style="margin-right:5px;">
            <span class="fa fa-check"></span>
            <span> </span>
        </label>
        <label for="large-checkbox{{bSystem.C_ClinicalObservation_key}}"
               class="observation-label">
            {{bSystem.ClinicalObservation}}
        </label>
    </div>
</ng-template>

<div class="modal-header justify-content-center">
    <h4 class="modal-title">Select {{title}}</h4>
</div>
<div *ngIf="!isGLP" class="modal-body">
    <form role="form">
        <h4 class="mb-3">Select {{title}}</h4>
        <nav ngbNav #nav="ngbNav" class="nav-tabs p-0">
            <ng-container ngbNavItem>
                <a ngbNavLink>{{'Non-Experimental' | translate}}</a>
                <ng-template ngbNavContent>
                <div class="row">
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let observationCV of observationsNotInWorkflow | half:'first'"
                             class="checkbox-group"
                             style="background-color:white;"
                        >
                            <ng-template [ngTemplateOutlet]="observationcheck"
                                         [ngTemplateOutletContext]="{
                                    observationCV : observationCV
                                    }">
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let observationCV of observationsNotInWorkflow | half:'last'"
                             class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="observationcheck"
                                         [ngTemplateOutletContext]="{
                                    observationCV : observationCV
                                    }">
                            </ng-template>
                        </div>
                    </div>
                </div>
                </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
                <a ngbNavLink>{{'Experimental' | translate}}</a>
                <ng-template ngbNavContent>
                <div class="row">
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let observationCV of observationsInWorkflow | half:'first'"
                             style="background-color:white;"
                             class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="observationcheck"
                                         [ngTemplateOutletContext]="{
                                    observationCV : observationCV
                                    }">
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let observationCV of observationsInWorkflow | half:'last'"
                             class="checkbox-group">
                            <ng-template [ngTemplateOutlet]="observationcheck"
                                         [ngTemplateOutletContext]="{
                                    observationCV : observationCV
                                    }">
                            </ng-template>
                        </div>
                    </div>
                </div>
                </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="checkbox-row mt-2"></div>
    </form>
</div>

<div *ngIf="isGLP" class="modal-body">
    <loading-overlay *ngIf="loading"></loading-overlay>
    <form role="form">
        <h4 class="mb-3">Select {{title}}</h4>
        <nav ngbNav #navGLP="ngbNav" [activeId]="lastTab" class="nav-tabs p-0">
            <ng-container *ngFor="let system of bodySystem"
                          [ngbNavItem]="system.C_BodySystem_key"
                          [disabled]="selectedTab !== null && system.C_BodySystem_key !== selectedTab">
                <a ngbNavLink>{{system.BodySystem}}</a>
                <ng-template ngbNavContent>
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let bSystem of  system.clinicalObservations | half:'first'"
                            style="background-color:white;" class="checkbox-group">

                                <input type="checkbox" [(ngModel)]="bSystem.IsSelected"
                                    name="large-checkbox{{bSystem.C_ClinicalObservation_key}}"
                                    id="large-checkbox{{bSystem.C_ClinicalObservation_key}}" autocomplete="off"
                                    (change) = "updateCheck(bSystem)"
                                    [disabled] = "selectedCheckbox && !bSystem.IsSelected"
                                    />
                                <div class="btn-group" style="display:inline-flex;">
                                    <label for="large-checkbox{{bSystem.C_ClinicalObservation_key}}"
                                        class="btn btn-secondary input-shape" style="margin-right:5px;">
                                        <span class="fa fa-check"></span>
                                        <span> </span>
                                    </label>
                                    <label for="large-checkbox{{bSystem.C_ClinicalObservation_key}}"
                                           class="observation-label">
                                        {{bSystem.cv_ClinicalObservation.ClinicalObservation}}
                                    </label>
                                </div>


                        </div>
                    </div>
                    <div class="col-md-6 checkbox-column">
                        <div *ngFor="let bSystem of system.clinicalObservations  | half:'last'"
                            class="checkbox-group">

                                <input type="checkbox" [(ngModel)]="bSystem.IsSelected"
                                    name="large-checkbox{{bSystem.C_ClinicalObservation_key}}"
                                    id="large-checkbox{{bSystem.C_ClinicalObservation_key}}" autocomplete="off"
                                    (change) = "updateCheck(bSystem)"
                                    [disabled] = "selectedCheckbox && !bSystem.IsSelected"
                                    />
                                <div class="btn-group" style="display:inline-flex;">
                                    <label for="large-checkbox{{bSystem.C_ClinicalObservation_key}}"
                                        class="btn btn-secondary input-shape" style="margin-right:5px;">
                                        <span class="fa fa-check"></span>
                                        <span> </span>
                                    </label>
                                    <label for="large-checkbox{{bSystem.C_ClinicalObservation_key}}"
                                           class="observation-label">
                                        {{bSystem.cv_ClinicalObservation.ClinicalObservation}}
                                    </label>
                                </div>

                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="navGLP" class="checkbox-row mt-2"></div>
    </form>
</div>

<div class="modal-footer">
    <button type="submit" class="btn btn-fw btn-lg btn-primary" (click)="okClicked($event)">Select</button>
    <button type="button" class="btn btn-fw btn-lg btn-secondary" (click)="closeClicked()">Cancel</button>
</div>
