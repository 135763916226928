import { DeletionService } from './deletion.service';
import { Subject } from 'rxjs';

import { LoggingService } from './logging.service';
import { AdminManagerService } from './admin-manager.service';
import { DataManagerService } from './data-manager.service';
import { Injectable } from '@angular/core';

import {
    EntityState, Entity, EntityChangedEventArgs, PropertyChangedEventArgs, SaveResult
} from 'breeze-client';
import { OverlayService } from './overlay-service';
import { ReasonForChangeService } from '../common/reason-for-change/reason-for-change.service';
import { FeatureFlagService } from './feature-flags.service';
import { breeze } from "./breeze";
import { Entity as IEntity } from '../common/types/breeze.interface';

/**
 * Datacontext wrapper for DataManager and AdminManager
 */
@Injectable()
export class DataContextService {

    private onCancelSource = new Subject<void>();
    onCancel$ = this.onCancelSource.asObservable();

    private checkChangesSource = new Subject<boolean>();
    checkChanges$ = this.checkChangesSource.asObservable();

    private entityChangesSource = new Subject<void>();
    entityChanges$ = this.entityChangesSource.asObservable();

    /**
     * Called whenever changes have successfully saved
     */
    private changesSavedSource = new Subject<void>();
    changesSaved$ = this.changesSavedSource.asObservable();

    private _initPromise: Promise<any>;

    constructor(
        private dataManager: DataManagerService,
        private adminManager: AdminManagerService,
        private deletionService: DeletionService,
        private loggingService: LoggingService,
        private overlayService: OverlayService,
        private reasonForChangeService: ReasonForChangeService,
        private featureFlagService: FeatureFlagService,
    ) {}

    init(): Promise<any> {
        if (!this._initPromise) {
            this._initPromise = Promise.all([
                this.dataManager.init(),
                this.adminManager.init()
            ]).then(() => {
                this.subscribeChanges();
                this.registerBeforeUnloadEvent();
            });
        }
        return this._initPromise;
    }


    subscribeChanges() {
        const publishChange = () => {
            const hasEntityChanges = this.hasChanges();
            this.checkChangesSource.next(hasEntityChanges);
        };

        const onEntityChanged = (changeArgs: EntityChangedEventArgs) => {
            this.entityChangesSource.next();
            if (!changeArgs || changeArgs.entityAction !== breeze.EntityAction.PropertyChange) {
                return;
            }

            const args = changeArgs.args as PropertyChangedEventArgs;
            const entity = changeArgs.entity;
            if (!args || !args.propertyName
                || !entity || !entity.entityAspect || !entity.entityAspect.originalValues) {
                return;
            }

            const originalValues = entity.entityAspect.originalValues;
            // clear change history if the property value is the same as it was originally
            if (originalValues[args.propertyName] === args.newValue) {
                delete originalValues[args.propertyName];

                if (Object.getOwnPropertyNames(originalValues).length === 0) {
                    entity.entityAspect.setUnchanged();
                }
            }
        };

        // register to BreezeJS EntityManager hasChangesChanged event
        // cf.: http://breeze.github.io/doc-js/api-docs/classes/entitymanager.html#haschangeschanged
        this.dataManager.getManager().hasChangesChanged.subscribe(publishChange);
        this.adminManager.getManager().hasChangesChanged.subscribe(publishChange);

        this.dataManager.getManager().entityChanged.subscribe(onEntityChanged);
        this.adminManager.getManager().entityChanged.subscribe(onEntityChanged);

        // publish it once on load
        publishChange();
    }

    registerBeforeUnloadEvent() {
        // eslint-disable-next-line
        const _self = this;
        window.onbeforeunload = (event: BeforeUnloadEvent) => {
            const hasEntityChanges = _self.hasChanges();
            if (hasEntityChanges) {
                event.returnValue = hasEntityChanges;
                return hasEntityChanges;
            }
        };
    }

    clear() {
        this.dataManager.clear();
    }

    hasChanges(): boolean {
        return this.dataManager.hasChanges() ||
               this.adminManager.hasChanges();
    }

    getChangesCount() {
        return this.getChanges().length;
    }

    getChanges() {
        return this.dataManager.getChanges()
            .concat(this.adminManager.getChanges());
    }

    cancel(logIt?: boolean): boolean {
        const canceledDataChanges = this.dataManager.cancel();
        const canceledAdminChanges = this.adminManager.cancel();

        this.onCancelSource.next();

        if (logIt) {
            this.loggingService.logSuccess('Changes canceled', null, 'data-context', true);
        }

        return canceledDataChanges || canceledAdminChanges;
    }

    saveEntity(entityName: string): Promise<any> {
        return this.saveEntities([entityName]);
    }

    /**
     * Preferred method for saving by entity type is through
     *   dataContext.saveEntities(), because it sets up any Deletion records
     */
    saveEntities(entityNames: string[]): Promise<any> {
        const isGLP = this.featureFlagService.isFlagOn('IsGLP');
        let promise: Promise<any>;
        if (isGLP) {
            promise = this.reasonForChangeService.handleReasonForChangeForTypes(entityNames).then((reasonForChangeEntities: any[]) => {
                return Promise.all([
                    this.dataManager.saveEntities(entityNames),
                    this.saveSingleRecordBatch(reasonForChangeEntities)
                ]);
            });
        } else {
            const deletionRecords = this.deletionService.handleDeletedItemsForEntities(entityNames);

            promise =  Promise.all([
                this.dataManager.saveEntities(entityNames),
                this.saveSingleRecordBatch(deletionRecords)
            ]);
        }
        return promise;
    }

    /**
     * Preferred method for generic saving is through
     *   dataContext.save(), because it handles all
     *   DataManager and AdminManager traffic, but also
     *   sets up any Deletion records
     */
    save(): Promise<any> {
        let promise: Promise<any>;
        const isGLP = this.featureFlagService.isFlagOn('IsGLP');
        if (isGLP) {
            promise = this.reasonForChangeService.handleReasonForChange().then(() => {
                const overlayId = this.overlayService.show();
                return Promise.all([
                    this.dataManager.save(),
                    this.adminManager.save()
                ]).then(() => {
                    console.log("changes saved");
                    this.changesSaved();
                }).finally(() => {
                    this.overlayService.hide(overlayId);
                });
            });
        } else {
            const overlayId = this.overlayService.show();
            this.deletionService.handleDeletedItems();
            promise = Promise.all([
                this.dataManager.save(),
                this.adminManager.save()
            ]).then(() => {
                console.log("changes saved");
                this.changesSaved();
            }).finally(() => {
                this.overlayService.hide(overlayId);
            });
        }

        return promise;
    }

    async saveSingleRecordBatch(saveBatch: Entity[]): Promise<any> {
        const flag = await this.featureFlagService.getFlag("IsGLP");
        const isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
        let overlayId: string;

        if (isGLP) {
            // only modified or deleted entities should show ReasonForChange modals.
            const reasonForChangeBatch = saveBatch.filter((e: Entity) =>
                e.entityAspect.entityState === EntityState.Modified
                || e.entityAspect.entityState === EntityState.Deleted);

            const reasonForChangeEntities = await this.reasonForChangeService
                .openReasonForChangeModal(reasonForChangeBatch);

            overlayId = this.overlayService.show();
            await Promise.all([
                this.dataManager.saveSingleRecordBatch(saveBatch),
                this.dataManager.saveSingleRecordBatch(reasonForChangeEntities),
            ]);
        } else {
            overlayId = this.overlayService.show();
            const deletionRecordBatch = this.deletionService.handleDeletedItemsInBatch(saveBatch);
            await Promise.all([
                this.dataManager.saveSingleRecordBatch(saveBatch),
                this.dataManager.saveSingleRecordBatch(deletionRecordBatch),
            ]);
        }

        console.log('changes saved');
        this.changesSaved();
        this.overlayService.hide(overlayId);
    }


    changesSaved() {
        this.changesSavedSource.next();
    }

    public cancelPropertyChange(entity: IEntity<any>, propertyName: string): void {
        if (entity.entityAspect.originalValues.hasOwnProperty(propertyName)) {
            const originalValue = entity.entityAspect.originalValues[propertyName];

            entity.setProperty(propertyName, originalValue);
            delete entity.entityAspect.originalValues[propertyName];
      
            if (Object.getOwnPropertyNames(entity.entityAspect.originalValues).length === 0) {
                entity.entityAspect.setUnchanged();
            }
        }
    }
}
