<form data-automation-id="add-task-window" 
      novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Add a Task</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <label for="taskInput-{{domIdAddition}}"
                   class="col-md-3 col-form-label">Task</label>
            <div class="col-md-9">
                <task-select data-automation-id="task-name-dropdown"
                             id="taskInput-{{domIdAddition}}"
                             [(model)]="workflowTaskKey"
                             (modelChange)="onTaskChange()"
                             [taskType]="taskType"
                             [showActiveOnly]="true"
                             [required]="true"></task-select>
            </div>
        </div>
        <div class="form-group row">
            <label for="dueDateInput"
                   class="col-md-3 col-form-label">Due Date</label>
            <div class="col-md-4">
                <climb-ngb-date #dateControl="ngModel"
                                name="Due Date"
                                [(ngModel)]="dateDue"
                                [allowTime]="true"
                                [required]="true"></climb-ngb-date>
            </div>
        </div>

        <fieldset class="fieldset-bordered">
            <legend>Repeat</legend>
            <div class="form-group row">
                <label for="frequencySelect-{{domIdAddition}}"
                       class="col-md-3 col-form-label">Frequency</label>
                <div class="col-md-9">
                    <select id="frequencySelect-{{domIdAddition}}"
                            name="frequency"
                            class="form-control"
                            (change)="frequencyChanged()"
                            [(ngModel)]="frequency">
                        <option></option>
                        <option *ngFor="let frequency of frequencies">{{frequency}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row" *ngIf="intervalInputAllowed()">
                <label for="intervalInput-{{domIdAddition}}"
                       class="col-md-3 col-form-label">Repeat Every</label>
                <div class="col-md-9">
                    <input type="number"
                           id="intervalInput-{{domIdAddition}}"
                           name="interval"
                           class="form-control form-control-inline"
                           min="1"
                           [(ngModel)]="interval" />
                    {{frequencyLabel}}
                </div>
            </div>
            <div class="form-group row">
                <label for="occurrencesInput-{{domIdAddition}}"
                       class="col-md-3 col-form-label">End After</label>
                <div class="col-md-7">
                    <input type="number"
                           id="occurrencesInput-{{domIdAddition}}"
                           name="occurrences"
                           class="form-control form-control-inline"
                           min="1"
                           [disabled]="!frequency"
                           [(ngModel)]="occurrences" />
                    occurrences
                </div>
            </div>
        </fieldset>

        <fieldset class="fieldset-bordered"
                  *ngIf="inputs.length > 0">
            <legend>Inputs</legend>

            <div class="form-group row">
                <label class="col-md-3 col-form-label">Apply To</label>
                <div *ngIf="!hasRequiredInputs || hasRequiredInputs && !assignedToKey" class="col-md-9">
                    <div
                        *ngFor="let inputsApplyOption of inputsApplyOptions; let ix = index"
                        class="form-check form-check-inline"
                    >
                        <label class="form-check-label" [attr.data-index]="ix">
                            <input type="radio"
                                   class="form-check-input"
                                   name="inputsApply"
                                   id="inputsApply-{{ix}}-{{domIdAddition}}"
                                   [(ngModel)]="inputsApply"
                                   value="{{inputsApplyOption}}">
                            {{inputsApplyOption}}
                        </label>
                    </div>
                </div>
                <div *ngIf="assignedToKey && hasRequiredInputs" class="col-md-9">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input type="radio"
                                   class="form-check-input"
                                   name="inputsApply"
                                   id="inputsApply-0-{{domIdAddition}}"
                                   [(ngModel)]="inputsApply"
                                   value="{{inputsApplyOptions[0]}}"
                                   title="Required inputs must be filled if a resource is assigned">
                            {{inputsApplyOptions[0]}}
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input type="radio"
                                   class="form-check-input"
                                   name="inputsApply"
                                   id="inputsApply-1-{{domIdAddition}}"
                                   [(ngModel)]="inputsApply"
                                   value="{{inputsApplyOptions[1]}}"
                                   checked>
                            {{inputsApplyOptions[1]}}
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-group row"
                 *ngFor="let input of inputs; let ix = index"
                 [attr.data-index]="ix">
                <label for="task-input-input-{{ix}}-{{domIdAddition}}"
                       class="col-md-3 col-form-label">{{input.InputName}}</label>
                <div class="col-md-9">
                    <data-type-input *ngIf="isRegularInput(input)"
                                     [id]="'task-input-input-' + ix + '-' + domIdAddition"
                                     [ioObject]="input"
                                     [readonly]="false"
                                     [(value)]="input.taskInputValue"
                                     (valueChange)="checkForInvalidInputs()"
                                     [container]="''"></data-type-input>
                    <input *ngIf="isDosingTableInput(input)"
                           type="text"
                           id="'task-input-input-' + ix + '-' + domIdAddition"
                           [ngModelOptions]="{standalone: true}"
                           class="form-control input-medium"
                           [(ngModel)]="input.cv_DosingTable.DosingTable"
                           disabled />
                    <input *ngIf="isJobCharacteristicInput(input)"
                           type="text"
                           id="'task-input-input-' + ix + '-' + domIdAddition"
                           [ngModelOptions]="{standalone: true}"
                           class="form-control input-medium"
                           [(ngModel)]="input.cv_JobCharacteristicType.JobCharacteristicType"
                           disabled />
                </div>
            </div>

        </fieldset>

        <fieldset class="fieldset-bordered">
            <div class="form-group row">
                <label for="assignedToSelect-{{domIdAddition}}"
                       class="col-md-3 col-form-label">Assign To</label>
                <div class="col-md-3">
                    <climb-assigned-to-select [(model)]="assignedToKey"
                                              [disabled]="!workflowTaskKey || hasInvalidInputs">
                    </climb-assigned-to-select>
                </div>
                <div class="col-md-6">
                    <span class="color-warning" *ngIf="hasInvalidInputs">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        Required inputs must be filled out before assigning a resource.
                    </span>
                </div>
            </div>
        </fieldset>

        <fieldset class="fieldset-bordered"
                  *ngIf="workflowTaskKey > 0 && canAddSampleGroups"
                  style="overflow-x: auto;">
            <legend>Sample Groups</legend>
            <table class="table table-bordered" aria-describedby="Sample Groups">
                <thead>
                    <tr class="detail-grid-header">
                        <th>
                            <a class="add-item-link" (click)="createAddRow()">
                                <i class="fa fa-plus-circle" title="Add Sample Group"></i>
                            </a>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Samples'"
                                              (updateClicked)="updateBulkNumSamples()">
                                <div modal-content>
                                    <input [(ngModel)]="bulkNumSamples"
                                           type="number"
                                           min="1"
                                           step="1"
                                           class="form-control input-small"
                                           name="sampleGroup-bulk-numSamples" />
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Type'"
                                              (updateClicked)="updateBulkSampleTypeKey()">
                                <div modal-content>
                                    <active-vocab-select [(model)]="bulkSampleTypeKey"
                                                         [vocabChoices]="sampleTypes"
                                                         [keyFormatter]="sampleTypeKeyFormatter"
                                                         [optionFormatter]="sampleTypeFormatter"
                                                         [nullable]="false">
                                    </active-vocab-select>
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Status'"
                                              (updateClicked)="updateBulkSampleStatusKey()">
                                <div modal-content>
                                    <active-vocab-select [(model)]="bulkSampleStatusKey"
                                                         [vocabChoices]="sampleStatuses"
                                                         [keyFormatter]="sampleStatusKeyFormatter"
                                                         [optionFormatter]="sampleStatusFormatter"
                                                         [nullable]="false">
                                    </active-vocab-select>
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Preservation'"
                                              (updateClicked)="updateBulkPreservationMethodKey()">
                                <div modal-content>
                                    <active-vocab-select [(model)]="bulkPreservationMethodKey"
                                                         [vocabChoices]="preservationMethods"
                                                         [keyFormatter]="preservationMethodKeyFormatter"
                                                         [optionFormatter]="preservationMethodFormatter"
                                                         [nullable]="true">
                                    </active-vocab-select>
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Container'"
                                              (updateClicked)="updateBulkContainerTypeKey()">
                                <div modal-content>
                                    <active-vocab-select [(model)]="bulkContainerTypeKey"
                                                         [vocabChoices]="containerTypes"
                                                         [keyFormatter]="containerTypeKeyFormatter"
                                                         [optionFormatter]="containerTypeFormatter"
                                                         [nullable]="true">
                                    </active-vocab-select>
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Subtype'"
                                              (updateClicked)="updateBulkSampleSubtypeKey()">
                                <div modal-content>
                                    <active-vocab-select [(model)]="bulkSampleSubtypeKey"
                                                         [vocabChoices]="sampleSubtypes"
                                                         [keyFormatter]="sampleSubtypeKeyFormatter"
                                                         [optionFormatter]="sampleSubtypeFormatter"
                                                         [nullable]="true">
                                    </active-vocab-select>
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Processing'"
                                              (updateClicked)="updateBulkSampleProcessingMethodKey()">
                                <div modal-content>
                                    <active-vocab-select [(model)]="bulkSampleProcessingMethodKey"
                                                         [vocabChoices]="sampleProcessingMethods"
                                                         [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                                         [optionFormatter]="sampleProcessingMethodFormatter"
                                                         [nullable]="true">
                                    </active-vocab-select>
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Send To'"
                                              (updateClicked)="updateBulkSendTo()">
                                <div modal-content>
                                    <input [(ngModel)]="bulkSendTo"
                                           type="text"
                                           maxlength="150"
                                           class="form-control input-small"
                                           name="sampleGroup-bulk-sendTo" />
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th>
                            <bulk-edit-header [headerText]="'Analysis'"
                                              (updateClicked)="updateBulkSampleAnalysisMethodKey()">
                                <div modal-content>
                                    <active-vocab-select [(model)]="bulkSampleAnalysisMethodKey"
                                                         [vocabChoices]="sampleAnalysisMethods"
                                                         [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                                         [optionFormatter]="sampleAnalysisMethodFormatter"
                                                         [nullable]="true">
                                    </active-vocab-select>
                                </div>
                            </bulk-edit-header>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of sampleGroups; let index = index">
                        <!-- Remove -->
                        <td class="icon-cell">
                            <a (click)="createRemoveRow(index)">
                                <i class="fa fa-remove remove-item" title="Remove Sample Group"></i>
                            </a>
                        </td>
                        <!-- NumSamples -->
                        <td>
                            <input [(ngModel)]="row.NumSamples"
                                   type="number"
                                   min="1"
                                   step="1"
                                   class="form-control input-small"
                                   name="sampleGroup-create-{{index}}-NumSamples" />
                        </td>

                        <!-- Type -->
                        <td>
                            <active-vocab-select [(model)]="row.C_SampleType_key"
                                                 [vocabChoices]="sampleTypes"
                                                 [keyFormatter]="sampleTypeKeyFormatter"
                                                 [optionFormatter]="sampleTypeFormatter"
                                                 [nullable]="false">
                            </active-vocab-select>
                        </td>

                        <!-- Status -->
                        <td>
                            <active-vocab-select [(model)]="row.C_SampleStatus_key"
                                                 [vocabChoices]="sampleStatuses"
                                                 [keyFormatter]="sampleStatusKeyFormatter"
                                                 [optionFormatter]="sampleStatusFormatter"
                                                 [nullable]="false">
                            </active-vocab-select>
                        </td>

                        <!-- Preservation -->
                        <td>
                            <active-vocab-select [(model)]="row.C_PreservationMethod_key"
                                                 [vocabChoices]="preservationMethods"
                                                 [keyFormatter]="preservationMethodKeyFormatter"
                                                 [optionFormatter]="preservationMethodFormatter"
                                                 [nullable]="true"></active-vocab-select>
                        </td>

                        <!-- Container -->
                        <td>
                            <active-vocab-select [(model)]="row.C_ContainerType_key"
                                                 [vocabChoices]="containerTypes"
                                                 [keyFormatter]="containerTypeKeyFormatter"
                                                 [optionFormatter]="containerTypeFormatter"
                                                 [nullable]="true"></active-vocab-select>
                        </td>

                        <!-- Subtype -->
                        <td>
                            <active-vocab-select [(model)]="row.C_SampleSubtype_key"
                                                 [vocabChoices]="sampleSubtypes"
                                                 [keyFormatter]="sampleSubtypeKeyFormatter"
                                                 [optionFormatter]="sampleSubtypeFormatter"
                                                 [nullable]="true"></active-vocab-select>
                        </td>

                        <!-- Processing -->
                        <td>
                            <active-vocab-select [(model)]="row.C_SampleProcessingMethod_key"
                                                 [vocabChoices]="sampleProcessingMethods"
                                                 [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                                 [optionFormatter]="sampleProcessingMethodFormatter"
                                                 [nullable]="true"></active-vocab-select>
                        </td>

                        <!-- Send To -->
                        <td>
                            <input [(ngModel)]="row.SendTo"
                                   type="text"
                                   maxlength="150"
                                   class="form-control input-small"
                                   name="sampleGroup-sendTo" />
                        </td>

                        <!-- Analysis -->
                        <td>
                            <active-vocab-select [(model)]="row.C_SampleAnalysisMethod_key"
                                                 [vocabChoices]="sampleAnalysisMethods"
                                                 [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                                 [optionFormatter]="sampleAnalysisMethodFormatter"
                                                 [nullable]="true"></active-vocab-select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-primary"
                (click)="onSubmit()"
                [disabled]="!workflowTaskKey || !dateDue">
            Add
        </button>
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Cancel
        </button>
    </div>
</form>
