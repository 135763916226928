import { DataContextService } from './data-context.service';
import {
    Injectable
} from '@angular/core';

import { LoggingService } from '../services/logging.service';
import {
    ViewUnsavedChangesModalService
} from '../common/toolbars/view-unsaved-changes-modal.service';
import { Subject, Subscription } from 'rxjs';
import { FacetView, IFacet } from '../common/facet';
import { TranslationService, UNHANDLED_ERROR_MESSAGE } from './translation.service';
import { ToastrService } from "./toastr.service";
import { LogLevel } from "./models";
import { formatDataAutomationId } from '@common/util/format-data-automation-id';

export interface IValidator {
    context: IValidatable;
    validate: () => Promise<string>;
}

export interface IValidationError {
    errMsg: string;
    index: number;
}

export type ValidatorMap = Map<string, IValidator>;

export type IdGeneratorsMap = Map<string, IIdGenerator>;

export type BeforeSaveInterceptorsMap = Map<string, BeforeSaveInterceptor>;

export interface IFacetView {
    facet: IFacet;
    facetView: FacetView;
}
// Any component which has custom save validation should implement this interface
export interface IValidatable extends IFacetView {
    validate(): Promise<string>;
}

export interface IIdGenerator {
    context: ISupportIdGeneration;
    generateId: () => Promise<void>;
}

// Any component which supports entity id generation
export interface ISupportIdGeneration extends IFacetView{
    generateId(): Promise<void>;
}


export interface BeforeSaveInterceptor {
    context: IBeforeSaveInterceptor;
    beforeSave: (token?: SaveCancellationToken) => Promise<void>;
}

export interface IBeforeSaveInterceptor extends IFacetView{
    beforeSave(token?: SaveCancellationToken): Promise<void>;
}

export interface SaveCancellationToken {
    /**
     * Setting this to true will cancel the current save request.
     * This should never be set to false manually.
     */
    cancelled: boolean;
}

// Any IValidatable which subscribes to saveSuccessful$ should implement this interface
export interface OnSaveSuccessful {
    onSaveSuccessful(): void;
}

// Any IValidatable which subscribes to saveFailed$ should implement this interface
export interface OnSaveFailed {
    onSaveFailed(): void;
}

// Any IValidatable which subscribes to saveResult$ should implement this interface
export interface OnSaveResult {
    onSaveResult(): void;
}

export enum UnsavedChanges {
    noChanges = 'no-changes',
    save = 'save',
    discard = 'discard',
}

@Injectable()
export class SaveChangesService {
    readonly SERVICE_LOG_TAG = 'save-changes-service';

    // Maybe we just make this an array of objects instead? Do we ever actually need to access a specific validator? Should always just be iterating through all of them.
    private validatorMap: ValidatorMap = new Map();

    private idGeneratorMap: IdGeneratorsMap = new Map();
    
    private beforeSaveInterceptorsMap: BeforeSaveInterceptorsMap = new Map();

    private saveSuccessfulSource = new Subject<void>();
    saveSuccessful$ = this.saveSuccessfulSource.asObservable();

    private saveFailedSource = new Subject<void>();
    saveFailed$ = this.saveFailedSource.asObservable();

    private saveResultSource = new Subject<void>();
    saveResult$ = this.saveResultSource.asObservable();

    hasChanges = false;
    saving = false;

    isLocked = false;

    constructor(
        private loggingService: LoggingService,
        private viewUnsavedChangesModalService: ViewUnsavedChangesModalService,
        private dataContext: DataContextService,
        private translationService: TranslationService,
        private toastrService: ToastrService,
    ) {
        this.dataContext.checkChanges$.subscribe((hasChanges: boolean) => {
            this.hasChanges = hasChanges;
        });

        this.saveSuccessfulSource.subscribe(() => {
            this.saveResultSource.next();
        });

        this.saveFailedSource.subscribe(() => {
            this.saveResultSource.next();
        });
    }

    /**
     * Registers a function that performs custom validation logic specific to a validatable component that will be run on save
     * @param context The Validatable's class context (usually 'this')
     */
    registerValidator(context: IValidatable) {
        // TODO: Figure out how to deal with child components of a facet view that have their own validation
        // (should be moved to parent where possible though)

        const facetViewName = this.getFullFacetViewName(context.facet.FacetName, context.facetView);

        // Validation is not neccesary if the user does not have write-access to the current facet
        if (context.facet.Privilege !== 'ReadWrite') {
            this.loggingService.logDebug(`Unable to register validation function for ${facetViewName}. Requires write-access.`, null, this.SERVICE_LOG_TAG);
            return;
        }

        // If the facet/view implements Validatable it will have a validate function which we can add to the validators map with the component's context bound to it
        const validator: IValidator = {
            context,
            validate: context.validate.bind(context)
        };

        if (this.validatorMap.has(facetViewName)) {
            this.loggingService.logDebug(`Re-registering validation function for ${facetViewName}.`, null, this.SERVICE_LOG_TAG);
        } else {
            this.loggingService.logDebug(`Registering validation function for ${facetViewName}.`, null, this.SERVICE_LOG_TAG);
        }

        this.validatorMap.set(facetViewName, validator);
        this.loggingService.logDebug('Validators:', this.validatorMap, this.SERVICE_LOG_TAG);
    }

    /**
     * Unregisters a validation function when it is no longer neccesary (most likely facet/view was closed)
     * @param context The Validatable's class context (usually 'this')
     */
    unregisterValidator(context: IValidatable) {
        const facetViewName = this.getFullFacetViewName(context.facet.FacetName, context.facetView);

        // Remove facet/view from map if it exists
        if (this.validatorMap.has(facetViewName)) {
            this.loggingService.logDebug(`Unregistering validation function for ${facetViewName}`, null, this.SERVICE_LOG_TAG);
            this.validatorMap.delete(facetViewName);
        } else {
            this.loggingService.logDebug(`Unable to unregister validation function for ${facetViewName}. It may not be currently registered.`, null, this.SERVICE_LOG_TAG);
        }

        this.loggingService.logDebug('Validators:', this.validatorMap, this.SERVICE_LOG_TAG);
    }

    registerIdGenerator(context: ISupportIdGeneration) {
        const facetViewName = this.getFullFacetViewName(context.facet.FacetName, context.facetView);

        // Id generation is not neccesary if the user does not have write-access to the current facet
        if (context.facet.Privilege !== 'ReadWrite') {
            this.loggingService.logDebug(`Unable to register id generation function for ${facetViewName}. Requires write-access.`, null, this.SERVICE_LOG_TAG);
            return;
        }

        const idGenerator: IIdGenerator = {
            context,
            generateId: context.generateId.bind(context)
        };

        if (this.idGeneratorMap.has(facetViewName)) {
            this.loggingService.logDebug(`Re-registering id generation function for ${facetViewName}.`, null, this.SERVICE_LOG_TAG);
        } else {
            this.loggingService.logDebug(`Registering id generation function for ${facetViewName}.`, null, this.SERVICE_LOG_TAG);
        }

        this.idGeneratorMap.set(facetViewName, idGenerator);
        this.loggingService.logDebug('Id generation functions:', this.idGeneratorMap, this.SERVICE_LOG_TAG);
    }

    unregisterIdGenerator(context: ISupportIdGeneration) {
        const facetViewName = this.getFullFacetViewName(context.facet.FacetName, context.facetView);

        // Remove facet/view from map if it exists
        if (this.idGeneratorMap.has(facetViewName)) {
            this.loggingService.logDebug(`Unregistering id generation function for ${facetViewName}`, null, this.SERVICE_LOG_TAG);
            this.idGeneratorMap.delete(facetViewName);
        } else {
            this.loggingService.logDebug(`Unable to unregister id generation function for ${facetViewName}. It may not be currently registered.`, null, this.SERVICE_LOG_TAG);
        }

        this.loggingService.logDebug('Id generation functions:', this.idGeneratorMap, this.SERVICE_LOG_TAG);
    }


    registerBeforeSaveInterceptor(context: IBeforeSaveInterceptor) {
        const facetViewName = this.getFullFacetViewName(context.facet.FacetName, context.facetView);

        // before save logic is not neccesary if the user does not have write-access to the current facet
        if (context.facet.Privilege !== 'ReadWrite') {
            this.loggingService.logDebug(`Unable to register before save intercepting function for ${facetViewName}. Requires write-access.`, null, this.SERVICE_LOG_TAG);
            return;
        }

        const beforeSaveInterceptor: BeforeSaveInterceptor  = {
            context,
            beforeSave: context.beforeSave.bind(context)
        };

        if (this.beforeSaveInterceptorsMap.has(facetViewName)) {
            this.loggingService.logDebug(`Re-registering before save intercepting function for ${facetViewName}.`, null, this.SERVICE_LOG_TAG);
        } else {
            this.loggingService.logDebug(`Registering before save intercepting function for ${facetViewName}.`, null, this.SERVICE_LOG_TAG);
        }

        this.beforeSaveInterceptorsMap.set(facetViewName, beforeSaveInterceptor);
        this.loggingService.logDebug('Before save intercepting function:', this.beforeSaveInterceptorsMap, this.SERVICE_LOG_TAG);
    }

    unregisterBeforeSaveInterceptor(context: IBeforeSaveInterceptor) {
        const facetViewName = this.getFullFacetViewName(context.facet.FacetName, context.facetView);

        // Remove facet/view from map if it exists
        if (this.beforeSaveInterceptorsMap.has(facetViewName)) {
            this.loggingService.logDebug(`Unregistering  before save intercepting function for ${facetViewName}`, null, this.SERVICE_LOG_TAG);
            this.beforeSaveInterceptorsMap.delete(facetViewName);
        } else {
            this.loggingService.logDebug(`Unable to unregister  before save intercepting function for ${facetViewName}. It may not be currently registered.`, null, this.SERVICE_LOG_TAG);
        }

        this.loggingService.logDebug('Before save intercepting function:', this.beforeSaveInterceptorsMap, this.SERVICE_LOG_TAG);
    }


    /**
     * Opens a modal to promp the user to save their unsaved changes
     * @param logTag The log tag of the component that requested the opening of the modal
     */
    async promptForUnsavedChanges(logTag: string): Promise<UnsavedChanges> {
        if (!this.hasChanges) {
            return UnsavedChanges.noChanges;
        }
        // If there are unsaved changes, prompt the user to save or discard them
        const result = await this.viewUnsavedChangesModalService.openComponent();
        if (result === 'save') {
            await this.saveChanges(logTag);
            return UnsavedChanges.save;
        }

        return UnsavedChanges.discard;
    }

    /**
     * Saves all changes to entities if validation passes and alerts subscribers of result
     * (should almost always be called by promptForUnsavedChanges but left public as it may be neccesary to call directly in some cases)
     * @param logTag The log tag of the component that requested the save
     * @param showToast Whether or not to show a toast on save success
     */
    async saveChanges(logTag: string, showToast = true, dataAutomationId = "changes-saved"): Promise<void> {
        this.saving = true;
        // Validate changes by running all registered validation functions
        const errMsg = await this.validateChanges();

        // If a validation error occured, abort the save and report the failure to save event subscribers
        if (errMsg) {
            this.saving = false;

            const error = new Error(errMsg);
            this.loggingService.logError(error.message, error, logTag, true);
            this.saveFailedSource.next();
            throw error;
        } else {
            await this.runIdGenerators().catch((error: Error) => {
                this.saving = false;
                // TODO: uncomment this block when logging will be fixed
                // this.loggingService.logError(error.message, error, logTag, false);
                // TODO: fix translation service, so it doesn't fire exception for unhandled errors from backend.
                //  Then use translate message method.
                this.toastrService.showToast(UNHANDLED_ERROR_MESSAGE, LogLevel.Error);
                this.saveFailedSource.next();
                throw error;
            });

            const saveCancellationToken: SaveCancellationToken = {
                cancelled: false
            };

            await this.runBeforeSaveInterceptors(saveCancellationToken).catch((error: Error) => {
                this.saving = false;
                // this.loggingService.logError(error.message, error, logTag, false);
                // TODO: fix translation service, so it doesn't fire exception for unhandled errors from backend.
                //  Then use translate message method.
                this.toastrService.showToast(UNHANDLED_ERROR_MESSAGE, LogLevel.Error);
                this.saveFailedSource.next();
                throw error;
            });
            
            if (saveCancellationToken.cancelled) {
                this.saving = false;
                return;
            }

            // If no validation error occured, attempt to save and report the result of the save to save event subscribers
            return this.dataContext.save().then(() => {
                this.saving = false;

                this.loggingService.logFacetSaveSuccess(logTag, showToast).attr("data-automation-id", formatDataAutomationId(dataAutomationId, "", "-text"));
                this.saveSuccessfulSource.next();
            }).catch((error: Error) => {
                this.saving = false;
                // IMPORTANT: do not set showToast: true, because data-manager does it.
                // Otherwise 2 popups will be shown for same error.
                this.loggingService.logError(error.message, error, logTag, false);
                this.saveFailedSource.next();
                throw error;
            });
        }
    }


    /**
     * Runs all registered validation logic and stops early to report an error if one occurs
     */
    async validateChanges(): Promise<string> {
        // Make a list of the results of all of the registered validation function promises
        const validators = Array.from(this.validatorMap.values());
        const validationResults = validators.map((validator) => validator.validate());

        // Find the first error from the first promise to resolve with an error message, or undefined if all validators pass
        const result = await this.findFirstValidationError(validationResults);
        let { errMsg } = result;
        const { index } = result;

        // If an error occured, prepend facet/view name to error message
        if (errMsg && index > -1) {
            const context = validators[index].context;
            errMsg = this.generateSaveErrorMessage(context.facet.FacetName, context.facetView, errMsg);
        }

        return errMsg;
    }

    async runIdGenerators(): Promise<void> {
        // Call active id generators for entities
        const eventHandlers = Array.from(this.idGeneratorMap.values());
        await Promise.all(eventHandlers.map((eventHandler) => eventHandler.generateId()));
    }

    async runBeforeSaveInterceptors(token: SaveCancellationToken): Promise<void> {
        // Call active id generators for entities
        const eventHandlers = Array.from(this.beforeSaveInterceptorsMap.values());
        await Promise.all(eventHandlers.map((eventHandler) => eventHandler.beforeSave(token)));
    }

    generateSaveErrorMessage(facetName: string, facetView: FacetView, baseErrorMessage: string): string {
        return `[${this.getFullFacetViewName(facetName, facetView)}] Save failed: ${baseErrorMessage}`;
    }

    /**
     * Finds the first validation error in the list of validation result promises
     * (result of first promise to resolve to a value other than undefined or undefined if all resolve to undefined)
     * @param validationResults The list of validation result promises to check
     */
    private findFirstValidationError(validationResults: Promise<string>[]): Promise<IValidationError> {
        // Map promises to new promises which resolve with their error message if not undefined
        const newPromises: Promise<IValidationError>[] = validationResults.map((result, index) => {
            return new Promise((resolve, reject) => {
                result.then((errMsg) => errMsg && resolve({errMsg, index}), reject);
            });
        });

        // Add promise for all original promises (not the new mapping) resolving to undefined (no errors)
        newPromises.push(Promise.all(validationResults).then(() => {
            return {errMsg: undefined, index: -1};
        }));

        // Let the new list of promises race and interrupt once one resolves (either one validator failed or all passed)
        return Promise.race(newPromises);
    }

    /**
     * Formats the component's facet and view name into a human-readable tag
     */
    private getFullFacetViewName(facetName: string, facetView: FacetView) {
        return `${this.translationService.translate(facetName)} ${facetView ? facetView : 'Facet'}`;
    }
}
