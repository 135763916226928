import { Entity, JobCohort } from "@common/types";

// Animal counts for groups
export class AnimalCount {
    inJob: number = 0;
    total: number = 0;

    get complete(): boolean {
        return (this.inJob === this.total);
    }
}

export interface UIJobCohort {
    isSelected: boolean;
    animalCounts: AnimalCount;
}

export type ExtendedJobCohort = Entity<UIJobCohort & JobCohort>;
