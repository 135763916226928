import { Injectable } from '@angular/core';
import { Observable, Subject, defer, forkJoin } from 'rxjs';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import {
    notEmpty,
    softCompare,
    sortObjectArrayByProperty,
    uniqueArray
} from '../common/util';
import {
    getDateRangePredicates
} from '../services/queries';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';

import { EditTasksComponent } from '../tasks/edit-tasks.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { WebApiService } from '../services/web-api.service';
import { CountResult } from '../services/models';
import { FeatureFlagService } from '../services/feature-flags.service';
import { NamingService } from '../services/naming.service';
import { FacetView } from '../common/facet';
import { OrderService } from '../orders/order.service';
import { TaskService } from '../tasks/task.service';
import { Animal, Sample, SampleGroup, TaskInstance, TaskJob } from '@common/types';
import { concatMap, map } from 'rxjs/operators';

export interface IJobMaterial {
    C_Material_key: number;
    C_Job_key: number;
    DateIn?: Date;
    DateOut?: Date;
    CreatedBy?: string;
    DateCreated?: Date;
    ModifiedBy?: string;
    DateModified?: Date;
    Version?: number;
    Sequence?: number;
}

@Injectable()
export class JobService extends BaseEntityService {
    
    private cancelTasksModal: NgbModalRef = null;

    // Observable string from Save-menu-icon
    private listViewStudies1 = new Subject<any>();
    private listViewStudies2 = new Subject<any>();
    // Observable string streams
    changeStudies1ViewMethodCalled$ = this.listViewStudies1.asObservable();
    changeStudies2ViewMethodCalled$ = this.listViewStudies2.asObservable();

    private basicJobPropertiesToExpand = [
        'cv_JobType',
        'cv_JobSubtype',
        'cv_JobStatus',
        'cv_IACUCProtocol',
        'cv_JobReport',
        'Compliance',
        'Line',
        'Line.cv_Taxon',
        'Study',
        'Institution',
        'Site',
        'JobTestArticle',
        'JobTestArticle.cv_TestArticle',
        'JobInstitution',
        'JobInstitution.ScientificContactPerson',
        'JobInstitution.BillingContactPerson',
        'JobInstitution.AuthorizationContactPerson',
        'JobInstitution.Site',
        'JobInstitution.Site.ContactPerson',
        'JobInstitution.Institution',
        'JobInstitution.JobInstitutionBillingContact.ContactPerson',
        'JobGroup',
        'JobLine.Line.cv_Taxon',
        'JobOrder',
        'Placeholder',
        'Placeholder.AnimalPlaceholder',
        'Placeholder.AnimalPlaceholder.SampleGroupSourceMaterial',
        'JobStandardPhrase',
        'Note',
        'JobLocation.LocationPosition'
    ];
    constructor(
        private dataManager: DataManagerService,
        private modalService: NgbModal,
        private vocabularyService: VocabularyService,
        private webApiService: WebApiService,
        private featureFlagService: FeatureFlagService,
        private namingService: NamingService,
        private orderService: OrderService,
        private taskService: TaskService,
    ) {
        super();
    }

    getIsCroFlag(): boolean {
        let flag = this.featureFlagService.getFlag("IsCRO");
        return flag && (flag.Value.toLowerCase() === 'true') && (flag.IsActive === true);
    }

    getIsCrlFlag(): boolean {
        let flag = this.featureFlagService.getFlag("IsCRL");
        return flag && (flag.Value.toLowerCase() === 'true') && (flag.IsActive === true);
    }

    getIsGLPFlag(): boolean {
        let flag = this.featureFlagService.getFlag("IsGLP");
        return flag && (flag.Value.toLowerCase() === 'true') && (flag.IsActive === true);
    }

    getIsClassicJobOnlyFlag(): boolean {
        let flag = this.featureFlagService.getFlag("IsClassicJobOnly");
        return flag && (flag.Value.toLowerCase() === 'true') && (flag.IsActive === true);
    }

    getJobsWithAllDependencies(queryDef: QueryDef): Promise<QueryResult> {
        return this.loadJobsList(queryDef, this.basicJobPropertiesToExpand);
    }

    getJobsWithMinimumDependencies(queryDef: QueryDef): Promise<QueryResult> {

        const propertiesToExpand: string[] = [
            'JobTestArticle',
            'JobTestArticle.cv_TestArticle',
        ];

        return this.loadJobsList(queryDef, propertiesToExpand);
    }
    
    private loadJobsList(queryDef: QueryDef, propertiesToExpand: string[] = []): Promise<QueryResult> {
        
        let query = this.buildDefaultQuery('Jobs', queryDef);
      
        
        for (const propertyName of propertiesToExpand) {
            this.ensureDefExpanded(queryDef, propertyName);
        }
        
        if (queryDef.expands) {
           query = query.expand(queryDef.expands.join(','));
       }
       
        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        let p1 = this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);

        // also load AnimalCount and SampleCount values
        let p2 = p1.then((response) => {
            let jobs = response.results;
            return this.loadJobMaterialCounts(jobs);
        });

        // return value of first promise
        return Promise.all([p1, p2]).then((responses) => {
            return responses[0];
        });
    }

    /**
     * Load and set properties 
     *   job.AnimalCount
     *   and job.SampleCount
     * @param jobs 
     */
    loadJobMaterialCounts(jobs: any[]): Promise<any> {
        let jobKeys = jobs.map((job) => {
            return job.C_Job_key;
        });
        let p1 = this.getJobAnimalCounts(jobKeys).then((results) => {
            for (let job of jobs) {
                let countResult = results.find((item) => {
                    return item.key === job.C_Job_key;
                });
                // always default to 0
                job.AnimalCount = (countResult && countResult.count) || 0;
            }
        });
        let p2 = this.getJobSampleCounts(jobKeys).then((results) => {
            for (let job of jobs) {
                let countResult = results.find((item) => {
                    return item.key === job.C_Job_key;
                });
                // always default to 0
                job.SampleCount = (countResult && countResult.count) || 0;
            }
        });

        return Promise.all([p1, p2]);
    }

    /**
     * Load Animal counts for each job
     * @param jobKeys 
     */
    getJobAnimalCounts(jobKeys: number[]): Promise<CountResult[]> {
        return this.webApiService.callCountApi('api/counts/GetJobAnimalCounts', jobKeys);
    }

    /**
     * Load Sample counts for each job
     * @param jobKeys
     */
    getJobSampleCounts(jobKeys: number[]): Promise<CountResult[]> {
        return this.webApiService.callCountApi('api/counts/GetJobSampleCounts', jobKeys);
    }

    buildPredicates(filter: any): Predicate[] {
        if (!filter) {
            return [];
        }

        let predicates: Predicate[] = [];
        if (filter.JobID) {
            predicates.push(Predicate.create('JobID', FilterQueryOp.Contains, { value: filter.JobID }));
        }

        if (filter.LeadScientist) {
            predicates.push(Predicate.create('LeadScientist', 'eq', filter.LeadScientist));
        }
        if (filter.StudyMonitor) {
            predicates.push(Predicate.create('StudyMonitor', 'eq', filter.StudyMonitor));
        }

        if (filter.ResearchDirector) {
            predicates.push(Predicate.create('ResearchDirector', 'eq', filter.ResearchDirector));
        }
        if (filter.ClientManager) {
            predicates.push(Predicate.create('ClientManager', 'eq', filter.ClientManager));
        }
        if (filter.StudyDirector) {
            predicates.push(Predicate.create('StudyDirector', 'eq', filter.StudyDirector));
        }

        if (notEmpty(filter.C_Taxon_keys)) {
            let subPredicates: any = [];

            let subPredicate = Predicate.create(
                'Line.C_Taxon_key', 'in', filter.C_Taxon_keys
            );

            subPredicates.push(subPredicate);

            subPredicates.push(Predicate.create(
                'JobLine', 'any',
                subPredicate
            ));

            predicates.push(Predicate.or(subPredicates));
        }

        if (filter.JobCode) {
            predicates.push(Predicate.create('JobCode', FilterQueryOp.Contains, { value: filter.JobCode }));
        }

        // Deprecated: replaced by filter.C_JobStatus_keys
        if (filter.C_JobStatus_key) {
            predicates.push(Predicate.create('C_JobStatus_key', 'eq', filter.C_JobStatus_key));
        }
        if (notEmpty(filter.C_JobStatus_keys)) {
            predicates.push(
                Predicate.create('C_JobStatus_key', 'in', filter.C_JobStatus_keys)
            );
        }

        if (filter.C_JobType_key) {
            predicates.push(Predicate.create('C_JobType_key', 'eq', filter.C_JobType_key));
        }

        if (notEmpty(filter.JobSubtypes)) {
            let jobSubtypeKeys = filter.JobSubtypes.map((j: any) => j.JobSubtypeKey);
            predicates.push(Predicate.create('C_JobSubtype_key', 'in', jobSubtypeKeys));
        }

        if (filter.ProjectImplantDateStart || filter.ProjectImplantDateEnd) {
            let datePredicates: Predicate[] = getDateRangePredicates(
                'ProjectImplantDate', filter.ProjectImplantDateStart, filter.ProjectImplantDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.ImplantedDateStart || filter.ImplantedDateEnd) {
            let datePredicates: Predicate[] = getDateRangePredicates(
                'ImplantedDate', filter.ImplantedDateStart, filter.ImplantedDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.ProjectedStartDateStart || filter.ProjectedStartDateEnd) {
            let datePredicates: Predicate[] = getDateRangePredicates(
                'ProjectedStartDate', filter.ProjectedStartDateStart, filter.ProjectedStartDateEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.DateStartedStart || filter.DateStartedEnd) {
            let datePredicates: Predicate[] = getDateRangePredicates(
                'DateStarted', filter.DateStartedStart, filter.DateStartedEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.DateEndedStart || filter.DateEndedEnd) {
            let datePredicates: Predicate[] = getDateRangePredicates(
                'DateEnded', filter.DateEndedStart, filter.DateEndedEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (notEmpty(filter.CreatedBys)) {
            let createdBys = filter.CreatedBys.map((createdBy: any) => {
                return createdBy.User.UserName;
            });
            predicates.push(Predicate.create(
                'CreatedBy', 'in', createdBys
            ));
        }

        if (notEmpty(filter.CreatedBy)) {
            predicates.push(Predicate.create('CreatedBy', 'in', [filter.CreatedBy]));
        }

        if (filter.DateCreatedStart || filter.DateCreatedEnd) {
            let datePredicates: Predicate[] = getDateRangePredicates(
                'DateCreated', filter.DateCreatedStart, filter.DateCreatedEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.Location) {
            predicates.push(Predicate.create(
                'CurrentLocationPath', FilterQueryOp.Contains, { value: filter.Location },
            ));
        }

        // Deprecated: replaced by filter.lines
        if (filter.C_Line_Keys && filter.C_Line_Keys.length > 0) {
            predicates.push(Predicate.create(
                'C_Line_key', 'in', filter.C_Line_Keys
            ));
        }

        if (notEmpty(filter.orders)) {
            let orderKeys = filter.orders.map((order: any) => order.OrderKey);
            predicates.push(Predicate.create(
                'JobOrder', 'any', 'C_Order_key', 'in', orderKeys
            ));
        }

        if (notEmpty(filter.lines)) {
            let lineKeys = filter.lines.map((line: any) => {
                return line.LineKey;
            });

            let subPredicates: any = [];

            subPredicates.push(Predicate.create(
                'C_Line_key', 'in',
                lineKeys
            ));

            subPredicates.push(Predicate.create(
                'JobLine', 'any', 'C_Line_key', 'in', lineKeys
            ));

            predicates.push(Predicate.or(subPredicates));
        }

        if (notEmpty(filter.testArticles)) {
            let testArticleKeys = filter.testArticles.map((testArticle: any) => {
                return testArticle.TestArticleKey;
            });

            predicates.push(Predicate.create(
                'JobTestArticle', 'any', 'cv_TestArticle.C_TestArticle_key', 'in', testArticleKeys
            ));
        }

        if (filter.Batch) {
            predicates.push(Predicate.create(
                'JobTestArticle', FilterQueryOp.Any, 'Batch', FilterQueryOp.Contains, { value: filter.Batch },
            ));
        }

        if (notEmpty(filter.institutions)) {
            let institutionKeys = filter.institutions.map((institution: any) => {
                return institution.InstitutionKey;
            });

            predicates.push(Predicate.create(
                'JobInstitution', 'any', 'C_Institution_key', 'in', institutionKeys
            ));
        }

        if (notEmpty(filter.sites)) {
            let siteKeys = filter.sites.map((site: any) => {
                return site.SiteKey;
            });

            predicates.push(Predicate.create(
                'JobInstitution', 'any', 'C_Site_key', 'in', siteKeys
            ));
        }

        if (notEmpty(filter.C_IACUCProtocol_keys)) {
            predicates.push(
                Predicate.create('C_IACUCProtocol_key', 'in', filter.C_IACUCProtocol_keys));
        }

        if (notEmpty(filter.C_Compliance_keys)) {
            predicates.push(
                Predicate.create('C_Compliance_key', 'in', filter.C_Compliance_keys));
        }

        if (filter.StudyName) {
            predicates.push(Predicate.create('Study.StudyName', FilterQueryOp.Contains, { value: filter.StudyName }));
        }
        if (filter.InputValue) {

            let inputValuePredicate = Predicate.and([
                Predicate.create('InputValue', FilterQueryOp.Contains, { value: filter.InputValue }),
                Predicate.create('Input.cv_DataType.DataType', 'in', 
                    ['Text', 'Long Text', 'Enumeration', 'Long Enumeration']
                )
            ]);

            let subPredicate = Predicate.create(
                'TaskInstance.TaskInput', 'any',
                inputValuePredicate
            );

            predicates.push(Predicate.create(
                'TaskJob', 'any',
                subPredicate
            ));
        }
        if (filter.Animals && filter.Animals.length) {
            let animalKeys = filter.Animals.map((animal: any) => animal.C_Material_key);
            predicates.push(Predicate.create(
                'JobMaterial', 'any', 'Material.C_Material_key', 'in', animalKeys
            ));
        }

        if (filter.LockedTasks) {
            // Filter on jobs that contain any locked or unlocked tasks
            let value = filter.LockedTasks;
            let operator: string;
            if (value !== true && value !== 'true') {
                operator = '!=';
            } else {
                operator = '==';
            }

            predicates.push(Predicate.create(
                'TaskJob', 'any', 'TaskInstance.IsLocked', operator, true
            ));
        }

        // handle workspace filters
        if ('animal-filter' in filter) {
            predicates.push(Predicate.create(
                'JobMaterial', 'any', 'Material.C_Material_key', 'in', filter['animal-filter']
            ));
        }

        return predicates;
    }

    getJob(jobKey: number, expands?: string[]): Promise<any> {
        let query = EntityQuery.from('Jobs')
            .where('C_Job_key', '==', jobKey);
        if (notEmpty(expands)) {
            query = query.expand(expands.join(','));
        }
        return this.dataManager.returnSingleQueryResult(query);
    }
    
    getJobPharmaDetails(jobKey: number, loadTasks: boolean): Promise<any> {

        let additionalPropertiesToExpand: any[] = [
            'JobGroup.JobGroupTreatment',
            'JobGroup.JobGroupTreatment.ProtocolInstance',
            'JobGroup.AnimalPlaceholder.Material.TaskMaterial',
            'AnimalPlaceholder.Material.Animal',
            'AnimalPlaceholder.Placeholder',
            'JobGroup.AnimalPlaceholder',
            'Order',
            'JobLocation',
            'JobOrder',
        ];
        if (loadTasks) {
            additionalPropertiesToExpand.push('TaskJob.TaskInstance.WorkflowTask.Output.OutputFlag');
            additionalPropertiesToExpand.push('TaskJob.TaskInstance.TaskInput.Input');
        }

        const jobPharmaPropertiesToExpand: string[] = this.basicJobPropertiesToExpand.concat(additionalPropertiesToExpand);
        return this.getJob(jobKey, jobPharmaPropertiesToExpand);
    }

    getOrderByKey(jobKey: number): Promise<any> {
        let query = EntityQuery.from('Orders')
            .where('C_Order_key', '==', jobKey);

        return this.dataManager.returnSingleQueryResult(query);
    }
    
    getJobPrefixField(): Promise<string> {
        return this.namingService.getNameFormat('Job').then((jobNameFormat: any) => {
            let prefixField = '';
            if (jobNameFormat && jobNameFormat.cv_JobPrefixField) {
                prefixField = jobNameFormat.cv_JobPrefixField.JobPrefixField;
            }

            return Promise.resolve(prefixField);
        });
    }

    autoGenerateJobID(job: any): Promise<string> {
        const url = 'api/namegenerator/generateJobID';
        const request = {
            C_Job_key: job.C_Job_key,
            JobID: job.JobID,
            JobCode: job.JobCode,
            C_IACUCProtocol_key: job.C_IACUCProtocol_key,
            C_Line_key: job.C_Line_key,
            C_JobType_key: job.C_JobType_key,
            C_JobSubtype_key: job.C_JobSubtype_key,
            C_Compliance_key: job.C_Compliance_key
        };

        return this.webApiService.postApi(url, request).then((response: any) => {
            return response.data;
        });
    }

    getJobByID(jobId: string): Promise<any> {
        let query = EntityQuery.from('Jobs')
            .where('JobID', '==', jobId);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getFilteredJobs(filterText: string, limit: number): Promise<any[]> {
        let predicates = [];

        if (filterText) {
            predicates.push(Predicate.create('JobID', FilterQueryOp.Contains, { value: filterText }));
        }

        let query = EntityQuery.from('Jobs')
            .orderBy('JobID');

        if (predicates.length > 0) {
            query = query.where(predicates);
        }

        if (limit) {
            query = query.top(limit);
        }

        return this.dataManager.returnQueryResults(query);
    }

    jobRefreshVocabulary() {
        this.vocabularyService.refreshVocabularyData();
    }

    getJobTasks(jobKey: number, extraExpands?: string[]): Observable<any[]> {
        let predicate = new Predicate('TaskJob', 'any', 'C_Job_key', '==', jobKey);
        let query = EntityQuery.from('TaskInstances')
            .where(predicate)
            .orderBy('ProtocolTask.SortOrder');

        let expandClauses = [
            'WorkflowTask',
            'ProtocolTask.Protocol',
            'ProtocolInstance.Protocol',
            'TaskMaterial.Material.Animal',
            'TaskMaterial.Material.Sample',
            'TaskInput.Input.cv_DataType',
            'TaskJob'
        ];

        if (notEmpty(extraExpands)) {
            expandClauses = expandClauses.concat(extraExpands);
        }
        return defer(() => this.dataManager.returnQueryResults(query))
        .pipe(
            concatMap((tasks) => {
                const requests = [
                    defer(() => this.vocabularyService.ensureCVLoaded('cv_AnimalStatuses')),
                    defer(() => this.vocabularyService.ensureCVLoaded('cv_TimeUnits')),
                    defer(() => this.vocabularyService.ensureCVLoaded('cv_TimeRelations')),
                    defer(() => this.vocabularyService.ensureCVLoaded('cv_DataTypes')),
                    defer(() => this.vocabularyService.ensureCVLoaded('cv_TaskTypes')),
                    this.dataManager.ensureRelationships$(tasks, expandClauses)
                ];
                return forkJoin(requests).pipe(map(() => tasks));
            })
        );
    }

    getJobMaterials(jobKey: number): Promise<any[]> {
        let predicate = new Predicate('C_Job_key', '==', jobKey);

        let expandClauses = [
            'Material.Animal.cv_Sex',
            'Material.Animal.cv_AnimalStatus',
            'Material.Animal.Genotype',
            'Material.Line',
            'Material.Sample.cv_PreservationMethod',
            'Material.Sample.cv_SampleType',
            'Material.Sample.cv_SampleStatus',
            'Material.cv_MaterialType',
            'Material.MaterialSourceMaterial.SourceMaterial.Animal',
            'Material.MaterialSourceMaterial.SourceMaterial.Sample',
            'Material.MaterialPoolMaterial.MaterialPool'
        ];

        let query = EntityQuery.from('JobMaterials')
            .expand(expandClauses.join(','))
            .where(predicate);

        let p1 = this.dataManager.getQueryResults(query);

        let p2 = Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_GenotypeAssays'),
            this.vocabularyService.ensureCVLoaded('cv_GenotypeSymbols')
        ]);

        return Promise.all([p1, p2]).then((responses) => {
            return responses[0];
        });
    }

    ensureJobCohorts(job: any): Observable<any> {
        const expands = [
            'JobCohort.Cohort.CohortMaterial'
        ];
        return this.dataManager.ensureRelationships$([job], expands);
    }

    async ensureVisibleColumnsDataLoaded(jobs: any[], visibleColumns: string[]): Promise<void> {
        let expands = this.generateExpandsFromVisibleColumns(jobs[0], visibleColumns);
        if (expands.includes('Order')) {
            await this.orderService.getAllOrders();
            expands = expands.filter((field) => field !== 'Order');
        }
        return this.dataManager.ensureRelationships(jobs, expands);
    }

    getJobCharacteristics(jobKey: number): Promise<any[]> {
        let expandClauses = [
            'JobCharacteristic.cv_DataType',
            'JobCharacteristic.EnumerationClass.EnumerationItem',
            'JobCharacteristic.cv_JobCharacteristicLinkType',
        ];

        let query = EntityQuery.from('JobCharacteristicInstances')
            .expand(expandClauses.join(','))
            .where('C_Job_key', '==', jobKey)
            .orderBy('JobCharacteristic.SortOrder');

        return this.dataManager.getQueryResults(query);
    }

    getJobMaterialCount(taxonKey: number): Promise<number> {
        let query = EntityQuery.from('JobMaterials')
            .expand('Material')
            .where('Material.C_Taxon_key', '==', taxonKey)
            .take(0)
            .inlineCount(true);

        return this.dataManager.returnQueryCount(query);
    }

    getTaskPlaceholdersByKey(key: string): Promise<any> {
        let query = EntityQuery.from('TaskPlaceholders')
            .where('C_Placeholder_key', '==', key);

        return this.dataManager.returnQueryResults(query, true);
    }

    getTaskPlaceholdersByAnimalPlaceholder(key: string): Promise<any> {
        let query = EntityQuery.from('TaskPlaceholders')
            .where('C_AnimalPlaceholder_key', '==', key);

        return this.dataManager.returnQueryResults(query, true);
    }

    getAnimalPlaceholdersByKey(key: string): Promise<any> {
        let query = EntityQuery.from('AnimalPlaceholders')
            .where('C_Placeholder_key', '==', key);

        return this.dataManager.returnQueryResults(query, true);
    }

    getTaskPlaceholderInputsByKey(key: string): Promise<any> {
        let query = EntityQuery.from('TaskPlaceholderInputs')
            .where('C_TaskPlaceholder_key', '==', key);

        return this.dataManager.returnQueryResults(query, true);
    }

    getRecentJobs(limit: number): Promise<any[]> {
        let query = EntityQuery.from("Jobs")
            .orderBy('DateCreated DESC')
            .take(limit);

        return this.dataManager.returnQueryResults(query);
    }

    getJobStandardPhrases(): Promise<any[]> {
        let query = EntityQuery.from('JobStandardPhrase');

        return this.dataManager.getQueryResults(query);
    }

    getWorkflowTaskByKey(key: string): Promise<any> {
        let query = EntityQuery.from('WorkflowTasks')
            .where('C_WorkflowTask_key', '==', key);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getJobStandardPhraseCounts(): Promise<CountResult[]> {
        return this.webApiService.callCountApi('api/counts/GetJobStandardPhraseCounts', null);
    }

    ensureMaterialJobsLoaded(animals: any[]): Promise<void> {
        let expands = [
            'Material.JobMaterial.Job'
        ];
        return this.dataManager.ensureRelationships(animals, expands);
    }

    createJob(): Promise<any> {
        return this.vocabularyService.getCVDefault('cv_JobStatuses').then((jobStatus: any) => {

            let initialValues: any = {
                DateCreated: new Date(),
                IsLocked: false
            };

            if (jobStatus) {
                initialValues.C_JobStatus_key = jobStatus.C_JobStatus_key;
            }

            return this.dataManager.createEntity('Job', initialValues);
        });
    }

    createTaskJob(
        jobKey: number,
        taskInstanceKey: number,
        sequence: number
    ): any {
        let initialValues = {
            C_Job_key: jobKey,
            C_TaskInstance_key: taskInstanceKey,
            Sequence: sequence
        };

        return this.dataManager.createEntity('TaskJob', initialValues);
    }

    createJobMaterial(initialValues: IJobMaterial): any {
        let manager = this.dataManager.getManager();
        let entityType = 'JobMaterial';
        let initialJobKey = initialValues.C_Job_key;
        let initialMaterialKey = initialValues.C_Material_key;

        // Check local entities for duplicates
        let jobMaterials: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        let duplicates = jobMaterials.filter((jobMaterial) => {
            const isDuplicate = softCompare(jobMaterial.C_Job_key, initialJobKey) &&
                softCompare(jobMaterial.C_Material_key, initialMaterialKey);
            if (this.getIsGLPFlag()) {
                return isDuplicate && !jobMaterial.DateOut;
            } else {
                return isDuplicate;
            }
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }

        return null;
    }

    createJobMaterialPool(initialValues: any): any {
        let manager = this.dataManager.getManager();
        let entityType = 'JobMaterialPool';
        let initialJobKey = initialValues.C_Job_key;
        let initialMaterialPoolKey = initialValues.C_MaterialPool_key;

        // Check local entities for duplicates
        let jobMaterialPools: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        let duplicates = jobMaterialPools.filter((jobMaterialPool) => {
            return softCompare(jobMaterialPool.C_Job_key, initialJobKey) &&
                softCompare(jobMaterialPool.C_MaterialPool_key, initialMaterialPoolKey);
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }

        return null;
    }

    createJobCohort(initialValues: any): any {
        let manager = this.dataManager.getManager();
        let entityType = 'JobCohort';
        let initialJobKey = initialValues.C_Job_key;
        let initialCohortKey = initialValues.C_Cohort_key;

        // Check local entities for duplicates
        let jobCohorts: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        let duplicates = jobCohorts.filter((jobCohort) => {
            return softCompare(jobCohort.C_Job_key, initialJobKey) &&
                softCompare(jobCohort.C_Cohort_key, initialCohortKey);
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }

        return null;
    }

    createJobCharacteristics(job: any): Promise<any[]> {
        let p1 = this.createJobTypeCharacteristics(job);
        let p2 = this.createJobIacucCharacteristics(job);

        return Promise.all([p1, p2]);
    }

    createJobTypeCharacteristics(job: any): Promise<any[]> {
        let expandClauses = [
            'cv_DataType',
            'EnumerationClass.EnumerationItem',
            'cv_JobCharacteristicLinkType',
            'JobCharacteristicJobSubtype',
            'JobCharacteristicJobType'
        ];

        let predicates: Predicate[] = [];
        predicates.push(Predicate.create('IsActive', '==', true));
        predicates.push(Predicate.create('cv_JobCharacteristicLinkType.JobCharacteristicLinkType', '==', 'Job Type'));

        if (this.featureFlagService.getIsCRO()) {
            predicates.push(Predicate.create('JobCharacteristicJobSubtype', 'any', 'C_JobSubtype_key', '==', job.C_JobSubtype_key));
        } else {
            predicates.push(Predicate.create('JobCharacteristicJobType', 'any', 'C_JobType_key', '==', job.C_JobType_key));
        }

        let query = EntityQuery.from('JobCharacteristics')
            .expand(expandClauses.join(','))
            .where(Predicate.and(predicates))
            .orderBy('SortOrder');

        return this.dataManager.executeQuery(query).then((data) => {
            let newCharacteristics: any[] = [];
            let characteristics = data.results as any[];
            for (let characteristic of characteristics) {

                let newCharacteristic = this.dataManager.createEntity(
                    'JobCharacteristicInstance',
                    {
                        C_JobCharacteristic_key: characteristic.C_JobCharacteristic_key,
                        C_Job_key: job.C_Job_key,
                        C_JobSubtype_key: characteristic.C_JobSubtype_key,
                        CharacteristicName: characteristic.CharacteristicName,
                        Description: characteristic.Description,
                        DefaultValue: characteristic.DefaultValue
                    });
                newCharacteristics.push(newCharacteristic);
            }
            return newCharacteristics;
        }).catch(this.dataManager.queryFailed);
    }

    createJobIacucCharacteristics(job: any): Promise<any[]> {
        let expandClauses = [
            'cv_DataType',
            'EnumerationClass.EnumerationItem',
            'cv_JobCharacteristicLinkType',
            'JobCharacteristicIACUCProtocol'
        ];

        let predicates: Predicate[] = [];
        predicates.push(Predicate.create('IsActive', '==', true));
        predicates.push(Predicate.create('cv_JobCharacteristicLinkType.JobCharacteristicLinkType', '==', 'IACUC Protocol'));
        predicates.push(Predicate.create('JobCharacteristicIACUCProtocol', 'any', 'C_IACUCProtocol_key', '==', job.C_IACUCProtocol_key));

        let query = EntityQuery.from('JobCharacteristics')
            .expand(expandClauses.join(','))
            .where(Predicate.and(predicates))
            .orderBy('SortOrder');

        return this.dataManager.executeQuery(query).then((data) => {
            let newCharacteristics: any[] = [];
            let characteristics = data.results as any[];
            for (let characteristic of characteristics) {

                let newCharacteristic = this.dataManager.createEntity(
                    'JobCharacteristicInstance',
                    {
                        C_JobCharacteristic_key: characteristic.C_JobCharacteristic_key,
                        C_Job_key: job.C_Job_key,
                        C_JobSubtype_key: characteristic.C_JobSubtype_key,
                        CharacteristicName: characteristic.CharacteristicName,
                        Description: characteristic.Description,
                        DefaultValue: characteristic.DefaultValue
                    });
                newCharacteristics.push(newCharacteristic);
            }
            return newCharacteristics;
        }).catch(this.dataManager.queryFailed);
    }

    createJobTestArticle(initialValues: any): any {
        return this.dataManager.createEntity('JobTestArticle', initialValues);
    }

    createJobInstitution(initialValues: any): any {
        return this.dataManager.createEntity('JobInstitution', initialValues);
    }

    createJobLocation(initialValues: any): any {
        return this.dataManager.createEntity('JobLocation', initialValues);
    }

    createJobInstitutionBillingContact(initialValues: any): any {
        return this.dataManager.createEntity('JobInstitutionBillingContact', initialValues);
    }

    createJobGroup(initialValues: any): any {
        return this.dataManager.createEntity('JobGroup', initialValues);
    }

    createJobGroupTreatment(initialValues: any): any {
        return this.dataManager.createEntity('JobGroupTreatment', initialValues);
    }

    createJobLine(initialValues: any): any {
        return this.dataManager.createEntity('JobLine', initialValues);
    }



    createPlaceholder(initialValues: any): any {
        return this.dataManager.createEntity('Placeholder', initialValues);
    }

    createPlaceholderForTask(initialValues: any): any {
        return this.dataManager.createEntity('TaskPlaceholder', initialValues);
    }

    createJobStandardPhrase(initialValues: any): any {
        return this.dataManager.createEntity('JobStandardPhrase', initialValues);
    }

    jobStatusChanged(job: any, isStudy2: boolean, readonly: boolean) {
        if (this.isJobInEndState(job) && notEmpty(job.TaskJob)) {           
            // get uncompleted tasks to open modal with
            let tasks = job.TaskJob.map((taskJob: any) => {
                return taskJob.TaskInstance;
            });
            // filter out end state tasks
            tasks = tasks.filter((task: any) => {
                return !(task.cv_TaskStatus &&
                    task.cv_TaskStatus.IsEndState);
            });
            if (isStudy2) {
                // show only child tasks
                tasks = tasks.filter((task: any) => {
                    return task.C_GroupTaskInstance_key;
                });
            }
            if (notEmpty(tasks)) {
                let title = 'Overview for ' + job.JobID;
                this.openCancelTasksModal(tasks, title, job, readonly);
            }
        }
    }

    jobShowOverview(job: any, isStudy2: boolean, readonly: boolean): Promise<any> {
        if (notEmpty(job.TaskJob)) {
            // get uncompleted tasks to open modal with
            let tasks = job.TaskJob.map((taskJob: any) => {
                return taskJob.TaskInstance;
            });
            // filter out end state tasks
            tasks = tasks.filter((task: any) => {
                return !(task.cv_TaskStatus &&
                    task.cv_TaskStatus.IsEndState);
            });
            if (isStudy2) {
                // show only child tasks
                tasks = tasks.filter((task: any) => {
                    return task.C_GroupTaskInstance_key;
                });
            }
            if (notEmpty(tasks)) {
                let title = 'Overview for ' + job.JobID;
                return this.openCancelTasksModal(tasks, title, job, readonly);
            }
        }
    }

    isJobInEndState(job: any): boolean {
        if (!job || !job.cv_JobStatus) {
            return false;
        }
        return job.cv_JobStatus.IsEndState;
    }

    openCancelTasksModal(tasks: any, title: string, job: any, readonly: boolean): Promise<any> {
        this.cancelTasksModal = this.modalService.open(
            EditTasksComponent,
            {
                size: 'xl',
                backdrop: "static",
                keyboard: false
            }
        );
        let modalComponent: EditTasksComponent = this.cancelTasksModal.componentInstance;
        modalComponent.title = title;
        modalComponent.tasks = tasks;
        modalComponent.job = job;
        modalComponent.readonly = readonly;
        return this.cancelTasksModal.result.then(() => {
            this.cancelTasksModal = null;
        }).catch(() => {
            this.cancelTasksModal = null;
        });
    }

    async copyJob(fromJob: any, toJob: any, isJobPharma: boolean = false, isCRO: boolean = false) {
        const formatPlaceholderName = (name: string) => name.replace(fromJob.JobID, toJob.JobID ?? '');

        // Copy job level details
        toJob.C_JobType_key = fromJob.C_JobType_key;
        toJob.C_Study_key = fromJob.C_Study_key;
        toJob.C_Line_key = fromJob.C_Line_key;
        toJob.C_IACUCProtocol_key = fromJob.C_IACUCProtocol_key;
        toJob.C_JobType_key = fromJob.C_JobType_key;
        toJob.C_JobSubtype_key = fromJob.C_JobSubtype_key;
        toJob.C_Institution_key = fromJob.C_Institution_key;
        toJob.C_Compliance_key = fromJob.C_Compliance_key;
        toJob.C_JobReport_key = fromJob.C_JobReport_key;
        toJob.Site = fromJob.Site;
        toJob.CurrentLocationPath = fromJob.CurrentLocationPath;
        toJob.Cost = fromJob.Cost;
        toJob.Duration = fromJob.Duration;
        toJob.Goal = fromJob.Goal;
        toJob.Notes = fromJob.Notes;
        toJob.ResearchDirector = fromJob.ResearchDirector;
        toJob.ClientManager = fromJob.ClientManager;
        toJob.StudyDirector = fromJob.StudyDirector;
        toJob.ProjectImplantDate = fromJob.ProjectImplantDate;
        toJob.ImplantedDate = fromJob.ImplantedDate;
        toJob.ProjectedStartDate = fromJob.ProjectedStartDate;
        toJob.DateStarted = fromJob.DateStarted;
        toJob.DateEnded = fromJob.DateEnded;
        toJob.DurationDays = fromJob.DurationDays;
        toJob.JobGroupOverage = fromJob.JobGroupOverage;
        toJob.Imaging = fromJob.Imaging;
        toJob.JobCode = fromJob.JobCode;
        toJob.LeadScientist = fromJob.LeadScientist;
        toJob.StudyMonitor = fromJob.StudyMonitor;
        if (!isJobPharma) {
            toJob.IsHighThroughput = fromJob.IsHighThroughput;
        }

        // Copy institutions
        for (let fromInstitution of fromJob.JobInstitution) {
            const jobInstitution = this.dataManager.createEntity('JobInstitution', {
                DateCreated: new Date(),
                C_Job_key: toJob.C_Job_key,
                C_Institution_key: fromInstitution.C_Institution_key,
                Institution: fromInstitution.Institution,
                C_Site_key: fromInstitution.C_Site_key,
                Site: fromInstitution.Site,
                C_ScientificContactPerson_key: fromInstitution.C_ScientificContactPerson_key,
                ScientificContactPerson: fromInstitution.ScientificContactPerson,
                C_BillingContactPerson_key: fromInstitution.C_BillingContactPerson_key,
                BillingContactPerson: fromInstitution.BillingContactPerson,
                C_AuthorizationContactPerson_key: fromInstitution.C_AuthorizationContactPerson_key,
                AuthorizationContactPerson: fromInstitution.AuthorizationContactPerson
            });

            for (const fromBillingContact of fromInstitution.JobInstitutionBillingContact) {
                const billingContact = this.dataManager.createEntity('JobInstitutionBillingContact', {
                    DateCreated: new Date(),
                    C_ContactPerson_key: fromBillingContact.C_ContactPerson_key
                });
                jobInstitution.JobInstitutionBillingContact.push(billingContact);
            }
        }

        // Copy locations
        for (let fromLocation of fromJob.JobLocation) {
            this.dataManager.createEntity('JobLocation', {
                DateCreated: new Date(),
                C_Job_key: toJob.C_Job_key,
                C_Location_key: fromLocation.C_Location_key,
                C_LocationPosition_key: fromLocation.C_LocationPosition_key,
                C_Workgroup_key: fromLocation.C_Workgroup_key,
            });
        }

        // Copy characteristics
        for (let fromCharacteristic of fromJob.JobCharacteristicInstance) {
            this.dataManager.createEntity('JobCharacteristicInstance', {
                DateCreated: new Date(),
                C_Job_key: toJob.C_Job_key,
                C_JobCharacteristic_key: fromCharacteristic.C_JobCharacteristic_key,
                C_JobSubtype_key: fromCharacteristic.C_JobSubtype_key,
                CharacteristicValue: fromCharacteristic.CharacteristicValue,
                CharacteristicName: fromCharacteristic.CharacteristicName,
                Description: fromCharacteristic.Description,
                DefaultValue: fromCharacteristic.DefaultValue
            });
        }
        let fromTaskJobs = sortObjectArrayByProperty(fromJob.TaskJob.slice(), 'Sequence');

        // Copy protocol intances
        let protocolInstances: any[] = [];
        for (let fromTaskJob of fromTaskJobs) {
            let fromTask = fromTaskJob.TaskInstance;
            if (isJobPharma && fromTask.IsGroup === false) {
                if (!fromTask.WorkflowTask.NoMaterials) {
                    // In JobPharma, we only want to copy group task and also no material tasks
                    continue;
                }
            }
            if (fromTask.ProtocolInstance) {
                protocolInstances.push(fromTask.ProtocolInstance);
            }
        }
        protocolInstances = uniqueArray(protocolInstances);
        let protocolInstancesNew: any[] = [];
        for (let protocolInstance of protocolInstances) {
            const newProtocolInstance = this.dataManager.createEntity('ProtocolInstance', {
                C_Protocol_key: protocolInstance.C_Protocol_key,
                ProtocolAlias: protocolInstance.ProtocolAlias,
            });
            newProtocolInstance.UsedInProtocol = protocolInstance.UsedInProtocol;
            protocolInstancesNew.push(newProtocolInstance);
        }

        // Copy job tasks
        for (let fromTaskJob of fromTaskJobs) {
            let fromTask = fromTaskJob.TaskInstance;
            if (isJobPharma && fromTask.IsGroup === false) {
                if (!fromTask.WorkflowTask.NoMaterials) {
                    // In JobPharma, we only want to copy group task and also no material tasks
                    continue;
                }
            }
            let newProtocolInstance = null;
            if (fromTask.ProtocolInstance) {
                newProtocolInstance = protocolInstancesNew.find((item) => {
                    return item.C_Protocol_key === fromTask.ProtocolInstance.C_Protocol_key
                        && item.ProtocolAlias === fromTask.ProtocolInstance.ProtocolAlias;
                });
            }
            let newProtocolInstanceKey = null;
            if (newProtocolInstance) {
                newProtocolInstanceKey = newProtocolInstance.C_ProtocolInstance_key;
            }

            // If task is versioned, use first original parent task in copied job
            let workflowTask = await this.getWorkflowTaskByKey(fromTask.C_WorkflowTask_key);
            while (workflowTask.C_ParentWorkflowTask_key !== null) {
                workflowTask = await this.getWorkflowTaskByKey(workflowTask.C_ParentWorkflowTask_key);
            }

            let toTask: any = this.dataManager.createEntity('TaskInstance', {
                DateCreated: new Date(),
                C_WorkflowTask_key: workflowTask.C_WorkflowTask_key,
                C_ScheduleReason_key: fromTask.C_ScheduleReason_key,
                C_TaskOutcome_key: fromTask.C_TaskOutcome_key,
                C_ProtocolTask_key: fromTask.C_ProtocolTask_key,
                C_ProtocolInstance_key: newProtocolInstanceKey,
                C_TaskStatus_key: fromTask.C_TaskStatus_key,
                IsLocked: false,
                SortOrder: fromTask.SortOrder,
                Comments: fromTask.Comments,
                TaskAlias: fromTask.TaskAlias,
                IsGroup: fromTask.IsGroup,
            });

            this.dataManager.createEntity('TaskJob', {
                DateCreated: new Date(),
                Sequence: fromTaskJob.Sequence,
                C_Job_key: toJob.C_Job_key,
                C_TaskInstance_key: toTask.C_TaskInstance_key
            });

            // Copy the inputs
            for (let fromInput of fromTask.TaskInput) {
                toTask.TaskInput.push(this.dataManager.createEntity('TaskInput', {
                    DateCreated: new Date(),
                    C_TaskInstance_key: toTask.C_TaskInstance_key,
                    C_Input_key: fromInput.C_Input_key,
                    InputValue: fromInput.InputValue
                }));
            }

            // Copy SampleGroups
            for (let sampleGroup of fromTask.SampleGroup) {
                toTask.SampleGroup.push(this.dataManager.createEntity('SampleGroup', {
                    DateCreated: new Date(),
                    C_TaskInstance_key: toTask.C_TaskInstance_key,
                    C_SampleType_key: sampleGroup.C_SampleType_key,
                    C_SampleStatus_key: sampleGroup.C_SampleStatus_key,
                    C_PreservationMethod_key: sampleGroup.C_PreservationMethod_key,
                    C_ContainerType_key: sampleGroup.C_ContainerType_key,
                    NumSamples: sampleGroup.NumSamples,
                    C_TimeUnit_key: sampleGroup.C_TimeUnit_key,
                    TimePoint: sampleGroup.TimePoint,
                    DateHarvest: sampleGroup.DateHarvest,
                    DateExpiration: sampleGroup.DateExpiration,
                    C_SampleSubtype_key: sampleGroup.C_SampleSubtype_key,
                    C_SampleProcessingMethod_key: sampleGroup.C_SampleProcessingMethod_key,
                    SendTo: sampleGroup.SendTo,
                    C_SampleAnalysisMethod_key: sampleGroup.C_SampleAnalysisMethod_key
                }));
            }
        }

        // Copy JobTestArticle
        for (const fromJobTestArticle of fromJob.JobTestArticle) {
            const jobTestArticle = this.dataManager.createEntity('JobTestArticle', {
                DateCreated: new Date(),
                C_Job_key: toJob.C_Job_key,
                C_TestArticle_key: fromJobTestArticle.C_TestArticle_key,
                Identifier: fromJobTestArticle.Identifier,
                Purity: fromJobTestArticle.Purity,
                Batch: fromJobTestArticle.Batch,
                Code: fromJobTestArticle.Code,
                Vehicle: fromJobTestArticle.Vehicle,
            });

            jobTestArticle.UsedInTreatment = fromJobTestArticle.UsedInTreatment;
        }
        if (isCRO) {

            // Copy JobLines
            for (let fromJobLine of fromJob.JobLine) {
                this.dataManager.createEntity('JobLine', {
                    DateCreated: new Date(),
                    C_Job_key: toJob.C_Job_key,
                    C_Line_key: fromJobLine.C_Line_key,
                });
            }

            // Copy JobGroups
            for (let fromJobGroup of fromJob.JobGroup) {
                const jobGroup = this.dataManager.createEntity('JobGroup', {
                    DateCreated: new Date(),
                    C_Job_key: toJob.C_Job_key,
                    Group: fromJobGroup.Group,
                    Number: fromJobGroup.Number,
                    Treatment: fromJobGroup.Treatment,
                    FormulationDose: fromJobGroup.FormulationDose,
                    ActiveDose: fromJobGroup.ActiveDose,
                    Route: fromJobGroup.Route,
                    DosingVolume: fromJobGroup.DosingVolume,
                    Schedule: fromJobGroup.Schedule,
                    C_Line_key: fromJobGroup.C_Line_key,
                    C_Sex_key: fromJobGroup.C_Sex_key,
                    MinAge: fromJobGroup.MinAge,
                    MaxAge: fromJobGroup.MaxAge,
                    C_MaterialOrigin_key: fromJobGroup.C_MaterialOrigin_key
                });

                for (const fromJobGroupTreatment of fromJobGroup.JobGroupTreatment) {
                    let protocolInstanceKey = null;
                    if (fromJobGroupTreatment.ProtocolInstance) {
                        const protocolInstance = protocolInstancesNew.find((pi) =>
                            pi.C_Protocol_key === fromJobGroupTreatment.ProtocolInstance.C_Protocol_key &&
                            pi.ProtocolAlias === fromJobGroupTreatment.ProtocolInstance.ProtocolAlias);
                        protocolInstanceKey = protocolInstance ? protocolInstance.C_ProtocolInstance_key : null;
                    }
                    const jobGroupTreatment = this.dataManager.createEntity('JobGroupTreatment', {
                        DateCreated: new Date(),
                        C_JobGroup_key: jobGroup.C_JobGroup_key,
                        C_ProtocolInstance_key: protocolInstanceKey,
                        Treatment: fromJobGroupTreatment.Treatment,
                        FormulationDose: fromJobGroupTreatment.FormulationDose,
                        ActiveDose: fromJobGroupTreatment.ActiveDose,
                        ActiveUnit: fromJobGroupTreatment.ActiveUnit,
                        DosingUnit: fromJobGroupTreatment.DosingUnit,
                        Unit: fromJobGroupTreatment.Unit,
                        Concentration: fromJobGroupTreatment.Concentration,
                        Route: fromJobGroupTreatment.Route,
                        DosingVolume: fromJobGroupTreatment.DosingVolume,
                        Schedule: fromJobGroupTreatment.Schedule
                    });
                    jobGroup.JobGroupTreatment.push(jobGroupTreatment);
                }

                for (const fromPlaceHolder of fromJobGroup.Placeholder) {
                    const placeholder = this.dataManager.createEntity('Placeholder', {
                        DateCreated: new Date(),
                        C_Job_key: toJob.C_Job_key,
                        C_JobGroup_key: jobGroup.C_JobGroup_key,
                        PlaceholderName: formatPlaceholderName(fromPlaceHolder.PlaceholderName),
                    });
                    jobGroup.Placeholder.push(placeholder);
                }

                for (const fromAnimalPlaceholder of fromJobGroup.AnimalPlaceholder) {
                    const placeholder = jobGroup.Placeholder.find((p: any) => p.PlaceholderName === formatPlaceholderName(fromAnimalPlaceholder.Placeholder.PlaceholderName));
                    const animalPlaceholder = this.dataManager.createEntity('AnimalPlaceholder', {
                        DateCreated: new Date(),
                        C_Job_key: toJob.C_Job_key,
                        C_JobGroup_key: jobGroup.C_JobGroup_key,
                        Name: formatPlaceholderName(fromAnimalPlaceholder.Name),
                        Placeholder: placeholder
                    });
                    jobGroup.AnimalPlaceholder.push(animalPlaceholder);
                }
            }

            // Copy JobStandardPhrases
            for (const fromJobStandardPhrase of fromJob.JobStandardPhrase) {
                this.dataManager.createEntity('JobStandardPhrase', {
                    DateCreated: new Date(),
                    C_Job_key: toJob.C_Job_key,
                    C_StandardPhrase_key: fromJobStandardPhrase.C_StandardPhrase_key
                });
            }
        }
    }

    deleteJob(job: any) {
        while (job.JobCharacteristicInstance.length > 0) {
            this.dataManager.deleteEntity(job.JobCharacteristicInstance[0]);
        }
        while (job.TaskJob.length > 0) {
            let taskJob = job.TaskJob[0];
            let task = taskJob.TaskInstance;

            this.dataManager.deleteEntity(taskJob);

            while (task.TaskInput.length > 0) {
                this.dataManager.deleteEntity(task.TaskInput[0]);
            }

            while (task.TaskMaterial.length > 0) {
                this.dataManager.deleteEntity(task.TaskMaterial[0]);
            }

            while (task.TaskPlaceholder.length > 0) {
                while (task.TaskPlaceholder.TaskPlaceholderInputlength > 0) {
                    this.dataManager.deleteEntity(task.TaskPlaceholder.TaskPlaceholderInput[0]);
                }
                this.dataManager.deleteEntity(task.TaskPlaceholder[0]);
            }

            this.dataManager.deleteEntity(task);
        }

        while (job.JobMaterial.length > 0) {
            this.dataManager.deleteEntity(job.JobMaterial[0]);
        }

        while (job.JobLine.length > 0) {
            this.dataManager.deleteEntity(job.JobLine[0]);
        }

        while (job.JobMaterialPool.length > 0) {
            this.dataManager.deleteEntity(job.JobMaterialPool[0]);
        }

        while (job.Placeholder.length > 0) {
            this.dataManager.deleteEntity(job.Placeholder[0]);
        }

        while (job.AnimalPlaceholder.length > 0) {
            this.dataManager.deleteEntity(job.AnimalPlaceholder[0]);
        }

        while (job.JobGroup.length > 0) {
            this.dataManager.deleteEntity(job.JobGroup[0]);
        }

        while (job.JobWorkflowTask.length > 0) {
            this.dataManager.deleteEntity(job.JobWorkflowTask[0]);
        }

        this.dataManager.deleteEntity(job);
    }

    deleteJobCharacteristic(jobCharacteristic: any) {
        this.dataManager.deleteEntity(jobCharacteristic);
    }

    deleteJobMaterial(jobMaterial: any) {
        jobMaterial.entityAspect.setDeleted();
    }

    deleteJobMaterialPool(jobMaterialPool: any) {
        this.dataManager.deleteEntity(jobMaterialPool);
    }

    deleteJobTaskMaterial(jobTaskMaterial: any) {
        this.dataManager.deleteEntity(jobTaskMaterial);
    }

    deleteJobTaskInput(jobTaskInput: any) {
        this.dataManager.deleteEntity(jobTaskInput);
    }

    deleteJobCohort(jobCohort: any) {
        this.dataManager.deleteEntity(jobCohort);
    }

    deleteJobTestArticle(jobTestArticle: any) {
        this.dataManager.deleteEntity(jobTestArticle);
    }

    deleteJobInstitution(jobInstitution: any) {
        if (jobInstitution.JobInstitutionBillingContact) {
            while (jobInstitution.JobInstitutionBillingContact.length > 0) {
                this.dataManager.deleteEntity(jobInstitution.JobInstitutionBillingContact[0]);
            }
        }
        this.dataManager.deleteEntity(jobInstitution);
    }

    deleteJobLocation(jobLocation: any) {
        this.dataManager.deleteEntity(jobLocation);
    }

    deleteJobInstitutionBillingContact(jobInstitutionBillingContact: any) {
        this.dataManager.deleteEntity(jobInstitutionBillingContact);
    }

    deleteTreatment(treatment: any) {
        this.dataManager.deleteEntity(treatment);
    }

    deleteJobGroup(jobGroup: any) {
        while (jobGroup.JobGroupTreatment.length > 0) {
            this.dataManager.deleteEntity(jobGroup.JobGroupTreatment[0]);
        }
        this.dataManager.deleteEntity(jobGroup);
    }

    deleteJobLine(jobLine: any) {
        this.dataManager.deleteEntity(jobLine);
    }
    
    deletePlaceholder(placeholder: any) {
        this.getAnimalPlaceholdersByKey(placeholder.C_Placeholder_key).then((animalPlaceholders: any) => {
            animalPlaceholders.forEach((animalPlaceholder: any) => {
                this.deleteAnimalPlaceholder(animalPlaceholder);
            });
        });

        this.getTaskPlaceholdersByKey(placeholder.C_Placeholder_key).then((taskPlaceholders: any) => {
            taskPlaceholders.forEach((taskPlaceholder: any) => {
                this.deleteTaskPlaceholder(taskPlaceholder);
            });
        });
        
        this.dataManager.deleteEntity(placeholder);
    }

    deleteTaskPlaceholder(taskPlaceholder: any) {
        this.getTaskPlaceholderInputsByKey(taskPlaceholder.C_TaskPlaceholder_key).then((taskPlaceholderInputs: any) => {
            taskPlaceholderInputs.forEach((taskPlaceholderInput: any) => {
                this.deleteTaskPlaceholderInput(taskPlaceholderInput);
            });
        });

        this.dataManager.deleteEntity(taskPlaceholder);
    }

    deleteAnimalPlaceholder(animalPlaceholder: any) {
        this.getTaskPlaceholdersByAnimalPlaceholder(animalPlaceholder.C_AnimalPlaceholder_key).then((taskPlaceholders: any) => {
            taskPlaceholders.forEach((taskPlaceholder: any) => {
                this.deleteTaskPlaceholder(taskPlaceholder);
            });
        });

        this.dataManager.deleteEntity(animalPlaceholder);
    }

    deleteTaskPlaceholderInput(taskPlaceholderInput: any) {
        this.dataManager.deleteEntity(taskPlaceholderInput);
    }

    deleteSampleGroupSourceMaterial(sgSource: any) {
        this.dataManager.deleteEntity(sgSource);
    }

    deleteJobStandardPhrase(jobStandardPhrase: any) {
        this.dataManager.deleteEntity(jobStandardPhrase);
    }

    cancelJob(job: any) {
        if (!job) {
            return;
        }

        if (job.C_Job_key > 0) {
            this._cancelJobEdits(job);
        } else {
            this._cancelNewJob(job);
        }
    }

    private _cancelNewJob(job: any) {
        try {
            this.deleteJob(job);
        } catch (error) {
            console.error('Error canceling new job: ' + error);
        }
    }

    private _cancelJobEdits(job: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(job);
        this.dataManager.rejectChangesToEntityByFilter(
            'JobGroup', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'JobGroupTreatment', (item: any) => {
                return item.C_Workgroup_key === job.C_Workgroup_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'SampleGroupSourceMaterial', (item: any) => {
                return item.C_Workgroup_key === job.C_Workgroup_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'JobMaterial', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'JobMaterialPool', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'JobTestArticle', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'JobInstitution', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'JobOrder', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        // Take a snapshot of taskJobs before discarding changes
        let taskJobsBefore = job.TaskJob.slice();
        this.dataManager.rejectChangesToEntityByFilter(
            'TaskJob', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        let taskJobs = null;
        if (taskJobsBefore.length > job.TaskJob.length) {
            // We must be cancelling new taskJobs, so use taskJob list from before discard
            taskJobs = taskJobsBefore;
        } else {
            // We must be cancelling taskJob deletions, so use taskJob list from after discard
            taskJobs = job.TaskJob;
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'JobLine', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        for (let taskJob of taskJobs) {
            let taskInputs = this.dataManager.rejectChangesToEntityByFilter(
                'TaskInput', (item: any) => {
                    return item.C_TaskInstance_key === taskJob.C_TaskInstance_key;
                }
            );
            for (let taskInput of taskInputs) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskCohortInput', (item: any) => {
                        return item.C_Input_key === taskInput.C_Input_key;
                    }
                );

                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskPlaceholderInput', (item: any) => {
                        return item.C_Input_key === taskInput.C_Input_key;
                    }
                );
            }

            let taskInstances = this.dataManager.rejectChangesToEntityByFilter(
                'TaskInstance', (item: any) => {
                    return item.C_TaskInstance_key === taskJob.C_TaskInstance_key;
                }
            );
            for (let taskInstance of taskInstances) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'ProtocolInstance', (item: any) => {
                        return item.C_ProtocolInstance_key === taskInstance.C_ProtocolInstance_key;
                    }
                );
            }

            this.dataManager.rejectChangesToEntityByFilter(
                'TaskPlaceholder', (item: any) => {
                    return item.C_TaskInstance_key === taskJob.C_TaskInstance_key;
                }
            );

            this.dataManager.rejectChangesToEntityByFilter(
                'TaskCohort', (item: any) => {
                    return item.C_TaskInstance_key === taskJob.C_TaskInstance_key;
                }
            );

            this.dataManager.rejectChangesToEntityByFilter(
                'TaskMaterial', (item: any) => {
                    return item.C_TaskInstance_key === taskJob.C_TaskInstance_key;
                }
            );

            this.dataManager.rejectChangesToEntityByFilter(
                'TaskInput', (item: any) => {
                    return item.C_TaskInstance_key === taskJob.C_TaskInstance_key;
                }
            );

            let outputSets = this.dataManager.rejectChangesToEntityByFilter(
                'TaskOutputSet', (item: any) => {
                    return item.C_TaskInstance_key === taskJob.C_TaskInstance_key;
                }
            );

            for (let outputSet of outputSets) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskOutputSetMaterial', (item: any) => {
                        return item.C_TaskOutputSet_key === outputSet.C_TaskOutputSet_key;
                    }
                );

                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskOutput', (item: any) => {
                        return item.C_TaskOutputSet_key === outputSet.C_TaskOutputSet_key;
                    }
                );
            }

            let sampleGroups = this.dataManager.rejectChangesToEntityByFilter(
                'SampleGroup', (item: any) => {
                    return item.C_TaskInstance_key === taskJob.C_TaskInstance_key;
                }
            );

            for (let sampleGroup of sampleGroups) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'SampleGroupSourceMaterial', (item: any) => {
                        return item.C_SampleGroup_key === sampleGroup.C_SampleGroup_key;
                    }
                );
            }
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'Note', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        let fileMaps = this.dataManager.rejectChangesToEntityByFilter(
            'StoredFileMap', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        // also reject files associated with each fileMap
        for (let fileMap of fileMaps) {
            this.dataManager.rejectChangesToEntityByFilter(
                'StoredFile', (item: any) => {
                    return item.C_StoredFile_key === fileMap.C_StoredFile_key;
                }
            );
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'Placeholder', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'AnimalPlaceholder', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'JobStandardPhrase', (item: any) => {
                return item.C_Job_key === job.C_Job_key;
            }
        );

        
    }

    listViewScreenIntoStudies1(viewLabel: FacetView) {
        this.listViewStudies1.next(viewLabel);      
    }

    listViewScreenIntoStudies2(viewLabel: FacetView) {
        this.listViewStudies2.next(viewLabel);      
    }

    createAnimalPlaceholders(jobGroup: any, count: any, begin: any = 0) {
        // Start placeholder naming at next unused number
        let x = begin + 1;
        while (x <= count) {
            this.dataManager.createEntity('AnimalPlaceholder', {
                C_Job_key: jobGroup.C_Job_key,
                C_JobGroup_key: jobGroup.C_JobGroup_key,
                C_Placeholder_key: jobGroup.Placeholder[0].C_Placeholder_key,
                Name: `${jobGroup.Placeholder[0].PlaceholderName}_${x}`,
                DateCreated: new Date(),
            });
            x++;
        }
    }

    deleteAnimalPlaceholders(jobGroup: any, count: any) {
        let x = 1;
        while (x <= count) {
            this.dataManager.deleteEntity(jobGroup.AnimalPlaceholder[jobGroup.AnimalPlaceholder.length - 1]);
            x++;
        }
    }

    createAnimalPlaceholderForTask(initialValues: any): any {
        return this.dataManager.createEntity('TaskPlaceholder', initialValues);
    }

    getGroupPlaceholders(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('Placeholders', queryDef);

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildGroupPlaceholdersdPredicates(queryDef.filter));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }

    private buildGroupPlaceholdersdPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];

        if (filter.name) {
            predicates.push(Predicate.create('PlaceholderName', FilterQueryOp.Contains, { value: filter.name }));
        }

        return predicates;
    }

    getJobCharacteristicInstances(): Promise<any[]> {
        let query = EntityQuery
            .from('JobCharacteristicInstances')
            .orderBy('CharacteristicName');

        return this.dataManager.returnQueryResults(query);
    }

    async addMaterialToJob(taskJob: TaskJob, material: Animal | Sample): Promise<void> {
        const {
            ProtocolTask,
            C_WorkflowTask_key,
            C_TaskStatus_key,
            C_ProtocolTask_key,
            TaskAlias,
            DateDue,
            C_Workgroup_key,
            Version,
            C_TaskInstance_key,
            SampleGroup: sg
        } = taskJob.TaskInstance;
        
        let newProtocolInstanceKey = null;
        if (ProtocolTask?.Protocol) {
            const { C_Protocol_key, Protocol: { ProtocolName } } = ProtocolTask;

            // Create a new ProtocolInstance
            newProtocolInstanceKey = this.taskService.createProtocolInstance({
                C_Protocol_key,
                ProtocolAlias: ProtocolName,
            }).C_ProtocolInstance_key;
        }

        const initialValues: Partial<TaskInstance> = {
            C_WorkflowTask_key,
            C_TaskStatus_key,
            C_ProtocolTask_key,
            C_ProtocolInstance_key: newProtocolInstanceKey,
            TaskAlias,
            DateDue,
            C_Workgroup_key,
            Version,
            C_GroupTaskInstance_key: C_TaskInstance_key,
            IsGroup: false
        };
        const { C_Material_key } = material;

        const createdTaskInstance = await this.taskService.createTaskInstance(initialValues) as TaskInstance;

        // Add the task to the Job
        this.createTaskJob(taskJob.Job.C_Job_key, createdTaskInstance.C_TaskInstance_key, 0);

        // Add material to Task                                
        this.taskService.createTaskMaterial({
            C_TaskInstance_key: createdTaskInstance.C_TaskInstance_key,
            C_Material_key,
        });

        sg?.forEach((sampleGroup: SampleGroup) => {
            this.dataManager.createEntity('SampleGroupSourceMaterial', {
                C_SampleGroup_key: sampleGroup.C_SampleGroup_key,
                C_Material_key,
                SampleGroup: sampleGroup,
            });
        });
    }
}
