import {
    Component,
    Input,
    OnInit,
    TemplateRef,

    OnDestroy
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LocationService } from '../../locations/location.service';
import { MaterialPoolService } from '../../services/material-pool.service';
import { WorkspaceFilterService } from '../../services/workspace-filter.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';

import {
    BaseFacet,
    BaseFacetService
} from '../../common/facet';

import { HousingTableOptions } from '../helpers/housing-table-options';
import { HousingFilterComponent } from './filter/housing-filter.component';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';

import { WsFilterEvent } from '../../services/ws-filter-event';
import { QueryDef } from '../../services/query-def';
import { filterToDate, maxSequence, notEmpty, uniqueArrayFromPropertyPath } from '../../common/util';
import { CageCardChooserReportType } from '../../reporting/cage-card-chooser.interface';
import { TranslationService } from '../../services/translation.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { TaskType } from '../../tasks/models';
import { ViewAddProtocolComponentService, ViewAddTaskComponentService } from '../../tasks/add-task';
import { SaveChangesService } from '../../services/save-changes.service';
import { TaskService } from '../../tasks/task.service';
import { ProtocolDateCalculator } from '../../tasks/tables/protocol-date-calculator';
import { SettingService } from '../../settings/setting.service';
import { FeatureFlagService } from '../../services/feature-flags.service';
import { DataContextService } from '@services/data-context.service';
import { takeUntil } from 'rxjs/operators';
import { arrowClockwise, brokenChain, chain, magnifier } from '@icons';

@Component({
    selector: 'housing-facet',
    templateUrl: './housing-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class HousingFacetComponent extends BaseFacet
    implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;

    readonly icons = { arrowClockwise, brokenChain, chain, magnifier };

    isGLP: boolean;
    isCRO: boolean;

    componentName = 'housing';
    animalsInSelectedRow: any[] = [];
    JOB_TYPE: string;
    WEAN_TYPE: string;
    reportTypes: CageCardChooserReportType[];
    housingTableOptions: HousingTableOptions;
    componentFilterSubscription: Subscription;

    // Active and required fields set by facet settings
    activeFields: string[] = [];
    inactiveFields: string[] = [];
    requiredFields: string[] = [];

    readonly COMPONENT_LOG_TAG = 'housing-facet';

    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private locationService: LocationService,
        private materialPoolService: MaterialPoolService,
        private modalService: NgbModal,
        workspaceFilterService: WorkspaceFilterService,
        private translationService: TranslationService,
        private vocabularyService: VocabularyService,
        private viewAddTaskComponentService: ViewAddTaskComponentService,
        private viewAddProtocolComponentService: ViewAddProtocolComponentService,
        private saveChangesService: SaveChangesService,
        private taskService: TaskService,
        private settingService: SettingService,
        private featureFlagService: FeatureFlagService,
        private dataContext: DataContextService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.isGLP = this.initIsGLP();
        this.isCRO = this.initIsCRO();
        this.housingTableOptions = new HousingTableOptions(this.cellFormatterService);

        this.dataTableColumns = new BehaviorSubject(this.housingTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.JOB_TYPE = translationService.translate('Job');
        this.WEAN_TYPE = translationService.translate('Wean');

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemList(tableState);
            }
        };
    }

    initIsGLP(): boolean {
        const flag = this.featureFlagService.getFlag("IsGLP");
        return flag && flag.IsActive && flag.Value.toLowerCase() === 'true';
    }
    initIsCRO(): boolean {
        const flag = this.featureFlagService.getFlag("IsCRO");
        return flag && flag.IsActive && flag.Value.toLowerCase() === 'true';
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.supportedWorkspaceFilters = ['animal-filter', 'job-filter'];
        this.reportTypes = [    
            { key: 'Mating', display: 'Mating' },
            { key: 'Wean', display: this.WEAN_TYPE },
            { key: 'Job', display: this.JOB_TYPE }
        ];
        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.exitDetail();
        });
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.settingService.getFacetSettingsByType('housing', this.isCRO, this.isGLP).then((facetSettings) => {
            this.activeFields = this.settingService.getActiveFields(facetSettings);
            this.inactiveFields = this.settingService.getInactiveFields(facetSettings);
            this.requiredFields = this.settingService.getRequiredFields(facetSettings);
        });
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.DatePooledStart = filterToDate(this.filter.DatePooledStart);
                this.filter.DatePooledEnd = filterToDate(this.filter.DatePooledEnd);
            } else {
                this.filter = {};
            }
        }
    }

    async loadItemList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        this.setLoadingState(tableState.loadingMessage);

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands = ['MaterialPoolMaterial.Material.Animal'];
        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        try {
            const response = await this.materialPoolService.getMaterialPools(queryDef);

            const visibleColumns = this.getVisibleColumns(this.housingTableOptions.options);
            await this.materialPoolService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        this.loading = true;
        this.createNewItem().then((item) => {
            this.loading = false;
            this.itemToEdit = item;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    async createNewItem(): Promise<any> {        
        const materialPoolTypeDefault = await this.vocabularyService.getCVDefault('cv_MaterialPoolTypes', true);            
                  
        const newMaterialPool = await this.materialPoolService.createMaterialPoolAsType(materialPoolTypeDefault.MaterialPoolType);        
        newMaterialPool.DatePooled = Date.now();

        await Promise.all([
            this.locationService.getDefaultLocation().then( (defaultLocation) => {
                this.locationService.createMaterialLocation({
                    C_MaterialPool_key: newMaterialPool.C_MaterialPool_key,
                    C_LocationPosition_key: defaultLocation.C_LocationPosition_key
                });
            }),

            this.vocabularyService.getCVDefault('cv_MaterialPoolStatuses').then((value) => {
                newMaterialPool.cv_MaterialPoolStatus = value;
            }),

            this.vocabularyService.getCVDefault('cv_MaterialPoolTypes').then((value) => {
                newMaterialPool.cv_MaterialPoolType = value;
            }),

            this.vocabularyService.getCVContainerTypeDefault('Animal').then((value) => {
                newMaterialPool.cv_ContainerType = value;
            }),
        ]);

        return newMaterialPool;
    }

    openFilter() {
        const ref = this.modalService.open(HousingFilterComponent, { size: 'lg' });
        const component = ref.componentInstance as HousingFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    dragStart() {
        this.materialPoolService.draggedPools = this.selectedRows;
    }

    dragStop() {
        setTimeout(() => {
            this.materialPoolService.draggedPools = [];
        }, 500);
    }

    async openCageCardModal(cageCardModal: TemplateRef<any>) {
        this.setLoadingState();
        this.animalsInSelectedRow = [];
        
        // Fetch all MaterialPoolMaterials
        const promises: Promise<any[]>[] = [];
        this.selectedRows.forEach((house) => {
            promises.push(this.materialPoolService.getMaterialPoolMaterials(
                house.C_MaterialPool_key
            ));
        });
        // make sure we have all animals
        await Promise.all(promises);
        
        // pass animals to the CageCardModal
        this.selectedRows.forEach((house) => {
            if (house.MaterialPoolMaterial.length > 0) {
                const animalsCurrentlyInCage = house.MaterialPoolMaterial
                    .filter((animal: any) => !animal.DateOut);

                this.animalsInSelectedRow = this.animalsInSelectedRow
                    .concat(animalsCurrentlyInCage);
            }
        });

        this.modalService.open(cageCardModal);
        this.stopLoading();
    }

    onWorkspaceFilterChange(wsFilterEvent: WsFilterEvent) {
        const oldFilterSupported = this.workspaceFilterSupported(wsFilterEvent.oldFilterKind);
        const newFilterSupported = this.workspaceFilterSupported(wsFilterEvent.filterKind);
        if (!this.ignoreWorkspaceFilter && (oldFilterSupported || newFilterSupported)) {
            this.reloadTable();
        }
    }

    clickBulkAssignTasks(): Promise<any> {
        return this.viewAddTaskComponentService.openComponent(TaskType.Housing)
            .then((taskValues) => {
                this.facetLoadingState.changeLoadingState(true);
                return this.createTasksForSelectedHousings(taskValues);
            }).then((newTaskInstances) => {
                if (notEmpty(newTaskInstances)) {
                    return this.saveChangesService.saveChanges(this.componentName).then(() => {
                        this.loggingService.logSuccess(
                            "Added new tasks for " +
                            this.selectedRows.length +
                            " housings",
                            null, this.componentName, true
                        );
                    });
                }
            }).then(() => {
                this.facetLoadingState.changeLoadingState(false);
            }).catch((error) => {
                this.facetLoadingState.changeLoadingState(false);
                throw error;
            });
    }

    clickBulkAssignProtocol(): Promise<any> {
        return this.viewAddProtocolComponentService.openComponent(TaskType.Housing)
            .then((taskValues) => {
                this.facetLoadingState.changeLoadingState(true);
                return this.createTasksForSelectedHousings(taskValues);
            }).then((newTaskInstances) => {
                if (notEmpty(newTaskInstances)) {
                    return this.saveChangesService.saveChanges(this.componentName).then(() => {
                        this.loggingService.logSuccess(
                            "Added new tasks for " +
                            this.selectedRows.length +
                            " housings",
                            null, this.componentName, true
                        );
                    });
                }
            }).then(() => {
                this.facetLoadingState.changeLoadingState(false);
            }).catch((error) => {
                this.facetLoadingState.changeLoadingState(false);
                throw error;
            });
    }

    createTasksForSelectedHousings(taskValues: any[]): Promise<any[]> {
        if (!notEmpty(taskValues)) {
            return Promise.resolve([]);
        }

        return this.materialPoolService.ensureTasksLoaded(this.selectedRows).then(() => {
            return this.getDefaultTaskStatus();
        }).then((taskStatusDefault: any) => {
            const taskInstances: any[] = [];

            let promise: Promise<void> = Promise.resolve();

            for (const housing of this.selectedRows) {
                let protocolInstance: any = null;
                if (notEmpty(taskValues) && taskValues[0].protocol) {
                    protocolInstance = this.createProtocolInstance(
                        taskValues[0].protocol, housing
                    );
                }

                for (const taskInstanceValues of taskValues) {
                    if (taskStatusDefault) {
                        taskInstanceValues.C_TaskStatus_key =
                            taskStatusDefault.C_TaskStatus_key;
                    }

                    // copy values to edit for this housing
                    const initialTaskValues = { ...taskInstanceValues };
                    if (protocolInstance) {
                        initialTaskValues.C_ProtocolInstance_key =
                            protocolInstance.C_ProtocolInstance_key;
                    }

                    promise = promise.then(() => {
                        return this.taskService.createTaskInstance(initialTaskValues);
                    }).then((newTaskInstance) => {
                        const taskMaterialPoolValues = {
                            C_MaterialPool_key: housing.C_MaterialPool_key,
                            C_TaskInstance_key: newTaskInstance.C_TaskInstance_key,
                            Sequence: maxSequence(housing.TaskMaterialPool) + 1
                        };
                        this.taskService.createTaskMaterialPool(taskMaterialPoolValues);

                        taskInstances.push(newTaskInstance);
                    });
                }
            }

            return promise.then(() => {
                return taskInstances;
            });
        }).then((newTaskInstances) => {
            // need schedule types for calculating protocol dates
            return this.ensureDataCalculatorCVsLoaded().then(() => {
                this.calculateProtocolDates(newTaskInstances);

                return newTaskInstances;
            });
        });
    }

    createProtocolInstance(protocol: any, materialPool: any): any {
        const protocolInstances = uniqueArrayFromPropertyPath(
            materialPool, 'TaskMaterialPool.TaskInstance.ProtocolInstance'
        );
        const protocolInitialValues = {
            C_Protocol_key: protocol.C_Protocol_key,
            ProtocolAlias: this.taskService.generateProtocolAlias(
                protocolInstances, protocol
            )
        };
        return this.taskService.createProtocolInstance(
            protocolInitialValues
        );
    }
    
    calculateProtocolDates(taskInstances: any[]) {
        if (!notEmpty(taskInstances) ||
            !taskInstances[0].C_ProtocolTask_key
        ) {
            // no protocol to process
            return;
        }
        const protocolDateCalculator = new ProtocolDateCalculator();
        for (const task of taskInstances) {
            protocolDateCalculator.scheduleMaterialDueDates(taskInstances, task);
        }
    }

    private getDefaultTaskStatus(): Promise<any> {
        const preferLocal = true;
        return this.vocabularyService.getCVDefault('cv_TaskStatuses', preferLocal);
    }

    private ensureDataCalculatorCVsLoaded(): Promise<any> {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_ScheduleTypes'),
            this.vocabularyService.ensureCVLoaded('cv_TimeRelations'),
            this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
        ]);
    }

    exitDetail() {
        this.reloadTable();
        this.changeView(this.LIST_VIEW);
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.materialPoolService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}
