<div class="detail-form-wrapper bulk-data-flex">

    <div class="detail-header">
        <h3>Bulk Data Entry</h3>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked()">

            <ng-container additional-groups>
                <ng-container *ngIf="searchEnabled">
                    <label for="searchInput">Search</label>
                    <input class="form-control search-input"
                           id="searchInput"
                           type="text"
                           [(ngModel)]="searchMicrochipID"
                           (keyup.enter)="onSearchEnter()" />
                </ng-container>

                <div class="btn-group" role="group">
                    <div class="btn-group" role="group">
                        <button type="button"
                                class="btn btn-secondary dropdown-toggle"
                                title="Export to CSV or PDF"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            <span class="gridster-d-sm-none gridster-d-md-inline-block">Export</span>
                            <span class="sr-only">Export</span>
                        </button>
                        <div class="dropdown-menu">
                            <a (click)="exportData(exportTypes.CSV)"
                               class="dropdown-item">
                                CSV
                            </a>
                            <a (click)="exportData(exportTypes.PDF)"
                               class="dropdown-item">
                                PDF
                            </a>
                        </div>
                    </div>
                    <button class="btn btn-secondary"
                            (click)="importOpenModal(importModal)"
                            [disabled]="readonly || import.inProgress"
                            *ngIf="!isGLP && importVisible">
                        <i class="fa fa-upload" aria-hidden="true" title="Import Outputs"></i>
                        <span class="gridster-d-sm-none gridster-d-md-inline-block">Import</span>
                    </button>
                    <button class="btn btn-secondary"
                            (click)="printData()"
                            [disabled]="loading">
                        <i class="fa fa-print" aria-hidden="true" title="Print"></i>
                        <span class="gridster-d-sm-none gridster-d-md-inline-block">Print</span>
                    </button>
                </div>

                <climb-multi-select [values]="workflowSettings"
                                    [(model)]="enabledWorkflowSettings"
                                    [displayAllSelectedText]="false"
                                    [fixedTitle]="true"
                                    [placeholder]="'Settings'"
                                    [enableSearch]="false"
                                    [showCheckAll]="false"
                                    [showUncheckAll]="false"
                                    (modelChange)="onWorkflowSettingsChange($event)">
                </climb-multi-select>
                <ngx-bootstrap-multiselect #freezeSelect
                                           title="Only three columns may be selected at one time."
                                           [settings]="freezeSettings"
                                           [texts]="freezeTexts"
                                           [options]="columnFreeze.labels | mapFn : toOptions"
                                           [(ngModel)]="columnFreeze.model"
                                           (dropdownClosed)="columnFreezeSelectChanged(columnFreeze.model)">
                </ngx-bootstrap-multiselect>
                <ngx-bootstrap-multiselect #columnOutputSelect
                                           [settings]="columnOutputSettings"
                                           [texts]="columnOutputTexts"
                                           [options]="columnOutput.labels | mapFn : toOptions"
                                           [(ngModel)]="columnOutput.model"
                                           (dropdownClosed)="columnOutputChanged(columnOutput.model)">
                </ngx-bootstrap-multiselect>
                <climb-column-select [columnLabels]="columnSelect"
                                     (onChange)="columnSelectChanged($event)"
                                     class="old-fashioned">
                </climb-column-select>
            </ng-container>
        </detail-toolbar>
    </div>
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <ng-container *ngIf="rows">
        <h4 class="table-title">
            <i class="fa fa-clock-o"></i>&nbsp;{{currentTimeText}}
            <ng-container *ngIf="common.JobID">
                —&nbsp;{{'Job' | translate}}&nbsp;Name:&nbsp;{{common.JobID}}
            </ng-container>
            <ng-container *ngIf="common.TaskAlias">
                —&nbsp;Task Name: &nbsp;{{common.TaskAlias}}
            </ng-container>
        </h4>
        <form class="form-horizontal detail-form bde-table-parent {{uniqueId}}" 
            style="position: relative;">

            <table class="table table-bordered workflow-bulk-data-entry bde-table {{uniqueId}}">
                <thead>
                    <tr class="sticky">
                        <!-- Job ID, if not common -->
                        <th #th
                            *ngIf="visible.JobID"
                            data-key="JobID"
                            [class.freeze]="getFreezedClass('JobID')"
                            [style.left.px]="getFreezedOffset('JobID')"
                        >
                            <ng-container *ngIf="!readonly">
                                {{'Job' | translate}} Name
                            </ng-container>
                            <ng-container *ngIf="readonly">
                                <a (click)="sortColumn('job.JobID', $event)"
                                   class="header-sort-link"
                                   title="Sort by {{'Job' | translate}} Name">
                                    {{'Job' | translate}} Name {{ tableSort.nested ? tableSort.nestedIndex['job.JobID'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['job.JobID'] : tableSort.reverse,
                                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['job.JobID'] : !tableSort.reverse
                                            }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'job.JobID' || tableSort.nested && tableSort.properties.includes('job.JobID')"></i>
                                </a>
                            </ng-container>
                        </th>
                        <!-- Task Alias, if not common -->
                        <th #th
                            *ngIf="visible.TaskAlias"
                            data-key="TaskAlias"
                            [class.freeze]="getFreezedClass('TaskAlias')"
                            [style.left.px]="getFreezedOffset('TaskAlias')"
                        >
                            <a (click)="sortColumn('task.TaskAlias', $event)"
                               class="header-sort-link"
                               title="Sort by Task Name">
                                Task Name {{ tableSort.nested ? tableSort.nestedIndex['task.TaskAlias'] : '' }}
                                <i class="fa"
                                   [ngClass]="{
                                        'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['task.TaskAlias'] : tableSort.reverse,
                                        'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['task.TaskAlias'] : !tableSort.reverse
                                        }"
                                   style="font-size: 10px !important;"
                                   *ngIf="tableSort.propertyPath === 'task.TaskAlias' || tableSort.nested && tableSort.properties.includes('task.TaskAlias')"></i>
                            </a>
                        </th>
                        <!-- Animal Marker -->
                        <th #th
                            *ngIf="visible.PhysicalMarker"
                            data-key="PhysicalMarker"
                            [class.freeze]="getFreezedClass('PhysicalMarker')"
                            [style.left.px]="getFreezedOffset('PhysicalMarker')"
                        >
                            <div>
                                <a (click)="sortColumn('animal.PhysicalMarker', $event)"
                                   class="header-sort-link"
                                   title="Sort by Marker">
                                    Marker {{ tableSort.nested ? tableSort.nestedIndex['animal.PhysicalMarker'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                        'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['animal.PhysicalMarker'] : tableSort.reverse,
                                        'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['animal.PhysicalMarker'] : !tableSort.reverse
                                        }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'animal.PhysicalMarker' || tableSort.nested && tableSort.properties.includes('animal.PhysicalMarker')"></i>
                                </a>
                            </div>
                        </th>
                        <!-- Task Animals -->
                        <th #th
                            *ngIf="visible.AnimalName"
                            data-key="AnimalName"
                            [class.freeze]="getFreezedClass('AnimalName')"
                            [style.left.px]="getFreezedOffset('AnimalName')"
                        >
                            <div>
                                <a (click)="sortColumn('animal.AnimalName', $event)"
                                   class="header-sort-link"
                                   title="Sort by Animal">
                                    Animal {{ tableSort.nested ? tableSort.nestedIndex['animal.AnimalName'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                        'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['animal.AnimalName'] : tableSort.reverse,
                                        'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['animal.AnimalName'] : !tableSort.reverse
                                        }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'animal.AnimalName' || tableSort.nested && tableSort.properties.includes('animal.AnimalName')"></i>
                                </a>
                            </div>
                        </th>
                        <!-- Microchip ID -->
                        <th #th
                            *ngIf="visible.MicrochipIdentifier"
                            data-key="MicrochipIdentifier"
                            [class.freeze]="getFreezedClass('MicrochipIdentifier')"
                            [style.left.px]="getFreezedOffset('MicrochipIdentifier')"
                        >
                            <div>
                                <a (click)="sortColumn('animal.Material.MicrochipIdentifier', $event)"
                                   class="header-sort-link"
                                   title="Sort by Microchip ID">
                                    Microchip ID {{ tableSort.nested ? tableSort.nestedIndex['animal.Material.MicrochipIdentifier'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                        'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['animal.Material.MicrochipIdentifier'] : tableSort.reverse,
                                        'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['animal.Material.MicrochipIdentifier'] : !tableSort.reverse
                                        }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'animal.Material.MicrochipIdentifier' || tableSort.nested && tableSort.properties.includes('animal.Material.MicrochipIdentifier')"></i>
                                </a>
                            </div>
                        </th>
                        <!-- Alternate Physical ID -->
                        <th #th
                            *ngIf="visible.AlternatePhysicalID"
                            data-key="AlternatePhysicalID"
                            [class.freeze]="getFreezedClass('AlternatePhysicalID')"
                            [style.left.px]="getFreezedOffset('AlternatePhysicalID')"
                        >
                            <div>
                                <a (click)="sortColumn('animal.AlternatePhysicalID', $event)"
                                   class="header-sort-link"
                                   title="Sort by Alternate Physical ID">
                                    Alternate Physical ID {{ tableSort.nested ? tableSort.nestedIndex['animal.AlternatePhysicalID'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                        'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['animal.AlternatePhysicalID'] : tableSort.reverse,
                                        'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['animal.AlternatePhysicalID'] : !tableSort.reverse
                                        }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'animal.AlternatePhysicalID' || tableSort.nested && tableSort.properties.includes('animal.AlternatePhysicalID')"></i>
                                </a>
                            </div>
                        </th>
                        <!-- Scan -->
                        <th *ngIf="visible.Scan">Scan</th>
                        <!-- Animal Comments -->
                        <th #th
                            *ngIf="visible.AnimalComments"
                            data-key="AnimalComments"
                            [class.freeze]="getFreezedClass('AnimalComments')"
                            [style.left.px]="getFreezedOffset('AnimalComments')"    
                        >Animal Comments</th>
                        <!-- Animal Classification -->
                        <th #th
                            *ngIf="visible.AnimalClassification && isGLP"
                            data-key="AnimalClassification"
                            [class.freeze]="getFreezedClass('AnimalClassification')"
                            [style.left.px]="getFreezedOffset('AnimalClassification')"       
                        >
                            <div>
                                <a (click)="sortColumn('animal.cv_AnimalClassification.AnimalClassification', $event)"
                                   class="header-sort-link"
                                   title="Sort by Animal Classification">
                                    Animal Classification {{ tableSort.nested ? tableSort.nestedIndex['animal.cv_AnimalClassification.AnimalClassification'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                        'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['animal.cv_AnimalClassification.AnimalClassification'] : tableSort.reverse,
                                        'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['animal.cv_AnimalClassification.AnimalClassification'] : !tableSort.reverse
                                        }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'animal.cv_AnimalClassification.AnimalClassification' || tableSort.nested && tableSort.properties.includes('animal.cv_AnimalClassification.AnimalClassification')"></i>
                                </a>
                            </div>
                        </th>
                        <!-- Cohort -->
                        <th #th
                            *ngIf="visible.Cohort"
                            data-key="Cohort"
                            [class.freeze]="getFreezedClass('Cohort')"
                            [style.left.px]="getFreezedOffset('Cohort')"
                        >
                            <div>
                                <a (click)="sortColumn('animal.CohortsSortable', $event)"
                                class="header-sort-link"
                                title="Sort by Animal Cohort">
                                    Animal Cohort {{ tableSort.nested ? tableSort.nestedIndex['animal.CohortsSortable'] : '' }}
                                    <i class="fa"
                                    [ngClass]="{
                                        'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['animal.CohortsSortable'] : tableSort.reverse,
                                        'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['animal.CohortsSortable'] : !tableSort.reverse
                                        }"
                                    style="font-size: 10px !important;"
                                    *ngIf="tableSort.propertyPath === 'animal.CohortsSortable' || tableSort.nested && tableSort.properties.includes('animal.CohortsSortable')"></i>
                                </a>
                            </div>
                        </th>
                        <!--List View Characteristics-->
                        <ng-container *ngFor="let taxonCharacteristic of taxonCharacteristicsInListView">
                            <th *ngIf="visible[taxonCharacteristic.C_TaxonCharacteristic_key]">
                                <a (click)="sortColumn('characteristics.' + taxonCharacteristic.C_TaxonCharacteristic_key + '.CharacteristicValue', $event)"
                                   class="header-sort-link"
                                   [title]="'Sort by ' + taxonCharacteristic.CharacteristicName">
                                    {{taxonCharacteristic.CharacteristicName}}
                                    <i class="fa"
                                       [ngClass]="{
                                        'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['characteristics.' + taxonCharacteristic.C_TaxonCharacteristic_key + '.CharacteristicValue'] : tableSort.reverse,
                                        'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['characteristics.' + taxonCharacteristic.C_TaxonCharacteristic_key + '.CharacteristicValue'] : !tableSort.reverse
                                        }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'characteristics.' + taxonCharacteristic.C_TaxonCharacteristic_key + '.CharacteristicValue' || tableSort.nested && tableSort.properties.includes('characteristics.' + taxonCharacteristic.C_TaxonCharacteristic_key + '.CharacteristicValue')"></i>
                                </a>
                            </th>
                        </ng-container>
                        <!-- Housing ID -->
                        <th *ngIf="visible.HousingID"
                            data-key="HousingID"
                            [class.freeze]="getFreezedClass('HousingID')"
                            [style.left.px]="getFreezedOffset('HousingID')"
                        >
                            <div>
                                <a (click)="sortColumn('currentHousingId', $event)"
                                class="header-sort-link"
                                title="Sort by Housing ID">
                                    Housing ID {{ tableSort.nested ? tableSort.nestedIndex['currentHousingId'] : '' }}
                                    <i class="fa"
                                    [ngClass]="{
                                        'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['currentHousingId'] : tableSort.reverse,
                                        'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['currentHousingId'] : !tableSort.reverse
                                        }"
                                    style="font-size: 10px !important;"
                                    *ngIf="tableSort.propertyPath === 'currentHousingId' || tableSort.nested && tableSort.properties.includes('currentHousingId')"></i>
                                </a>
                            </div>
                        </th>
                        <!-- Task Samples -->
                        <th #th
                            *ngIf="visible.SampleName"
                            data-key="SampleName"
                            [class.freeze]="getFreezedClass('SampleName')"
                            [style.left.px]="getFreezedOffset('SampleName')"
                        >Sample</th>
                        <!-- Time Due : bulk fill (with increment) -->
                        <th *ngIf="visible.DateDue">
                            <ng-container *ngIf="readonly">
                                <a (click)="sortColumn('task.DateDueSortable', $event)"
                                   class="header-sort-link"
                                   title="Sort by Date Due">
                                    Date Due {{ tableSort.nested ? tableSort.nestedIndex['task.DateDueSortable'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['task.DateDueSortable'] : tableSort.reverse,
                                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['task.DateDueSortable'] : !tableSort.reverse
                                            }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'task.DateDueSortable' || tableSort.nested && tableSort.properties.includes('task.DateDueSortable')"></i>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!readonly">
                                <ng-template [ngTemplateOutlet]="bulkDateDueHeader"
                                             [ngTemplateOutletContext]="{title: 'Date Due'}"></ng-template>
                            </ng-container>
                        </th>
                        <th *ngIf="visible.TimeDue">
                            <ng-container *ngIf="readonly">Time Due</ng-container>
                            <ng-container *ngIf="!readonly">
                                <ng-template [ngTemplateOutlet]="bulkDateDueHeader"
                                             [ngTemplateOutletContext]="{title: 'Time Due'}"></ng-template>
                            </ng-container>
                        </th>

                        <!-- Inputs: Loop over inputs -->
                        <ng-container *ngIf="visible.Inputs">
                            <ng-container *ngFor="let inputCol of inputColumns; index as i;trackBy: trackColumns">
                                <th *ngIf="inputCol.visible"
                                    class="input">
                                    <ng-container *ngIf="readonly">
                                        <a (click)="sortColumn('inputs[' + inputCol.key + '].InputValue', $event)"
                                           class="header-sort-link"
                                           title="Sort by {{inputCol.label}}">
                                            {{inputCol.label}} {{ tableSort.nested ? tableSort.nestedIndex['inputs[' + inputCol.key + '].InputValue'] : '' }}
                                            <i class="fa"
                                               [ngClass]="{
                                                    'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['inputs[' + inputCol.key + '].InputValue'] : tableSort.reverse,
                                                    'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['inputs[' + inputCol.key + '].InputValue'] : !tableSort.reverse
                                                    }"
                                               style="font-size: 10px !important;"
                                               *ngIf="tableSort.propertyPath === 'inputs[' + inputCol.key + '].InputValue' || tableSort.nested && tableSort.properties.includes('inputs[' + inputCol.key + '].InputValue')"></i>
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="!readonly">
                                        {{inputCol.label}}
                                    </ng-container>
                                </th>
                            </ng-container>
                        </ng-container>

                        <!-- Output Samples : Loop over outputs -->
                        <ng-container *ngIf="visible.Outputs">
                            <th *ngIf="visible.OutputSample"
                                class="output">
                                <ng-container *ngIf="readonly">
                                    <a (click)="sortColumn('outputSample.SampleName', $event)"
                                       class="header-sort-link"
                                       title="Sort by Output Sample">
                                        Output Sample {{ tableSort.nested ? tableSort.nestedIndex['outputSample.SampleName'] : '' }}
                                        <i class="fa"
                                           [ngClass]="{
                                                'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['outputSample.SampleName'] : tableSort.reverse,
                                                'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['outputSample.SampleName'] : !tableSort.reverse
                                                }"
                                           style="font-size: 10px !important;"
                                           *ngIf="tableSort.propertyPath === 'outputSample.SampleName' || tableSort.nested && tableSort.properties.includes('outputSample.SampleName')"></i>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="!readonly">
                                    Output Sample
                                </ng-container>
                            </th>
                            <!-- Harvest Date -->
                            <th *ngIf="visible.HarvestDate">
                                <ng-container *ngIf="readonly">Harvest Date</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Harvest Date'"
                                                      (updateClicked)="bulkHarvestDateChanged()">
                                        <div modal-content>
                                            <climb-ngb-date name="Harvest Date"
                                                            [(ngModel)]="bulk.harvestDate"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [allowNow]="true"
                                                            ngDefaultControl>
                                            </climb-ngb-date>
                                            <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Sample Type -->
                            <th *ngIf="visible.SampleType">
                                <ng-container *ngIf="readonly">Sample Type</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Type'"
                                                      (updateClicked)="bulkSampleTypeChanged()">
                                        <div modal-content>
                                            <active-vocab-select [(model)]="bulk.sampleTypeKey"
                                                                 [vocabChoices]="sampleTypes"
                                                                 [keyFormatter]="sampleTypeKeyFormatter"
                                                                 [optionFormatter]="sampleTypeFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Sample Status -->
                            <th *ngIf="visible.SampleStatus">
                                <ng-container *ngIf="readonly">Sample Status</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Status'"
                                                      (updateClicked)="bulkSampleStatusChanged()">
                                        <div modal-content>
                                            <active-vocab-select [(model)]="bulk.sampleStatusKey"
                                                                 [vocabChoices]="sampleStatuses"
                                                                 [keyFormatter]="sampleStatusKeyFormatter"
                                                                 [optionFormatter]="sampleStatusFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Sample Preservation Method -->
                            <th *ngIf="visible.PreservationMethod">
                                <ng-container *ngIf="readonly">Sample Preservation Method</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Preservation Method'"
                                                      (updateClicked)="bulkSamplePreservationMethodChanged()">
                                        <div modal-content>
                                            <active-vocab-select [(model)]="bulk.samplePreservationMethodKey"
                                                                 [vocabChoices]="preservationMethods"
                                                                 [keyFormatter]="preservationMethodKeyFormatter"
                                                                 [optionFormatter]="preservationMethodFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Sample Container Type -->
                            <th *ngIf="visible.ContainerType">
                                <ng-container *ngIf="readonly">Sample Container Type</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Container Type'"
                                                      (updateClicked)="bulkSampleContainerTypeChanged()">
                                        <div modal-content>
                                            <active-vocab-select [(model)]="bulk.sampleContainerTypeKey"
                                                                 [vocabChoices]="containerTypes"
                                                                 [keyFormatter]="containerTypeKeyFormatter"
                                                                 [optionFormatter]="containerTypeFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Sample Volume -->
                            <th *ngIf="visible.SampleVolume">
                                <ng-container *ngIf="readonly">Sample Measurement</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Measurement'"
                                                      (updateClicked)="bulkSampleMeasurementChanged()">
                                        <div modal-content>
                                            <input type="number"
                                                   min="0"
                                                   step="any"
                                                   name="volume"
                                                   [(ngModel)]="bulk.sampleVolume"
                                                   class="form-control input-medium" />
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Volume Unit -->
                            <th *ngIf="visible.VolumeUnit">
                                <ng-container *ngIf="readonly">Sample Unit</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Unit'"
                                                      (updateClicked)="bulkSampleUnitChanged()">
                                        <div modal-content>
                                            <active-vocab-select [(model)]="bulk.sampleUnitKey"
                                                                 [vocabChoices]="volumeUnitTypes"
                                                                 [keyFormatter]="volumeUnitKeyFormatter"
                                                                 [optionFormatter]="volumeUnitFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Sample Subtype -->
                            <th *ngIf="visible.SampleSubtype">
                                <ng-container *ngIf="readonly">Sample Subtype</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Subtype'"
                                                      (updateClicked)="bulkSampleSubtypeChanged()">
                                        <div modal-content>
                                            <active-vocab-select [(model)]="bulk.sampleSubtypeKey"
                                                                 [vocabChoices]="sampleSubtypes"
                                                                 [keyFormatter]="sampleSubtypeKeyFormatter"
                                                                 [optionFormatter]="sampleSubtypeFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Sample Processing Method -->
                            <th *ngIf="visible.SampleProcessingMethod">
                                <ng-container *ngIf="readonly">Sample Subtype</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Processing Method'"
                                                      (updateClicked)="bulkSampleProcessingMethodChanged()">
                                        <div modal-content>
                                            <active-vocab-select [(model)]="bulk.sampleProcessingMethodKey"
                                                                 [vocabChoices]="sampleProcessingMethods"
                                                                 [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                                                 [optionFormatter]="sampleProcessingMethodFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Sample Send To -->
                            <th *ngIf="visible.SendTo">
                                <ng-container *ngIf="readonly">Sample Send To</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Send To'"
                                                      (updateClicked)="bulkSampleSendToChanged()">
                                        <div modal-content>
                                            <input type="text"
                                                   maxlength="150"
                                                   name="sendTo"
                                                   [(ngModel)]="bulk.sendTo"
                                                   class="form-control input-medium" />
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Sample Analysis Method -->
                            <th *ngIf="visible.SampleAnalysisMethod">
                                <ng-container *ngIf="readonly">Sample Analysis Method</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Analysis Method'"
                                                      (updateClicked)="bulkSampleAnalysisMethodChanged()">
                                        <div modal-content>
                                            <active-vocab-select [(model)]="bulk.sampleAnalysisMethodKey"
                                                                 [vocabChoices]="sampleAnalysisMethods"
                                                                 [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                                                 [optionFormatter]="sampleAnalysisMethodFormatter"
                                                                 [nullable]="true"></active-vocab-select>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <!-- Sample Lot Number -->
                            <th *ngIf="visible.SampleLotNumber">
                                <ng-container *ngIf="readonly">Sample Lot Number</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Sample Lot Number'"
                                                      (updateClicked)="bulkSampleLotNumberChanged()">
                                        <div modal-content>
                                            <input type="text"
                                                   maxlength="150"
                                                   name="lotNumber"
                                                   [(ngModel)]="bulk.lotNumber"
                                                   class="form-control input-medium" />
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <td *ngIf="visible.Inherited"
                                class="output"
                                title="Updating all will not override values already displayed">
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header (updateClicked)="bulkFetchInherited()">
                                        <div modal-content>
                                            Bulk fetch inherited values
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </td>
                            <!--Output Columns-->
                            <ng-container *ngFor="let outputCol of outputColumns; index as i;trackBy: trackColumns">
                                <th *ngIf="outputCol.visible && output[outputCol.label]"
                                    class="output">
                                    <ng-container *ngIf="!outputCol.showBulk || readonly">
                                        <a (click)="sortColumn('taskOutputSet.TaskOutput[' + i + '].OutputValue', $event)"
                                           class="header-sort-link"
                                           title="Sort by {{outputCol.label}}">
                                            {{outputCol.label}} {{ tableSort.nested ? tableSort.nestedIndex['taskOutputSet.TaskOutput[' + i + '].OutputValue'] : '' }}
                                            <i class="fa"
                                               [ngClass]="{
                                                    'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['taskOutputSet.TaskOutput[' + i + '].OutputValue'] : tableSort.reverse,
                                                    'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['taskOutputSet.TaskOutput[' + i + '].OutputValue'] : !tableSort.reverse
                                                    }"
                                               style="font-size: 10px !important;"
                                               *ngIf="tableSort.propertyPath === 'taskOutputSet.TaskOutput[' + i + '].OutputValue' || tableSort.nested && tableSort.properties.includes('taskOutputSet.TaskOutput[' + i + '].OutputValue')"></i>
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="outputCol.showBulk && !readonly">
                                        <bulk-edit-header [headerText]="outputCol.label"
                                                          (updateClicked)="bulkOutputChanged(outputCol)">
                                            <div modal-content>
                                                <data-type-input [ioObject]="outputCol.output"
                                                                 [(value)]="outputCol.value">
                                                </data-type-input>
                                            </div>
                                        </bulk-edit-header>
                                    </ng-container>
                                </th>
                            </ng-container>
                            <th *ngIf="visible.Collected"
                                class="output"
                                title="Mark Output as Collected">
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header (updateClicked)="bulkCollectedChanged()">
                                        <div modal-content>
                                            Mark as Collected
                                            <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <th *ngIf="visible.CollectedBy"
                                class="output">
                                <ng-container *ngIf="isGLP">
                                    <a (click)="sortColumn('taskOutputSet.Resource.UserName', $event)"
                                       class="header-sort-link"
                                       title="Sort by Collected By">
                                        Collected By {{ tableSort.nested ? tableSort.nestedIndex['taskOutputSet.Resource.Username'] : '' }}
                                        <i class="fa"
                                           [ngClass]="{
                                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['taskOutputSet.Resource.UserName'] : tableSort.reverse,
                                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['taskOutputSet.Resource.UserName'] : !tableSort.reverse
                                            }"
                                           style="font-size: 10px !important;"
                                           *ngIf="tableSort.propertyPath === 'taskOutputSet.Resource.UserName' || tableSort.nested && tableSort.properties.includes('taskOutputSet.Resource.UserName')"></i>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="!readonly && !isGLP">
                                    <bulk-edit-header [headerText]="'Collected By'"
                                                      (updateClicked)="bulkCollectedByChanged()">
                                        <div modal-content>
                                            <active-vocab-select [(model)]="bulk.collectedByKey"
                                                                 [vocabChoices]="resources"
                                                                 [keyFormatter]="resourceKeyFormatter"
                                                                 [optionFormatter]="resourceNameFormatter"
                                                                 [nullable]="true">
                                            </active-vocab-select>
                                            <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <th *ngIf="visible.CollectedDate" class="output">
                                <ng-container *ngIf="readonly">Collected Date</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Collected Date'"
                                                      (updateClicked)="bulkCollectedTimeChanged()">
                                        <div modal-content>
                                            <climb-ngb-date name="Collected Date"
                                                            [(ngModel)]="bulk.collectedTime"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [allowNow]="true"
                                                            [allowTime]="true"
                                                            [alwaysShowTime]="true"
                                                            ngDefaultControl></climb-ngb-date>
                                            <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                            <th #th *ngIf="visible.CollectedTime" class="output">
                                <ng-container *ngIf="readonly">Collected Time</ng-container>
                                <ng-container *ngIf="!readonly">
                                    <bulk-edit-header [headerText]="'Collected Time'"
                                                      (updateClicked)="bulkCollectedTimeChanged()">
                                        <div modal-content>
                                            <climb-ngb-date name="Collected Time"
                                                            [(ngModel)]="bulk.collectedTime"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [allowNow]="true"
                                                            [allowTime]="true"
                                                            [alwaysShowTime]="true"
                                                            ngDefaultControl></climb-ngb-date>
                                            <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                        </div>
                                    </bulk-edit-header>
                                </ng-container>
                            </th>
                        </ng-container>
                        <th *ngIf="visible.Completed" title="Mark Task as Completed">
                            <ng-container *ngIf="!readonly">
                                <bulk-edit-header (updateClicked)="bulkCompletedChanged()"
                                                  [disabled]="loading">
                                    <div modal-content class="text-body">
                                        Mark as Completed
                                        <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                    </div>
                                </bulk-edit-header>
                            </ng-container>
                        </th>
                        <th *ngIf="visible.CompletedBy">
                            <ng-container *ngIf="readonly && !isGLP">
                                <a (click)="sortColumn('task.CompletedByResource.ResourceName', $event)"
                                   class="header-sort-link"
                                   title="Sort by Completed By">
                                    Completed By {{ tableSort.nested ? tableSort.nestedIndex['task.CompletedByResource.ResourceName'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['task.CompletedByResource.ResourceName'] : tableSort.reverse,
                                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['task.CompletedByResource.ResourceName'] : !tableSort.reverse
                                            }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'task.CompletedByResource.ResourceName' || tableSort.nested && tableSort.properties.includes('task.CompletedByResource.ResourceName')"></i>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="isGLP">
                                <a (click)="sortColumn('task.CompletedByResource.UserName', $event)"
                                   class="header-sort-link"
                                   title="Sort by Completed By">
                                    Completed By {{ tableSort.nested ? tableSort.nestedIndex['task.CompletedByResource.UserName'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['task.CompletedByResource.UserName'] : tableSort.reverse,
                                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['task.CompletedByResource.UserName'] : !tableSort.reverse
                                            }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'task.CompletedByResource.UserName' || tableSort.nested && tableSort.properties.includes('task.CompletedByResource.UserName')"></i>
                                </a>
                            </ng-container>
                            <bulk-edit-header *ngIf="!readonly && !isGLP" [headerText]="'Completed By'"
                                              (updateClicked)="bulkCompletedByChanged()">
                                <div modal-content class="text-body">
                                    <active-vocab-select [(model)]="bulk.completedByKey"
                                                         [vocabChoices]="resources"
                                                         [keyFormatter]="resourceKeyFormatter"
                                                         [optionFormatter]="resourceNameFormatter"
                                                         [nullable]="true">
                                    </active-vocab-select>
                                    <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                </div>
                            </bulk-edit-header>
                        </th>
                        <th *ngIf="visible.CompletedDate">
                            <ng-container *ngIf="readonly">
                                <a (click)="sortColumn('task.DateCompleteSortable', $event)"
                                   class="header-sort-link"
                                   title="Sort by Completed Date">
                                    Completed Date {{ tableSort.nested ? tableSort.nestedIndex['task.DateCompleteSortable'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['task.DateCompleteSortable'] : tableSort.reverse,
                                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['task.DateCompleteSortable'] : !tableSort.reverse
                                            }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'task.DateCompleteSortable' || tableSort.nested && tableSort.properties.includes('task.DateCompleteSortable')"></i>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!readonly">
                                <bulk-edit-header [headerText]="'Completed Date'"
                                                  (updateClicked)="bulkCompletedTimeChanged()">
                                    <div modal-content class="text-body">
                                        <climb-ngb-date name="Completed Date"
                                                        [(ngModel)]="bulk.completedTime"
                                                        [allowNow]="true"
                                                        [allowTime]="true"
                                                        [alwaysShowTime]="true"
                                                        ngDefaultControl></climb-ngb-date>
                                        <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                    </div>
                                </bulk-edit-header>
                            </ng-container>
                        </th>
                        <th *ngIf="visible.CompletedTime">
                            <ng-container *ngIf="readonly">Completed Time</ng-container>
                            <ng-container *ngIf="!readonly">
                                <bulk-edit-header [headerText]="'Completed Time'"
                                                  (updateClicked)="bulkCompletedTimeChanged()">
                                    <div modal-content class="text-body">
                                        <climb-ngb-date name="Completed Time"
                                                        [(ngModel)]="bulk.completedTime"
                                                        [allowNow]="true"
                                                        [allowTime]="true"
                                                        [alwaysShowTime]="true"
                                                        ngDefaultControl></climb-ngb-date>
                                        <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                    </div>
                                </bulk-edit-header>
                            </ng-container>
                        </th>
                        <th *ngIf="visible.Reviewed" title="Mark Task as Reviewed">
                            <ng-container *ngIf="!readonly">
                                <bulk-edit-header (updateClicked)="bulkReviewedChanged()"
                                                  [disabled]="loading">
                                    <div modal-content>
                                        Mark as Reviewed
                                        <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                    </div>
                                </bulk-edit-header>
                            </ng-container>
                        </th>
                        <th *ngIf="visible.ReviewedBy">
                            <ng-container *ngIf="readonly && !isGLP">
                                <a (click)="sortColumn('task.ReviewedByResource.ResourceName', $event)"
                                   class="header-sort-link"
                                   title="Sort by Reviewed By">
                                    Reviewed By {{ tableSort.nested ? tableSort.nestedIndex['task.ReviewedByResource.ResourceName'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['task.ReviewedByResource.ResourceName'] : tableSort.reverse,
                                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['task.ReviewedByResource.ResourceName'] : !tableSort.reverse
                                            }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'task.ReviewedByResource.ResourceName' || tableSort.nested && tableSort.properties.includes('task.ReviewedByResource.ResourceName')"></i>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="isGLP">
                                <a (click)="sortColumn('task.ReviewedByResource.UserName', $event)"
                                   class="header-sort-link"
                                   title="Sort by Reviewed By">
                                    Reviewed By {{ tableSort.nested ? tableSort.nestedIndex['task.ReviewedByResource.UserName'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['task.ReviewedByResource.UserName'] : tableSort.reverse,
                                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['task.ReviewedByResource.UserName'] : !tableSort.reverse
                                            }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'task.ReviewedByResource.UserName' || tableSort.nested && tableSort.properties.includes('task.ReviewedByResource.UserName')"></i>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!readonly && !isGLP">
                                <bulk-edit-header [headerText]="'Reviewed By'"
                                                  (updateClicked)="bulkReviewedByChanged()">
                                    <div modal-content>
                                        <active-vocab-select [(model)]="bulk.reviewedByKey"
                                                             [vocabChoices]="resources"
                                                             [keyFormatter]="resourceKeyFormatter"
                                                             [optionFormatter]="resourceNameFormatter"
                                                             [nullable]="true">
                                        </active-vocab-select>
                                        <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                    </div>
                                </bulk-edit-header>
                            </ng-container>
                        </th>
                        <th *ngIf="visible.ReviewedDate">
                            <ng-container *ngIf="readonly">
                                <a (click)="sortColumn('task.DateReviewedSortable', $event)"
                                   class="header-sort-link"
                                   title="Sort by Reviewed Date">
                                    Reviewed Date {{ tableSort.nested ? tableSort.nestedIndex['task.DateReviewedSortable'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['task.DateReviewedSortable'] : tableSort.reverse,
                                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['task.DateReviewedSortable'] : !tableSort.reverse
                                            }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'task.DateReviewedSortable' || tableSort.nested && tableSort.properties.includes('task.DateReviewedSortable')"></i>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!readonly">
                                <bulk-edit-header [headerText]="'Reviewed Date'"
                                                  (updateClicked)="bulkReviewedTimeChanged()">
                                    <div modal-content>
                                        <climb-ngb-date name="Reviewed Date"
                                                        [(ngModel)]="bulk.reviewedTime"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [allowNow]="true"
                                                        [allowTime]="true"
                                                        [alwaysShowTime]="true"
                                                        ngDefaultControl></climb-ngb-date>
                                        <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                    </div>
                                </bulk-edit-header>
                            </ng-container>
                        </th>
                        <th *ngIf="visible.ReviewedTime">
                            <ng-container *ngIf="readonly">Reviewed Time</ng-container>
                            <ng-container *ngIf="!readonly">
                                <bulk-edit-header [headerText]="'Reviewed Time'"
                                                  (updateClicked)="bulkReviewedTimeChanged()">
                                    <div modal-content>
                                        <climb-ngb-date name="Reviewed Time"
                                                        [(ngModel)]="bulk.reviewedTime"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [allowNow]="true"
                                                        [allowTime]="true"
                                                        [alwaysShowTime]="true"
                                                        ngDefaultControl></climb-ngb-date>
                                        <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                    </div>
                                </bulk-edit-header>
                            </ng-container>
                        </th>
                        <!-- Task Status bulk fill -->
                        <th *ngIf="visible.TaskStatus">
                            <ng-container *ngIf="readonly">
                                <a (click)="sortColumn('task.cv_TaskStatus.TaskStatus', $event)"
                                   class="header-sort-link"
                                   title="Sort by Task Status">
                                    Task Status {{ tableSort.nested ? tableSort.nestedIndex['task.cv_TaskStatus.TaskStatus'] : '' }}
                                    <i class="fa"
                                       [ngClass]="{
                                            'fa-arrow-down': tableSort.nested ? tableSort.nestedReverse['task.cv_TaskStatus.TaskStatus'] : tableSort.reverse,
                                            'fa-arrow-up': tableSort.nested ? !tableSort.nestedReverse['task.cv_TaskStatus.TaskStatus'] : !tableSort.reverse
                                            }"
                                       style="font-size: 10px !important;"
                                       *ngIf="tableSort.propertyPath === 'task.cv_TaskStatus.TaskStatus' || tableSort.nested && tableSort.properties.includes('job.Study.StudyName')"></i>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="!readonly">
                                <bulk-edit-header [headerText]="'Task Status'"
                                                  (updateClicked)="bulkTaskStatusChanged()"
                                                  [disabled]="loading">
                                    <div modal-content class="text-body">
                                        <active-vocab-select [(model)]="bulk.taskStatusKey"
                                                             [vocabChoices]="taskStatuses"
                                                             [keyFormatter]="taskStatusKeyFormatter"
                                                             [optionFormatter]="taskStatusFormatter"
                                                             [nullable]="true"></active-vocab-select>

                                        <ng-template [ngTemplateOutlet]="bulkForce"></ng-template>
                                    </div>
                                </bulk-edit-header>
                            </ng-container>
                        </th>
                        <!-- Animal Status bulk fill -->
                        <th *ngIf="visible.AnimalStatus">
                            <ng-container *ngIf="readonly">Animal Status</ng-container>
                            <ng-container *ngIf="!readonly">
                                <bulk-edit-header [headerText]="'Animal Status'"
                                                  (updateClicked)="bulkAnimalStatusChanged()">
                                    <div modal-content>
                                        <active-vocab-select [(model)]="bulk.animalStatusKey"
                                                             [vocabChoices]="animalStatuses"
                                                             [keyFormatter]="animalStatusKeyFormatter"
                                                             [optionFormatter]="animalStatusFormatter"
                                                             [nullable]="true"></active-vocab-select>
                                    </div>
                                </bulk-edit-header>
                            </ng-container>
                        </th>
                        <!-- Animal Exit Reason bulk fill -->
                        <th *ngIf="visible.ExitReason">
                            <ng-container *ngIf="readonly">Exit Reason</ng-container>
                            <ng-container *ngIf="!readonly">
                                <bulk-edit-header [headerText]="'Exit Reason'"
                                                  (updateClicked)="bulkExitReasonChanged()">
                                    <div modal-content>
                                        <active-vocab-select [(model)]="bulk.exitReasonKey"
                                                             [vocabChoices]="exitReasons"
                                                             [keyFormatter]="exitReasonKeyFormatter"
                                                             [optionFormatter]="exitReasonFormatter"
                                                             [nullable]="true"></active-vocab-select>
                                    </div>
                                </bulk-edit-header>
                            </ng-container>
                        </th>
                        <!-- Clinical -->
                        <th *ngIf="visible.Clinical && !clinicalSyncEnabled">Clinical</th>
                        <!-- Notes -->
                        <th *ngIf="visible.Notes">Notes</th>
                        <!-- Files -->
                        <th *ngIf="visible.Files">Files</th>
                        <!-- Task Lock -->
                        <th *ngIf="visible.Lock">
                            <ng-container *ngIf="studyAdministratorStudies && studyAdministratorStudies.length > 0">
                                <div *ngIf="readonly">Lock</div>
                                <div *ngIf="!readonly" class="text-nowrap dropdown">
                                    <a class="dropdown-toggle"
                                       data-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false"
                                       title="Lock/unlock all">
                                        Lock
                                    </a>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" (click)="lockAllTaskInstances()">
                                            <i class="fa fa-lock" aria-hidden="true"></i>
                                            Lock All
                                        </a>
                                        <a class="dropdown-item" (click)="unlockAllTaskInstances()">
                                            <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                                            Unlock All
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                        </th>
                        <ng-container *ngFor="let taxonCharacteristic of taxonCharacteristicsInListView">
                            <th *ngIf="visible[taxonCharacteristic.CharacteristicName]">
                                {{taxonCharacteristic.CharacteristicName}}
                            </th>
                        </ng-container>
                    </tr>
                </thead>

                <tbody>
                    <ng-container *ngFor="let row of rows
                                | paginate: { id: paginationRandomId, itemsPerPage: 30, currentPage: taskPage };
                                let rowIndex = index;
                                trackBy: trackRows;">
                        <tr class="workflow-bulk-data-entry-row"
                            [ngClass]="{
                            'current': currentIndex == row.index,
                            'completed': row.task?.cv_TaskStatus?.IsEndState,
                            'end-state-animal': row.animal?.cv_AnimalStatus?.IsExitStatus
                            }"
                            (click)="setCurrentRow(row.index)"
                            id="row-{{row.index}}"
                            [attr.data-index]="row.index">
                            <!-- Job ID, if not common -->
                            <td *ngIf="visible.JobID"
                                [style.left.px]="getFreezedOffset('JobID')"
                                [ngClass]="{'task-extra': !row.taskFirst, 'freeze': getFreezedClass('JobID') }">
                                <ng-container *ngIf="row.taskFirst">
                                    {{row.job?.JobID}}
                                </ng-container>
                            </td>
                            <!-- Task Alias, if not common -->
                            <td *ngIf="visible.TaskAlias"
                                [style.left.px]="getFreezedOffset('TaskAlias')"
                                [ngClass]="{'task-extra': !row.taskFirst, 'freeze': getFreezedClass('TaskAlias')}">
                                <ng-container *ngIf="row.taskFirst">
                                    {{row.task?.TaskAlias}}
                                </ng-container>
                            </td>
                            <!-- Animal Marker -->
                            <td *ngIf="visible.PhysicalMarker"
                                [style.left.px]="getFreezedOffset('PhysicalMarker')"
                                [ngClass]="{'animal-extra': !row.animalFirst, 'freeze': getFreezedClass('PhysicalMarker')}"
                            >
                                <ng-container *ngIf="row.animalFirst">
                                    <animal-marker *ngIf="row.animal"
                                                   [marker]="row.animal.PhysicalMarker"
                                                   [markerType]="row.animal.cv_PhysicalMarkerType"></animal-marker>
                                </ng-container>
                            </td>
                            <!-- Task Animal -->
                            <td *ngIf="visible.AnimalName"
                                [style.left.px]="getFreezedOffset('AnimalName')"
                                [ngClass]="{'animal-extra': !row.animalFirst, 'freeze': getFreezedClass('AnimalName') }"
                                class="nowrap"
                            >
                                <ng-container *ngIf="row.animalFirst">
                                    {{row.animal?.AnimalName}}
                                </ng-container>
                            </td>
                            <!-- Microchip ID -->
                            <td *ngIf="visible.MicrochipIdentifier"
                                [style.left.px]="getFreezedOffset('MicrochipIdentifier')"
                                [ngClass]="{'animal-extra': !row.animalFirst, 'freeze': getFreezedClass('MicrochipIdentifier') }"
                                class="nowrap">
                                <ng-container *ngIf="row.animalFirst && row.animal">
                                    {{row.animal.Material?.MicrochipIdentifier}}
                                </ng-container>
                            </td>
                            <!-- Alternate Physical ID -->
                            <td *ngIf="visible.AlternatePhysicalID && isGLP"
                                [style.left.px]="getFreezedOffset('AlternatePhysicalID')"
                                [ngClass]="{'animal-extra': !row.animalFirst, 'freeze': getFreezedClass('AlternatePhysicalID') }"
                                class="nowrap">
                                <ng-container *ngIf="row.animalFirst && row.animal">
                                    {{row.animal.AlternatePhysicalID}}
                                </ng-container>
                            </td>
                            <!-- Scan -->
                            <td *ngIf="visible.Scan"
                                [ngClass]="row.classes.animal"
                                class="nowrap">
                                <ng-container *ngIf="row.animalFirst && row.animal">
                                    <input type="text"
                                           name="scan-{{row.index}}"
                                           [disabled]="readonly"
                                           [ngModelOptions]="{updateOn: 'blur'}"
                                           [(ngModel)]="row.scanMaterial.ScanValue"
                                           (ngModelChange)="scanValueChanged(row)"
                                           class="form-control scan-value input-small" />
                                    <i *ngIf="row.scanMaterial.ScanValue === null" class="fa fa-fw"></i>
                                    <ng-container *ngIf="row.scanMaterial.ScanValue !== null">
                                        <i *ngIf="row.scanMaterial.IsScanValid" class="fa fa-check text-success"></i>
                                        <i *ngIf="!row.scanMaterial.IsScanValid" class="fa fa-exclamation-triangle text-danger"></i>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <!-- Animal Comments -->
                            <td *ngIf="visible.AnimalComments"
                                [style.left.px]="getFreezedOffset('AnimalComments')"
                                [ngClass]="{'animal-extra': !row.animalFirst, 'freeze': getFreezedClass('AnimalComments') }"
                                class="nowrap text-center">
                                <ng-container *ngIf="row.animalFirst && row.animal">
                                    <ng-container *ngFor="let animalComment of row.animal.AnimalComment">
                                        <div class="animal-comments" [ngClass]="animalComment | animalCommentStatusStyle" *ngIf="!animalComment.cv_AnimalCommentStatus?.IsEndState && (animalComment.cv_AnimalCommentStatus?.IsPermanent || animalComment.cv_AnimalCommentStatus?.IsActive)">
                                            <span>{{animalComment.cv_AnimalCommentStatus.AnimalCommentStatus}}</span>: {{animalComment.Comment}}
                                        </div>
                                    </ng-container>
                                    <a *ngIf="!readonly" class="add-item-link detail-grid-button" (click)="openAnimalCommentsModal(row)">
                                        <i class="fa fa-plus-circle" title="Add Animal Comments"></i>
                                    </a>
                                </ng-container>
                            </td>
                            <!-- Animal Classification -->
                            <td *ngIf="visible.AnimalClassification && isGLP"
                                [style.left.px]="getFreezedOffset('AnimalClassification')"
                                [ngClass]="{'animal-extra': !row.animalFirst, 'freeze': getFreezedClass('AnimalClassification') }"
                                class="nowrap">
                                <ng-container *ngIf="row.animal">
                                    {{row.animal.cv_AnimalClassification?.AnimalClassification}}
                                </ng-container>
                            </td>
                            <!-- Cohort -->
                            <td *ngIf="visible.Cohort"
                                [style.left.px]="getFreezedOffset('Cohort')"
                                [ngClass]="{'animal-extra': !row.animalFirst, 'freeze': getFreezedClass('Cohort') }"
                                class="nowrap">

                                <ng-container *ngFor="let cm of getAnimalCohorts(row) | orderBy:'Cohort.CohortName'; let last = last">
                                    <div *ngIf="cm as cohort"
                                         class="cohort-badge"
                                         [ngStyle]="{ backgroundColor: cohort.BackgroundColor, color: cohort.ForegroundColor }">
                                        {{cohort.CohortName}}
                                    </div>
                                </ng-container>


                            </td>
                            <!--List View Characteristics-->
                            <ng-container *ngFor="let taxonCharacteristic of taxonCharacteristicsInListView">
                                <td *ngIf="visible[taxonCharacteristic.C_TaxonCharacteristic_key]" class="characteristic-column">
                                    <ng-container *ngIf="row.characteristics[taxonCharacteristic.C_TaxonCharacteristic_key]">
                                        {{row.characteristics[taxonCharacteristic.C_TaxonCharacteristic_key].CharacteristicValue}}
                                    </ng-container>
                                    <ng-container *ngIf="!row.characteristics[taxonCharacteristic.C_TaxonCharacteristic_key]">&nbsp;</ng-container>
                                </td>
                            </ng-container>
                            <!-- Housing ID -->
                            <td *ngIf="visible.HousingID"
                                [style.left.px]="getFreezedOffset('HousingID')"
                                [ngClass]="{'animal-extra': !row.animalFirst, 'freeze': getFreezedClass('HousingID') }"
                                class="nowrap">
                                <ng-container *ngIf="row.animalFirst && row.animal">
                                    {{row.animal.currentHousingId}}
                                </ng-container>
                                <ng-container *ngIf="row.taskMaterialPool">
                                    {{row.taskMaterialPool?.MaterialPoolID}}
                                </ng-container>
                            </td>
                            <!-- Task Sample -->
                            <td *ngIf="visible.SampleName"
                                [style.left.px]="getFreezedOffset('SampleName')"
                                [ngClass]="{'sample-extra': !row.sampleFirst, 'freeze': getFreezedClass('SampleName') }"
                                class="nowrap">
                                {{row.sample?.SampleName}}
                            </td>
                            <!-- Time Due: edit -->
                            <td *ngIf="visible.DateDue"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                    <climb-ngb-date #dateControl="ngModel"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    [ngModel]="row.task.DateDue"
                                                    (ngModelChange)="dateDueChanged(row.task, $event)"
                                                    [allowTime]="readonly ? true : false"
                                                    [disabled]="readonly || row.task.IsWorkflowLocked"
                                                    ngDefaultControl></climb-ngb-date>
                                </ng-container>
                            </td>
                            <td *ngIf="visible.TimeDue"
                                [ngClass]="row.classes.task"
                                class="nowrap">
                                <ng-container *ngIf="row.taskFirst">
                                    <div class="time-due-input-wrapper">
                                        <climb-ngb-date #dateControl="ngModel"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [ngModel]="row.task.DateDue"
                                                        (ngModelChange)="dueTimeChanged(row.task, $event)"
                                                        [allowDate]="false"
                                                        [allowTime]="true"
                                                        [alwaysShowTime]="true"
                                                        [disabled]="readonly || row.task.IsWorkflowLocked"
                                                        ngDefaultControl></climb-ngb-date>
                                        <i *ngIf="!row.task?.cv_TaskStatus?.IsEndState && (row.task?.DateDue < currentTime)"
                                           class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                    </div>
                                </ng-container>
                            </td>

                            <!-- Inputs: Loop over inputs -->
                            <ng-container *ngIf="visible.Inputs">
                                <ng-container *ngFor="let inputCol of inputColumns;trackBy: trackColumns">
                                    <td *ngIf="inputCol.visible"
                                        [ngClass]="row.classes.task">
                                        <ng-container *ngIf="row.taskFirst && row.inputs[inputCol.key] && row.inputs[inputCol.key].Input?.IsActive">
                                            <span *ngIf="row.inputs[inputCol.key].Input?.cv_DataType?.DataType === 'Date'">
                                                {{row.inputs[inputCol.key].InputValue | formatShortDate}}
                                            </span>
                                            <span *ngIf="row.inputs[inputCol.key].Input?.cv_DataType?.DataType === 'Date' && !row.inputs[inputCol.key].InputValue">
                                                {{'[No value specified]'}}
                                            </span>
                                            <span *ngIf="row.inputs[inputCol.key].Input?.cv_DataType?.DataType !== 'Date'">
                                                {{row.inputs[inputCol.key].InputValue || '[No value specified]'}}
                                            </span>
                                            <small *ngIf="row.inputs[inputCol.key].RequiresValidation && !row.inputs[inputCol.key].InputValue"
                                                   class="form-text text-muted">
                                                <i class="fa fa-exclamation-triangle text-danger"></i>
                                                Input cannot be validated
                                            </small>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <!-- Outputs and Collected -->
                            <ng-container *ngIf="visible.Outputs">
                                <td *ngIf="visible.OutputSample"
                                    class="nowrap">
                                    <span *ngIf="row.animal || row.sample">
                                        {{row.outputSample?.SampleName}}
                                    </span>
                                </td>
                                <!-- Harvest Date -->
                                <td *ngIf="visible.HarvestDate"
                                    [ngClass]="row.classes.animal">
                                    <ng-container *ngIf="row.outputSample">
                                        <climb-ngb-date #dateControl="ngModel"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [(ngModel)]="row.outputSample.DateHarvest"
                                                        [disabled]="readonly || row.task.IsWorkflowLocked"
                                                        ngDefaultControl></climb-ngb-date>
                                    </ng-container>
                                    <ng-container *ngIf="!row.outputSample&&row.sample">
                                        <climb-ngb-date #dateControl="ngModel"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [(ngModel)]="row.sample.DateHarvest"
                                                        [disabled]="readonly || row.task.IsWorkflowLocked"
                                                        ngDefaultControl></climb-ngb-date>
                                    </ng-container>
                                </td>

                                <!-- Sample Type-->
                                <td *ngIf="visible.SampleType"
                                    [ngClass]="row.classes.animal">
                                    <ng-container>
                                        <active-vocab-select *ngIf="row.outputSample"
                                                             [(model)]="row.outputSample.C_SampleType_key"
                                                             [vocabChoices]="sampleTypes"
                                                             [keyFormatter]="sampleTypeKeyFormatter"
                                                             [optionFormatter]="sampleTypeFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                        <active-vocab-select *ngIf="!row.outputSample&&row.sample"
                                                             [(model)]="row.sample.C_SampleType_key"
                                                             [vocabChoices]="sampleTypes"
                                                             [keyFormatter]="sampleTypeKeyFormatter"
                                                             [optionFormatter]="sampleTypeFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                    </ng-container>
                                </td>

                                <!-- Sample Status-->
                                <td *ngIf="visible.SampleStatus"
                                    [ngClass]="row.classes.animal">
                                    <ng-container>
                                        <active-vocab-select *ngIf="row.outputSample"
                                                             [(model)]="row.outputSample.C_SampleStatus_key"
                                                             [vocabChoices]="sampleStatuses"
                                                             [keyFormatter]="sampleStatusKeyFormatter"
                                                             [optionFormatter]="sampleStatusFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                        <active-vocab-select *ngIf="!row.outputSample&&row.sample"
                                                             [(model)]="row.sample.C_SampleStatus_key"
                                                             [vocabChoices]="sampleStatuses"
                                                             [keyFormatter]="sampleStatusKeyFormatter"
                                                             [optionFormatter]="sampleStatusFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                    </ng-container>
                                </td>

                                <!-- Sample Preservation Method-->
                                <td *ngIf="visible.PreservationMethod"
                                    [ngClass]="row.classes.animal">
                                    <ng-container>
                                        <active-vocab-select *ngIf="row.outputSample"
                                                             [(model)]="row.outputSample.C_PreservationMethod_key"
                                                             [vocabChoices]="preservationMethods"
                                                             [keyFormatter]="preservationMethodKeyFormatter"
                                                             [optionFormatter]="preservationMethodFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                        <active-vocab-select *ngIf="!row.outputSample&&row.sample"
                                                             [(model)]="row.sample.C_PreservationMethod_key"
                                                             [vocabChoices]="preservationMethods"
                                                             [keyFormatter]="preservationMethodKeyFormatter"
                                                             [optionFormatter]="preservationMethodFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                    </ng-container>
                                </td>

                                <!-- Sample Container Type-->
                                <td *ngIf="visible.ContainerType"
                                    [ngClass]="row.classes.animal">
                                    <ng-container>
                                        <active-vocab-select *ngIf="row.outputSample && row.outputSample.Material"
                                                             [(model)]="row.outputSample.Material.C_ContainerType_key"
                                                             [vocabChoices]="containerTypes"
                                                             [keyFormatter]="containerTypeKeyFormatter"
                                                             [optionFormatter]="containerTypeFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                        <active-vocab-select *ngIf="!row.outputSample && row.sample && row.sample.Material"
                                                             [(model)]="row.sample.Material.C_ContainerType_key"
                                                             [vocabChoices]="containerTypes"
                                                             [keyFormatter]="containerTypeKeyFormatter"
                                                             [optionFormatter]="containerTypeFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                    </ng-container>
                                </td>

                                <!-- Sample Volume -->
                                <td *ngIf="visible.SampleVolume"
                                    [ngClass]="row.classes.animal">
                                    <input *ngIf="row.outputSample"
                                           type="number"
                                           min="0"
                                           step="any"
                                           name="volume-{{row.index}}"
                                           [ngModelOptions]="{updateOn: 'blur'}"
                                           [(ngModel)]="row.outputSample.Volume"
                                           class="form-control input-medium"
                                           [disabled]="readonly || row.task.IsWorkflowLocked" />
                                    <input *ngIf="!row.outputSample&&row.sample"
                                           type="number"
                                           min="0"
                                           step="any"
                                           name="volume-{{row.index}}"
                                           [ngModelOptions]="{updateOn: 'blur'}"
                                           [(ngModel)]="row.sample.Volume"
                                           class="form-control input-medium"
                                           [disabled]="readonly || row.task.IsWorkflowLocked" />
                                </td>

                                <!-- Sample Unit-->
                                <td *ngIf="visible.VolumeUnit"
                                    [ngClass]="row.classes.animal">
                                    <ng-container>
                                        <active-vocab-select *ngIf="row.outputSample"
                                                             [(model)]="row.outputSample.C_Unit_key"
                                                             [vocabChoices]="volumeUnitTypes"
                                                             [keyFormatter]="volumeUnitKeyFormatter"
                                                             [optionFormatter]="volumeUnitFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                        <active-vocab-select *ngIf="!row.outputSample&&row.sample"
                                                             [(model)]="row.sample.C_Unit_key"
                                                             [vocabChoices]="volumeUnitTypes"
                                                             [keyFormatter]="volumeUnitKeyFormatter"
                                                             [optionFormatter]="volumeUnitFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                    </ng-container>
                                </td>

                                <!-- Sample Subtype-->
                                <td *ngIf="visible.SampleSubtype"
                                    [ngClass]="row.classes.animal">
                                    <ng-container>
                                        <active-vocab-select *ngIf="row.outputSample"
                                                             [(model)]="row.outputSample.C_SampleSubtype_key"
                                                             [vocabChoices]="sampleSubtypes"
                                                             [keyFormatter]="sampleSubtypeKeyFormatter"
                                                             [optionFormatter]="sampleSubtypeFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                        <active-vocab-select *ngIf="!row.outputSample&&row.sample"
                                                             [(model)]="row.sample.C_SampleSubtype_key"
                                                             [vocabChoices]="sampleSubtypes"
                                                             [keyFormatter]="sampleSubtypeKeyFormatter"
                                                             [optionFormatter]="sampleSubtypeFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                    </ng-container>
                                </td>

                                <!-- Sample Processing Method-->
                                <td *ngIf="visible.SampleProcessingMethod"
                                    [ngClass]="row.classes.animal">
                                    <ng-container>
                                        <active-vocab-select *ngIf="row.outputSample"
                                                             [(model)]="row.outputSample.C_SampleProcessingMethod_key"
                                                             [vocabChoices]="sampleProcessingMethods"
                                                             [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                                             [optionFormatter]="sampleProcessingMethodFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                        <active-vocab-select *ngIf="!row.outputSample&&row.sample"
                                                             [(model)]="row.sample.C_SampleProcessingMethod_key"
                                                             [vocabChoices]="sampleProcessingMethods"
                                                             [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                                             [optionFormatter]="sampleProcessingMethodFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                    </ng-container>
                                </td>

                                <!-- Sample Send To -->
                                <td *ngIf="visible.SendTo"
                                    [ngClass]="row.classes.animal">
                                    <input *ngIf="row.outputSample"
                                           type="text"
                                           maxlength="150"
                                           name="sendTo-{{row.index}}"
                                           [ngModelOptions]="{updateOn: 'blur'}"
                                           [(ngModel)]="row.outputSample.SendTo"
                                           class="form-control input-medium"
                                           [disabled]="readonly || row.task.IsWorkflowLocked" />
                                    <input *ngIf="!row.outputSample&&row.sample"
                                           type="text"
                                           maxlength="150"
                                           name="sendTo-{{row.index}}"
                                           [ngModelOptions]="{updateOn: 'blur'}"
                                           [(ngModel)]="row.sample.SendTo"
                                           class="form-control input-medium"
                                           [disabled]="readonly || row.task.IsWorkflowLocked" />
                                </td>

                                <!-- Sample Analysis Method-->
                                <td *ngIf="visible.SampleAnalysisMethod"
                                    [ngClass]="row.classes.animal">
                                    <ng-container>
                                        <active-vocab-select *ngIf="row.outputSample"
                                                             [(model)]="row.outputSample.C_SampleAnalysisMethod_key"
                                                             [vocabChoices]="sampleAnalysisMethods"
                                                             [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                                             [optionFormatter]="sampleAnalysisMethodFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                        <active-vocab-select *ngIf="!row.outputSample&&row.sample"
                                                             [(model)]="row.sample.C_SampleAnalysisMethod_key"
                                                             [vocabChoices]="sampleAnalysisMethods"
                                                             [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                                             [optionFormatter]="sampleAnalysisMethodFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                    </ng-container>
                                </td>

                                <!-- Sample Lot Number -->
                                <td *ngIf="visible.SampleLotNumber"
                                    [ngClass]="row.classes.animal">
                                    <input *ngIf="row.outputSample"
                                           type="text"
                                           maxlength="150"
                                           name="lotNumber-{{row.index}}"
                                           [ngModelOptions]="{updateOn: 'blur'}"
                                           [(ngModel)]="row.outputSample.LotNumber"
                                           class="form-control input-medium"
                                           [disabled]="readonly || row.task.IsWorkflowLocked" />
                                    <input *ngIf="!row.outputSample&&row.sample"
                                           type="text"
                                           maxlength="150"
                                           name="lotNumber-{{row.index}}"
                                           [ngModelOptions]="{updateOn: 'blur'}"
                                           [(ngModel)]="row.sample.LotNumber"
                                           class="form-control input-medium"
                                           [disabled]="readonly || row.task.IsWorkflowLocked" />
                                </td>

                                <!-- Inherited -->
                                <td *ngIf="visible.Inherited"
                                    class="output">
                                    <ng-container *ngIf="!readonly || row.hasInherited">
                                        <button [ngClass]="(row.needsInherited && !row.taskOutputSet.CollectionDateTime) ? 'btn-warning' : 'btn-secondary'"
                                                type="button"
                                                title="Fetch inherited values"
                                                class="btn row-inherited-button"
                                                (click)="inheritedClicked($event,row)">
                                            <i class="fa fa-history"></i>
                                        </button>
                                    </ng-container>
                                </td>

                                <!-- Outputs: loop over outputs -->
                                <ng-container *ngFor="let outputCol of outputColumns;trackBy: trackColumns">
                                    <td *ngIf="outputCol.visible && output[outputCol.label]"
                                        class="output" #outputContainer>
                                        <ng-container *ngIf="row.taskOutputs[outputCol.key]">
                                            <div class="output-container">
                                                <div class="output-input-container">
                                                    <data-type-input [ioObject]="row.taskOutputs[outputCol.key].Output"
                                                                     [emitOnBlur]="true"
                                                                     [(value)]="row.taskOutputs[outputCol.key].OutputValue"
                                                                     (valueChange)="outputChanged(row, outputCol)"
                                                                     [readonly]="readonly || row.task.IsWorkflowLocked"
                                                                     [dynamicWidth]="true">
                                                    </data-type-input>
                                                    <i *ngIf="row.taskOutputs[outputCol.key].HasFlag"
                                                       class="fa fa-flag text-danger" style="padding-left: 2px;" aria-hidden="true"></i>
                                                </div>
                                                <em class="text-danger" *ngIf="row.taskOutputs[outputCol.key].HasFlag">
                                                    {{row.taskOutputs[outputCol.key].FlagsMessages}}
                                                </em>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <td *ngIf="visible.Collected"
                                    class="output">
                                    <ng-container *ngIf="row.taskOutputSet">
                                        <button [ngClass]="row.taskOutputSet.CollectionDateTime ? 'btn-primary' : 'btn-secondary'"
                                                type="button"
                                                title="Mark output as collected"
                                                class="btn row-collected-button"
                                                (click)="collectedClicked($event,row)"
                                                [disabled]="readonly || row.task.IsWorkflowLocked">
                                            <i class="fa fa-check-square"></i>
                                        </button>
                                    </ng-container>
                                </td>
                                <td *ngIf="visible.CollectedBy">
                                    <ng-container *ngIf="row.taskOutputSet">
                                        <div *ngIf="!isGLP">
                                            <active-vocab-select [(model)]="row.taskOutputSet.C_Resource_key"
                                                                 [vocabChoices]="resources"
                                                                 [keyFormatter]="resourceKeyFormatter"
                                                                 [optionFormatter]="resourceNameFormatter"
                                                                 [nullable]="true"
                                                                 [readonly]="readonly || row.task.IsWorkflowLocked">
                                            </active-vocab-select>
                                        </div>
                                        <div *ngIf="isGLP">
                                            {{row.taskOutputSet.Resource?.UserName}}
                                        </div>
                                    </ng-container>
                                </td>
                                <td *ngIf="visible.CollectedDate">
                                    <ng-container *ngIf="row.taskOutputSet">
                                            <climb-ngb-date #dateControl="ngModel"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [(ngModel)]="row.taskOutputSet.CollectionDateTime"
                                                            [allowTime]="false"
                                                            [disabled]="readonly || row.task.IsWorkflowLocked"
                                                            ngDefaultControl></climb-ngb-date>
                                    </ng-container>
                                </td>
                                <td *ngIf="visible.CollectedTime">
                                    <ng-container *ngIf="row.taskOutputSet">
                                            <climb-ngb-date #dateControl="ngModel"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [(ngModel)]="row.taskOutputSet.CollectionDateTime"
                                                            [allowDate]="false"
                                                            [allowTime]="true"
                                                            [alwaysShowTime]="true"
                                                            [disabled]="readonly || row.task.IsWorkflowLocked"
                                                            ngDefaultControl></climb-ngb-date>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <td *ngIf="visible.Completed"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                    <button data-automation-id="mark-task-as-completed-button"
                                            [ngClass]="row.task?.cv_TaskStatus?.IsEndState ? 'btn-primary' : 'btn-secondary'"
                                            type="button"
                                            title="Mark task as completed"
                                            class="btn task-completed-button"
                                            (click)="completedChange($event, row, rowIndex)"
                                            [disabled]="readonly||loading||row.task.IsWorkflowLocked">
                                        <i class="fa fa-check"></i>
                                    </button>
                                </ng-container>
                            </td>
                            <td *ngIf="visible.CompletedBy"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                    <div *ngIf="!isGLP">
                                        <active-vocab-select [(model)]="row.task.C_CompletedBy_key"
                                                             [vocabChoices]="resources"
                                                             [keyFormatter]="resourceKeyFormatter"
                                                             [optionFormatter]="resourceNameFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked">
                                        </active-vocab-select>
                                    </div>
                                    <div *ngIf="isGLP">
                                        {{row.task.CompletedByResource?.UserName}}
                                    </div>
                                </ng-container>
                            </td>
                            <td *ngIf="visible.CompletedDate"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                            <climb-ngb-date #dateControl="ngModel"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [ngModel]="row.task.DateComplete"
                                                            (ngModelChange)="completedDateChanged(row, $event)"
                                                            [allowTime]="readonly ? true : false"
                                                            [disabled]="readonly || row.task.IsWorkflowLocked"
                                                            ngDefaultControl></climb-ngb-date>
                                </ng-container>
                            </td>
                            <td *ngIf="visible.CompletedTime"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                            <climb-ngb-date #dateControl="ngModel"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [ngModel]="row.task.DateComplete"
                                                            (ngModelChange)="completedTimeChanged(row, $event)"
                                                            [allowDate]="false"
                                                            [allowTime]="true"
                                                            [alwaysShowTime]="true"
                                                            [disabled]="readonly || row.task.IsWorkflowLocked"
                                                            ngDefaultControl></climb-ngb-date>
                                </ng-container>
                            </td>
                            <td *ngIf="visible.Reviewed"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                    <button [ngClass]="row.task?.IsReviewed ? 'btn-primary' : 'btn-secondary'"
                                            type="button"
                                            title="Mark task as reviewed"
                                            class="btn task-completed-button"
                                            (click)="reviewedClicked($event,row)"
                                            [disabled]="readonly||loading||row.task.IsWorkflowLocked">
                                        <i class="fa fa-registered" aria-hidden="true"></i>
                                    </button>
                                </ng-container>
                            </td>
                            <td *ngIf="visible.ReviewedBy"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                    <div *ngIf="!isGLP">
                                        <active-vocab-select [(model)]="row.task.C_ReviewedBy_key"
                                                             [vocabChoices]="resources"
                                                             [keyFormatter]="resourceKeyFormatter"
                                                             [optionFormatter]="resourceNameFormatter"
                                                             [nullable]="true"
                                                             [readonly]="readonly || row.task.IsWorkflowLocked">
                                        </active-vocab-select>
                                    </div>
                                    <div *ngIf="isGLP">
                                        {{row.task.ReviewedByResource?.UserName}}
                                    </div>
                                </ng-container>
                            </td>
                            <td *ngIf="visible.ReviewedDate"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                            <climb-ngb-date #dateControl="ngModel"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [(ngModel)]="row.task.DateReviewed"
                                                            [allowTime]="readonly ? true : false"
                                                            [disabled]="readonly || row.task.IsWorkflowLocked"
                                                            ngDefaultControl></climb-ngb-date>
                                </ng-container>
                            </td>
                            <td *ngIf="visible.ReviewedTime"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                            <climb-ngb-date #dateControl="ngModel"
                                                            [ngModelOptions]="{ standalone: true }"
                                                            [(ngModel)]="row.task.DateReviewed"
                                                            [allowDate]="false"
                                                            [allowTime]="true"
                                                            [alwaysShowTime]="true"
                                                            [disabled]="readonly || row.task.IsWorkflowLocked"
                                                            ngDefaultControl></climb-ngb-date>
                                </ng-container>
                            </td>
                            <!-- Task Status -->
                            <td *ngIf="visible.TaskStatus"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                    <active-vocab-select [model]="row.task.C_TaskStatus_key"
                                                         [vocabChoices]="taskStatuses"
                                                         [keyFormatter]="taskStatusKeyFormatter"
                                                         [optionFormatter]="taskStatusFormatter"
                                                         (modelChange)="taskStatusChanged(row.task, $event)"
                                                         [nullable]="true"
                                                         [readonly]="loading|| readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                </ng-container>
                            </td>
                            <!-- Animal Status -->
                            <td *ngIf="visible.AnimalStatus"
                                [ngClass]="row.classes.animal">
                                <ng-container *ngIf="row.animalFirst">
                                    <active-vocab-select *ngIf="row.animal"
                                                         [(model)]="row.animal.C_AnimalStatus_key"
                                                         (modelChange)="animalStatusChanged(row.animal)"
                                                         [vocabChoices]="animalStatuses"
                                                         [keyFormatter]="animalStatusKeyFormatter"
                                                         [optionFormatter]="animalStatusFormatter"
                                                         [nullable]="true"
                                                         [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                </ng-container>
                            </td>

                            <!-- Animal Exit Reason -->
                            <td *ngIf="visible.ExitReason"
                                [ngClass]="row.classes.animal">
                                <ng-container *ngIf="row.animalFirst">
                                    <active-vocab-select *ngIf="row.animal"
                                                         [(model)]="row.animal.C_ExitReason_key"
                                                         [vocabChoices]="exitReasons"
                                                         [keyFormatter]="exitReasonKeyFormatter"
                                                         [optionFormatter]="exitReasonFormatter"
                                                         [nullable]="true"
                                                         [readonly]="readonly || row.task.IsWorkflowLocked"></active-vocab-select>
                                </ng-container>
                            </td>

                            <!-- Clinical -->
                            <td *ngIf="visible.Clinical && !clinicalSyncEnabled"
                                class="icon-cell col-size-fit"
                                [ngClass]="row.classes.animal">
                                <ng-container *ngIf="!readonly && row.animalFirst && row.animal">
                                    <a class="add-item-link detail-grid-button" (click)="addClinicalRecord(row)"><i class="fa fa-plus-circle" title="Add Clinical Record"></i></a>
                                </ng-container>
                            </td>

                            <!-- Notes: modal -->
                            <td *ngIf="visible.Notes"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                    <div class="btn-group" view-notes-button
                                         [pkName]="'C_TaskInstance_key'"
                                         [pkValue]="row.task.C_TaskInstance_key"
                                         [objectName]="row.task.TaskAlias"
                                         [objectType]="'Task'"
                                         [isObjectNew]="row.task | isNew"
                                         [readonly]="readonly"
                                         appInsightsClick="click-notes-from-workflow-bulk-data-entry">
                                    </div>
                                </ng-container>
                            </td>
                            <!-- File: modal -->
                            <td *ngIf="visible.Files"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                    <view-files-button class="btn-group"
                                                       [pkName]="'C_TaskInstance_key'"
                                                       [pkValue]="row.task.C_TaskInstance_key"
                                                       [facetPrivilege]="facet.Privilege">
                                    </view-files-button>
                                </ng-container>
                            </td>
                            <!-- Task Lock -->
                            <td *ngIf="visible.Lock"
                                class="icon-cell col-size-fit"
                                [ngClass]="row.classes.task">
                                <ng-container *ngIf="row.taskFirst">
                                    <lock-control [(model)]="row.task.IsWorkflowLocked"
                                                  [administrator]="isAdminForTask(row.task)"
                                                  [readonly]="readonly || loading"
                                                  [largeText]="true"></lock-control>
                                </ng-container>
                            </td>
                            <ng-container *ngFor="let taxonCharacteristic of taxonCharacteristicsInListView">
                                <td *ngIf="visible[taxonCharacteristic.CharacteristicName]" class="characteristic-column">
                                    <ng-container *ngIf="row.animal && row.animal.TaxonCharacteristicInstance && row.animal.TaxonCharacteristicInstance.length > 0">
                                        <ng-container *ngFor="let characteristic of row.animal.TaxonCharacteristicInstance | getTaxonCharacteristicInstance: taxonCharacteristic.CharacteristicName">
                                                {{characteristic.CharacteristicValue}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <div style="position: relative">
                <pagination-controls (pageChange)="changeTaskPage($event)"
                                     [id]="paginationRandomId"
                                     [autoHide]="true"
                                     class="ngx-table-paginator"></pagination-controls>
            </div>
            <div class="row">
                <div class="stats-box-wrapper" *ngFor="let cohort of cohortsStats">
                    <div class="stats-box table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <td colspan="2" class="border-top-0">
                                        <span class="cohort-badge" [ngStyle]="{ backgroundColor: cohort.BackgroundColor, color: cohort.ForegroundColor }">
                                            {{ cohort.CohortName }}
                                        </span>
                                    </td>
                                    <td class="num-cell border-top-0" [attr.colspan]="cohort.outputs.length">Outputs</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Count</th>
                                    <td class="num-cell">{{ cohort.Count }}/{{cohort.Total}}</td>
                                    <th *ngFor="let output of cohort.outputs" class="num-cell">{{ output.name }}</th>
                                </tr>
                                <tr>
                                    <th colspan="2">Average</th>
                                    <td class="num-cell" *ngFor="let output of cohort.outputs">
                                        {{ output.average | safeNumber:'1.2-2' }}
                                        <i *ngIf="output.HasAvgFlag && output.average !== null"
                                           class="fa fa-flag text-danger" style="padding-left: 2px;" aria-hidden="true"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="2">Median</th>
                                    <td class="num-cell" *ngFor="let output of cohort.outputs">
                                        {{ output.median | safeNumber:'1.2-2' }}
                                        <i *ngIf="output.HasMdnFlag && output.median !== null"
                                           class="fa fa-flag text-danger" style="padding-left: 2px;" aria-hidden="true"></i>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="2">Standard Deviation (σ)</th>
                                    <td class="num-cell" *ngFor="let output of cohort.outputs">
                                        {{ output.standard | safeNumber:'1.2-2' }}
                                        <i *ngIf="output.HasStdDevFlag && output.standard !== null"
                                           class="fa fa-flag text-danger" style="padding-left: 2px;" aria-hidden="true"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</div>

<div id="{{printPreviewId}}" class="d-none">
    <workflow-bulk-data-print [rows]="rows"
                              [inputColumns]="inputColumns"
                              [outputColumns]="outputColumns"
                              [common]="common"
                              [taxonCharacteristicsInListView]="taxonCharacteristicsInListView"
                              [visible]="visible"></workflow-bulk-data-print>
</div>

<ng-template #bulkForce>
    <label *ngIf="!isGLP" class="d-flex align-items-center mt-2 mb-0">
        <input type="checkbox"
               [(ngModel)]="bulk.force"
               class="mr-2"
               name="bulk_Force" />
        <span class="font-weight-bold">Overwrite existing values</span>
    </label>
</ng-template>

<ng-template #bulkDateDueHeader let-title="title">
    <bulk-edit-header [headerText]="title"
                      (updateClicked)="bulkDateDueChanged()"
                      [disabled]="bulkDateDueDisabled()">
        <div modal-content class="text-body">
            <div class="form-group">
                <label>Task Name</label>
                <select [(ngModel)]="bulk.dateDueTaskAlias"
                        class="form-control input-medium"
                        name="bulk_dateDueTaskAlias">
                    <option *ngIf="bulk.dateDueTaskAliases.length == 0"
                            [value]="null">
                        [ No valid tasks ]
                    </option>
                    <option *ngFor="let taskAlias of bulk.dateDueTaskAliases"
                            [value]="taskAlias">
                        {{taskAlias}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Starting at</label>
                <climb-ngb-date #dateControl="ngModel"
                                name="Starting at"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="bulk.dateDue"
                                [allowTime]="true"
                                [alwaysShowTime]="true"
                                ngDefaultControl>
                </climb-ngb-date>
            </div>
            <div class="form-group">
                <label>Increment start times by</label>
                <div class="d-flex">
                    <input type="number"
                           min="0"
                           step="any"
                           name="bulk_DateDueIncrement"
                           [(ngModel)]="bulk.dateDueIncrement"
                           class="form-control input-small mr-1" />
                    <active-vocab-select [(model)]="bulk.dateDueUnitKey"
                                         [vocabChoices]="timeUnits"
                                         [keyFormatter]="timeUnitKeyFormatter"
                                         [optionFormatter]="timeUnitFormatter"></active-vocab-select>
                </div>
            </div>
        </div>
    </bulk-edit-header>
</ng-template>

<!--Import data file-->
<ng-template #importModal let-d="dismiss">
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Import Outputs</h4>
    </div>

    <div class="modal-body body-container">
        <div class="col-md-6">
            <ng-container *ngIf="import.hasErrors">
                <h4>Errors</h4>
                <ul>
                    <li *ngFor="let error of import.errors"
                        class="alert alert-warning">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{error}}
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="!import.hasErrors">
                <h4>Download Template</h4>
                <label>
                    <input type="checkbox"
                           [(ngModel)]="import.includeAnimalNames"
                           name="includeAnimalNames">
                    Include "Animal" in download template
                    <info-tooltip [text]="'Pre-populate the animal details in the Template form'"></info-tooltip>
                </label>
                <br />
                <label>
                    <input type="checkbox"
                           [(ngModel)]="import.includeSampleNames"
                           name="includeSampleNames">
                    Include "Sample" in download template
                    <info-tooltip [text]="'Pre-populate the sample details in the Template form'"></info-tooltip>
                </label>
                <br />
                <h4>Import File</h4>
                <label>
                    <input type="checkbox"
                           [(ngModel)]="import.attachToTasks"
                           name="attachToTasks">
                    Attach Imported File To Tasks
                </label>
                <br />
                <label>
                    <input type="checkbox"
                           [(ngModel)]="import.attachToJob"
                           name="attachToJob">
                    Attach Imported File To {{'Job' | translate}}
                </label>
                <br />
                <import-file-upload [readonly]="false"
                                    [facetPrivilege]="facet.Privilege"
                                    (fileChange)="importFileUpdate($event)">
                </import-file-upload>
            </ng-container>
        </div>
        <div class="col-md-6">
            <h4>File Format</h4>
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>Column</th>
                        <th>Description</th>
                        <th>Read Only</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><code>Collected Date</code></td>
                        <td>The date the value was collected</td>
                        <td>false</td>
                    </tr>
                    <tr>
                        <td><code>Collected By</code></td>
                        <td>The resource name of the person who collected the value</td>
                        <td>false</td>
                    </tr>
                    <tr>
                        <td><code>Animal ID</code></td>
                        <td>The animal ID of the animal associated with the output</td>
                        <td>true</td>
                    </tr>
                    <tr>
                        <td><code>Animal Name</code></td>
                        <td>The name of the animal associated with the output</td>
                        <td>true</td>
                    </tr>
                    <tr>
                        <td><code>Microchip ID</code></td>
                        <td>The microchip ID of the animal associated with the output</td>
                        <td>true</td>
                    </tr>
                    <tr>
                        <td><code>External ID</code></td>
                        <td>The external ID of the animal associated with the output</td>
                        <td>true</td>
                    </tr>
                    <tr *ngIf="isGLP">
                        <td><code>Alternate Physical ID</code></td>
                        <td>The alternate physical ID assigned to the animal</td>
                        <td>true</td>
                    </tr>
                    <tr>
                        <td><code>Sample ID</code></td>
                        <td>The sample ID of the sample associated with the output</td>
                        <td>true</td>
                    </tr>
                    <tr>
                        <td><code>Sample Name</code></td>
                        <td>The name of the sample associated with the output</td>
                        <td>true</td>
                    </tr>
                    <tr *ngFor="let output of import.outputs | orderBy : 'SortOrder'">
                        <td><code>{{output?.OutputName}}</code></td>
                        <td>Output value</td>
                        <td>false</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary"
                [disabled]="!import.filename || import.inProgress"
                (click)="importProcessFile()">
            <i class="fa"
               [ngClass]="{'fa-upload':!import.inProgress, 'fa-2x':import.inProgress, 'fa-spinner':import.inProgress}"
               aria-hidden="true"></i>
            Import
        </button>
        <button class="btn btn-secondary"
                (click)="importDownloadTemplate()">
            <i class="fa fa-download" aria-hidden="true" title="Download Template"></i>
            Download Template
        </button>
        <button class="btn btn-secondary"
                (click)="d()">
            Close
        </button>
    </div>
</ng-template>
