<div class="facet" id="vocabulary-panel">
    <facet-header caption="Vocabularies"
        [facetId]="facetId">
        <button data-automation-id="refresh-data-button"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>
    <div *ngIf="facet.Privilege!=='None'"
         class="facet-body"
         style="overflow-x:hidden !important;overflow-y:hidden !important;">
        <climb-facet-toolbar>
            <button *ngIf="facet.Privilege === 'ReadWrite'"
                    climbSaveButton
                    [disabled]="detailToolbar.isSaveDisabled"
                    (click)="onSaveVocabularies()">
            </button>
        </climb-facet-toolbar>
        <div class="detail-header d-none">
            <detail-toolbar #detailToolbar
                            [showListViewButton]="false"
                            [facetPrivilege]="facet.Privilege"
                            [logTag]="COMPONENT_LOG_TAG"
                            [addVocabularies]="true"
                            (saveVocabularies)="onSaveVocabularies()">
            </detail-toolbar>
        </div>
        <div class="row mx-0" style="overflow-y: scroll; height: calc(100% - 40px) !important;">
            <div class="col-md-4 col-lg-3 pb-3 h-100" style="overflow-y: scroll;">
                <ul class="facet-contentlist">
                    <ng-container *ngFor="let item of vocabularyMasterList; trackBy vocabTableName">
                        <ng-container *ngIf="!isDTX">
                            <ng-container *ngIf="(isCRO && isCRL) || (!isCRO && isCRL)">
                                <li *ngIf="!item.hideForNotGLP" class="facet-contentlistitem"
                                    [ngClass]="{'facet-contentlistitem-selected': item.isSelected}">
                                    <span *ngIf="item.isSelected">{{ item.friendlyName }} </span>
                                    <a *ngIf="!item.isSelected" (click)="selectVocabItem(item)" class="content-list-item">
                                        {{ item.friendlyName }}
                                    </a>
                                </li>

                            </ng-container>
                            <ng-container *ngIf="isCRO && !isCRL">
                                <li *ngIf="!item.hideForNotCRL && !item.hideForNotGLP"
                                    class="facet-contentlistitem"
                                    [ngClass]="{'facet-contentlistitem-selected': item.isSelected}">
                                    <span *ngIf="item.isSelected">{{ item.friendlyName }}</span>
                                    <a *ngIf="!item.isSelected" (click)="selectVocabItem(item)" class="content-list-item">
                                        {{ item.friendlyName }}
                                    </a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="!isCRO && !isCRL">
                                <li *ngIf="!item.hideForNotCRO && !item.hideForNotCRL && !item.hideForNotGLP"
                                    class="facet-contentlistitem"
                                    [ngClass]="{'facet-contentlistitem-selected': item.isSelected}">
                                    <span *ngIf="item.isSelected">{{ item.friendlyName }}</span>
                                    <a *ngIf="!item.isSelected" (click)="selectVocabItem(item)" class="content-list-item">
                                        {{ item.friendlyName }}
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="isDTX">
                            <ng-container *ngIf="isCRO && isCRL">
                                <li *ngIf="!item.hideForDTX && !item.hideForNotGLP"
                                    class="facet-contentlistitem"
                                    [ngClass]="{'facet-contentlistitem-selected': item.isSelected}">
                                    <span *ngIf="item.isSelected">{{ item.friendlyName }}</span>
                                    <a *ngIf="!item.isSelected" (click)="selectVocabItem(item)" class="content-list-item">
                                        {{ item.friendlyName }}
                                    </a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="isCRO && !isCRL">
                                <li *ngIf="!item.hideForNotCRL && !item.hideForDTX && !item.hideForNotGLP"
                                    class="facet-contentlistitem"
                                    [ngClass]="{'facet-contentlistitem-selected': item.isSelected}">
                                    <span *ngIf="item.isSelected">{{ item.friendlyName }}</span>
                                    <a *ngIf="!item.isSelected" (click)="selectVocabItem(item)" class="content-list-item">
                                        {{ item.friendlyName }}
                                    </a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="!isCRO && !isCRL">
                                <li *ngIf="!item.hideForNotCRO && !item.hideForDTX && !item.hideForNotGLP"
                                    class="facet-contentlistitem"
                                    [ngClass]="{'facet-contentlistitem-selected': item.isSelected}">
                                    <span *ngIf="item.isSelected">{{ item.friendlyName }}</span>
                                    <a *ngIf="!item.isSelected" (click)="selectVocabItem(item)" class="content-list-item">
                                        {{ item.friendlyName }}
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ul>
            </div>
            <div class="col-md-8 col-lg-9 pl-2"
                 style="height:100%; overflow-y:auto;"
                 #vocabularyPanelBody>
                <loading-overlay *ngIf="loading"></loading-overlay>
                <div *ngIf="isEditing" data-drop="false">
                    <h3>{{ currentVocab.friendlyName }}</h3>

                    <form class="form-label-right">
                        <fieldset [disabled]="isReadOnly">
                            <!--Default-->
                            <div *ngIf="!this.currentVocab.noDefault
                                        && this.currentVocab.fieldName !== 'Modifier1'
                                        && this.currentVocab.fieldName !== 'Modifier2'
                                        && this.currentVocab.fieldName !== 'Modifier3'
                                        && this.currentVocab.fieldName !== 'Modifier4'"
                                 class="form-group row">
                                <label class="col-md-3 col-form-label">Default</label>
                                <div class="col-md-9">
                                    <active-vocab-select [(model)]="this.currentDefaultKey"
                                                         id="vocab-default-select"
                                                         (modelChange)="this.defaultChanged($event)"
                                                         [vocabChoices]="this.getActiveCurrentVocabOptions()"
                                                         [keyFormatter]="this.itemKeyFormatter"
                                                         [optionFormatter]="this.itemFormatter"
                                                         [nullable]="this.currentVocab.nullable"
                                                         [required]="!this.currentVocab.nullable"></active-vocab-select>
                                </div>
                            </div>

                            <!--Mating Default-->
                            <div class="form-group row" *ngIf="this.currentVocab.hasMaterialDefault">
                                <label class="col-md-3 col-form-label">Mating Default</label>
                                <div class="col-md-9"
                                     [ngClass]="{'has-error': (!this.isMaterialDefaultValid)}">
                                    <active-vocab-select [(model)]="this.currentMatingDefaultKey"
                                                         (modelChange)="this.matingDefaultChanged($event)"
                                                         [vocabChoices]="this.getActiveCurrentVocabOptions()"
                                                         [keyFormatter]="this.itemKeyFormatter"
                                                         [optionFormatter]="this.itemFormatter"
                                                         [required]="true"></active-vocab-select>
                                    <small class="form-text text-muted"
                                           *ngIf="!this.isMaterialDefaultValid">
                                        Please select a Mating Default value to be the default housing type for mating.
                                    </small>
                                </div>
                            </div>

                            <!--Default End State-->
                            <div class="form-group row" *ngIf="this.currentVocab.hasDefaultEndState">
                                <label class="col-md-3 col-form-label"
                                       *ngIf="currentVocab.friendlyName !== 'Housing Status'">Default End State</label>
                                <div class="col-md-3" *ngIf="currentVocab.friendlyName === 'Housing Status'">
                                    <label class="col-md-12 col-form-label padding-right0">
                                        Default End State
                                        <info-tooltip [text]="'Setting a default will enable automatic Housing Unit ending when empty. Users will be prompted when automatic status changes are triggered.'">
                                        </info-tooltip>
                                    </label>
                                </div>
                                <div class="col-md-9"
                                     [ngClass]="{'has-error': (!this.isDefaultEndStateValid)}">
                                    <active-vocab-select [(model)]="this.currentDefaultEndStateKey"
                                                         (modelChange)="this.defaultEndStateChanged($event)"
                                                         [vocabChoices]="this.endStateItems"
                                                         [keyFormatter]="this.itemKeyFormatter"
                                                         [optionFormatter]="this.itemFormatter"
                                                         [nullable]="!this.currentVocab.defaultEndStateRequired"
                                                         [required]="this.currentVocab.defaultEndStateRequired"></active-vocab-select>
                                    <small class="form-text text-muted"
                                           *ngIf="!this.isDefaultEndStateValid">
                                        Please select an End State value to be the default end state.
                                    </small>
                                </div>
                            </div>

                            <!--Default Automatic End State-->
                            <div class="form-group row"
                                 *ngIf="this.currentVocab.hasDefaultAutoEndState && hasAutomaticallyEndTask">
                                <label class="col-md-3 col-form-label"
                                       *ngIf="currentVocab.friendlyName !== 'Task Statuses'">
                                    Default Automatic End State
                                </label>
                                <div class="col-md-3" *ngIf="currentVocab.friendlyName === 'Task Statuses'">
                                    <label class="col-md-12 col-form-label padding-right0">
                                        Default Automatic End State
                                        <info-tooltip [text]="'The default value set here will apply to any task status changed automatically by the system when animals are set to an end state, provided that the feature is also enabled in the Task facet. Only &ldquo;End State&rdquo; status values can be selected.'">
                                        </info-tooltip>
                                    </label>
                                </div>
                                <div class="col-md-9"
                                     [ngClass]="{'has-error': (!this.isDefaultAutoEndStateValid)}">
                                    <active-vocab-select [(model)]="this.currentDefaultAutoEndStateKey"
                                                         (modelChange)="this.defaultAutoEndStateChanged($event)"
                                                         [vocabChoices]="this.endStateItems"
                                                         [keyFormatter]="this.itemKeyFormatter"
                                                         [optionFormatter]="this.itemFormatter"
                                                         [nullable]="!this.currentVocab.defaultAutoEndStateRequired"
                                                         [required]="this.currentVocab.defaultAutoEndStateRequired">
                                    </active-vocab-select>
                                    <small class="form-text text-muted"
                                           *ngIf="!this.isDefaultAutoEndStateValid">
                                        Please select an End State value to be the default automatic end state.
                                    </small>
                                </div>
                            </div>

                            <!--Default Clinical Status-->
                            <div class="form-group row" *ngIf="this.currentVocab.hasDefaultHealthRecordStatus">
                                <label class="col-md-3 col-form-label">Default Clinical Status</label>
                                <div class="col-md-9"
                                     [ngClass]="{'has-error': (this.currentVocab.defaultHealthRecordStatusRequired &&
                                                            !this.currentDefaultHealthRecordStatusKey)}">
                                    <active-vocab-select [(model)]="this.currentDefaultHealthRecordStatusKey"
                                                         (modelChange)="this.defaultHealthRecordStatusChanged($event)"
                                                         [vocabChoices]="this.getActiveCurrentVocabOptions()"
                                                         [keyFormatter]="this.itemKeyFormatter"
                                                         [optionFormatter]="this.itemFormatter"
                                                         [nullable]="!this.currentVocab.defaultHealthRecordStatusRequired"
                                                         [required]="this.currentVocab.defaultHealthRecordStatusRequired">
                                    </active-vocab-select>
                                    <small class="form-text text-muted"
                                           *ngIf="this.currentVocab.defaultHealthRecordStatusRequired && !this.currentDefaultHealthRecordStatusKey">
                                        Please select a default value for new health records.
                                    </small>
                                </div>
                            </div>

                            <!--Default Sample Container-->
                            <!--Default Animal Container-->
                            <ng-container *ngIf="currentFieldName === 'ContainerType'">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Default Sample Container</label>
                                    <div class="col-md-9">
                                        <active-vocab-select [(model)]="this.currentSampleDefaultKey"
                                                             (modelChange)="this.sampleContainerDefaultChanged($event)"
                                                             [vocabChoices]="this.sampleContainerChoices"
                                                             [keyFormatter]="this.itemKeyFormatter"
                                                             [optionFormatter]="this.itemFormatter"
                                                             [nullable]="this.currentVocab.nullable"
                                                             [required]="!this.currentVocab.nullable"></active-vocab-select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Default Animal Container</label>
                                    <div class="col-md-9">
                                        <active-vocab-select [(model)]="this.currentAnimalDefaultKey"
                                                             (modelChange)="this.animalContainerDefaultChanged($event)"
                                                             [vocabChoices]="this.animalContainerChoices"
                                                             [keyFormatter]="this.itemKeyFormatter"
                                                             [optionFormatter]="this.itemFormatter"
                                                             [nullable]="this.currentVocab.nullable"
                                                             [required]="!this.currentVocab.nullable"></active-vocab-select>
                                    </div>
                                </div>
                            </ng-container>

                            <small class="form-text text-muted col-md-12 px-0" style="margin:14px 0 5px;"
                                   *ngIf="!isReadOnly">
                                Drag to reorder.
                            </small>

                            <div class="col-md-12 px-0">
                                <table class="table table-borderless"
                                       [ngClass]="{'table-hover' : !isReadOnly}">
                                    <thead>
                                        <tr class="border-bottom">
                                            <th class="icon-cell" style="width: 2em;"
                                                *ngIf="!isReadOnly">
                                                <a class="add-item-link" (click)="this.addNewVocabItems()">
                                                    <i class="fa fa-plus-circle" title="Add value"></i>
                                                </a>
                                            </th>
                                            <th>Value</th>
                                            <th *ngIf="currentFieldName === 'GenotypeAssay'">Genotypes</th>
                                            <th *ngIf="currentFieldName === 'StandardPhrase' && isCRL"
                                                class="text-center">Category</th>
                                            <th *ngIf="currentFieldName === 'StandardPhrase' && isCRL"
                                                class="text-center">Type</th>
                                            <th *ngIf="currentFieldName === 'StandardPhrase' && isCRO"
                                                class="text-center">{{'Job' | translate}} Defaults</th>
                                            <th class="text-center">Active</th>
                                            <th *ngIf="currentFieldName === 'ContainerType'" class="text-center">
                                                Animal
                                                <info-tooltip class="tool-tip" [placement]="'top'"
                                                              [text]="'If Animal is not selected, container type will default to Sample.'">
                                                </info-tooltip>
                                            </th>

                                            <th class="text-center" *ngIf="currentVocab.hasEndState && currentVocab.endStateTitle == null">End State</th>
                                            <th class="text-center" *ngIf="currentVocab.hasEndState && currentVocab.endStateTitle != null">{{currentVocab.endStateTitle}}</th>
                                            <th class="text-center" *ngIf="currentVocab.hasPermanent">Permanent</th>
                                            <th class="text-center" *ngIf="currentFieldName === 'ClinicalObservation'">
                                                Experimental
                                            </th>
                                            <th class="text-center" *ngIf="currentVocab.canDelete">Delete</th>
                                            <!-- TODO Allow editing of cv_MatingType.IsLineOptional -->
                                        </tr>
                                    </thead>

                                    <tbody [dragula]='dragulaBagName' [dragulaModel]="rowsToEdit">
                                        <tr *ngFor="let item of this.rowsToEdit; trackBy: trackRow">
                                            <td class="icon-cell" *ngIf="!isReadOnly">
                                                <div class="form-control-plaintext">
                                                    <i class="fa fa-sort text-muted draggable"
                                                       title="Drag to reorder"></i>
                                                </div>
                                            </td>
                                            <td *ngIf="currentFieldName === 'StandardPhrase' && !isCRL">
                                                <textarea [(ngModel)]="item[this.currentFieldName]"
                                                          data-auto-focus="cvItemInput" rows="6"
                                                          class="form-control input-medium"
                                                          [ngModelOptions]="{standalone: true}"
                                                          [disabled]="item.IsUsed"></textarea>
                                                <info-tooltip *ngIf="item.IsUsed" class="tool-tip"
                                                              [placement]="'right-bottom'"
                                                              [text]="'Standard phrases used in ' + ('Jobs' | translate) + ' cannot be modified. To change the text or variable selection, please create a new standard phrase.'">
                                                </info-tooltip>
                                            </td>
                                            <td *ngIf="currentFieldName === 'StandardPhrase' && isCRL">
                                                <variable-standard-phrase [standardPhrase]="item"
                                                                          [variablesList]="standardPhraseVariables"
                                                                          [disabled]="isReadOnly || item.IsUsed"
                                                                          [expressionJson]="castToCVStandardPhrase(item).StandardPhraseJSON"
                                                                          (expressionChange)="standardPhraseExpressionChanged(castToCVStandardPhrase(item), $event)"
                                                                          data-auto-focus="cvItemInput" [isUsed]="item.IsUsed">
                                                </variable-standard-phrase>
                                            </td>
                                            <td *ngIf="currentFieldName !== 'StandardPhrase'">
                                                <input type="text" data-auto-focus="cvItemInput"
                                                       [(ngModel)]="item[this.currentFieldName]"
                                                       class="form-control input-medium"
                                                       [required]="item.IsActive"
                                                       [ngModelOptions]="{standalone: true}">
                                            </td>
                                            <td *ngIf="currentFieldName === 'GenotypeAssay'" style="min-width: 100px; text-align: left">
                                                <!-- Table for Symbols allowed with this GenotypeAssay -->
                                                <table *ngIf="castToCVGenotypeAssay(item).GenotypeAssayGenotypeSymbol | notEmpty">
                                                    <tbody>
                                                        <tr *ngFor="let assayGenotypeSymbol of castToCVGenotypeAssay(item).GenotypeAssayGenotypeSymbol">
                                                            <td class="icon-cell">
                                                                <a (click)="removeGenotypeAssayGenotypeSymbol(assayGenotypeSymbol)">
                                                                    <i class="fa fa-remove remove-item"
                                                                       title="Remove genotype"></i>
                                                                </a>
                                                            </td>
                                                            <td class="text-nowrap">
                                                                {{ assayGenotypeSymbol.cv_GenotypeSymbol.GenotypeSymbol }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <!-- Typeahead for GenotypeSymbols -->
                                                <climb-indirect-typeahead [search]="searchGenotypes"
                                                                          [resultFormatter]="genotypeSymbolFormatter"
                                                                          [placeholder]="genotypeSymbolPlaceholder"
                                                                          (selectItem)="addGenotypeAssayGenotypeSymbol($event, castToCVGenotypeAssay(item))">
                                                </climb-indirect-typeahead>
                                            </td>
                                            <td *ngIf="currentFieldName === 'StandardPhrase' && isCRL"
                                                [ngClass]="{'has-error': (!castToCVStandardPhraseCategory(item).C_StandardPhraseCategory_key)}">
                                                <div class="form-group row">
                                                    <div class="col-md-12">
                                                        <active-vocab-select [(model)]="castToCVStandardPhraseCategory(item).C_StandardPhraseCategory_key"
                                                                             [vocabChoices]="this.standardPhraseCategories"
                                                                             [keyFormatter]="this.standardPhraseCategoryKeyFormatter"
                                                                             [optionFormatter]="this.standardPhraseCategoryFormatter"
                                                                             [required]="isCRL"></active-vocab-select>
                                                        <small class="form-text text-muted" *ngIf="!castToCVStandardPhraseCategory(item).C_StandardPhraseCategory_key">
                                                            Please select a Category.
                                                        </small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="currentFieldName === 'StandardPhrase' && isCRL"
                                                [ngClass]="{'has-error': (!castToCVStandardPhraseLinkType(item).C_StandardPhraseLinkType_key)}">
                                                <div class="form-group row">
                                                    <div class="col-md-12">
                                                        <active-vocab-select [(model)]="castToCVStandardPhraseLinkType(item).C_StandardPhraseLinkType_key"
                                                                             (modelChange)="applyLinkTypeChange(castToCVStandardPhrase(item))"
                                                                             [vocabChoices]="standardPhraseLinkTypes"
                                                                             [keyFormatter]="standardPhraseLinkTypeKeyFormatter"
                                                                             [optionFormatter]="standardPhraseLinkTypeFormatter"
                                                                             [required]="isCRL"
                                                                             [readonly]="item.IsUsed">
                                                        </active-vocab-select>
                                                        <small class="form-text text-muted" *ngIf="!castToCVStandardPhraseLinkType(item).C_StandardPhraseLinkType_key">
                                                            Please select a Type.
                                                        </small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="currentFieldName === 'StandardPhrase' && isCRL && !castToCVStandardPhrase(item).cv_StandardPhraseLinkType">
                                                &nbsp;
                                            </td>
                                            <td *ngIf="currentFieldName === 'StandardPhrase' && ((isCRO && !isCRL) || (isCRL && castToCVStandardPhrase(item).cv_StandardPhraseLinkType?.StandardPhraseLinkType === 'Job'))">
                                                <div class="form-group row">
                                                    <label class="col-md-12">Type</label>
                                                    <div class="col-md-12">
                                                        <climb-multi-select [(model)]="castToCVStandardPhrase(item).JobType_keys"
                                                                            (modelChange)="applyJobTypeChange(castToCVStandardPhrase(item))"
                                                                            [values]="this.jobTypes" [keyProperty]="'C_JobType_key'"
                                                                            [valueProperty]="'JobType'" [placeholder]="'Select...'">
                                                        </climb-multi-select>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-md-12">Subtype</label>
                                                    <div class="col-md-12">
                                                        <climb-multi-select [(model)]="castToCVStandardPhrase(item).JobSubtype_keys"
                                                                            (modelChange)="applyJobSubtypeChange(castToCVStandardPhrase(item))"
                                                                            [values]="this.jobSubtypes"
                                                                            [keyProperty]="'C_JobSubtype_key'"
                                                                            [valueProperty]="'JobSubtype'" [placeholder]="'Select...'">
                                                        </climb-multi-select>
                                                    </div>
                                                </div>
                                                <div *ngIf="castToCVStandardPhrase(item).C_StandardPhraseCategory_key == categoryReportKey && isCRL"
                                                     class="form-group row">
                                                    <label class="col-md-12">Report</label>
                                                    <div class="col-md-12">
                                                        <active-vocab-select [(model)]="castToCVStandardPhrase(item).C_JobReport_key"
                                                                             [vocabChoices]="this.jobReports"
                                                                             [keyFormatter]="this.jobReportKeyFormatter"
                                                                             [optionFormatter]="this.jobReportFormatter">
                                                        </active-vocab-select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="currentFieldName === 'StandardPhrase' && isCRL && castToCVStandardPhrase(item).cv_StandardPhraseLinkType?.StandardPhraseLinkType === 'IACUC Protocol'">
                                                <div class="form-group row">
                                                    <label class="col-md-12">IACUC Protocol</label>
                                                    <div class="col-md-12">
                                                        <climb-multi-select [(model)]="castToCVStandardPhrase(item).IACUCProtocol_keys"
                                                                            (modelChange)="applyIACUCProtocolChange(castToCVStandardPhrase(item))"
                                                                            [values]="iacucProtocols"
                                                                            [keyProperty]="'C_IACUCProtocol_key'"
                                                                            [valueProperty]="'IACUCProtocol'"
                                                                            [placeholder]="'Select...'"></climb-multi-select>
                                                    </div>
                                                </div>
                                            </td>
                                            <!--Is Active-->
                                            <td class="text-center">
                                                <input type="checkbox"
                                                       class="vocab-item-active-checkbox mt-1"
                                                       [(ngModel)]="item.IsActive"
                                                       (change)="activeChanged(item)"
                                                       [ngModelOptions]="{standalone: true}">
                                            </td>
                                            <!--Animal-->
                                            <td *ngIf="currentFieldName === 'ContainerType'" class="text-center">
                                                <input type="checkbox"
                                                       class="mt-1"
                                                       [(ngModel)]="item.IsAnimal"
                                                       (change)="animalChanged(castToCVContainerType(item))"
                                                       [ngModelOptions]="{standalone: true}">
                                            </td>
                                            <!--End State-->
                                            <td class="text-center" *ngIf="currentVocab.hasEndState">
                                                <input type="checkbox"
                                                       class="mt-1"
                                                       [(ngModel)]="item[currentVocab.endStateFieldName]"
                                                       (change)="endStateChanged()"
                                                       [ngModelOptions]="{standalone: true}"
                                                       [disabled]="currentVocab.hasPermanent && item[currentVocab.permanentFieldName] === true">
                                            </td>
                                            <td class="text-center" *ngIf="currentVocab.hasPermanent">
                                                <input type="checkbox"
                                                       class="mt-1"
                                                       [(ngModel)]="item[currentVocab.permanentFieldName]"
                                                       [ngModelOptions]="{standalone: true}"
                                                       [disabled]="item[currentVocab.endStateFieldName] === true">
                                            </td>
                                            <td class="text-center" *ngIf="currentFieldName === 'ClinicalObservation'">
                                                <input type="checkbox"
                                                       class="mt-1"
                                                       [(ngModel)]="item.TrackInWorkflow"
                                                       [ngModelOptions]="{standalone: true}">
                                            </td>
                                            <td class="icon-cell" *ngIf="currentVocab.canDelete">
                                                <div class="form-control-plaintext">
                                                    <button *ngIf="!isReadOnly" class="btn btn-link btn-icon vocab-item-delete" (click)="deleteVocabTerm(currentVocab, item)">
                                                        <i class="fa fa-remove remove-item" title="Delete vocabulary term"></i>
                                                    </button>
                                                    <i *ngIf="isReadOnly" class="fa fa-remove remove-item"></i>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
