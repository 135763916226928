import { 
    CreateSampleGroupsModalService 
} from './pharma/modals/create-sample-groups-modal.service';
import { 
    CreateSampleGroupsModalComponent 
} from './pharma/modals/create-sample-groups-modal.component';
import { AuditSharedModule } from './../audit/audit-shared.module';
import { UsersSharedModule } from './../user/users-shared.module';
import { LinesSharedModule } from './../lines/lines-shared.module';
import { InstitutionsSharedModule } from './../institution/institutions-shared.module';
import { VocabulariesSharedModule } from './../vocabularies/vocabularies-shared.module';
import { JobLogicService } from './job-logic.service';
import { ViewJobAuditReportComponentService } from './view-job-audit-report-component.service';
import { JobAuditReportComponent } from './job-audit-report.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AnimalsSharedModule } from '../animals/animals-shared.module';
import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { JobService } from './job.service';
import { JobVocabService } from './job-vocab.service';
import { ExportJobDetailService } from './export-job-detail.service';
import { ExportJobAuditService } from './export-job-audit.service';
import { MaterialJobSelectComponent } from './material-job-select.component';
import { MaterialPoolJobSelectComponent } from './material-pool-job-select.component';
import { JobMultiselectComponent } from './job-multiselect.component';
import { JobSelectComponent } from './job-select.component';
import { EnumerationsSharedModule } from '../enumerations/enumerations-shared.module';
import { TestArticleMultiselectComponent } from './test-article-multiselect.component';
import { TestArticleSelectComponent } from './test-article-select.component';
import { ReportingSharedModule } from "../reporting/reporting-shared.module";
import { StandardPhraseChooserComponent } from './pharma/modals/standard-phrase-chooser.component';
import { CreatePlaceholderAssociationModalComponent } from './pharma/modals/create-placeholder-association-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JobSubtypeSelectComponent } from './job-subtype-select.component';
import { GroupPlaceholderMultiselectComponent } from './group-placeholder-multiselect.component';
import { JobOrderMultiselectComponent } from './job-order-multiselect.component';
import { BulkAssignCohortsModalComponent } from './pharma/modals/bulk-assign-cohort-modal.component';
import { BulkAssignCohortsModalService } from './pharma/modals/bulk-assign-cohort-modal.service';
import { ProtocolsSharedModule } from '../protocol/protocols-shared.module';
import { FileDownloader } from '@common/download';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AnimalsSharedModule,
        AuditSharedModule,
        ClimbCommonModule,
        ClimbServicesModule,
        EnumerationsSharedModule,
        InstitutionsSharedModule,
        LinesSharedModule,
        UsersSharedModule,
        VocabulariesSharedModule,
        NgbModule,
        ProtocolsSharedModule
    ],
    declarations: [
        MaterialJobSelectComponent,
        MaterialPoolJobSelectComponent,
        JobMultiselectComponent,
        JobSelectComponent,
        JobAuditReportComponent,
        CreateSampleGroupsModalComponent,
        BulkAssignCohortsModalComponent,
        CreatePlaceholderAssociationModalComponent,
        TestArticleMultiselectComponent,
        TestArticleSelectComponent,
        StandardPhraseChooserComponent,
        JobSubtypeSelectComponent,
        GroupPlaceholderMultiselectComponent,
        JobOrderMultiselectComponent
    ],
    exports: [
        MaterialJobSelectComponent,
        MaterialPoolJobSelectComponent,
        JobMultiselectComponent,
        JobSelectComponent,
        JobAuditReportComponent,
        CreateSampleGroupsModalComponent,
        BulkAssignCohortsModalComponent,
        CreatePlaceholderAssociationModalComponent,
        TestArticleMultiselectComponent,
        TestArticleSelectComponent,
        StandardPhraseChooserComponent,
        JobSubtypeSelectComponent,
        GroupPlaceholderMultiselectComponent,
        JobOrderMultiselectComponent
    ]
})
export class JobsSharedModule {
    static forRoot(): ModuleWithProviders<JobsSharedModule> {
        return {
          ngModule: JobsSharedModule,
          providers: [
                JobLogicService,
                JobService,
                JobVocabService,
                ExportJobDetailService,
                ExportJobAuditService,
                ViewJobAuditReportComponentService,
                CreateSampleGroupsModalService,
                BulkAssignCohortsModalService,
                ReportingSharedModule,
                FileDownloader
          ]
        };
    }
}
