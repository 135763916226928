<div class="form-group">
    <div class="col-md-12">
        <p *ngIf="!isEnhancedDistributionSelected" class="intro">
            Randomly regroup the selected animals to generate new cohorts.
            <em>Generate Cohorts</em> as often as necessary to achieve statistically desirable groupings.
        </p>
        <p *ngIf="isEnhancedDistributionSelected" class="intro">
            Distribute the selected animals to create new cohorts.
            You can draft and redraft cohorts multiple times to achieve statistically desirable groupings.
        </p>
    </div>
</div>

<div class="form-group d-flex col">
    <label class="col-auto col-form-label">Selected Animals</label>
    <div class="col-auto">
        <p class="form-control-plaintext"
            *ngIf="selectedAnimalsValid; else invalidAnimalsBlock">
            {{selectedCohortMaterials.length}}
        </p>
        <ng-template #invalidAnimalsBlock>
            <p class="form-control-plaintext text-warning">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                {{selectedCohortMaterials.length}}
            </p>
        </ng-template>
    </div>
</div>

<!-- Controls -->
<form class="form-group col">
    <!-- ****** Common validation ****** -->
    <!-- Please add a Name to generate a cohort -->
    <small *ngIf="!isNameValid" class="col-auto form-text text-muted mb-2">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{NO_NAME_MESSAGE}}
    </small>
    <!-- Please select at least one animal -->
    <small *ngIf="!isCohortMaterialsSelected" class="col-auto form-text text-muted mb-2">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{NO_COHORT_MATERIALS_SELECTED_MESSAGE}}
    </small>
    <!-- The number of new cohorts cannot be greater than the number of selected animals -->
    <small *ngIf="isCohortMaterialsSelected && !selectedAnimalsValid" class="col-auto form-text text-muted mb-2">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{INVALID_NEW_COHORTS_NUMBER_MESSAGE}}
    </small>

    <!-- ****** Common for Enhanced Distribution and Pair Match validation ****** -->
    <!-- Please adjust the number of animals to be assigned to each split cohort -->
    <small *ngIf="isOutputBasedMethodSelected && areCommonSettingsValid && !isGroupsCountValid" class="col-auto form-text text-muted mb-2">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{INVALID_ANIMALS_TO_COHORT_MESSAGE}}
    </small>
    <!-- Sum of cohort sizes must not exceed the number of animals selected -->
    <small *ngIf="isOutputBasedMethodSelected && areCommonSettingsValid && !isCohortSizeSelectionsValid" class="col-auto form-text text-muted mb-2">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{INVALID_COHORT_SIZE_SELECTION_MESSAGE}}
    </small>

    <!-- ****** Enhanced Distribution ****** -->
    <!-- Please make sure at least one output is selected in the animal chart above -->
    <small *ngIf="isEnhancedDistributionSelected && areCommonSettingsValid && !isCohortOutputsSelectionValid" class="col-auto form-text text-muted mb-2">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{ED_NO_OUTPUTS}}
    </small>
    <!-- Please select at least one output to distribute by -->
    <small *ngIf="isEnhancedDistributionSelected && areCommonSettingsValid && areOutputBasedMethodSettingsValid && !isSelectedOutputsToRandomizeValid" class="col-auto form-text text-muted mb-2">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{ED_INVALID_DISTRIBUTION_MESSAGE}}
    </small>

    <!-- ****** Two-Factor Pair Match validation ****** -->
    <!-- Please make sure Output1 and Output2 are selected in the animal chart above -->
    <small *ngIf="isPairMatchingSelected && areCommonSettingsValid && !isCohortOutputsSelectionValid" class="col-auto form-text text-muted mb-2">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{TFPM_NO_OUTPUTS}}
    </small>
    <!-- Please select more animals for distribution or adjust the number of animals to be assigned to each split cohort -->
    <small *ngIf="isPairMatchingSelected && areCommonSettingsValid && areOutputBasedMethodSettingsValid && !isPairMatchingValid" class="col-auto form-text text-muted mb-2">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{TFPM_INVALID_DISTRIBUTION_MESSAGE}}
    </small>

    <div *ngIf="allowSplitting" class=" form-group d-flex flex-row">
        <div class="col-auto">
            <label class="col-form-label font-weight-bold align-self-center">
                Model
                <info-tooltip style="margin-left: 5px;" [text]="'Select a randomization model. Note, once the split cohorts have been created, the model selection will be locked.'"></info-tooltip>
            </label>
            <div>
                <active-vocab-select *ngIf="newCohorts.length === 0; else methodFixedBlock"
                                     [(model)]="cohort.C_MatchingMethod_key"
                                     (modelChange)="methodChanged()"
                                     [vocabChoices]="matchingMethods"
                                     [keyFormatter]="matchingMethodKeyFormatter"
                                     [optionFormatter]="matchingMethodFormatter"
                                     class="form-control-plaintext">
                </active-vocab-select>
                <ng-template #methodFixedBlock>
                    <p class="form-control-plaintext">
                        {{ cohort.cv_MatchingMethod?.MatchingMethod }}
                    </p>
                </ng-template>
            </div>
        </div>
        <div *ngIf="allowSplitting && isEnhancedDistributionSelected && outputSelectionOptions | notEmpty" class="col-auto">
            <label class="col-form-label text-left font-weight-bold" style="min-width: 100%; display:inline-flex">
                Distribute By
            </label>
            <div class="d-flex form-control-plaintext flex-wrap">
                <checkbox-multi-select class="mr-3"
                                       [isDefaultSelection]="draftCohortsData"
                                       [options]="outputSelectionOptions"
                                       (selectionChange)="onSelectionChange($event)"></checkbox-multi-select>
            </div>
        </div>
    </div>
    <div class="form-group d-flex flex-row">
        <div class="col-auto">
            <label class="col-form-label font-weight-bold">New Cohorts</label>
            <div class="form-group">
                <select name="newCohortCount"
                        [(ngModel)]="newCohortCount"
                        (ngModelChange)="newCohortCountChanged($event)"
                        class="form-control input-medium">
                    <option *ngFor="let number of newCohortCountOptions"
                            [value]="number">
                        {{number}}
                    </option>
                </select>
            </div>
        </div>
        <ng-container *ngIf="allowSplitting && (
            isPairMatchingSelected || isEnhancedDistributionSelected
        )">
            <div class="d-flex flex-row flex-wrap">
                <ng-container *ngFor="let group of groups; index as i">
                    <div>
                        <label class="col-auto col-form-label">Cohort {{i + 1}}</label>
                        <div class="col-auto">
                            <input type="number"
                                   step="1"
                                   name="group{{i}}"
                                   [(ngModel)]="group.count"
                                   (ngModelChange)="groupCountChanged()"
                                   [class.ng-invalid]="!isCohortSizeSelectionsValid"
                                   class="form-control input-small"
                                   integerInput
                                   [min]="0"
                                   [max]="selectedCohortMaterials.length">
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <!-- Pair Matching Table -->
    <div style="margin-top: 15px; margin-left: 15px" class="pair-matching-table"
         *ngIf="allowSplitting && isPairMatchingSelected && categorykeys.length > 0">
        <div class="form-group row">
            <div class="col-md-12">
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th>
                                {{cohort.Output1?.OutputName}}
                            </th>
                            <th>
                                {{cohort.Output2?.OutputName}}
                            </th>
                            <th>Total Animals</th>
                            <th>Select Animals for Distribution</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let category of categorykeys">
                            <th>{{ category.OutputValue1 }}</th>
                            <th>{{ category.OutputValue2 }}</th>
                            <th>{{ category.animals.length }}</th>
                            <th>
                                <select name="animals-selection-{{category.name}}"
                                        class="form-control input-medium"
                                        [(ngModel)]="category.selection"
                                        (ngModelChange)="categorySelectionChanged()">
                                    <option value="0"></option>
                                    <option *ngFor="let number of category.numbers"
                                            [value]="number">
                                        {{number}}
                                    </option>
                                </select>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="form-group col-auto mt-3">
        <button *ngIf="!isEnhancedSplittingMethod" data-automation-id="generate-cohorts-button" type="button"
                class="btn btn-primary"
                [disabled]="!formValid()"
                (click)="initiateSplit()">
            Generate Cohorts
        </button>
        <button *ngIf="isEnhancedSplittingMethod" data-automation-id="draft-cohorts-button" type="button"
                class="btn btn-primary"
                [disabled]="!formValid()"
                (click)="initiateDraft(true)">
            {{ draftCohortsData ? 'Redraft Cohorts' : 'Draft Cohorts'}}
        </button>
        <ng-container *ngIf="isEnhancedSplittingMethod && draftCohortsData">
            <button type="button"
                data-automation-id="confirm-cohorts-button"
                class="btn btn-primary"
                [disabled]="!draftCohortsData && !formValid()"
                (click)="onConfirmCohortsClick()">
                    Confirm Cohorts
            </button>
            <info-tooltip [text]="'You can save drafted cohorts before confirming them.'"></info-tooltip>
        </ng-container>
    </div>
</form>

<ng-container *ngIf="isEnhancedDistributionSelected && newCohorts | notEmpty">
    <div class="d-flex justify-content-around pl-1 pr-1" style="min-width: fit-content; gap: 1rem;">
        <ng-container *ngIf="cohort.Output1 && selectedOutputsToRandomize.includes('OutputValue1')">
            <cohort-output-graph 
                [cohorts]='newCohorts'
                [outputName]="cohort.Output1.OutputName"
                outputValueKey="OutputValue1">
            </cohort-output-graph>
        </ng-container>
        <ng-container *ngIf="cohort.Output2 && selectedOutputsToRandomize.includes('OutputValue2')">
            <cohort-output-graph 
                [cohorts]='newCohorts'
                [outputName]="cohort.Output2.OutputName"
                outputValueKey="OutputValue2">
            </cohort-output-graph>
        </ng-container>
        <ng-container *ngIf="cohort.Output3 && selectedOutputsToRandomize.includes('OutputValue3')">
            <cohort-output-graph 
                [cohorts]='newCohorts' 
                [outputName]="cohort.Output3.OutputName"
                outputValueKey="OutputValue3">
            </cohort-output-graph>
        </ng-container>
    </div>
</ng-container>

<!-- New Cohorts -->
<div class="new-cohorts-container" style="width: min-content; min-width: 100%;" *ngIf="newCohorts | notEmpty">
    <ng-container #splits *ngFor="let newCohort of newCohorts; let index = index;">
        <ng-container *ngIf='newCohort.CohortMaterial | notEmpty'>
            <fieldset *ngIf="cohort.cv_MatchingMethod?.MatchingMethod !== splittingMethod.ENHANCED" class="fieldset-bordered mb-4">
                <legend>Cohort {{index + 1}}</legend>
                <div>
                    <div class="form-group d-flex" [ngClass]="cohortNameField.hasError('unique') ? 'has-error' : ''">
                        <label class="col-2 col-form-label">Name</label>
                        <div class="col">
                            <input #cohortNameField="ngModel"
                                type="text"
                                name="newCohortName{{index}}"
                                [(ngModel)]="newCohort.CohortName"
                                required
                                validateUnique
                                uniqueEntity="Cohorts"
                                uniqueProperty="CohortName"
                                [entityPK]="newCohort.C_Cohort_key"
                                entityPKName="C_Cohort_key"
                                validateInitialState="true"
                                maxlength="{{COHORT_NAME_LENGTH_MAX}}"
                                class="form-control input-medium" 
                                (ngModelChange)="triggerCohortNameValidation()"/>
        
                            <small class="form-text text-muted"
                            *ngIf="cohortNameField.errors?.unique">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                This cohort name already exists.
                            </small>
                        </div>
                    </div>
    
                    <div class="form-group d-flex" *ngIf="(cohort.cv_MatchingMethod?.MatchingMethod !== splittingMethod.PAIR_MATCHING) || (allowSplitting && isPairMatchingSelected)">
                        <label class="col-2 col-form-label">Animals</label>
                        <div class="col">
                            <p class="form-control-plaintext">
                                {{newCohort?.CohortMaterial?.length}}
                            </p>
                        </div>
                    </div>
        
                    <div class="form-group row mt-4" *ngIf="allowSplitting && isPairMatchingSelected && newCohort.CohortMaterial.length > 0">
                        <div class="col-md-12">
                            <table class="table table-sm table-borderless table-nonfluid">
                                <thead>
                                    <tr class="border-bottom">
                                        <th colspan="3"></th>
                                        <th>
                                            {{cohort.Output1?.OutputName}}
                                        </th>
                                        <th>
                                            {{cohort.Output2?.OutputName}}
                                        </th>
                                        <th>
                                            {{cohort.Output3?.OutputName}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody [dragula]="'cohort-animals'"
                                    [dragulaModel]="newCohort.CohortMaterial"
                                    [attr.data-cohort]="newCohort.C_Cohort_key">
                                    <ng-container *ngIf="newCohort.CohortMaterial.length">
                                        <tr *ngFor="let material of newCohort.CohortMaterial; trackBy: trackRow" [attr.data-key]="material.C_CohortMaterial_key">
                                            <!-- Drag Handle -->
                                            <td class="icon-cell">
                                                <i *ngIf="material.C_CohortMaterial_key < 0"
                                                class="fa fa-sort text-muted"
                                                [ngClass]="{ 'draggable': material.C_CohortMaterial_key < 0 }"
                                                    title="Drag to reorder"></i>
                                            </td>
                                            <td>
                                                <a *ngIf="material.C_CohortMaterial_key < 0" (click)="removeMaterialFromCohort(material)">
                                                    <i class="fa fa-remove remove-item"
                                                    title="Remove '{{material.Material?.Animal?.AnimalName}}' animal"></i>
                                                </a>
                                            </td>
                                            <td>{{material.Material?.Animal?.AnimalName}}</td>
                                            <td>{{material.OutputValue1}}</td>
                                            <td>{{material.OutputValue2}}</td>
                                            <td>{{material.OutputValue3}}</td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="!newCohort.CohortMaterial.length">
                                        <tr>
                                            <td class="icon-cell">
                                                <i
                                                class="fa fa-sort text-muted"
                                                [ngClass]="{ 'draggable': false }"
                                                    title="Drag to reorder"></i>
                                            </td>
                                            <td>
                                                <i>No animals to display. Drop some animals to the table.</i>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>   
        
                    <div class="d-flex mt-3">
                        <ng-container *ngIf="cohort.Output1 || cohort.Output2 || cohort.Output3">
                            <div class="p-1 d-flex flex-wrap align-items-center">
                                <div class="d-flex flex-column form-group p-2">
                                    <label>Output Statistics</label>
                                    <table class="table table-sm table-borderless table-nonfluid">
                                        <thead>
                                            <tr class="border-bottom">
                                                <th></th>
                                                <th class="text-right"
                                                    *ngFor="let selectedOutput of [cohort.Output1, cohort.Output2, cohort.Output3]">
                                                    <span *ngIf="selectedOutput">
                                                        {{selectedOutput?.OutputName}}
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody cohort-output-stats-rows
                                            [cohort]="newCohort"
                                            [maxOutputs]="maxOutputs"
                                            [columnsReadWrite]="0"
                                            [columnsTotal]="maxOutputs + 1"
                                            [readonly]="false"></tbody>
                                    </table>
                                </div>
                            </div>
                        </ng-container> 
                    </div>  
                </div> 
            </fieldset>
            <fieldset *ngIf="isEnhancedDistributionSelected" class="fieldset-bordered p-0 mb-4">  
                <div class="d-flex">
                    <div class="d-flex flex-column flex-grow-1 border-right">
                        <div [ngClass]="{'pb-3': draftCohortsData && !isConfirmClicked}" class="d-flex justify-content-between pt-3 pl-2 pr-2">
                            <editable-label 
                                [index]="index"
                                [(model)]="newCohort.CohortName"
                                (valid)="updateNewCohorts()"
                                uniqueEntity="Cohorts"
                                uniqueProperty="CohortName">
                            </editable-label>
                            <div class="d-flex">
                                <small *ngIf="!isConfirmClicked" class="confirm-cohorts-text">
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    <span>You must confirm drafted cohorts before housing them</span>
                                </small>
                                <ng-container *ngIf="isConfirmClicked">
                                    <label class="mr-1 font-weight-bold align-self-center">Housing</label>
                                    <text-slider-button
                                        [(model)]="templates?.options.housingOptions.show"
                                        (modelChange)="onSaveConfig()"
                                        trueText="Show"
                                        falseText="Hide">
                                    </text-slider-button>
                                </ng-container>
                            </div>
                        </div>
                        <div class="d-flex h-100">
                            <div class="d-flex">
                                <ng-container *ngIf="cohort.Output1 || cohort.Output2 || cohort.Output3">
                                    <div class="border-top p-1 d-flex flex-wrap align-items-center">
                                        <div class="d-flex flex-column form-group p-2">
                                            <label>Output Statistics</label>
                                            <table class="table table-sm table-borderless table-nonfluid">
                                                <thead>
                                                    <tr class="border-bottom">
                                                        <th></th>
                                                        <th class="text-nowrap"
                                                            *ngFor="let selectedOutput of [cohort.Output1, cohort.Output2, cohort.Output3]">
                                                            <span *ngIf="selectedOutput">
                                                                {{selectedOutput?.OutputName}}
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody cohort-output-stats-rows
                                                    [cohort]="newCohort"
                                                    [maxOutputs]="maxOutputs"
                                                    [columnsReadWrite]="0"
                                                    [columnsTotal]="maxOutputs + 1"
                                                    [readonly]="false"></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-container> 
                            </div>
                            <cohort-split-table
                                class='flex-grow-1'
                                [facet]="facet"
                                [cohort]='newCohort'
                                [columns]='templates.options.columns'
                                (cohortChange)="updateNewCohorts()"
                                (columnSelectChange)="onSaveConfig()">
                            </cohort-split-table> 
                        </div>
                    </div>
                    <div class="p-2" [hidden]="!templates?.options?.housingOptions?.show || !(isEnhancedDistributionSelected && isConfirmClicked)">
                        <cohort-housing
                            class="d-block"
                            style="width: 275px;"
                            [facet]="facet"
                            [cohortMaterials]="newCohort.CohortMaterial"
                            [options]="templates.options.housingOptions"
                            (change)="onSaveConfig()"
                        ></cohort-housing>
                    </div>
                </div>
            </fieldset>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isEnhancedDistributionSelected">
        <ng-container *ngFor="let cohort of [excludedCohort]">
            <fieldset class="fieldset-bordered p-0" *ngIf='cohort?.CohortMaterial | notEmpty'>
                <div class="pt-3 pl-2">
                    <editable-label 
                        [model]="cohort.CohortName"
                        [isEditable]="false"></editable-label>
                </div>
        
                <div class="border-top d-flex mt-4">
                    <ng-container *ngIf="cohort.Output1 || cohort.Output2 || cohort.Output3">
                        <div class="p-1 d-flex flex-wrap align-items-center">
                            <table class="table table-sm table-borderless table-nonfluid">
                                <thead>
                                    <tr class="border-bottom">
                                        <th></th>
                                        <th class="text-right"
                                            *ngFor="let selectedOutput of [cohort.Output1, cohort.Output2, cohort.Output3]">
                                            <span *ngIf="selectedOutput">
                                                {{selectedOutput?.OutputName}}
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody cohort-output-stats-rows
                                    [cohort]="cohort"
                                    [maxOutputs]="maxOutputs"
                                    [columnsReadWrite]="0"
                                    [columnsTotal]="maxOutputs + 1"
                                    [readonly]="false"></tbody>
                            </table>
                        </div>
                    </ng-container>
                    <cohort-split-table
                        class="flex-grow-1"
                        [facet]="facet"
                        [cohort]="cohort"
                        [columns]="templates.options.columns"
                        (columnSelectChange)="onSaveConfig()">
                    </cohort-split-table>  
                </div>    
            </fieldset>
        </ng-container>
    </ng-container>
</div>


<cohort-split-table-templates #templates
    [facet]="facet" 
    [cohort]="cohort" 
    (cohortChange)="updateNewCohorts()">
</cohort-split-table-templates>
