import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

@Component({
    selector: 'animal-name-range-select',
    template: `
    <div class="animal-name-range-select">
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="animalNameFormatter"
            [keyFormatter]="animalNameSortableFormatter"
            [search]="searchAnimals"
            [keySearch]="searchAnimalsByNameSortable"
            [placeholder]="placeholder"
            [required]="required"
            [namespace]="'animal'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `,
    styles: [`
        .animal-name-range-select {
            min-width: 100px;
        }
    `]
})
export class AnimalNameRangeSelectComponent {
    @Input() model: any;
    @Input() placeholder: string;
    @Input() required: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private searchService: SearchService
    ) {
        //
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchAnimals = (search: string): Promise<any[]> => {
        let filter = {
            AnimalName: search
        };
        let count = 50;

        return this._searchAnimals(filter, count);
    }

    searchAnimalsByNameSortable = (search: string): Promise<any[]> => {
        let filter = {
            AnimalNameSortable: search
        };
        let count = 1;

        return this._searchAnimals(filter, count);
    }

    private _searchAnimals(animalFilter: any, count: number): Promise<any[]> {
        let searchQueryDef: SearchQueryDef = {
            entity: 'Animals',
            page: 1,
            size: count,
            sortColumn: 'AnimalNameSortable',
            sortDirection: 'asc',
            filter: animalFilter
        };

        return this.searchService.getEntitiesBySearch(searchQueryDef)
            .then((results: any) => {
                return results.data.map((animal: any) => {
                    // strip away all but the essential attributes from animal
                    // so that it can be easily serialized when facet is saved
                    return {
                        AnimalName: animal.AnimalName,
                        AnimalNameSortable: animal.AnimalNameSortable,
                    };
                });
            });
    }

    animalNameFormatter = (item: any) => {
        return item.AnimalName;
    }

    animalNameSortableFormatter = (item: any) => {
        return item.AnimalNameSortable;
    }
}
