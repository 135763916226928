<form role="form" class="form-label-right">

    <div class="modal-header justify-content-center">
        <h4 class="modal-title">Samples Filter</h4>
    </div>

    <div class="modal-body">

        <div class="form-group row">
            <div class="col-md-2">
                <label class="col-form-label" style="display: block;">Name</label>
                <div class="text-right">
                    <one-many-toggle #nameToggle></one-many-toggle>
                </div>
            </div>
            <div class="col-md-4">
                <multi-control-wrap [toggleComponent]="nameToggle"
                                    [(singleModel)]="filter.SampleName"
                                    [(manyModel)]="filter.SampleNames">
                    <multi-control name="single">
                        <input type="text" name="sampleName"
                               [(ngModel)]="filter.SampleName"
                               class="form-control input-medium"
                               data-auto-focus="sampleName" />
                        <small class="form-text text-muted">
                            Find names <em>containing</em> this value.
                        </small>
                    </multi-control>
                    <multi-control name="many">
                        <multi-paste-input [(model)]="filter.SampleNames"
                                           [limit]="MULTI_PASTE_INPUT_LIMIT"
                                           [rows]="3"></multi-paste-input>
                        <small class="form-text text-muted">
                            Names separated by
                            <code>,</code> or <code>Enter</code>.
                            Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                        </small>
                    </multi-control>
                </multi-control-wrap>
            </div>
            <label class="col-md-2 col-form-label">ID</label>
            <div class="col-md-4">
                <multi-paste-input [(model)]="filter.Identifiers"
                                   [isNumeric]="true"
                                   [limit]="MULTI_PASTE_INPUT_LIMIT"
                                   [rows]="3"></multi-paste-input>
                <small class="form-text text-muted">
                    IDs separated by
                    <code>,</code> or <code>Enter</code>.
                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                </small>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Source Name</label>
                    <div class="col-md-8">
                        <input type="text"
                                name="sourceName"
                                [(ngModel)]="filter.SourceName"
                                class="form-control input-medium" />
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label">Source Microchip ID</label>
                        <div class="text-right">
                            <one-many-toggle #sourceMicrochipIdentifierToggle></one-many-toggle>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <multi-control-wrap [toggleComponent]="sourceMicrochipIdentifierToggle"
                                    [(singleModel)]="filter.sourceMicrochipIdentifier"
                                    [(manyModel)]="filter.sourceMicrochipIdentifiers">
                            <multi-control name="single">
                                <input type="text" name="sourceMicrochipIdentifier"
                                    [(ngModel)]="filter.sourceMicrochipIdentifier"
                                    class="form-control input-medium"
                                    data-auto-focus="sourceMicrochipIdentifier" />
                            </multi-control>
                            <multi-control name="many">
                                <multi-paste-input [(model)]="filter.sourceMicrochipIdentifiers"
                                    [isNumeric]="false"
                                    [limit]="MULTI_PASTE_INPUT_LIMIT"
                                    [rows]="3"></multi-paste-input>
                                <small class="form-text text-muted">
                                    IDs separated by
                                    <code>,</code> or <code>Enter</code>.
                                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                                </small>
                            </multi-control>
                        </multi-control-wrap>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Preservation Method</label>
                    <div class="col-md-8">
                        <select name="preservationMethodKey"
                                [(ngModel)]="filter.C_PreservationMethod_key"
                                class="form-control input-medium">
                            <option></option>
                            <option *ngFor="let item of preservationMethods"
                                    [value]="item.C_PreservationMethod_key">
                                {{item.PreservationMethod}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Type</label>
                    <div class="col-md-8">
                        <climb-multi-select [(model)]="filter.C_SampleType_keys"
                                            [values]="sampleTypes"
                                            [keyProperty]="'C_SampleType_key'"
                                            [valueProperty]="'SampleType'"
                                            [placeholder]="'Select types...'"></climb-multi-select>
                    </div>
                </div>
            </div>
            <label class="col-md-2 col-form-label">Microchip ID</label>
            <div class="col-md-4">
                <multi-paste-input [(model)]="filter.microchipIdentifiers"
                                   [isNumeric]="false"
                                   [limit]="MULTI_PASTE_INPUT_LIMIT"
                                   [rows]="3"></multi-paste-input>
                <small class="form-text text-muted">
                    IDs separated by
                    <code>,</code> or <code>Enter</code>.
                    Limit: {{MULTI_PASTE_INPUT_LIMIT}}.
                </small>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Subtype</label>
                    <div class="col-md-8">
                        <select name="sampleSubtypeKey"
                                [(ngModel)]="filter.C_SampleSubtype_key"
                                class="form-control input-medium">
                            <option></option>
                            <option *ngFor="let item of sampleSubtypes"
                                    [value]="item.C_SampleSubtype_key">
                                {{item.SampleSubtype}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Processing</label>
                    <div class="col-md-8">
                        <select name="processingMethodKey"
                                [(ngModel)]="filter.C_SampleProcessingMethod_key"
                                class="form-control input-medium">
                            <option></option>
                            <option *ngFor="let item of sampleProcessingMethods"
                                    [value]="item.C_SampleProcessingMethod_key">
                                {{item.SampleProcessingMethod}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Send To</label>
                    <div class="col-md-8">
                        <input type="text"
                               name="sendTo"
                               maxlength="150"
                               [(ngModel)]="filter.SendTo"
                               class="form-control input-medium" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Analysis</label>
                    <div class="col-md-8">
                        <select name="sampleAnalysisMethodKey"
                                [(ngModel)]="filter.C_SampleAnalysisMethod_key"
                                class="form-control input-medium">
                            <option></option>
                            <option *ngFor="let item of sampleAnalysisMethods"
                                    [value]="item.C_SampleAnalysisMethod_key">
                                {{item.SampleAnalysisMethod}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>


        <div class="form-group row">
                <label class="col-md-2 col-form-label">Time Point</label>
                <div class="col-md-2">
                    <input type="number"
                           min="0"
                           step="any"
                           name="timePoint"
                           [(ngModel)]="filter.TimePoint"
                           decimalFormatter
                           [decimalPlaces]="3"
                           class="form-control input-medium" />
                </div>
                <div class="col-md-2">
                    <select name="timeUnitKey"
                            [(ngModel)]="filter.C_TimeUnit_key"
                            class="form-control input-medium">
                        <option></option>
                        <option *ngFor="let item of timeUnits"
                                [value]="item.C_TimeUnit_key">
                            {{item.TimeUnit}}
                        </option>
                    </select>
                </div>
            </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Status</label>
            <div class="col-md-4">
                <climb-multi-select [(model)]="filter.C_SampleStatus_keys"
                                    [values]="sampleStatuses"
                                    [keyProperty]="'C_SampleStatus_key'"
                                    [valueProperty]="'SampleStatus'"
                                    [placeholder]="'Select statuses...'"></climb-multi-select>
            </div>
            <label class="col-md-2 col-form-label">{{'Job' | translate}} Name</label>
            <div class="col-md-4">
                <job-multiselect [(model)]="filter.Jobs"></job-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Location</label>
            <div class="col-md-4">
                <input type="text"
                       name="location"
                       [(ngModel)]="filter.Location"
                       class="form-control input-medium" />
            </div>
            <label class="col-md-2 col-form-label">Species</label>
            <div class="col-md-4">
                <select name="taxonKey"
                        [(ngModel)]="filter.C_Taxon_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of taxons"
                            [value]="item.C_Taxon_key">
                        {{item.CommonName}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Harvest Date Range</label>
            <div class="col-md-2">
                <climb-ngb-date #dateControl="ngModel"
                                name="Harvest Date Start"
                                [(ngModel)]="filter.DateHarvestStart"
                                [placeholder]="'From&hellip;'"
                                [required]="false"></climb-ngb-date>
            </div>
            <div class="col-md-2">
                <climb-ngb-date #dateControl="ngModel"
                                name="Harvest Date End"
                                [(ngModel)]="filter.DateHarvestEnd"
                                [placeholder]="'To&hellip;'"
                                [required]="false"></climb-ngb-date>
            </div>
             <label class="col-md-2 col-form-label">Construct</label>
            <div class="col-md-4">
                <construct-multiselect [(model)]="filter.constructs"></construct-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">{{'Line' | translate}}</label>
            <div class="col-md-10">
                <line-multiselect [(model)]="filter.lines"></line-multiselect>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">{{'Study' | translate}}</label>
            <div class="col-md-10">
                <study-select [(model)]="filter.C_Study_key"
                              [excludeLocked]="false"></study-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Order</label>
            <div class="col-md-10">
                <select name="order"
                        [(ngModel)]="filter.C_SampleOrder_key"
                        class="form-control input-medium">
                    <option></option>
                    <option *ngFor="let item of sampleOrders"
                            [value]="item.C_SampleOrder_key">
                        {{item.Order.OrderID}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Lot Number</label>
            <div class="col-md-10">
                <input type="text"
                       name="lotNumber"
                       [(ngModel)]="filter.LotNumber"
                       class="form-control input-medium" />
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <span class="btn-set">
            <button type="submit"
                    class="btn btn-fw-wide btn-lg btn-primary"
                    (click)="filterClicked($event)">
                Filter
            </button>
            <button data-automation-id="clear-filter-button"
                    type="button"
                    class="btn btn-fw-wide btn-lg btn-secondary"
                    (click)="clearClicked()">
                Clear Filter
            </button>
        </span>
        <span class="btn-set">
            <button type="button"
                    class="btn btn-lg btn-secondary"
                    (click)="closeClicked()">
                Close
            </button>
        </span>
    </div>
</form>
