<!-- Table for Institutions -->
<div class="form-group row">
    <label class="col-sm-2 col-form-label"
           [ngClass]="{'required-text' : required}">{{'Institution' | translate }}s</label>
    <div class="col-sm-10">
        <table class="table">
            <thead>
                <tr>
                    <th class="icon-cell col-size-fit">
                        <a (click)="addJobInstitution(job)"
                           class="add-item-link"
                           *ngIf="!readonly">
                            <i class="fa fa-plus-circle" title="Add Institution and Site"></i>
                        </a>
                    </th>
                    <th>{{'Institution' | translate}}</th>
                    <th>Site</th>
                    <th *ngIf="showSiteContact">Scientific Contact</th>
                    <th *ngIf="showSiteContact">Billing Contact</th>
                    <th *ngIf="showSiteContact">Authorization Contact</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let jobInstitution of job.JobInstitution">
                    <td>
                        <a *ngIf="!readonly" (click)="removeInstitution(jobInstitution)">
                            <i class="fa fa-fw fa-remove remove-item"
                               title="Delete {{'Institution' | translate}}"></i>
                        </a>
                    </td>
                    <td>
                        <institution-select [(model)]="jobInstitution.C_Institution_key"
                                            (modelChange)="institutionChanged($event, jobInstitution)"
                                            [required]="required">
                        </institution-select>
                    </td>
                    <td>
                        <select name="Site"
                                #name="ngModel"
                                [(ngModel)]="jobInstitution.C_Site_key"
                                [disabled]="!jobInstitution.C_Institution_key || jobInstitution.SelectedSites?.length === 0"
                                (ngModelChange)="siteChanged($event, jobInstitution)"
                                class="form-control input-medium">
                            <option></option>
                            <option *ngFor="let item of jobInstitution.SelectedSites"
                                    [value]="item.C_Site_key">
                                {{item.Name}}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="showSiteContact">
                        <select *ngIf="!isCRL"
                                name="ScientificContact"
                                #name="ngModel"
                                [(ngModel)]="jobInstitution.C_ScientificContactPerson_key"
                                [disabled]="!jobInstitution.C_Site_key"
                                class="form-control input-medium">
                            <option></option>
                            <ng-container *ngFor="let item of jobInstitution.Site?.ContactPerson">
                                <option *ngIf="item.IsActive" [value]="item.C_ContactPerson_key">
                                    {{item.FirstName}} {{item.LastName}}
                                </option>
                            </ng-container>
                        </select>

                        <select *ngIf="isCRL"
                                name="ScientificContact"
                                #name="ngModel"
                                [(ngModel)]="jobInstitution.C_ScientificContactPerson_key"
                                [disabled]="!jobInstitution.C_Institution_key"
                                class="form-control input-medium">
                            <option></option>
                            <ng-container *ngFor="let item of jobInstitution.InstitutionContacts">
                                <option *ngIf="item.IsActive" [value]="item.C_ContactPerson_key">
                                    {{item.FirstName}} {{item.LastName}}
                                </option>
                            </ng-container>
                        </select>
                    </td>
                    <td *ngIf="showSiteContact">
                        <select *ngIf="!isCRL"
                                name="BillingContact"
                                #name="ngModel"
                                [(ngModel)]="jobInstitution.C_BillingContactPerson_key"
                                [disabled]="!jobInstitution.C_Site_key"
                                class="form-control input-medium">
                            <option></option>
                            <ng-container *ngFor="let item of jobInstitution.Site?.ContactPerson">
                                <option *ngIf="jobInstitution.C_JobInstitution_key > -1 || item.IsActive"
                                        [value]="item.C_ContactPerson_key">
                                    {{item.FirstName}} {{item.LastName}}
                                </option>
                            </ng-container>
                        </select>

                        <institution-contact-multiselect *ngIf="isCRL"
                                            [jobInstitutionKey]="jobInstitution.C_JobInstitution_key"
                                            [institutionKey]="jobInstitution.C_Institution_key"
                                            [disabled]="!jobInstitution.C_Institution_key || jobInstitution.SelectedContacts.length >= 5"
                                            [(model)]="jobInstitution.SelectedContacts"
                                            (isRemove)="isRemoveBillingContact=$event"
                                            (updatedContact)="institutionBillingContactChanged($event, jobInstitution)">
                        </institution-contact-multiselect>
                    </td>
                    <td *ngIf="showSiteContact">
                        <select *ngIf="!isCRL"
                                name="AuthorizationContact"
                                #name="ngModel"
                                [(ngModel)]="jobInstitution.C_AuthorizationContactPerson_key"
                                [disabled]="!jobInstitution.C_Site_key"
                                class="form-control input-medium">
                            <option></option>
                            <ng-container *ngFor="let item of jobInstitution.Site?.ContactPerson">
                                <option *ngIf="jobInstitution.C_JobInstitution_key > -1 || item.IsActive"
                                        [value]="item.C_ContactPerson_key">
                                    {{item.FirstName}} {{item.LastName}}
                                </option>
                            </ng-container>
                        </select>

                        <select *ngIf="isCRL"
                                name="AuthorizationContact"
                                #name="ngModel"
                                [(ngModel)]="jobInstitution.C_AuthorizationContactPerson_key"
                                [disabled]="!jobInstitution.C_Institution_key"
                                class="form-control input-medium">
                            <option></option>
                            <ng-container *ngFor="let item of jobInstitution.InstitutionContacts">
                                <option *ngIf="item.IsActive" [value]="item.C_ContactPerson_key">
                                    {{item.FirstName}} {{item.LastName}}
                                </option>
                            </ng-container>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
