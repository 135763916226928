const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';

@Component({
    selector: 'test-article-select',
    template: `
    <div>
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="testArticleFormatter"
            [keyFormatter]="testArticleKeyFormatter"
            [search]="searchTestArticles"
            [keySearch]="searchTestArticlesByKey"  
            [required]="required"
            [disabled]="disabled"
            [placeholder]="placeholder"
            [namespace]="'testArticle'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `
})
export class TestArticleSelectComponent implements OnInit {
    @Input() model: any;
    @Input() excludeLocked: boolean = false;
    @Input() required: boolean;
    @Input() disabled: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    placeholder: string;

    constructor(
        private searchService: SearchService
    ) {
    }

    ngOnInit() {
        this.placeholder = 'Test Article' +
            ELLIPSIS;
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchTestArticles = (search: string): Promise<any[]> => {

        let filter: any = {
            TestArticle: search
        };

        if (this.excludeLocked) {
            filter.IsLocked = false;
        }

        return this._searchTestArticles(filter, 50);
    }

    searchTestArticlesByKey = (key: any): Promise<any[]> => {
        let filter = {
            TestArticleKey: key
        };
        return this._searchTestArticles(filter, 1);
    }

    _searchTestArticles(thisFilter: any, count: number): Promise<any[]> {
        let searchQueryDef: SearchQueryDef = {
            entity: 'cv_TestArticles',
            page: null,
            size: count,
            sortColumn: 'TestArticle',
            sortDirection: 'asc',
            isActive: true,
            filter: thisFilter
        };     
        return this.searchService.getEntitiesBySearch(searchQueryDef).then((results: any) => {
            return results.data;
        });
    }

    testArticleKeyFormatter = (value: any) => {
        return value.TestArticleKey;
    }
    testArticleFormatter = (value: any) => {
        return value.TestArticle;
    }
}
