<div class="cro-task-selector">
    <div class="row">
        <div class="col-1 text-center" style="margin-top: 20px; padding-right: 0;">
            Task
            <div style="margin-top: 20px;">
                <a class="add-item-link" (click)="addTask()"><i class="fa fa-plus-circle" title="Add new task"></i></a>
            </div>
        </div>
        <div class="col-11" style="padding-left: 0;">
            <fieldset class="fieldset-bordered" *ngFor="let task of currentTasks; let i = index;">
                <div class="row no-gutters">
                    <div [ngClass]="i > 0 ? 'col-11': 'col-12'">
                        <!-- Task selector -->
                        <task-select data-automation-id="task-name-dropdown" 
                                     [(model)]="task.taskKey" 
                                     (modelChange)="taskChanged(task)" 
                                     [showActiveOnly]="true"
                                     [required]="true">
                        </task-select>
                    </div>
                    <div class="col-1 d-flex align-items-center">
                        <a *ngIf="i > 0" class="remove-item-link ml-3" (click)="removeTask(i)"><i class="fa fa-remove"
                                title="Remove task"></i></a>
                    </div>
                    <div class="col-12 mt-1">
                        <!-- Frequency Settings -->
                        <table class="table table-borderless">
                            <thead>
                                <tr>
                                    <th>Frequency</th>
                                    <th>Offset
                                        <info-tooltip [text]="'The value selected determines the number of times this task will be scheduled on its first occurrence. Leaving the selection blank will assign the value without an offset.'">
                                        </info-tooltip>
                                    </th>
                                    <th>Occurrences</th>
                                    <th>To End</th>
                                    <th>Pattern</th>
                                    <th>Time Difference</th>
                                    <th>Time Relation</th>
                                    <th>Relation to</th>
                                    <th>Anchor</th>
                                    <th>Day</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <active-vocab-select [(model)]="task.C_Frequency_key"
                                            (modelChange)="frequencyChanged(task)" [vocabChoices]="task.frequencies"
                                            [fullWidth]="false" [required]="true" [keyFormatter]="frequencyKeyFormatter"
                                            [optionFormatter]="frequencyFormatter"></active-vocab-select>
                                    </td>
                                    <td>
                                        <!-- a select dropdown with values from 1 to 3 -->
                                        <select [(ngModel)]="task.offset" (ngModelChange)="offsetChanged(task)"
                                            class="form-control"
                                            [disabled]="!task.offsets.length || task.TaskCount === 1">
                                            <option [ngValue]="0"></option>
                                            <option *ngFor="let offset of task.offsets" [value]="offset">{{offset}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input [(ngModel)]="task.TaskCount" (ngModelChange)="taskCountChanged(task)"
                                            [required]="!task.ToEnd" type="number" max="999" min="1" step="1"
                                            class="form-control input-small" name="task-count" />
                                    </td>
                                    <td>
                                        <input type="checkbox" name="isToEnd_{{task.SortOrder}}"
                                            [(ngModel)]="task.ToEnd" (ngModelChange)="taskToEndChanged(task)" />
                                    </td>
                                    <td>
                                        <div class="dropdown">
                                            <a class="dropdown-toggle" data-toggle="dropdown" role="button"
                                                aria-haspopup="true" aria-expanded="false">
                                                <span class="position-name">Adjust Pattern</span>
                                            </a>
                                            <div class="dropdown-menu overflow-auto" onClick="event.stopPropagation();"
                                                style="padding:10px; width: 275px !important; height: 500px;">
                                                <div class="text-center">
                                                    {{ timeUnitMap[task.C_TimeUnit_key] }}
                                                </div>
                                                <div class="full-width text-center"
                                                    style="width: 14.285%; display: inline-block;"
                                                    *ngFor="let day of task.Days; let i = index;">
                                                    <div *ngIf="!isGLP">
                                                        {{i}}
                                                    </div>
                                                    <div *ngIf="isGLP">
                                                        {{i + 1}}
                                                    </div>
                                                    <button [ngClass]="day > 0 ? 'btn-primary' : 'btn-secondary'"
                                                        type="button" class="btn task-complete-button"
                                                        (click)="selectDay(task, day, i)" style="margin: 5px;">
                                                        <i class="fa fa-check" title="Mark selected"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <select name="timeDifference" [(ngModel)]="task.TimeDifference"
                                            class="form-control input-medium" [disabled]="!task.timeDifferences.length"
                                            [required]="task.timeDifferences.length">
                                            <option *ngFor="let item of task.timeDifferences" [ngValue]="item">
                                                {{item}} hrs
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <active-vocab-select [(model)]="task.C_TimeRelation_key"
                                            [readonly]="task.RelatedTo == 'study_start_day'" [fullWidth]="false"
                                            [vocabChoices]="timeRelations" [keyFormatter]="timeRelationsKeyFormatter"
                                            [optionFormatter]="timeRelationsTypeFormatter"></active-vocab-select>
                                    </td>
                                    <td>
                                        <active-vocab-select [(model)]="task.C_ScheduleType_key"
                                            [readonly]="task.RelatedTo == 'study_start_day'" [required]="true"
                                            [fullWidth]="false" [vocabChoices]="scheduleTypes"
                                            [keyFormatter]="scheduleTypesKeyFormatter"
                                            [optionFormatter]="scheduleTypesFormatter"></active-vocab-select>
                                    </td>
                                    <td>
                                        <select name="anchorTask" [(ngModel)]="task.RelatedTo" [required]="true"
                                            class="form-control input-medium">
                                            <ng-container *ngFor="let item of currentTasks; let i = index;">
                                                <option value="" hidden></option>
                                                <option *ngIf="item.taskKey > 0 && item.taskKey !== task.taskKey && item.RelatedTo !== currentTasks.indexOf(task)"
                                                    [ngValue]="i">
                                                    {{ taskKeyNameMap[item.taskKey] }}
                                                </option>
                                            </ng-container>
                                            <option value="study_start_day">Study Day</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select *ngIf="task.RelatedTo > -1 && currentTasks[task.RelatedTo]" name="AnchorDay"
                                                [(ngModel)]="task.AnchorDay" class="form-control input-medium"
                                                (ngModelChange)="startDayChanged(task)" [required]="true">
                                            <ng-container *ngFor="let item of currentTasks[task.RelatedTo].Days; let i = index;">
                                                <option *ngIf="item > 0" [ngValue]="i">
                                                    <div *ngIf="!isGLP">
                                                        {{ i }} Day
                                                    </div>
                                                    <div *ngIf="isGLP">
                                                        {{ i + 1 }} Day
                                                    </div>
                                                </option>
                                            </ng-container>
                                        </select>

                                        <div *ngIf="task.RelatedTo === 'study_start_day'">
                                            <input [(ngModel)]="task.StartDay" (ngModelChange)="startDayChanged(task)"
                                                   [required]="true" type="number"
                                                   [max]="maxDays" [min]="minDays" step="1"
                                                   *ngIf="task.RelatedTo === 'study_start_day'" name="AnchorDay"
                                                   class="form-control input-small" name="task-count" />
                                            <label style="font-size: 9px">Max value is 150</label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12" *ngIf="task.TaskCount > 0 && task.C_Frequency_key > 0">
                        <!-- Calendar -->
                        Calendar
                        <a (click)="task.calendarExpand = !task.calendarExpand" class="calendar-chevron-link">
                            <i class="fa" [ngClass]="task.calendarExpand ? 'fa-chevron-up' : 'fa-chevron-down'"
                                [title]="(task.calendarExpand ? 'Hide ' : 'Show ')" aria-hidden="true"></i>
                        </a>
                        <a style="float:right;" (click)="downloadCalendar(i, task)" class="calendar-download-link">
                            <i class="fa fa-download" aria-hidden="true"></i>
                        </a>
                        <fieldset class="fieldset-bordered" id="task-calendar-{{i}}" *ngIf="task.calendarExpand">
                            <table class="table table-bordered" *ngFor="let week of task.WeekWise; let i = index;">
                                <thead>
                                    <tr class="detail-grid-header">
                                        <th>
                                            Day
                                        </th>
                                        <th *ngFor="let row of week; let index = index">
                                            <div *ngIf="!isGLP">
                                                {{ i * 28 + index }}
                                            </div>
                                            <div *ngIf="isGLP">
                                                {{ i * 28 + index + 1 }}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="task.Weeks.length > 0">
                                        <td>Week</td>
                                        <td colspan="7" class="text-center"
                                            *ngFor="let row of task.MultipleWeeks[i]; let weekIndex = index;">
                                            {{ (i * 4) + (weekIndex + 1) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Task Count
                                        </td>
                                        <td *ngFor="let row of week">{{ row }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                    <div class="col-12" *ngIf="task.inputs.length > 0">
                        <!-- Inputs -->
                        Inputs
                        <a (click)="task.inputsExpand = !task.inputsExpand" class="toggle-inputs-link">
                            <i class="fa" [ngClass]="task.inputsExpand ? 'fa-chevron-up' : 'fa-chevron-down'"
                                [title]="(task.inputsExpand ? 'Hide ' : 'Show ')" aria-hidden="true"></i>
                        </a>
                        <fieldset class="fieldset-bordered form-label-right" *ngIf="task.inputsExpand">
                            <div class="form-group row" *ngFor="let input of task.inputs; let ix = index"
                                [attr.data-index]="ix">
                                <label for="task-input-input-{{ix}}-{{domIdAddition}}"
                                    class="col-md-3 col-form-label">{{input.InputName}}</label>
                                <div class="col-md-9">
                                    <data-type-input *ngIf="isRegularInput(input)"
                                        [id]="'task-input-input-' + ix + '-' + domIdAddition" [ioObject]="input"
                                        [readonly]="false" [(value)]="task.inputValues[input.C_Input_key]">
                                    </data-type-input>
                                    <input *ngIf="isDosingTableInput(input)" type="text"
                                        id="'task-input-input-' + ix + '-' + domIdAddition"
                                        [ngModelOptions]="{standalone: true}" class="form-control input-medium"
                                        [(ngModel)]="input.cv_DosingTable.DosingTable" disabled />
                                    <input *ngIf="isJobCharacteristicInput(input)" type="text"
                                        id="'task-input-input-' + ix + '-' + domIdAddition"
                                        [ngModelOptions]="{standalone: true}" class="form-control input-medium"
                                        [(ngModel)]="input.cv_JobCharacteristicType.JobCharacteristicType" disabled />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-12" *ngIf="task.canAddSampleGroups">
                        <!-- Sample Groups -->
                        Sample Groups
                        <a (click)="task.sampleGroupsExpand = !task.sampleGroupsExpand" class="toggle-sample-link">
                            <i class="fa" [ngClass]="task.sampleGroupsExpand ? 'fa-chevron-up' : 'fa-chevron-down'"
                                [title]="(task.sampleGroupsExpand ? 'Hide ' : 'Show ')" aria-hidden="true"></i>
                        </a>
                        <fieldset class="fieldset-bordered" *ngIf="task.sampleGroupsExpand">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="detail-grid-header">
                                        <th>
                                            <a class="add-item-link" (click)="createAddRow(task)">
                                                <i class="fa fa-plus-circle" title="Add Sample Group"></i>
                                            </a>
                                        </th>
                                        <th>
                                            <bulk-edit-header [headerText]="'Samples'"
                                                (updateClicked)="updateBulkNumSamples(task)">
                                                <div modal-content>
                                                    <input [(ngModel)]="bulkNumSamples" type="number" min="1" step="1"
                                                        class="form-control input-small"
                                                        name="sampleGroup-bulk-numSamples" />
                                                </div>
                                            </bulk-edit-header>
                                        </th>
                                        <th>
                                            <bulk-edit-header [headerText]="'Type'"
                                                (updateClicked)="updateBulkSampleTypeKey(task)">
                                                <div modal-content>
                                                    <active-vocab-select [(model)]="bulkSampleTypeKey"
                                                        [vocabChoices]="sampleTypes"
                                                        [keyFormatter]="sampleTypeKeyFormatter"
                                                        [optionFormatter]="sampleTypeFormatter" [nullable]="true">
                                                    </active-vocab-select>
                                                </div>
                                            </bulk-edit-header>
                                        </th>
                                        <th>
                                            <bulk-edit-header [headerText]="'Status'"
                                                (updateClicked)="updateBulkSampleStatusKey(task)">
                                                <div modal-content>
                                                    <active-vocab-select [(model)]="bulkSampleStatusKey"
                                                        [vocabChoices]="sampleStatuses"
                                                        [keyFormatter]="sampleStatusKeyFormatter"
                                                        [optionFormatter]="sampleStatusFormatter" [nullable]="true">
                                                    </active-vocab-select>
                                                </div>
                                            </bulk-edit-header>
                                        </th>
                                        <th>
                                            <bulk-edit-header [headerText]="'Preservation'"
                                                (updateClicked)="updateBulkPreservationMethodKey(task)">
                                                <div modal-content>
                                                    <active-vocab-select [(model)]="bulkPreservationMethodKey"
                                                        [vocabChoices]="preservationMethods"
                                                        [keyFormatter]="preservationMethodKeyFormatter"
                                                        [optionFormatter]="preservationMethodFormatter"
                                                        [nullable]="true">
                                                    </active-vocab-select>
                                                </div>
                                            </bulk-edit-header>
                                        </th>
                                        <th>
                                            <bulk-edit-header [headerText]="'Container'"
                                                (updateClicked)="updateBulkContainerTypeKey(task)">
                                                <div modal-content>
                                                    <active-vocab-select [(model)]="bulkContainerTypeKey"
                                                        [vocabChoices]="containerTypes"
                                                        [keyFormatter]="containerTypeKeyFormatter"
                                                        [optionFormatter]="containerTypeFormatter" [nullable]="true">
                                                    </active-vocab-select>
                                                </div>
                                            </bulk-edit-header>
                                        </th>
                                        <th>
                                            <bulk-edit-header [headerText]="'Subtype'"
                                                (updateClicked)="updateBulkSampleSubtypeKey(task)">
                                                <div modal-content>
                                                    <active-vocab-select [(model)]="bulkSampleSubtypeKey"
                                                        [vocabChoices]="sampleSubtypes"
                                                        [keyFormatter]="sampleSubtypeKeyFormatter"
                                                        [optionFormatter]="sampleSubtypeFormatter" [nullable]="true">
                                                    </active-vocab-select>
                                                </div>
                                            </bulk-edit-header>
                                        </th>
                                        <th>
                                            <bulk-edit-header [headerText]="'Processing'"
                                                (updateClicked)="updateBulkSampleProcessingMethodKey(task)">
                                                <div modal-content>
                                                    <active-vocab-select [(model)]="bulkSampleProcessingMethodKey"
                                                        [vocabChoices]="sampleProcessingMethods"
                                                        [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                                        [optionFormatter]="sampleProcessingMethodFormatter"
                                                        [nullable]="true">
                                                    </active-vocab-select>
                                                </div>
                                            </bulk-edit-header>
                                        </th>
                                        <th>
                                            <bulk-edit-header [headerText]="'Send To'"
                                                (updateClicked)="updateBulkSendTo(task)">
                                                <div modal-content>
                                                    <input [(ngModel)]="bulkSendTo" type="text" maxlength="150"
                                                        class="form-control input-small"
                                                        name="sampleGroup-bulk-sendTo" />
                                                </div>
                                            </bulk-edit-header>
                                        </th>
                                        <th>
                                            <bulk-edit-header [headerText]="'Analysis'"
                                                (updateClicked)="updateBulkSampleAnalysisMethodKey(task)">
                                                <div modal-content>
                                                    <active-vocab-select [(model)]="bulkSampleAnalysisMethodKey"
                                                        [vocabChoices]="sampleAnalysisMethods"
                                                        [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                                        [optionFormatter]="sampleAnalysisMethodFormatter"
                                                        [nullable]="true">
                                                    </active-vocab-select>
                                                </div>
                                            </bulk-edit-header>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of task.sampleGroups; let index = index">
                                        <!-- Remove -->
                                        <td class="icon-cell">
                                            <a (click)="createRemoveRow(task, index)">
                                                <i class="fa fa-remove remove-item" title="Remove Sample Group"></i>
                                            </a>
                                        </td>
                                        <!-- NumSamples -->
                                        <td>
                                            <input [(ngModel)]="row.NumSamples" type="number" min="1" step="1"
                                                class="form-control input-small"
                                                name="sampleGroup-create-{{index}}-NumSamples" />
                                        </td>

                                        <!-- Type -->
                                        <td>
                                            <active-vocab-select [(model)]="row.C_SampleType_key"
                                                [vocabChoices]="sampleTypes" [keyFormatter]="sampleTypeKeyFormatter"
                                                [optionFormatter]="sampleTypeFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Status -->
                                        <td>
                                            <active-vocab-select [(model)]="row.C_SampleStatus_key"
                                                [vocabChoices]="sampleStatuses"
                                                [keyFormatter]="sampleStatusKeyFormatter"
                                                [optionFormatter]="sampleStatusFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Preservation -->
                                        <td>
                                            <active-vocab-select [(model)]="row.C_PreservationMethod_key"
                                                [vocabChoices]="preservationMethods"
                                                [keyFormatter]="preservationMethodKeyFormatter"
                                                [optionFormatter]="preservationMethodFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Container -->
                                        <td>
                                            <active-vocab-select [(model)]="row.C_ContainerType_key"
                                                [vocabChoices]="containerTypes"
                                                [keyFormatter]="containerTypeKeyFormatter"
                                                [optionFormatter]="containerTypeFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Subtype -->
                                        <td>
                                            <active-vocab-select [(model)]="row.C_SampleSubtype_key"
                                                [vocabChoices]="sampleSubtypes"
                                                [keyFormatter]="sampleSubtypeKeyFormatter"
                                                [optionFormatter]="sampleSubtypeFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Processing -->
                                        <td>
                                            <active-vocab-select [(model)]="row.C_SampleProcessingMethod_key"
                                                [vocabChoices]="sampleProcessingMethods"
                                                [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                                [optionFormatter]="sampleProcessingMethodFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>

                                        <!-- Send To -->
                                        <td>
                                            <input [(ngModel)]="row.SendTo" type="text" maxlength="150"
                                                class="form-control input-small" name="sampleGroup-sendTo-{{index}}" />
                                        </td>

                                        <!-- Analysis -->
                                        <td>
                                            <active-vocab-select [(model)]="row.C_SampleAnalysisMethod_key"
                                                [vocabChoices]="sampleAnalysisMethods"
                                                [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                                [optionFormatter]="sampleAnalysisMethodFormatter" [nullable]="true">
                                            </active-vocab-select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</div>
