import { Injectable } from '@angular/core';
import { DateFormatterService } from '@common/util/date-time-formatting';
import { TranslationService } from '../services/translation.service';
import { UserNameService } from '../user/user-name.service';
import { CsvExporter } from '../common/export/csv-exporter';


/**
 * Exports a job audit report to CSV
 */
@Injectable()
export class ExportJobAuditService {

    csvExporter: CsvExporter;

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService
    ) {
        this.csvExporter = new CsvExporter();
    }

    exportToCsv(
        jobAuditData: any[],
        jobAuditLinesData: any[],
        jobAuditLineDetailsData: any[],
        jobAuditTestArticlesData: any[],
        jobAuditJobGroupsData: any[],
        jobAuditInstitutionData: any[],
        jobAuditCharacteristicsData: any[],
        jobAuditSampleGroupsData: any[],
        jobAuditAnimalsData: any[],
        jobAuditSamplesData: any[],
        jobAuditCurrentTasksData: any[],
        jobAuditDeletedTasksData: any[],
        jobAuditStandardPhrasesData: any[],
        jobAuditCohortsData: any[],
        jobAuditGroupPlaceholdersData: any[],
        jobAuditAnimalPlaceholdersData: any[],
        isCRL: boolean,
        isCRO: boolean,
        isGLP: boolean
    ) {
        const EXPORTER_CSV_FILENAME =
            this.translationService.translate('Job') +
            "Audit.csv";

        let data: any[][] = this.buildExportData(
            jobAuditData,
            jobAuditLinesData,
            jobAuditLineDetailsData,
            jobAuditTestArticlesData,
            jobAuditJobGroupsData,
            jobAuditInstitutionData,
            jobAuditCharacteristicsData,
            jobAuditStandardPhrasesData,
            jobAuditSampleGroupsData,
            jobAuditAnimalsData,
            jobAuditSamplesData,
            jobAuditCurrentTasksData,
            jobAuditDeletedTasksData,
            jobAuditCohortsData,
            jobAuditGroupPlaceholdersData,
            jobAuditAnimalPlaceholdersData,
            isCRL,
            isCRO,
            isGLP
        );

        this.csvExporter.download(data, EXPORTER_CSV_FILENAME);
    }

    buildExportData(
        jobAuditData: any[],
        jobAuditLinesData: any[],
        jobAuditLineDetailsData: any[],
        jobAuditTestArticlesData: any[],
        jobAuditJobGroupsData: any[],
        jobAuditInstitutionData: any[],
        jobAuditCharacteristicsData: any[],
        jobAuditStandardPhrasesData: any[],
        jobAuditSampleGroupsData: any[],
        jobAuditAnimalsData: any[],
        jobAuditSamplesData: any[],
        jobAuditCurrentTasksData: any[],
        jobAuditDeletedTasksData: any[],
        jobAuditCohortsData: any[],
        jobAuditGroupPlaceholdersData: any[],
        jobAuditAnimalPlaceholdersData: any[],
        isCRL: boolean,
        isCRO: boolean,
        isGLP: boolean
    ): any[][] {
        let data: any[][] = [];

        data.push(['Details']);
        // Job audit data
        if (!isCRO) {
            let jobDetailsHeads = [
                'Action',
                'Modified Date',
                'Modified By',
                'Name',
                'Code',
                this.translationService.translate('Study'),
                'Title',
                'Lead Scientist',
                'Study Monitor',
                'Description',
                this.translationService.translate('Line'),
                'IACUC Protocol',
                'Compliance',
                'Status',
                'Type',
                'Projected Start Date',
                'Start Date',
                'Duration Days',
                'End Date',
                'Locations',
                'Cost',
                'Duration Minutes',
                'Locked'
            ];
            data.push(jobDetailsHeads);
            for (let row of jobAuditData) {
                let jobDetailsRow = [
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.JobID,
                    row.JobCode,
                    row.Study,
                    row.Goal,
                    row.LeadScientist,
                    row.StudyMonitor,
                    row.Notes,
                    row.Line,
                    row.IACUCProtocol,
                    row.Compliance,
                    row.JobStatus,
                    row.JobType,
                    this.dateFormatterService.formatDateOnlyUTC(row.ProjectedStartDate),
                    this.dateFormatterService.formatDateOnlyUTC(row.DateStarted),
                    row.DurationDays,
                    this.dateFormatterService.formatDateOnlyUTC(row.DateEnded),
                    row.CurrentLocationPath,
                    row.Cost,
                    row.Duration,
                    row.IsLocked
                ];
                data.push(jobDetailsRow);
            }
        } else if (!isCRL) {
            // isCRO && !isCRL
            let jobDetailsHeads = [
                'Action',
                'Modified Date',
                'Modified By',
                'Name',
                'Code',
                'Research Director',
                !isGLP ? 'Client Manager' : 'Alternate Contact',
                'Study Director',
                'IACUC Protocol',
                'Compliance',
                'Status',
                'Locations',
                this.translationService.translate('Study'),
                'Type',
                'Subtype',
                'Title',
                'Description',
                'Overage (%)',
                this.translationService.translate('Job') + ' Report',
                'Projected Implant Date',
                'Implanted Date',
                'Projected Start Date',
                'Start Date',
                'Duration Days',
                'End Date',                
                'Locked'
            ];
            data.push(jobDetailsHeads);
            for (let row of jobAuditData) {
                let jobDetailsRow = [
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.JobID,
                    row.JobCode,
                    row.ResearchDirector,
                    row.ClientManager,
                    row.StudyDirector,
                    row.IACUCProtocol,
                    row.Compliance,
                    row.JobStatus,
                    row.CurrentLocationPath,
                    row.Study,
                    row.JobType,
                    row.JobSubtype,
                    row.Goal,
                    row.Notes,
                    row.JobGroupOverage,
                    row.JobReport,
                    this.dateFormatterService.formatDateOnlyUTC(row.ProjectImplantDate),
                    this.dateFormatterService.formatDateOnlyUTC(row.ImplantedDate),
                    this.dateFormatterService.formatDateOnlyUTC(row.ProjectedStartDate),
                    this.dateFormatterService.formatDateOnlyUTC(row.DateStarted),
                    row.DurationDays,
                    this.dateFormatterService.formatDateOnlyUTC(row.DateEnded),
                    row.IsLocked
                ];
                data.push(jobDetailsRow);
            }
        } else {
            // isCRO && isCRL
            let jobDetailsHeads = [
                'Action',
                'Modified Date',
                'Modified By',
                'Name',
                'Code',
                'Research Director',
                !isGLP ? 'Client Manager' : 'Alternate Contact',
                'Study Director',
                'IACUC Protocol',
                'Compliance',
                'Status',
                'Locations',
                this.translationService.translate('Study'),
                'Type',
                'Subtype',
                'Imaging',
                'Title',
                'Description',
                'Overage (%)',
                this.translationService.translate('Job') + ' Report',
                'Projected Implant Date',
                'Implanted Date',
                'Projected Start Date',
                'Start Date',                
                'Duration Days',
                'End Date',
                'Locked'
            ];
            data.push(jobDetailsHeads);
            for (let row of jobAuditData) {
                let jobDetailsRow = [
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.JobID,
                    row.JobCode,
                    row.ResearchDirector,
                    row.ClientManager,
                    row.StudyDirector,
                    row.IACUCProtocol,
                    row.Compliance,
                    row.JobStatus,
                    row.CurrentLocationPath,
                    row.Study,
                    row.JobType,
                    row.JobSubtype,
                    row.Imaging,
                    row.Goal,
                    row.Notes,
                    row.JobGroupOverage,
                    row.JobReport,
                    this.dateFormatterService.formatDateOnlyUTC(row.ProjectImplantDate),
                    this.dateFormatterService.formatDateOnlyUTC(row.ImplantedDate),
                    this.dateFormatterService.formatDateOnlyUTC(row.ProjectedStartDate),
                    this.dateFormatterService.formatDateOnlyUTC(row.DateStarted),
                    row.DurationDays,
                    this.dateFormatterService.formatDateOnlyUTC(row.DateEnded),
                    row.IsLocked
                ];
                data.push(jobDetailsRow);
            }
        }

        if (isCRO && jobAuditLinesData.length > 0) {
            data.push([]);
            data.push([this.translationService.translate('Lines')]);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                this.translationService.translate('Line'),
                'Species'
            ]);
            for (let row of jobAuditLinesData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.LineName,
                    row.Species
                ]);
            }
        }

        if (isCRO && jobAuditLineDetailsData.length > 0) {
            data.push([]);
            data.push([this.translationService.translate('Line') + ' Details']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                this.translationService.translate('Line'),
                'Sex',
                'Min Age',
                'Max Age',
                'Origin'
            ]);
            for (let row of jobAuditLineDetailsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.LineName,
                    row.Sex,
                    row.MinAge,
                    row.MaxAge,
                    row.MaterialOrigin
                ]);
            }
        }

        if (jobAuditTestArticlesData.length > 0) {
            data.push([]);
            data.push(['Test Articles']);
            if (isCRO) {
                data.push([
                    'Action',
                    'Modified Date',
                    'Modified By',
                    'Test Article',
                    'Batch',
                    'Vehicle'
                ]);
            } else {
                data.push([
                    'Action',
                    'Modified Date',
                    'Modified By',
                    'Test Article',
                    'Batch'
                ]);
            }
            for (let row of jobAuditTestArticlesData) {
                if (isCRO) {
                    data.push([
                        row.UpdateType,
                        this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                        this.userNameService.toFullName(row.ModifiedBy),
                        row.TestArticle,
                        row.Batch,
                        row.Vehicle
                    ]);
                } else {
                    data.push([
                        row.UpdateType,
                        this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                        this.userNameService.toFullName(row.ModifiedBy),
                        row.TestArticle,
                        row.Batch
                    ]);
                }
            }
        }

        if (isCRO && jobAuditJobGroupsData.length > 0) {
            data.push([]);
            data.push(['Dosing Table']);
            if (!isCRL) {
                data.push([
                    'Action',
                    'Modified Date',
                    'Modified By',
                    'Group',
                    'Number',
                    'Treatment',
                    'FormulationDose',
                    'ActiveDose',
                    'ActiveUnit',
                    'Concentration',
                    'Route',
                    'DosingVolume',
                    'DosingUnit',
                    'Schedule',
                    'Protocol'
                ]);
            } else {
                data.push([
                    'Action',
                    'Modified Date',
                    'Modified By',
                    'Group',
                    'Number',
                    'Treatment',
                    'FormulationDose',
                    'ActiveDose',
                    'ActiveUnit',
                    'Route',
                    'DosingVolume',
                    'DosingUnit',
                    'Schedule',
                    'Protocol'
                ]);
            }
            for (let row of jobAuditJobGroupsData) {
                if (!isCRL) {
                    data.push([
                        row.UpdateType,
                        this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                        this.userNameService.toFullName(row.ModifiedBy),
                        row.Group,
                        row.Number,
                        row.Treatment,
                        row.FormulationDose,
                        row.ActiveDose,
                        row.ActiveUnit,
                        row.Concentration,
                        row.Route,
                        row.DosingVolume,
                        row.DosingUnit,
                        row.Schedule,
                        row.Protocol
                    ]);
                } else {
                    data.push([
                        row.UpdateType,
                        this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                        this.userNameService.toFullName(row.ModifiedBy),
                        row.Group,
                        row.Number,
                        row.Treatment,
                        row.FormulationDose,
                        row.ActiveDose,
                        row.ActiveUnit,
                        row.Route,
                        row.DosingVolume,
                        row.DosingUnit,
                        row.Schedule,
                        row.Protocol
                    ]);
                }
            }
        }

        if (jobAuditInstitutionData.length > 0) {
            data.push([]);
            data.push([this.translationService.translate('Institution')]);
            if (isCRO) {
                data.push([
                    'Action',
                    'Modified Date',
                    'Modified By',
                    'Institution',
                    'Site',
                    'Scientific Contact',
                    'Billing Contact',
                    'Authorization Contact'
                ]);
            } else {
                data.push([
                    'Action',
                    'Modified Date',
                    'Modified By',
                    'Institution',
                    'Site'
                ]);
            }
            for (let row of jobAuditInstitutionData) {
                if (isCRO) {
                    data.push([
                        row.UpdateType,
                        this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                        this.userNameService.toFullName(row.ModifiedBy),
                        row.InstitutionName,
                        row.Sitename,
                        row.ScientificContactPerson,
                        isCRL ? row.JobInstitutionBillingContacts : row.BillingContactPerson,
                        row.AuthorizationContactPerson
                    ]);
                } else {
                    data.push([
                        row.UpdateType,
                        this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                        this.userNameService.toFullName(row.ModifiedBy),
                        row.InstitutionName,
                        row.Sitename
                    ]);
                }
            }
        }

        if (jobAuditCharacteristicsData.length > 0) {
            data.push([]);
            data.push(['Characteristics']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Characteristic Name',
                'Value'
            ]);
            for (let row of jobAuditCharacteristicsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.CharacteristicName,
                    row.CharacteristicValue
                ]);
            }
        }

        if (isCRL && jobAuditStandardPhrasesData.length > 0) {
            data.push([]);
            data.push(['Standard Phrases']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Standard Phrase'
            ]);
            for (let row of jobAuditStandardPhrasesData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.StandardPhrase
                ]);
            }
        }

        if (jobAuditAnimalsData.length > 0) {
            data.push([]);
            data.push(['Animals']);
            // Animals added and deleted
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Animal Name'
            ]);
            for (let row of jobAuditAnimalsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.MaterialName
                ]);
            }
        }

        if (jobAuditCohortsData.length > 0) {
            data.push([]);

            data.push(['Cohorts']);
            // Cohorts added and deleted
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Cohort Name'
            ]);
            for (let row of jobAuditCohortsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.CohortName
                ]);
            }
        }

        if (jobAuditGroupPlaceholdersData.length > 0) {
            data.push([]);

            data.push(['Group Placeholders']);
            // Placeholders added and deleted
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Placeholder Name',
                'Cohort Name'
            ]);
            for (let row of jobAuditGroupPlaceholdersData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.PlaceholderName,
                    row.CohortName
                ]);
            }
        }

        if (jobAuditAnimalPlaceholdersData.length > 0) {
            data.push([]);

            data.push(['Animal Placeholders']);
            // Placeholders added and deleted
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Placeholder',
                'Cohort',
                'Group Placeholder'
            ]);
            for (let row of jobAuditAnimalPlaceholdersData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.PlaceholderName,
                    row.AnimalName,
                    row.GroupPlaceholderName
                ]);
            }
        }

        if (jobAuditSamplesData.length > 0) {
            data.push([]);
            
            data.push(['Samples']);
            // Samples added and deleted
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Sample Name'
            ]);
            for (let row of jobAuditSamplesData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.MaterialName
                ]);
            }
        }

        if (jobAuditCurrentTasksData.length > 0) {
            data.push([]);
            data.push(['Tasks']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Task Name',
                'Task',
                'Sequence',
                'Due Date',
                'Deviation',
                'Task Location',
                'Complete Date',
                'Protocol',
                'Status',
                'Assigned To',
                'Cost',
                'Duration',
                'Locked'
            ]);
            for (let row of jobAuditCurrentTasksData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.TaskAlias,
                    row.TaskName,
                    row.Sequence,
                    this.dateFormatterService.formatDateOrTimeUTC(row.DateDue),
                    row.Deviation,
                    row.CurrentLocationPath,
                    this.dateFormatterService.formatDateOrTimeUTC(row.DateComplete),
                    row.ProtocolName,
                    row.TaskStatus,
                    row.AssignedTo,
                    row.Cost,
                    row.Duration,
                    row.IsLocked
                ]);
            }
        }

        if (jobAuditSampleGroupsData.length > 0) {
            data.push([]);
            data.push(['Sample Groups']);
            data.push([
                'Action',
                'Modified Date',
                'Modified By',
                'Task Name',
                'Samples per Source',
                'Type',
                'Status',
                'Preservation',
                'Container',
                'Harvest Date',
                'Expiration Date',
                'Time Point',
                'Subtype',
                'Processing',
                'Send To',
                'Analysis'
            ]);
            for (let row of jobAuditSampleGroupsData) {
                data.push([
                    row.UpdateType,
                    this.dateFormatterService.formatDateTimeUTC(row.ModifiedDate),
                    this.userNameService.toFullName(row.ModifiedBy),
                    row.TaskAlias,
                    row.SamplesPerSource,
                    row.SampleType,
                    row.SampleStatus,
                    row.PreservationMethod,
                    row.ContainerType,
                    row.DateHarvest,
                    row.DateExpiration,
                    row.TimePoint,
                    row.SampleSubtype,
                    row.SampleProcessingMethod,
                    row.SendTo,
                    row.SampleAnalysisMethod
                ]);
            }
        }

        return data;
    }
}
