import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    EventEmitter,
    Output,
} from '@angular/core';
import { JobPharmaDetailService } from '../job-pharma-detail.service';
import { SaveChangesService } from '../../../services/save-changes.service';
import { SaveRecordsOverlayEvent } from './job-pharma-tasks-list-table.component';
import { DataManagerService } from '../../../services/data-manager.service';
import { LoggingService } from '../../../services/logging.service';
import { BulkAssignCohortsModalService } from '../modals';
import { uniqueArrayFromPropertyPath } from '../../../common/util';
import { Subscription } from 'rxjs';
import { Animal, Entity, Material, TaskInstance } from '@common/types';
import { isAnyTaskEndState } from 'src/app/tasks/util/is-any-task-end-state';

@Component({
    selector: 'job-pharma-animals-placeholder-table',
    templateUrl: './job-pharma-animals-placeholder-table.component.html',
    styles: [`
       .ui-draggable-dragging {
         padding: 4px;
         border-radius: 2px;
         font-size: 12px;
         margin-left: 20px;
       }
    `]
})
export class JobPharmaAnimalsPlaceholderTableComponent implements OnDestroy, OnInit, OnChanges {
    @Input() readonly: boolean;
    @Input() job: any;

    @Input() tabset: string = 'animals';
    @Input() tab: string = 'placeholder';
    @Output() busy: EventEmitter<SaveRecordsOverlayEvent> = new EventEmitter<SaveRecordsOverlayEvent>();

    loading: boolean = false;
    loadingMessage: string = "Loading";

    taskNumber = this.jobPharmaDetailService.taskNumberValue;

    subs = new Subscription();
    // Are all the rows selected?
    allSelected: boolean = false;

    readonly COMPONENT_LOG_TAG = 'job-pharma-animals-placeholder-table';

    constructor(
        private jobPharmaDetailService: JobPharmaDetailService,
        private saveChangesService: SaveChangesService,
        private dataManager: DataManagerService,
        private loggingService: LoggingService,
        private bulkAssignCohortService: BulkAssignCohortsModalService
    ) {
        // Do nothing
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        this.clearSelections();

        // Clear all the subscriptions
        this.subs.unsubscribe();
    }

    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            this.initJob();
        }
    }

    initialize() {
        this.initJob();
    }

    initializeTasksNumber() {
        this.subs.add(this.jobPharmaDetailService.taskNumber$.subscribe(taskNumber => {
            this.taskNumber = taskNumber;
        }));
    }

    // Initialize the list of placeholders
    private initJob() {
        const expands = [
            'JobCohort.Cohort',
        ];

        return this.dataManager.ensureRelationships([this.job], expands).then(() => {
            // Just in case, clear the selections
            this.clearSelections();
        });
    }

    /**
     * Unselect all the rows
     */
    clearSelections() {
        if (this.job.Placeholder) {
            // Reset animal selections
            this.job.Placeholder.forEach((placeholder: any) => { placeholder.isSelected = false; });
        }
        this.allSelected = false;
    }

    /**
     * The "Clear All" button was clicked
     */
    clearAllClicked($event: any) {
        // Clear the selections first
        this.clearSelections();

        // Also clear out the header the checkbox
        $event.target.checked = false;
    }

    /**
     * The Select/Clear All button was clicked
     */
    allSelectedChanged() {
        // Select or unselect all the rows
        if (this.job.Placeholder) {
            for (const placeholder of this.job.Placeholder) {
                placeholder.isSelected = this.allSelected;
            }
        }
    }

    /**
     * A row selection checkbox was clicked.
     */
    isSelectedChanged() {
        // Check if all the rows are selected
        this.allSelected = this.job.Placeholder.every((placeholder: any) => placeholder.isSelected);
    }

    // Dragging Out
    private dragId: number = null;
    dragStart() {
        // Find the selected cohorts
        const selected = this.job.Placeholder
            .filter((placeholder: any) => placeholder.isSelected);

        this.dragId = this.jobPharmaDetailService.startDrag('Placeholder', selected);
        if (selected.length > 1) {
            jQuery('.ui-draggable-dragging')[0].textContent = `${selected.length} Placeholders Selected`;
        }
    }

    // TODO: ?
    dragStop() {
        setTimeout(() => {
            this.jobPharmaDetailService.stopDrag(this.dragId);
        }, 500);
    }

    canSave(): boolean {
        return this.saveChangesService.hasChanges && !this.saveChangesService.saving;
    }

    getCohortStyle(key: any) {
        // Find matching cohort
        const cohorts = this.job.JobCohort.filter((cohort: any) => cohort.C_JobCohort_key === key);
        if (cohorts.length > 0 && cohorts[0].Cohort) {
            return { backgroundColor: cohorts[0].Cohort.BackgroundColor, color: cohorts[0].Cohort.ForegroundColor };
        }
        return {};
    }

    getCohortName(key: any) {
        // Find matching cohort
        const cohorts = this.job.JobCohort.filter((cohort: any) => cohort.C_JobCohort_key === key);
        if (cohorts.length > 0 && cohorts[0].Cohort) {
            return cohorts[0].Cohort.CohortName;
        }
        return "";
    }

    async onRemoveCohortAssociation(placeholder: any) {
        // Check if this cohort can be removed from all tasks this placeholder is associated with?
        const animals = this.jobPharmaDetailService._getCohortAnimals(placeholder.JobCohort.Cohort);
        if (animals.length > 0) {
            const tasks = placeholder.TaskPlaceholder.map((tp: any) => tp.TaskInstance);
            const taskFilter = this.jobPharmaDetailService.buildTaskFilter(tasks);
            const materials = animals.map(a => a.Material) as Entity<Material>[];
            const animalsWithData = await this.jobPharmaDetailService.findMaterialsWithData(this.job, materials, taskFilter);
            if (animalsWithData.length > 0) {
                this.loggingService.logWarning("Cannot unlink cohort and placeholder because there are animals with completed tasks or collected data.", null, this.COMPONENT_LOG_TAG, true);
            } else {
                this.busy.emit({ state: true, message: this.loadingMessage });
                this.jobPharmaDetailService.tryRemoveCohortsFromTasks(this.job, [placeholder.JobCohort.Cohort], tasks)
                    .then((result: any) => {
                        if (result.withData.length === 0) {
                            placeholder.C_JobCohort_key = null;
                        }
                    })
                    .finally(() => {
                        this.busy.emit({ state: false, message: this.loadingMessage });
                    });
                const animalTasks = placeholder.JobGroup.AnimalPlaceholder.reduce((acc: any, animalPlaceholder: any) => {
                    return acc.concat(animalPlaceholder.TaskPlaceholder.map((tp: any) => tp.TaskInstance));
                }, []);
                const singleAnimals = placeholder.JobGroup.AnimalPlaceholder.map((animalPlaceholder: any) => animalPlaceholder.Material.Animal);
                const removeResult = await this.jobPharmaDetailService.tryRemoveAnimalsFromTasks(this.job, singleAnimals, animalTasks);
                if (removeResult.allRemoved) {
                    placeholder.JobGroup.AnimalPlaceholder.forEach((animalPlaceholder: any) => {
                        animalPlaceholder.C_Material_key = null;
                    });
                }

                if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
                    const sampleGroups = await this.jobPharmaDetailService.getSampleGroupsWithSamples([tasks]);
                    const materials = singleAnimals.map((a: Animal) => a.Material).filter((m: Material) => m);
                    await this.jobPharmaDetailService.handleSampleAssociationDeletes(sampleGroups, materials);
                }
            }
            return;
        }
        placeholder.C_JobCohort_key = null;
        placeholder.JobGroup.AnimalPlaceholder.forEach((animalPlaceholder: any) => {
            animalPlaceholder.C_Material_ey = null;
        });
    }

    //  Open the create job pharma modal
    async openJobPharmaModal(): Promise<void> {
        const result = await this.bulkAssignCohortService.openComponent(this.job, false);
        const atLeastOneTaskSelected = result?.selectedTasks.length > 0;

        if (atLeastOneTaskSelected && this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
            await this.dataManager.ensureRelationships(result.selectedTasks, ['SampleGroup.Sample']);

            const memberTasksWithSampleGroups = result.selectedTasks
                .filter(ti => ti.SampleGroup.length > 0)
                .filter(ti => ti.SampleGroup.find(sg => sg.Sample.length > 0))
                .flatMap(ti => ti.MemberTaskInstance);
            if (isAnyTaskEndState(memberTasksWithSampleGroups)) {
                await this.jobPharmaDetailService.showCompletedTasksModal();
                return;
            }
        }

        // Add animals
        if (result?.selectedAnimals.length > 0 && atLeastOneTaskSelected) {
            // for each selected task, see if the placeholder already exists
            result.selectedTasks.forEach((task: any) => {
                result.selectedAnimals.forEach((placeholder: any) => {
                    this.addAnimalPlaceholdersToTask(task, placeholder);
                });
            });
        }
        // Add cohorts
        if (result?.selectedCohorts.length > 0 && atLeastOneTaskSelected) {
            // for each selected task, see if the placeholder already exists
            result.selectedTasks.forEach((task: any) => {
                result.selectedCohorts.forEach((placeholder: any) => {
                    this.addPlaceholdersToTask(task, placeholder);
                });
            });
        }
    }

    addAnimalsToTask(task: any, animals: any) {
        return this.jobPharmaDetailService.legacyAddAnimalsToTask(
            this.job, task, animals).then(() => {
                this.jobPharmaDetailService.tabRefresh('tasks', 'list');
            });
    }

    addCohortToTask(task: any, cohorts: any) {
        return this.jobPharmaDetailService.addCohortsToTask(
            this.job, task, cohorts).then(() => {
                this.jobPharmaDetailService.tabRefresh('tasks', 'list');
            });
    }

    // Add animal placeholders to tasks
    addAnimalPlaceholdersToTask(task: any, placeholder: any) {
        console.log("addAnimalPlaceholdersToTask", task, placeholder);
        this.jobPharmaDetailService.addAnimalPlaceholderToTask(task, placeholder);
        if (placeholder.C_Material_key !== null) {
            const currentMaterialKeys = uniqueArrayFromPropertyPath(task, 'MemberTaskinstance.TaskMaterial').map((tm: any) => tm.C_Material_key);
            if (!currentMaterialKeys.includes(placeholder.C_Material_key)) {
               this.addAnimalsToTask(task, [placeholder.Material.Animal]);
            }
        }
    }

    // Add placeholders to tasks
    addPlaceholdersToTask(task: any, placeholder: any) {
        console.log("addPlaceholdersToTask", task, placeholder);
        this.jobPharmaDetailService.addPlaceholderToTask(task, placeholder);
        if (placeholder.C_JobCohort_key) {
            const currentCohortKeys = uniqueArrayFromPropertyPath(task, 'MemberTaskinstance.TaskCohort').map((tc: any) => tc.C_Cohort_key);
            if (!currentCohortKeys.includes(placeholder.JobCohort.C_Cohort_key)) {
                this.addCohortToTask(task, [placeholder.JobCohort.Cohort]);
            }
        }
    }
}
