import { map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeatureFlagService } from '../../services/feature-flags.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { ClinicalVocabService } from '../clinical-vocab.service';

@Component({
    selector: 'observation-chooser',
    templateUrl: './observation-chooser.component.html',
    styleUrls: ['./observation-chooser.component.scss'],
})
export class ObservationChooserComponent implements OnInit {
    @Input() selectedObservations: any[];
    @Input() selectedObservationForGLP: any = null;
    @Input() selectedBodySystemForGLP: any = null;
    @Input() diagnosticObservation = false;
    @Output() onSelect = new EventEmitter<Record<string, any>>();
    @Output() close = new EventEmitter<Record<string, any>>();

    // isGLP

    isGLP = false;
    // loading
    loading = false;

    // vocabularies
    observationCVs: any[];
    observationsInWorkflow: any[];
    observationsNotInWorkflow: any[];
    bodySystem: any[];
    clinicalObservationBodySystem: any[];
    selectedCheckbox = false;
    selectedTab: any = null;
    lastTab: any = null;
    title: any;

    constructor(
        private clinicalVocabService: ClinicalVocabService,
        private featureFlagService: FeatureFlagService,
        private vocabularyService: VocabularyService
    ) {}

    // lifecycle
    ngOnInit() {
        this.loading = true;
        this.observationCVs = [];
        this.observationsInWorkflow = [];
        this.observationsNotInWorkflow = [];

        this.initIsGLP();
        if (this.isGLP) {
            this.bodySystemCVs().then(() => {
                this.bodySystem.forEach((bsystem) => {
                    bsystem.cv_ClinicalObservationBodySystems.forEach((item: any) => item.IsSelected = false);

                    if (this.selectedBodySystemForGLP !== null && this.selectedObservationForGLP !== null) {
                        if (bsystem.C_BodySystem_key === this.selectedBodySystemForGLP) {
                            const selectedClinicalObservations = bsystem.cv_ClinicalObservationBodySystems
                                .filter((item: any) => item.C_ClinicalObservation_key === this.selectedObservationForGLP);
                            if (selectedClinicalObservations.length > 0) {
                                selectedClinicalObservations[0].IsSelected = true;
                                this.selectedCheckbox = true;
                                this.selectedTab = this.selectedBodySystemForGLP;
                                this.lastTab = this.selectedBodySystemForGLP;
                            }
                        }
                    }

                    if (!this.diagnosticObservation) {
                        this.title = "Clinical Observations";
                        bsystem.clinicalObservations = bsystem.cv_ClinicalObservationBodySystems.filter((item: any, index: any, self: any) => {
                            return self.indexOf(item) === index
                            && (item.cv_ClinicalObservation.IsActive || item.IsSelected === true)
                            && (item.cv_ClinicalObservation.TrackInWorkflow === false
                                || item.cv_ClinicalObservation.TrackInWorkflow === null);
                        });
                    } else {
                        this.title = "Diagnostic Observations";
                        bsystem.clinicalObservations = bsystem.cv_ClinicalObservationBodySystems.filter((item: any, index: any, self: any) => {
                            return self.indexOf(item) === index && (item.cv_ClinicalObservation.IsActive || item.IsSelected === true);
                        });
                    }
                });

                this.loading = false;
            });

        } else {
            this.title = "Clinical Observations";
            this.setupCVs();
        }
    }


    // isGLP
    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    async bodySystemCVs(): Promise<[void, void]>{
        const p1 = this.vocabularyService.getCV('cv_BodySystems').then((data: any) => {
            this.bodySystem = data;
        });
        const p2 = this.vocabularyService.getClinicalObservationBodySystem('cv_ClinicalObservationBodySystems').then((data: any) => {
            this.clinicalObservationBodySystem = data;
        });
        return Promise.all([p1, p2]);
    }

    updateCheck(clinicalObservation: any) {
        this.selectedCheckbox = clinicalObservation.IsSelected;
        this.lastTab = clinicalObservation.C_BodySystem_key;
        if (this.selectedCheckbox) {
            this.selectedTab = clinicalObservation.C_BodySystem_key;
        } else {
            this.selectedTab = null;
        }
    }

    setupCVs(): Promise<any> {
        return this.clinicalVocabService.clinicalObservations$
            .pipe(map((clinicalObservations) => {
                this.observationCVs = clinicalObservations;
                this.assignPreselected();
                this.filterObservationCVs();
            })).toPromise();
    }

    /*
    * Set IsSelected flag for all selectedObservations
    */
    assignPreselected() {
        const selectedKeys = (this.selectedObservations || []).map( (item: any) => {
            return item.C_ClinicalObservation_key;
        });
        for (const observationCV of this.observationCVs) {
            observationCV.IsSelected = selectedKeys.indexOf(
                observationCV.C_ClinicalObservation_key
            ) > -1;
        }
    }

    filterObservationCVs() {
        this.observationsInWorkflow = [];
        this.observationsNotInWorkflow = [];
        for (const observation of this.observationCVs) {
            if (observation.IsActive) {
                if (observation.TrackInWorkflow) {
                    this.observationsInWorkflow.push(observation);
                } else {
                    this.observationsNotInWorkflow.push(observation);
                }
            }
        }
    }

    closeClicked() {
        this.close.emit();
    }

    okClicked(event: Event) {
        event.preventDefault();
        if (this.isGLP) {
            for (const bSystem of this.bodySystem) {
                for (const observation of bSystem.cv_ClinicalObservationBodySystems) {
                    if (observation.IsSelected) {
                        this.onSelect.emit(observation);
                        this.close.emit();
                        return;
                    }
                }
            }
            this.onSelect.emit(null);
            this.close.emit();
        } else {
            const selectedObservations = this.observationCVs.filter((item: any) => {
                return item.IsSelected;
            });
            this.onSelect.emit(selectedObservations);
            this.close.emit();
        }
    }

}
