<div class="detail-form-wrapper">
    <loading-overlay *ngIf="loading || saveChangesService.saving" [message]="loadingMessage"></loading-overlay>
    <div class="detail-header">
        <pager-toolbar [itemName]="'job' | translate"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)">
        </pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">

            <ng-container additional-groups>
                <div class="btn-group"
                     role="group"
                     aria-label="Edit actions">

                    <!-- Enclose Notes in btn-group b/c NG component tag messes up Bootstrap -->
                    <div class="btn-group" view-notes-button
                        [readonly]="facet.Privilege === 'ReadOnly'"
                        [pkName]="'C_Job_key'"
                        [pkValue]="job.C_Job_key"
                        [objectName]="job.JobID"
                        [objectType]="'Job' | translate"
                        [isObjectNew]="job | isNew"
                        appInsightsClick="click-notes-from-job">
                    </div>

                    <button type="button"
                            class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block"
                            (click)="copyJob()"
                            [disabled]="loading"
                            [appInsightsClick]="'click-copy-job-pharma'"
                            *ngIf="readwrite">
                        <i class="fa fa-copy" aria-hidden="true"></i>
                        Copy
                    </button>

                    <div class="btn-group" role="group">
                        <!-- multiple reports menu buttons -->
                        <button type="button"
                                class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block dropdown-toggle"
                                [disabled]="loading"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                            <span class="gridster-d-sm-none gridster-d-md-inline-block">Reports</span>
                            <span class="sr-only">Reports</span>
                        </button>
                        <div class="dropdown-menu">
                            <a 
                                *ngIf="isCRL"
                                (click)='getJobClientReport()'
                               class="dropdown-item"
                               [appInsightsClick]="'click-job-client'">
                                Client Report
                            </a>
                            <a (click)='getJobSummaryReport()'
                               class="dropdown-item"
                               [appInsightsClick]="'click-job-internal'">
                                Internal Report
                            </a>
                            <a 
                                (click)="requestEndOfStudyReport()"
                                class="dropdown-item"
                                >
                                End of Study Report
                            </a>
                        </div>
                    </div>

                    <button type="button"
                            class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block"
                            (click)='exportJob()'
                            [disabled]="loading"
                            [appInsightsClick]="'click-job-export'"
                            title="Export to CSV">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        Export
                    </button>

                    <button *ngIf="!isGLP" type="button"
                            class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block"
                            (click)="viewAuditReport()"
                            [appInsightsClick]="'click-job-audit'">
                        <i class="fa fa-flag" aria-hidden="true"></i>
                        Audit
                    </button>
                    <button type="button"
                            class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block-block"
                            (click)="viewOverviewModal()"
                            [disabled]="canViewOverview()"
                            [appInsightsClick]="'click-job-overview'">
                        Overview
                    </button>

                    <button *ngIf="isDotmatics" type="button"
                            class="btn btn-secondary gridster-d-sm-none gridster-d-md-inline-block"
                            [disabled]="loading"
                            (click)="onSendToDotmatics()">
                        <span *ngIf="!sendingJobReport">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                            Send Report to Dotmatics
                        </span>
                        <span *ngIf="sendingJobReport">
                            <i class="fa fa-spinner fa-spin" title="Sending&hellip;"></i>
                            Sending Report...
                        </span>
                    </button>
                </div>

                <!-- Tools group for narrow screens -->
                <div class="btn-group"
                     role="group"
                     aria-label="Tools">
                    <button type="button"
                            class="btn btn-secondary dropdown-toggle gridster-d-sm-inline-block gridster-d-md-none"
                            [disabled]="loading"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                        <i class="fa fa-cog" title="Tools"></i>
                    </button>
                    <div class="dropdown-menu">
                        <a *ngIf="readwrite"
                           class="dropdown-item"
                           (click)="copyJob()"
                           [appInsightsClick]="'click-copy-job'">
                            <i class="fa fa-copy" aria-hidden="true"></i>
                            Copy
                        </a>
                        <a (click)="getJobSummaryReport()"
                           class="dropdown-item"
                           [appInsightsClick]="'click-job-summary'">
                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                            Report
                        </a>
                        <a (click)="exportJob()"
                           class="dropdown-item"
                           [appInsightsClick]="'click-job-export'">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Export to CSV
                        </a>
                        <a (click)="viewAuditReport()"
                           class="dropdown-item"
                           [appInsightsClick]="'click-job-audit'">
                            <i class="fa fa-flag" aria-hidden="true"></i>
                            Audit
                        </a>
                        <a *ngIf="isDotmatics"
                           class="dropdown-item"
                           (click)="onSendToDotmatics()">
                            <i class="fa fa-paper-plane" aria-hidden="true"></i>
                            Send to Dotmatics
                        </a>
                    </div>
                </div>
            </ng-container>
        </detail-toolbar>
    </div>

    <form *ngIf="job"
          #jobForm="ngForm"
          class="detail-form"
          style="position: relative;">

        <fieldset>
            <div class="form-group row">
                <div class="offset-md-2 col-md-10">
                    <p class="form-control-plaintext">
                        <lock-control [(model)]="job.IsLocked"
                                      [administrator]="isStudyDirector"
                                      [readonly]="readonly || loading"
                                      [largeText]="true"
                                      (modelChange)="onJobLockChange()">
                        </lock-control>
                    </p>
                </div>
            </div>
        </fieldset>
        <fieldset [disabled]="readonly || job.IsLocked">
            <div class="form-row justify-content-between">
                <div class="col-md-6 form-group row flex-grow-1">
                    <label class="col-md-4 col-form-label pr-md-2">Name</label>
                    <div class="col-md-8 pl-md-4">
                        <input type="text"
                               name="jobID"
                               #jobID="ngModel"
                               [ngClass]="{ 'is-invalid': jobID.hasError('unique') && jobID.dirty && job.JobID !== originalJobName }"
                               [(ngModel)]="job.JobID"
                               (ngModelChange)="jobIDUpdated()"
                               [disabled]="(job | isNew) && jobNamingActive"
                               required
                               validateUnique
                               uniqueEntity="Jobs"
                               uniqueProperty="JobID"
                               [entityPK]="job.C_Job_key"
                               entityPKName="C_Job_key"
                               maxlength="100"
                               class="form-control input-medium job-id-input" />
                        <!-- TODO Fix: displays warning if value changed than changed back to original -->
                        <small class="form-text text-muted"
                               *ngIf="jobID.errors?.unique && jobID.dirty && job.JobID !== originalJobName">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            This {{'Job' | translate}} Name already exists.
                        </small>
                        <small class="form-text text-muted"
                               *ngIf="(job | isNew) && jobNamingActive">
                            The {{'Job' | translate}} Name will be assigned automatically.
                        </small>
                    </div>
                </div>
                <div *ngIf="activeFields.includes('Code')"
                     class="col-md-6 form-group row flex-grow-1">
                    <label class="col-md-4 col-form-label">Code</label>
                    <div class="col-md-8">
                        <input type="text" name="jobCode"
                               class="form-control input-medium job-code-input"
                               maxlength="20"
                               [(ngModel)]="job.JobCode"
                               (focusout)="jobCodeChanged()"
                               [required]="isCodeFieldRequired || (requiredFields.includes('JobCode'))" />
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">More</label>
                <div class="col-md-10">
                    <climb-expand [(model)]="detailsExpanded"
                                  label="Details">
                    </climb-expand>
                    <span *ngIf="!detailsExpanded" class="details-summary" style="display: flex; flex-wrap: wrap;">
                        <span *ngIf="activeFields.includes('Study') && job.C_Study_key && jobDetailsOrder.study"
                              [ngStyle]="{'order': jobDetailsOrder.study}">
                            <b>{{'Study' | translate}}:</b> {{job.Study.StudyName}}
                        </span>
                        <span *ngIf="activeFields.includes('Title') && job.Goal && jobDetailsOrder.title"
                              [ngStyle]="{'order': jobDetailsOrder.title}">
                            <b>Title:</b> {{job.Goal}}
                        </span>
                        <span *ngIf="activeFields.includes('Lead Scientist') && job.LeadScientist && jobDetailsOrder.leadScientist"
                              [ngStyle]="{'order': jobDetailsOrder.leadScientist}">
                            <b>Lead Scientist:</b> {{job.LeadScientist}}
                        </span>
                        <span *ngIf="activeFields.includes('Research Director') && job.ResearchDirector && jobDetailsOrder.researchDirector"
                              [ngStyle]="{'order': jobDetailsOrder.researchDirector}">
                            <b>Research Director:</b> {{job.ResearchDirector}}
                        </span>
                        <span *ngIf="activeFields.includes('Client Manager') && job.ClientManager && jobDetailsOrder.clientManager"
                              [ngStyle]="{'order': jobDetailsOrder.clientManager}">
                            <b>{{!isGLP ? 'Client Manager' : 'Alternate Contact'}}:</b> {{job.ClientManager}}
                        </span>
                        <span *ngIf="activeFields.includes('Study Director') && job.StudyDirector && jobDetailsOrder.studyDirector"
                              [ngStyle]="{'order': jobDetailsOrder.studyDirector}">
                            <b>{{'Job' | translate}} Director:</b> {{job.StudyDirector}}
                        </span>
                        <span *ngIf="activeFields.includes('Description') && job.Notes && jobDetailsOrder.description"
                              [ngStyle]="{'order': jobDetailsOrder.description}">
                            <b>Description:</b> {{job.Notes}}
                        </span>
                        <span *ngIf="activeFields.includes('Line') && job.C_Line_key && jobDetailsOrder.line && job.Line"
                              [ngStyle]="{'order': jobDetailsOrder.line}">
                            <b>{{'Line' | translate}}:</b> {{job.Line.LineName}}
                        </span>
                        <span *ngIf="job.C_Line_key && jobDetailsOrder.species && job.Line?.cv_Taxon"
                              [ngStyle]="{'order': jobDetailsOrder.species}">
                            <b>Species:</b> {{job.Line.cv_Taxon.CommonName}}
                        </span>
                        <span *ngIf="activeFields.includes('Line') && job.JobLine && job.JobLine.length > 0 && jobDetailsOrder.linesTable"
                              [ngStyle]="{'order': jobDetailsOrder.linesTable}">
                            <b>{{'Line' | translate}}:</b> {{job.LinesSortable}}
                        </span>
                        <span *ngIf="activeFields.includes('IACUC Protocol') && job.cv_IACUCProtocol && jobDetailsOrder.iacuc"
                              [ngStyle]="{'order': jobDetailsOrder.iacuc}">
                            <b>IACUC Protocol:</b> {{job.cv_IACUCProtocol.IACUCProtocol}}
                        </span>
                        <span *ngIf="activeFields.includes('Compliance') && job.C_Compliance_key && jobDetailsOrder.compliance"
                              [ngStyle]="{'order': jobDetailsOrder.compliance}">
                            <b>Compliance:</b> {{job.Compliance.Compliance}}
                        </span>
                        <span *ngIf="job.C_JobStatus_key && jobDetailsOrder.status"
                              [ngStyle]="{'order': jobDetailsOrder.status}">
                            <b>Status:</b> {{job.cv_JobStatus.JobStatus}}
                        </span>
                        <span *ngIf="activeFields.includes('Type') && job.C_JobType_key && jobDetailsOrder.jobType"
                              [ngStyle]="{'order': jobDetailsOrder.jobType}">
                            <b>Type:</b> {{job.cv_JobType.JobType}}
                        </span>
                        <span *ngIf="activeFields.includes('Projected Start Date') && job.ProjectedStartDate && jobDetailsOrder.projectedStartDate"
                              [ngStyle]="{'order': jobDetailsOrder.projectedStartDate}">
                            <b>Projected Start Date:</b> {{job.ProjectedStartDate | formatShortDate}}
                        </span>
                        <span *ngIf="activeFields.includes('Start Date') && job.DateStarted && jobDetailsOrder.startDate"
                              [ngStyle]="{'order': jobDetailsOrder.startDate}">
                            <b>Start Date:</b> {{job.DateStarted | formatShortDate}}
                        </span>
                        <span *ngIf="activeFields.includes('End Date') && job.DateEnded && jobDetailsOrder.endDate"
                              [ngStyle]="{'order': jobDetailsOrder.endDate}">
                            <b>End Date:</b> {{job.DateEnded | formatShortDate}}
                        </span>
                        <span *ngIf="activeFields.includes('Locations') && job.CurrentLocationPath && jobDetailsOrder.location"
                              [ngStyle]="{'order': jobDetailsOrder.location}">
                            <b>Locations:</b> {{job.CurrentLocationPath}}
                        </span>
                        <span *ngIf="job.Cost && jobDetailsOrder.costDuration"
                              [ngStyle]="{'order': jobDetailsOrder.costDuration}">
                            <b>Cost:</b> {{job.Cost}}
                        </span>
                        <span *ngIf="job.Duration && jobDetailsOrder.costDuration"
                              [ngStyle]="{'order': jobDetailsOrder.costDuration}">
                            <b>Duration:</b> {{job.Duration}} minutes
                        </span>
                        <span *ngIf="job.CreatedBy && jobDetailsOrder.created"
                              [ngStyle]="{'order': jobDetailsOrder.created}">
                            <b>Created:</b> {{job.CreatedBy | userFullName}} &middot; {{job.DateCreated | formatShortDate}}
                        </span>
                    </span>
                </div>
            </div>

            <div *ngIf="detailsExpanded" style="display: flex; flex-direction: column;">
                <div class="form-group row" *ngIf="activeFields.includes('Study') && jobDetailsOrder.study"
                     [ngStyle]="{'order': jobDetailsOrder.study}">
                    <label class="col-md-2 col-form-label">{{'Study' | translate}}</label>
                    <div [ngClass]="
                        {
                        'col-md-4': job.C_Study_key,
                        'col-md-10': !job.C_Study_key
                        }">
                        <study-select class="study-input"
                                      [(model)]="job.C_Study_key"
                                      (modelChange)="onStudyChange()"
                                      [excludeInactive]="true"
                                      [excludeLocked]="true"
                                      [required]="requiredFields.includes('C_Study_key')">
                        </study-select>
                    </div>
                    <ng-container *ngIf="job.C_Study_key && job.Study.ApprovalNumber">
                        <label class="col-md-3 col-form-label">{{'Study' | translate}} Approval Number</label>
                        <div class="col-md-3">
                            <input class="form-control"
                                   name="studyApprovalNumber"
                                   disabled
                                   [(ngModel)]="job.Study.ApprovalNumber" />
                        </div>
                    </ng-container>
                </div>

                <div class="form-group row" *ngIf="activeFields.includes('Title') && jobDetailsOrder.title"
                     [ngStyle]="{'order': jobDetailsOrder.title}">
                    <label class="col-md-2 col-form-label">Title</label>
                    <div class="col-md-10">
                        <input name="jobTitle"
                               class="form-control input-medium job-title-input"
                               [(ngModel)]="job.Goal"
                               [required]="requiredFields.includes('Goal')" />
                    </div>
                </div>

                <div class="form-group row" *ngIf="activeFields.includes('Lead Scientist') && jobDetailsOrder.leadScientist"
                     [ngStyle]="{'order': jobDetailsOrder.leadScientist}">
                    <label class="col-md-2 col-form-label">Lead Scientist</label>
                    <div class="col-md-10">
                        <user-select class="lead-scientist-input"
                                     [(model)]="job.LeadScientist"
                                     [required]="requiredFields.includes('LeadScientist')"
                                     [forceValidSelection]="requiredFields.includes('LeadScientist')">
                        </user-select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Study Monitor') && jobDetailsOrder.studyMonitor"
                     [ngStyle]="{'order': jobDetailsOrder.studyMonitor}">
                    <label class="col-md-2 col-form-label">Study Monitor</label>
                    <div class="col-md-10">
                        <user-select class="study-monitor-input"
                                     [(model)]="job.StudyMonitor"
                                     [required]="requiredFields.includes('StudyMonitor')"
                                     [forceValidSelection]="requiredFields.includes('StudyMonitor')">
                        </user-select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Research Director') && jobDetailsOrder.researchDirector"
                     [ngStyle]="{'order': jobDetailsOrder.researchDirector}">
                    <label class="col-md-2 col-form-label">Research Director</label>
                    <div class="col-md-10">
                        <user-select class="research-director-input"
                                     [(model)]="job.ResearchDirector"
                                     [required]="requiredFields.includes('ResearchDirector')"
                                     [forceValidSelection]="requiredFields.includes('ResearchDirector')">
                        </user-select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Client Manager') && jobDetailsOrder.clientManager"
                     [ngStyle]="{'order': jobDetailsOrder.clientManager}">
                    <label class="col-md-2 col-form-label">{{!isGLP ? 'Client Manager' : 'Alternate Contact'}}</label>
                    <div class="col-md-10">
                        <user-select class="client-manager-input"
                                     [(model)]="job.ClientManager"
                                     [required]="requiredFields.includes('ClientManager')"
                                     [forceValidSelection]="requiredFields.includes('ClientManager')">
                        </user-select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Job Director') && jobDetailsOrder.studyDirector"
                     [ngStyle]="{'order': jobDetailsOrder.studyDirector}">
                    <label class="col-md-2 col-form-label">{{'Job' | translate}} Director</label>
                    <div class="col-md-10">
                        <user-select class="study-director-input"
                                     [(model)]="job.StudyDirector"
                                     [required]="requiredFields.includes('StudyDirector')"
                                     [forceValidSelection]="requiredFields.includes('StudyDirector')"
                                     (modelChange)="getStudyAdminStatus()">
                        </user-select>
                    </div>
                </div>

                <div class="form-group row" *ngIf="activeFields.includes('Description') && jobDetailsOrder.description"
                     [ngStyle]="{'order': jobDetailsOrder.description}">
                    <label class="col-md-2 col-form-label">Description</label>
                    <div class="col-md-10">
                        <textarea name="jobNotes"
                                  class="form-control input-medium job-description-input"
                                  rows="5"
                                  [(ngModel)]="job.Notes"
                                  [required]="requiredFields.includes('Notes')">
                        </textarea>
                    </div>
                </div>
                <div class="form-group row" *ngIf="jobDetailsOrder.line"
                     [ngStyle]="{'order': jobDetailsOrder.line}">
                    <label class="col-md-2 col-form-label">{{'Line' | translate}}</label>
                    <div class="col-md-10">
                        <line-select class="line-input"
                                     [required]="isLineFieldRequired || (requiredFields.includes('JobLine[0]'))"
                                     [(model)]="job.C_Line_key"
                                     (modelChange)="lineChanged()">
                        </line-select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="jobDetailsOrder.species" [ngStyle]="{'order': jobDetailsOrder.species}">
                    <label class="col-md-2 col-form-label">Species</label>
                    <div class="col-md-10">
                        <ng-container *ngIf="job?.C_Line_key && job.Line?.cv_Taxon">
                            <input type="text" name="jobSpecies"
                                   [value]="job.Line.cv_Taxon.CommonName"
                                   readonly="readonly"
                                   class="form-control input-medium species-input" />
                        </ng-container>
                        <small class="form-text text-muted species-input" *ngIf="!job?.C_Line_key">
                            Please specify a {{'Line' | translate}}.
                        </small>
                    </div>
                </div>

                <job-line-table *ngIf="activeFields.includes('Line') && jobDetailsOrder.linesTable"
                                class="lines-table"
                                [ngStyle]="{'order': jobDetailsOrder.linesTable}"
                                [job]="job"
                                (firstLineChanged)="updateJobName('line')"
                                [readonly]="readonly || job.IsLocked"
                                [required]="requiredFields.includes('JobLine[0]') && job.JobLine.length === 0">
                </job-line-table>

                <job-group-table *ngIf="activeFields.includes('Dosing Table') && jobDetailsOrder.groups"
                                 class="groups-table"
                                 [ngStyle]="{'order': jobDetailsOrder.groups}"
                                 [job]="job"
                                 [isCRL]="isCRL"
                                 [isDTX]="isDotmatics"
                                 (busy)="onLoadingChanged($event)"
                                 [readonly]="readonly || job.IsLocked"
                                 [required]="requiredFields.includes('JobGroup.JobGroupTreatment[0]') && job.JobGroup.length === 0">
                </job-group-table>

                <div class="form-group row" *ngIf="activeFields.includes('Order ID(s)')"
                     [ngStyle]="{'order': jobDetailsOrder.orderID}">
                    <label class="col-md-2 col-form-label"
                           [ngClass]="{'required-text' : requiredFields.includes('JobOrder[0]') && job.JobOrder.length === 0}">
                        Order ID(s)
                    </label>
                    <div class="col-md-10">
                        <job-order-multiselect [job]="job"
                                               [readonly]="readonly || job.IsLocked"
                                               [required]="requiredFields.includes('JobOrder[0]')">
                        </job-order-multiselect>
                    </div>
                </div>

                <line-details-table *ngIf="isCRL && activeFields.includes('Line') && jobDetailsOrder.lineDetailsTable"
                                    class="line-details-table"
                                    [ngStyle]="{'order': jobDetailsOrder.lineDetailsTable}"
                                    [job]="job"
                                    (busy)="onLoadingChanged($event)"
                                    [readonly]="readonly || job.IsLocked">
                </line-details-table>

                <div class="form-group row" *ngIf="isCRL && activeFields.includes('Standard Phrases') && jobDetailsOrder.standardPhrases" [ngStyle]="{'order': jobDetailsOrder.standardPhrases}">
                    <label class="col-md-2 col-form-label"
                           [ngClass]="{'required-text' : requiredFields.includes('JobStandardPhrase[0]') && job.JobStandardPhrase.length === 0}">
                        Standard Phrases
                    </label>
                    <div class="standard-phrase-row">
                        <button type="button"
                                class="btn btn-primary form-control standard-phrase-button"
                                (click)="openStandardPhraseChooser(standardphrasemodal)">
                            ...
                        </button>
                        <div class="standard-phrase-box">
                            <ng-container *ngIf="job.JobStandardPhrase.length <= 3">
                                <span *ngFor=" let jobStandardPhrase of job.JobStandardPhrase">
                                    {{jobStandardPhrase.cv_StandardPhrase.StandardPhrase | truncateSentence:50}}
                                    <br />
                                </span>
                            </ng-container>
                            <ng-container *ngIf="job.JobStandardPhrase.length > 3">
                                {{job.JobStandardPhrase.length}} phrases selected
                            </ng-container>
                        </div>
                    </div>
                </div>

                <job-test-article-table *ngIf="!isDotmatics && activeFields.includes('Test Article') && jobDetailsOrder.testArticles"
                                        class="test-articles"
                                        [ngStyle]="{'order': jobDetailsOrder.testArticles}"
                                        [job]="job"
                                        [readonly]="readonly || job.IsLocked"
                                        [required]="true"
                                        [isCRO]="isCRO"
                                        [isCRL]="isCRL"
                                        [required]="requiredFields.includes('JobTestArticle[0]') && job.JobTestArticle.length === 1">
                </job-test-article-table>

                <dotmatics-test-article-table *ngIf="isDotmatics && activeFields.includes('Test Article') && jobDetailsOrder.testArticles && this.dotmaticsTestArticles"
                                              class="test-articles"
                                              [ngStyle]="{'order': jobDetailsOrder.testArticles}"
                                              [job]="job"
                                              [readonly]="readonly || job.IsLocked"
                                              [required]="requiredFields.includes('JobTestArticle[0]')"
                                              [isCRO]="isCRO"
                                              [dotmaticsTestArticles]="dotmaticsTestArticles"
                                              [required]="requiredFields.includes('JobTestArticle[0]') && job.JobTestArticle.length === 1">
                </dotmatics-test-article-table>

                <job-institution-table class="institutions-table"
                                       *ngIf="activeFields.includes('Institutions') && jobDetailsOrder.institutions"
                                       [ngStyle]="{'order': jobDetailsOrder.institutions}"
                                       [job]="job"
                                       [showSiteContact]="isCRO"
                                       [readonly]="readonly || job.IsLocked"
                                       [required]="true"
                                       [isDotmatics]="isDotmatics"
                                       [required]="requiredFields.includes('JobInstitution[0]') && job.JobInstitution.length === 0"
                                       (institutionChange)="refreshDtxTestArticles($event)">
                </job-institution-table>

                <div class="form-group row" *ngIf="activeFields.includes('IACUC Protocol') && jobDetailsOrder.iacuc"
                     [ngStyle]="{'order': jobDetailsOrder.iacuc}">
                    <label class="col-md-2 col-form-label">IACUC Protocol</label>
                    <div class="col-md-10">
                        <active-vocab-select class="iacuc-protocol-input"
                                             [(model)]="job.C_IACUCProtocol_key"
                                             (modelChange)="iacucProtocolChanged()"
                                             [vocabChoices]="iacucProtocols"
                                             [keyFormatter]="iacucProtocolKeyFormatter"
                                             [optionFormatter]="iacucProtocolFormatter"
                                             [required]="isIACUCProtocolFieldRequired || requiredFields.includes('C_IACUCProtocol_key')"
                                             [nullable]="!requiredFields.includes('C_IACUCProtocol_key')">
                        </active-vocab-select>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Compliance') && jobDetailsOrder.compliance"
                     [ngStyle]="{'order': jobDetailsOrder.compliance}">
                    <label class="col-md-2 col-form-label">Compliance</label>
                    <div class="col-md-10">
                        <active-vocab-select class="compliance-input"
                                             [(model)]="job.C_Compliance_key"
                                             (modelChange)="complianceChanged()"
                                             [vocabChoices]="compliances"
                                             [keyFormatter]="complianceKeyFormatter"
                                             [optionFormatter]="complianceFormatter"
                                             [required]="isComplianceFieldRequired || requiredFields.includes('C_Compliance_key')"
                                             [nullable]="!requiredFields.includes('C_Compliance_key')">
                        </active-vocab-select>
                    </div>
                </div>

                <div class="form-group row" *ngIf="jobDetailsOrder.status" [ngStyle]="{'order': jobDetailsOrder.status}">
                    <label class="col-md-2 col-form-label">Status</label>
                    <div class="col-md-10">
                        <active-vocab-select class="status-input"
                                             [(model)]="job.C_JobStatus_key"
                                             (modelChange)="jobStatusChanged()"
                                             [vocabChoices]="jobStatuses"
                                             [keyFormatter]="jobStatusKeyFormatter"
                                             [optionFormatter]="jobStatusFormatter"
                                             [required]="true">
                        </active-vocab-select>
                    </div>
                </div>

                <div class="form-group row" *ngIf="jobDetailsOrder.jobType"
                     [ngStyle]="{'order': jobDetailsOrder.jobType}">
                    <label class="col-md-2 col-form-label">Type</label>
                    <div class="col-md-10">
                        <active-vocab-select class="study-type-input"
                                             [(model)]="job.C_JobType_key"
                                             (modelChange)="typeChanged()"
                                             [vocabChoices]="jobTypes"
                                             [keyFormatter]="jobTypeKeyFormatter"
                                             [optionFormatter]="jobTypeFormatter"
                                             [required]="true">
                        </active-vocab-select>
                    </div>
                </div>

                <div class="form-group row" *ngIf="jobDetailsOrder.jobSubtype"
                     [ngStyle]="{'order': jobDetailsOrder.jobSubtype}">
                    <label class="col-md-2 col-form-label">Subtype</label>
                    <div class="col-md-10">
                        <job-subtype-select [(model)]="job.C_JobSubtype_key"
                                            (modelChange)="subtypeChanged()"
                                            [required]="true">
                        </job-subtype-select>
                    </div>
                </div>

                <div class="form-group row" *ngIf="jobDetailsOrder.imaging && isCRL"
                     [ngStyle]="{'order': jobDetailsOrder.imaging}">
                    <label class="col-md-2 col-form-label">Imaging</label>
                    <div class="col-md-10">
                        <input type="checkbox"
                               class="mt-1"
                               name="imaging"
                               [(ngModel)]="job.Imaging"
                               (ngModelChange)="imagingChanged()" />
                    </div>
                </div>

                <div class="form-group row" *ngIf="jobDetailsOrder.jobReport && isCRL"
                     [ngStyle]="{'order': jobDetailsOrder.jobReport}">
                    <label class="col-md-2 col-form-label">{{'Job' | translate}} Report</label>
                    <div class="col-md-10">
                        <active-vocab-select [(model)]="job.C_JobReport_key"
                                             (modelChange)="reportChanged()"
                                             [vocabChoices]="jobReports"
                                             [keyFormatter]="jobReportKeyFormatter"
                                             [optionFormatter]="jobReportFormatter"></active-vocab-select>
                    </div>
                </div>

                <div class="form-group row" *ngIf="activeFields.includes('Projected Implant Date') && jobDetailsOrder.projectImplantDate"
                     [ngStyle]="{'order': jobDetailsOrder.projectImplantDate}">
                    <label class="col-md-2 col-form-label">Projected Implant Date</label>
                    <div class="col-md-3">
                        <climb-ngb-date name="Projected Implant Date" 
                                        #dateControl="ngModel"
                                        class="projectImplant-date-input"
                                        [(ngModel)]="job.ProjectImplantDate"
                                        [required]="requiredFields.includes('ProjectImplantDate')">
                        </climb-ngb-date>
                    </div>
                    <div class="col-md-3">
                        <p class="form-control-plaintext text-nowrap"
                           *ngIf="(job.ProjectImplantDate | daysFromToday) > 1">
                            ({{job.ProjectImplantDate | daysFromToday}} days ago)
                        </p>
                        <p class="form-control-plaintext text-nowrap"
                           *ngIf="(job.ProjectImplantDate | daysFromToday) === 1">
                            ({{job.ProjectImplantDate | daysFromToday}} day ago)
                        </p>
                    </div>
                </div>

                <div class="form-group row" *ngIf="activeFields.includes('Implanted Date') && jobDetailsOrder.implantedDate"
                     [ngStyle]="{'order': jobDetailsOrder.implantedDate}">
                    <label class="col-md-2 col-form-label">Implanted Date</label>
                    <div class="col-md-3">
                        <climb-ngb-date name="Implanted Date"
                                        #dateControl="ngModel"
                                        class="implantedDate-date-input"
                                        [(ngModel)]="job.ImplantedDate"
                                        [required]="requiredFields.includes('ImplantedDate')">
                        </climb-ngb-date>
                    </div>
                    <div class="col-md-3">
                        <p class="form-control-plaintext text-nowrap"
                           *ngIf="(job.ImplantedDate | daysFromToday) > 1">
                            ({{job.ImplantedDate | daysFromToday}} days ago)
                        </p>
                        <p class="form-control-plaintext text-nowrap"
                           *ngIf="(job.ImplantedDate | daysFromToday) === 1">
                            ({{job.ImplantedDate | daysFromToday}} day ago)
                        </p>
                    </div>
                </div>

                <div class="form-group row" *ngIf="activeFields.includes('Start Date') && jobDetailsOrder.projectedStartDate"
                     [ngStyle]="{'order': jobDetailsOrder.projectedStartDate}">
                    <label class="col-md-2 col-form-label">Projected Start Date</label>
                    <div class="col-md-3">
                        <climb-ngb-date name="Projected Start Date"
                                        #dateControl="ngModel"
                                        class="start-date-input"
                                        [(ngModel)]="job.ProjectedStartDate"
                                        [required]="requiredFields.includes('ProjectedStartDate')"
                                        #projectedStartDate>
                        </climb-ngb-date>
                    </div>
                    <div class="col-md-3">
                        <p class="form-control-plaintext text-nowrap"
                           *ngIf="(job.ProjectedStartDate | daysFromToday) > 1">
                            ({{job.ProjectedStartDate | daysFromToday}} days ago)
                        </p>
                        <p class="form-control-plaintext text-nowrap"
                           *ngIf="(job.ProjectedStartDate | daysFromToday) === 1">
                            ({{job.ProjectedStartDate | daysFromToday}} day ago)
                        </p>
                    </div>
                </div>

                <div class="form-group row" *ngIf="activeFields.includes('Start Date') && jobDetailsOrder.startDate"
                     [ngStyle]="{'order': jobDetailsOrder.startDate}">
                    <label class="col-md-2 col-form-label">Start Date</label>
                    <div class="col-md-3">
                        <climb-ngb-date name="Start Date"
                                        #dateControl="ngModel"
                                        class="start-date-input"
                                        [(ngModel)]="job.DateStarted"
                                        (ngModelChange)="startDateChanged(dateStarted)"
                                        [required]="requiredFields.includes('DateStarted')"
                                        #dateStarted
                                        ngDefaultControl>
                        </climb-ngb-date>
                    </div>
                    <div class="col-md-3">
                        <p class="form-control-plaintext text-nowrap"
                           *ngIf="(job.DateStarted | daysFromToday) > 1">
                            ({{job.DateStarted | daysFromToday}} days ago)
                        </p>
                        <p class="form-control-plaintext text-nowrap"
                           *ngIf="(job.DateStarted | daysFromToday) === 1">
                            ({{job.DateStarted | daysFromToday}} day ago)
                        </p>
                    </div>
                </div>

                <!-- Job Duration Days-->
                <div class="form-group row" *ngIf="activeFields.includes('Job Duration (days)')"
                     [ngStyle]="{'order': jobDetailsOrder.durationDays}">
                    <label class="col-md-2 col-form-label">{{'Job' | translate}} Duration (days)</label>
                    <div class="col-md-1">
                        <input type="number"
                               id="duration-days"
                               name="durationDays"
                               [(ngModel)]="job.DurationDays"
                               (ngModelChange)="durationDaysChanged()"
                               (focusout)="extendJob($event)"
                               [disabled]="disableDuration"
                               step="any"
                               min="1"
                               oninput="this.value = this.value == 0 ? null : Math.abs(this.value)"
                               decimalFormatter
                               [decimalPlaces]="0"
                               [required]="requiredFields.includes('DurationDays')"
                               class="form-control input-medium durationdays-input" />
                    </div>
                </div>

                <div class="form-group row" *ngIf="activeFields.includes('End Date') && jobDetailsOrder.endDate"
                     [ngStyle]="{'order': jobDetailsOrder.endDate}">
                    <label class="col-md-2 col-form-label">End Date</label>
                    <div class="col-md-3">
                        <climb-ngb-date name="End Date"
                                        #dateControl="ngModel"
                                        class="end-date-input"
                                        [(ngModel)]="job.DateEnded"
                                        (ngModelChange)="endDateChanged(dateEnded)"
                                        [required]="requiredFields.includes('DateEnded')"
                                        #dateEnded
                                        ngDefaultControl>
                        </climb-ngb-date>
                    </div>
                    <div class="col-md-3">
                        <p class="form-control-plaintext text-nowrap"
                           *ngIf="(job.DateEnded | daysFromToday) > 1">
                            ({{job.DateEnded | daysFromToday}} days ago)
                        </p>
                        <p class="form-control-plaintext text-nowrap"
                           *ngIf="(job.DateEnded | daysFromToday) === 1">
                            ({{job.DateEnded | daysFromToday}} day ago)
                        </p>
                    </div>
                </div>

                <job-location-table class="locations-table"
                                    *ngIf="activeFields.includes('Locations') && jobDetailsOrder.location"
                                    [ngStyle]="{'order': jobDetailsOrder.location}"
                                    [job]="job"
                                    [readonly]="readonly || job.IsLocked"
                                    [required]="requiredFields.includes('JobLocation[0]') && job.JobLocation.length === 0">
                </job-location-table>

                <fieldset class="fieldset-bordered" [ngStyle]="{'order': jobDetailsOrder.characteristics}"
                          *ngIf="jobDetailsOrder.characteristics && job.JobCharacteristicInstance.length > 0">
                    <legend>Characteristics</legend>
                    <div class="form-group row"
                         *ngFor="let characteristicInstance of job.JobCharacteristicInstance |
                        orderBy:'JobCharacteristic.SortOrder'">
                        <div class="col-md-12">
                            <label>
                                {{characteristicInstance?.CharacteristicName}}
                            </label>
                            <characteristic-input #characteristicInput
                                                  [characteristic]="characteristicInstance"
                                                  [readonly]="readonly || job.IsLocked"
                                                  [(value)]="characteristicInstance.CharacteristicValue"
                                                  (lostFocus)="characteristicValueChanged($event, characteristicInstance)">
                            </characteristic-input>
                        </div>
                    </div>
                </fieldset>

                <div class="form-group row" *ngIf="jobDetailsOrder.costDuration" [ngStyle]="{'order': jobDetailsOrder.costDuration}">
                    <!--Cost-->
                    <label class="col-md-2 col-form-label">Cost</label>
                    <div class="col-md-4">
                        <div class="input-group">
                            <input type="number"
                                   name="jobCost"
                                   [(ngModel)]="job.Cost"
                                   step="0.01"
                                   min="0.00"
                                   class="form-control input-medium cost-input"
                                   moneyFormatter />
                        </div>
                    </div>

                    <!--Duration-->
                    <label class="col-md-2 col-form-label">Duration (minutes)</label>
                    <div class="col-md-4">
                        <div class="input-group">
                            <input type="number" name="jobDuration"
                                   class="form-control input-medium duration-input"
                                   min="0"
                                   step="any"
                                   decimalFormatter
                                   [decimalPlaces]="2"
                                   [(ngModel)]="job.Duration" />
                        </div>
                    </div>
                </div>

                <div class="form-group row" *ngIf="jobDetailsOrder.created"
                     style="margin-top: 1em;"
                     [ngStyle]="{'order': jobDetailsOrder.created}">
                    <label class="col-md-2 col-form-label">Created</label>
                    <div class="col-md-10">
                        <p class="form-control-plaintext">
                            {{job.CreatedBy | userFullName}}
                            &middot;
                            {{job.DateCreated | formatShortDate}}
                        </p>
                    </div>
                </div>

                <div class="form-group row" *ngIf="jobDetailsOrder.files" [ngStyle]="{'order': jobDetailsOrder.files}">
                    <label class="col-md-2 col-form-label">Files</label>
                    <div class="col-md-10">
                        <div class="form-control-plaintext">
                            <facet-detail-file-upload class="file-upload-input"
                                                      [pkName]="'C_Job_key'"
                                                      [pkValue]="job.C_Job_key"
                                                      [facetPrivilege]="facet.Privilege"
                                                      [disabled]="job.IsLocked">
                            </facet-detail-file-upload>
                        </div>
                    </div>
                </div>

            </div>
            
            <job-pharma-report-table 
                    #jobPharmaReportsTable
                    [job]="job"
                    [ngStyle]="{'order': jobDetailsOrder.reports, 'display': detailsExpanded ? '' : 'none'}"
                ></job-pharma-report-table>
            <nav ngbNav
                 #navTasks="ngbNav"
                 class="nav-tabs job-pharma-tabset tasks expand-{{numExpanded}} mt-2 px-0"
                 [destroyOnHide]="true"
                 [activeId]="'tab-tasks-list-' + domIdAddition"
                 (navChange)="onNavChange('tasks', $event)"
                 [attr.aria-label]="INFO_TOOLTIP_TASKS">
                <ng-container [ngbNavItem]="'tab-tasks-label-' + domIdAddition"
                              [domId]="'tab-tasks-label-' + domIdAddition">
                    <span class="nav-item" [ngClass]="{ closed: !expand.tasks }">
                        <climb-expand [(model)]="expand.tasks"
                                      (modelChange)="onExpandChange()"
                                      label="Tasks"></climb-expand>
                        Tasks
                        <info-tooltip
                            class="tool-tip"
                            [text]="INFO_TOOLTIP_TASKS">
                        </info-tooltip>
                    </span>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-tasks-outline-' + domIdAddition"
                              [domId]="'tab-tasks-outline-' + domIdAddition">
                    <a ngbNavLink>Outline</a>
                    <ng-template ngbNavContent>
                        <job-pharma-tasks-outline-table [job]="job"
                                                         [readonly]="readonly || job.IsLocked"
                                                         [numItemsExpanded]="numExpanded"
                                                         [isCRO]="isCRO"
                                                         [isGLP]="isGLP"
                                                         [isStudyDirector]="isStudyDirector"
                                                         (busy)="onLoadingChanged($event)">
                        </job-pharma-tasks-outline-table>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-tasks-list-' + domIdAddition"
                              [domId]="'tab-tasks-list-' + domIdAddition">
                    <a ngbNavLink>List</a>
                    <ng-template ngbNavContent>
                        <job-pharma-tasks-list-table [job]="job"
                                                     [readonly]="readonly || job.IsLocked"
                                                     [numItemsExpanded]="numExpanded"
                                                     [isCRO]="isCRO"
                                                     [isCRL]="isCRL"
                                                     [isGLP]="isGLP"
                                                     [isStudyDirector]="isStudyDirector"
                                                     [facet]="facet"
                                                     (busy)="onLoadingChanged($event)">
                        </job-pharma-tasks-list-table>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-tasks-schedule-' + domIdAddition"
                              [domId]="'tab-tasks-schedule-' + domIdAddition">
                    <a ngbNavLink>Schedule</a>
                    <ng-template ngbNavContent>
                        <job-pharma-tasks-schedule-table [job]="job"
                                                         [readonly]="readonly || job.IsLocked"
                                                         [isStudyDirector]="isStudyDirector"
                                                         (busy)="onLoadingChanged($event)">
                        </job-pharma-tasks-schedule-table>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-tasks-inputs-' + domIdAddition"
                              [domId]="'tab-tasks-inputs-' + domIdAddition">
                    <a ngbNavLink>Inputs</a>
                    <ng-template ngbNavContent>
                        <job-pharma-tasks-inputs-table [job]="job"
                                                       [isStudyDirector]="isStudyDirector"
                                                       [readonly]="readonly || job.IsLocked">
                        </job-pharma-tasks-inputs-table>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-tasks-configure-' + domIdAddition"
                              [domId]="'tab-tasks-configure-' + domIdAddition">
                    <span class="nav-item d-inline-flex align-items-center">
                        <button (click)="tabActionCreateSampleGroups()"
                                class="btn btn-secondary mr-1"
                                [disabled]="activeTabs?.tasks === 'outline'"
                                title="Create Sample Groups">
                            <i class="fa fa-cubes" aria-hidden="true"></i>
                        </button>
                        <ng-template [ngTemplateOutlet]="columnSelect"
                                     [ngTemplateOutletContext]="{tabset: 'tasks'}">
                        </ng-template>
                    </span>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="navTasks"
                 [ngClass]="{'d-none': !expand.tasks}"
                 class="expand-{{numExpanded}}">
            </div>

            <nav data-automation-id="animals-row"
                 ngbNav
                 #navAnimals="ngbNav"
                 class="nav-tabs job-pharma-tabset expand-{{numExpanded}} mt-2 px-0"
                 [destroyOnHide]="true"
                 [activeId]="'tab-animals-cohorts-' + domIdAddition"
                 (navChange)="onNavChange('animals', $event)"
                 [attr.aria-label]="INFO_TOOLTIP_ANIMALS">
                <ng-container [ngbNavItem]="'tab-animals-label-' + domIdAddition"
                              [domId]="'tab-animals-label-' + domIdAddition">
                    <span class="nav-item" [ngClass]="{ closed: !expand.animals }">
                        <climb-expand [(model)]="expand.animals"
                                      (modelChange)="onExpandChange()"
                                      label="Animals">
                        </climb-expand>
                        Animals
                        <info-tooltip class="tool-tip"
                                      [text]="INFO_TOOLTIP_ANIMALS">
                        </info-tooltip>
                    </span>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-animals-cohorts-' + domIdAddition"
                              [domId]="'tab-animals-cohorts-' + domIdAddition">
                    <a data-automation-id="cohorts-tab" 
                       ngbNavLink>
                    Cohorts
                    </a>
                    <ng-template ngbNavContent>
                        <job-pharma-animals-cohorts-table [job]="job"
                                                          [readonly]="readonly || job.IsLocked"
                                                          [isCRO]="isCRO"
                                                          [isGLP]="isGLP"
                                                          (busy)="onLoadingChanged($event)">
                        </job-pharma-animals-cohorts-table>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="isCRO"
                              [ngbNavItem]="'tab-animals-placeholder-' + domIdAddition"
                              [domId]="'tab-animals-placeholder-' + domIdAddition">
                    <a ngbNavLink>Group Placeholders</a>
                    <ng-template ngbNavContent>
                        <job-pharma-animals-placeholder-table [job]="job"
                                                              [readonly]="readonly || job.IsLocked"
                                                              (busy)="onLoadingChanged($event)">
                        </job-pharma-animals-placeholder-table>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-animals-individual-' + domIdAddition"
                              [domId]="'tab-animals-individual-' + domIdAddition">
                    <a data-automation-id="individual-tab" 
                       ngbNavLink>
                    Individual
                    </a>
                    <ng-template ngbNavContent>
                        <job-pharma-animals-individual-table [facet]="facet"
                                                             [job]="job"
                                                             [readonly]="readonly || job.IsLocked"
                                                             [isGLP]="isGLP"
                                                             (busy)="onLoadingChanged($event)">
                        </job-pharma-animals-individual-table>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="isCRO"
                              [ngbNavItem]="'tab-animals-individual-placeholder-' + domIdAddition"
                              [domId]="'tab-animals-individual-placeholder-' + domIdAddition">
                    <a ngbNavLink>Animal Placeholders</a>
                    <ng-template ngbNavContent>
                        <job-pharma-animals-individual-placeholder-table [job]="job"
                                                                         [readonly]="readonly || job.IsLocked"
                                                                         (busy)="onLoadingChanged($event)">
                        </job-pharma-animals-individual-placeholder-table>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-animals-configure-' + domIdAddition"
                              [domId]="'tab-animals-configure-' + domIdAddition">
                    <span class="nav-item d-inline-flex align-items-center">
                        <button *ngIf="isCRO"
                                (click)="tabActionCreatePlaceholderAssociation()"
                                class="btn btn-secondary mr-1"
                                [title]="hasSelectedPlaceholders() ? 'Link Placeholders to Cohorts' : 'You must first select placeholders to use the link function'"
                                [disabled]="!hasSelectedPlaceholders()">
                            <i class="fa fa-link" aria-hidden="true"></i>
                        </button>
                        <ng-template [ngTemplateOutlet]="columnSelect"
                                     [ngTemplateOutletContext]="{tabset: 'animals'}">
                        </ng-template>
                    </span>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="navAnimals"
                 [ngClass]="{'d-none': !expand.animals}"
                 class="expand-{{numExpanded}}">
            </div>

            <nav ngbNav
                 #navSamples="ngbNav"
                 class="nav-tabs job-pharma-tabset expand-{{numExpanded}} mt-2 px-0"
                 [destroyOnHide]="true"
                 [activeId]="'tab-samples-groups-' + domIdAddition"
                 (navChange)="onNavChange('samples', $event)"
                 [attr.aria-label]="INFO_TOOLTIP_SAMPLES">
                <ng-container [ngbNavItem]="'tab-samples-label-' + domIdAddition"
                              [domId]="'tab-samples-label-' + domIdAddition">
                    <span class="nav-item" [ngClass]="{ closed: !expand.samples }">
                        <climb-expand [(model)]="expand.samples"
                                      (modelChange)="onExpandChange()"
                                      label="Samples">
                        </climb-expand>
                        Samples
                        <info-tooltip
                            class="tool-tip"
                            [text]="INFO_TOOLTIP_SAMPLES">
                        </info-tooltip>
                    </span>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-samples-groups-' + domIdAddition"
                              [domId]="'tab-samples-groups-' + domIdAddition">
                    <a ngbNavLink>Groups</a>
                    <ng-template ngbNavContent>
                        <job-pharma-samples-groups-table #samplesGroupsTable
                                                         *ngIf="expand && expand.samples"
                                                         [job]="job"
                                                         [facet]="facet"
                                                         [facetView]="facetView"
                                                         [activeFields]="samplesActiveFields"
                                                         [requiredFields]="samplesRequiredFields"
                                                         [readonly]="readonly || job.IsLocked">
                                                         (busy)="onLoadingChanged($event)"
                        </job-pharma-samples-groups-table>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-samples-individual-' + domIdAddition"
                              [domId]="'tab-samples-individual-' + domIdAddition">
                    <a ngbNavLink>Individual</a>
                    <ng-template ngbNavContent>
                        <job-pharma-samples-individual-table [job]="job"
                                                             [activeFields]="samplesActiveFields"
                                                             (selectedRowsChange)="onSelectedRowsChange($event)"
                                                             [readonly]="readonly || job.IsLocked">
                        </job-pharma-samples-individual-table>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="'tab-samples-configure-' + domIdAddition"
                              [domId]="'tab-samples-configure-' + domIdAddition">
                    <span class="nav-item d-inline-flex align-items-center">
                        <button *ngIf="activeTabs?.samples == 'individual'" type="button"
                                class="btn btn-secondary mr-1"
                                title="Print label for selected samples"
                                [disabled]="selectedISRows.length === 0"
                                (click)="openLabelModal(labelmodal)">
                            <i class="fa fa-print" aria-hidden="true"></i>
                            <span class="gridster-d-sm-none gridster-d-md-inline-block">Labels</span>
                            <span class="sr-only">Print Labels</span>
                        </button>
                        <button (click)="tabActionCreateIndividualSamples()"
                                class="btn btn-secondary mr-1"
                                title="Create Individual Samples"
                                *ngIf="this.sampleNamingActive">
                            <img src="/images/samples.png" width="12" height="12" alt=" " />
                        </button>
                        <ng-template [ngTemplateOutlet]="columnSelect"
                                     [ngTemplateOutletContext]="{tabset: 'samples'}">
                        </ng-template>
                    </span>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="navSamples"
                 [ngClass]="{'d-none': !expand.samples}"
                 class="expand-{{numExpanded}}">
            </div>
        </fieldset>
    </form>
</div>

<ng-template #columnSelect let-tabset="tabset">
    <climb-column-select [columnLabels]="jobPharmaDetailService.columnSelectMap[tabset][activeTabs[tabset]]"
                         (onChange)="jobPharmaDetailService.columnSelectChanged(tabset, activeTabs[tabset], $event)"
                         [disabled]="!jobPharmaDetailService.columnSelectMap[tabset][activeTabs[tabset]]"
                         class="old-fashioned">
    </climb-column-select>
</ng-template>
<ng-template #labelmodal let-c="close">
    <label-chooser [samples]="selectedISRows"
                   (close)="c('Close')">
    </label-chooser>
</ng-template>
<ng-template #standardphrasemodal let-c="close">
    <standard-phrase-chooser [isCRL]="isCRL" [selectedStandardPhrases]="job.JobStandardPhrase"
                             (close)="c('Close')"
                             (onSelect)="onSelectStandardPhrase($event)">
    </standard-phrase-chooser>
</ng-template>
