<form novalidate>
    <div class="modal-header justify-content-center">
        <h4 class="modal-title">
            {{'Job' | translate}} Audit Report
        </h4>
    </div>

    <div class="modal-body modal-body-tall">

        <loading-overlay *ngIf="loading"></loading-overlay>

        <h2>
            {{job?.JobID}}
        </h2>

        <div class="audit-report-section">
            <table *ngIf="!isCRO" class="table audit-table">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                        <th>Code</th>
                        <th>{{'Study' | translate}}</th>
                        <th>Title</th>
                        <th>Lead Scientist</th>
                        <th>Study Monitor</th>
                        <th>Description</th>
                        <th>{{'Line' | translate}}</th>
                        <th>IACUC Protocol</th>
                        <th>Compliance</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Projected Start Date</th>
                        <th>Start Date</th>
                        <th>Duration Days</th>
                        <th>End Date</th>
                        <th>Locations</th>
                        <th>Cost</th>
                        <th>Duration Minutes</th>
                        <th>Locked</th>
                        <th *ngIf="isGLP">Reason For Change</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td class="text-nowrap">{{auditRow.JobID}}</td>
                        <td class="text-nowrap">{{auditRow.JobCode}}</td>
                        <td class="text-nowrap">{{auditRow.Study}}</td>
                        <td>
                            <div class="long-text">
                                {{auditRow.Goal}}
                            </div>
                        </td>
                        <td>
                            <div class="long-text">
                                {{auditRow.LeadScientist}}
                            </div>
                        </td>
                        <td>
                            <div class="long-text">
                                {{auditRow.StudyMonitor}}
                            </div>
                        </td>
                        <td>
                            <div class="long-text">
                                {{auditRow.Notes}}
                            </div>
                        </td>
                        <td class="text-nowrap">{{auditRow.Line}}</td>
                        <td class="text-nowrap">{{auditRow.IACUCProtocol}}</td>
                        <td class="text-nowrap">{{auditRow.Compliance}}</td>
                        <td>{{auditRow.JobStatus}}</td>
                        <td>{{auditRow.JobType}}</td>
                        <td>{{auditRow.ProjectedStartDate | formatShortDateUtc}}</td>
                        <td>{{auditRow.DateStarted | formatShortDateUtc}}</td>
                        <td>{{auditRow.DurationDays}}</td>
                        <td>{{auditRow.DateEnded | formatShortDateUtc}}</td>
                        <td class="text-nowrap">{{auditRow.CurrentLocationPath}}</td>
                        <td class="num-cell">{{auditRow.Cost}}</td>
                        <td class="num-cell">{{auditRow.Duration}}</td>
                        <td>{{auditRow.IsLocked}}</td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="isCRO" class="table audit-table">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Research Director</th>
                        <th>{{!isGLP ? 'Client Manager' : 'Alternate Contact'}}</th>
                        <th>Study Director</th>
                        <th>IACUC Protocol</th>
                        <th>Compliance</th>
                        <th>Status</th>
                        <th>Locations</th>
                        <th>{{'Study' | translate}}</th>
                        <th>Type</th>
                        <th>Subtype</th>
                        <th *ngIf="isCRL">Imaging</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Overage (%)</th>
                        <th>{{'Job' | translate}} Report</th>
                        <th>Projected Implant Date</th>
                        <th>Implanted Date</th>
                        <th>Projected Start Date</th>
                        <th>Start Date</th>
                        <th>Duration Days</th>
                        <th>End Date</th>
                        <th>Locked</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td class="text-nowrap">{{auditRow.JobID}}</td>
                        <td class="text-nowrap">{{auditRow.JobCode}}</td>
                        <td>
                            <div class="long-text">
                                {{auditRow.ResearchDirector}}
                            </div>
                        </td>
                        <td>
                            <div class="long-text">
                                {{auditRow.ClientManager}}
                            </div>
                        </td>
                        <td>
                            <div class="long-text">
                                {{auditRow.StudyDirector}}
                            </div>
                        </td>
                        <td class="text-nowrap">{{auditRow.IACUCProtocol}}</td>
                        <td class="text-nowrap">{{auditRow.Compliance}}</td>
                        <td>{{auditRow.JobStatus}}</td>
                        <td class="text-nowrap">{{auditRow.CurrentLocationPath}}</td>
                        <td class="text-nowrap">{{auditRow.Study}}</td>
                        <td>{{auditRow.JobType}}</td>
                        <td>{{auditRow.JobSubtype}}</td>
                        <td *ngIf="isCRL">{{auditRow.Imaging}}</td>
                        <td>
                            <div class="long-text">
                                {{auditRow.Goal}}
                            </div>
                        </td>
                        <td>
                            <div class="long-text">
                                {{auditRow.Notes}}
                            </div>
                        </td>
                        <td>{{auditRow.JobGroupOverage}}</td>
                        <td *ngIf="isCRL">{{auditRow.JobReport}}</td>
                        <td>{{auditRow.ProjectImplantDate | formatShortDateUtc}}</td>
                        <td>{{auditRow.ImplantedDate | formatShortDateUtc}}</td>
                        <td>{{auditRow.ProjectedStartDate | formatShortDateUtc}}</td>
                        <td>{{auditRow.DateStarted | formatShortDateUtc}}</td>
                        <td>{{auditRow.DurationDays}}</td>
                        <td>{{auditRow.DateEnded | formatShortDateUtc}}</td>
                        <td>{{auditRow.IsLocked}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="isCRO" class="audit-report-section">
            <h3>{{'Line' | translate}}s</h3>

            <p *ngIf="!(jobAuditLinesData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditLinesData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>{{'Line' | translate}} Name</th>
                        <th>Species</th>
                        <th *ngIf="isGLP">Reason For Change</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditLinesData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.LineName}}</td>
                        <td>{{auditRow.Species}}</td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div *ngIf="isCRO" class="audit-report-section">
            <h3>{{'Line' | translate}} Details</h3>

            <p *ngIf="!(jobAuditLineDetailsData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditLineDetailsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>{{'Line' | translate}}</th>
                        <th>Sex</th>
                        <th>Min Age</th>
                        <th>Max Age</th>
                        <th>Origin</th>
                        <th *ngIf="isGLP">Reason For Change</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditLineDetailsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.LineName}}</td>
                        <td>{{auditRow.Sex}}</td>
                        <td>{{auditRow.MinAge}}</td>
                        <td>{{auditRow.MaxAge}}</td>
                        <td>{{auditRow.MaterialOrigin}}</td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div class="audit-report-section">
            <h3>Test Articles</h3>

            <p *ngIf="!(jobAuditTestArticlesData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditTestArticlesData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Test Article</th>
                        <th>Batch</th>
                        <th *ngIf="isCRO">Vehicle</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditTestArticlesData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.TestArticle}}</td>
                        <td>{{auditRow.Batch}}</td>
                        <td *ngIf="isCRO">{{auditRow.Vehicle}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="isCRO" class="audit-report-section">
            <h3>Dosing Table</h3>

            <p *ngIf="!(jobAuditJobGroupsData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditJobGroupsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Group</th>
                        <th>Number</th>
                        <th>Treatment</th>
                        <th>Formulation Dose</th>
                        <th>Active Dose</th>
                        <th>Active Unit</th>
                        <th *ngIf="!isCRL">Concentration</th>
                        <th>Route</th>
                        <th>Dosing Volume</th>
                        <th>Dosing Unit</th>
                        <th>Schedule</th>
                        <th>Protocol</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditJobGroupsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.Group}}</td>
                        <td>{{auditRow.Number}}</td>
                        <td>{{auditRow.Treatment}}</td>
                        <td>{{auditRow.FormulationDose}}</td>
                        <td>{{auditRow.ActiveDose}}</td>
                        <td>{{auditRow.ActiveUnit}}</td>
                        <td *ngIf="!isCRL">{{auditRow.Concentration}}</td>
                        <td>{{auditRow.Route}}</td>
                        <td>{{auditRow.DosingVolume}}</td>
                        <td>{{auditRow.DosingUnit}}</td>
                        <td>{{auditRow.Schedule}}</td>
                        <td *ngIf="isCRO">{{auditRow.Protocol}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>{{'Institution' | translate}}s</h3>

            <p *ngIf="!(jobAuditInstitutionData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditInstitutionData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>{{'Institution' | translate}}</th>
                        <th>Site</th>
                        <th *ngIf="isCRO">Scientific Contact</th>
                        <th *ngIf="isCRO">Billing Contact</th>
                        <th *ngIf="isCRO">Authorization Contact</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditInstitutionData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.InstitutionName }}</td>
                        <td>{{auditRow.Sitename }}</td>
                        <td *ngIf="isCRO">{{auditRow.ScientificContactPerson }}</td>
                        <td *ngIf="isCRO && !isCRL">{{auditRow.BillingContactPerson }}</td>
                        <td *ngIf="isCRO && isCRL">{{auditRow.JobInstitutionBillingContacts }}</td>
                        <td *ngIf="isCRO">{{auditRow.AuthorizationContactPerson }}</td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div class="audit-report-section">
            <h3>Characteristics</h3>

            <p *ngIf="!(jobAuditCharacteristicsData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditCharacteristicsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                        <th>Value</th>
                        <th *ngIf="isGLP">Reason For Change</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditCharacteristicsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.CharacteristicName}}</td>
                        <td>{{auditRow.CharacteristicValue}}</td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div *ngIf="isCRL" class="audit-report-section">
            <h3>Standard Phrases</h3>

            <p *ngIf="!(jobAuditStandardPhrasesData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditStandardPhrasesData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Standard Phrase</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditStandardPhrasesData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.StandardPhrase | slice:0:50}}<ng-container *ngIf="auditRow.StandardPhrase.length > 50">...</ng-container></td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div class="audit-report-section">
            <h3>Animals</h3>

            <p *ngIf="!(jobAuditAnimalsData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditAnimalsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                        <th *ngIf="isGLP">Date In</th>
                        <th *ngIf="isGLP">Date Out</th>
                        <th *ngIf="isGLP">Reason For Change</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditAnimalsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.MaterialName}}</td>
                        <td *ngIf="isGLP">{{auditRow.DateIn | formatUtcDateTime}}</td>
                        <td *ngIf="isGLP">{{auditRow.DateOut | formatUtcDateTime}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Cohorts</h3>

            <p *ngIf="!(jobAuditCohortsData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditCohortsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditCohortsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.CohortName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Group Placeholders</h3>

            <p *ngIf="!(jobAuditGroupPlaceholdersData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditGroupPlaceholdersData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Placeholder</th>
                        <th>Cohort</th>
                        <th *ngIf="isGLP">Reason For Change</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditGroupPlaceholdersData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.PlaceholderName}}</td>
                        <td>{{auditRow.CohortName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Animal Placeholders</h3>

            <p *ngIf="!(jobAuditAnimalPlaceholdersData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditAnimalPlaceholdersData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Placeholder</th>
                        <th>Animal</th>
                        <th>Group Placeholder</th>
                        <th *ngIf="isGLP">Reason For Change</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditAnimalPlaceholdersData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.PlaceholderName}}</td>
                        <td>{{auditRow.AnimalName}}</td>
                        <td>{{auditRow.GroupPlaceholderName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Samples</h3>

            <p *ngIf="!(jobAuditSamplesData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table" *ngIf="jobAuditSamplesData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Name</th>
                        <th *ngIf="isGLP">Reason For Change</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditSamplesData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.MaterialName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="audit-report-section">
            <h3>Tasks</h3>

            <p *ngIf="!(jobAuditCurrentTasksData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table"
                   *ngIf="jobAuditCurrentTasksData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Task Name</th>
                        <th>Task</th>
                        <th>Sequence</th>
                        <th>Due Date</th>
                        <th>Deviation</th>
                        <th>Task Location</th>
                        <th>Complete Date</th>
                        <th>Protocol</th>
                        <th>Status</th>
                        <th>Assigned To</th>
                        <th>Cost</th>
                        <th>Duration</th>
                        <th>Locked</th>
                        <th *ngIf="isGLP">Reason For Change</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditCurrentTasksData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td class="text-nowrap">{{auditRow.TaskAlias}}</td>
                        <td class="text-nowrap">{{auditRow.TaskName}}</td>
                        <td class="num-cell">{{auditRow.Sequence}}</td>
                        <td>{{auditRow.DateDue | formatShortDateOrTimeUtc}}</td>
                        <td>{{auditRow.Deviation}}</td>
                        <td>{{auditRow.CurrentLocationPath}}</td>
                        <td>{{auditRow.DateComplete | formatShortDateOrTimeUtc}}</td>
                        <td>{{auditRow.ProtocolName}}</td>
                        <td>{{auditRow.TaskStatus}}</td>
                        <td>{{auditRow.AssignedTo}}</td>
                        <td class="num-cell">{{auditRow.Cost}}</td>
                        <td class="num-cell">{{auditRow.Duration}}</td>
                        <td>{{auditRow.IsLocked}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="showSampleGroups" class="audit-report-section">
            <h3>Sample Groups</h3>

            <p *ngIf="!(jobAuditSampleGroupsData | notEmpty)" class="no-data-message">
                {{noDataMessage}}
            </p>

            <table class="table audit-table" *ngIf="jobAuditSampleGroupsData | notEmpty">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Modified Date</th>
                        <th>Modified By</th>
                        <th>Task Name</th>
                        <th>Samples per Source</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Preservation</th>
                        <th>Container</th>
                        <th>Harvest Date</th>
                        <th>Expiration Date</th>
                        <th>Time Point</th>
                        <th>Subtype</th>
                        <th>Processing</th>
                        <th>Send To</th>
                        <th>Analysis</th>
                        <th *ngIf="isGLP">Reason For Change</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let auditRow of jobAuditSampleGroupsData"
                        [ngClass]="{'current-value' : auditRow.IsCurrent, 'expired-value': !auditRow.IsCurrent}">
                        <td class="text-nowrap">
                            <update-type-cell [updateType]="auditRow.UpdateType"></update-type-cell>
                        </td>
                        <td>{{auditRow.ModifiedDate | formatUtcDateTime}}</td>
                        <td>{{auditRow.ModifiedBy | userFullName}}</td>
                        <td>{{auditRow.TaskAlias}}</td>
                        <td>{{auditRow.SamplesPerSource}}</td>
                        <td>{{auditRow.SampleType}}</td>
                        <td>{{auditRow.SampleStatus}}</td>
                        <td>{{auditRow.PreservationMethod}}</td>
                        <td>{{auditRow.ContainerType}}</td>
                        <td>{{auditRow.DateHarvest}}</td>
                        <td>{{auditRow.DateExpiration}}</td>
                        <td>{{auditRow.TimePoint}}</td>
                        <td>{{auditRow.SampleSubtype}}</td>
                        <td>{{auditRow.SampleProcessingMethod}}</td>
                        <td>{{auditRow.SendTo}}</td>
                        <td>{{auditRow.SampleAnalysisMethod}}</td>
                    </tr>
                </tbody>
            </table>

        </div>

    </div><!-- /.modal-body -->

    <div class="modal-footer">
        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="exportAudit()"
                [disabled]="loading">
            <i class="fa fa-download" aria-hidden="true"></i>
            Export
        </button>

        <button type="button"
                class="btn btn-fw btn-lg btn-secondary"
                (click)="onCancel()">
            Close
        </button>
    </div><!-- /.modal-footer -->
</form>
