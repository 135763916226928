import {
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter
} from '@angular/core';

import { SaveRecordsOverlayEvent } from './pharma/tables';
import { TranslationService } from '../services/translation.service';
import { JobVocabService } from './job-vocab.service';


@Component({
    selector: 'line-details-table',
    templateUrl: './line-details-table.component.html'
})
export class LineDetailsTableComponent implements OnInit {
    @Input() job: any;
    @Input() readonly: boolean;
    @Output() busy: EventEmitter<SaveRecordsOverlayEvent> = new EventEmitter<SaveRecordsOverlayEvent>();

    bulk: any = null;

    enumerationItems: any[];

    // Cvs
    sexes: any[] = [];
    materialOrigins: any[] = [];

    tableExpanded: boolean = true;

    constructor(
        public translationService: TranslationService,
        private jobVocabService: JobVocabService,
    ) {
        //
    }

    // lifecycle
    ngOnInit() {
        this.initBulkValues();
        this.getCVs();
    }

    initBulkValues() {
        this.bulk = {
            line: null,
            sex: null,
            minAge: null,
            maxAge: null,
            origin: null,
            force: false
        };        
    }

    private getCVs(): Promise<any> {
        this.jobVocabService.sexes$.subscribe((sexes) => {
            this.sexes = sexes;
        });

        this.jobVocabService.materialOrigins$.subscribe((materialOrigins) => {
            this.materialOrigins = materialOrigins;
        });

        return Promise.resolve();
    }

    bulkLineChanged() {
        for (let jobGroup of this.job.JobGroup) {
            if (this.bulk.force || jobGroup.C_Line_key === null) {
                jobGroup.C_Line_key = this.bulk.line;
            }
        }
        this.initBulkValues();
    }

    bulkSexChanged() {
        for (let jobGroup of this.job.JobGroup) {
            if (this.bulk.force || jobGroup.C_Sex_key === null) {
                jobGroup.C_Sex_key = this.bulk.sex;
            }
        }
        this.initBulkValues();
    }

    bulkMinAgeChanged() {
        for (let jobGroup of this.job.JobGroup) {
            if (this.bulk.force || jobGroup.MinAge === null) {
                jobGroup.MinAge = this.bulk.minAge;
            }
        }
        this.initBulkValues();
    }

    bulkMaxAgeChanged() {
        for (let jobGroup of this.job.JobGroup) {
            if (this.bulk.force || jobGroup.MaxAge === null) {
                jobGroup.MaxAge = this.bulk.maxAge;
            }
        }
        this.initBulkValues();
    }

    bulkOriginChanged() {
        for (let jobGroup of this.job.JobGroup) {
            if (this.bulk.force || jobGroup.C_MaterialOrigin_key === null) {
                jobGroup.C_MaterialOrigin_key = this.bulk.origin;
            }
        }
        this.initBulkValues();
    }

    materialOriginKeyFormatter = (value: any) => {
        return value.C_MaterialOrigin_key;
    }
    materialOriginFormatter = (value: any) => {
        return value.MaterialOrigin;
    }
    sexKeyFormatter = (value: any) => {
        return value.C_Sex_key;
    }
    sexFormatter = (value: any) => {
        return value.Sex;
    }
}
