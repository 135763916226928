import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { JobService } from './job.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';

/**
 * Table for adding test articles to jobs
 */
@Component({
    selector: 'job-test-article-table',
    templateUrl: './job-test-article-table.component.html'
})
export class JobTestArticleTableComponent implements OnInit {
    @Input() job: any;
    @Input() required: boolean;
    @Input() readonly: boolean;

    // IsCRO feature flag
    @Input() isCRO: boolean = false;
    @Input() isCRL: boolean = false;

    systemGeneratedTestArticleKey: any;

    constructor(
        private jobService: JobService,
        private vocabularyService: VocabularyService
    ) {
        //
    }

    // lifecycle
    ngOnInit() {
        this.initSystemGeneratedTestArticleKey();
    }

    initSystemGeneratedTestArticleKey(): Promise<any> {
        return this.vocabularyService.getSystemGeneratedCV('cv_TestArticles').then((item) => {
            this.systemGeneratedTestArticleKey = item.C_TestArticle_key;
            console.log('systemGeneratedTestArticleKey', this.systemGeneratedTestArticleKey);
        });
    }

    addJobTestArticle(job: any) {
        if (this.readonly) {
            return;
        }

        this.vocabularyService.getSystemGeneratedCV('cv_TestArticles').then((item) => {
            let systemGenerated = job.JobTestArticle.find((jobTestArticle: any) => {
                return jobTestArticle.C_TestArticle_key === item.C_TestArticle_key;
            });
            if (!systemGenerated) {
                let jobTestArticle = {
                    C_Job_key: job.C_Job_key,
                    cv_TestArticle: item
                };
                this.jobService.createJobTestArticle(jobTestArticle);
            }        

            let identifier = null;
            if (this.isCRL) {
                identifier = 1;
                for (let jobTestArticle of job.JobTestArticle) {
                    if (jobTestArticle.Identifier >= identifier) {
                        identifier = jobTestArticle.Identifier + 1;
                    }
                }
            }

            let initialValues = {
                C_Job_key: job.C_Job_key,
                Identifier: identifier
            };
            let newJobTestArticle = this.jobService.createJobTestArticle(initialValues);
            this.vocabularyService.getCVDefault('cv_TestArticles').then((value) => {
                newJobTestArticle.cv_TestArticle = value;
            });
        });
    }

    removeTestArticle(jobTestArticle: any) {
        if (this.readonly) {
            return;
        }
        this.jobService.deleteJobTestArticle(jobTestArticle);
    }
}
