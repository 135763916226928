import { Injectable } from '@angular/core';
import { DataManagerService } from '../services/data-manager.service';
import { BaseEntityService } from '../services/base-entity.service';
import { OrderService } from '../orders/order.service';
import { Entity, Order } from "@common/types";
import { EntityQuery } from "breeze-client";
@Injectable()
export class JobOrderService extends BaseEntityService {

    constructor(
        private dataManager: DataManagerService,
        private orderService: OrderService
    ) {
        super();
    }

    async createJobOrder(initialValues: any) {
        const jobOrder = this.dataManager.createEntity('JobOrder', initialValues);
        
        const entityManager = this.dataManager.getManager();
        let order: Entity<Order> = entityManager.getEntityByKey('Order', initialValues.C_Order_key) as Entity<Order>;
        if (order === null) {
            order = await this.orderService.getOrder(initialValues.C_Order_key);
        }
        order.C_Job_key = initialValues.C_Job_key;
        return jobOrder;
    }

    async deleteJobOrder(jobOrder: any) {
        this.dataManager.deleteEntity(jobOrder);
        const entityManager = this.dataManager.getManager();
        let order: Entity<Order> = entityManager.getEntityByKey('Order', jobOrder.C_Order_key) as Entity<Order>;
        if (order === null) {
            order = await this.orderService.getOrder(jobOrder.C_Order_key);
        }
        order.C_Job_key = null;
    }

    getJobOrderByKey(jobKey: number): Promise<any> {
        let query = EntityQuery.from('JobOrders')
            .where('C_Order_key', '==', jobKey);

        return this.dataManager.returnSingleQueryResult(query);
    }
}
