import { CreateSampleGroupData } from './create-sample-groups-models';
import { CreateSampleGroupsModalComponent } from './create-sample-groups-modal.component';

import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { CreatePlaceholderAssociationModalComponent } from './create-placeholder-association-modal.component';


@Injectable()
export class CreateSampleGroupsModalService {

    modalOptions: NgbModalOptions;

    constructor(
        private modalService: NgbModal
    ) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        this.modalOptions = {
            size: 'lg'
        };
    }


    openCreateSampleGroupsModal(taskInstances: any[]): Promise<CreateSampleGroupData> {

        const create: CreateSampleGroupData = {
            tasks: taskInstances,
            rows: [],
        };

        // Open modal
        const modalRef = this.modalService.open(
            CreateSampleGroupsModalComponent, this.modalOptions
        );
        let component = modalRef.componentInstance as CreateSampleGroupsModalComponent;
        component.create = create;

        return modalRef.result.then((result) => {
            if (result !== 'create') {
                // Not the Create Sample Groups button
                return null;
            }

            return create;
        });
    }

    openCreatePlaceholderAssociationsModal(placeholders: any[], job: any): Promise<any> {
        // Open modal
        const modalRef = this.modalService.open(
            CreatePlaceholderAssociationModalComponent, this.modalOptions
        );
        let component = modalRef.componentInstance as CreatePlaceholderAssociationModalComponent;
        component.placeholders = placeholders;
        component.job = job;

        return modalRef.result.then((result) => {
            if (result !== 'create') {
                return null;
            }
            return placeholders;
        });
    }
}
