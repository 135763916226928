import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JobReportReference } from "@common/types/models/job-report-reference.interface";
import { apiServiceBaseUri } from "@config";
import { DataManagerService } from "@services/data-manager.service";
import { EntityQuery, Predicate } from "breeze-client";
import { Observable } from "rxjs";

@Injectable()
export class JobPharmaReportService {
    baseEndpoint: string;

    constructor(private _httpClient: HttpClient, private dataManager: DataManagerService) {
        this.baseEndpoint = apiServiceBaseUri + "api/endofstudyreport";
    }

    /**
     * Gets all of the JobReportReference entities for a job that has the supplied job key.
     * Ordered by DateCreated.
     * @param jobKey
     * @returns 
     */
    getReportReferences(jobKey: number): Promise<JobReportReference[]> {
        let query = EntityQuery.from("JobReportReferences")
            .where(Predicate.create("C_Job_key", "eq", jobKey))
            .orderBy("DateCreated", true);
        // substitute for http get call
        return this.dataManager.getQueryResults(query);
    }

    /**
     * Requests the End of Study Report. 
     * @param jobKey  
     * @returns an observable containing an array with the job reference and a string containing the csv data. 
     */
    requestReport(jobKey: number): Observable<string> {
        // substitute for http post call
        return this._httpClient.get<string>(this.baseEndpoint + "/requestreport?jobKey=" + jobKey);
    }


    /**
     * Returns the CSV string based on the job key and report GUID.
     * @param jobKey 
     * @param reportGUID 
     * @returns an observable containing the CSV string.
     */
    downloadReport(jobKey: number, fileGUID: string): Observable<string> {
        // substitute for http get call
        return this._httpClient.get<string>(this.baseEndpoint + "/downloadreport?jobKey=" + jobKey + "&fileGUID=" + fileGUID);
    }
}