import { VocabularyService } from './../../vocabularies/vocabulary.service';
import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
} from '@angular/core';

import { SampleService } from '../../samples/sample.service';
import { CopyBufferService } from '../../common/services/copy-buffer.service';
import { JobService } from '../job.service';
import { 
    notEmpty 
} from '../../common/util';

import { TableSort } from '../../common/models';

@Component({
    selector: 'job-sample-table',
    templateUrl: './job-sample-table.component.html'
})
export class JobSampleTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() readonly: boolean;
    @Input() job: any;
    @Input() sampleMaterials: any[];
    @Input() tableSort: TableSort;

    constructor(
        private sampleService: SampleService,
        private copyBufferService: CopyBufferService,
        private jobService: JobService,
        private vocabularyService: VocabularyService
    ) {
        // do nothing
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        if (notEmpty(this.sampleMaterials)) {
            // reset sample selections
            for (let sample of this.sampleMaterials) {
                sample.isSelected = false;
            }
        }
    }

    ngOnChanges(changes: any) {
        if (changes.job) {
            if (!changes.job.firstChange) {
                this.initialize();
            }
        } 
    }

    initialize() {
        if (!this.sampleMaterials) {
            return;
        }
        this.initializeSamples(this.sampleMaterials);
        this.getCVs();
    }

    getCVs(): Promise<any> {
        return this.vocabularyService.ensureCVLoaded('cv_TimeUnits');
    }

    copySamples() {
        let samplesToCopy = this.sampleMaterials.filter((sampleMaterial) => {
            return sampleMaterial.isSelected && sampleMaterial.Material;
        }).map((sampleMaterial) => {
            return sampleMaterial.Material.Sample;
        });
        this.copyBufferService.copy(samplesToCopy);
    }

    selectAllSamples($event: any) {
        let checkbox = $event.target;
        for (let sample of this.sampleMaterials) {
            sample.isSelected = checkbox.checked;
        }
    }

    onDropMaterialToJob() {
        let samples = this.sampleService.draggedSamples;
        for (let sample of samples) {
            this.addMaterialsFromSample(sample);
            sample.isSelected = false;
        }
        this.sampleService.draggedSamples = [];

        
        this.initializeSamples(samples);
    }

    pasteSamplesIntoJob() {
        if (this.copyBufferService.hasSamples()) {
            let pastedSamples = this.copyBufferService.paste();
            for (let sample of pastedSamples) {
                this.addMaterialsFromSample(sample);
                sample.isSelected = false;
            }
            this.initializeSamples(pastedSamples);
        }
    }

    addMaterialsFromSample(sample: any) {
        let jobKey = this.job.C_Job_key;

        this.jobService.createJobMaterial({
            C_Job_key: jobKey,
            C_Material_key: sample.C_Material_key
        });
    }

    removeJobMaterial(jobMaterial: any) {
        this.jobService.deleteJobMaterial(jobMaterial);
    }

    initializeSamples(samples: any[]) {
        //
    }

    dragSamplesStart() {
        let samplesToCopy = [];
        for (let jobMaterial of this.job.JobMaterial) {
            if (jobMaterial.isSelected && jobMaterial.Material.Sample) {
                samplesToCopy.push(jobMaterial.Material.Sample);
            }
        }
        this.sampleService.draggedSamples = samplesToCopy;
    }

    dragSamplesStop() {
        setTimeout(() => {
            this.sampleService.draggedSamples = [];
        }, 500);
    }

  }
