import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import type { ColDef, GridOptions, ValueFormatterParams } from "ag-grid-community";

import { NO_DRAG_CLASS } from '../common/datatable/climb-data-table.component';

import { VocabularyService } from '../vocabularies/vocabulary.service';
import { ResourceService } from '../resources/resource.service';
import { DateFormatterService } from '@common/util/date-time-formatting';

@Component({
    selector: 'animal-cancel-treatments',
    templateUrl: './animal-cancel-treatments.component.html',
})
export class AnimalCancelTreatmentsComponent {
    @Input()
    treatmentPlans: any[] = [];
    selectedTreatmentPlans: any[] = [];

    readonly columnDefs: ColDef[] = [
        {
            headerName: '',
            headerClass: 'ag-header-cell-center',
            cellClass: NO_DRAG_CLASS,
            resizable: false,
            suppressSizeToFit: true,
            width: 30,
            sortable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
        },
        {
            headerName: 'Status',
            field: 'TaskInstance.cv_TaskStatus.TaskStatus',
        },
        {
            headerName: 'Due Date',
            field: 'TaskInstance.DateDue',
            valueFormatter: (params: ValueFormatterParams) => {
                return this.dateFormatterService.formatDateOnly(params.value);
            },
        },
        {
            headerName: 'Assigned To',
            field: 'TaskInstance.AssignedToResource.ResourceName',
        },
    ];

    gridOptions: GridOptions = {
        icons: {
            checkboxChecked: '<i class="fa fa-check-square-o ag-grid-checkbox"/>',
            checkboxUnchecked: '<i class="fa fa-square-o ag-grid-checkbox"/>',
            checkboxIndeterminate: '<i class="fa fa-square-o ag-grid-checkbox"/>',
        },
        defaultColDef: {
            sortable: true,
            resizable: true,
        },
        rowSelection: 'multiple',
        rowDeselection: true,
        onGridReady: () => {
            this.selectAll();
        },
        onModelUpdated: () => {
            this.selectAll();
        },
    };

    defaultEndStatus: any = null;

    constructor(
        public activeModal: NgbActiveModal,
        private vocabularyService: VocabularyService,
        private resourceService: ResourceService,
        private dateFormatterService: DateFormatterService
    ) {
        this.vocabularyService.getCV('cv_TaskStatuses').then((taskStatuses: any[]) => {
            for (let currStatus of taskStatuses) {
                if (currStatus.IsDefaultEndState) {
                    this.defaultEndStatus = currStatus;
                    break;
                }
            }
        });
    }

    rowSelectionChanged() {
        this._makeApiCall(() => {
            let selectedNodes = this.gridOptions.api.getSelectedNodes();
            this.selectedTreatmentPlans = selectedNodes.map((node) => node.data);
        });
    }

    private selectAll() {
        this._makeApiCall(() => {
            this.gridOptions.api.selectAll();
        });
    }

    cancelSelectedAndClose() {
        this.close();

        // grab current state to guarantee nothing can change before the promise executes
        const treatmentPlans = this.selectedTreatmentPlans.slice();
        const defaultEndStatusKey = this.defaultEndStatus.C_TaskStatus_key;
        const now = Date.now();

        this.resourceService.getCurrentUserResource().then((currUser: any) => {
            for (let treatmentPlan of treatmentPlans) {
                treatmentPlan.TaskInstance.C_TaskStatus_key = defaultEndStatusKey;
                treatmentPlan.TaskInstance.DateComplete = now;
                if (currUser) {
                    treatmentPlan.TaskInstance.C_CompletedBy_key = currUser.C_Resource_key;
                }
            }
        });
    }

    close() {
        this.activeModal.close();
    }

    private _makeApiCall(callFunc: () => void) {
        if (this.gridOptions.api) {
            setTimeout(callFunc, 0);
        }
    }
}
