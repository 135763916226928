import { map, takeUntil } from 'rxjs/operators';
import {
    Component,
    Input,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TaskService } from './task.service';
import { TaskVocabService } from './task-vocab.service';
import { ConfirmService } from '../common/confirm';
import { CopyBufferService } from '../common/services/copy-buffer.service';
import { SaveChangesService } from '../services/save-changes.service';
import { TranslationService } from '../services/translation.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import {
    BaseFacet,
    BaseFacetService,
    IFacet
} from '../common/facet';

import { TaskTableOptions } from './task-table-options';
import { TaskFilterComponent } from './task-filter.component';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { QueryDef } from '../services/query-def';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { DataContextService } from "@services/data-context.service";
import { arrowClockwise, magnifier, squareOnSquare } from '@icons';

@Component({
    selector: 'task-facet',
    templateUrl: './task-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class TaskFacetComponent extends BaseFacet
    implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: IFacet;

    readonly icons = { arrowClockwise, magnifier, squareOnSquare };

    readonly HEALTHRECORD_TYPE = 'Health Record';
    readonly JOBTASK_TYPE = 'Job';

    componentName = 'task';

    taskTableOptions: TaskTableOptions;

    readonly COMPONENT_LOG_TAG = 'task-facet';

    componentFilterSubscription: Subscription;
    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private confirmService: ConfirmService,
        private copyBufferService: CopyBufferService,
        private taskService: TaskService,
        private taskVocabService: TaskVocabService,
        private modalService: NgbModal,
        private saveChangesService: SaveChangesService,
        private translationService: TranslationService,
        workspaceFilterService: WorkspaceFilterService,
        private dataContext: DataContextService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.taskTableOptions = new TaskTableOptions(
            this.cellFormatterService,
            this.translationService
        );

        this.dataTableColumns = new BehaviorSubject(this.taskTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.initialize();
        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (!this.filter) {
                this.filter = {};
            }
        }
    }

    async loadItemList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: any[] = [];

        this.setLoadingState(tableState.loadingMessage);

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        try {
            const response = await this.taskService.getTasks(queryDef);

            const visibleColumns = this.getVisibleColumns(this.taskTableOptions.options);
            await this.taskService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    copyTasks() {
        this.copyBufferService.copy(this.selectedRows);
    }

    async addItemClick() {
        if (this.privilegeService.readwrite) {
            this.setLoadingState();
            try {
                const task = await this.createNewTask();
                this.stopLoading();
                this.itemToEdit = task;
                this.changeView(this.DETAIL_VIEW);
            } catch (error) {
                this.loading = false;
                this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
            };
        }
    }

    createNewTask(): Promise<any> {
        const newTask = this.taskService.createTask();

        // set default task type if available
        return this.taskVocabService.taskTypes$.pipe(map((data) => {
            const defaultType = data.find((item) => {
                return item && item.TaskType === this.JOBTASK_TYPE;
            });

            if (defaultType) {
                newTask.C_TaskType_key = defaultType.C_TaskType_key;
            }

            return newTask;
        })).toPromise();
    }

    async deleteItemClick(task: any): Promise<void> {
        if (this.privilegeService.readwrite && this.isTaskEditable(task)) {
            const isAssociatedWithWorkflowData = await this.taskService.isTaskAssociatedWithWorkflowData(task);
            if (!isAssociatedWithWorkflowData) {
                const isAssociatedWithInheritedOutputs = await this.taskService.isTaskAssociatedWithInheritedOutputs(task);
                if (!isAssociatedWithInheritedOutputs) {
                    this.deleteItem(task);
                } else {                            
                    this.loggingService.logWarning(
                        'Cannot delete task: at least one output is inherited into at least one other task.',
                        null,
                        this.COMPONENT_LOG_TAG,
                        true
                    );
                }               
            } else {
                this.loggingService.logWarning(
                    'Cannot delete task: it is associated with workflow data.',
                    null,
                    this.COMPONENT_LOG_TAG,
                    true
                );
            }
        }
    }

    isTaskEditable(task: any): boolean {
        if (!task) {
            return false;
        }

        // Currently disable editing on Treatment Plan task
        if (task.TaskName === 'Treatment Plan' &&
            task.cv_TaskType &&
            task.cv_TaskType.TaskType === this.HEALTHRECORD_TYPE
        ) {
            return false;
        }

        return task.IsEditable !== false;
    }

    /**
     * Deletes task, saves changes, reloads grid.
     *
     * @param task
     */
    private async deleteItem(task: any): Promise<any> {
        const modalTitle = 'Delete Task';
        const modalText = `Delete "${task.TaskName}"? This action cannot be undone.`;

        try {
            await this.confirmService.confirmDelete(modalTitle, modalText);
            this.taskService.deleteTask(task);
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
            this.reloadTable();
        } catch (error) {
            throw error;
        }       
    }

    modelCopied(itemCopied: any) {
        this.itemToEdit = itemCopied;

        this.loggingService.logSuccess('Task copied', null, this.COMPONENT_LOG_TAG, true);
        this.changeView(this.DETAIL_VIEW);
    }

    openFilter() {
        const ref = this.modalService.open(TaskFilterComponent);
        const component = ref.componentInstance as TaskFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    dragStart() {
        this.taskService.draggedTasks = this.draggedRows;
    }

    dragStop() {
        setTimeout(() => {
            this.taskService.draggedTasks = [];
        }, 500);
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.taskService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}

