import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    EventEmitter,
    Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet';
import { DroppableEvent } from '@common/droppable-event';
import {
    uniqueArrayFromPropertyPath,
} from '@common/util';

import { JobPharmaDetailService } from '../job-pharma-detail.service';
import { LoggingService } from '@services/logging.service';
import { ProtocolService } from '../../../protocol';
import { TaskType } from '../../../tasks/models';
import { TaskService } from '../../../tasks/task.service';
import {
    ViewAddProtocolComponentService,
} from '../../../tasks/add-task';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { WorkflowService } from '../../../workflow/services/workflow.service';
import { SaveChangesService } from '@services/save-changes.service';

import { DataManagerService } from '@services/data-manager.service';
import { CohortService } from '../../../cohort';
import { DialogService } from '@common/dialog/deprecated';

import { ProtocolDateCalculator } from '../../../tasks/tables/protocol-date-calculator';
import { EntityQuery } from 'breeze-client';
import { SaveRecordsOverlayEvent } from './job-pharma-tasks-list-table.component';
import type {  Entity, ProtocolInstance, TaskInstance } from '@common/types';
import { JobPharmaTableService } from "./job-pharma-table.service";
import { ExtendedProtocolInstance } from '../models/extended-protocol-instance';
import { ExtendedTaskInstance } from '../models/extended-task-instance';

// Status counts for groups
class StatusCount {
    endState: number = 0;
    total: number = 0;

    get done(): boolean {
        return (this.total > 0) && (this.endState === this.total);
    }
}

interface StatusCountMap {
    [index: number]: StatusCount;
}

@Component({
    selector: 'job-pharma-tasks-outline-table',
    templateUrl: './job-pharma-tasks-outline-table.component.html',
})
export class JobPharmaTasksOutlineTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() readonly: boolean;
    @Input() job: any;
    @Input() numItemsExpanded: number;

    @Input() tabset: string = 'tasks';
    @Input() tab: string = 'outline';
    @Input() isCRO: boolean;
    @Input() isGLP: boolean;
    @Input() isStudyDirector: boolean;

    @Output() busy: EventEmitter<SaveRecordsOverlayEvent> = new EventEmitter<SaveRecordsOverlayEvent>();

    loading: boolean = false;
    loadingMessage: string = null;

    taskPage: number = 1;

    // Data Output Events
    @Output() materialAdd: EventEmitter<any> = new EventEmitter<any>();

    // Tasks to show in the table
    tasks: any[] = [];
    protocols: (ProtocolInstance & ExtendedProtocolInstance)[] = [];

    // Column selections
    columnSelect: ColumnSelect = {
        model: [],
        labels: [],
    };

    // Visible columns
    visible: any = {};

    // Are all the rows selected?
    allSelected: boolean = false;
    allLocked: boolean = false;

    // All subscriptions
    subs = new Subscription();

    defaultTaskStatusKey: any;

    readonly COMPONENT_LOG_TAG = 'job-pharma-tasks-outline-table';
    readonly SAVING_MESSAGE = 'Saving. It may take a minute or longer to save a large number of records.';

    refreshData: boolean = false;

    constructor(
        private jobPharmaDetailService: JobPharmaDetailService,
        private jobPharmaTableService: JobPharmaTableService,
        private loggingService: LoggingService,
        public protocolService: ProtocolService,
        public taskService: TaskService,
        private vocabularyService: VocabularyService,
        private viewAddProtocolComponentService: ViewAddProtocolComponentService,
        private workflowService: WorkflowService,
        private saveChangesService: SaveChangesService,
        private dataManager: DataManagerService,
        private cohortService: CohortService,
        private dialogService: DialogService,
    ) {
        // Do nothing
    }

    ngOnInit() {
        this.initChangeDetection();
        this.initColumnSelect();
        this.initialize();
        this.initTabActions();
    }

    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            this.initJob();
        }
    }

    ngOnDestroy() {
        // Clear all the subscriptions
        this.subs.unsubscribe();
    }

    /**
     * Watch for external changes
     */
    initChangeDetection() {
        // Watch for changes to job.TaskJob
        this.subs.add(this.jobPharmaDetailService.jobTasksChanged$.subscribe(() => {
            this.initJob();
        }));
    }

    /**
     * Watch for event between the tabs
     */
    initTabActions() {
        // Listen for calls to refresh the view
        this.subs.add(
            this.jobPharmaDetailService.tabRefresh$.subscribe((event) => {
                if (event.tabset === 'tasks' && event.tab === 'outline') {
                    this.initJob();
                }
            })
        );
    }

    /**
     * Initialize the column selections
     */
    initColumnSelect() {
        // Default Visibility
        this.visible = {
            protocol: true,
            taskDefinition: true,
            taskAlias: true,
            cohorts: true,
            individualAnimals: true,
            sampleGroups: true,
            individualSamples: true,
            status: true,
        };

        // Assemble the list of all columns that can be selected
        this.columnSelect.labels = [
            new ColumnSelectLabel('protocol', 'Protocol'),
            new ColumnSelectLabel('taskDefinition', 'Task'),
            new ColumnSelectLabel('taskAlias', 'Task Name'),
            new ColumnSelectLabel('cohorts', 'Cohort'),
            new ColumnSelectLabel('individualAnimals', 'Individual Animal'),
            new ColumnSelectLabel('sampleGroups', 'Sample Group'),
            new ColumnSelectLabel('individualSamples', 'Individual Sample'),
            new ColumnSelectLabel('status', 'Progress'),
        ];

        this.columnSelect.model = this.columnSelect.labels.filter(
            (item) => this.visible[item.key]
        ).map((item) => item.key);

        // Register the columns
        this.subs.add(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, this.tab, this.columnSelect,
                () => { this.updateVisible(); }
            )
        );

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }

    initialize(): Promise<any> {
        return this.getCVs().then(() => {
            return this.initJob();
        });
    }

    getCVs(): Promise<any> {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_TaskStatuses'),
        ]).then(() => {
            const preferLocal = true;
            return this.vocabularyService.getCVDefault(
                'cv_TaskStatuses', preferLocal
            ).then((status) => {
                if (status) {
                    this.defaultTaskStatusKey = status.C_TaskStatus_key;
                }
            });
        });
    }

    /**
     * Initialize the Job-related values.
     */
    async initJob(): Promise<void> {
        this.loading = true;
        const expands = [
            // Need the status IsEndState when counting
            'cv_TaskStatus',
            // For Animals
            'TaskCohort.Cohort',
            'TaskMaterial.Material.Animal',
            'Job.TaskJob.TaskInstance.TaskCohort',
            // For Samples
            'SampleGroup',
            'SampleGroup.SampleGroupSourceMaterial',
            // For Placeholder
            'TaskPlaceholder',
            'TaskPlaceholder.Placeholder.JobCohort',
            'TaskPlaceholder.TaskPlaceholderInput',

            'ProtocolInstance.Protocol',
            'ProtocolTask',
            'TaskJob',
            'WorkflowTask.cv_TaskType'
        ];

        try {
            const loadProtocolsPromise = this.jobPharmaDetailService.loadProtocolInstanceKeysForJob(this.job.C_Job_key);
            const loadTaskInstancesPromise = this.jobPharmaDetailService.loadTaskInstances(this.job.C_Job_key);
            const loadTaskJobsPromise = this.jobPharmaDetailService.loadTaskJobs(this.job.C_Job_key);
            await Promise.all([loadProtocolsPromise, loadTaskInstancesPromise, loadTaskJobsPromise]);

            const tasks = this.jobPharmaDetailService.getJobPharmaTaskRows(this.job);
            await this.dataManager.ensureRelationships(tasks, expands);
            this.jobPharmaDetailService.sortTasksBySchedule(tasks);
            await this.initStatusCounts(tasks);
            this.tasks = tasks;
            this.isLockedChanged();
            this.protocols = this.jobPharmaDetailService.getJobPharmaProtocolRows(this.job);
            for (let protocol of this.protocols) {
                protocol.taskDefinitionNames = [];
                protocol.taskInstanceNames = [];
                protocol.taskMaterials = [];
                protocol.taskPlaceholders = [];
                protocol.taskCohorts = [];
                protocol.statusCount = new StatusCount();
                protocol.IsLocked = protocol.TaskInstance.every((task: any) => task.IsLocked);
                for (let taskInstance of protocol.TaskInstance as TaskInstance[] & ExtendedTaskInstance[]) {
                    protocol.taskDefinitionNames.push(taskInstance.WorkflowTask.TaskName);
                    protocol.taskInstanceNames.push(taskInstance.TaskAlias);
                    this.fillUniqueEntities(protocol.taskMaterials, taskInstance.TaskMaterial, 'C_Material_key');
                    this.fillUniqueEntities(protocol.taskPlaceholders, taskInstance.TaskPlaceholder, 'C_Placeholder_key');
                    this.fillUniqueEntities(protocol.taskCohorts, taskInstance.TaskCohort, 'C_Cohort_key');

                    protocol.statusCount.endState += taskInstance.statusCount?.endState;
                    protocol.statusCount.total += taskInstance.statusCount.total;
                }
                this.initProtocol(protocol.TaskInstance, protocol);
            }
            this.isLockedChanged();
        } catch (error) {
            console.error(error);
        }

        this.loading = false;

    }

    private fillUniqueEntities(arrToFill: any[], entities: any[], key: string) {
        entities.forEach((entity: any) => {
            const found = arrToFill.find((arrItem: any) => entity[key] === arrItem[key]);
            if (!found) {
                arrToFill.push(entity);
            }
        });
    }

    /**
     * Count the total and completed tasks in each group
     *
     * @param tasks tasks that will be displayed
     */
    async initStatusCounts(tasks: any[]) {
        if (!this.job.TaskJob) {
            // Nothing to count
            return;
        }

        const counts: StatusCountMap = {};

        let query = new EntityQuery('TaskJobs')
            .where('C_Job_key', 'eq', this.job.C_Job_key);

        // Get updated TaskJob
        const taskJobs = await this.dataManager.returnQueryResults(query, true);
        if (this.job.TaskJob.length < taskJobs) {
            for (let taskJob of taskJobs) {
                let taskJobOld = this.job.TaskJob.find((item: any) => {
                    return item.C_TaskJob_key === taskJob.C_TaskJob_key;
                });
                if (taskJobOld == null) {
                    this.job.TaskJob(taskJob);
                }
            }
        }
        // Go through all the tasks for this Job and count them by group
        for (let jobTask of this.job.TaskJob) {
            if (!jobTask.TaskInstance) {
                // Weird...
                continue;
            }

            const task = jobTask.TaskInstance;

            if (task.IsGroup) {
                // Don't count the groups themselves
                continue;
            }

            // Which group does this task belong to.
            // Ungrouped tasks belong to themselves.
            const key = task.C_GroupTaskInstance_key || task.C_TaskInstance_key;

            let count = counts[key];
            if (!count) {
                // Initialize a new counter for this group
                counts[key] = count = new StatusCount();
            }

            // Increment the counters
            count.total += 1;
            if (task.cv_TaskStatus && task.cv_TaskStatus.IsEndState) {
                count.endState += 1;
            }
        }
        // Assign the group counts to the tasks that will appear in the table
        for (let task of tasks) {
            task.statusCount = counts[task.C_TaskInstance_key] || new StatusCount();
        }
    }

    private initProtocol(tasks: any[], protocol: any) {
        protocol.SampleGroupTypes = '';
        protocol.numSources = null;
        protocol.numSourceAnimals = null;
        protocol.numSourceSamples = null;
        protocol.numSourceAnimalPlaceholders = null;

        for (let task of tasks) {
            if (!task.SampleGroup || (task.SampleGroup.length === 0)) {
                continue;
            }

            // Count the sources in the task
            const sources = this.jobPharmaDetailService.getTaskSourceMaterials(task);
            let numSourceAnimals = 0;
            let numSourceSamples = 0;
            let numSourceAnimalPlaceholders = 0;

            task.SampleGroupTypes = '';
            for (let sampleGroup of task.SampleGroup) {
                if (sampleGroup.cv_SampleType) {
                    task.SampleGroupTypes += sampleGroup.cv_SampleType.SampleType + ', ';
                }
                numSourceAnimals += sampleGroup.SampleGroupSourceMaterial.filter((sm: any) => sm.Material && sm.Material.Animal !== null).length;
                numSourceSamples += sampleGroup.SampleGroupSourceMaterial.filter((sm: any) => sm.Material && sm.Material.Sample !== null).length;
                numSourceAnimalPlaceholders += sampleGroup.SampleGroupSourceMaterial.filter((sm: any) => sm.AnimalPlaceholder && !sm.AnimalPlaceholder.Material).length;
            }
            protocol.SampleGroupTypes += task.SampleGroupTypes;
            protocol.numSourceAnimals += numSourceAnimals;
            protocol.numSourceSamples += numSourceSamples;
            protocol.numSourceAnimalPlaceholders += numSourceAnimalPlaceholders;
            protocol.numSources += numSourceAnimals + numSourceSamples + numSourceAnimalPlaceholders;
        }
        protocol.SampleGroupTypes = protocol.SampleGroupTypes
        .substring(0, protocol.SampleGroupTypes.length - 2);
    }

    /**
     * The Select/Clear All button was clicked
     */
    allSelectedChanged() {
        // Select or unselect all the rows
        if (this.protocols) {
            for (let protocol of this.protocols) {
                protocol.isSelected = this.allSelected;
            }
        }
    }

    allLockedChanged() {
        // locked or unlocked all the rows
        if (this.tasks) {
            for (let protocol of this.protocols) {
                protocol.IsLocked = this.allLocked;
                for (let task of protocol.TaskInstance) {
                    task.IsLocked = this.allLocked;
                }
            }
        }
    }

    /**
     * A row selection checkbox was clicked.
     */
    isSelectedChanged() {
        // Check if all the rows are selected
        this.allSelected = this.protocols.every(protocol => protocol.isSelected);
    }

    isLockedChanged(protocol?: any) {
        if (protocol) {
            protocol.TaskInstance.forEach((task: any) => {
                task.IsLocked = protocol.IsLocked;
            });
        }
        this.allLocked = this.protocols.length && this.protocols.every((proto) => proto.IsLocked);
    }

    /**
     * Add tasks for a Protocol selected through the modal.
     *
     * Note: The add-protocol component provides the initial values for the
     * TaskInstances.
     */
    async addProtocolViaModal() {
        // FORCE SAVE BEFORE ADD PROTOCOL
        if (this.canSave) {
            let updatePlaceholders = false;
            if (this.job.JobID === null || this.job.JobID.length === 0) {
                updatePlaceholders = true;
            }
            this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG).then(() => {
                if (updatePlaceholders) {
                    this.jobPharmaDetailService.updatePlaceholderNames(this.job);
                }
            });
        }

        // Show the Add Protocol modal
        const protocolKey = await this.viewAddProtocolComponentService.openComponent(TaskType.Job);
        if (!protocolKey) {
            return;
        }
        const protocol = await this.protocolService.getProtocol(protocolKey);
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            await this.jobPharmaDetailService.addProtocols(this.job, [protocol]);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    /**
     * Handle drop events
     */
    async onDrop(event: DroppableEvent): Promise<any> {
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            await this.jobPharmaTableService.onDropToJob(this.job);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    get canSave(): boolean {
        return this.saveChangesService.hasChanges && !this.saveChangesService.saving;
    }

    async onDropToProtocolInstance(protocolInstance: Entity<ProtocolInstance>, isLocked: any, event: DroppableEvent): Promise<any> {
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            return await this.jobPharmaTableService.onDropToProtocolInstance(this.job, protocolInstance, isLocked, event);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    /**
     * Check if cohort has already been added to a protocol
     */
    checkForDupCohorts(protocolInstance: any, entities: any[]): any {
        let taskCohorts: any[];
        let cohorts: any[];
        let duplicates: any[];

        for (let task of protocolInstance.TaskInstance) {
            taskCohorts = task.TaskCohort;
            cohorts = taskCohorts.map((taskCohort) => taskCohort.Cohort);
            duplicates = entities.filter((entity) => {
                return cohorts.includes(entity);
            });
        }

        return duplicates;
    }

    changeTaskPage(newPage: number) {
        this.taskPage = newPage;
    }

    /**
     * All cohort members get added to the job or removed from the job,
     *      so load them into memory and refresh them for display
     */
    refreshMaterialsFromCohort(cohort: any): Promise<any> {
        return this.cohortService.ensureMaterialsExpanded([cohort]).then(() => {
            let materialKeys = uniqueArrayFromPropertyPath(
                cohort, 'CohortMaterial.C_Material_key'
            );
            return this.dataManager.refreshEntityCollection(
                'Material', 'JobMaterial', materialKeys
            );
        });
    }

    /**
     * Calculate the DateDue based on Material field(s)
     *
     * @param taskInstanceKey
     */
    calculateMaterialDueDatesFromTaskKey(taskInstance: any) {
        let protocolDateCalculator = new ProtocolDateCalculator();
        let allTasks = this.getAllTaskInstances();
        let protocolInstanceTasks = this.getTasksInSameProtocol(taskInstance, allTasks);
        protocolDateCalculator.scheduleMaterialDueDates(protocolInstanceTasks, taskInstance);
    }

    getAllTaskInstances(): any[] {
        return uniqueArrayFromPropertyPath(this.tasks, 'TaskInstance');
    }

    getTasksInSameProtocol(taskInstance: any, allTasks: any[]): any[] {
        return allTasks.filter((task) => {
            return task.C_ProtocolInstance_key === taskInstance.C_ProtocolInstance_key;
        });
    }
    // End Samples Region


    cannotDeleteSample(protocol: any) {
        return protocol.TaskInstance.some((task: any) => {
            if (task.SampleGroup.length > 0) {
                return true;
            }
        });
    }

    checkIfIsInViewPort(event: DroppableEvent): boolean {
        const rect = document.querySelector('.job-pharma-tabset.tasks ~ .tab-content').getBoundingClientRect();
        return (event.event.clientX >= rect.left &&
            event.event.clientX <= rect.right &&
            event.event.clientY >= rect.top &&
            event.event.clientY <= rect.bottom);
    }

    taskDontHaveMatchingCohort(placeholder: any, taskCohort: any[]) {
        return this.jobPharmaDetailService.taskDontHaveMatchingCohort(placeholder, taskCohort);
    }

    taskDontHaveMatchingAnimal(placeholder: any, taskMaterial: any[]) {
        return this.jobPharmaDetailService.taskDontHaveMatchingAnimal(placeholder, taskMaterial);
    }

    isAnyProtocolSelected(): boolean {
        if (this.readonly) {
            return false;
        }

        for (let protocol of this.protocols) {
            if (protocol.isSelected) {
                return true;
            }
        }
        return false;
    }

    canRemoveProtocolInstance(protocolInstance: ProtocolInstance): boolean {
        if (this.readonly) {
            return false;
        }
        
        return this.jobPharmaTableService.canRemoveProtocolInstance(protocolInstance);
    }

    /**
     * Remove Protocol Instance from Job
     * @param protocol
     */
    removeProtocolInstance(protocolInstance: ProtocolInstance): void {
        if (this.readonly) {
            return;
        }

        this.jobPharmaTableService.removeProtocolInstance(protocolInstance);
    }

    removeProtocolInstances(): Promise<void> {
        if (this.readonly) {
            return;
        }

        const selectedProtocols = this.protocols.filter(protocol => protocol.isSelected);
        if (selectedProtocols.length === 0) {
            return;
        }

        return this.jobPharmaTableService.removeProtocolInstances(selectedProtocols);
    }
}
