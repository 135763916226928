import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    EventEmitter,
    Output
} from '@angular/core';
import { Subscription } from 'rxjs';

import { DragulaOptions, DragulaService } from 'ng2-dragula';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet';
import { DateFormatterService } from '@common/util/date-time-formatting';
import {
    getSafeProp,
    randomId,
    sortObjectArrayByAccessors,
    sortObjectArrayByAccessor,
} from '@common/util';

import { AnimalService } from '../../../animals/services/animal.service';
import { CopyBufferService } from '../../../common/services/copy-buffer.service';
import { DataManagerService } from '../../../services/data-manager.service';
import { JobPharmaDetailService } from '../job-pharma-detail.service';
import { JobService } from '../../job.service';
import { LoggingService } from '../../../services/logging.service';
import { SaveChangesService } from '../../../services/save-changes.service';
import { SaveRecordsOverlayEvent } from './job-pharma-tasks-list-table.component';
import { TableSort } from '../../../common/models';
import { WorkspaceService } from '../../../workspaces/workspace.service';
import { TranslationService } from '../../../services/translation.service';
import { Animal, Entity, Material, ExtendedJob, JobMaterial, SampleGroupSourceMaterial, TaskJob, Job } from '@common/types';
import { WorkflowService } from '../../../workflow/services/workflow.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';

class SortConfig {
    columns: { [key: string]: SortColumnConfig } = {};
}

class SortColumnConfig {
    selected: boolean;
    reverse: boolean;
}

interface IsSelected {
    isSelected: boolean;
}

@Component({
    selector: 'job-pharma-animals-individual-table',
    templateUrl: './job-pharma-animals-individual-table.component.html',
    styles: [`
        table.job-pharma-animals-individual .fa-sort.draggable {
            cursor: grab;
        }

        table.job-pharma-animals-individual .fa-sort:not(.draggable) {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .bulk-name .form-control {
            width: 50px;
        }

        .ui-draggable-dragging {
            padding: 4px;
            border-radius: 2px;
            font-size: 12px;
            margin-left: 20px;
        }
    `]
})
export class JobPharmaAnimalsIndividualTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() readonly: boolean;
    @Input() job: Entity<Job & ExtendedJob>;
    @Input() facet: any;

    @Input() tabset: string = 'animals';
    @Input() tab: string = 'individual';

    // Table State
    @Input() primaryCohortsExpanded: boolean = true;

    @Input() isGLP: boolean;

    tableSort: TableSort = new TableSort();

    // Outputs for the properties that can be modified herein
    @Output() primaryCohortsExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() busy: EventEmitter<SaveRecordsOverlayEvent> = new EventEmitter<SaveRecordsOverlayEvent>();

    loading: boolean = false;
    loadingMessage: string = "Loading";
    animalPage: number = 1;

    animalMaterials: any[];

    // Column selections
    columnSelect: ColumnSelect = {
        model: [],
        labels: [],
    };

    // Visible columns
    visible: any = {};

    bulkNamePrefix: string;
    bulkNameCounter: number = 1;
    bulkNameSuffix: string;

    dragulaBagName: string;

    // Are all the rows selected?
    allSelected: boolean = false;
    // Are any rows selected?
    anySelected: boolean = false;

    // All subscriptions
    subs = new Subscription();

    // How many names to show in the Dragula helper
    readonly DRAG_MAX_NAMES = 5;

    // Where to sort Animals without a Sequence value
    readonly SEQUENCE_END = 1000000;

    // sort changed
    sortChanged: boolean = false;

    // Default table sort property path
    readonly TABLE_SORT_PROPERTY_PATH_DEFAULT = 'AnimalName';

    readonly COMPONENT_LOG_TAG = 'job-pharma-animals-individual-table';

    constructor(
        private animalService: AnimalService,
        private copyBufferService: CopyBufferService,
        private dataManager: DataManagerService,
        private dragulaService: DragulaService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private jobService: JobService,
        private loggingService: LoggingService,
        private saveChangesService: SaveChangesService,
        private workspaceService: WorkspaceService,
        private translationService: TranslationService,
        private dateFormatterService: DateFormatterService,
        private workflowService: WorkflowService,
        private vocabularyService: VocabularyService
    ) {
        // Do nothing
    }

    async ngOnInit() {
        this.loading = true;
        this._setDefaultTableSort();
        this.initColumnSelect();
        this.dragOnInit();
        await this.initJob();
        this.initChangeDetection();
        this.loading = false;
    }

    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            this.initJob();
        }
    }

    ngOnDestroy() {
        this.dragOnDestroy();
        this.clearAnimalSelections();

        // Clear all the subscriptions
        this.subs.unsubscribe();
    }

    /**
     * Watch for external changes
     */
    initChangeDetection() {
        this.subs.add(this.jobPharmaDetailService.jobCohortsChanged$.subscribe(() => {
            this.initJob();
        }));

        // Watch for changes to Job.JobMaterial
        this.subs.add(this.jobPharmaDetailService.jobMaterialsChanged$.subscribe(() => {
            this.initJob();
        }));
    }

    /**
     * Initialize the column selections
     */
    initColumnSelect() {
        // Default Visibility
        this.visible = {
            name: true,
            line: true,
            genotype: true,
            sex: true,
            birthDate: true,
            status: true,
            housing: true,
            cohort: true,
            sequence: true,
        };

        // Assemble the list of all columns that can be selected
        if (this.isGLP) {
            this.columnSelect.labels = [
                new ColumnSelectLabel('name', 'Name'),
                new ColumnSelectLabel('line', this.translationService.translate('Line')),
                new ColumnSelectLabel('genotype', 'Genotype'),
                new ColumnSelectLabel('sex', 'Sex'),
                new ColumnSelectLabel('birthDate', 'Birth Date'),
                new ColumnSelectLabel('status', 'Status'),
                new ColumnSelectLabel('heldFor', 'Held For'),
                new ColumnSelectLabel('housing', 'Housing ID'),
                new ColumnSelectLabel('microchipIdentifier', 'Microchip ID'),
                new ColumnSelectLabel('alternatePhysicalID', 'Alternate Physical ID'),
                new ColumnSelectLabel('externalIdentifier', 'External ID'),
                new ColumnSelectLabel('cITESNumber', 'CITES Number'),
                new ColumnSelectLabel('classification', 'Classification'),
                new ColumnSelectLabel('ageDays', 'Age (Days)'),
                new ColumnSelectLabel('ageWeeks', 'Age (Weeks)'),
                new ColumnSelectLabel('ageMonths', 'Age (Months)'),
                new ColumnSelectLabel('cohort', 'Cohort'),
                new ColumnSelectLabel('sequence', 'Sequence'),
            ];
        } else {
            this.columnSelect.labels = [
                new ColumnSelectLabel('name', 'Name'),
                new ColumnSelectLabel('line', this.translationService.translate('Line')),
                new ColumnSelectLabel('genotype', 'Genotype'),
                new ColumnSelectLabel('sex', 'Sex'),
                new ColumnSelectLabel('birthDate', 'Birth Date'),
                new ColumnSelectLabel('status', 'Status'),
                new ColumnSelectLabel('housing', 'Housing ID'),
                new ColumnSelectLabel('microchipIdentifier', 'Microchip ID'),
                new ColumnSelectLabel('externalIdentifier', 'External ID'),
                new ColumnSelectLabel('ageDays', 'Age (Days)'),
                new ColumnSelectLabel('ageWeeks', 'Age (Weeks)'),
                new ColumnSelectLabel('ageMonths', 'Age (Months)'),
                new ColumnSelectLabel('cohort', 'Cohort'),
                new ColumnSelectLabel('sequence', 'Sequence'),
            ];
        }

        this.columnSelect.model = this.columnSelect.labels.filter(
            (item) => this.visible[item.key]
        ).map((item) => item.key);

        // Register the columns
        this.subs.add(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, this.tab, this.columnSelect,
                () => {
                    this.updateVisible();
                }
            )
        );

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        this.busy.emit({state: true, message: this.loadingMessage});

        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });

        this.busy.emit({state: false, message: this.loadingMessage});
    }

    /**
     * Setup for row dragging
     */
    private dragOnInit() {
        // Need a unique name for our table in the Dragule config
        this.dragulaBagName = randomId() + '-bag';

        // Register a callback to handle the row drop
        this.subs.add(this.dragulaService.dropModel(this.dragulaBagName).subscribe(({el, targetModel}) => {
            // At this point we know which table row was dragged ...
            // ... but we need to know which Animal was dragged.
            const draggedKey = parseInt((el as HTMLElement).dataset.key, 10);

            this.animalMaterials = targetModel;

            // Now, reorder the animals
            this.dragReorderAnimals(draggedKey);
        }));

        // Register a callback to update the drag helper element
        this.subs.add(this.dragulaService.cloned(this.dragulaBagName).subscribe(({clone, cloneType}) => {
            if (cloneType !== 'mirror') {
                // Something else was cloned
                return;
            }

            // Update the mirror (aka drag helper);
            this.dragUpdateHelper(clone as HTMLElement);
        }));

        // Dragula options
        const opts: DragulaOptions<any> = {
            moves: (el: Element, source: Element, handle: Element, sibling: Element) => {
                return this.readonly
                    ? false // Disable dragging if can't edit
                    : handle.classList.contains('draggable'); // Only allow dragging by the drag handle
            },
        };

        // Configure the service for this table
        this.dragulaService.createGroup(this.dragulaBagName, opts);
    }

    /**
     * Remove Dragula configuration and listeners
     */
    private dragOnDestroy() {
        if (this.dragulaService.find(this.dragulaBagName)) {
            this.dragulaService.destroy(this.dragulaBagName);
        }
    }

    /**
     * Update the Dragula mirror element (aka drag helper) that is displayed
     * under the cursor while dragging rows.
     *
     * By default, the dragged element is cloned. Instead, we want to display
     * some information about the selected rows.
     *
     * @param mirror DOM element displayed under the cursor
     */
    private dragUpdateHelper(mirror: HTMLElement) {
        // Figure out which row was dragged
        const draggedKey: number = parseInt(mirror.dataset.key, 10);

        //  Get the names of all the animals to be dragged
        const names = this.animalMaterials.filter((jm) => {
            return jm.isSelected || (jm.C_Material_key === draggedKey);
        }).filter((jm) => {
            return jm.Material && jm.Material.Animal;
        }).map((jm) => {
            return jm.Material.Animal.AnimalName;
        });

        let text = '';
        if (names.length <= this.DRAG_MAX_NAMES) {
            // Join all the names
            text = names.join(', ');
        } else {
            // Join the first names, and note how many were left off
            text = names.slice(0, this.DRAG_MAX_NAMES).join(', ');
            text += ` (plus ${names.length - this.DRAG_MAX_NAMES} more)`;
        }

        // Just to be paranoid, escape the names
        text = this.escapeHtml(text);

        // Swap out the contents of the mirror element
        mirror.innerHTML = `<div class="dragula-helper">${text}</div>`;
    }

    /**
     * Reorder the rows after a row drag/drop event.
     *
     * @param draggedKey Material key of the animal in the row that was dragged
     */
    private dragReorderAnimals(draggedKey: number) {
        const dragged = this.animalMaterials.filter((jm: any) => {
            // Find all the selected or dragged rows
            // The draggedKey is checked just in case the dragged row is not selected
            return jm.isSelected || (jm.C_Material_key === draggedKey);
        });

        if (draggedKey && (dragged.length > 1)) {
            // Here's the deal...
            // At this point the row that was dragged is in the correct
            // position in the animalMaterials array. However, the other
            // selected rows have not been moved. We are going to rebuild the
            // array and insert all the selected rows when we find the one row
            // that was moved.

            // Need to put the dragged materials back in the original order first
            dragged.sort(this.sortBySequence);

            const materials: any[] = [];
            this.animalMaterials.forEach((jm) => {
                if (jm.C_Material_key === draggedKey) {
                    // Push on all the dragged materials at the same time
                    materials.push(...dragged);
                } else if (!jm.isSelected) {
                    // Not dragged
                    materials.push(jm);
                }
            });

            // Use the updated array
            this.animalMaterials = materials;
        }

        // Recalculate the sequence
        this.animalMaterials.forEach((jm: any, index: number) => {
            jm.Sequence = index + 1;
        });

        // Finally, reset the selections
        this.clearAnimalSelections();
    }

    // Initialize the list of Animals
    private async initJob(): Promise<any> {
        this.loading = true;
        if (!this.job) {
            // Just in case, there is no Job
            this.animalMaterials = [];
            return Promise.resolve();
        }

        await this.dataManager.ensureRelationships(
            [this.job], ['JobMaterial.Material.Animal']
        );
        // Find all the Animals
        this.animalMaterials = this.job.JobMaterial.filter((item: any) => {
            return getSafeProp(item, 'Material.Animal');
        });
        if (this.isGLP) {
            this.animalMaterials = this.animalMaterials.filter((jm: any) => {
                return !jm.DateOut;
            });
        }
        this.animalMaterials.forEach((am: any) => {
            if (am.Material.Animal.DateBorn) {
                am.DateBornSortable = (new Date(this.dateFormatterService.formatDateOnly(am.Material.Animal.DateBorn))).getTime().toString();
            }
        });
        await this.getCVs();
        await this.ensureAnimalRelationships();
        await this.initAnimalSequence();
        // Just in case, clear the selections
        this.clearAnimalSelections();
        this.loading = false;
    }

    /**
     * Make sure all the JobMaterial relationships are loaded.
     */
    ensureAnimalRelationships(): Promise<any> {
        const expands = [
            'Material.MaterialPoolMaterial.MaterialPool',
            'Material.CohortMaterial.Cohort',
            'Material.Animal.cv_Sex',
            'Material.Animal.cv_AnimalStatus',
            'Material.Animal.Genotype',
            'Material.Line'
        ];

        return this.dataManager.ensureRelationships(this.animalMaterials, expands);
    }

    getCVs() {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_GenotypeAssays', false),
            this.vocabularyService.ensureCVLoaded('cv_GenotypeSymbols', false)
        ]);
    }

    /**
     * Initialize the Animal JobMaterial.Sequence values to assign defaults and
     * smooth out gaps.
     */
    initAnimalSequence(): Promise<void> {
        // We'll want to notify the user when changing the sequences
        let changedSequence: boolean = false;

        // Assign default Sequence values just in case an animal is assigned without one.
        this.animalMaterials.forEach((jm: any, index: number) => {
            if (!jm.Sequence) {
                // Add the Animals to the end of the sequence in order of their key.
                // Note: new entities have increasingly negative key values.
                jm.Sequence = this.SEQUENCE_END + Math.abs(jm.C_Material_key);

                changedSequence = true;
            }
        });

        // Sort by Sequence
        this.animalMaterials.sort(this.sortBySequence);

        // Smooth out the sequence just in case the values are not set or there
        // are gaps;
        this.animalMaterials.forEach((jm: any, index: number) => {
            if (jm.Sequence !== index + 1) {
                // The Index in the DB is 1-based
                jm.Sequence = index + 1;

                changedSequence = true;
            }
        });

        if (changedSequence) {
            // Let the user know about the sequence change
            this.loggingService.logWarning(
                'Updated the Animals Sequence',
                null,
                this.COMPONENT_LOG_TAG,
                true);
            if (this.saveChangesService) {
                return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
            }
        }

        return Promise.resolve();
    }

    /**
     * Unselect all the animals
     */
    clearAnimalSelections() {
        if (this.animalMaterials) {
            // Reset animal selections
            this.animalMaterials.forEach((jm) => {
                jm.isSelected = false;
            });
        }
        this.allSelected = false;
        this.anySelected = false;
    }


    /**
     * The Select/Clear All button was clicked
     */
    allSelectedChanged() {
        // Select or unselect all the rows
        if (this.animalMaterials) {
            for (const jm of this.animalMaterials) {
                jm.isSelected = this.allSelected;
            }
        }
        this.anySelected = this.allSelected;
    }

    /**
     * A row selection checkbox was clicked.
     */
    isSelectedChanged() {
        // Check if all the rows are selected
        this.allSelected = this.animalMaterials.every((jm) => jm.isSelected);
        // Check if any rows are selected
        this.anySelected = this.animalMaterials.some((jm) => jm.isSelected);
    }

    /**
     * Update the animal names from the bulk fill down form
     */
    bulkNameUpdated() {
        // Get the labels, some of which may be missing
        const prefix = this.defaultString(this.bulkNamePrefix, '');
        const suffix = this.defaultString(this.bulkNameSuffix, '');
        let counter = this.bulkNameCounter;

        this.animalMaterials.forEach((jm) => {
            if (!jm.Material || !jm.Material.Animal) {
                // Weird...
                return;
            }

            // Assign the new name
            jm.Material.Animal.AnimalName = `${prefix}${counter}${suffix}`;

            // Bump the counter
            counter += 1;
        });
    }

    /**
     * Helper to return a string or a default if the string is not set.
     *
     * Slightly different than notEmpty() in that '0' is a valid value.
     *
     * @param value Possibly missing value
     * @param defaultValue Value to use if the actual value is missing
     */
    defaultString(value: string, defaultValue: string) {
        if ((value === null) || (value === undefined)) {
            return defaultValue;
        }

        return value;
    }

    /**
     * Handle dropping Animals into the Job
     */
    async onDrop() {
        let animals = this.animalService.draggedAnimals;
        this.animalService.draggedAnimals = [];
        try {
            this.busy.emit({state: true, message: this.loadingMessage});
            await this.addAnimalsToJob(animals)
        } finally {
            this.busy.emit({state: false, message: this.loadingMessage});
        }
    }

    /**
     * Handle pasting Animals into the Job
     */
    async onPaste() {
        if (!this.copyBufferService.hasAnimals()) {
            return Promise.resolve();
        }
        const animals = this.copyBufferService.paste();
        try {
            this.busy.emit({state: true, message: this.loadingMessage});
            await this.addAnimalsToJob(animals)
        } finally {
            this.busy.emit({state: false, message: this.loadingMessage});
        }
    }

    /**
     * Get confirmation if any new animals are already in jobs, then add.
     */
    private async addAnimalsToJob(animals: Entity<Animal>[]) {
        const materials = animals.map(a => a.Material) as Entity<Material>[];
        return await this.jobPharmaDetailService.addMaterialsToJobIfMissing(this.job, materials);
    }

    /**
     * Remove an Animal from the Job
     */
    async removeJobMaterial(jobMaterial: JobMaterial): Promise<void> {
        const animal = jobMaterial.Material.Animal as Entity<Animal>;
        const taskInstanceKeys = this.job.TaskJob.map((taskJob: TaskJob) => taskJob.C_TaskInstance_key);
        const samples = jobMaterial.Material.SampleGroupSourceMaterial
            .find((x: any) => x.SampleGroup && x.SampleGroup.Sample && x.SampleGroup.Sample.length > 0
                && taskInstanceKeys.includes(x.SampleGroup.C_TaskInstance_key));

        if (!this.isGLP && samples) {
            return this.jobPharmaDetailService.notifyAnimalsHaveSamples([animal]);
        }

        try {
            // Try to remove the Animal from the tasks as well
            this.jobPharmaDetailService.busyStart();
            const result = await this.jobPharmaDetailService.tryRemoveAnimalsFromTasks(this.job, [animal]);

            if (!result.allRemoved) {
                return this.jobPharmaDetailService.notifyAnimalsHaveSamples(result.withData);
            }

            const sampleGroupSourceMaterialToDelete = jobMaterial.Material.SampleGroupSourceMaterial
                .filter((x: SampleGroupSourceMaterial) => x.SampleGroup && taskInstanceKeys.includes(x.SampleGroup.C_TaskInstance_key));

            if (sampleGroupSourceMaterialToDelete) {
                sampleGroupSourceMaterialToDelete.forEach((element: SampleGroupSourceMaterial) => {
                    this.dataManager.deleteEntity(element);
                });
                this.jobPharmaDetailService.tabRefresh('samples', 'groups');
            }

            const taskInstances = this.job.TaskJob.map(taskJob => taskJob.TaskInstance);

            if (jobMaterial.Material.CohortMaterial?.length) {
                const tasksToDelete = taskInstances.filter(taskInstance => {
                    return taskInstance.TaskMaterial.map(item => item.C_Material_key).includes(animal.C_Material_key);
                });

                tasksToDelete.forEach(task => {
                    this.workflowService.deleteTask(task);
                });
            }

            // Remove the Animal from the Job
            this.jobService.deleteJobMaterial(jobMaterial);

            this.jobPharmaDetailService.tabRefresh('tasks', 'list');
            this.jobPharmaDetailService.tabRefresh('tasks', 'outline');

            if (this.isGLP) {
                jobMaterial.DateOut = new Date();
                await this.initJob();
            }
        } finally {
            this.jobPharmaDetailService.busyStop();
        }
    }

    // Dragging Out
    private dragId: number = null;

    dragStart() {
        // Find the selected animals
        const selected = this.animalMaterials
            .filter((jm) => jm.isSelected)
            .map((jm) => jm.Material.Animal);

        this.dragId = this.jobPharmaDetailService.startDrag('Animal', selected);
        if (selected.length > 1) {
            jQuery('.ui-draggable-dragging')[0].textContent = `${selected.length} Animals Selected`;
        }
    }

    // TODO: ?
    dragStop() {
        setTimeout(() => {
            this.jobPharmaDetailService.stopDrag(this.dragId);
        }, 500);
    }

    /**
     * ngFor helper to track DOM changes to table rows.
     */
    trackRow = (index: number, item: any): string => {
        // Include the Sequence and Material key in tracking key so ngFor will
        // update properly after a multi-row drag
        return `${item.Sequence}-${item.C_Material_key}`;
    }

    // Comparator to sort JobMaterial by Sequence
    sortBySequence = (a: any, b: any): number => {
        return (a.Sequence || 0) - (b.Sequence || 0);
    }

    // HTML entities that need to be escaped
    readonly entityMap = {
        '<': '&lt;',
        '>': '&gt;',
        '&': '&amp;',
    };

    /**
     * Escape HTML entities in text.
     */
    escapeHtml(value: string) {
        return String(value).replace(/[<>&]/g, (s) => {
            return this.entityMap[s];
        });
    }

    changeAnimalPage(newPage: number) {
        this.animalPage = newPage;
    }

    viewPrimaryCohorts() {
        this.primaryCohortsExpanded = true;
        this.primaryCohortsExpandedChange.emit(this.primaryCohortsExpanded);
    }

    viewAllCohorts() {
        this.primaryCohortsExpanded = false;
        this.primaryCohortsExpandedChange.emit(this.primaryCohortsExpanded);
    }

    private _setDefaultTableSort() {
        if (!this.tableSort) {
            this.tableSort = new TableSort();
        }
        const config = this.parseSortConfig();
        const columns = Object.keys(config.columns);
        if (columns.length === 0) {
            return;
        }
        this.tableSort.nested = columns.length > 1;
        this.tableSort.natural = true;
        if (this.tableSort.nested) {
            let properties: any[] = [];
            let reverses = {};
            let indexes = {};
            columns.forEach((key: any, index) => {
                reverses[key] = config.columns[key].reverse;
                indexes[key] = index + 1;
                properties.push(key);
            });
            this.tableSort.setSortSetup(properties, reverses, indexes);
        } else {
            const key = columns[0];
            this.tableSort.setSingleSortSetup(key, config.columns[key].reverse);
        }
    }

    sortColumn(column: any, event: any) {
        if (event.shiftKey) {
            this.tableSort.nested = true;
        } else {
            this.tableSort.nested = false;
        }

        this.sortChanged = true;
        this.tableSort.toggleSort(column);


        if (this.tableSort.nested) {
            const sortDefs = this.tableSort.properties.map((path: any) => {
                return {
                    sortAccessor: (item: any) => {
                        return getSafeProp(item, path);
                    },
                    descending: this.tableSort.nestedReverse[path]
                };
            });
            sortObjectArrayByAccessors(this.animalMaterials, sortDefs, this.tableSort.natural);
        } else {
            if (this.tableSort.propertyPath !== '') {
                sortObjectArrayByAccessor(this.animalMaterials, (item) => {
                    return getSafeProp(item, this.tableSort.propertyPath);
                }, this.tableSort.reverse, this.tableSort.natural);
            }
        }

        let sequence: number = 0;
        this.animalMaterials.forEach((jm: any) => {
            sequence++;
            jm.Sequence = sequence;
        });
        this.saveSortConfig();
    }

    /**
     * Parse the SortConfig JSON string, or provide a blank config object
     */
    parseSortConfig(): SortConfig {
        try {
            // using BulkDataConfiguration to avoid new field
            if (this.facet.BulkDataConfiguration) {
                return JSON.parse(this.facet.BulkDataConfiguration);
            }
        } catch (e) {
            console.error('Could not parse BulkDataConfiguration', e);
        }

        return new SortConfig();
    }

    /**
     * Save the sort selections for this facet.
     */
    saveSortConfig() {
        // Start from scratch
        const config = new SortConfig();

        if (this.tableSort.nested) {
            this.tableSort.properties.forEach((path: any) => {
                const columnConfig = new SortColumnConfig();
                columnConfig.selected = true;
                columnConfig.reverse = this.tableSort.nestedReverse[path];
                config.columns[path] = columnConfig;
            });
        } else {
            if (this.tableSort.propertyPath !== '') {
                const columnConfig = new SortColumnConfig();
                columnConfig.selected = true;
                columnConfig.reverse = this.tableSort.reverse;
                config.columns[this.tableSort.propertyPath] = columnConfig;
            }
        }

        // Rebuild the BulkDataConfiguration JSON
        this.facet.BulkDataConfiguration = JSON.stringify(config);

        // Save just the BulkDataConfiguration value in the facet
        this.workspaceService.saveBulkDataConfiguration(this.facet);
    }
}
