import { BulkEditCommService } from './../../common/facet/bulk-edit-comm.service';
import { SampleService } from './../sample.service';
import { DataManagerService } from '../../services/data-manager.service';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import { FacetView } from '../../common/facet';
import { IValidatable, SaveChangesService } from '../../services/save-changes.service';
import { SettingService } from '../../settings/setting.service';
import { SampleBulkTemplatesComponent } from './sample-bulk-templates.component';
import { BulkEditToolbarComponent } from '../../common/toolbars';


@Component({
    selector: 'sample-bulk-edit',
    templateUrl: './sample-bulk-edit.component.html',
    providers: [
        BulkEditCommService
    ]
})
export class SampleBulkEditComponent implements OnInit, OnDestroy, IValidatable {
    @Input() facet: any;
    @Input() facetView: FacetView;
    @Input() samples: any[];
    @Input() requiredFields: string[];
    @Input() activeFields: string[];

    @Output() exit: EventEmitter<any> = new EventEmitter<any[]>();
    @ViewChild('bulkEditToolbar') bulkEditToolbar: BulkEditToolbarComponent;
    @ViewChild('bulkTemplates') bulkTemplates: SampleBulkTemplatesComponent;

    readonly COMPONENT_LOG_TAG = 'sample-bulk-edit';

    constructor(
        private sampleService: SampleService,
        private loggingService: LoggingService,
        private dataManager: DataManagerService,
        private settingService: SettingService,
        private saveChangesService: SaveChangesService,
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.saveChangesService.registerValidator(this);
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);
    }

    initialize() {
         // Copy the input so we don't touch the grid data
        this.samples = this.samples.slice();
    }

    exitClicked() {
        if (this.dataManager.hasChanges()) {
            for (const sample of this.samples) {
                this.sampleService.cancelSample(sample);
            }
            this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
        }

        this.exit.emit(this.samples);
    }

    async validate(): Promise<string> {
        const bulkEditFields = [
            'Material.MaterialSourceMaterial[0]',
            'TimePoint',
            'DateHarvest',
            'DateExpiration',
            'Material.C_Line_key',
            'C_PreservationMethod_key',
            'Material.C_MaterialOrigin_key',
            'Material.ExternalIdentifier',
            'Material.C_ContainerType_key',
            'C_Unit_key',
            'C_SampleSubtype_key',
            'C_SampleProcessingMethod_key',
            'SendTo',
            'C_SampleAnalysisMethod_key',
            'Description',
            'LotNumber'
        ];
        const filterRequiredFields = this.requiredFields.filter((field: string) => bulkEditFields.includes(field));
        return this.settingService.bulkValidate(this.samples, filterRequiredFields, 'sample') || this.bulkTemplates.validate();
    }

}
