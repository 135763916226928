import { FacetSharedModule } from './../common/facet/facet-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { SettingsSharedModule } from '../settings/settings-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { VocabulariesSharedModule } from '../vocabularies/vocabularies-shared.module';

import { ClinicalSharedModule } from './clinical-shared.module';
import { AnimalsSharedModule } from '../animals/animals-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { MessagesSharedModule } from '../messages/messages-shared.module';
import { ResourcesSharedModule } from '../resources/resources-shared.module';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { WorkflowsSharedModule } from '../workflow/workflows-shared.module';
import { ClinicalFilterComponent } from './clinical-filter/clinical-filter.component';

import { TreatmentPlanBulkAddComponent } from './bulkedit/treatment-plan-bulk-add.component';
import { TreatmentPlanBulkTemplatesComponent } from './bulkedit/treatment-plan-bulk-templates.component';

import { ObservationBulkTemplatesComponent } from './bulkedit/observation-bulk-templates.component';
import { ObservationBulkAddComponent } from './bulkedit/observation-bulk-add.component';
import { ObservationBulkEditComponent } from './bulkedit/observation-bulk-edit.component';
import { TreatmentBulkEditComponent } from './bulkedit/treatment-bulk-edit.component';
import { ClinicalAlertSenderComponent } from './clinical-alert-sender/clinical-alert-sender.component';
import { ClinicalFacetComponent } from './clinical-facet/clinical-facet.component';
import { NewHealthRecordComponent } from './new-health-record/new-health-record.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ClimbCommonModule,
        FacetSharedModule,
        ClinicalSharedModule,
        AnimalsSharedModule,
        LinesSharedModule,
        MessagesSharedModule,
        ResourcesSharedModule,
        TasksSharedModule,
        UsersSharedModule,
        SettingsSharedModule,
        VocabulariesSharedModule,
        WorkflowsSharedModule,
        WorkspacesSharedModule
    ],
    declarations: [
        ClinicalAlertSenderComponent,
        ClinicalFacetComponent,
        ClinicalFilterComponent,
        NewHealthRecordComponent,
        ObservationBulkAddComponent,
        ObservationBulkTemplatesComponent,
        TreatmentPlanBulkTemplatesComponent,
        TreatmentPlanBulkAddComponent,
        ObservationBulkEditComponent,
        TreatmentBulkEditComponent
    ],
    exports: [
        ClinicalFacetComponent
    ],
    providers: [
    ]
})
export class ClinicalModule { }
