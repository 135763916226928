<div class="modal-header justify-content-center">
    <h4 class="modal-title">Create Sample Groups</h4>
</div>

<div class="modal-body" style="overflow-x: auto;">
    <div class="protocol-samples-groups-create-wrapper">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Task</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let task of create.tasks; let index = index">
                    <td>{{task.TaskAlias}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <table class="table table-bordered">
        <thead>
            <tr class="detail-grid-header">
                <th>
                    <a class="add-item-link" (click)="createAddRow()">
                        <i class="fa fa-plus-circle" title="Add Sample Group"></i>
                    </a>
                </th>
                <th>
                    <bulk-edit-header [headerText]="'Samples'"
                                      (updateClicked)="updateBulkNumSamples()">
                        <div modal-content>
                            <input [(ngModel)]="bulkNumSamples"
                                   type="number"
                                   min="1"
                                   step="1"
                                   class="form-control input-small"
                                   name="sampleGroup-bulk-numSamples" />
                        </div>
                    </bulk-edit-header>
                </th>
                <th>
                    <bulk-edit-header [headerText]="'Type'"
                                      (updateClicked)="updateBulkSampleTypeKey()">
                        <div modal-content>
                            <active-vocab-select [(model)]="bulkSampleTypeKey"
                                                 [vocabChoices]="sampleTypes"
                                                 [keyFormatter]="sampleTypeKeyFormatter"
                                                 [optionFormatter]="sampleTypeFormatter"
                                                 [nullable]="true">
                            </active-vocab-select>
                        </div>
                    </bulk-edit-header>
                </th>
                <th>
                    <bulk-edit-header [headerText]="'Status'"
                                      (updateClicked)="updateBulkSampleStatusKey()">
                        <div modal-content>
                            <active-vocab-select [(model)]="bulkSampleStatusKey"
                                                 [vocabChoices]="sampleStatuses"
                                                 [keyFormatter]="sampleStatusKeyFormatter"
                                                 [optionFormatter]="sampleStatusFormatter"
                                                 [nullable]="true">
                            </active-vocab-select>
                        </div>
                    </bulk-edit-header>
                </th>
                <th>
                    <bulk-edit-header [headerText]="'Preservation'"
                                      (updateClicked)="updateBulkPreservationMethodKey()">
                        <div modal-content>
                            <active-vocab-select [(model)]="bulkPreservationMethodKey"
                                                 [vocabChoices]="preservationMethods"
                                                 [keyFormatter]="preservationMethodKeyFormatter"
                                                 [optionFormatter]="preservationMethodFormatter"
                                                 [nullable]="true">
                            </active-vocab-select>
                        </div>
                    </bulk-edit-header>
                </th>
                <th>
                    <bulk-edit-header [headerText]="'Container'"
                                      (updateClicked)="updateBulkContainerTypeKey()">
                        <div modal-content>
                            <active-vocab-select [(model)]="bulkContainerTypeKey"
                                                 [vocabChoices]="containerTypes"
                                                 [keyFormatter]="containerTypeKeyFormatter"
                                                 [optionFormatter]="containerTypeFormatter"
                                                 [nullable]="true">
                            </active-vocab-select>
                        </div>
                    </bulk-edit-header>
                </th>
                <th>
                    <bulk-edit-header [headerText]="'Subtype'"
                                      (updateClicked)="updateBulkSampleSubtypeKey()">
                        <div modal-content>
                            <active-vocab-select [(model)]="bulkSampleSubtypeKey"
                                                 [vocabChoices]="sampleSubtypes"
                                                 [keyFormatter]="sampleSubtypeKeyFormatter"
                                                 [optionFormatter]="sampleSubtypeFormatter"
                                                 [nullable]="true">
                            </active-vocab-select>
                        </div>
                    </bulk-edit-header>
                </th>
                <th>
                    <bulk-edit-header [headerText]="'Processing'"
                                      (updateClicked)="updateBulkSampleProcessingMethodKey()">
                        <div modal-content>
                            <active-vocab-select [(model)]="bulkSampleProcessingMethodKey"
                                                 [vocabChoices]="sampleProcessingMethods"
                                                 [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                                 [optionFormatter]="sampleProcessingMethodFormatter"
                                                 [nullable]="true">
                            </active-vocab-select>
                        </div>
                    </bulk-edit-header>
                </th>
                <th>
                    <bulk-edit-header [headerText]="'Send To'"
                                      (updateClicked)="updateBulkSendTo()">
                        <div modal-content>
                            <input [(ngModel)]="bulkSendTo"
                                   type="text"
                                   maxlength="150"
                                   class="form-control input-small"
                                   name="sampleGroup-bulk-sendTo" />
                        </div>
                    </bulk-edit-header>
                </th>
                <th>
                    <bulk-edit-header [headerText]="'Analysis'"
                                      (updateClicked)="updateBulkSampleAnalysisMethodKey()">
                        <div modal-content>
                            <active-vocab-select [(model)]="bulkSampleAnalysisMethodKey"
                                                 [vocabChoices]="sampleAnalysisMethods"
                                                 [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                                 [optionFormatter]="sampleAnalysisMethodFormatter"
                                                 [nullable]="true">
                            </active-vocab-select>
                        </div>
                    </bulk-edit-header>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of create.rows; let index = index">
                <!-- Remove -->
                <td class="icon-cell">
                    <a (click)="createRemoveRow(index)">
                        <i class="fa fa-remove remove-item" title="Remove Sample Group"></i>
                    </a>
                </td>
                <!-- NumSamples -->
                <td>
                    <input [(ngModel)]="row.NumSamples"
                           type="number"
                           min="1"
                           step="1"
                           class="form-control input-small"
                           name="sampleGroup-create-{{index}}-NumSamples" />
                </td>

                <!-- Type -->
                <td>
                    <active-vocab-select [(model)]="row.C_SampleType_key"
                                         [vocabChoices]="sampleTypes"
                                         [keyFormatter]="sampleTypeKeyFormatter"
                                         [optionFormatter]="sampleTypeFormatter"
                                         [nullable]="true">
                    </active-vocab-select>
                </td>

                <!-- Status -->
                <td>
                    <active-vocab-select [(model)]="row.C_SampleStatus_key"
                                         [vocabChoices]="sampleStatuses"
                                         [keyFormatter]="sampleStatusKeyFormatter"
                                         [optionFormatter]="sampleStatusFormatter"
                                         [nullable]="true">
                    </active-vocab-select>
                </td>

                <!-- Preservation -->
                <td>
                    <active-vocab-select [(model)]="row.C_PreservationMethod_key"
                                         [vocabChoices]="preservationMethods"
                                         [keyFormatter]="preservationMethodKeyFormatter"
                                         [optionFormatter]="preservationMethodFormatter"
                                         [nullable]="true"></active-vocab-select>
                </td>

                <!-- Container -->
                <td>
                    <active-vocab-select [(model)]="row.C_ContainerType_key"
                                         [vocabChoices]="containerTypes"
                                         [keyFormatter]="containerTypeKeyFormatter"
                                         [optionFormatter]="containerTypeFormatter"
                                         [nullable]="true"></active-vocab-select>
                </td>

                <!-- Subtype -->
                <td>
                    <active-vocab-select [(model)]="row.C_SampleSubtype_key"
                                         [vocabChoices]="sampleSubtypes"
                                         [keyFormatter]="sampleSubtypeKeyFormatter"
                                         [optionFormatter]="sampleSubtypeFormatter"
                                         [nullable]="true"></active-vocab-select>
                </td>

                <!-- Processing -->
                <td>
                    <active-vocab-select [(model)]="row.C_SampleProcessingMethod_key"
                                         [vocabChoices]="sampleProcessingMethods"
                                         [keyFormatter]="sampleProcessingMethodKeyFormatter"
                                         [optionFormatter]="sampleProcessingMethodFormatter"
                                         [nullable]="true"></active-vocab-select>
                </td>

                <!-- Send To -->
                <td>
                    <input [(ngModel)]="row.SendTo"
                           type="text"
                           maxlength="150"
                           class="form-control input-small"
                           name="sampleGroup-sendTo" />
                </td>

                <!-- Analysis -->
                <td>
                    <active-vocab-select [(model)]="row.C_SampleAnalysisMethod_key"
                                         [vocabChoices]="sampleAnalysisMethods"
                                         [keyFormatter]="sampleAnalysisMethodKeyFormatter"
                                         [optionFormatter]="sampleAnalysisMethodFormatter"
                                         [nullable]="true"></active-vocab-select>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="modal-footer">
    <button class="btn btn-primary"
            (click)="activeModal.close('create')">
        <i class="fa"
           aria-hidden="true"></i>
        Create Sample Groups
    </button>
    <button class="btn btn-secondary"
            (click)="activeModal.dismiss()">
        Cancel
    </button>
</div>
