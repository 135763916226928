import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';

import { AuditService } from '../audit/audit.service';
import { ExportJobAuditService } from './export-job-audit.service';
import { JobService } from './job.service';

import {
    getSafeProp,
} from '../common/util/';

@Component({
    selector: 'job-audit-report',
    templateUrl: './job-audit-report.component.html'
})
export class JobAuditReportComponent implements OnInit {
    @Input() jobKey: any;
    @Input() showSampleGroups: boolean;
    @Input() isCRL: boolean;
    @Input() isCRO: boolean;
    @Input() isGLP: boolean;

    job: any;
    jobAuditData: any[] = [];
    jobAuditLinesData: any[] = [];
    jobAuditLineDetailsData: any[] = [];
    jobAuditTestArticlesData: any[] = [];    
    jobAuditJobGroupsData: any[] = [];
    jobAuditInstitutionData: any[] = [];
    jobAuditCharacteristicsData: any[] = [];
    jobAuditStandardPhrasesData: any[] = [];
    jobAuditAnimalsData: any[] = [];
    jobAuditSamplesData: any[] = [];
    jobAuditSampleGroupsData: any[] = [];
    jobAuditCurrentTasksData: any[] = [];
    jobAuditDeletedTasksData: any[] = [];
    jobAuditCohortsData: any[] = [];
    jobAuditGroupPlaceholdersData: any[] = [];
    jobAuditAnimalPlaceholdersData: any[] = [];
    loading: boolean = false;

    readonly noDataMessage: string = 'No changes.';


    constructor(
        private activeModal: NgbActiveModal,
        private auditService: AuditService,
        private exportJobAuditService: ExportJobAuditService,
        private jobService: JobService
    ) {
    }

    ngOnInit() {
        this.getData();
    }

    private getData(): Promise<void> {

        let p1 = this.jobService.getJob(this.jobKey)
            .then((data: any) => {
                this.job = data;
            });

        let p2 = this.auditService.getJobAuditData(this.jobKey)
            .then((response: any) => {
                this.jobAuditData = getSafeProp(response, 'data.results') || [];
            });

        let p3 = this.auditService.getJobAuditCharacteristicsData(this.jobKey)
            .then((response: any) => {
                this.jobAuditCharacteristicsData = getSafeProp(response, 'data.results') || [];
            });

        let p4 = this.auditService.getJobAuditAnimalsData(this.jobKey)
            .then((response: any) => {
                this.jobAuditAnimalsData = getSafeProp(response, 'data.results') || [];
            });

        let p5 = this.auditService.getJobAuditSamplesData(this.jobKey)
            .then((response: any) => {
                this.jobAuditSamplesData = getSafeProp(response, 'data.results') || [];
            });

        let p6 = this.auditService.getCurrentJobAuditTasksData(this.jobKey)
            .then((response: any) => {
                this.jobAuditCurrentTasksData = getSafeProp(response, 'data.results') || [];
            });

        let p7 = this.auditService.getJobAuditTestArticlesData(this.jobKey)
            .then((response: any) => {
                this.jobAuditTestArticlesData = getSafeProp(response, 'data.results') || [];
            });

        let p8 = this.auditService.getJobAuditInstitutionData(this.jobKey)
            .then((response: any) => {
                this.jobAuditInstitutionData = getSafeProp(response, 'data.results') || [];
            });

        let p9 = this.auditService.getJobAuditSampleGroupsData(this.jobKey)
            .then((response: any) => {
                this.jobAuditSampleGroupsData = getSafeProp(response, 'data.results') || [];
            });

        let p10 = this.auditService.getJobAuditStandardPhrasesData(this.jobKey)
            .then((response: any) => {
                this.jobAuditStandardPhrasesData = getSafeProp(response, 'data.results') || [];
            });

        let p11 = this.auditService.getJobAuditLinesData(this.jobKey)
            .then((response: any) => {
                this.jobAuditLinesData = getSafeProp(response, 'data.results') || [];
            });

        let p12 = this.auditService.getJobAuditJobGroupData(this.jobKey)
            .then((response: any) => {
                this.jobAuditJobGroupsData = getSafeProp(response, 'data.results') || [];
            });

        let p13 = this.auditService.getJobAuditCohortsData(this.jobKey)
            .then((response: any) => {
                this.jobAuditCohortsData = getSafeProp(response, 'data.results') || [];
            });

        let p14 = this.auditService.getJobAuditGroupPlaceholdersData(this.jobKey)
            .then((response: any) => {
                this.jobAuditGroupPlaceholdersData = getSafeProp(response, 'data.results') || [];
            });

        let p15 = this.auditService.getJobAuditAnimalPlaceholdersData(this.jobKey)
            .then((response: any) => {
                this.jobAuditAnimalPlaceholdersData = getSafeProp(response, 'data.results') || [];
            });

        let p16 = this.auditService.getJobAuditLineDetailsData(this.jobKey)
            .then((response: any) => {
                this.jobAuditLineDetailsData = getSafeProp(response, 'data.results') || [];
            });

        this.loading = true;
        return Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16]).then(() => {
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            throw error;
        });
    }

    onCancel(): void {
        this.activeModal.dismiss();
    }

    exportAudit(): void {
        this.exportJobAuditService.exportToCsv(
            this.jobAuditData,
            this.jobAuditLinesData,
            this.jobAuditLineDetailsData,
            this.jobAuditTestArticlesData,
            this.jobAuditJobGroupsData,
            this.jobAuditInstitutionData,
            this.jobAuditCharacteristicsData,
            this.jobAuditStandardPhrasesData,
            this.jobAuditSampleGroupsData,
            this.jobAuditAnimalsData,
            this.jobAuditSamplesData,
            this.jobAuditCurrentTasksData,
            this.jobAuditDeletedTasksData,
            this.jobAuditCohortsData,
            this.jobAuditGroupPlaceholdersData,
            this.jobAuditAnimalPlaceholdersData,
            this.isCRL,
            this.isCRO,
            this.isGLP
        );
    }
}
