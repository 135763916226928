import { intersection } from '@lodash';
import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import {
    batchArray,
    getSafeProp,
    notEmpty,
    softCompare,
    sortObjectArrayByAccessor,
} from '../common/util';

import {
    getDateRangePredicates,
    getIsUrgentPredicate,
} from '../services/queries';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { AnimalHealthRecord, ClinicalObservationDetail, Entity } from '@common/types';
import { ExtendedJobMaterial } from '../jobs/job-detail.component';

@Injectable()
export class ClinicalService extends BaseEntityService {
    isGLP = false;

    constructor(
        private dataManager: DataManagerService,
        private featureFlagService: FeatureFlagService,
        private vocabularyService: VocabularyService,
    ) {
        super();
        this.initIsGLP();
    }

    // isGLP
    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    getClinicalObservationsCount(queryDef: QueryDef): Promise<number> {
        let query = this.buildDefaultQuery('AnimalClinicalObservations', queryDef);

        if (notEmpty(queryDef.expands)) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildObservationPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .then((queryResult: QueryResult) => queryResult.inlineCount)
            .catch(this.dataManager.queryFailed);
    }

    getDiagnosticObservationsCount(queryDef: QueryDef): Promise<number> {
        let query = this.buildDefaultQuery('AnimalDiagnosticObservations', queryDef);

        if (notEmpty(queryDef.expands)) {
            query = query.expand(queryDef.expands.join(','));
        }

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildObservationPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .then((queryResult: QueryResult) => queryResult.inlineCount)
            .catch(this.dataManager.queryFailed);
    }

    getModifier1(queryDef: QueryDef): Promise<QueryResult> {
        const query = this.buildDefaultQuery('cv_Modifiers1', queryDef);
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getHealthRecords(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('AnimalHealthRecords', queryDef);

        this.ensureDefExpanded(queryDef, 'Animal.Material.Line');
        this.ensureDefExpanded(queryDef, 'Animal.Material.JobMaterial.Job');
        this.ensureDefExpanded(queryDef,
            'Animal.AnimalDiagnosticObservation'
        );
        this.ensureDefExpanded(queryDef,
            'Animal.AnimalClinicalObservation.ClinicalObservationDetail.cv_ClinicalObservation'
        );
        this.ensureDefExpanded(queryDef, 'Animal.AnimalClinicalObservation.Resource');
        this.ensureDefExpanded(queryDef,
            'TaskAnimalHealthRecord.TaskInstance.AssignedToResource'
        );
        this.ensureDefExpanded(queryDef, 'Animal.Material');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }
        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed);
    }


    buildObservationPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (filter.Identifier && filter.Identifier !== 'null') {
            predicates.push(Predicate.create(
                'Animal.Material.Identifier', '==', filter.Identifier
            ));
        }

        return predicates;
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (filter.Identifier && filter.Identifier !== 'null') {
            predicates.push(Predicate.create(
                'Animal.Material.Identifier', '==', filter.Identifier
            ));
        }
        if (filter.AnimalExternalID) {
            predicates.push(Predicate.create(
                'Animal.Material.ExternalIdentifier', FilterQueryOp.Contains, { value: filter.AnimalExternalID },
            ));
        }
        if (notEmpty(filter.microchipIdentifiers)) {
            predicates.push(Predicate.create(
                'Animal.Material.MicrochipIdentifier', 'in', filter.microchipIdentifiers
            ));
        }
        if (filter.AnimalName) {
            predicates.push(Predicate.create(
                'Animal.AnimalName', FilterQueryOp.Contains, { value: filter.AnimalName },
            ));
        }
        if (filter.C_AnimalStatus_key) {
            predicates.push(Predicate.create(
                'Animal.C_AnimalStatus_key', 'eq', filter.C_AnimalStatus_key
            ));
        }
        if (filter.C_AnimalStatus_keys && filter.C_AnimalStatus_keys.length > 0) {
            predicates.push(
                Predicate.create('Animal.C_AnimalStatus_key', 'in', filter.C_AnimalStatus_keys)
            );
        }
        if (filter.C_Material_key) {
            predicates.push(Predicate.create('C_Material_key', 'eq', filter.C_Material_key));
        }
        // Deprecated: replaced by filter.lines
        if (filter.C_Line_Keys && filter.C_Line_Keys.length > 0) {
            predicates.push(Predicate.create(
                'Animal.Material.C_Line_key', 'in', filter.C_Line_Keys
            ));
        }
        if (notEmpty(filter.lines)) {
            const lineKeys = filter.lines.map((line: any) => {
                return line.LineKey;
            });

            predicates.push(Predicate.create(
                'Animal.Material.C_Line_key', 'in', lineKeys
            ));
        }
        if (filter.JobID) {
            predicates.push(Predicate.create(
                'Animal.Material.JobMaterial', FilterQueryOp.Any,
                'Job.JobID', FilterQueryOp.Contains, { value: filter.JobID },
            ));
        }
        if (notEmpty(filter.C_BodyConditionScore_keys)) {
            predicates.push(Predicate.create(
                'Animal.C_BodyConditionScore_key', 'in', filter.C_BodyConditionScore_keys
            ));
        }
        if (filter.C_AssignedTo_key) {
            predicates.push(
                Predicate.create('C_Resource_key', 'eq', filter.C_AssignedTo_key)
            );
        }
        if (filter.IsUrgent) {
            const isUrgentPredicate: Predicate = getIsUrgentPredicate(filter.IsUrgent);
            predicates.push(isUrgentPredicate);
        }

        const hasObservationsFilter = notEmpty(filter.C_ClinicalObservation_keys);

        // observation filters
        if (filter.C_Resource_key ||
            filter.ObservedByUsername ||
            hasObservationsFilter ||
            filter.DateObservedStart ||
            filter.DateObservedEnd ||
            notEmpty(filter.C_ClinicalObservationStatus_keys)
        ) {
            let observationPredicates: Predicate[] = [];
            if (this.isGLP) {
                if (filter.ObservedByUsername) {
                    observationPredicates.push(Predicate.create(
                        'ObservedByUsername', 'eq', filter.ObservedByUsername)
                    );
                }
                if (hasObservationsFilter) {
                    observationPredicates.push(Predicate.create(
                        'C_ClinicalObservation_key', 'in',
                        filter.C_ClinicalObservation_keys)
                    );
                }
            } else {
                if (filter.C_Resource_key) {
                    observationPredicates.push(Predicate.create(
                        'C_Resource_key', 'eq', filter.C_Resource_key)
                    );
                }
                if (hasObservationsFilter) {
                    observationPredicates.push(Predicate.create(
                        'ClinicalObservationDetail', 'any',
                        'C_ClinicalObservation_key', 'in',
                        filter.C_ClinicalObservation_keys)
                    );
                }
            }

            if (notEmpty(filter.C_ClinicalObservationStatus_keys)) {
                observationPredicates.push(Predicate.create(
                    'C_ClinicalObservationStatus_key', 'in',
                    filter.C_ClinicalObservationStatus_keys
                ));
            }

            if (filter.DateObservedStart || filter.DateObservedEnd) {
                const datePredicates: Predicate[] = getDateRangePredicates(
                    'DateObserved',
                    filter.DateObservedStart,
                    filter.DateObservedEnd
                );

                if (notEmpty(datePredicates)) {
                    observationPredicates = observationPredicates.concat(datePredicates);
                }
            }

            predicates.push(Predicate.create(
                'Animal.AnimalClinicalObservation', 'any',
                Predicate.and(observationPredicates)
            ));
        }

        if (this.isGLP) {
            const diagnosticObservationPredicates: Predicate[] = [];
            if (notEmpty(filter.C_DiagnosticObservation_keys)) {
                diagnosticObservationPredicates.push(Predicate.create(
                    'C_ClinicalObservation_key', 'in',
                    filter.C_DiagnosticObservation_keys
                ));
            }

            if (notEmpty(filter.C_DiagnosticObservationStatus_keys)) {
                diagnosticObservationPredicates.push(Predicate.create(
                    'C_ClinicalObservationStatus_key', 'in',
                    filter.C_DiagnosticObservationStatus_keys
                ));
            }

            if (notEmpty(diagnosticObservationPredicates)) {
                predicates.push(Predicate.create(
                    'Animal.AnimalDiagnosticObservation', 'any',
                    Predicate.and(diagnosticObservationPredicates)
                ));
            }
        }

        // Treatment Plan filters
        if (filter.DateDueStart || filter.DateDueEnd) {
            const dateDuePredicates: Predicate[] = getDateRangePredicates(
                'TaskInstance.DateDue',
                filter.DateDueStart,
                filter.DateDueEnd
            );

            if (notEmpty(dateDuePredicates)) {
                // Include only incomplete tasks
                dateDuePredicates.push(Predicate.create(
                    'TaskInstance.DateComplete', '==', null
                ));

                predicates.push(Predicate.create(
                    'TaskAnimalHealthRecord', 'any',
                    Predicate.and(dateDuePredicates)
                ));
            }
        }

        // handle workspace filters
        if ('animal-filter' in filter) {
            predicates.push(Predicate.create(
                'Animal.C_Material_key', 'in', filter['animal-filter']
            ));
        }
        if ('job-filter' in filter) {
            predicates.push(Predicate.create(
                'Animal.Material.JobMaterial', 'any',
                'Job.C_Job_key', 'in', filter['job-filter']
            ));
        }

        return predicates;
    }

    getObservationCVs(): Promise<any[]> {
        const query = EntityQuery.from('cv_ClinicalObservations')
            .orderBy('ClinicalObservation');

        return this.dataManager.returnQueryResults(query);
    }

    /**
     * Queries all ClinicalObservationDetails for each
     * materialkey in materialKeys. 
     *   Returns a detailMap of materialKey -> ClinicalObservationDetail[]
     * @param materialKeys 
     */
    getObservationDetailsForMaterials(materialKeys: number[]): Promise<Record<string, ClinicalObservationDetail[]>> {

        const batchSize = 50;
        const promises: Promise<void>[] = [];
        const detailsMap: Record<string, ClinicalObservationDetail[]> = {};
        for (const batch of batchArray(materialKeys, batchSize)) {
            promises.push(
                this._getObservationDetailsForMaterialsBatch(batch, detailsMap)
            );
        }

        return Promise.all(promises).then(() => {
            return detailsMap;
        });
    }

    public async mapDetailsClinicalHistory(jobMaterials: ExtendedJobMaterial[]): Promise<ExtendedJobMaterial[]> {
        const materialKeys = jobMaterials.map(item => item.C_Material_key);

        const detailsMap = await this.getObservationDetailsForMaterials(materialKeys);
        for (const animalMaterial of jobMaterials) {
            animalMaterial.clinicalHistory = [];
            const materialKey = animalMaterial.C_Material_key;
            if (materialKey in detailsMap) {
                animalMaterial.clinicalHistory = detailsMap[materialKey];
                sortObjectArrayByAccessor(animalMaterial.clinicalHistory, (item: ClinicalObservationDetail) => {
                    return item.AnimalClinicalObservation.DateObserved;
                }, true);
            }
        }
        return jobMaterials;
    }

    private _getObservationDetailsForMaterialsBatch(
        materialKeys: number[],
        detailsMap: Record<string, any>
    ): Promise<void> {
        const predicates: Predicate[] = [];
        predicates.push(Predicate.create(
            'AnimalClinicalObservation.C_Material_key', 'in', materialKeys
        ));

        const expands = [
            'AnimalClinicalObservation',
            'cv_ClinicalObservation'
        ];

        const query = EntityQuery.from('ClinicalObservationDetails')
            .where(Predicate.and(predicates))
            .expand(expands.join(','));

        return this.dataManager.getQueryResults<ClinicalObservationDetail>(query).then((results) => {
            // group results to details map by C_Material_key
            for (const result of results) {
                const materialKey = result.AnimalClinicalObservation.C_Material_key;
                if (!(materialKey in detailsMap)) {
                    detailsMap[materialKey] = [];
                }
                detailsMap[materialKey].push(result);
            }
        });
    }

    getHealthRecordTasks(): Promise<any[]> {
        const predicate = Predicate.create(
            'cv_TaskType.TaskType', '==', 'Health Record'
        );

        const query = EntityQuery.from('WorkflowTasks')
            .where(predicate)
            .orderBy('TaskName')
            .select('C_WorkflowTask_key, TaskName')
            .expand('cv_TaskType');

        return this.dataManager.returnQueryResults(query);
    }

    getHealthRecord(materialKey: number, expands?: string[]): Promise<Entity<AnimalHealthRecord>> {
        if (!expands) {
            expands = [];
        }
        this.ensureExpanded(expands, 'Animal');

        const query = EntityQuery.from('AnimalHealthRecords')
            .expand(expands.join(", "))
            .where('C_Material_key', '==', materialKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    /**
     * Loads animal health records into the Breeze cache.
     * @param materialKeys animal keys
     */
    async loadHealthRecords(materialKeys: number[]): Promise<void> { 
        if (!materialKeys || materialKeys.length === 0) {
            return;
        }

        const query = EntityQuery.from('AnimalHealthRecords')
            .expand('Animal')
            .where('C_Material_key', 'in', materialKeys);

        await this.dataManager.returnQueryResults(query);
    }

    getAnimalForHealthRecordTask(taskKey: any): Promise<any[]> {
        const predicate = Predicate.create(
            'AnimalHealthRecord.TaskAnimalHealthRecord', 'any',
            'C_TaskInstance_key', 'eq', taskKey
        );

        const query = EntityQuery.from('Animals')
            .expand("Material")
            .where(predicate);
        return this.dataManager.returnQueryResults(query);
    }

    updateReviewDate(observation: any) {
        if (observation) {
           observation.ReviewDate = observation.ReviewDate;
        }
    }

    createHealthRecord(initialValues: any): Entity<AnimalHealthRecord> {
        initialValues.DateCreated = new Date();
        initialValues.IsUrgent = false;

        return this.dataManager.createEntity('AnimalHealthRecord', initialValues);
    }

    createObservation(initialValues: any): any {
        return this.dataManager.createEntity('AnimalClinicalObservation', initialValues);
    }

    createDiagnosticObservation(initialValues: any): any {
        return this.dataManager.createEntity('AnimalDiagnosticObservation', initialValues);
    }

    createObservationDetail(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const initialObservationKey = initialValues.C_AnimalClinicalObservation_key;
        const initialCVKey = initialValues.C_ClinicalObservation_key;

        // Check local entities for duplicates
        const details: any[] = manager.getEntities('ClinicalObservationDetail');
        const duplicates = details.filter((detail) => {
            return softCompare(detail.C_AnimalClinicalObservation_key, initialObservationKey) &&
                softCompare(detail.C_ClinicalObservation_key, initialCVKey);
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity('ClinicalObservationDetail', initialValues);
        }

        return null;
    }

    /**
     * Returns Promise with new TaskInstance
     * @param materialKey
     * @param initialValues
     */
    createHealthRecordTask(materialKey: number, initialValues: any, plan?: string, jobNames?: string): Promise<any> {
        if (!initialValues) {
            initialValues = {};
        }
        initialValues.IsLocked = false;

        const newTask: any = this.dataManager.createEntity('TaskInstance', initialValues);
        this.dataManager.createEntity(
            'TaskAnimalHealthRecord',
            {
                C_Material_key: materialKey,
                C_TaskInstance_key: newTask.C_TaskInstance_key,
                JobName: jobNames
            }
        );

        // attach inputs to new task instance
        const query = EntityQuery.from('Inputs')
            .where('C_WorkflowTask_key', '==', newTask.C_WorkflowTask_key);

        return this.dataManager.executeQuery(query).then((response) => {
            const inputs = response.results as any[];
            for (const input of inputs) {
                const inputValues = {
                    C_TaskInstance_key: newTask.C_TaskInstance_key,
                    C_Input_key: input.C_Input_key,
                    InputValue: plan ? plan : null
                };
                this.dataManager.createEntity('TaskInput', inputValues);
            }
            return newTask;
        });
    }

    deleteObservation(observation: any) {
        while (observation.Event.length > 0) {
            this.dataManager.deleteEntity(observation.Event[0]);
        }
        while (observation.ClinicalObservationDetail.length > 0) {
            this.dataManager.deleteEntity(observation.ClinicalObservationDetail[0]);
        }
        
        this.dataManager.deleteEntity(observation);
    }

    deleteDiagnosticObservation(observation: any) {
        if (observation.Event) {
            while (observation.Event.length > 0) {
                this.dataManager.deleteEntity(observation.Event[0]);
            }
        }

        if (observation.DiagnosticObservationDetail) {
            while (observation.DiagnosticObservationDetail.length > 0) {
                this.dataManager.deleteEntity(observation.DiagnosticObservationDetail[0]);
            }
        }
        this.dataManager.deleteEntity(observation);
    }

    deleteObservationDetail(observationDetail: any) {
        this.dataManager.deleteEntity(observationDetail);
    }

    deleteEvent(event: any) {
        this.dataManager.deleteEntity(event);
    }

    createClinicalObservationBodySystem(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const entityName = 'cv_ClinicalObservationBodySystem';

        // Check local entities for duplicates
        const initialObservationKey = initialValues.C_ClinicalObservation_key;
        const initialBodySystemKey = initialValues.C_BodySystem_key;
        const clinicalObservationBodySystems: any[] = manager.getEntities(entityName);

        const duplicates = clinicalObservationBodySystems.filter((assoc) => {
            return softCompare(assoc.C_ClinicalObservation_key, initialObservationKey) &&
                softCompare(assoc.C_BodySystem_key, initialBodySystemKey);
        });

        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityName, initialValues);
        }

        return null;
    }

    deleteClinicalObservationBodySystem(clinicalObservationBodySystem: any) {
        this.dataManager.deleteEntity(clinicalObservationBodySystem);
    }

    cancelHealthRecord(healthRecord: any) {
        if (healthRecord && healthRecord.Animal) {
            if (healthRecord.C_Material_key > 0) {
                this._cancelHealthRecordEdits(healthRecord);
            } else {
                this._cancelNewHealthRecord(healthRecord);
            }
        }
    }

    private _cancelNewHealthRecord(healthRecord: any) {
        try {
            this._cancelHealthRecordEdits(healthRecord);
        } catch (error) {
            console.error('Error canceling new health record: ' + error);
        }
    }

    private _cancelHealthRecordEdits(healthRecord: any) {

        const observations = getSafeProp(healthRecord, 'Animal.AnimalClinicalObservation');
        if (observations) {
            for (const observation of observations) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'ClinicalObservationDetail', (item: any) => {
                        return item.C_AnimalClinicalObservation_key ===
                            observation.C_AnimalClinicalObservation_key;
                    }
                );
            }
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'AnimalClinicalObservation', (item: any) => {
                return item.C_Material_key === healthRecord.C_Material_key;
            }
        );

        const taskRecords = healthRecord.TaskAnimalHealthRecord;
        if (taskRecords) {
            for (const taskRecord of taskRecords) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskInstance', (item: any) => {
                        return item.C_TaskInstance_key === taskRecord.C_TaskInstance_key;
                    }
                );
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskInput', (item: any) => {
                        return item.C_TaskInstance_key === taskRecord.C_TaskInstance_key;
                    }
                );
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskOutputSet', (item: any) => {
                        return item.C_TaskInstance_key === taskRecord.C_TaskInstance_key;
                    }
                );
            }
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialPoolMaterial', (item: any) => {
                return item.C_Material_key === healthRecord.C_Material_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'Animal', (item: any) => {
                return item.C_Material_key === healthRecord.C_Material_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'TaskAnimalHealthRecord', (item: any) => {
                return item.C_Material_key === healthRecord.C_Material_key;
            }
        );

        const fileMaps = this.dataManager.rejectChangesToEntityByFilter(
            'StoredFileMap', (item: any) => {
                return item.C_AnimalHealthRecord_key === healthRecord.C_Material_key;
            }
        );
        // also reject files associated with each fileMap
        for (const fileMap of fileMaps) {
            this.dataManager.rejectChangesToEntityByFilter(
                'StoredFile', (item: any) => {
                    return item.C_StoredFile_key === fileMap.C_StoredFile_key;
                }
            );
        }

        if (healthRecord) { 
            this.dataManager.rejectEntityAndRelatedPropertyChanges(healthRecord);
        }
    }

    async ensureVisibleColumnsDataLoaded(healthRecords: any[], visibleColumns: string[]): Promise<void> {
        const fieldsRequireCVClinicalObservation = [
            'Animal.AnimalClinicalObservation[0]',
            'Animal.AnimalDiagnosticObservation[0]',
        ];
        const fieldsRequireCVClinicalObservationStatus = [
            'Animal.AnimalClinicalObservation',
            'Animal.AnimalClinicalObservation[0]',
            'Animal.AnimalDiagnosticObservation[0].C_AnimalClinicalObservation_key',
        ];
        const isCVClinicalObservation = intersection(visibleColumns, fieldsRequireCVClinicalObservation).length > 0;
        const isCVClinicalObservationStatus = intersection(visibleColumns, fieldsRequireCVClinicalObservationStatus).length > 0;
        if (isCVClinicalObservation) {
            await this.vocabularyService.getCV('cv_ClinicalObservations');
        }
        if (isCVClinicalObservationStatus) {
            await this.vocabularyService.getCV('cv_ClinicalObservationStatuses');
        }
        const expands = this.generateExpandsFromVisibleColumns(healthRecords[0], visibleColumns);
        return this.dataManager.ensureRelationships(healthRecords, expands);
    }
}

