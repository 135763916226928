<div class="modal-header justify-content-center">
    <h4 class="modal-title">{{ showCohorts ? 'Assign Animals' : 'Assign Placeholders' }}</h4>
</div>

<div class="modal-body" style="overflow-x: auto;">
    <table class="table table-borderless mb-0">
        <tbody>
            <tr *ngIf="showCohorts">
                <th>
                    <div class="form-group row">
                        <label for="cohort" class="col-md-2 col-form-label">Cohort(s)</label>
                        <div class="col-md-9">
                            <ng-container *ngFor="let cohort of job.JobCohort">
                                <span class="cohort-badge" *ngIf="cohort.isSelected == selectedJobCohorts"
                                    [ngStyle]="{ backgroundColor: cohort.Cohort.BackgroundColor, color: cohort.Cohort.ForegroundColor }">
                                    {{cohort.Cohort.CohortName}}
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </th>
            </tr>
            <tr *ngIf="!showCohorts">
                <th>
                    <div class="form-group row">
                        <label for="placeholder" class="col-md-2 col-form-label">Group Placeholder(s)</label>
                        <div class="col-md-9">
                            <ng-container *ngFor="let placeholder of job.Placeholder">
                                <span class="cohort-badge" *ngIf="placeholder.isSelected == selectedPlaceholder" >
                                    {{placeholder.PlaceholderName}}
                                </span>

                            </ng-container>
                        </div>
                    </div>
                </th>
            </tr>
            <tr>
                <th>
                    <div class="form-group row">
                        <label for="cohort" class="col-md-2 col-form-label">Protocol</label>
                        <div class="col-md-9">
                            <select class="form-control input-medium" [(ngModel)]="protocolSelected" (change)="protocolSelectionChanged()">
                                <option [ngValue]="'null'"></option>
                                <option *ngFor="let protocol of protocols" [ngValue]="protocol.C_ProtocolInstance_key">
                                    {{protocol.ProtocolAlias}}
                                </option>
                            </select>
                        </div>
                    </div>
                </th>
            </tr>
            <tr>
                <th>
                    <div class="form-group row">
                        <label for="cohort" class="col-md-2 col-form-label">Task(s)</label>
                        <div class="col-md-9">
                            <climb-multi-select *ngIf="protocolSelected == 'null'" [(model)]="freeTasksSelected"
                                [values]="freeTasks" [keyProperty]="'C_WorkflowTask_key'" [valueProperty]="'TaskName'"
                                [emitOnClose]="true" [placeholder]="'Select Tasks...'"
                                [buttonClasses]="taskSelectValid ? '' : 'ng-invalid'"></climb-multi-select>

                            <climb-multi-select *ngIf="protocolSelected != 'null'" [(model)]="protocolTasksSelected"
                                [values]="protocolToWorkflowTaskMap[protocolSelected]"
                                [keyProperty]="'C_WorkflowTask_key'" [valueProperty]="'TaskName'" [emitOnClose]="true"
                                [placeholder]="'Select Tasks...'" (modelChange)="protocolTasksSelectionChanged()"
                                [buttonClasses]="taskSelectValid ? '' : 'ng-invalid'">
                            </climb-multi-select>
                        </div>
                    </div>
                </th>
            </tr>

            <tr *ngIf="protocolSelected != 'null' && protocolTasksSelected.length > 0 && taskAliases.length > 0">
                <th>
                    <div class="form-group row">
                        <label for="cohort" class="col-md-2 col-form-label">Task Name(s)</label>
                        <div class="col-md-9">
                            <climb-multi-select [(model)]="taskAliasesSelected" [values]="taskAliases"
                                [keyProperty]="'C_TaskInstance_key'" [valueProperty]="'TaskAlias'" [emitOnClose]="true"
                                [placeholder]="'Select Task Name(s)...'"></climb-multi-select>
                        </div>
                    </div>
                </th>
            </tr>
        </tbody>
    </table>

    <div style="border-top: 1px dotted rgb(0 0 0 / 20%); padding: 1rem 1rem 0;">
        <div class="form-group row form-check" style="margin-bottom: 0 !important; display: flex; align-items: center;">
            <input id="chooseCohortTypeRandom" type="radio" name="chooseCohortType" title="Choose Random" [(ngModel)]="randomChooseCohorts"
                    value="random" class="form-check-input">
            <label for="chooseCohortTypeRandom" class="form-check-label">Random selection of</label>
            <input type="number" class="form-control input-small m-1" style="width:40px !important; min-width: 40px;"
                [(ngModel)]="noOfCohorts" [disabled]="randomChooseCohorts === 'individual'"
                (change)="checkMinCohortAnimal()" [max]="minAnimalCount" min="0">
            <label for="chooseCohortTypeRandom" class="form-check-label">{{ showCohorts ? 'animals from each cohort' : 'placeholders from each group placeholder' }}</label>
        </div>
    </div>
    <div style="padding: 10px 1rem 0;">
        <div class="form-group row form-check d-flex" style="margin-bottom:0 !important;">
            <div class="col-md-6" style="padding: 0;">
                <input id="chooseCohortTypeSpecific" type="radio" name="chooseCohortType" title="Choose Random"
                        [(ngModel)]="randomChooseCohorts" value="individual" class="form-check-input">
                <label class="form-check-label" for="chooseCohortTypeSpecific">Specific selection</label>
            </div>
            <div class="col-md-6 text-right pr-0" *ngIf="columnVisible">
                <climb-column-select [columnLabels]="{
                                         model: cohortAnimalColumns,
                                         labels: cohortAnimalColumnLabels
                                     }"
                                     (onChange)="columnChanged($event)"
                                     class="old-fashioned">
                </climb-column-select>
            </div>
        </div>
    </div>
    <div *ngIf="randomChooseCohorts === 'individual'" class="pt-3">
        <ng-container *ngIf="showCohorts">
            <ng-container *ngFor="let cohort of job.JobCohort">
                <div *ngIf="cohort.isSelected === selectedJobCohorts" class="bulk-modal-table-wrapper">
                    <p class="bulk-modal-table-info">
                        <span class="cohort-badge cohort-ellipsis"
                            [ngStyle]="{ backgroundColor: cohort.Cohort.BackgroundColor, color: cohort.Cohort.ForegroundColor }">
                            {{cohort.Cohort.CohortName}}
                        </span>
                        <a (click)="cohort.allHidden = !cohort.allHidden"
                            class="cohort-toggle-link"><i class="fa"
                                [ngClass]="cohort.allHidden ? 'fa-chevron-down' : 'fa-chevron-up'"
                                [title]="(cohort.allHidden ? 'Show ' : 'Hide ')"
                                aria-hidden="true"></i></a>
                        <span class="bulk-modal-selected-info">{{ selectedCount(cohort) }} selected from total of {{cohort.Cohort.CohortMaterial.length}}</span>
                    </p>
                    <table *ngIf="!cohort.allHidden" class="table table-hover bulk-modal-table-bordered">
                        <thead>
                            <tr class="detail-grid-header">
                                <th class="icon-cell">
                                    <input type="checkbox" name="" id="" [(ngModel)]="cohort.allSelected"
                                        (ngModelChange)="allSelectedChanged(cohort)">
                                </th>
                                <th *ngIf="cohortAnimalColumnVisible.ID">
                                    ID
                                </th>
                                <th *ngIf="cohortAnimalColumnVisible.Name">
                                    Name
                                </th>
                                <th *ngIf="cohortAnimalColumnVisible.Line">
                                    Line
                                </th>
                                <th *ngIf="cohortAnimalColumnVisible.Genotype">
                                    Genotype
                                </th>
                                <th *ngIf="cohortAnimalColumnVisible.Sex">
                                    Sex
                                </th>
                                <th *ngIf="cohortAnimalColumnVisible.BirthDate">
                                    Birth Date
                                </th>
                                <th *ngIf="cohortAnimalColumnVisible.Status">
                                    Status
                                </th>
                                <th *ngIf="cohortAnimalColumnVisible.HousingId">
                                    Housing ID
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let animal of cohort.Cohort.CohortMaterial">
                                <td class="icon-cell">
                                    <input type="checkbox" name="" id="" [(ngModel)]="animal.isSelected" (ngModelChange)="selectedChanged(cohort)">
                                </td>
                                <td *ngIf="cohortAnimalColumnVisible.ID">
                                    {{animal.Material.C_Material_key}}
                                </td>
                                <td *ngIf="cohortAnimalColumnVisible.Name">
                                    {{animal.Material.Animal.AnimalName}}
                                </td>
                                <td *ngIf="cohortAnimalColumnVisible.Line">
                                    {{animal.Material.Line?.LineName}}
                                </td>
                                <td *ngIf="cohortAnimalColumnVisible.Genotype">
                                    <ng-template [ngIf]="animal.Material.Animal.Genotype?.length > 0">
                                        <span>{{ animal.Material.Animal.Genotype | formatGenotypeNames}}</span>
                                    </ng-template>
                                </td>
                                <td *ngIf="cohortAnimalColumnVisible.Sex">
                                    {{animal.Material.Animal.cv_Sex?.Sex}}
                                </td>
                                <td *ngIf="cohortAnimalColumnVisible.BirthDate">
                                    {{animal.Material.Animal.DateBorn | formatShortDate}}
                                </td>
                                <td *ngIf="cohortAnimalColumnVisible.Status">
                                    {{animal.Material.Animal.cv_AnimalStatus?.AnimalStatus}}
                                </td>
                                <td *ngIf="cohortAnimalColumnVisible.HousingId">
                                    {{animal.Material?.MaterialPoolMaterial | currentMaterialHousingID}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!showCohorts">
            <ng-container *ngFor="let placeholder of job.Placeholder">
                <div *ngIf="placeholder.isSelected == selectedPlaceholder" class="bulk-modal-table-wrapper">
                    <p class="bulk-modal-table-info">
                        {{placeholder.PlaceholderName}}
                        <a (click)="placeholder.allHidden = !placeholder.allHidden"
                            class="placeholder-toggle-link"><i class="fa"
                                [ngClass]="placeholder.allHidden ? 'fa-chevron-up' : 'fa-chevron-down'"
                                [title]="(placeholder.allHidden ? 'Hide ' : 'Show ') + 'label'"
                                aria-hidden="true"></i></a>
                        <span class="bulk-modal-selected-info">{{ selectedPlaceholderCount(placeholder) }} selected from total of {{placeholder.AnimalPlaceholders.length}}</span>
                    </p>
                    <table *ngIf="!placeholder.allHidden" class="table table-hover bulk-modal-table-bordered">
                        <thead>
                            <tr class="detail-grid-header">
                                <th class="icon-cell">
                                    <input type="checkbox" name="" id="" [(ngModel)]="placeholder.allSelected"
                                        (ngModelChange)="allSelectedChangedPlaceholder(placeholder)">
                                </th>
                                <th>
                                    Animal Placeholder Name
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let animal of placeholder.AnimalPlaceholders">
                                <td class="icon-cell">
                                    <input type="checkbox" name="" id="" [(ngModel)]="animal.isSelected" (ngModelChange)="selectedPlaceholderChanged(placeholder)">
                                </td>
                                <td>
                                    {{animal.Name}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </ng-container>
    </div>

</div>
<div class="modal-footer">
    <button class="btn btn-fw btn-lg btn-primary" (click)="onSubmit()" [disabled]="checkAddDisabled"
        [title]="maxAnimalError ? 'Random Cohort Selection number is invalid' : ''">
        Add
    </button>
    <button class="btn btn-fw btn-lg btn-secondary" (click)="onCancel()">
        Cancel
    </button>
</div>
