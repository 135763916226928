const ELLIPSIS = '\u2026';

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { SearchService } from '../search/search.service';
import { SearchQueryDef } from '../search/search-query-def';
import { TranslationService } from '../services/translation.service';

@Component({
    selector: 'job-subtype-select',
    template: `
    <div>
        <climb-typeahead
            [(model)]="model"
            [resultFormatter]="jobSubtypeFormatter"
            [keyFormatter]="jobSubtypeKeyFormatter"
            [search]="searchJobSubtypes"
            [keySearch]="searchJobSubtypesByKey"  
            [required]="required"
            [disabled]="disabled"
            [placeholder]="placeholder"
            [namespace]="'jobSubtype'"
            (selectItem)="selectItemHandler($event)"
            #typeahead
        ></climb-typeahead>
    </div>
    `
})
export class JobSubtypeSelectComponent implements OnInit {
    @Input() model: any;
    @Input() excludeLocked: boolean = false;
    @Input() required: boolean;
    @Input() disabled: boolean;

    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    placeholder: string;

    constructor(
        private searchService: SearchService,
        private translationService: TranslationService
    ) {
    }

    ngOnInit() {
        this.placeholder = this.translationService.translate('Job') + ' Subtype' +
            ELLIPSIS;
    }

    selectItemHandler(item: any) {
        this.modelChange.emit(this.model);
    }

    searchJobSubtypes = (search: string): Promise<any[]> => {

        let filter: any = {
            JobSubtype: search
        };

        if (this.excludeLocked) {
            filter.IsLocked = false;
        }

        return this._searchJobSubtypes(filter, 50, true);
    }

    searchJobSubtypesByKey = (key: any): Promise<any[]> => {
        let filter = {
            JobSubtypeKey: key
        };
        return this._searchJobSubtypes(filter, 1, false);
    }

    _searchJobSubtypes(thisFilter: any, count: number, isActive: boolean): Promise<any[]> {
        let searchQueryDef: SearchQueryDef = {
            entity: 'cv_JobSubtypes',
            page: null,
            size: count,
            sortColumn: 'JobSubtype',
            sortDirection: 'asc',
            isActive: isActive,
            filter: thisFilter
        };     
        return this.searchService.getEntitiesBySearch(searchQueryDef).then((results: any) => {
            return results.data;
        });
    }

    jobSubtypeKeyFormatter = (value: any) => {
        return value.JobSubtypeKey;
    }
    jobSubtypeFormatter = (value: any) => {
        return value.JobSubtype;
    }
}
