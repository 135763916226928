import {
    CellFormatterService,
    END_STATE_CLASS,
    FormatterOptions,
    TableOptions
} from '@common/datatable';
import { TranslationService } from '@services/translation.service';
import {
    isDateOverdue,
    isToday,
    isDateMidnight,
} from '@common/util';
import { DateFormatterService } from '@common/util/date-time-formatting';

import { DateTime } from 'luxon';
import { addAuditColumnsToOptions, ignoreColumnsInOptions, pipe } from '@common/datatable/utils';

export class WorkflowTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private isGLP: boolean,
        private dateFormatterService: DateFormatterService
    ) {
        this.translationService = translationService;
        this.options = pipe(
            this.getDefaultOptions(),
            addAuditColumnsToOptions(cellFormatterService),
            ignoreColumnsInOptions(),
        );
    }

    getDefaultOptions(): TableOptions {
        return {
            csvFileName: 'workflow.csv',
            enableAddButton: false,
            enableDetailColumn: true,
            enableSelectable: true,
            enableDraggable: false,
            useUtcDates: true,
            refreshOnColumnChange: true,
            rowClass: (params: any) => {
                const row = params.data;
                const classes: string[] = [];
                if (row && row.IsEndState) {
                    classes.push(END_STATE_CLASS);
                }
                return classes;
            },
            columns: [
                {
                    displayName: 'Count',
                    field: 'TaskCount',
                    visible: false,
                    sortable: false
                },
                {
                    displayName: 'Status',
                    field: 'TaskStatus'
                },
                {
                    displayName: this.translationService.translate('Job') + ' Status',
                    field: 'JobStatus'
                },
                {
                    displayName: this.translationService.translate('Job') + ' Locked', field: 'IsLocked',
                    formatter: this.cellFormatterService.lockedFormatter,
                    exportFormatter: this.cellFormatterService.booleanExportFormatter,
                    maxWidth: 125,
                },
                {
                    displayName: 'Data Locked',
                    field: 'DataLocked',
                    visible: false
                },
                {
                    displayName: this.translationService.translate('Study'),
                    field: 'StudyName',
                    visible: false,
                },
                {
                    displayName: this.translationService.translate('Job'),
                    field: 'JobId'
                },
                {
                    displayName: 'Task Name',
                    field: 'TaskAlias',
                    sortField: 'TaskAliasSortable'
                },
                {
                    displayName: 'Task',
                    field: 'TaskName',
                    sortField: 'TaskNameSortable',
                    visible: false
                },
                {
                    displayName: 'Type',
                    field: 'TaskType',
                    formatter: (row: any, value: any) => {
                        return this.translationService.translate(value);
                    },
                    visible: false
                },
                {
                    displayName: this.translationService.translate('Job') + ' Created By',
                    field: 'JobCreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    visible: false
                },
                {
                    displayName: 'Input Count',
                    field: 'InputCount',
                    visible: false
                },
                {
                    displayName: 'Output Count',
                    field: 'OutputCount',
                    visible: false
                },
                {
                    displayName: 'Animal ID',
                    field: 'AnimalID',
                    visible: false,
                    sortable: true
                },
                {
                    displayName: 'Alternate Physical ID',
                    field: 'AlternatePhysicalID',
                    visible: false,
                    ignore: !this.isGLP,
                    sortable: true
                },
                {
                    displayName: 'Animal Name',
                    field: 'AnimalName',
                    sortField: 'AnimalNameSortable',
                    visible: false
                },
                {
                    displayName: 'Animal Status',
                    field: 'AnimalStatus',
                    visible: false
                },
                {
                    displayName: 'Animal Owner',
                    field: 'Owner',
                    visible: false
                },
                {
                    displayName: 'Animal Use',
                    field: 'AnimalUse',
                    visible: false
                },
                {
                    displayName: 'Animal Classification',
                    field: 'AnimalClassification',
                    visible: false,
                    ignore: !this.isGLP,
                    sortable: true
                },
                {
                    displayName: 'Cohorts',
                    field: 'Cohorts',
                    visible: false, 
                    exportFormatter: (row: any, value: any) => {
                        const values = value?.split('^o_O^');
                        let cohorts: any[] = [];
                        for (let i = 0; i < values.length; i = i + 3) {
                            cohorts.push(values[i])
                        }
                        return cohorts.join(', ');
                    },
                    formatter: (row: any, value: any, formatterOptions: any) => {
                        if (!value) {
                            return value ?? "";
                        }
                        formatterOptions.escapeHTML = false;
                        // Each cohort has 3 associated values separated by kittens ^o_O^
                        //      - Cohort name
                        //      - Cohort background color (or empty string)
                        //      - Cohort text color (or empty string)
                        const values = value?.split('^o_O^');
                        let output = '';
                        for (let i = 0; i < values.length; i = i + 3) {
                            const cohortName = values[i];
                            const background = `background-color: ${values[i + 1]}; `;
                            const foreground = `color: ${values[i + 2]}; `;
                            const style = (background + foreground)
                                .replace('background-color: ; ', '')
                                .replace('color: ; ', '');

                            output = output +
                                `<div class='cohort-badge' style='${style}'>`.replace(' style=\'\'', '') +
                                cohortName +
                                '</div>';
                        }
                        return output;
                    },
                },
                {
                    displayName: 'Protocol',
                    field: 'ProtocolName',
                    visible: false
                },
                {
                    displayName: 'Birth ID',
                    field: 'BirthId',
                    visible: false
                },
                {
                    displayName: 'Mating ID',
                    field: 'MatingId',
                    visible: false
                },
                {
                    displayName: 'Task Location',
                    field: 'CurrentLocationPath',
                    visible: false
                },
                {
                    displayName: 'Material Location',
                    field: 'AnimalLocationPath',
                    visible: false
                },
                {
                    displayName: this.translationService.translate('Line'),
                    field: 'LineName',
                    visible: false
                },
                {
                    displayName: 'Housing ID',
                    field: 'HousingId',
                    visible: false
                },
                {
                    displayName: 'Assigned To',
                    field: 'ResourceName',
                    visible: false
                },
                {
                    colId: 'DueDateAndTimeId',
                    displayName: 'Due Date and Time',
                    field: 'DateDueTime',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.dateOrTimeFormatter(row, this.dateFormatterService.convertWorkflowTimeToUTC(value), formatterOptions);
                    },
                    cellClass: (params: any) => {
                        const row = params.data;
                        const classes: string[] = [];
                        // must have a DateDue
                        if (!row || !row.DateDue) {
                            return classes;
                        }

                        const date = DateTime.fromFormat(row.DateDue, 'yyyy-MM-dd').toJSDate();
                        if (isToday(date)) {
                            classes.push('due-state');
                        }
                        if (row.IsEndState === false && isDateOverdue(date)) {
                            classes.push('overdue-state');
                        }
                        return classes;
                    }
                },
                {
                    colId: 'DueDateId',
                    displayName: 'Due Date',
                    field: 'DateDue',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.dateFormatter(row, this.dateFormatterService.formatDateOnly(value), formatterOptions);
                    },
                    cellClass: (params: any) => {
                        const row = params.data;
                        const classes: string[] = [];
                        // must have a DateDue
                        if (!row || !row.DateDue) {
                            return classes;
                        }

                        const date = DateTime.fromFormat(row.DateDue, 'yyyy-MM-dd').toJSDate();
                        if (isToday(date)) {
                            classes.push('due-state');
                        }
                        if (row.IsEndState === false && isDateOverdue(date)) {
                            classes.push('overdue-state');
                        }
                        return classes;
                    }
                },
                {
                    displayName: 'Deviation',
                    field: 'DueDeviation',
                    formatter: this.cellFormatterService.deviationFormatter,
                    cellClass: (params: any) => {
                        const row = params.data;
                        const classes: string[] = [];
                        if (!row) {
                            return classes;
                        }
                        // technically, despite having the UTC marker time zone they are immediately local when converted to a JS date.
                        const localDueDate = DateTime.fromJSDate(row.DateDue, {zone: "UTC"}).toJSDate();
                        const isAllDay = isDateMidnight(localDueDate);
                        if (isAllDay === true && row.DateComplete !== '') {
                            const localCompleteDate = DateTime.fromJSDate(row.DateDue, {zone: "UTC"}).toJSDate();
                            const dateDiff = (localCompleteDate.getTime() - localDueDate.getTime())
                                / 1000 / 60 / 60 / 24;
                            if (dateDiff < 1) {
                                return classes;
                            }
                        }

                        if (parseInt(row.DueDeviation, 10) > 0) {
                            classes.push('positive-deviation');
                        }
                        return classes;
                    },
                    visible: false
                },
                {
                    displayName: 'Allowance',
                    field: 'Deviation',
                    visible: false
                },
                {
                    colId: 'CompleteDateAndTimeId',
                    displayName: 'Complete Date And Time',
                    field: 'DateCompleteTime',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.dateOrTimeFormatter(row, this.dateFormatterService.convertWorkflowTimeToUTC(value), formatterOptions);
                    }
                },
                {
                    colId: 'CompleteDateId',
                    displayName: 'Complete Date',
                    field: 'DateComplete',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.dateFormatter(row, this.dateFormatterService.formatDateOnly(value), formatterOptions);
                    }
                },
                {
                    colId: 'ReviewedDateAndTimeId',
                    displayName: 'Reviewed Date And Time',
                    field: 'DateReviewedTime',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.dateOrTimeFormatter(row, this.dateFormatterService.convertWorkflowTimeToUTC(value), formatterOptions);
                    },
                    visible: false
                },
                {
                    colId: 'ReviewedDateId',
                    displayName: 'Reviewed Date',
                    field: 'DateReviewed',
                    formatter: (row: any, value: any, formatterOptions: FormatterOptions) => {
                        return this.cellFormatterService.dateFormatter(row, this.dateFormatterService.formatDateOnly(value), formatterOptions);
                    },
                    visible: false
                },
                {
                    displayName: 'Inputs',
                    field: 'TaskInputs',
                    visible: false
                },
                {
                    displayName: 'Outputs',
                    field: 'TaskOutputSets',
                    visible: false
                },
                {
                    displayName: 'Note Count',
                    field: 'Notes',
                    visible: false
                },
            ]
        };
    }
}
