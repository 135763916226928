import { Genotype } from '../types/models/genotype.interface';
import { notEmpty } from './not-empty';

/**
 * Returns Genotype names from Genotypes
 */
export function getGenotypesString(items: Genotype[]): string {
    if (notEmpty(items)) {
        let values = items.map((genotype: Genotype) => {
            const assay = genotype.cv_GenotypeAssay?.GenotypeAssay ?? '';
            const symbol = genotype.cv_GenotypeSymbol?.GenotypeSymbol ?? '';

            return (assay + ' ' + symbol).trim();
        });
        values.sort();
        return values.filter((x: string) => x !== '').join(', ');
    }
    return '';
}
