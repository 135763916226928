import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobVocabService } from '../../job-vocab.service';

@Component({
    selector: 'standard-phrase-chooser',
    templateUrl: './standard-phrase-chooser.component.html',
    styleUrls: ['./standard-phrase-chooser.component.scss'],
})
export class StandardPhraseChooserComponent implements OnInit {
    @Input() selectedStandardPhrases: any[];
    @Input() isCRL = false;
    @Output() onSelect = new EventEmitter<{}>();
    @Output() close = new EventEmitter<{}>();

    // vocabularies
    standardPhrases: any[];
    observationsInWorkflow: any[];
    observationsNotInWorkflow: any[];
    categoriesWithPhrases: any[];

    constructor(
        private jobVocabService: JobVocabService
    ) { }

    // lifecycle
    ngOnInit() {
        this.standardPhrases = [];
        this.categoriesWithPhrases = [];
        if (this.isCRL) {
            this.jobVocabService.getStandardPhrasesWithCategory().then((phrases) => {
                this.standardPhrases = this.filterActiveVocabItems(phrases);
                this.assignPreselected();
                this.standardPhrases.forEach((phrase: any) => {
                    const categoryName = phrase.cv_StandardPhraseCategory.StandardPhraseCategory;
                    const indexFound = this.categoriesWithPhrases.findIndex((obj) => categoryName === obj.categoryName);
                    if (indexFound < 0) {
                        this.categoriesWithPhrases.push({categoryName, phrases: [phrase]});
                    } else {
                        this.categoriesWithPhrases[indexFound].phrases.push(phrase);
                    }
                });
            });
        } else {
            this.getCVs().then(() => {
                this.standardPhrases = this.filterActiveVocabItems(this.standardPhrases);
                this.assignPreselected();
            });
        }
    }

    private getCVs(): Promise<any> {
        this.jobVocabService.standardPhrases$.subscribe((standardPhrases) => {
            this.standardPhrases = standardPhrases;
        });

        return Promise.resolve();
    }

    /*
    * Set IsSelected flag for all selected Phrases
    */
    assignPreselected() {
        let selectedKeys = this.selectedStandardPhrases.map( (item: any) => {
            return item.C_StandardPhrase_key;
        });
        for (let standardPhraseCV of this.standardPhrases) {
            standardPhraseCV.IsSelected = selectedKeys.indexOf(
                standardPhraseCV.C_StandardPhrase_key
            ) > -1;
        }
    }

    filterActiveVocabItems(items: any[]) {
        if (!items) {
            return [];
        }

        let selectedKeys = this.selectedStandardPhrases.map((item: any) => {
            return item.C_StandardPhrase_key;
        });

        return items.filter((item: any) => {
            return item.IsActive || selectedKeys.includes(item.C_StandardPhrase_key);
        });
    }

    closeClicked() {
        this.close.emit();
    }

    okClicked(event: Event) {
        event.preventDefault();
        let selectedStandardPhrases = this.standardPhrases.filter( (item: any) => {
            return item.IsSelected;
        });
        this.onSelect.emit(selectedStandardPhrases);
        this.close.emit();
    }

}
