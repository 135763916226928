import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { findElementByQuery } from '../common/util/find-element';

const MESSAGE_PANEL_ID = 'messagePanel';

/*
* Service to communicate with messages panel
*/
@Injectable()
export class MessagePanelService {
    
    private openMessageCenterSource = new Subject<any>();
    openMessageCenter$ = this.openMessageCenterSource.asObservable();

    private openNewMessageSource = new Subject<any>();
    openNewMessage$ = this.openNewMessageSource.asObservable();

    openNewMessage(defaultValues: any) {
        this.showMessagePanel();
        this.openNewMessageSource.next(defaultValues);
    }

    /*
     * Calls bootstrap collapse() plugin to show message panel
     */
    showMessagePanel() {
        findElementByQuery('#' + MESSAGE_PANEL_ID).then((element) => {
            ($(element) as any).collapse('show');
            this.signalMessagePanelOpen();
        });
    }

    /*
     * Calls bootstrap collapse() plugin to hide message panel
     */
    hideMessagePanel() {
        findElementByQuery('#' + MESSAGE_PANEL_ID).then((element) => {
            ($(element) as any).collapse('hide');
        });
    }

    signalMessagePanelOpen() {
        this.openMessageCenterSource.next();
    }
}
