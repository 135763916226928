import {
    EventEmitter,
    Component,
    Input,
    Output,
    OnInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export enum HousingOption {
    Move,
    Skip,
    Cancel
}
@Component({
    selector: 'confirm-housing-modal-component',
    templateUrl: './confirm-housing-modal.component.html'
})
export class ConfirmHousingModalComponent implements OnInit {
    @Input() housedAnimals: any[];
    @Output() exit: EventEmitter<HousingOption> = new EventEmitter<HousingOption>(); 

    confirmMessage: string = '';
    animalString: string = '';
    buttonText: string;

    constructor(
        private activeModal: NgbActiveModal
    ) {
       // Nothing to do
    }

    ngOnInit() {
        let animalCount = this.housedAnimals.length;
        if (animalCount > 1) {
            this.buttonText = 'Animals';
            this.confirmMessage = animalCount +
                ' of these animals are already in housing.';
            this.animalString = 'Animals: ';
            for (let animal of this.housedAnimals) {
                this.animalString += animal.AnimalName;
                if (this.housedAnimals.indexOf(animal) + 1 < animalCount) {
                    this.animalString += ', ';
                }
            }
        } else {
            this.confirmMessage = 'This animal is already in housing.';
            this.animalString = 'Animal: ' + this.housedAnimals[0].AnimalName;
            this.buttonText = 'Animal';
        }
        this.confirmMessage += ' How would you like to proceed?';
    }

    // Handle events
    moveAnimals() {
        this.exit.emit(HousingOption.Move);
        this.activeModal.close(HousingOption.Move);
    }

    skipAnimals() {
        this.exit.emit(HousingOption.Skip);
        this.activeModal.close(HousingOption.Skip);
    }

    cancel() {
        this.exit.emit(HousingOption.Cancel);
        this.activeModal.dismiss(HousingOption.Cancel);
    }
}
