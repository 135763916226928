import { BulkEditCommService } from './../../common/facet/bulk-edit-comm.service';
import { DataManagerService } from '../../services/data-manager.service';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AnimalService } from '../services/animal.service';
import { EnumerationService } from '../../enumerations/enumeration.service';
import { LoggingService } from '../../services/logging.service';
import { AnimalLogic } from '../animal-logic.shared';
import { AnimalBulkTemplatesComponent } from './animal-bulk-templates.component';
import { EntityStateSymbol, Validator } from 'breeze-client';
import { SettingService } from '../../settings/setting.service';
import { SaveChangesService, IValidatable } from '../../services/save-changes.service';
import { FacetView, IFacet } from '../../common/facet';
import { TranslationService } from '../../services/translation.service';
import { IButtonBulkEdit } from '@common/bulk-edit-header.component';
import { Animal, AnimalComment, Entity } from '@common/types';
import { NgModel } from '@angular/forms';
import { AnimalCommentsBulkActions } from './animal-comments-bulk-actions.enum';

@Component({
    selector: 'animal-bulk-edit',
    templateUrl: './animal-bulk-edit.component.html',
    providers: [
        BulkEditCommService
    ]
})
export class AnimalBulkEditComponent implements OnInit, OnDestroy, IValidatable {
    @ViewChild('bulkTemplates') bulkTemplates: AnimalBulkTemplatesComponent;
    @ViewChild('dateControl') dateControl: NgModel;

    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() animals: any[];
    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() inactiveFields: string[];
    @Input() requiredFields: string[];
    @Output() exit: EventEmitter<any> = new EventEmitter<any[]>();

    // shared logic for animal facet
    animalLogic: AnimalLogic;
    bulkFillAnimalCommentFieldConfig: { field: string, action: string, value: string };
    animalCommentsBulkActionsButtons: IButtonBulkEdit[];

    readonly COMPONENT_LOG_TAG = 'animal-bulk-edit';

    constructor(
        private animalService: AnimalService,
        private enumerationService: EnumerationService,
        private loggingService: LoggingService,
        private modalService: NgbModal,
        private settingService: SettingService,
        private dataManager: DataManagerService,
        private saveChangesService: SaveChangesService,
        private translationService: TranslationService
    ) {
        this.animalLogic = new AnimalLogic(
            animalService,
            enumerationService,
        );
    }

    // lifecycle
    ngOnInit() {
        this.saveChangesService.registerValidator(this);
        this.initialize();
        this.animalCommentsBulkActionsButtons = [
            { text: AnimalCommentsBulkActions.CreateNew, fieldName: 'AnimalComment.Comment' },
            { text: AnimalCommentsBulkActions.UpdateExisting, fieldName: 'AnimalComment.Comment', isDisabled: this.isAnimalCommentsEmpty() },
        ];
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);
    }

    updateAnimals([field, action]: [string, string]) {
        this.bulkFillAnimalCommentFieldConfig = { field, action, value: this.bulkTemplates.bulkFillAnimalComment };
        setTimeout(() => this.onAnimalCommentsControlsStatusUpdate(), 0);
    }

    initialize() {
        // Copy the input so we don't touch the grid data
        this.animals = this.animals.slice();

        this.animals.forEach((animal) => {
            animal.Material.entityType.getProperty('C_Line_key').validators.push(Validator.required());
        });
    }

    openCageCardModal(cagecardmodal: TemplateRef<any>) {
        this.modalService.open(cagecardmodal);
    }

    isAnimalCommentsEmpty(callOnSave = false): boolean {
        if (callOnSave) {
            return this.animals.every((animal: Animal) => !animal.AnimalComment.length);
        } else {
            const comments = this.animals.map((animal: Animal) => {
                if (animal.AnimalComment.length) {
                    const comment = animal.AnimalComment[animal.AnimalComment.length - 1] as Entity<AnimalComment>;
                    const commentEntityState =  comment.entityAspect.entityState as EntityStateSymbol & { name: string };
                    return commentEntityState.name;
                }
            }).filter((item: undefined | string) => item);
            const isAllCommentsNew = comments.every((commentEntityState: string) => commentEntityState === 'Added');
            return this.animals.every((animal: Animal) => !animal.AnimalComment.length) || isAllCommentsNew;
        }
    }

    onAnimalCommentsControlsStatusUpdate(): void {
        this.animalCommentsBulkActionsButtons = this.animalCommentsBulkActionsButtons.map((button: IButtonBulkEdit) => {
            if (button.text === AnimalCommentsBulkActions.UpdateExisting) {
                return {
                    ...button, isDisabled: this.isAnimalCommentsEmpty()
                };
            } else {
                return button;
            }
        });
    }

    async validate(): Promise<string> {
        let errMsg = this.bulkTemplates?.validate?.() ?? '';

        // Validate fields required by facet settings for each animal
        for (let animal of this.animals) {
            if (!animal.Material.C_Line_key) {
                errMsg = 'An Animal requires a ' + this.translationService.translate('Line') + '.';
            }

            if (!errMsg) {
                errMsg = await this.settingService.validateRequiredFields(this.requiredFields, animal, 'animal');
            }

            if (errMsg) {
                break;
            }
        }

        if (!errMsg && this.bulkTemplates.isGLP && this.bulkTemplates.hasMicrochipIdErrors()) {
            errMsg = 'The Microchip ID is already in use.';
        }

        if (!errMsg && this.bulkTemplates.isGLP && this.bulkTemplates.hasAlternatePhysicalIdErrors()) {
            errMsg = 'The Alternate Physical ID is already in use.';
        }

        return errMsg;
    }

    // TODO: look into this some more
    exitClicked() {
        if (this.dataManager.hasChanges()) {
            for (let animal of this.animals) {
                this.animalService.cancelAnimal(animal);
            }
            // remove added, but un-saved animals
            this.animals = this.animals.filter((animal) => {
                return animal.C_Material_key > 0;
            });
            this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
        }

        this.exit.emit(this.animals);
    }

    saveClicked() {
        this.animalCommentsBulkActionsButtons = [
            { text: AnimalCommentsBulkActions.CreateNew, fieldName: 'AnimalComment.Comment' },
            { text: AnimalCommentsBulkActions.UpdateExisting, fieldName: 'AnimalComment.Comment', isDisabled: this.isAnimalCommentsEmpty(true) },
        ];
    }
}
