import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import { AnimalService } from '../../animals/services/animal.service';
import { CopyBufferService } from '../../common/services/copy-buffer.service';
import { EventService } from '../../events/event.service';
import { JobLogicService } from '../job-logic.service';
import { JobService } from '../job.service';

import {
    notEmpty
} from '../../common/util';

import { TableSort } from '../../common/models';
import { ClinicalService } from '../../clinical/clinical.service';

@Component({
    selector: 'job-animal-table',
    templateUrl: './job-animal-table.component.html'
})
export class JobAnimalTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() readonly: boolean;
    @Input() job: any;
    @Input() animalMaterials: any[];
    @Input() tableSort: TableSort;

    // info toggles
    showAllClinical: boolean = false;


    constructor(
        private animalService: AnimalService,
        private copyBufferService: CopyBufferService,
        private eventService: EventService,
        private jobLogicService: JobLogicService,
        private jobService: JobService,
        private clinicalService: ClinicalService,
    ) {
        // do nothing
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        if (notEmpty(this.animalMaterials)) {
            // reset animal selections
            for (let animal of this.animalMaterials) {
                animal.isSelected = false;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.job) {
            if (!changes.job.firstChange) {
                this.initialize();
            }
        }
        if (changes.animalMaterials) {
            this.clinicalService.mapDetailsClinicalHistory(this.animalMaterials);
        }
    }

    initialize() {
        if (!this.animalMaterials) {
            return;
        }
        this.initializeAnimals(this.animalMaterials);
    }

    private initializeAnimals(animals: any[]) {
        //
    }

    copyAnimals() {
        let animalsToCopy = this.animalMaterials.filter((animalMaterial) => {
            return animalMaterial.isSelected && animalMaterial.Material;
        }).map((animalMaterial) => {
            return animalMaterial.Material.Animal;
        });
        this.copyBufferService.copy(animalsToCopy);
    }

    selectAllAnimals($event: any) {
        let checkbox = $event.target;
        for (let animal of this.animalMaterials) {
            animal.isSelected = checkbox.checked;
        }
    }


    // Dropping/Pasting In
    onDropMaterialToJob() {
        let animals = this.animalService.draggedAnimals;
        this.addAnimalsToJob(animals);

        this.animalService.draggedAnimals = [];
    }

    pasteAnimalsIntoJob() {
        if (this.copyBufferService.hasAnimals()) {
            let animals = this.copyBufferService.paste();
            this.addAnimalsToJob(animals);
        }
    }

    /**
     * Get confirmation if any new animals are already in jobs, then add.
     * @param animals
     */
    private addAnimalsToJob(animals: any[]) {
        this.jobLogicService.getAnimalsEnrolledOnlyInOtherJobs(animals, this.job)
            .then((animalsInJobs: any[]) => {

                if (notEmpty(animalsInJobs)) {
                    // Prompt for confirmation
                    return this.jobLogicService.showAddAnimalsToMultipleJobsConfirm(animalsInJobs)
                        .then(() => {
                            // Yes, add anyway
                            this.doAddAnimalsToJob(animals);
                        }).catch(() => {
                            // Canceled: do nothing
                        });
                } else {
                    this.doAddAnimalsToJob(animals);
                }

            });
    }

    private doAddAnimalsToJob(animals: any[]) {
        for (let animal of animals) {
            this.addMaterialsFromAnimal(animal);
            animal.isSelected = false;
        }

        this.initializeAnimals(animals);
    }

    private addMaterialsFromAnimal(animal: any) {
        let jobKey = this.job.C_Job_key;

        this.jobService.createJobMaterial({
            C_Job_key: jobKey,
            C_Material_key: animal.C_Material_key
        });
    }


    // Removing
    removeJobMaterial(jobMaterial: any) {
        this.jobService.deleteJobMaterial(jobMaterial);
    }


    // Dragging Out
    dragAnimalsStart() {
        let animalsToCopy = [];
        for (let jobMaterial of this.job.JobMaterial) {
            if (jobMaterial.isSelected && jobMaterial.Material.Animal) {
                animalsToCopy.push(jobMaterial.Material.Animal);
            }
        }
        this.animalService.draggedAnimals = animalsToCopy;
    }

    dragAnimalsStop() {
        setTimeout(() => {
            this.animalService.draggedAnimals = [];
        }, 500);
    }


    // Column Controls
    showHideClinical(jobMaterial: any) {
        jobMaterial.clinicalExpanded = !jobMaterial.clinicalExpanded;
    }

    showHideAllClinical() {
        this.showAllClinical = !this.showAllClinical;

        for (let jobMaterial of this.job.JobMaterial) {
            if (this.showAllClinical && notEmpty(jobMaterial.clinicalHistory)) {
                jobMaterial.clinicalExpanded = true;
            } else {
                jobMaterial.clinicalExpanded = false;
            }
        }
    }

    getStatusHistory(material: any) {
        material.statusExpanded = !material.statusExpanded;

        if (material.statusExpanded === true) {
            this.eventService.getMaterialEvents(material.C_Material_key)
                .then((data: any[]) => {
                    material.statusHistory = data;
                });
        }
    }
}
