import {
    Component,
    Input,
    OnInit,
    OnChanges,
    EventEmitter,
    Output
} from '@angular/core';

import { JobService } from './job.service';
import { FacetLoadingStateService } from '../common/facet';
import { OrderService } from '../orders/order.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { InstitutionService } from '../institution/institution.service';

/**
 * Table for adding institutions to jobs
 */
@Component({
    selector: 'job-institution-table',
    templateUrl: './job-institution-table.component.html'
})
export class JobInstitutionTableComponent implements OnInit, OnChanges {
    @Input() job: any;
    @Input() showSiteContact: boolean = false;
    @Input() isDotmatics: boolean = false;
    @Input() required: boolean;
    @Input() readonly: boolean;

    @Output() institutionChange: EventEmitter<any> = new EventEmitter<any>();

    isCRL: boolean = false;
    isRemoveBillingContact: boolean;

    constructor(
        private jobService: JobService,
        private facetLoadingStateService: FacetLoadingStateService,
        private orderService: OrderService,
        private featureFlagService: FeatureFlagService,
        private institutionService: InstitutionService
    ) {
        //
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: any) {
        // Re-initialize table when job.C_Job_key changes (only happens when Job is copied)
        if (changes.job && changes.job.previousValue && (changes.job.currentValue.C_Job_key !== changes.job.previousValue.C_Job_key)) {
            this.initialize();
        }
    }

    initialize() {       
        this.getAllInstitutionSites();
        this.initIsCRL();
        if (this.isCRL) {
            this.getJobInstitutionBillingContacts();
        }
    }

    initIsCRL() {
        const flag = this.featureFlagService.getFlag("IsCRL");
        this.isCRL = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    addJobInstitution(job: any) {
        if (this.readonly) {
            return;
        }
        let initialValues = {
            C_Job_key: job.C_Job_key,
        };
        this.jobService.createJobInstitution(initialValues);
    }


    removeInstitution(jobInstitution: any) {
        if (this.readonly) {
            return;
        }
        this.jobService.deleteJobInstitution(jobInstitution);
    }

    getAllInstitutionSites(): Promise<any> {
        this.facetLoadingStateService.changeLoadingState(true);
        let promises = [];
        for (let jobInstitution of this.job.JobInstitution) {
            let promise = this.orderService.getInstitutionSites(jobInstitution.C_Institution_key)
                .then((data) => {
                    return jobInstitution.SelectedSites = data;
                });
            let promise2 = this.institutionService.getInstitutionContacts(jobInstitution.C_Institution_key)
            .then((data) => {
                return jobInstitution.InstitutionContacts = data;
            });
            promises.push(promise);
            promises.push(promise2);
        }
        return Promise.all(promises).then(() => {
            this.facetLoadingStateService.changeLoadingState(false);
        });       
    }

    getJobInstitutionBillingContacts() {
        for (let jobInstitution of this.job.JobInstitution) {
            jobInstitution.SelectedContacts = jobInstitution.JobInstitutionBillingContact.map((x: any) => x.ContactPerson);
        }
    }

    getSites(institutionKey: any) {
        this.orderService.getInstitutionSites(institutionKey).then((data) => {
            this.job.JobInstitution.filter((jobInstitution: any) => {
                if (jobInstitution.C_Institution_key === institutionKey) {
                    jobInstitution.SelectedSites = data;
                }
            });
        });
    }

    getContacts(institutionKey: any) {
        if (!institutionKey) {
            return;
        }
        this.institutionService.getInstitutionContacts(institutionKey).then((data) => {
            this.job.JobInstitution.filter((jobInstitution: any) => {
                if (jobInstitution.C_Institution_key === institutionKey) {
                    jobInstitution.InstitutionContacts = data;
                }
            });
        });
    }

    institutionChanged(institutionKey: any, jobInstitution: any) {
        jobInstitution.C_Site_key = null;
        this.getSites(institutionKey);
        this.getContacts(institutionKey);
        jobInstitution.SelectedContacts = [];
        this.removeContactsFromJobInstitution(jobInstitution);

        if (this.isDotmatics) {
            this.institutionChange.emit(institutionKey);
        }
    }

    removeContactsFromJobInstitution(jobInstitution: any): void {
        jobInstitution.C_ScientificContactPerson_key = null;
        jobInstitution.C_AuthorizationContactPerson_key = null;
        jobInstitution.C_BillingContactPerson_key = null;
        for (let billingContact of jobInstitution.JobInstitutionBillingContact) {
            this.removeJobInstitutionBillingContact(billingContact);
        }
    }

    siteChanged(siteKey: any, jobInstitution: any) {
        if (!this.isCRL) {
            jobInstitution.C_ScientificContactPerson_key = null;
            jobInstitution.C_BillingContactPerson_key = null;
            jobInstitution.C_AuthorizationContactPerson_key = null;
        }
    }

    institutionBillingContactChanged(updatedContact: any, jobInstitution: any) {
        let updatedBillingContact = jobInstitution.JobInstitutionBillingContact.find((x: any) => x.ContactPerson.C_ContactPerson_key === updatedContact.C_ContactPerson_key);
        if (updatedBillingContact && this.isRemoveBillingContact) {
            this.removeJobInstitutionBillingContact(updatedBillingContact);
        } else {
            this.addJobInstitutionBillingContact(jobInstitution, updatedContact);
        }
    }

    addJobInstitutionBillingContact(jobInstitution: any, contactPerson: any) {
        if (this.readonly) {
            return;
        }
        let initialValues = {
            C_JobInstitution_key: jobInstitution.C_JobInstitution_key,
            C_ContactPerson_key: contactPerson.C_ContactPerson_key
        };
        this.jobService.createJobInstitutionBillingContact(initialValues);
    }

    removeJobInstitutionBillingContact(jobInstitutionBillingContact: any) {
        if (this.readonly) {
            return;
        }
        this.jobService.deleteJobInstitutionBillingContact(jobInstitutionBillingContact);
    }
}
