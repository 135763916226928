import {
    CellFormatterService,
    TableColumnDef,
    TableOptions
} from '../common/datatable';
import {
    AnimalMarkerCellRendererComponent
} from './animal-marker-cell-renderer.component';
import {
    animalAgeDays,
    animalAgeWeeks,
    animalAgeMonths,
    currentMaterialHousingID,
    priorMaterialHousingID,
    getSafeProp,
    uniqueArrayFromPropertyPath,
} from '../common/util';
import { TranslationService } from '../services/translation.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import {
    addAuditColumnsToOptions,
    addColumnsToOptions,
    pipe,
} from '@common/datatable/utils';

export class AnimalTableOptions {
    options: TableOptions;
    isGLP = false;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService,
        additionalColumns?: TableColumnDef[]
    ) {
        this.initIsGLP();

        this.options = addAuditColumnsToOptions(cellFormatterService)(this.getDefaultOptions());
        if (additionalColumns) {
            this.options = pipe(
                this.getDefaultOptions(),
                addColumnsToOptions(additionalColumns ?? []),
                addAuditColumnsToOptions(cellFormatterService),
            );
        }
    }

    getDefaultOptions(): TableOptions {
        let columns = [
            {
                displayName: 'ID',
                field: 'Material.Identifier',
            },
            {
                displayName: 'Name', field: 'AnimalName',
                sortField: 'AnimalNameSortable',
            },
            {
                displayName: 'Marker Type', field: 'cv_PhysicalMarkerType.PhysicalMarkerType',
                visible: false,
            },
            {
                displayName: 'Marker', field: 'PhysicalMarker',
                visible: false,
                rendererComponent: <new () => AnimalMarkerCellRendererComponent>
                    AnimalMarkerCellRendererComponent,
            },
            {
                displayName: 'Sex',
                field: 'cv_Sex.Sex',
            },
            {
                displayName: 'Status',
                field: 'cv_AnimalStatus.AnimalStatus',
            },
            {
                displayName: 'Mating Status', field: 'cv_AnimalMatingStatus.AnimalMatingStatus',
                visible: false,
            },
            {
                displayName: 'Breeding Status', field: 'cv_BreedingStatus.BreedingStatus',
                visible: false,
            },
            {
                displayName: 'Owner', field: 'Owner',
                visible: false,
                formatter: this.cellFormatterService.userNameFormatter,
            },
            {
                displayName: 'Use', field: 'cv_AnimalUse.AnimalUse',
                visible: false,
            },
            {
                displayName: 'IACUC Protocol', field: 'cv_IACUCProtocol.IACUCProtocol',
                visible: false,
            },
            {
                displayName: this.translationService.translate('Line'), field: 'Material.Line.LineName',
                visible: false,
            },
            {
                displayName: this.translationService.translate('Line') + ' (Short)', field: 'Material.Line.StockNumber',
                visible: false,
            },
            {
                displayName: this.translationService.translate('Line') + ' (Stock)', field: 'Material.Line.StockID',
                visible: false,
            },
            {
                displayName: 'Species', field: 'Material.cv_Taxon.CommonName',
                visible: false,
            },
            {
                displayName: 'Origin', field: 'Material.cv_MaterialOrigin.MaterialOrigin',
                visible: false,
            },
            {
                displayName: 'Genotype', field: 'Genotype',
                sortField: 'Material.MaterialTopGenotype.Genotype',
                visible: false,
                formatter: (row: any, value: any) => {
                    let values = row.Genotype.map((genotype: any) => {
                        let symbol = genotype.cv_GenotypeSymbol ?
                            genotype.cv_GenotypeSymbol.GenotypeSymbol : '';
                        let assay = genotype.cv_GenotypeAssay ?
                            genotype.cv_GenotypeAssay.GenotypeAssay : '';

                        return assay + ' ' + symbol;
                    });
                    return values.filter((x: any) => x !== ' ').join(', ');
                },
            },
            {
                displayName: 'Protocol',
                field: 'Material.TaskMaterial.TaskInstance.ProtocolTask.Protocol',
                sortable: false,
                visible: false,
                formatter: (row: any, value: any) => {
                    let uniqueValues = uniqueArrayFromPropertyPath(
                        row,
                        'Material.TaskMaterial.TaskInstance.ProtocolTask.Protocol.ProtocolName'
                    );
                    return uniqueValues.join(', ');
                },
            },
            {
                displayName: 'Plate', field: 'Material.PlateMaterial',
                sortable: false,
                visible: false,
                formatter: (row: any, value: any) => {
                    let values = row.Material.PlateMaterial.map((plateMaterial: any) => {
                        return plateMaterial.Plate.PlateID;
                    });
                    return values.join(', ');
                },
            },
            {
                displayName: 'Generation', field: 'cv_Generation.Generation',
                visible: false,
            },
            {
                displayName: 'Diet', field: 'cv_Diet.Diet',
                visible: false,
            },
            {
                displayName: 'Microchip ID', field: 'Material.MicrochipIdentifier',
                visible: false,
            },
            {
                displayName: 'External ID', field: 'Material.ExternalIdentifier',
                visible: false,
            },
            {
                displayName: 'Comments', field: 'AnimalCommentsSortable',
                visible: false,
                formatter: (row: any, value: any) => {
                    if (!row) {
                        return '';
                    }

                    const mostRecentComment = row.AnimalComment[row.AnimalComment.length - 1];
                    if (!mostRecentComment) {
                        return '';
                    }

                    const statusText = mostRecentComment.cv_AnimalCommentStatus.AnimalCommentStatus;

                    return `${statusText}: ${mostRecentComment.Comment}`;
                },
                cellClass: (params: any) => {
                    const classes: string[] = [];

                    const row = params.data;
                    if (!row) {
                        return classes;
                    }

                    const mostRecentComment = row.AnimalComment[row.AnimalComment.length - 1];
                    if (!mostRecentComment) {
                        return classes;
                    }

                    const status = mostRecentComment.cv_AnimalCommentStatus;
                    if (status) {
                        if (status.IsEndState) {
                            classes.push('italicized');
                        } else if (status.IsPermanent) {
                            classes.push('bolded');
                        }
                    }

                    return classes;
                },
            },
            {
                displayName: 'Parent Mating ID', field: 'Birth.Mating.MatingID',
                visible: false,
            },
            {
                displayName: 'Sire(s)', field: 'Sires',
                visible: false,
                sortable: false,
                formatter: (row: any, value: any) => {
                    let parents = uniqueArrayFromPropertyPath(
                        row,
                        'Birth.Mating.MaterialPool.MaterialPoolMaterial.Material.Animal');
                    parents = parents.filter((animal) => {
                        return getSafeProp(animal, 'cv_Sex.Sex') === 'Male';
                    });

                    let birthMaterials = uniqueArrayFromPropertyPath(
                        row,
                        'Birth.BirthMaterial'
                    );

                    parents = parents.filter((animal) =>
                        birthMaterials.find((bm) =>
                            bm.C_Material_key === animal.C_Material_key));

                    let parentNames = parents.map((animal) => {
                        return animal.AnimalName;
                    });
                    return parentNames.join(', ');
                },
            },
            {
                displayName: 'Dam(s)', field: 'Dams',
                visible: false,
                sortable: false,
                formatter: (row: any, value: any) => {
                    let parents = uniqueArrayFromPropertyPath(
                        row,
                        'Birth.Mating.MaterialPool.MaterialPoolMaterial.Material.Animal'
                    );
                    parents = parents.filter((animal) => {
                        return getSafeProp(animal, 'cv_Sex.Sex') === 'Female';
                    });

                    let birthMaterials = uniqueArrayFromPropertyPath(
                        row,
                        'Birth.BirthMaterial'
                    );

                    parents = parents.filter((animal) =>
                        birthMaterials.find((bm) =>
                            bm.C_Material_key === animal.C_Material_key));

                    let parentNames = parents.map((animal) => {
                        return animal.AnimalName;
                    });
                    return parentNames.join(', ');
                },
            },
            {
                displayName: 'Birth ID', field: 'Birth.BirthID',
                visible: false,
            },
            {
                displayName: 'Birth Date', field: 'DateBorn',
                visible: false,
                formatter: this.cellFormatterService.dateFormatter,
            },
            {
                displayName: 'Death/Exit Date', field: 'DateExit',
                visible: false,
                formatter: this.cellFormatterService.dateFormatter,
            },
            {
                displayName: 'Death/Exit Reason', field: 'cv_ExitReason.ExitReason',
                visible: false,
            },
            {
                displayName: 'Arrival Date', field: 'DateOrigin',
                visible: false,
                formatter: this.cellFormatterService.dateFormatter,
            },
            {
                displayName: 'Wean Date', field: 'Birth.DateWean',
                visible: false,
                formatter: this.cellFormatterService.dateFormatter,
            },
            {
                displayName: 'Shipment', field: 'ShipmentID',
                visible: false,
            },
            {
                displayName: 'Vendor', field: 'VendorID',
                visible: false,
            },
            {
                displayName: 'Order ID', field: 'Order.OrderID',
                visible: false,
            },
            {
                displayName: 'Age (days)', field: 'AgeInDays',
                sortable: false,
                visible: false,
                formatter: (row: any, value: any) => {
                    return animalAgeDays(row.DateBorn, row.DateExit);
                },
                headerClass: 'text-nowrap',
                cellClass: 'num-cell',
            },
            {
                displayName: 'Age (weeks)', field: 'AgeInWeeks',
                sortable: false,
                visible: false,
                formatter: (row: any, value: any) => {
                    return animalAgeWeeks(row.DateBorn, row.DateExit);
                },
                headerClass: 'text-nowrap',
                cellClass: 'num-cell',
            },
            {
                displayName: 'Age (months)', field: 'AgeInMonths',
                sortable: false,
                visible: false,
                formatter: (row: any, value: any) => {
                    return animalAgeMonths(row.DateBorn, row.DateExit);
                },
                headerClass: 'text-nowrap',
                cellClass: 'num-cell',
            },
            {
                displayName: 'Housing ID', field: 'Material.MaterialPoolMaterial',
                sortField: 'Material.MaterialTopMaterialPool.HousingID',
                visible: false,
                formatter: (row: any, value: any) => {
                    return currentMaterialHousingID(row.Material.MaterialPoolMaterial);
                },
            },
            {
                displayName: 'Prior Housing ID', field: 'PriorHousingID',
                sortField: 'Material.MaterialPriorMaterialPool.HousingID',
                visible: false,
                formatter: (row: any, value: any) => {
                    return priorMaterialHousingID(row.Material.MaterialPoolMaterial);
                },
            },
            {
                displayName: 'Location', field: 'Material.CurrentLocationPath',
                visible: false,
            },
            {
                displayName: this.translationService.translate('Job'),
                field: 'Material.JobMaterial',
                sortField: 'JobIDsSortable',
                visible: false,
                formatter: (row: any, value: any) => {
                    if (value.length > 0) {
                        let jobIds;
                        if (this.isGLP) {
                            jobIds = value.filter((jm: any) => !jm.DateOut);
                        } else {
                            jobIds = value;
                        }
                        jobIds = jobIds.map((jm: any) => jm.Job.JobID);
                        if (jobIds.length > 1) {
                            return jobIds.sort((a: any, b: any) => a.DateCreated - b.DateCreated).join(',');
                        }
                        return jobIds.join(',');
                    } else {
                        return '';
                    }

                },
            },
            {
                displayName: this.translationService.translate('Study'),
                field: 'Material.JobMaterial.Job.Study.StudyName',
                sortable: false,
                visible: false,
                formatter: (row: any, value: any) => {
                    let uniqueValues = uniqueArrayFromPropertyPath(
                        row,
                        'Material.JobMaterial.Job.Study.StudyName'
                    );
                    return uniqueValues.join(', ');
                },
            },
            {
                displayName: 'Cohort',
                field: 'CohortsSortable',
                visible: false,
            },
            {
                displayName: 'Notes',
                field: 'Material.Note',
                visible: false,
                formatter: this.cellFormatterService.noteFormatter,
                sortable: false,
            },
        ];
        return {
            csvFileName: 'animals.csv',
            enableDetailColumn: true,
            enableDraggable: true,
            enableSelectable: true,
            columns
        };
    }

    initIsGLP() {
        this.isGLP = this.featureFlagService.isFlagOn('IsGLP');
    }
}
