
import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { BulkAssignCohortsModalComponent } from './bulk-assign-cohort-modal.component';
import { Animal, Cohort, Entity, TaskInstance } from '@common/types';

export interface BulkAssignResult {
    selectedAnimals: Entity<Animal>[];
    selectedCohorts: Entity<Cohort>[];
    selectedTasks: Entity<TaskInstance>[];
}
@Injectable()
export class BulkAssignCohortsModalService {
    readonly EMPTY_RESULT: null = null;
    modalOptions: NgbModalOptions;

    constructor(
        private modalService: NgbModal
    ) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        this.modalOptions = {
            size: 'md'
        };
    }


    async openComponent(job?: any, showCohorts: boolean = true): Promise<BulkAssignResult | null> {
        // Open modal
        const modalRef = this.modalService.open(
            BulkAssignCohortsModalComponent, this.modalOptions
        );

        let component = modalRef.componentInstance as BulkAssignCohortsModalComponent;
        component.job = job;
        component.showCohorts = showCohorts;
        try {
            const result = await modalRef.result;
            if (result) {
                return result;
            } else {
                return this.EMPTY_RESULT;
            }
        } catch {
            return this.EMPTY_RESULT;
        }
    }
}
