import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VocabularyService } from './../../../vocabularies/vocabulary.service';
import { CreateSampleGroupData } from './create-sample-groups-models';
import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { LocationService } from '../../../locations/location.service';

@Component({
    selector: 'create-sample-groups-modal',
    templateUrl: './create-sample-groups-modal.component.html'
})
export class CreateSampleGroupsModalComponent implements OnInit {
    @Input() create: CreateSampleGroupData;

    defaultPreservationMethodKey: string;
    defaultSampleStatusKey: string;
    defaultSampleTypeKey: string;
    defaultContainerTypeKey: string;
    defaultSampleSubtypeKey: string;
    defaultSampleProcessingMethodKey: string;
    defaultSampleAnalysisMethodKey: string;

    // CVs
    preservationMethods: any[];
    containerTypes: any[];
    sampleStatuses: any[];
    sampleTypes: any[];
    timeUnits: any[];
    sampleSubtypes: any[];
    sampleAnalysisMethods: any[];
    sampleProcessingMethods: any[];

    // Bulk update placeholders
    bulkNumSamples: number;
    bulkPreservationMethodKey: string;
    bulkContainerTypeKey: string;
    bulkSampleStatusKey: string;
    bulkSampleTypeKey: string;
    bulkDateHarvest: Date;
    bulkDateExpiration: Date;
    bulkTimePoint: number;
    bulkTimeUnitKey: string;
    bulkSampleSubtypeKey: string;
    bulkSampleProcessingMethodKey: string;
    bulkSendTo: string;
    bulkSampleAnalysisMethodKey: string;

    constructor(
        public activeModal: NgbActiveModal,
        private vocabularyService: VocabularyService,
        private locationService: LocationService
    ) {}

    ngOnInit() {
        this.getCVs().then(() => {
            this.initCreateData();
        });
    }

    initCreateData() {
        // Add a default row to start
        this.createAddRow();
    }

    getCVs(): Promise<any> {
        const preferLocal = false;
        return Promise.all([
            this.vocabularyService.getCV(
                'cv_PreservationMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.preservationMethods = data;
            }),
            this.locationService.getContainerTypes('Sample').then((data) => {
                this.containerTypes = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleStatuses', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleStatuses = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleTypes', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleTypes = data;
            }),
            this.vocabularyService.getCV(
                'cv_TimeUnits', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.timeUnits = data;
                }),
            this.vocabularyService.getCV(
                'cv_SampleSubtypes', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleSubtypes = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleProcessingMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleProcessingMethods = data;
            }),
            this.vocabularyService.getCV(
                'cv_SampleAnalysisMethods', 'SortOrder', preferLocal
            ).then((data: any[]) => {
                this.sampleAnalysisMethods = data;
            }),
            this.vocabularyService.getCVDefault(
                'cv_PreservationMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultPreservationMethodKey = value.C_PreservationMethod_key;
                }
            }),
            this.vocabularyService.getCVContainerTypeDefault(
                'Sample'
            ).then((value) => {
                if (value != null) {
                    this.defaultContainerTypeKey = value.C_ContainerType_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleStatuses', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleStatusKey = value.C_SampleStatus_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleTypes', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleTypeKey = value.C_SampleType_key;
                }
                }),
            this.vocabularyService.getCVDefault(
                'cv_SampleSubtypes', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleSubtypeKey = value.C_SampleSubtype_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleProcessingMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleProcessingMethodKey = value.C_SampleProcessingMethod_key;
                }
            }),
            this.vocabularyService.getCVDefault(
                'cv_SampleAnalysisMethods', preferLocal
            ).then((value) => {
                if (value != null) {
                    this.defaultSampleAnalysisMethodKey = value.C_SampleAnalysisMethod_key;
                }
            }),
            
        ]);
    }

    /**
     * Add a new default SampleGroup row to the create modal
     */
    createAddRow() {
        this.create.rows.push({
            NumSamples: 1,
            C_SampleType_key: this.defaultSampleTypeKey,
            C_SampleStatus_key: this.defaultSampleStatusKey,
            C_PreservationMethod_key: this.defaultPreservationMethodKey,
            C_ContainerType_key: this.defaultContainerTypeKey,
            C_SampleSubtype_key: this.defaultSampleSubtypeKey,
            C_SampleProcessingMethod_key: this.defaultSampleProcessingMethodKey,
            SendTo: '',
            C_SampleAnalysisMethod_key: this.defaultSampleAnalysisMethodKey
        });
    }

    /**
     * Remove a SampleGroup row from the create modal
     * 
     * @param index index of the reow to remove
     */
    createRemoveRow(index: number) {
        this.create.rows.splice(index, 1);
    }


    updateBulkNumSamples() {
        this.updateBulkValue('NumSamples', this.bulkNumSamples);
    }
    updateBulkPreservationMethodKey() {
        this.updateBulkValue('C_PreservationMethod_key', this.bulkPreservationMethodKey);
    }
    updateBulkContainerTypeKey() {
        this.updateBulkValue('C_ContainerType_key', this.bulkContainerTypeKey);
    }
    updateBulkSampleStatusKey() {
        this.updateBulkValue('C_SampleStatus_key', this.bulkSampleStatusKey);
    }
    updateBulkSampleTypeKey() {
        this.updateBulkValue('C_SampleType_key', this.bulkSampleTypeKey);
    }
    updateBulkDateHarvest() {
        this.updateBulkValue('DateHarvest', this.bulkDateHarvest);
    }
    updateBulkDateExpiration() {
        this.updateBulkValue('DateExpiration', this.bulkDateExpiration);
    }
    updateBulkTimePoint() {
        this.updateBulkValue('TimePoint', this.bulkTimePoint);
        this.updateBulkValue('C_TimeUnit_key', this.bulkTimeUnitKey);
    }
    updateBulkSampleSubtypeKey() {
        this.updateBulkValue('C_SampleSubtype_key', this.bulkSampleSubtypeKey);
    }
    updateBulkSampleProcessingMethodKey() {
        this.updateBulkValue('C_SampleProcessingMethod_key', this.bulkSampleProcessingMethodKey);
    }
    updateBulkSampleAnalysisMethodKey() {
        this.updateBulkValue('C_SampleAnalysisMethod_key', this.bulkSampleAnalysisMethodKey);
    }
    updateBulkSendTo() {
        this.updateBulkValue('SendTo', this.bulkSendTo);
    }

    // Bulk update handlers
    updateBulkValue(key: string, value: any) {
        // Update the rows in the Create Modal
        if (this.create && this.create.rows) {
            for (const row of this.create.rows) {
                row[key] = value;
            }
        }
    }   

    // <select> formatters
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }
    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }
    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
    preservationMethodKeyFormatter = (value: any) => {
        return value.C_PreservationMethod_key;
    }
    preservationMethodFormatter = (value: any) => {
        return value.PreservationMethod;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    timeUnitKeyFormatter = (value: any) => {
        return value.C_TimeUnit_key;
    }
    timeUnitFormatter = (value: any) => {
        return value.TimeUnit;
    }
    sampleSubtypeKeyFormatter = (value: any) => {
        return value.C_SampleSubtype_key;
    }
    sampleSubtypeFormatter = (value: any) => {
        return value.SampleSubtype;
    }
    sampleProcessingMethodKeyFormatter = (value: any) => {
        return value.C_SampleProcessingMethod_key;
    }
    sampleProcessingMethodFormatter = (value: any) => {
        return value.SampleProcessingMethod;
    }
    sampleAnalysisMethodKeyFormatter = (value: any) => {
        return value.C_SampleAnalysisMethod_key;
    }
    sampleAnalysisMethodFormatter = (value: any) => {
        return value.SampleAnalysisMethod;
    }
}
