const ELLIPSIS = '\u2026';

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { JobService } from './job.service';
import { SearchService } from '../search/search.service';
import { TranslationService } from '../services/translation.service';

/**
 * Selector for associating multiple jobs with a MaterialPool.
 */
@Component({
    selector: 'material-pool-job-select',
    templateUrl: './material-pool-job-select.component.html',
    styles: [`
        .table {
            margin-bottom: 0.25em;
        }
    `]
})
export class MaterialPoolJobSelectComponent implements OnInit {
    @Input() materialPool: any;
    @Input() readonly: boolean;

    placeholder: string;

    constructor(
        private jobService: JobService,
        private searchService: SearchService,
        private translationService: TranslationService
    ) {
        //
    }

    ngOnInit() {
        this.placeholder = this.translationService.translate('Job') +
            ' name' +
            ELLIPSIS;
    }

    addItem(job: any) {
        let materialPoolKey = this.materialPool.C_MaterialPool_key;
        let jobKey = job.JobKey;

        let newJobMaterialPool = this.jobService.createJobMaterialPool({
            C_Job_key: jobKey,
            C_MaterialPool_key: materialPoolKey,
            Version: 0
        });

        // load job into breeze cache
        if (newJobMaterialPool) {
            this.jobService.getJob(jobKey).then((data: any) => {
                newJobMaterialPool.Job = data;
            });
        }
    }

    removeItem(jobMaterialPool: any) {
        this.jobService.deleteJobMaterialPool(jobMaterialPool);
    }

    searchJobs = (term: string): Promise<any> => {
        // Ignore job locked status
        return this.searchService.searchJobs(term).then((results: any) => {
            return results.data;
        });
    }

    jobIdFormatter = (value: any) => {
        return value.JobID;
    }
}
