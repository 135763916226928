import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { AnimalAgeDaysPipe } from '@common/pipes/animal-age-days.pipe';
import { AnimalAgeMonthsPipe } from '@common/pipes/animal-age-months.pipe';
import { AnimalAgeWeeksPipe } from '@common/pipes/animal-age-weeks.pipe';
import { AnimalCommentStatusStylePipe } from '@common/pipes/animal-comment-status-style.pipe';
import { AnimalFilterPipe } from '@common/pipes/animal-filter.pipe';
import { CharacteristicsWithoutListViewPipe } from '@common/pipes/characteristics-without-list-view.pipe';
import { ClinicalJobNameFormatPipe } from '@common/pipes/clinical-job-number-format.pipe';
import { ClinicalObservationModifierFormatPipe } from '@common/pipes/clinical-observation-format.pipe';
import { CurrentMaterialHousingIDPipe } from '@common/pipes/current-material-housing-id.pipe';
import { CurrentMaterialLocationPipe } from '@common/pipes/current-material-location.pipe';
import { DaysFromTodayPipe } from '@common/pipes/days-from-today.pipe';
import { DaysSincePipe } from '@common/pipes/days-since.pipe';
import { DelimitPipe } from '@common/pipes/delimit.pipe';
import { EvenPipe } from '@common/pipes/even.pipe';
import { FormatGenotypeNamePipe } from '@common/pipes/format-genotype-names.pipe';
import { FormatShortDateOrTimePipe } from '@common/pipes/format-short-date-or-time.pipe';
import { FormatShortDateOrTimeUtcPipe } from '@common/pipes/format-short-date-or-time-utc.pipe';
import { FormatShortDatePipe } from '@common/pipes/format-short-date.pipe';
import { FormatShortDateTimePipe } from '@common/pipes/format-short-date-time.pipe';
import { FormatShortDateTimeUtcPipe } from '@common/pipes/format-short-date-time-utc.pipe';
import { FormatShortDateUtcPipe } from '@common/pipes/format-short-date-utc.pipe';
import { FormatTimePipe } from '@common/pipes/format-time.pipe';
import { FormatUtcDateTimePipe } from '@common/pipes/format-utc-date-time.pipe';
import { FormatWeekPipe } from '@common/pipes/format-week.pipe';
import { GetTaxonCharacteristicInstancePipe } from '@common/pipes/get-taxon-characteristic-instance.pipe';
import { HalfPipe } from '@common/pipes/half.pipe';
import { HealthRecordJobNameFormatPipe } from '@common/pipes/health-record-job-number-format.pipe';
import { HousingCurrentAnimalsPipe } from '@common/pipes/housing-current-animals.pipe';
import { HousingOtherSexesPipe } from '@common/pipes/housing-other-sexes.pipe';
import { HousingSexFilterPipe } from '@common/pipes/housing-sex-filter.pipe';
import { HousingUniqueFemaleMaterialsPipe } from '@common/pipes/housing-unique-female-materials.pipe';
import { HousingUniqueMaleMaterialsPipe } from '@common/pipes/housing-unique-male-materials.pipe';
import { IsDeletedPipe } from '@common/pipes/is-deleted.pipe';
import { IsNewPipe } from '@common/pipes/is-new.pipe';
import { MapFnPipe } from '@common/pipes/map-fn.pipe';
import { MapPipe } from '@common/pipes/map.pipe';
import { MatingActiveAnimalsPipe } from '@common/pipes/mating-active-animals.pipe';
import { NotEmptyPipe } from '@common/pipes/not-empty.pipe';
import { OddPipe } from '@common/pipes/odd.pipe';
import { OrderByPipe } from '@common/pipes/order-by.pipe';
import { PriorMaterialHousingIDPipe } from '@common/pipes/prior-material-housing-id.pipe';
import { RandomIDPipe } from '@common/pipes/random-id.pipe';
import { RelativeTaskPipe } from '@common/pipes/relative-task.pipe';
import { ResourceNameDisplayPipe } from '@common/pipes/resource-name-display.pipe';
import { SafeNumberPipe } from '@common/pipes/safe-number.pipe';
import { SampleFilterPipe } from '@common/pipes/sample-filter.pipe';
import { TranslateMessagePipe } from '@common/pipes/translate-message.pipe';
import { TranslatePipe } from '@common/pipes/translate.pipe';
import { TruncateListPipe } from '@common/pipes/truncate-list.pipe';
import { TruncatePipe } from '@common/pipes/truncate.pipe';
import { TruncateSentencePipe } from '@common/pipes/truncate-sentence.pipe';
import { UserFullNamePipe } from '@common/pipes/user-full-name.pipe';
import { ValidDatePipe } from '@common/pipes/date-validator.pipe';
import { WeeksSincePipe } from '@common/pipes/weeks-since.pipe';
import { GetPropertyValuePipe } from './get-property-value.pipe';
import { DataAutomationIdPipe } from './data-automation-id.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        AnimalAgeDaysPipe,
        AnimalAgeMonthsPipe,
        AnimalAgeWeeksPipe,
        AnimalCommentStatusStylePipe,
        AnimalFilterPipe,
        CharacteristicsWithoutListViewPipe,
        ClinicalJobNameFormatPipe,
        ClinicalObservationModifierFormatPipe,
        CurrentMaterialHousingIDPipe,
        CurrentMaterialLocationPipe,
        DaysFromTodayPipe,
        DaysSincePipe,
        DelimitPipe,
        EvenPipe,
        FormatGenotypeNamePipe,
        FormatShortDateOrTimePipe,
        FormatShortDateOrTimeUtcPipe,
        FormatShortDatePipe,
        FormatShortDateTimePipe,
        FormatShortDateTimeUtcPipe,
        FormatShortDateUtcPipe,
        FormatTimePipe,
        FormatUtcDateTimePipe,
        FormatWeekPipe,
        GetPropertyValuePipe,
        GetTaxonCharacteristicInstancePipe,
        HalfPipe,
        HealthRecordJobNameFormatPipe,
        HousingCurrentAnimalsPipe,
        HousingOtherSexesPipe,
        HousingSexFilterPipe,
        HousingUniqueFemaleMaterialsPipe,
        HousingUniqueMaleMaterialsPipe,
        IsDeletedPipe,
        IsNewPipe,
        MapFnPipe,
        MapPipe,
        MatingActiveAnimalsPipe,
        NotEmptyPipe,
        OddPipe,
        OrderByPipe,
        PriorMaterialHousingIDPipe,
        RandomIDPipe,
        RelativeTaskPipe,
        ResourceNameDisplayPipe,
        SafeNumberPipe,
        SampleFilterPipe,
        TranslateMessagePipe,
        TranslatePipe,
        TruncateListPipe,
        TruncatePipe,
        TruncateSentencePipe,
        UserFullNamePipe,
        ValidDatePipe,
        WeeksSincePipe,
        DataAutomationIdPipe
    ],
    exports: [
        AnimalAgeDaysPipe,
        AnimalAgeMonthsPipe,
        AnimalAgeWeeksPipe,
        AnimalCommentStatusStylePipe,
        AnimalFilterPipe,
        CharacteristicsWithoutListViewPipe,
        ClinicalJobNameFormatPipe,
        ClinicalObservationModifierFormatPipe,
        CurrentMaterialHousingIDPipe,
        CurrentMaterialLocationPipe,
        DaysFromTodayPipe,
        DaysSincePipe,
        DelimitPipe,
        EvenPipe,
        FormatGenotypeNamePipe,
        FormatShortDateOrTimePipe,
        FormatShortDateOrTimeUtcPipe,
        FormatShortDatePipe,
        FormatShortDateTimePipe,
        FormatShortDateTimeUtcPipe,
        FormatShortDateUtcPipe,
        FormatTimePipe,
        FormatUtcDateTimePipe,
        FormatWeekPipe,
        GetPropertyValuePipe,
        GetTaxonCharacteristicInstancePipe,
        HalfPipe,
        HealthRecordJobNameFormatPipe,
        HousingCurrentAnimalsPipe,
        HousingOtherSexesPipe,
        HousingSexFilterPipe,
        HousingUniqueFemaleMaterialsPipe,
        HousingUniqueMaleMaterialsPipe,
        IsDeletedPipe,
        IsNewPipe,
        MapFnPipe,
        MapPipe,
        MatingActiveAnimalsPipe,
        NotEmptyPipe,
        OddPipe,
        OrderByPipe,
        PriorMaterialHousingIDPipe,
        RandomIDPipe,
        RelativeTaskPipe,
        ResourceNameDisplayPipe,
        SafeNumberPipe,
        SampleFilterPipe,
        TranslateMessagePipe,
        TranslatePipe,
        TruncateListPipe,
        TruncatePipe,
        TruncateSentencePipe,
        UserFullNamePipe,
        ValidDatePipe,
        WeeksSincePipe,
        DataAutomationIdPipe
    ],
    providers: [
        DecimalPipe,
    ],
})
export class BasePipesModule { }
