import { Injectable } from '@angular/core';

import { TranslationService } from '../services/translation.service';
import { UserNameService } from '../user/user-name.service';
import { CsvExporter } from '../common/export/csv-exporter';
import * as utils from '../common/export/utils';
import { DateFormatterService } from '@common/util/date-time-formatting';
import {
    getSafeProp,
    notEmpty,
    sortObjectArrayByAccessor,
    sortObjectArrayByProperty,
    currentMaterialHousingID,
    animalAgeDays,
    animalAgeWeeks,
    animalAgeMonths,
} from '@common/util';

import { TableSort } from '../common/models';
import { formatTimePoint } from '../samples/util';

/*
* Export a job detail record to CSV
*/
@Injectable()
export class ExportJobDetailService {

    csvExporter: CsvExporter;

    constructor(
        private translationService: TranslationService,
        private userNameService: UserNameService,
        private dateFormatterService: DateFormatterService
    ) {
        this.csvExporter = new CsvExporter();
    }

    exportJobToCsv(
        job: any,   
        animalTableSort: TableSort,
        sampleTableSort: TableSort,
        taskTableSort: TableSort,
        isCRL: boolean,
        isGLP: boolean
    ) {
        // Set defaults
        if (!animalTableSort.propertyPath) {
            animalTableSort.propertyPath = 'Material.Animal.AnimalName';
        }
        if (!sampleTableSort.propertyPath) {
            sampleTableSort.propertyPath = 'Material.Sample.SampleName';
        }
        if (!taskTableSort.propertyPath) {
            taskTableSort.propertyPath = 'TaskInstance.TaskAlias';
        }
        const filename = this.translationService.translate('Job') + 'Details.csv';

        let data: any[][] = this.buildExportJobData(
            job, 
            animalTableSort,
            sampleTableSort,
            taskTableSort,
            isCRL,
            isGLP
        );

        this.csvExporter.download(data, filename);
    }

    buildExportJobData(
        job: any,
        animalTableSort: TableSort,
        sampleTableSort: TableSort,
        taskTableSort: TableSort,
        isCRL: boolean,
        isGLP: boolean
    ): any[][] {
        let data: any[][] = [];
        
        // Build up CSV, basic values first
        data.push.apply(data, [
            [
                this.translationService.translate('Job') + ' Name',
                job.JobID
            ],
            [
                'Code',
                job.JobCode
            ],
            [
                this.translationService.translate('Study'),
                getSafeProp(job, "Study.StudyName")
            ],
            ['Title', job.Goal],
            ['Lead Scientist', job.LeadScientist],
            ['Study Monitor', job.StudyMonitor],
            ['Description', job.Notes],
            [this.translationService.translate('Line'), getSafeProp(job, "Line.LineName")],
            ['Species', getSafeProp(job, "Line.cv_Taxon.CommonName")],
            ['IACUC Protocol', getSafeProp(job, "cv_IACUCProtocol.IACUCProtocol")],
            ['Compliance', getSafeProp(job, "Compliance.Compliance")],
            [
                this.translationService.translate('Job') + ' Type',
                getSafeProp(job, "cv_JobType.JobType")
            ],
            [
                this.translationService.translate('Job') + ' Status',
                getSafeProp(job, "cv_JobStatus.JobStatus")
            ],
            ['Comments', job.Notes],
            ['Projected Start Date', this.dateFormatterService.formatDateOnly(job.ProjectedStartDate)],
            ['Start Date', this.dateFormatterService.formatDateOnly(job.DateStarted)],
            ['Duration Days', job.DurationDays],
            ['End Date', this.dateFormatterService.formatDateOnly(job.DateEnded)],
            ['Locations', job.CurrentLocationPath],
            ['Cost', job.Cost],
            ['Duration', job.Duration],
            ['Created By', this.userNameService.toFullName(job.CreatedBy)],
            ['Created Date', this.dateFormatterService.formatDateOnly(job.DateCreated)]
        ]);

        for (let jobCharacteristicInstance of
            sortObjectArrayByProperty(
                job.JobCharacteristicInstance, 'JobCharacteristic.SortOrder'
            )) {
            data.push(
                [jobCharacteristicInstance.CharacteristicName,
                jobCharacteristicInstance.CharacteristicValue]
            );
        }

        // List test articles involved with job
        data.push.apply(data, [[], [
            'Test Article',
            'Batch'
        ]]);
        let jobTestArticles: any[] = job.JobTestArticle;
        for (let jobTestArticle of jobTestArticles) {
            let testArticle: string = jobTestArticle.cv_TestArticle.TestArticle;

            let batch: string = jobTestArticle.Batch;

            data.push([
                testArticle,
                batch
            ]);
        }

        // List Institutions involved with job
        data.push.apply(data, [[], [
            this.translationService.translate('Institution'),
            'Site'
        ]]);
        let jobInstitutions: any[] = job.JobInstitution;
        for (let jobInstitution of jobInstitutions) {
            let institution: string = jobInstitution.Institution.Name;

            let site: string = "";
            if (jobInstitution.Site) {
                site = jobInstitution.Site.Name;
            }
            data.push([
                institution,
                site
            ]);
        }

        // List animals involved with job
        if (isGLP) {
            data.push.apply(data, [[], ['Animals'], [
                'ID',
                'Name',
                this.translationService.translate('Line'),
                'Genotypes',
                'Sex',
                'Birth Date',
                'Status',
                'Housing ID',
                'Microchip ID',
                'Alternate Physical ID',
                'External ID',
                'Classification',
                'Age (Days)',
                'Age (Weeks)',
                'Age (Months)',
                'Sequence'
            ]]);
        } else {
            data.push.apply(data, [[], ['Animals'], [
                'ID',
                'Name',
                this.translationService.translate('Line'),
                'Genotypes',
                'Sex',
                'Birth Date',
                'Status',
                'Housing ID',
                'Microchip ID',
                'External ID',
                'Age (Days)',
                'Age (Weeks)',
                'Age (Months)',
                'Sequence'
            ]]);
        }
        let jobAnimals: any[] = utils.materialTypeFilter
            (job.JobMaterial, 'Animal');
        for (let jobMaterial of sortObjectArrayByAccessor(
            jobAnimals,
            (item) => {
                return getSafeProp(item, animalTableSort.propertyPath);
            },
            animalTableSort.reverse)
        ) {
            let material: any = jobMaterial.Material;

            let genotypes: string = this.getGenotypesAsString(material.Animal.Genotype);
            if (isGLP) {
                data.push([
                    material.Identifier,
                    material.Animal.AnimalName,
                    getSafeProp(material, "Line.LineName"),
                    genotypes,
                    getSafeProp(material.Animal, "cv_Sex.Sex"),
                    this.dateFormatterService.formatDateOnly(material.Animal.DateBorn),
                    getSafeProp(material.Animal, "cv_AnimalStatus.AnimalStatus"),
                    currentMaterialHousingID(jobMaterial.Material.MaterialPoolMaterial),
                    material.MicrochipIdentifier,
                    material.Animal.AlternatePhysicalID,
                    material.ExternalIdentifier,
                    getSafeProp(material.Animal, "cv_Classification.Classification"),
                    animalAgeDays(material.Animal.DateBorn, material.Animal.DateExit),
                    animalAgeWeeks(material.Animal.DateBorn, material.Animal.DateExit),
                    animalAgeMonths(material.Animal.DateBorn, material.Animal.DateExit),
                    jobMaterial.Sequence
                ]);
            } else {
                data.push([
                    material.Identifier,
                    material.Animal.AnimalName,
                    getSafeProp(material, "Line.LineName"),
                    genotypes,
                    getSafeProp(material.Animal, "cv_Sex.Sex"),
                    this.dateFormatterService.formatDateOnly(material.Animal.DateBorn),
                    getSafeProp(material.Animal, "cv_AnimalStatus.AnimalStatus"),
                    currentMaterialHousingID(jobMaterial.Material.MaterialPoolMaterial),
                    material.MicrochipIdentifier,
                    material.ExternalIdentifier,
                    animalAgeDays(material.Animal.DateBorn, material.Animal.DateExit),
                    animalAgeWeeks(material.Animal.DateBorn, material.Animal.DateExit),
                    animalAgeMonths(material.Animal.DateBorn, material.Animal.DateExit),
                    jobMaterial.Sequence
                ]);
            }
        }
        data.push(['Total: ' + jobAnimals.length]);

        // List samples involved with job
        data.push.apply(data, [[], ['Samples'], [
            'ID',
            'Name',
            'Source',
            'Type',
            'TimePoint',
            'Status',
            'Preservation',
            this.translationService.translate('Line')
        ]]);
        let jobSamples: any[] = utils.materialTypeFilter
            (job.JobMaterial, 'Sample');
        for (let jobMaterial of sortObjectArrayByAccessor(
            jobSamples,
            (item) => {
                return getSafeProp(item, sampleTableSort.propertyPath);
            },
            sampleTableSort.reverse)
        ) {
            let material: any = jobMaterial.Material;
            // Comma-sep list of sources, both animal and sample
            let sources: string = '';
            if (material.MaterialSourceMaterial) {
                sources = material.MaterialSourceMaterial.map(
                    (m: any) => {
                        return utils.getMaterialName(m.SourceMaterial);
                    }).join(', ');
            }
            data.push([
                material.Identifier,
                material.Sample.SampleName,
                sources,
                getSafeProp(material.Sample, "cv_SampleType.SampleType"),
                formatTimePoint(material.Sample.TimePoint, material.Sample.cv_TimeUnit),
                getSafeProp(material.Sample, "cv_SampleStatus.SampleStatus"),
                getSafeProp(material.Sample, "cv_PreservationMethod.PreservationMethod"),
                getSafeProp(material, "Line.LineName")
            ]);
        }
        data.push(['Total: ' + jobSamples.length]);

        data.push.apply(data, [[], []]);
        // Data on each TaskInstance associated with this job
        for (let task of sortObjectArrayByAccessor(
            job.TaskJob,
            (item) => {
                return getSafeProp(item, taskTableSort.propertyPath);
            },
            taskTableSort.reverse)
        ) {

            // Basic task data
            data.push.apply(data, [
                ['Task Name', task.TaskInstance.TaskAlias],
                ['Task', task.TaskInstance.WorkflowTask.TaskName],
                [
                    this.translationService.translate('Protocol'),
                    getSafeProp(task, "TaskInstance.ProtocolTask.Protocol.ProtocolName")
                ],
                ['Sequence', getSafeProp(task, "Sequence")],
                ['Due Date', this.dateFormatterService.formatDateOrTime(task.TaskInstance.DateDue)],
                ['Deviation', getSafeProp(task, "TaskInstance.Deviation")],
                ['Task Location', getSafeProp(task, "TaskInstance.CurrentLocationPath")],
                ['Status', getSafeProp(task, "TaskInstance.cv_TaskStatus.TaskStatus")],
                ['Assigned To', getSafeProp(task, "TaskInstance.AssignedToResource.ResourceName")],
                ['Cost', getSafeProp(task, "TaskInstance.Cost")],
                ['Duration', getSafeProp(task, "TaskInstance.Duration")]
            ]);

            // Task animals
            data.push(['Animals']);
            for (let animal of utils.materialTypeFilter(
                task.TaskInstance.TaskMaterial, 'Animal')) {
                data.push(['', animal.Material.Animal.AnimalName]);
            }

            // Task samples
            data.push(['Samples']);
            for (let sample of utils.materialTypeFilter(
                task.TaskInstance.TaskMaterial, 'Sample')) {
                data.push(['', sample.Material.Sample.SampleName]);
            }

            // Task inputs
            data.push(['Inputs']);
            for (let taskInput of sortObjectArrayByProperty(
                task.TaskInstance.TaskInput, 'Input.SortOrder')) {
                data.push([
                    '',
                    taskInput.Input.InputName,
                    taskInput.InputValue
                ]);
            }

            // Task outputs
            data.push(['Outputs']);
            if (task.TaskInstance.TaskOutputSet.length > 0) {
                data.push(['', 'Animals', 'Samples', 'Values']);
            }
            for (let taskOutputSet of task.TaskInstance.TaskOutputSet) {
                let row: string[] = [''];

                // Each type of material involved
                let animals: any[] = utils.materialTypeFilter(
                    taskOutputSet.TaskOutputSetMaterial, 'Animal');
                let samples: any[] = utils.materialTypeFilter(
                    taskOutputSet.TaskOutputSetMaterial, 'Sample');
                row.push.apply(row, [
                    animals
                        .map((a) => a.Material.Animal.AnimalName)
                        .join(', '),
                    samples
                        .map((a) => a.Material.Sample.SampleName)
                        .join(', ')
                ]);

                // Actual key-value outputs
                for (let taskOutput of taskOutputSet.TaskOutput) {
                    row.push.apply(row, [taskOutput.Output.OutputName + ':',
                        taskOutput.OutputValue === 'NaN' ? 0 : taskOutput.OutputValue]);
                }

                data.push(row);
            }
            data.push.apply(data, [[], []]);
        }

        return data;
    }

    private getJobGroupsWithTreatments(data: any[][], job: any, isCRL: boolean) {
        data.push.apply(data, [[], [
            'Dosing Table'
        ]]);
        if (!isCRL) {
            data.push.apply(data, [[
                'Group',
                'Number',
                'Treatment',
                'FormulationDose',
                'ActiveDose',
                'ActiveUnit',
                'Concentration',
                'Route',
                'DosingVolume',
                'DosingUnit',
                'Schedule',
                'Protocol'
            ]]);
        } else {
            data.push.apply(data, [[
                'Group',
                'Number',
                'Treatment',
                'FormulationDose',
                'ActiveDose',
                'ActiveUnit',
                'Route',
                'DosingVolume',
                'DosingUnit',
                'Schedule',
                'Protocol'
            ]]);
        }

        let total: number = 0;
        for (let jobGroup of job.JobGroup) {
            data.push([
                jobGroup.Group,
                jobGroup.Number
            ]);
            total += jobGroup.Number;
            for (let jobGroupTreatment of jobGroup.JobGroupTreatment) {
                const protocol = jobGroupTreatment.ProtocolInstance && jobGroupTreatment.ProtocolInstance.ProtocolAlias;
                if (!isCRL) {
                    data.push([
                        "",
                        "",
                        jobGroupTreatment.Treatment,
                        jobGroupTreatment.FormulationDose,
                        jobGroupTreatment.ActiveDose,
                        jobGroupTreatment.ActiveUnit,
                        jobGroupTreatment.Concentration,
                        jobGroupTreatment.Route,
                        jobGroupTreatment.DosingVolume,
                        jobGroupTreatment.DosingUnit,
                        jobGroupTreatment.Schedule,
                        protocol ? jobGroupTreatment.ProtocolInstance.ProtocolAlias : ""
                    ]);
                } else {
                    data.push([
                        "",
                        "",
                        jobGroupTreatment.Treatment,
                        jobGroupTreatment.FormulationDose,
                        jobGroupTreatment.ActiveDose,
                        jobGroupTreatment.ActiveUnit,
                        jobGroupTreatment.Route,
                        jobGroupTreatment.DosingVolume,
                        jobGroupTreatment.DosingUnit,
                        jobGroupTreatment.Schedule,
                        protocol ? jobGroupTreatment.ProtocolInstance.ProtocolAlias : ""
                    ]);
                }                
            }
        }
        data.push.apply(data, [
            ['Total', total],
            ['Overage (%)', job.JobGroupOverage],
            ['Animal Total', Math.ceil(total + (job.JobGroupOverage * total / 100))]
        ]);
    }

    /*
    * Assumes job record has all relationships loaded
    */
    exportJobPharmaToCsv(
        isCRL: boolean,        
        isCRO: boolean,
        isGLP: boolean,
        job: any,
        sampleGroups: any[],
        animalTableSort: TableSort,
        cohortTableSort: TableSort, 
        sampleGroupTableSort: TableSort,
        sampleTableSort: TableSort,
        taskTableSort: TableSort
    ) {
        // Set defaults
        if (!animalTableSort.propertyPath) {
            animalTableSort.propertyPath = 'Material.Animal.AnimalName';
        }
        if (cohortTableSort && !cohortTableSort.propertyPath) {
            cohortTableSort.propertyPath = 'Cohort.CohortName';
        }
        if (sampleGroupTableSort && !sampleGroupTableSort.propertyPath) {
            sampleGroupTableSort.propertyPath = 'SampleGroup.SampleGroupName';
        }
        if (!sampleTableSort.propertyPath) {
            sampleTableSort.propertyPath = 'Material.Sample.SampleName';
        }
        if (!taskTableSort.propertyPath) {
            taskTableSort.propertyPath = 'TaskInstance.TaskAlias';
        }
        const filename = this.translationService.translate('Job') + 'Details.csv';

        let data: any[][] = this.buildExportJobPharmaData(
            isCRL,            
            isCRO,
            isGLP,
            job,
            sampleGroups,
            animalTableSort,
            cohortTableSort,
            sampleGroupTableSort,
            sampleTableSort,
            taskTableSort
        );

        this.csvExporter.download(data, filename);
    }

    private _buildExportJobPharmaMetadata(job: any, isCRL: boolean): any[][] {
        let data: any[][] = [];
        // Build up CSV, basic values first
        data.push.apply(data, [
            [
                this.translationService.translate('Job') + ' Name',
                job.JobID
            ],
            ['Code', job.JobCode],
            [
                this.translationService.translate('Study'),
                getSafeProp(job, "Study.StudyName")
            ],
            ['Title', job.Goal],
            ['Lead Scientist', job.LeadScientist],
            ['Study Monitor', job.StudyMonitor],
            ['Description', job.Notes],
            [this.translationService.translate('Line'), getSafeProp(job, "Line.LineName")],
            ['Species', getSafeProp(job, "Line.cv_Taxon.CommonName")]
        ]);

        // List test articles involved with job
        data.push.apply(data, [[], [
            'Test Article',
            'Batch'
        ]]);
        let jobTestArticles: any[] = job.JobTestArticle;
        for (let jobTestArticle of jobTestArticles) {
            let testArticle: string = jobTestArticle.cv_TestArticle.TestArticle;

            let batch: string = jobTestArticle.Batch;

            data.push([
                testArticle,
                batch
            ]);
        }

        // List orders involved with job
        data.push.apply(data, [[], [
            'Order ID(s)'
        ]]);
        let jobOrders: any[] = job.JobOrder;
        for (let jobOrder of jobOrders) {
            let orderId: string = jobOrder.Order.OrderID;
            data.push([
                orderId
            ]);
        }

        // List Institutions involved with job
        data.push.apply(data, [[], [
            this.translationService.translate('Institution'),
            'Site'
        ]]);
        let jobInstitutions: any[] = job.JobInstitution;
        for (let jobInstitution of jobInstitutions) {
            let institution: string = jobInstitution.Institution.Name;

            let site: string = "";
            if (jobInstitution.Site) {
                site = jobInstitution.Site.Name;
            }
            data.push([
                institution,
                site
            ]);
        }

        data.push.apply(data, [[],
        ['IACUC Protocol', getSafeProp(job, "cv_IACUCProtocol.IACUCProtocol")],
        ['Compliance', getSafeProp(job, "Compliance.Compliance")],
        [
            this.translationService.translate('Job') + ' Status',
            getSafeProp(job, "cv_JobStatus.JobStatus")
        ],
        [
            this.translationService.translate('Job') + ' Type',
            getSafeProp(job, "cv_JobType.JobType")
            ],
            ['Projected Start Date', this.dateFormatterService.formatDateOnly(job.ProjectedStartDate)],
        ['Start Date', this.dateFormatterService.formatDateOnly(job.DateStarted)],
        ['End Date', this.dateFormatterService.formatDateOnly(job.DateEnded)],

        ['Locations', job.CurrentLocationPath],
        ]);

        // List Characteristics involved with job
        data.push.apply(data, [[], [
            'Characteristics'
        ]]);
        for (let jobCharacteristicInstance of
            sortObjectArrayByProperty(
                job.JobCharacteristicInstance, 'JobCharacteristic.SortOrder'
            )) {
            data.push(
                [jobCharacteristicInstance.CharacteristicName,
                jobCharacteristicInstance.CharacteristicValue]
            );
        }

        data.push.apply(data, [[],
        ['Cost', job.Cost],
        ['Duration', job.Duration],
        ['Created By', this.userNameService.toFullName(job.CreatedBy)],
        ['Created Date', this.dateFormatterService.formatDateOnly(job.DateCreated)]
        ]);

        // List files involved with job
        data.push.apply(data, [[], ['Files'], [
            'File',
            'Date'
        ]]);
        let files: any[] = job.StoredFileMap;
        for (let file of files) {
            data.push([
                file.StoredFile.FriendlyFileName,
                this.dateFormatterService.formatDateOnly(file.StoredFile.DateCreated)
            ]);
        }
        data.push(['Total: ' + files.length]);

        return data;
    }

    private _buildExportJobPharmaCroMetadata(job: any, isCRL: boolean, isGLP: boolean): any[][] {
        let data: any[][] = [];
        // Build up CSV, basic values first
        data.push.apply(data, [
            ['Name', job.JobID ],
            ['Code', job.JobCode]
        ]);

        // List Institutions involved with job
        data.push.apply(data, [[], [
            this.translationService.translate('Institution'),
            'Site',
            'Scientific Contact',
            'Billing Contact',
            'Authorization Contact' 
        ]]);

        let jobInstitutions: any[] = job.JobInstitution;
        for (let jobInstitution of jobInstitutions) {
            let institution: string = jobInstitution.Institution.Name;

            let site: string = "";
            if (jobInstitution.Site) {
                site = jobInstitution.Site.Name;
            }

            let scientificContact: string = "";
            if (jobInstitution.ScientificContactPerson) {
                scientificContact = jobInstitution.ScientificContactPerson.FirstName + ' ' + jobInstitution.ScientificContactPerson.LastName;
            }

            let billingContact: string = "";
            if (isCRL) {
                if (jobInstitution.JobInstitutionBillingContact) {
                    for (let jobInstitutionBillingContact of jobInstitution.JobInstitutionBillingContact) {
                        if (jobInstitutionBillingContact.ContactPerson) {
                            billingContact += jobInstitutionBillingContact.ContactPerson.FirstName + '' + jobInstitutionBillingContact.ContactPerson.LastName + ', ';
                        }
                    }
                    if (billingContact.length > 0) {
                        billingContact = billingContact.slice(0, -1);
                    }
                }
            } else {                
                if (jobInstitution.BillingContactPerson) {
                    billingContact = jobInstitution.BillingContactPerson.FirstName + ' ' + jobInstitution.BillingContactPerson.LastName;
                }
            }

            let authorizationContact: string = "";
            if (jobInstitution.AuthorizationContactPerson) {
                authorizationContact = jobInstitution.AuthorizationContactPerson.FirstName + ' ' + jobInstitution.AuthorizationContactPerson.LastName;
            }

            data.push([
                institution,
                site,
                scientificContact,
                billingContact,
                authorizationContact
            ]);
        }

        data.push.apply(data, [[],
            ['Research Director', job.ResearchDirector],
            [!isGLP ? 'Client Manager' : 'Alternate Contact', job.ClientManager],
            ['Study Director', job.StudyDirector],
            ['IACUC Protocol', getSafeProp(job, "cv_IACUCProtocol.IACUCProtocol")],
            ['Compliance', getSafeProp(job, "Compliance.Compliance")],
            ['Status', getSafeProp(job, "cv_JobStatus.JobStatus")],
            ['Locations', job.CurrentLocationPath],
            [
                this.translationService.translate('Study'),
                getSafeProp(job, "Study.StudyName")
            ],
            ['Type', getSafeProp(job, "cv_JobType.JobType")],
            ['Subtype', getSafeProp(job, "cv_JobSubtype.JobSubtype")]
        ]);

        if (isCRL) {
            data.push.apply(data, [
                ['Imaging', job.Imaging]
            ]);
        }

        // List Characteristics involved with job
        data.push.apply(data, [[], [
            'Characteristics'
        ]]);
        for (let jobCharacteristicInstance of
            sortObjectArrayByProperty(
                job.JobCharacteristicInstance, 'JobCharacteristic.SortOrder'
            )) {
            data.push(
                [jobCharacteristicInstance.CharacteristicName,
                jobCharacteristicInstance.CharacteristicValue]
            );
        }

        data.push.apply(data, [[],
            ['Title', job.Goal],
            ['Description', job.Notes]            
        ]);

        // List test articles involved with job
        data.push.apply(data, [[], [
            'Test Article',
            'Batch',
            'Vehicle'
        ]]);
        let jobTestArticles: any[] = job.JobTestArticle;
        for (let jobTestArticle of jobTestArticles) {
            let testArticle: string = jobTestArticle.cv_TestArticle.TestArticle;
            let batch: string = jobTestArticle.Batch;
            let vehicle: string = jobTestArticle.Vehicle;
            data.push([
                testArticle,
                batch,
                vehicle
            ]);
        }

        // List job groups involved with job
        this.getJobGroupsWithTreatments(data, job, isCRL);

        // List orders involved with job
        data.push.apply(data, [[], [
            'Order ID(s)'
        ]]);
        let jobOrders: any[] = job.JobOrder;
        for (let jobOrder of jobOrders) {
            let orderId: string = jobOrder.Order.OrderID;
            data.push([
                orderId
            ]);
        }
        
        // List Lines involved with job
        data.push.apply(data, [[], [
            this.translationService.translate('Line'),
            'Species'
        ]]);
        for (let jobLine of job.JobLine) {
            data.push([
                getSafeProp(jobLine, "Line.LineName"),
                getSafeProp(jobLine, "Line.cv_Taxon.CommonName")
            ]);
        }

        if (isCRL) {
            // Contents of the Line details table
            data.push.apply(data, [[], [
                'Group',
                this.translationService.translate('Line'),
                'Species',
                'Sex',
                'Min Age',
                'Max Age',
                'Origin'
            ]]);
            for (let jobGroup of job.JobGroup) {
                data.push([
                    jobGroup.Group,
                    getSafeProp(jobGroup, "Line.LineName"),
                    getSafeProp(jobGroup, "Line.cv_Taxon.CommonName"),
                    getSafeProp(jobGroup, "cv_Sex.Sex"),
                    jobGroup.MinAge,
                    jobGroup.MaxAge,
                    getSafeProp(jobGroup, "cv_MaterialOrigin.MaterialOrigin")
                ]);
            }            
        }

        // Job Report
        data.push.apply(data, [[],
            [
                this.translationService.translate('Job') + ' Report',
                getSafeProp(job, 'cv_JobReport.JobReport')
            ]
        ]);

        if (isCRL && job.JobStandardPhrase.length > 0) {
            // Standard Phrases
            data.push.apply(data, [[], [
                'Standard Phrases'
            ]]);
            for (let jobStandardPhrase of job.JobStandardPhrase) {
                data.push([
                    jobStandardPhrase.cv_StandardPhrase.StandardPhrase
                ]);
            }
        }
                
        data.push.apply(data, [[],
        ['Projected Implant Date', this.dateFormatterService.formatDateOnly(job.ProjectImplantDate)],
        ['Implanted Date', this.dateFormatterService.formatDateOnly(job.ImplantedDate)],
        ['Projected Start Date', this.dateFormatterService.formatDateOnly(job.ProjectedStartDate)],
        ['Start Date', this.dateFormatterService.formatDateOnly(job.DateStarted)],
        [this.translationService.translate('Job') + ' Duration (days)', job.DurationDays],
        ['End Date', this.dateFormatterService.formatDateOnly(job.DateEnded)],
        ['Created By', this.userNameService.toFullName(job.CreatedBy)],
        ['Created Date', this.dateFormatterService.formatDateOnly(job.DateCreated)]
        ]);

        // List files involved with job
        data.push.apply(data, [[], ['Files'], [
            'File',
            'Date'
        ]]);
        let files: any[] = job.StoredFileMap;
        for (let file of files) {
            data.push([
                file.StoredFile.FriendlyFileName,
                this.dateFormatterService.formatDateOnly(file.StoredFile.DateCreated)
            ]);
        }
        data.push(['Total: ' + files.length]);

        return data;
    }

    buildExportJobPharmaData(
        isCRL: boolean,        
        isCRO: boolean,
        isGLP: boolean,
        job: any,
        sampleGroups: any[],
        animalTableSort: TableSort,
        cohortTableSort: TableSort,
        sampleGroupTableSort: TableSort,
        sampleTableSort: TableSort,
        taskTableSort: TableSort
    ): any[][] {
        let data: any[][] = [];
        data.push.apply(data, [[
            'sep=,'
        ]]);
        // Build up CSV, basic values first
        if (isCRO) {
            data.push.apply(data, this._buildExportJobPharmaCroMetadata(job, isCRL, isGLP));
        } else {
            data.push.apply(data, this._buildExportJobPharmaMetadata(job, isCRL));
        }

        // List cohorts involved with job
        data.push.apply(data, [[], ['Cohorts'], [
            'Name',
            'Description',
            'Animals'
        ]]);
        let jobCohorts: any[] = job.JobCohort;
        for (let jobCohort of jobCohorts) {         
            data.push([
                jobCohort.Cohort.CohortName,
                jobCohort.Cohort.Description,
                '"' + jobCohort.animalCounts.inJob + '/' + jobCohort.animalCounts.total + '"' 
            ]);
        }
        data.push(['Total: ' + jobCohorts.length]);

        // List animals involved with job
        if (isGLP) {
            data.push.apply(data, [[], ['Animals'], [
                'ID',
                'Name',
                this.translationService.translate('Line'),
                'Genotypes',
                'Sex',
                'Birth Date',
                'Status',
                'Held For',
                'Housing ID',
                'Microchip ID',
                'Alternate Physical ID',
                'External ID',
                'CITES Number',
                'Classification',
                'Age (Days)',
                'Age (Weeks)',
                'Age (Months)',
                'Sequence'
            ]]);
        } else {
            data.push.apply(data, [[], ['Animals'], [
                'ID',
                'Name',
                this.translationService.translate('Line'),
                'Genotypes',
                'Sex',
                'Birth Date',
                'Status',
                'Housing ID',
                'Microchip ID',
                'External ID',
                'Age (Days)',
                'Age (Weeks)',
                'Age (Months)',
                'Sequence'
            ]]);
        }
        let jobAnimals: any[] = utils.materialTypeFilter
            (job.JobMaterial, 'Animal');
        if (isGLP) {
            jobAnimals = jobAnimals.filter((jm: any) => !jm.DateOut);
        }
        for (let jobMaterial of sortObjectArrayByAccessor(
            jobAnimals,
            (item) => {
                return getSafeProp(item, animalTableSort.propertyPath);
            },
            animalTableSort.reverse)
        ) {
            let material: any = jobMaterial.Material;

            let genotypes: string = this.getGenotypesAsString(material.Animal.Genotype);

            if (isGLP) {
                data.push([
                    material.Identifier,
                    material.Animal.AnimalName,
                    getSafeProp(material, "Line.LineName"),
                    genotypes,
                    getSafeProp(material.Animal, "cv_Sex.Sex"),
                    this.dateFormatterService.formatDateOnly(material.Animal.DateBorn),
                    getSafeProp(material.Animal, "cv_AnimalStatus.AnimalStatus"),
                    material.Animal.HeldFor,
                    currentMaterialHousingID(jobMaterial.Material.MaterialPoolMaterial),
                    material.MicrochipIdentifier,
                    material.Animal.AlternatePhysicalID,
                    material.ExternalIdentifier,
                    material.Animal.CITESNumber,
                    getSafeProp(material.Animal, "cv_Classification.Classification"),
                    animalAgeDays(material.Animal.DateBorn, material.Animal.DateExit),
                    animalAgeWeeks(material.Animal.DateBorn, material.Animal.DateExit),
                    animalAgeMonths(material.Animal.DateBorn, material.Animal.DateExit),
                    jobMaterial.Sequence
                ]);
            } else {
                data.push([
                    material.Identifier,
                    material.Animal.AnimalName,
                    getSafeProp(material, "Line.LineName"),
                    genotypes,
                    getSafeProp(material.Animal, "cv_Sex.Sex"),
                    this.dateFormatterService.formatDateOnly(material.Animal.DateBorn),
                    getSafeProp(material.Animal, "cv_AnimalStatus.AnimalStatus"),
                    currentMaterialHousingID(jobMaterial.Material.MaterialPoolMaterial),
                    material.MicrochipIdentifier,
                    material.ExternalIdentifier,
                    animalAgeDays(material.Animal.DateBorn, material.Animal.DateExit),
                    animalAgeWeeks(material.Animal.DateBorn, material.Animal.DateExit),
                    animalAgeMonths(material.Animal.DateBorn, material.Animal.DateExit),
                    jobMaterial.Sequence
                ]);
            }
        }
        data.push(['Total: ' + jobAnimals.length]);

        // List samples groups involved with job
        data.push.apply(data, [[], ['Sample Groups'], [
            'Protocol',
            'Task Name',
            'Source',
            'Samples per Source',
            'Samples',
            'Type',
            'Status',
            'Preservation',
            'Container',
            'Harvest Date',
            'Expiration Date',
            'Time Point',
            'Subtype',
            'Processing',
            'Send To',
            'Analysis'
        ]]);
        for (let sampleGroup of sampleGroups) {
            data.push([
                sampleGroup.TaskInstance.ProtocolInstance ?
                    sampleGroup.TaskInstance.ProtocolInstance.ProtocolAlias : '',
                sampleGroup.TaskInstance.TaskAlias,
                sampleGroup.SampleGroupSourceMaterial.length,
                sampleGroup.NumSamples,
                sampleGroup.SampleGroupSourceMaterial.length * sampleGroup.NumSamples,
                sampleGroup.cv_SampleType ? sampleGroup.cv_SampleType.SampleType : '',
                sampleGroup.cv_SampleStatus ? sampleGroup.cv_SampleStatus.SampleStatus : '',
                sampleGroup.cv_PreservationMethod ?
                    sampleGroup.cv_PreservationMethod.PreservationMethod : '',
                sampleGroup.cv_ContainerType ? sampleGroup.cv_ContainerType.ContainerType : '',
                this.dateFormatterService.formatDateOnly(sampleGroup.DateHarvest),
                this.dateFormatterService.formatDateOnly(sampleGroup.DateExpiration),
                sampleGroup.TimePoint + ' ' +
                (sampleGroup.cv_TimeUnit ? sampleGroup.cv_TimeUnit.TimeUnit : ''),
                sampleGroup.cv_SampleSubtype ? sampleGroup.cv_SampleSubtype.SampleSubtype : '',
                sampleGroup.cv_SampleProcessingMethod ? sampleGroup.cv_SampleProcessingMethod.SampleProcessingMethod : '',
                sampleGroup.SendTo,
                sampleGroup.cv_SampleAnalysisMethod ? sampleGroup.cv_SampleAnalysisMethod.SampleAnalysisMethod : ''
            ]);
        }
        data.push(['Total: ' + sampleGroups.length]);       

        // List samples involved with job
        data.push.apply(data, [[], ['Samples'], [
            'ID',
            'Name',
            'Source',
            'Type',
            'TimePoint',
            'Status',
            'Preservation',
            'Subtype',
            'Processing',
            'Send To',
            'Analysis',
            this.translationService.translate('Line')
        ]]);
        let jobSamples: any[] = utils.materialTypeFilter
            (job.JobMaterial, 'Sample');
        for (let jobMaterial of sortObjectArrayByAccessor(
            jobSamples,
            (item) => {
                return getSafeProp(item, sampleTableSort.propertyPath);
            },
            sampleTableSort.reverse)
        ) {
            let material: any = jobMaterial.Material;
            // Comma-sep list of sources, both animal and sample
            let sources: string = '';
            if (material.MaterialSourceMaterial) {
                sources = material.MaterialSourceMaterial.map(
                    (m: any) => {
                        return utils.getMaterialName(m.SourceMaterial);
                    }).join(', ');
            }
            data.push([
                material.Identifier,
                material.Sample.SampleName,
                sources,
                getSafeProp(material.Sample, "cv_SampleType.SampleType"),
                formatTimePoint(material.Sample.TimePoint, material.Sample.cv_TimeUnit),
                getSafeProp(material.Sample, "cv_SampleStatus.SampleStatus"),
                getSafeProp(material.Sample, "cv_PreservationMethod.PreservationMethod"),
                getSafeProp(material.Sample, "cv_SampleSubtype.SampleSubtype"),
                getSafeProp(material.Sample, "cv_SampleProcessingMethod.SampleProcessingMethod"),
                material.Sample.SendTo,
                getSafeProp(material.Sample, "cv_SampleAnalysisMethod.SampleAnalysisMethod"),
                getSafeProp(material, "Line.LineName")
            ]);
        }
        data.push(['Total: ' + jobSamples.length]);

        data.push.apply(data, [[], []]);
        // Data on each TaskInstance associated with this job
        for (let task of sortObjectArrayByAccessor(
            job.TaskJob,
            (item) => {
                return getSafeProp(item, taskTableSort.propertyPath);
            },
            taskTableSort.reverse)
        ) {

            // Basic task data
            data.push.apply(data, [
                ['Task Name', task.TaskInstance.TaskAlias],
                ['Task', task.TaskInstance.WorkflowTask.TaskName],
                [
                    this.translationService.translate('Protocol'),
                    getSafeProp(task, "TaskInstance.ProtocolTask.Protocol.ProtocolName")
                ],
                ['Sequence', getSafeProp(task, "Sequence")],
                ['Due Date', this.dateFormatterService.formatDateOrTime(task.TaskInstance.DateDue)],
                ['Deviation', getSafeProp(task, "TaskInstance.Deviation")],
                ['Task Location', getSafeProp(task, "TaskInstance.CurrentLocationPath")],
                ['Status', getSafeProp(task, "TaskInstance.cv_TaskStatus.TaskStatus")],
                ['Assigned To', getSafeProp(task, "TaskInstance.AssignedToResource.ResourceName")],
                ['Cost', getSafeProp(task, "TaskInstance.Cost")],
                ['Duration', getSafeProp(task, "TaskInstance.Duration")]
            ]);

            // Task animals
            data.push(['Animals']);
            for (let animal of utils.materialTypeFilter(
                task.TaskInstance.TaskMaterial, 'Animal')) {
                data.push(['', animal.Material.Animal.AnimalName]);
            }

            // Task samples
            data.push(['Samples']);
            for (let sample of utils.materialTypeFilter(
                task.TaskInstance.TaskMaterial, 'Sample')) {
                data.push(['', sample.Material.Sample.SampleName]);
            }

            // Task inputs
            data.push(['Inputs']);
            for (let taskInput of sortObjectArrayByProperty(
                task.TaskInstance.TaskInput, 'Input.SortOrder')) {
                data.push([
                    '',
                    taskInput.Input.InputName,
                    taskInput.InputValue
                ]);
            }

            // Task outputs
            data.push(['Outputs']);
            if (task.TaskInstance.TaskOutputSet.length > 0) {
                data.push(['', 'Animals', 'Samples', 'Values']);
            }
            for (let taskOutputSet of task.TaskInstance.TaskOutputSet) {
                let row: string[] = [''];

                // Each type of material involved
                let animals: any[] = utils.materialTypeFilter(
                    taskOutputSet.TaskOutputSetMaterial, 'Animal');
                let samples: any[] = utils.materialTypeFilter(
                    taskOutputSet.TaskOutputSetMaterial, 'Sample');
                row.push.apply(row, [
                    animals
                        .map((a) => a.Material.Animal.AnimalName)
                        .join(', '),
                    samples
                        .map((a) => a.Material.Sample.SampleName)
                        .join(', ')
                ]);

                // Actual key-value outputs
                for (let taskOutput of taskOutputSet.TaskOutput) {
                    row.push.apply(row, [taskOutput.Output.OutputName + ':',
                        taskOutput.OutputValue === 'NaN' ? 0 : taskOutput.OutputValue]);
                }

                data.push(row);
            }
            data.push.apply(data, [[], []]);
        }

        return data;
    }

    getGenotypesAsString(genotypes: any[]): string {
        const delimiter = ', ';
        let stringVal: string = '';

        if (notEmpty(genotypes)) {
            stringVal = genotypes.map(
                (genotype: any) => {
                    let out: string = '';

                    if (genotype.cv_GenotypeAssay) {
                        out = getSafeProp(genotype, 'cv_GenotypeAssay.GenotypeAssay');
                    }
                    if (genotype.cv_GenotypeSymbol) {
                        out += ' ' + getSafeProp(genotype, 'cv_GenotypeSymbol.GenotypeSymbol');
                    }

                    return out;
                })
                // filter out empty values
                .filter((item) => item)
                .join(delimiter);
        }

        return stringVal;
    }
}
