import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    Output,
    EventEmitter,
    ChangeDetectorRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet';
import {
    getSafeProp,
} from '../../../common/util';

import { SampleService } from '../../../samples/sample.service';
import { CopyBufferService } from '../../../common/services/copy-buffer.service';
import { DataManagerService } from '../../../services/data-manager.service';
import {
    JobPharmaDetailService,
} from '../job-pharma-detail.service';
import { JobService } from '../../job.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { NamingService } from '../../../services/naming.service';
import { Entity, Job, JobMaterial, Material, Sample, TaskJob } from '@common/types';
import { TaskService } from '../../../tasks/task.service';

@Component({
    selector: 'job-pharma-samples-individual-table',
    templateUrl: './job-pharma-samples-individual-table.component.html',
})
export class JobPharmaSamplesIndividualTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() readonly: boolean;
    @Input() job: Entity<Job>;

    @Input() tabset: string = 'samples';
    @Input() tab: string = 'individual';

    @Input() activeFields: string[] = [];

    loading: boolean = false;
    loadingMessage: string = "Loading";
    @Output() selectedRowsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

    samplePage: number = 1;

    sampleMaterials: any[];
    selectedRows: any[];

    // Column selections
    columnSelect: ColumnSelect = {
        model: [],
        labels: [],
    };

    // Visible columns
    visible: any = {};

    // Are all the rows selected?
    allSelected: boolean = false;

    // All subscriptions
    subs = new Subscription();

    sampleNamingActive: boolean = false;

    readonly COMPONENT_LOG_TAG = 'job-pharma-samples-individual-table';

    constructor(
        private copyBufferService: CopyBufferService,
        private dataManager: DataManagerService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private jobService: JobService,
        private sampleService: SampleService,
        private vocabularyService: VocabularyService,
        private namingService: NamingService,
        private taskService: TaskService,
    ) {
        // Do nothing
    }

    async ngOnInit() {
        this.initColumnSelect();
        this.initTabActions();
        await this.initialize();
        this.initChangeDetection();
    }

    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            this.initJob();
        }
    }

    ngOnDestroy() {
        this.clearSampleSelections();

        // Clear all the subscriptions
        this.subs.unsubscribe();
    }

    /**
     * Watch for external changes
     */
    initChangeDetection() {
        // Watch for changes to Job.JobMaterial
        this.subs.add(this.jobPharmaDetailService.jobMaterialsChanged$.subscribe(() => {
            this.initJob();
        }));
    }

    initialize(): Promise<any> {
        return this.getCVs().then(() => {
            this.isNamingActive();
            return this.initJob();
        });
    }

    getCVs(): Promise<any> {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
            this.vocabularyService.ensureCVLoaded('cv_SampleTypes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleStatuses'),
            this.vocabularyService.ensureCVLoaded('cv_PreservationMethods'),
            this.vocabularyService.ensureCVLoaded('cv_ContainerTypes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleSubtypes'),
            this.vocabularyService.ensureCVLoaded('cv_SampleProcessingMethods'),
            this.vocabularyService.ensureCVLoaded('cv_SampleAnalysisMethods'),
        ]);
    }

    private isNamingActive(): Promise<any> {
        return this.namingService.isSampleNamingActive().then((active: boolean) => {
            this.sampleNamingActive = active;
        });
    }

    /**
     * Initialize the column selections
     */
    initColumnSelect() {
        // Default Visibility
        this.visible = {
            name: true,
            type: true,
            timePoint: true,
            status: true,
            harvestDate: true,
            expirationDate: true,
            preservation: true,
            container: true,
            source: true,
            location: true,
            subtype: true,
            processing: true,
            sendTo: true,
            analysis: true,
        };

        // Assemble the list of all columns that can be selected
        this.columnSelect.labels = [
            new ColumnSelectLabel('name', 'Name'),
            new ColumnSelectLabel('type', 'Type'),
            this.activeFields.includes("TimePoint") ? new ColumnSelectLabel('timePoint', 'Time Point') : null,
            new ColumnSelectLabel('status', 'Status'),
            this.activeFields.includes("DateHarvest") ? new ColumnSelectLabel('harvestDate', 'Harvest Date') : null,
            this.activeFields.includes("DateExpiration") ? new ColumnSelectLabel('expirationDate', 'Expiration Date') : null,
            this.activeFields.includes("C_PreservationMethod_key") ? new ColumnSelectLabel('preservation', 'Preservation') : null,
            this.activeFields.includes("Material.C_ContainerType_key") ? new ColumnSelectLabel('container', 'Container') : null,
            this.activeFields.includes("Material.MaterialSourceMaterial") ? new ColumnSelectLabel('source', 'Source') : null,
            this.activeFields.includes("Location") ? new ColumnSelectLabel('location', 'Location') : null,
            this.activeFields.includes("C_SampleSubtype_key") ? new ColumnSelectLabel('subtype', 'Subtype') : null,
            this.activeFields.includes("C_SampleProcessingMethod_key") ? new ColumnSelectLabel('processing', 'Processing') : null,
            this.activeFields.includes("SendTo") ? new ColumnSelectLabel('sendTo', 'Send To') : null,
            this.activeFields.includes("C_SampleAnalysisMethod_key") ? new ColumnSelectLabel('analysis', 'Analysis') : null,
        ];

        this.columnSelect.labels = this.columnSelect.labels.filter((label: ColumnSelectLabel) => {
            return label !== null;
        });

        this.columnSelect.model = this.columnSelect.labels.filter(
            (item) => this.visible[item.key]
        ).map((item) => item.key);

        // Register the columns
        this.subs.add(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, this.tab, this.columnSelect,
                () => { this.updateVisible(); }
            )
        );

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }

    /**
     * Watch for event between the tabs
     */
    initTabActions() {
        // Listen for calls to refresh view
        this.subs.add(
            this.jobPharmaDetailService.tabRefresh$.subscribe((event) => {
                if (event.tabset === 'samples' && event.tab === 'individual') {
                    this.initJob();
                }
            })
        );
    }

    // Initialize the list of Samples
    private async initJob(): Promise<any> {
        this.loading = true;
        if (!this.job) {
            // Just in case, there is no Job
            this.sampleMaterials = [];
            return Promise.resolve();
        }

        try {
            await this.dataManager.ensureRelationships(
                [this.job], ['JobMaterial.Material.Sample']
            );
            // Find all the Samples
            this.sampleMaterials = this.job.JobMaterial.filter((item: any) => {
                return getSafeProp(item, 'Material.Sample');
            });
            await this.ensureSampleRelationships(this.samplePage);
            // Just in case, clear the selections
            this.clearSampleSelections();
        } finally {
            this.loading = false;
        }
    }

    /**
     * Make sure all the JobMaterial relationships are loaded.
     */
    ensureSampleRelationships(page: number): Promise<any> {
        const startIndex = (page - 1) * 50;
        let endIndex: number;
        if (page * 50 - 1 > this.sampleMaterials.length) {
            endIndex = this.sampleMaterials.length;
        }
        else {
            endIndex = page * 50;
        }
        const expands = [
            'Material.MaterialSourceMaterial.SourceMaterial.Animal',
            'Material.MaterialSourceMaterial.SourceMaterial.Sample',
        ];
        const pageSamples = this.sampleMaterials.slice(startIndex, endIndex);

        return this.dataManager.ensureRelationships(pageSamples, expands);
    }

    /**
     * Unselect all the samples
     */
    clearSampleSelections() {
        if (this.sampleMaterials) {
            // Reset samples selections
            this.sampleMaterials.forEach((jm) => { jm.isSelected = false; });
        }
        this.allSelected = false;
    }


    /**
     * The Select/Clear All button was clicked
     */
    allSelectedChanged() {
        // Select or unselect all the rows
        if (this.sampleMaterials) {
            for (const jm of this.sampleMaterials) {
                jm.isSelected = this.allSelected;
            }
        }
        this.selectionChanged();
    }

    /**
     * A row selection checkbox was clicked.
     */
    isSelectedChanged() {
        // Check if all the rows are selected
        this.allSelected = this.sampleMaterials.every((jm) => jm.isSelected);
        this.selectionChanged();
    }


    selectionChanged() {
        this.selectedRows = this.getSelectedRows();
        this.selectedRowsChange.emit(this.selectedRows);
    }

    getSelectedRows(): any[] {
        return this.sampleMaterials.filter((jm) => jm.isSelected);
    }
    /**
     * Handle dropping Samples into the Job
     */
    onDrop(): Promise<any> {
        let samples = this.sampleService.draggedSamples;
        this.sampleService.draggedSamples = [];

        return this.addSamplesToJob(samples);
    }

    /**
     * Handle pasting Samples into the Job
     */
    onPaste(): Promise<any> {
        if (!this.copyBufferService.hasSamples()) {
            return Promise.resolve();
        }

        let samples = this.copyBufferService.paste();
        return this.addSamplesToJob(samples);
    }

    /**
     * Add Samples to the job
     */
    private addSamplesToJob(samples: any[]): Promise<any> {
        const materials = samples.map(s => s.Material) as Entity<Material>[];
        return this.jobPharmaDetailService.addMaterialsToJobIfMissing(this.job, materials);
    }

    /**
     * Remove a Sample from the Job
     */
    removeJobMaterial(jobMaterial: JobMaterial): Promise<void> {
        return this.jobPharmaDetailService.removeJobMaterial(jobMaterial);
    }

    // Dragging Out
    private dragId: number = null;
    dragStart() {
        // Find the selected samples
        const selected = this.sampleMaterials
            .filter((jm) => jm.isSelected)
            .map((jm) => jm.Material.Sample);

        this.dragId = this.jobPharmaDetailService.startDrag('Sample', selected);
    }

    // TODO: ?
    dragStop() {
        setTimeout(() => {
            this.jobPharmaDetailService.stopDrag(this.dragId);
        }, 500);
    }

    async changeSamplePage(newPage: number) {
        this.loading = true;
        this.samplePage = newPage;
        await this.ensureSampleRelationships(this.samplePage);
        this.loading = false;
    }
}
