import {
    CellFormatterService,
    TableOptions,
    FormatterOptions
} from '../common/datatable';
import { TranslationService } from '../services/translation.service';
import { AuditModifiedFieldComponent } from './audit-modified-fields/audit-modified-field.component';
import { AuditClassFactory } from './audit-class-maker';

export class AuditJobSampleGroupsTableOptions {

    options: TableOptions;

    constructor(
        private cellFormatterService: CellFormatterService,
        private translationService: TranslationService
    ) {
        this.options = this.getDefaultOptions();
    }

    getDefaultOptions(): TableOptions {

        return {
            csvFileName: this.translationService.translate('job').toLowerCase() + '-sample-groups-audit.csv',
            enableDetailColumn: false,
            enableDraggable: false,
            enableSelectable: false,
            enableAuditColumns: false,
            columns: [
                {
                    displayName: 'Type',
                    field: 'UpdateType',
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified Fields',
                    field: 'ModifiedFields',
                    rendererComponent: AuditModifiedFieldComponent,
                    exportFormatter: this.cellFormatterService.auditModifiedFieldsExportFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Date',
                    field: 'ModifiedDate',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Modified By',
                    field: 'ModifiedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: this.translationService.translate('Job') + ' Name',
                    field: 'JobName',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('JobName', (this.translationService.translate('Job') + 'Name')),
                    cellClass: AuditClassFactory.createCellClass((this.translationService.translate('Job') + 'Name').toLowerCase()),
                },
                {
                    displayName: 'Sample Number',
                    field: 'SampleNumber',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SampleNumber', 'SampleNumber'),
                    cellClass: AuditClassFactory.createCellClass('samplenumber'),
                },
                {
                    displayName: 'Sample Type',
                    field: 'SampleType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SampleType', 'SampleType'),
                    cellClass: AuditClassFactory.createCellClass('sampletype'),
                },
                {
                    displayName: 'Sample Status',
                    field: 'SampleStatus',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('SampleStatus', 'SampleStatus'),
                    cellClass: AuditClassFactory.createCellClass('samplestatus'),
                },
                {
                    displayName: 'Sample Preservation',
                    field: 'PreservationMethod',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('PreservationMethod', 'PreservationMethod'),
                    cellClass: AuditClassFactory.createCellClass('preservationmethod'),
                },
                {
                    displayName: 'Sample Container',
                    field: 'ContainerType',
                    formatter: this.cellFormatterService.createAuditColumnFormatter('ContainerType', 'ContainerType'),
                    cellClass: AuditClassFactory.createCellClass('containertype'),
                },
                {
                    displayName: 'Created By',
                    field: 'CreatedBy',
                    formatter: this.cellFormatterService.userNameFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                },
                {
                    displayName: 'Created Date',
                    field: 'DateCreated',
                    formatter: this.cellFormatterService.dateTimeUTCFormatter,
                    cellClass: AuditClassFactory.createDeletedClass,
                }
            ]
        };
    }
}
